import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import pluralize from 'pluralize';
import humanizeString from 'humanize-string';
import { connect } from 'react-redux';
import {
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import ReactTagAutocomplete from 'react-tag-autocomplete';
import Tag from 'modules/core/components/Tag';
import getEntityProperty from '../selectors/getEntityProperty';

const NON_BREAKING_SPACE = '\u00A0';
const SPACES_PER_INDENT = 2;

/**
 * A tag select that uses entities
 */
const EntityTagSelect = ({
  className,
  entities,
  entityLabel,
  handleAddition,
  handleDelete,
  shouldRenderAsInput,
  tags,
}) => {
  const [isFiltering, setIsFiltering] = useState(false);

  // react-tag-autocomplete doesn't support hierarchy or indentation, nor does it allow
  // us to provide our own component for the menu or its items, so we can't use CSS
  // margin/padding to indent items. Instead we can cheat by using non-breaking
  // spaces. Unfortunately this means that very long tags look a little
  // awkward when they wrap lines, but that's the tradeoff we've chosen at this time.
  const getIndentedLeafName = tagName => {
    const tagParts = tagName.split(' > ');
    return tagParts
      .map((tag, index) =>
        index === tagParts.length - 1
          ? tag
          : NON_BREAKING_SPACE.repeat((index + 1) * SPACES_PER_INDENT),
      )
      .join('');
  };

  const escapeForRegExp = query =>
    query && query.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

  // This filter algorithm (including escapeForRegExp) was pulled directly out of
  // the react-tag-autocomplete source code. We're re-defining it here because we
  // need to filter on the full hierarchy string, not just the leaf node name, so
  // we need to test against item.fullAncestralName instead of item.name.
  const suggestionsFilter = (item, query) => {
    const regex = new RegExp(`(?:^|\\s)${escapeForRegExp(query)}`, 'i');
    return regex.test(item && item.fullAncestralName);
  };

  const getHierarchyTagSuggestions = () =>
    entities.map(entity => ({
      disabled: entity.hasChildren,
      id: entity.id,
      name: getIndentedLeafName(entity.name),
      fullAncestralName: entity.name,
    }));

  const getFlatTagSuggestions = () =>
    entities.map(entity => ({
      disabled: entity.hasChildren,
      id: entity.id,
      name: entity.name,
      fullAncestralName: entity.name,
    }));

  const handleFilterChange = query => {
    setIsFiltering(Boolean(query));
  };

  return (
    <div
      className={classnames('EntityTagSelect', 'tag-select', className, {
        'EntityTagSelect--no-suggestions': entities.length === 0,
        'EntityTagSelect--isNotFiltering': !isFiltering,
      })}
    >
      <ReactTagAutocomplete
        allowBackspace={false}
        autoresize={false}
        classNames={{
          root: classnames('react-tags', {
            'form-control': shouldRenderAsInput,
          }),
          rootFocused: 'is-focused',
          selected: 'react-tags__selected',
          selectedTag: 'react-tags__selected-tag',
          selectedTagName: 'react-tags__selected-tag-name',
          search: 'react-tags__search',
          searchWrapper: 'react-tags__search-wrapper',
          searchInput: 'react-tags__search-input',
          suggestions: 'react-tags__suggestions',
          suggestionActive: 'is-active',
          suggestionDisabled: 'is-disabled',
        }}
        maxSuggestionsLength={entities.length}
        minQueryLength={0}
        onAddition={handleAddition}
        onDelete={handleDelete}
        onInputChange={handleFilterChange}
        placeholderText={`+ Add ${entityLabel}`}
        suggestions={
          isFiltering ? getFlatTagSuggestions() : getHierarchyTagSuggestions()
        }
        suggestionsFilter={suggestionsFilter}
        tagComponent={({ onDelete, tag }) => (
          <Tag
            name={tag.name}
            /**
             * stopPropagation is used here to allow clicking on the remove tag icon
             * without triggering a click on dropdown menu behind it.
             */
            onClick={e => e.stopPropagation()}
            onDelete={onDelete}
          />
        )}
        tags={tags}
      />
    </div>
  );
};

EntityTagSelect.propTypes = {
  /**
   * Additional className to apply to the wrapper div component.
   */
  className: PropTypes.string,

  /**
   * The array of available entities to show in the autocomplete dropdown.
   */
  entities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,

  /**
   * The label for the entity type (used for "+ Add [entityLabel]")
   */
  entityLabel: PropTypes.string.isRequired,

  /**
   * Called when a tag should be added.
   */
  handleAddition: PropTypes.func.isRequired,

  /**
   * Called when a tag should be deleted.
   */
  handleDelete: PropTypes.func.isRequired,

  shouldRenderAsInput: PropTypes.bool,

  /**
   * The list of currently selected tag entities.
   */
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

/**
 * The enhanaced version of the EntityTagSelect looks up all properties from state,
 * filters out selected entities from the autocomplete, and converts handleAddition and
 * handleDelete callbacks into a universal onChange callback.
 */
export default compose(
  setDisplayName('EntityTagSelect(enhanced'),
  setPropTypes({
    /**
     * An array of IDs of all entities to allow/display in the list
     */
    entityIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,

    /**
     * An optoinal label for the entity type (used for "+ Add [entityLabel]")
     */
    entityLabel: PropTypes.string,

    /**
     * The name of the property on the entity that is used as the tag "label"
     */
    entityNameProperty: PropTypes.string.isRequired,

    /**
     * The entity type (schema key) we are showing here.
     */
    entityType: PropTypes.string.isRequired,

    /**
     * The array of IDs of the entities that are currently selected.
     */
    value: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,

    /**
     * Called when the value should be changed.
     */
    onChange: PropTypes.func.isRequired,
  }),
  defaultProps({
    entityIds: [],
    entityNameProperty: 'name',
    value: [],
  }),
  // Conver `handleAddition` and `handleDelete` callbacks from the ReactTagAutocomplete into
  // a more standard `onChange` callback.
  withHandlers({
    handleAddition: ({ onChange, value }) => tag => {
      onChange(value ? value.concat(tag.id) : [tag.id]);
    },
    handleDelete: ({ onChange, value }) => index => {
      onChange(value.slice(0, index).concat(value.slice(index + 1)));
    },
  }),

  /**
   * Lookup the entitity names from state so we they can be passed into the ReactTagAutocomplete
   * in the expected format (`{ id, name }`)
   */
  connect(
    (
      state,
      { entityIds, entityLabel, entityNameProperty, entityType, value },
    ) => ({
      entityLabel:
        entityLabel || humanizeString(pluralize.singular(entityType)),
      entities: entityIds
        .filter(id => !value.includes(id))
        .map(id => ({
          id: id,
          name: getEntityProperty(state, entityType, id, entityNameProperty),
          hasChildren: getEntityProperty(state, entityType, id, 'has_children'),
        })),
      tags: value.map(id => ({
        id: id,
        name: getEntityProperty(state, entityType, id, entityNameProperty),
        hasChildren: getEntityProperty(state, entityType, id, 'has_children'),
      })),
    }),
    {},
  ),
)(EntityTagSelect);
