import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import getSearchTitle from '../selectors/getSearchTitle';

const SearchTitle = ({ jobTitle, title }) => (
  <span className='SearchTitle'>{jobTitle || title}</span>
);

SearchTitle.propTypes = {
  jobTitle: PropTypes.string,
  title: PropTypes.string,
};

export default compose(
  setDisplayName('SearchTitle(enhanced)'),
  setPropTypes({
    /**
     * The search ID of the search to show the title of.
     */
    searchId: PropTypes.number.isRequired,
  }),
  connect(
    (state, { searchId }) => ({
      title: getSearchTitle(state, searchId),
    }),
    null,
  ),
)(SearchTitle);
