import { Map } from 'immutable';

export const BASE_URL = '/api/notifications';

export const NOTIFICATIONS_CLEAR = 'NOTIFICATIONS_CLEAR';
export const NOTIFICATIONS_INVALIDATE = 'NOTIFICATIONS_INVALIDATE';
export const NOTIFICATIONS_INITIALIZE_TOTAL_COUNT =
  'NOTIFICATIONS_INITIALIZE_TOTAL_COUNT';
export const NOTIFICATIONS_FETCH_START = 'NOTIFICATIONS_FETCH_START';
export const NOTIFICATIONS_FETCH_SUCCESS = 'NOTIFICATIONS_FETCH_SUCCESS';
export const NOTIFICATIONS_FETCH_FAILURE = 'NOTIFICATIONS_FETCH_FAILURE';
export const NOTIFICATIONS_UPDATE_READ_START =
  'NOTIFICATIONS_UPDATE_READ_START';
export const NOTIFICATIONS_UPDATE_READ_SUCCESS =
  'NOTIFICATIONS_UPDATE_READ_SUCCESS';
export const NOTIFICATIONS_UPDATE_READ_FAILURE =
  'NOTIFICATIONS_UPDATE_READ_FAILURE';
export const NOTIFICATIONS_UPDATE_ALL_READ_START =
  'NOTIFICATIONS_UPDATE_ALL_READ_START';
export const NOTIFICATIONS_UPDATE_ALL_READ_SUCCESS =
  'NOTIFICATIONS_UPDATE_ALL_READ_SUCCESS';
export const NOTIFICATIONS_UPDATE_ALL_READ_FAILURE =
  'NOTIFICATIONS_UPDATE_ALL_READ_FAILURE';

export const NOTIFICATIONS_CREATED = 'NOTIFICATIONS_CREATED';

export const NOTIFICATIONS_CATEGORY_ALL = 'all';
export const NOTIFICATIONS_CATEGORY_UNREAD = 'unread';

export const NOTIFICATIONS_FILTERABLE_TYPES = {
  candidacy_assessment: {
    name: 'Assessments',
    filter: [
      { resourceType: 'CandidacyAssessment', key: 'create' },
      { resourceType: 'CandidacyAssessment', key: 'update' },
    ],
  },
  notes: {
    name: 'Notes',
    filter: [
      { resourceType: 'Note', key: 'create' },
      { resourceType: 'Note', key: 'create_private' },
    ],
  },
  outreach: {
    name: 'Outreach',
    filter: [{ resourceType: 'Outreach', key: 'create' }],
  },
  references: {
    name: 'References',
    filter: [{ resourceType: 'Reference', key: 'create' }],
  },
  intros: {
    name: 'Introductions',
    filter: [
      { resourceType: 'Introduction', key: 'create' },
      { resourceType: 'Introduction', key: 'introduced_to' },
    ],
  },
  rejections: {
    name: 'Rejected Reasons',
    filter: [
      { resourceType: 'Candidacy', key: 'rejected' },
      { resourceType: 'Comment', key: 'rejected' },
    ],
  },
  assessments: {
    name: 'Assessments',
    filter: [{ resourceType: 'Assessment', key: 'create' }],
  },
  comments: {
    name: 'Comments',
    filter: [{ resourceType: 'Comment', key: 'create', recordType: 'search' }],
  },
  interviews: {
    name: 'Interviews',
    filter: [{ resourceType: 'Appointment', subType: 'Interview' }],
  },
  meetings: {
    name: 'Meetings',
    filter: [{ resourceType: 'Appointment', subType: 'Meeting' }],
  },
  votes: {
    name: 'Priority / Not a Fit Votes',
    filter: [{ resourceType: 'Vote', recordType: 'search' }],
  },
  stageChange: {
    name: 'Move Stage',
    filter: [
      {
        resourceType: 'Candidacy',
        key: 'stage_changed',
        recordType: 'search',
      },
    ],
  },
  tasks: {
    name: 'Tasks',
    filter: [{ resourceType: 'Task', key: 'create' }],
  },
  add_candidate_to_search: {
    name: 'Add Candidate To Search',
    filter: [{ resourceType: 'Candidacy', key: 'add_candidate_to_search' }],
  },
  remove_candidate_from_search: {
    name: 'Remove Candidate From Search',
    filter: [
      { resourceType: 'Candidacy', key: 'remove_candidate_from_search' },
    ],
  },
};

const NOTIFICATIONS_GENERAL_ACTIVITY_TYPES = [
  'notes',
  'outreach',
  'meetings',
  'references',
  'intros',
  'tasks',
];

const NOTIFICATIONS_PIPELINE_ACTIVITY_TYPES = [
  'commentsPipeline',
  'votesPipeline',
  'stageChangePipeline',
];

// when a user is logged out `thv.user` will return {}
// {}.features[‘feature.events’] causes tests to fail
if (
  window?.thv?.user &&
  Object.keys(window?.thv?.user).length > 0 &&
  window?.thv?.user?.features['feature.events']
) {
  // It was necessary to add the flipper check here for 'events' notification
  // as we want to keep the definitions within the original source of truth
  // and not add/remove elsewhere.
  NOTIFICATIONS_GENERAL_ACTIVITY_TYPES.push('events');
  NOTIFICATIONS_FILTERABLE_TYPES.events = {
    name: 'Events',
    filter: [{ resourceType: 'EventContact', key: 'create' }],
  };
}

export const NOTIFICATIONS_FILTER_SECTIONS_FOR_COMPANY_ACTIVITY = [
  {
    section: 'General Activity',
    types: ['notes', 'outreach', 'meetings', 'references', 'intros'],
  },
  {
    section: 'Search Activity',
    types: ['interviews'],
  },
];

export const NOTIFICATIONS_FILTER_SECTIONS_WITHOUT_LEGACY_ASSESSMENTS = [
  {
    section: 'General Activity',
    types: NOTIFICATIONS_GENERAL_ACTIVITY_TYPES,
  },
  {
    section: 'Search Activity',
    types: [
      'rejections',
      'candidacy_assessment',
      'comments',
      'interviews',
      'votes',
      'stageChange',
    ],
  },
];

export const NOTIFICATIONS_FILTER_SECTIONS_WITHOUT_SEARCH_ACTIVITY = [
  {
    section: 'General Activity',
    types: NOTIFICATIONS_GENERAL_ACTIVITY_TYPES,
  },
];

export const NOTIFICATIONS_FILTER_SECTIONS = [
  {
    section: 'General Activity',
    types: NOTIFICATIONS_GENERAL_ACTIVITY_TYPES,
  },
  {
    section: 'Search Activity',
    types: [
      'rejections',
      'assessments',
      'comments',
      'interviews',
      'votes',
      'stageChange',
    ],
  },
];

if (
  window?.thv?.user &&
  Object.keys(window?.thv?.user).length > 0 &&
  window?.thv?.user?.features['feature.pipeline_mvp']
) {
  // It was necessary to add the flipper check here for 'Pipeline' notification
  NOTIFICATIONS_FILTERABLE_TYPES.votesPipeline = {
    name: 'Priority / Not a Fit Votes',
    filter: [{ resourceType: 'Vote', recordType: 'pipeline' }],
  };
  NOTIFICATIONS_FILTERABLE_TYPES.stageChangePipeline = {
    name: 'Move Stage',
    filter: [
      {
        resourceType: 'Candidacy',
        key: 'stage_changed',
        recordType: 'pipeline',
      },
    ],
  };
  NOTIFICATIONS_FILTERABLE_TYPES.commentsPipeline = {
    name: 'Comments',
    filter: [
      { resourceType: 'Comment', key: 'create', recordType: 'pipeline' },
    ],
  };

  const pipelineActivitySection = {
    section: 'Pipeline Activity',
    types: NOTIFICATIONS_PIPELINE_ACTIVITY_TYPES,
  };
  NOTIFICATIONS_FILTER_SECTIONS_WITHOUT_LEGACY_ASSESSMENTS.push(
    pipelineActivitySection,
  );
  NOTIFICATIONS_FILTER_SECTIONS.push(pipelineActivitySection);
}

export const NOTIFICATIONS_STATE__ALL_SELECTED = new Map(
  NOTIFICATIONS_FILTERABLE_TYPES,
).map(type => Boolean(type.filter));

export const NOTIFICATIONS_STATE__NONE_SELECTED = new Map(
  NOTIFICATIONS_FILTERABLE_TYPES,
).map(() => false);
