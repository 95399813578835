import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import useFormValues from '@thrivetrm/ui/hooks/useFormValues';
import useUpdateFormField from '@thrivetrm/ui/hooks/useUpdateFormField';
import Avatar from '@thrivetrm/ui/components/Avatar';
import FormAsyncSelect from 'modules/core/components/FormAsyncSelect';
import routes from 'modules/routing/routes';
import SkyminyrCompanyDetailsCard from './SkyminyrCompanyDetailsCard';
import NewCompanyFormModal from '../NewCompanyFormModal';

export const formatOptions = option => {
  const industriesAndWebsite = [
    option.enriched_sectors?.slice(0, 3)?.join(', ') || null,
    option.website_url,
  ]
    .filter(Boolean)
    .join(' • ');

  return {
    label: (
      <div className='SkyminyrCompanySelect__option'>
        <Avatar
          className='SkyminyrCompanySelect__avatar u-marginRight-8'
          image={option.avatar}
          name={option.name}
          shape='square'
          size='small'
        />
        <div>
          <p className='u-margin-n u-textColor-gray60'>{option.name}</p>
          {industriesAndWebsite ? (
            <p className='u-margin-n u-textColor-gray40'>
              {industriesAndWebsite}
            </p>
          ) : null}
        </div>
      </div>
    ),
    value: option.id || option.skyminyr_id,
    data: option,
  };
};

const SkyminyrCompanySelect = ({
  canCreateNewCompany,
  canSelectThriveRecords,
  className,
  initialInputValue,
  initialValue,
  onCreate,
  queryParams,
  shouldNavigateToNewCompany,
}) => {
  const formValues = useFormValues();
  const updateFormField = useUpdateFormField();
  const [inputValue, setInputValue] = useState(initialInputValue || '');
  const [
    isCreateNewCompanyModalOpen,
    openCreateNewCompanyModal,
    closeCreateNewCompanyModal,
  ] = useToggle(false);
  const [
    isFetchingGoldenRecords,
    fetchGoldenRecords,
    fetchSimpleRecords,
  ] = useToggle(true);

  const handleInputChange = (newInputValue, { action }) => {
    if (action === 'input-change') {
      setInputValue(newInputValue);
    }
  };

  const menuListFooter =
    inputValue?.trim() !== '' &&
    (canSelectThriveRecords || canCreateNewCompany) ? (
      <div className='u-padding-4 u-paddingLeft-12'>
        {canSelectThriveRecords ? (
          <div className='u-flex u-flexAlign-c'>
            <ButtonLink
              label={
                isFetchingGoldenRecords
                  ? 'Show other records in database'
                  : 'Show golden records'
              }
              onClick={
                isFetchingGoldenRecords
                  ? fetchSimpleRecords
                  : fetchGoldenRecords
              }
            />
          </div>
        ) : null}
        {canCreateNewCompany ? (
          <div className='u-flex u-flexAlign-c u-marginTop-4 u-wordBreak'>
            <ButtonSecondary
              className='SkyminyrCompanySelect__companyCreateButton u-marginRight-4'
              icon='add'
              onClick={openCreateNewCompanyModal}
              size='small'
            />
            Create a new company &quot;{inputValue}&quot;
          </div>
        ) : null}
      </div>
    ) : null;

  const getCompanySearchQuery = searchTerm => ({
    query: {
      query: {
        resource: 'company',
        term: searchTerm,
        ...(isFetchingGoldenRecords ? {} : queryParams),
      },
    },
  });

  const selectedCompany = formValues?.company?.data;

  return (
    <>
      <FormAsyncSelect
        className={classNames(className, {
          'u-hidden': selectedCompany?.id || selectedCompany?.skyminyr_id,
        })}
        formatOption={formatOptions}
        initialInputValue={initialInputValue}
        initialValue={initialValue}
        inputValue={inputValue}
        label='Company'
        menuListFooter={menuListFooter}
        name='company'
        onInputChange={handleInputChange}
        placeholder='Enter company name'
        refreshOptionsToken={
          isFetchingGoldenRecords ? 'GoldenToken' : 'SimpleToken'
        }
        rules={{
          required: { value: true, message: 'Selecting a company is required' },
        }}
        url={searchTerm =>
          isFetchingGoldenRecords
            ? routes.api_v1_autocomplete_query_skyminyr(
                getCompanySearchQuery(searchTerm),
              )
            : routes.api_v1_autocomplete_query(
                getCompanySearchQuery(searchTerm),
              )
        }
      />
      {selectedCompany ? (
        <SkyminyrCompanyDetailsCard
          companyDetails={selectedCompany}
          onUnselect={() => {
            updateFormField('company', null);
          }}
        />
      ) : null}
      {isCreateNewCompanyModalOpen ? (
        <NewCompanyFormModal
          companyName={inputValue}
          onClose={newCompanyId => {
            setInputValue('');
            closeCreateNewCompanyModal();
            if (newCompanyId) {
              onCreate?.(newCompanyId);
            }
          }}
          onCloseCreateCompanyModal={() => {}}
          queryDuplicates={true}
          shouldNavigateToNewCompany={shouldNavigateToNewCompany}
          show={isCreateNewCompanyModalOpen}
        />
      ) : null}
    </>
  );
};

SkyminyrCompanySelect.defaultProps = {
  canCreateNewCompany: false,
  canSelectThriveRecords: false,
  initialValue: null,
  queryParams: null,
  shouldNavigateToNewCompany: true,
};

SkyminyrCompanySelect.propTypes = {
  canCreateNewCompany: PropTypes.bool,
  canSelectThriveRecords: PropTypes.bool,
  className: PropTypes.string,
  initialInputValue: PropTypes.string,
  initialValue: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }),
  onCreate: PropTypes.func,
  queryParams: PropTypes.shape({
    resource: PropTypes.string,
  }),
  shouldNavigateToNewCompany: PropTypes.bool,
};

export default SkyminyrCompanySelect;
