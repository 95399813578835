import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes, withProps } from 'recompose';
import PositionList from 'modules/contacts/components/positions/PositionList';
import withPositionListFetched from 'modules/contacts/components/positions/withPositionListFetched';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import withTargetCompanyListFetched from 'modules/target-companies/components/withTargetCompanyListFetched';
import mapActiveTargetCompanyIdsToCompany from 'modules/target-companies/components/mapActiveTargetCompanyIdsToCompany';

/**
 * Renders a list of positions in the contact of a candidacy (contact + search).
 * This also fetches target companies so we can show the company name with a target next to it
 * if the company in question is a target company in the search.
 */
export const CandidacyPositionsPanel = ({
  companyIds,
  positionList,
  renderDescriptions,
  renderIfNoData,
  renderTitles,
  targetCompanyList,
  title,
  ...props
}) => {
  const hasPositionData = positionList && positionList.get('ids')?.size > 0;
  const hasTargetCompaniesData =
    targetCompanyList && targetCompanyList.get('ids');

  if (!renderIfNoData && !(hasPositionData && hasTargetCompaniesData)) {
    return null;
  }

  const isFetching =
    (positionList && positionList.getIn(['_meta', 'isFetching'])) ||
    (targetCompanyList && targetCompanyList.getIn(['_meta', 'isFetching']));

  // We don't want to render anything until we have both positions AND target Companies -- otherwise
  // we may initially render a position without it's target company indicator, and then it will
  // just suddently pop into existence once the target companies successfully load.
  return (
    <div className='contacts--candidacy-positions'>
      {title && <h3>{title}</h3>}
      {hasTargetCompaniesData && hasPositionData && (
        <PositionList
          {...props}
          formLayout='narrow'
          positionIds={positionList.get('ids')}
          renderDescriptions={renderDescriptions}
          renderInsideACard={true}
          renderTitles={renderTitles}
          targetedCompanyIds={companyIds}
        />
      )}
      {isFetching && <LoadingIndicator />}
    </div>
  );
};

CandidacyPositionsPanel.propTypes = {
  /**
   * The list of company IDs that are target companies of the related search.
   * Note that these are the actual company IDs, and should not be confused with the
   * "target company ID", which is a different ID.
   */
  companyIds: ImmutablePropTypes.listOf(PropTypes.number),

  /**
   * The list of positions to render.
   */
  positionList: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isFetching: PropTypes.bool,
    }),
    ids: ImmutablePropTypes.listOf(PropTypes.number),
  }),

  /**
   * True to render the descriptions in the position list; otherwise they will not be shown
   */
  renderDescriptions: PropTypes.bool,

  /**
   * When false, this component will render `null` if there is no position data; otherwise it
   * will always render at least the outer div (and title, if the title has a value).
   */
  renderIfNoData: PropTypes.bool,

  /**
   * True to render the titles for each list item, otherwise they will not be rendered.
   */
  renderTitles: PropTypes.bool,

  /**
   * The list of target companies that belong to the search.
   */
  targetCompanyList: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isFetching: PropTypes.bool,
    }),
  }),

  /**
   * An optional title to display at the top of the component.
   */
  title: PropTypes.string,
};

CandidacyPositionsPanel.defaultProps = {
  renderDescriptions: false,
  renderIfNoData: true,
  renderTitles: false,
  title: 'Experience',
};

export default compose(
  setDisplayName('connect(CandidacyPositionsPanel)'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,

    // not isRequired at the moment because sometimes this gets rendered before the search is
    // loaded.
    searchId: PropTypes.number,
  }),

  // We need both positions and target companies for this particular component so that we can
  // highlight targeted companies in the list.
  withPositionListFetched,
  withTargetCompanyListFetched,

  // Map the IDs from the list so they an be used by mapTargetCompanyIdsToCompanyIds
  withProps(({ targetCompanyList }) => ({
    targetCompanyIds: targetCompanyList && targetCompanyList.get('ids'),
  })),

  // We don't particularly care so much about targetCompanyIds, we really need the underlying
  // company ids.
  mapActiveTargetCompanyIdsToCompany,
)(CandidacyPositionsPanel);
