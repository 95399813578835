import PropTypes from 'prop-types';
import {
  compose,
  setDisplayName,
  setPropTypes,
  setStatic,
  wrapDisplayName,
} from 'recompose';
import FieldState from '../FieldState';

import asField from './asField';
import withFormGroup from './withFormGroup';

/**
 * A higher-order function generator for wrapping a component in a form group and
 * converting it's value to a fieldState.
 */
export default Component =>
  compose(
    setDisplayName(wrapDisplayName(Component, 'field')),
    setPropTypes({
      fieldState: PropTypes.instanceOf(FieldState).isRequired,
      onChange: PropTypes.func.isRequired,
    }),
    setStatic(
      'createFieldState',
      Component.createFieldState || FieldState.create,
    ),
    withFormGroup,
    asField(),
  )(Component);
