import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '@thrivetrm/ui/components/Icon';
import ConfirmationPopoverButton from '../componentsLegacy/ConfirmationPopoverButton';

const MAX_HIERARCHY_DEPTH = 2;

/**
 * Truncate any hierarchical tag nodes between the root node and the leaf node.
 *
 * 'Baking' yields 'Baking'
 * 'Baking > Biscuits' yields 'Baking > Biscuits'
 * 'Baking > Biscuits > Savoury' yields 'Baking ... Savoury'
 * 'Baking > Biscuits > Savoury > Packaged' yields 'Baking ... Packaged'
 */
const formatTag = tag => {
  const tagNodes = tag.split(' > ');
  const rootNode = tagNodes[0];
  const leafNode = tagNodes[tagNodes.length - 1];

  if (tagNodes.length === 1) {
    return <b>{leafNode}</b>;
  } else if (tagNodes.length === MAX_HIERARCHY_DEPTH) {
    return (
      <>
        {rootNode} {'>'} <b>{leafNode}</b>
      </>
    );
  } else {
    return (
      <>
        {rootNode} ... <b>{leafNode}</b>
      </>
    );
  }
};

const Tag = ({ className, name, onDelete, shouldConfirmDelete, ...props }) => {
  const tagClass = classnames('Tag', className);

  const isDeletable = Boolean(onDelete);
  const deleteIcon = <Icon color='blue' size='small' type='close' />;

  return (
    <div {...props} className={tagClass}>
      <span className='Tag__name' title={name}>
        {name ? formatTag(name) : null}
      </span>
      {isDeletable && shouldConfirmDelete ? (
        <ConfirmationPopoverButton
          className='Tag__deleteButton'
          label={deleteIcon}
          onConfirm={() => onDelete(name)}
        >
          Are you sure you want to delete <strong>{name}</strong>?
        </ConfirmationPopoverButton>
      ) : null}

      {isDeletable && !shouldConfirmDelete ? (
        <button
          className='Tag__deleteButton'
          onClick={() => onDelete(name)}
          type='button'
        >
          {deleteIcon}
        </button>
      ) : null}
    </div>
  );
};

Tag.defaultProps = {
  className: null,
  onDelete: null,
  shouldConfirmDelete: true,
};

Tag.propTypes = {
  /** A classname to render on the root node */
  className: PropTypes.string,

  /** The tag to render */
  name: PropTypes.string.isRequired,
  /**
   * Function called when the tag is deleted. If provided, renders a delete button.
   * Called with the tag name.
   */
  onDelete: PropTypes.func,
  /**
   * A boolean that determined if there should be a confirmation popover when
   * a tag is deleted or not.
   */
  shouldConfirmDelete: PropTypes.bool,
};

export default Tag;
