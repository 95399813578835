//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'searchStages';

//---------------------------------------------------------
// Stages
//---------------------------------------------------------

export const STAGE_TYPE_JOB_SEARCH = 'JobSearchStage';
export const STAGE_TYPE_PIPELINE = 'PipelineStage';
export const STAGE_TYPE_TALENT_POOL = 'TalentPoolStage';

/**
 * All valid STAGE_TYPE_* constants.
 * @const {Array}
 */
export const STAGE_TYPES = [
  STAGE_TYPE_JOB_SEARCH,
  STAGE_TYPE_PIPELINE,
  STAGE_TYPE_TALENT_POOL,
];

//---------------------------------------------------------
// Stages filters
//---------------------------------------------------------

/**
 * A constant to indicate a "active" stages.
 */
export const STAGE_FILTER_ACTIVE = 'active';

/**
 * No filtering
 */
export const STAGE_FILTER_NONE = 'none';
