import { Map } from 'immutable';
import { NAME } from './constants';
import {
  TRANSACTION_START,
  TRANSACTION_FINISH,
  TRANSACTION_CLEAR,
} from './actions/actionTypes';

const INITIAL_STATE = new Map();

/**
 * @typedef {Object} TransactionState
 * @property {Boolean} isFinished A value indicating whether the transaction has finished.
 * @property {Object} [payload] An optional payload to store with the transaction.
 */

/**
 * The transactions state is simply a map of transactionId values to a TransactionState object.
 *
 * Transaction IDs are user supplied, and all transaction actions should include the
 * `transactionId` property. It's recommended that IDs be generated using uniqueId
 * from the core module:
 *    `uniqueId('optionalPrefix')`
 *
 * When a transaction is started (by a TRANSACTION_START action), it's `isFinished` property
 * is set to false, and when it is finished (by a TRANSACTION_FINISH action), it's `isFinished`
 * property is set to true. Clearing a transaction (by a TRANSACTION_CLEAR action) removes it
 * from the state entirely. It is expected that anything that starts a transaction should
 * ALWAYS clear the transaction when it has finished with it, to prevent lingering transactions
 * being retained that are no longer needed.
 *
 * @param {Immutable.Map} [state] The initial state
 * @param {Object} action The action to apply to the initial state.
 * @return {Immutable.Map} The resulting state after applying the action to the initial state.
 */
const transactionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TRANSACTION_START:
      return state.set(action.transactionId, {
        id: action.transactionId,
        isFinished: false,
        payload: action.payload,
      });
    case TRANSACTION_FINISH:
      return state.set(action.transactionId, {
        id: action.transactionId,
        isFinished: true,
        payload: action.payload,
        finishTime: Date.now(),
      });
    case TRANSACTION_CLEAR:
      return state.delete(action.transactionId);
    default:
      return state;
  }
};

transactionsReducer.NAME = NAME;

export default transactionsReducer;
