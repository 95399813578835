import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import emailTemplatesLists from './emailTemplatesLists';

const emailTemplatesReducer = combineImmutableReducers({
  emailTemplatesLists: emailTemplatesLists,
});

emailTemplatesReducer.NAME = NAME;

export default emailTemplatesReducer;
