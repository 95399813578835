import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
/**
 * Returns a function that creates a higher-order component wrapping
 * a Form, which allows the visibility of the form to be toggled on and off.
 *
 * This component will also maintain the `FormState` when the form is closed
 * by way of the "cancel" button, and pass it back in if the form is shown
 * again. This way, if a user begins filling out a form, then presses cancel,
 * then reopens the form, it will keep their existing changes.
 *
 * @param {Component} FormComponent The form to render.
 * @return {Function} [description] A function that can be used to return
 *   a higher-order component.
 */
const withFormToggleButton = FormComponent => {
  class FormToggleButton extends PureComponent {
    constructor(props) {
      super(props);

      this.state = {
        lastFormState: null,

        /**
         * True to display the form (and hide the button), false to show
         * the button only.
         * @type {Boolean}
         */
        isFormOpen: Boolean(props.initiallyOpenForm),
      };
    }

    /**
     * Called when toggle button is clicked, and toggles the display of the
     * FormComponent.
     */
    handleToggleButtonClick = () => this.toggleForm();

    /**
     * Called when the form is saved, causing the form to be hidden and the
     * button to be redisplayed.
     */
    handleSaved = () => {
      const { onSaved } = this.props;

      // Make sure to clear out any form state so the next time the form is
      // opened it doesn't get populated with old form data.
      this.setState({
        lastFormState: null,
      });

      this.toggleForm(false);

      if (onSaved) {
        onSaved();
      }
    };

    /**
     * Called when the form is cancelled, causing the form to be hidden and the
     * button to be redisplayed, and the optional field values to be stored in
     * case the form is reshown.
     * @param  {FormState} formState The state of the form when it was closed,
     *   which will be used to repopulate the form when/if it is shown again.
     */
    handleCancel = lastFormState => {
      const { onCancel } = this.props;
      this.setState({
        lastFormState: lastFormState,
      });

      if (onCancel) {
        onCancel();
      }

      this.toggleForm(false);
    };

    /**
     * Toggles the display of the form.
     * @param  {Boolean?} open If provided, whether the form should be shown.
     *   If not provided, the state will be toggled from it's current state.
     */
    toggleForm(open) {
      const { onToggle } = this.props;
      const { isFormOpen: isFormOpenCurrent } = this.state;

      // The isFormOpen vaue that we want to change to.
      const isFormOpen =
        typeof open !== 'undefined' ? open : !isFormOpenCurrent;

      if (isFormOpenCurrent !== isFormOpen) {
        if (onToggle) {
          onToggle(isFormOpen);
        }

        this.setState({
          isFormOpen: isFormOpen,
        });
      }
    }

    render() {
      const { isFormOpen, lastFormState } = this.state;
      const {
        // The label/button contents
        btnClassName,
        btnIcon,
        btnLabel,
        btnSize,
        children,
        className,
        // Prevent these from being included in formProps
        /* eslint-disable no-unused-vars */
        initiallyOpenForm,
        onToggle,
        renderTitle,
        shouldRenderV4Button,
        size,
        /* eslint-enable no-unused-vars */

        ...formProps
      } = this.props;

      if (typeof children === 'string' && !formProps.title && renderTitle) {
        formProps.title = children;
      }

      return isFormOpen ? (
        <FormComponent
          {...formProps}
          initialFormState={lastFormState}
          onCancel={this.handleCancel}
          onSaved={this.handleSaved}
        />
      ) : (
        <div
          className={classnames(className, {
            'form-toggle': !shouldRenderV4Button,
          })}
        >
          {shouldRenderV4Button ? (
            <ButtonPrimary
              className={`form-toggle-button ${btnClassName}`}
              data-testid='form toggle button'
              icon={btnIcon}
              isOutline={true}
              label={btnLabel}
              onClick={this.handleToggleButtonClick}
              size={size}
            />
          ) : (
            <button
              className={classnames('form-toggle-button', 'btn', btnClassName, {
                [`btn-${btnSize}`]: Boolean(btnSize),
              })}
              data-testid='form toggle button'
              onClick={this.handleToggleButtonClick}
              type='button'
            >
              {children}
            </button>
          )}
        </div>
      );
    }
  }

  FormToggleButton.propTypes = {
    /**
     * className to apply to the button.
     */
    btnClassName: PropTypes.string,

    btnIcon: PropTypes.string,

    btnLabel: PropTypes.string,
    /**
     * The size modified to apply to the button
     */
    btnSize: PropTypes.oneOf(['lg', 'sm', 'xs']),

    /**
     * The content to display in the button.
     */
    children: PropTypes.node,

    className: PropTypes.string,

    initiallyOpenForm: PropTypes.bool,

    /**
     * Called when the underlying form was cancelled.
     */
    onCancel: PropTypes.func,

    /**
     * Called when the underlying form was successfully submitted.
     */
    onSaved: PropTypes.func,

    /**
     * A callback called when the form display is toggled. Called with `true`
     * if the form is to be displayed, `false` if it is to be hidden.
     */
    onToggle: PropTypes.func,

    /**
     * Whether to render the form title (true by default).
     * Since the toggle button text is rendered as a title in the absence
     * of a title prop, this allows the choice of rendering no title at all.
     */
    renderTitle: PropTypes.bool,

    shouldRenderV4Button: PropTypes.bool,

    size: PropTypes.oneOf(['small', 'medium', 'large']),
  };

  FormToggleButton.defaultProps = {
    btnClassName: 'btn-secondary',
    renderTitle: true,
    shouldRenderV4Button: false,
    size: 'small',
  };

  return FormToggleButton;
};

export default withFormToggleButton;
