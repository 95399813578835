import selectn from 'selectn';
import getAssessmentProperty from 'modules/assessments/selectors/getAssessmentProperty';
import { assessmentSchema } from 'modules/assessments/schema';
import merge from 'modules/entities/actions/merge';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import isEntityType from 'modules/entities/isEntityType';
import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import { candidacySchema } from '../schemas';

/**
 * When an assessment is created or deleted it updats the candidacy so
 * the assessments_count is updated in real time.
 */
export default ({ action, dispatch, getState }) => {
  if (isEntityType(action, assessmentSchema.key)) {
    switch (action.type) {
      case entityActionTypes.CREATE_SUCCESS:
      case entityActionTypes.DELETE_SUCCESS: {
        const isDelete = action.type === entityActionTypes.DELETE_SUCCESS;
        const assessmentId = selectn(
          isDelete ? 'payload.id' : 'payload.result.assessment',
          action,
        );
        const state = getState();
        const candidacyId = getAssessmentProperty(
          state,
          assessmentId,
          'candidacy_id',
        );
        const assessmentCount = getEntityProperty(
          state,
          candidacySchema.key,
          candidacyId,
          'assessments_count',
        );
        const change = isDelete ? -1 : 1;

        if (candidacyId && typeof assessmentCount === 'number') {
          dispatch(
            merge({
              data: {
                id: candidacyId,
                assessments_count: assessmentCount + change,
              },
              schema: candidacySchema,
            }),
          );
        }
        break;
      }
      default: {
        // noop
      }
    }
  }
};
