import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import commentSchema from '../commentSchema';
import {
  COMMENT_LIST_FETCH_START,
  COMMENT_LIST_FETCH_SUCCESS,
  COMMENT_LIST_FETCH_FAILURE,
} from './actionTypes';
import getCommentListUrl from './getCommentListUrl';

/**
 * Fetches the list of comments for a parent
 * @param {Object} payload
 * @param {String} payload.parentId The ID of the parent to fetch the comments for (will depend on
 *   parentType)
 * @param {String} payload.parentType The type of parent to fetch the comments for (one of the
 *   values from PARENT_TYPES)
 */
export default compose(
  fetchAction,
  normalizeResponse([commentSchema]),
  createAsyncActions({
    startActionType: COMMENT_LIST_FETCH_START,
    successActionType: COMMENT_LIST_FETCH_SUCCESS,
    failureActionType: COMMENT_LIST_FETCH_FAILURE,
  }),
  withOptions(({ parentId, parentType }) => ({
    url: getCommentListUrl({ parentType: parentType, parentId: parentId }),
  })),
);
