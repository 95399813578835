import { fromJS, Map } from 'immutable';
import { SET_PREFERENCE } from '../actions/actionTypes';
import { PREFERENCES_STORAGE_KEY } from '../constants';
import { load, save } from '../persistence';

/**
 * Manages key-value pairs of individual application preferences.
 */
export default function preferences(
  state = load(PREFERENCES_STORAGE_KEY),
  action,
) {
  if (!Map.isMap(state)) {
    // When the store is first initialized, the user states comes in as
    // a standard javascript Object, so we need to convert it to an Immutable
    // Map
    return fromJS(state);
  }

  switch (action.type) {
    case SET_PREFERENCE: {
      const { key, value } = action.payload;
      const keyPath = Array.isArray(key) ? key : [key];
      const newState = state.setIn(keyPath, value);

      // Also save the new state to local storage
      save(PREFERENCES_STORAGE_KEY, newState.toJS());
      return newState;
    }
    default: {
      return state;
    }
  }
}
