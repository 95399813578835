import { fromJS, Map } from 'immutable';
import {
  INVITE_TOKEN_CREATE_START,
  INVITE_TOKEN_CREATE_SUCCESS,
  INVITE_TOKEN_CREATE_FAILURE,
} from '../actions/inviteTokens/actionTypes';

/**
 * Maintains a map of invite tokens keyed by candidacy IDs.
 * In addition to the standard `_meta` key, each value should have a
 * `url` and `token` value once "created". A create is actually idempotent
 * here -- so additional CREATEs will return the same created token.
 *
 * NOTE: This should probably be moved into it's own module at some point.
 * Currently we are only using these for "Candidacy" invites, but the
 * actual implementation of this is generic and will likely be used for
 * creating additional types of invite tokens in the future. So when that time
 * comes, please move this to it's own module.
 */
export default (state = new Map(), action) => {
  switch (action.type) {
    case INVITE_TOKEN_CREATE_START: {
      return state.setIn(
        [action.payload.candidacyId, '_meta', 'isFetching'],
        true,
      );
    }
    case INVITE_TOKEN_CREATE_SUCCESS: {
      return state.mergeDeepIn(
        [action.payload.candidacyId],
        fromJS({
          _meta: {
            isFetching: false,
          },
          ...action.payload.response,
        }),
      );
    }
    case INVITE_TOKEN_CREATE_FAILURE: {
      return state.mergeDeepIn(
        [action.payload.candidacyId, '_meta'],
        fromJS({
          isFetching: false,
          error: action.payload.error,
        }),
      );
    }
    default: {
      return state;
    }
  }
};
