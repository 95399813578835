/* eslint-disable react/jsx-props-no-spreading */
// ^ Since this is a wrapper component, we just want to pass everything through
import React, { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import BootstrapModal from 'react-bootstrap/lib/Modal';
import PropTypes from 'prop-types';
import {
  hideInAppHelpButton,
  showInAppHelpButton,
} from '@thrivetrm/ui/utilities/inAppHelpUtils';

// This is a wrapper component to set global configuration around Bootstrap Modals.
// This will be phased out once an alternative to bootstrap is decided upon.
const Modal = ({ children, ...props }) => {
  // Hide the in-app help button so it doesn't collide with modal content
  useEffect(() => {
    if (props.show) {
      hideInAppHelpButton();
    } else {
      showInAppHelpButton();
    }

    return showInAppHelpButton;
  }, [props.show]);

  return (
    <BootstrapModal {...props} backdrop='static'>
      {children}
    </BootstrapModal>
  );
};

Modal.Header = BootstrapModal.Header;
Modal.Body = BootstrapModal.Body;
Modal.Title = BootstrapModal.Title;
Modal.Footer = BootstrapModal.Footer;

Modal.defaultProps = {
  show: false,
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  show: PropTypes.bool,
};

export default Modal;
