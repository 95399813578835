import { connect } from 'react-redux';
import * as filterUtils from 'modules/core/filterUtils';
import getCandidacyTextForFiltering from '../selectors/getCandidacyTextForFiltering';

/**
 * A Higher order component that filters a list of candidacyIds based on a `filterText`
 * value. It also adds an `isFiltered` prop so the base component can know when it's candidacyIds
 * have been filtered.
 */
export default connect((state, { candidacyIds, filterText, isFiltered }) => {
  if (
    !filterText ||
    !filterText.trim() ||
    !candidacyIds ||
    candidacyIds.size < 1
  ) {
    return {};
  }

  const filterRegex = filterUtils.createMatchAllKeywordsRegex(filterText);

  return {
    isFiltered: isFiltered || true,
    candidacyIds: candidacyIds.filter(candidacyId =>
      filterRegex.test(getCandidacyTextForFiltering(state, candidacyId)),
    ),
  };
}, {});
