//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'tasks';

//---------------------------------------------------------
// Groupings
//---------------------------------------------------------

export const MY_TASKS = 'myTasks';
export const ASSIGNED_TASKS = 'assignedTasks';

//---------------------------------------------------------
// Sorting
//---------------------------------------------------------

export const SORT_BY_DUE_DATE = 'dueDate';
export const SORT_BY_ASSOCIATED_RECORD = 'associatedRecord';
export const SORT_BY_TITLE = 'title';

export const SORT_BY_LABELS = {
  [SORT_BY_ASSOCIATED_RECORD]: 'Associated Contact',
  [SORT_BY_DUE_DATE]: 'Due Date',
  [SORT_BY_TITLE]: 'Task',
};

export const SORT_BY_OPTIONS = Object.keys(SORT_BY_LABELS);
