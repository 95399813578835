import selectn from 'selectn';
import companySchema from 'modules/companies/companySchema';
import { REVENUE_LIST_UPDATE_SUCCESS } from '../actions/actionTypes';

/**
 * When company entity is triggered this will also trigger an update
 * for the revenues
 */
export default ({ action, dispatch }) => {
  const companyEntities = selectn('payload.entities.companies', action);

  if (companyEntities) {
    Object.keys(companyEntities)
      .map(id => companyEntities[id])
      .filter(companyEntity => companyEntity.revenues)
      .forEach(companyEntity => {
        dispatch({
          type: REVENUE_LIST_UPDATE_SUCCESS,
          payload: {
            companyId: companySchema.getId(companyEntity),
            result: {
              revenues: companyEntity.revenues,
            },
          },
        });
      });
  }
};
