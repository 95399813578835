/**
 * A simple implementation for comparing 2 values.
 * @param {*} a The first value
 * @param {*} b The second value
 * @returns {Number} 1 if `a` is greater than `b`, -1 if `b` is greater than `a`, 0 if both values
 *   are equal.
 */
export const defaultComparator = (a, b) => {
  if (a === undefined && b === undefined) {
    return 0;
  }

  if (a === undefined) {
    return 1;
  }

  if (b === undefined) {
    return -1;
  }

  if (a > b) {
    return 1;
  }

  if (a < b) {
    return -1;
  }

  return 0;
};

/**
 * A comparison implementation that can compare two arrays of values. As soon as two values
 * at the same index are different, that result is returned as the final result of the comparison.
 *
 * @example
 * ```
 * arrayComparitor([1, 2, 3], [1, 2, 3]) // 0
 * arrayComparitor([1, 1, 3], [1, 2, 3]) // -1
 * arrayComparitor(["alpha", 2], ["alpha" 1]) // 1
 * ```
 */
export const arrayComparator = (a, b) => {
  if (Array.isArray(a) && Array.isArray(b)) {
    let result = 0;
    let index = 0;

    while (result === 0 && index < a.length) {
      result = defaultComparator(a[index], b[index]);
      index += 1;
    }

    return result;
  }

  return defaultComparator(a, b);
};
