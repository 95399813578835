import selectn from 'selectn';
import { Map } from 'immutable';
import createListReducer from '../../../reducers/createListReducer';
import {
  INTRODUCTION_LIST_INVALIDATE,
  INTRODUCTION_LIST_FETCH_START,
  INTRODUCTION_LIST_FETCH_SUCCESS,
  INTRODUCTION_LIST_FETCH_FAILURE,
  INTRODUCTION_CREATE_SUCCESS,
  INTRODUCTION_UPDATE_SUCCESS,
  INTRODUCTION_DELETE_SUCCESS,
} from '../actions/actionTypes';
import { PARENT_SEARCH, PARENT_CONTACT } from '../constants';

const introductionListReducer = createListReducer({
  resultKey: 'introductions',
  entityResultKey: 'introduction',
  request: INTRODUCTION_LIST_FETCH_START,
  success: INTRODUCTION_LIST_FETCH_SUCCESS,
  failure: INTRODUCTION_LIST_FETCH_FAILURE,
  invalidated: INTRODUCTION_LIST_INVALIDATE,
  created: INTRODUCTION_CREATE_SUCCESS,
  updated: INTRODUCTION_UPDATE_SUCCESS,
  deleted: INTRODUCTION_DELETE_SUCCESS,
});

// References are keyed on the parent type (PARENT_SEARCH or PARENT_CONTACT),
// then by that parent's ID (a contact ID for PARENT_CONTACT or a candidacy ID for
// PARENT_SEARCH).
export default (state = new Map(), action) => {
  switch (action.type) {
    case INTRODUCTION_LIST_FETCH_START:
    case INTRODUCTION_LIST_FETCH_SUCCESS:
    case INTRODUCTION_LIST_FETCH_FAILURE: {
      const parentType = selectn('payload.parentType', action);
      const parentId = selectn('payload.parentId', action);

      if (parentType && parentId) {
        return state.updateIn([parentType, parentId], list =>
          introductionListReducer(list, action),
        );
      }

      return state;
    }
    case INTRODUCTION_LIST_INVALIDATE: {
      const contactId = selectn('payload.contactId', action);
      const searchId = selectn('payload.searchId', action);
      let result = state;

      if (contactId && state.hasIn([PARENT_CONTACT, contactId])) {
        result = result.setIn(
          [PARENT_CONTACT, contactId, '_meta', 'isInvalidated'],
          true,
        );
      }

      if (searchId && state.hasIn([PARENT_SEARCH, searchId])) {
        result = result.setIn(
          [PARENT_SEARCH, searchId, '_meta', 'isInvalidated'],
          true,
        );
      }

      return result;
    }
    case INTRODUCTION_CREATE_SUCCESS: {
      // Check if the newly created introduction belongs to any currently fetched lists.
      const introductionId = selectn('payload.result.introduction', action);
      const introduction = selectn(
        `payload.entities.introductions.${introductionId}`,
        action,
      );

      if (introduction) {
        return state.withMutations(mutable => {
          if (mutable.hasIn([PARENT_CONTACT, introduction.contact, 'ids'])) {
            mutable.updateIn(
              [PARENT_CONTACT, introduction.contact, 'ids'],
              ids => ids.push(introductionId),
            );
          }

          if (
            mutable.hasIn([PARENT_CONTACT, introduction.introduced_to, 'ids'])
          ) {
            mutable.updateIn(
              [PARENT_CONTACT, introduction.introduced_to, 'ids'],
              ids => ids.push(introductionId),
            );
          }

          if (
            introduction.search &&
            mutable.hasIn([PARENT_SEARCH, introduction.search, 'ids'])
          ) {
            mutable.updateIn([PARENT_SEARCH, introduction.search, 'ids'], ids =>
              ids.push(introductionId),
            );
          }
        });
      }

      return state;
    }
    default: {
      return state.map(parentTypeState =>
        parentTypeState.map(listState =>
          introductionListReducer(listState, action),
        ),
      );
    }
  }
};
