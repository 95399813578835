import React from 'react';
import PropTypes from 'prop-types';
import { relativeDate } from '@thrivetrm/ui/utilities/dateTimeUtils';
import HelpText from '@thrivetrm/ui/components/HelpText';
import Tooltip from '@thrivetrm/ui/components/Tooltip';

const DraftStatus = ({ lastUpdatedTime }) =>
  lastUpdatedTime ? (
    <Tooltip
      className='u-fontSize-small'
      content={
        <div className='u-textAlign-l' data-testid='draft tooltip content'>
          <p>Drafts are stored to this device on this browser for 7 days.</p>
          <p> Submit the form to save your changes.</p>
        </div>
      }
      data-testid='draft status'
      position='topRight'
      size='medium'
    >
      <HelpText>Draft saved </HelpText>
      {relativeDate(lastUpdatedTime)}
    </Tooltip>
  ) : null;

DraftStatus.defaultProps = {
  lastUpdatedTime: null,
};

DraftStatus.propTypes = {
  lastUpdatedTime: PropTypes.number,
};

export default DraftStatus;
