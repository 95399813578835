import { schema } from 'normalizr';
import companySchema from 'modules/companies/companySchema';
import levelSchema from 'modules/tenant/schemas/levels';
import positionFunctionSchema from 'modules/tenant/schemas/positionFunctions';

export default new schema.Entity('positions', {
  company: companySchema,
  levels: [levelSchema],
  position_functions: [positionFunctionSchema],
  duplicate_positions: [
    {
      company: companySchema,
      levels: [levelSchema],
      position_functions: [positionFunctionSchema],
    },
  ],
});
