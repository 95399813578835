import PropTypes from 'prop-types';
import {
  branch,
  compose,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import React from 'react';
import pluralize from 'pluralize';
import classnames from 'classnames';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { filterParams } from 'initializers/global_search';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';
import CompanyDuplicate from './CompanyDuplicate';

const CompanyDuplicates = ({
  companyName,
  duplicateIds,
  exactMatchCompany,
  isDangerous,
  matchedOnPropertyLabel,
  showViewMore,
}) => {
  const className = classnames('alert', {
    'alert-danger': isDangerous,
    'alert-warning': !isDangerous && exactMatchCompany,
    'alert-info': !exactMatchCompany && !isDangerous,
  });

  const duplicateCompaniesLimit = 5;

  const handleRedirect = () => {
    const base = '/companies?utf8=✓&sort_field=relevance';
    window.location = encodeURI(
      `${base}${filterParams(companyName)}${companyName}`,
    );
  };

  return (
    <div className={className}>
      {exactMatchCompany ? (
        <p>
          Hold on! We found {pluralize('company', duplicateIds.size, true)}{' '}
          associated with the same {matchedOnPropertyLabel}:{' '}
        </p>
      ) : (
        <p>
          We found {pluralize('company', duplicateIds.size, true)} with a
          similar {matchedOnPropertyLabel}:{' '}
        </p>
      )}

      <ul className='list-unstyled'>
        {duplicateIds.slice(0, duplicateCompaniesLimit).map(duplicateId => (
          <li className='dup-found' key={duplicateId}>
            <CompanyDuplicate companyId={duplicateId} />
          </li>
        ))}
      </ul>
      {duplicateIds.toJS().length > duplicateCompaniesLimit && showViewMore ? (
        <ButtonLink
          className='u-flex u-flexJustify-r u-width-100'
          label='View more'
          onClick={handleRedirect}
        />
      ) : null}
    </div>
  );
};

CompanyDuplicates.defaultProps = {
  showViewMore: true,
};

CompanyDuplicates.propTypes = {
  companyName: PropTypes.string,
  duplicateIds: ImmutablePropTypes.listOf(PropTypes.number).isRequired,
  exactMatchCompany: PropTypes.bool,
  isDangerous: PropTypes.bool,
  matchedOnPropertyLabel: PropTypes.string.isRequired,
  showViewMore: PropTypes.bool,
};

export default compose(
  setDisplayName('CompanyDuplicates(enhanced)'),
  setPropTypes({
    duplicateIds: ImmutablePropTypes.listOf(PropTypes.number).isRequired,
  }),
  branch(({ duplicateIds }) => duplicateIds.isEmpty(), renderNothing),
)(CompanyDuplicates);
