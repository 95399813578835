/**
 * A higher-order function to override overridenOptions
 * @param {Object|Function} overridenOptions The options to set, or a function that returns
 *   the options to set (called with the incoming options)
 *
 * @example
 * ```js
 * const myAction = compose(
 *   fetchAction,
 *   withOptions({ foo: 'bar '}),
 * );
 *
 * dispatch(myAction());
 * // myAction calls fetchAction({ foo: 'bar' });
 *
 * // Can not be overriden! use `withDefaults` for that.
 * dispatch(myAction({ foo: 'notbar!' }))
 * // myAction still calls fetchAction({ foo: 'bar' })
 * ```
 *
 * @example As a function
 * ```js
 * const myAction = compose(
 *   fetchAction,
 *   withOptions(({ id }) => ({ url: `/api/entities/${id}` })),
 * );
 *
 * dispatch(myAction({ id: 42 }));
 * // myAction calls fetchAction({ id: 42, url: '/api/entities/42' });
 * ```
 */
export default overridenOptions => options => {
  if (typeof overridenOptions === 'function') {
    return {
      ...options,
      ...overridenOptions(options),
    };
  }

  return {
    ...options,
    ...overridenOptions,
  };
};
