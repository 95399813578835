import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useApiGet } from 'modules/core/hooks/useApi';
import routes from 'modules/routing/routes';
import ReactSelectField from 'modules/forms/components/ReactSelectField';
import getCompanyProperty from 'modules/companies/selectors/getCompanyProperty';
import { useSelector } from 'react-redux';
import FieldState from 'modules/forms/FieldState';
import ExternalLink from 'modules/links/ExternalLink';
import HtmlText from '@thrivetrm/ui/components/HtmlText';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';

const PositionOfficeLocationSelectField = ({
  companyId,
  fieldState,
  onChange,
}) => {
  const [
    loadCompanyAddresses,
    isLoadingCompanyAddresses,
    _companyAddressesLoadError,
    companyAddresses,
  ] = useApiGet(routes.api_v1_company_addresses({ companyId: companyId }));

  const companyName = useSelector(state =>
    getCompanyProperty(state, companyId, 'name'),
  );
  const primaryAddressId = useSelector(state =>
    getCompanyProperty(state, companyId, 'primary_address'),
  );

  const formatAddressLabel = address => {
    const addressParts = [
      address.address_line_1,
      address.address_line_2,
      address.city,
      address.state,
      address.country_code,
      address.id === primaryAddressId ? '<b>(Primary)</b>' : null,
    ]
      .filter(Boolean)
      .join(', ');

    if (address.label) {
      return `<b>${address.label}</b>: ${addressParts}`;
    }
    return addressParts;
  };

  const renderAddress = option => <HtmlText htmlString={option.label} />;

  useEffect(() => {
    if (companyId) {
      loadCompanyAddresses();
    }
  }, [companyId, loadCompanyAddresses]);

  const addressOptions = companyAddresses?.addresses.map(address => ({
    value: address.id,
    label: formatAddressLabel(address),
  }));

  const officeLocationHtmlId = uniqueId('no-existing-locations');

  return (
    <div>
      {companyId && !addressOptions?.length && !isLoadingCompanyAddresses ? (
        <div>
          <label htmlFor={officeLocationHtmlId}>Office Location</label>
          <p id={officeLocationHtmlId}>
            No addresses found, add a new address from{' '}
            <ExternalLink href={`/companies/${companyId}`}>
              {companyName}.
            </ExternalLink>
          </p>
        </div>
      ) : (
        <ReactSelectField
          className='PositionOfficeLocationSelectField'
          fieldState={fieldState}
          isLoading={isLoadingCompanyAddresses}
          label='Office Location'
          onChange={onChange}
          optionRenderer={renderAddress}
          options={companyId ? addressOptions : []}
          simpleValue={true}
          valueRenderer={renderAddress}
        />
      )}
    </div>
  );
};

PositionOfficeLocationSelectField.propTypes = {
  companyId: PropTypes.number,
  fieldState: PropTypes.instanceOf(FieldState).isRequired,
  onChange: PropTypes.func,
};

export default PositionOfficeLocationSelectField;
