import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import byId from './byId';
import directReportsByContactId from './directReportsByContactId';
import educationsByContactId from './educationsByContactId';
import positionsByContactId from './positionsByContactId';
import queries from './queries';
import contactsLists from './contactsLists';
import ratingDefinitionsList from './ratingDefinitionsList';

const contactsNewReducer = combineImmutableReducers({
  byId: byId,
  contactsLists: contactsLists,
  directReportsByContactId: directReportsByContactId,
  educations: combineImmutableReducers({
    byContactId: educationsByContactId,
  }),
  positions: combineImmutableReducers({
    byContactId: positionsByContactId,
  }),
  queries: queries,
  ratingDefinitionsList: ratingDefinitionsList,
});

contactsNewReducer.NAME = NAME;

export default contactsNewReducer;
