import PropTypes from 'prop-types';
/* eslint-disable react/no-danger */
import React from 'react';
import {
  branch,
  compose,
  defaultProps,
  mapProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import HtmlText from '@thrivetrm/ui/components/HtmlText';
import Card from '@thrivetrm/ui/components/Card';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import mapContactIdToContact from './mapContactIdToContact';

const ContactCareerHighlights = ({
  highlights,
  title,
  wrapHighlightsWithCard,
}) => (
  <div className='ContactCareerHighlights'>
    {title}
    {wrapHighlightsWithCard ? (
      <Card isCentered={false}>
        <HtmlText htmlString={highlights} />
      </Card>
    ) : (
      <HtmlText htmlString={highlights} />
    )}
  </div>
);

ContactCareerHighlights.propTypes = {
  highlights: PropTypes.string,
  title: PropTypes.node,
  wrapHighlightsWithCard: PropTypes.bool,
};

export default compose(
  setDisplayName('ContactCareerHighlights(enhanced)'),
  setPropTypes({
    contactId: PropTypes.number,
    renderIfEmpty: PropTypes.bool,
  }),
  defaultProps({
    renderIfEmpty: false,
    wrapHighlightsWithCard: false,
  }),
  mapContactIdToContact,
  withFeatureCheck(
    'label.career_highlights_to_google_insights',
    'useGoogleInsightsLabel',
  ),
  mapProps(
    ({
      contact,
      renderIfEmpty,
      useGoogleInsightsLabel,
      wrapHighlightsWithCard,
    }) => ({
      renderIfEmpty: renderIfEmpty,
      highlights: contact && contact.get('career_highlights'),
      title: useGoogleInsightsLabel ? (
        <h3>Google Insights</h3>
      ) : (
        <h3>Career Highlights</h3>
      ),
      wrapHighlightsWithCard: wrapHighlightsWithCard,
    }),
  ),
  branch(
    ({ highlights, renderIfEmpty }) => !highlights && !renderIfEmpty,
    renderNothing,
  ),
)(ContactCareerHighlights);
