import Immutable, { fromJS, Iterable, List, Map } from 'immutable';
import { NAME } from './constants';
import {
  INVALIDATE,
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  UPDATE_START,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE_START,
  DELETE_SUCCESS,
  DELETE_FAILURE,
  DELETE_MULTIPLE_START,
  DELETE_MULTIPLE_SUCCESS,
  DELETE_MULTIPLE_FAILURE,
} from './actions/entityActionTypes';
import composeReducers from '../../reducers/composeReducers';

/**
 * Maps DELETE_MULTIPLE_* action types to their related
 * DELETE_* (single) action types.
 */
const ACTION_TYPE_MULTIPLE_MAP = {
  [DELETE_MULTIPLE_START]: DELETE_START,
  [DELETE_MULTIPLE_SUCCESS]: DELETE_SUCCESS,
  [DELETE_MULTIPLE_FAILURE]: DELETE_FAILURE,
};

/**
 * Used when merging the entities state.
 * By default, `mergeDeep` will merge Lists based on index -- which means [1, 2, 3] merged
 * with [4, 5] results in [4, 5, 3], which is not what we want! We want Lists to be completely
 * replaced.
 */
const entityMerger = (oldVal, newVal) => {
  if (List.isList(oldVal) || Array.isArray(oldVal)) {
    return newVal;
  }

  // The rest of this method is effectvely what the default merger does
  if (oldVal && oldVal.mergeWith && Iterable.isIterable(newVal)) {
    return oldVal.mergeWith(entityMerger, newVal);
  }

  return Immutable.is(oldVal, newVal) ? oldVal : newVal;
};

/**
 * This reducer responds to any action that is dispatched that has normalized entities
 * on it's payload (`payload.entities.[entitytype]`)
 */
const entitiesMapReducer = (state = Map(), action) => {
  if (!Iterable.isIterable(state)) {
    return entitiesMapReducer(fromJS(state), action);
  }

  const { payload } = action;

  if (payload && payload.entities) {
    // We use a custom merger function because we want any List/Array values to be completely
    // replaced by their new arrays/lists.
    return state.mergeWith(entityMerger, payload.entities);
  }

  return state;
};

const entityActionsReducer = (state, action) => {
  const { payload } = action;
  if (!payload || !payload.entityType || !(payload.id || payload.ids)) {
    return state;
  }

  const { entityType, id } = payload;
  const idString = String(id);

  switch (action.type) {
    case INVALIDATE: {
      if (state.hasIn([entityType, idString])) {
        return state
          .setIn([entityType, idString, '_meta', 'isInvalidated'], true)
          .deleteIn([entityType, idString, '_meta', 'error']);
      }

      return state;
    }
    case FETCH_START: {
      return state.setIn([entityType, idString, '_meta', 'isFetching'], true);
    }
    case FETCH_SUCCESS: {
      return state
        .mergeIn([entityType, idString, '_meta'], {
          isFetching: false,
          isInvalidated: false,
          lastFetched: Date.now(),
        })
        .deleteIn([entityType, idString, '_meta', 'error']);
    }
    case FETCH_FAILURE: {
      return state.mergeIn([entityType, idString, '_meta'], {
        isFetching: false,
        error: action.payload.error || true,
      });
    }
    case UPDATE_START: {
      return state.setIn([entityType, idString, '_meta', 'isUpdating'], true);
    }
    case UPDATE_SUCCESS: {
      return state
        .mergeIn([entityType, idString, '_meta'], {
          isUpdating: false,
          isInvalidated: false,
          lastFetched: Date.now(),
        })
        .deleteIn([entityType, idString, '_meta', 'error']);
    }
    case UPDATE_FAILURE: {
      return state.mergeIn([entityType, idString, '_meta'], {
        isUpdating: false,
        error: action.payload.error || true,
      });
    }
    case DELETE_START: {
      return state.setIn([entityType, idString, '_meta', 'isDeleting'], true);
    }
    case DELETE_SUCCESS: {
      return state
        .mergeIn([entityType, idString, '_meta'], {
          isDeleting: false,
          isDeleted: true,
          isInvalidated: false,
        })
        .deleteIn([entityType, idString, '_meta', 'error']);
    }
    case DELETE_FAILURE: {
      return state.mergeIn([entityType, idString, '_meta'], {
        isDeleting: false,
        error: action.payload.error || true,
      });
    }
    case DELETE_MULTIPLE_START:
    case DELETE_MULTIPLE_SUCCESS:
    case DELETE_MULTIPLE_FAILURE: {
      // Recursively call this reducer mimicking the single DELETE_* actions
      // for each ID.
      return state.withMutations(map => {
        payload.ids.forEach(entityId =>
          entityActionsReducer(map, {
            type: ACTION_TYPE_MULTIPLE_MAP[action.type],
            payload: {
              id: entityId,
              entityType: entityType,
            },
          }),
        );
      });
    }
    default: {
      return state;
    }
  }
};

const entitiesReducer = composeReducers(
  entitiesMapReducer,
  entityActionsReducer,
);

entitiesReducer.NAME = NAME;

export default entitiesReducer;
