import { connect } from 'react-redux';
import getAttribute from 'modules/assessments/selectors/getAttribute';
import getOption from 'modules/assessments/selectors/getOption';

/**
 * A higher order component which takes a List of search attributes (as a`searchAttributes` prop),
 * sorts them based on their `position` value, and maps them to their associated
 * option record.
 */
export default connect(
  (state, { searchAttributes }) => ({
    options:
      searchAttributes && searchAttributes.get('ids')
        ? searchAttributes
            .get('ids')
            .map(id => getAttribute(state, id))
            .sortBy(attribute => attribute.get('position'))
            .map(attribute =>
              getOption(state, attribute.get('assessment_option_id')),
            )
        : null,
  }),
  {},
);
