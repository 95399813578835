import selectn from 'selectn';
import { Map } from 'immutable';
import { contactSchema } from 'modules/contacts/schema';
import { searchSchema } from 'modules/searches/schema';

const contactsSelector = selectn(`payload.entities.${contactSchema.key}`);
const searchesSelector = selectn(`payload.entities.${searchSchema.key}`);

/**
 * Maintains a mapping of contact IDs to talent pool IDs.
 * NOTE: This currently does not support updating/deleting talent pools -- we currently don't
 * support that in the UI. But when we do, this will need to take that into account.
 */
export default (state = new Map(), action) => {
  const contacts = contactsSelector(action);
  const searches = searchesSelector(action);

  if (contacts || searches) {
    const fromTalentPools =
      searches &&
      Object.keys(searches)
        .map(key => searches[key])
        .filter(
          searchEntity =>
            searchEntity.current_contact || searchEntity.current_contact_id,
        )
        .map(searchEntity => [
          searchEntity.current_contact || searchEntity.current_contact_id,
          searchSchema.getId(searchEntity),
        ]);

    const fromContacts =
      contacts &&
      Object.keys(contacts)
        .map(key => contacts[key])
        .filter(contact =>
          Object.prototype.hasOwnProperty.call(contact, 'talent_pool'),
        )
        .map(contact => [contactSchema.getId(contact), contact.talent_pool]);

    return state.merge(fromTalentPools).merge(fromContacts);
  }

  return state;
};
