import getSortName from 'modules/users/selectors/getSortName';
import getSearchProperty from './getSearchProperty';

const getSearchLeadRecruiterSortName = (state, searchId) => {
  const userId = getSearchProperty(
    state,
    searchId,
    'lead_internal_team_member',
  );
  return userId && getSortName(state, userId);
};

export default getSearchLeadRecruiterSortName;
