import selectn from 'selectn';
import combineImmutableReducers from '../../reducers/combineImmutableReducers';
import createListReducer from '../../reducers/createListReducer';
import createFilteredReducer from '../../reducers/createFilteredReducer';
import {
  USER_LIST_FETCH_START,
  USER_LIST_FETCH_SUCCESS,
  USER_LIST_FETCH_FAILURE,
} from './actions/actionTypes';
import { NAME, FILTERS } from './constants';

const userList = createListReducer({
  request: USER_LIST_FETCH_START,
  success: USER_LIST_FETCH_SUCCESS,
  failure: USER_LIST_FETCH_FAILURE,
});

/**
 * A filter that returns true when the action type is a USER_FETCH_* action and the
 * action.payload.filter matches the filter specified.
 * @param {String} [filter] The filter value that must be present on the payload for this to match.
 * @return {Boolean} False when a USER_LIST_FETCH_* action is dispatched that does not match the
 *   filter value; Otherwise, true.
 */
const isFilteredUserAction = filter => (_, action) =>
  [
    USER_LIST_FETCH_START,
    USER_LIST_FETCH_SUCCESS,
    USER_LIST_FETCH_FAILURE,
  ].includes(action.type)
    ? selectn(['payload', 'filter'], action) === filter
    : true;

const usersReducer = combineImmutableReducers({
  all: createFilteredReducer(isFilteredUserAction(undefined), userList),
  ...FILTERS.reduce(
    (lists, filter) => ({
      ...lists,
      [filter]: createFilteredReducer(isFilteredUserAction(filter), userList),
    }),
    {},
  ),
});

usersReducer.NAME = NAME;

export default usersReducer;
