import { connect } from 'react-redux';
import getCandidacyVote from '../../selectors/getCandidacyVote';

/**
 * A higher order component which maps a `candidacyId` prop value to
 * the current user's vote value for that candidacy.
 */
export default connect(
  (state, { candidacyId }) => ({
    vote: getCandidacyVote(state, candidacyId),
  }),
  {},
);
