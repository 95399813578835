import { NAME } from '../constants';

/**
 * Gets the list of candidacies for a search.
 * @param {Object} state
 * @param {Number} parentType One of PARENT_SEARCH or PARENT_CONTACT - the list type to get
 *   (either the list of candidacies of a search, or the list of candidacies belonging to a contact)
 * @param {Number} parentId The search or contact ID, depending on parentType.
 * @return {?Immutable.Map} The list of candidacies.
 */
const getCandidacyList = (state, parentType, parentId) =>
  state[NAME].getIn(['candidacyLists', parentType, parentId]);

export default getCandidacyList;
