import React from 'react';
import TenantOptionsTagSelectField from './TenantOptionsTagSelectField';
import positionFunctionSchema from '../schemas/positionFunctions';

/**
 * A tag select component for choosing from the list of positionFunctions.
 */

const PositionFunctionsTagSelectField = ({ ...props }) => (
  <TenantOptionsTagSelectField
    {...props}
    className='PositionFunctionsTagSelectField'
    entityLabel='Function'
    label='Functions'
    schema={positionFunctionSchema}
  />
);

PositionFunctionsTagSelectField.createFieldState =
  TenantOptionsTagSelectField.createFieldState;

export default PositionFunctionsTagSelectField;
