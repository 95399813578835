import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as actions from '../actions';

/**
 * Connects the comment actions to the current dispatcher and adds
 * them to props as `commentActions`
 */
export default connect(null, dispatch => ({
  commentActions: bindActionCreators(actions, dispatch),
}));
