import getSearch from './getSearch';

const getSearchTitle = (state, id) => {
  const search = getSearch(state, id);

  if (!search) {
    return null;
  }
  // always display code_name if search is confidential
  return search.get('confidential')
    ? search.get('code_name')
    : search.get('job_title');
};

export default getSearchTitle;
