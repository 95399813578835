import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import documentLists from './documentLists';

const documentsReducer = combineImmutableReducers({
  documentLists: documentLists,
});

documentsReducer.NAME = NAME;

export default documentsReducer;
