import { NAME } from '../constants';

/**
 * Gets the list of IDs for a particular set of tenant options.
 * @param {Object} state
 * @param {String} optionKey The key on the tenant options where the IDs are stored. This is
 *   typically the same as the schema key (@see `fetchTenantOptions` action for why that is)
 * @return {List.<number>} An immutable list of the IDs, if the tenant option exists and has been
 *   loaded.
 */
export default (state, optionKey) =>
  state[NAME].getIn(['tenantOptions', optionKey]);
