import { connect } from 'react-redux';
import * as sortUtils from 'modules/core/sortUtils';
import getCandidacySortName from '../selectors/getCandidacySortName';
import getCandidacyRank from '../selectors/getCandidacyRank';

/**
 * A Higher order component that sorts a list of candidacyIds in order of the candidate's name
 */
export default connect(
  (state, { candidacyIds }) => ({
    candidacyIds:
      candidacyIds &&
      candidacyIds.sortBy(
        id => [getCandidacySortName(state, id), getCandidacyRank(state, id)],
        sortUtils.arrayComparator,
      ),
  }),
  {},
);
