import PropTypes from 'prop-types';
import React from 'react';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import { compose, setDisplayName, withStateHandlers } from 'recompose';

import NewCompanyFormModal from './NewCompanyFormModal';

/**
 * Renders a button that, when clicked, displays a modal form for creating a new company.
 */
const NewCompanyFormModalButton = ({
  companyName,
  hideModal,
  isModalShown,
  onCloseCreateCompanyModal,
  shouldNavigateToNewCompany,
  showModal,
  size,
}) => [
  <ButtonPrimary
    className='u-noWrap'
    icon='add'
    key='button'
    label={companyName ? `Create "${companyName}"` : 'New Company'}
    onClick={showModal}
    size={size}
  />,
  <NewCompanyFormModal
    companyName={companyName}
    key='modal'
    onClose={hideModal}
    onCloseCreateCompanyModal={onCloseCreateCompanyModal}
    shouldNavigateToNewCompany={shouldNavigateToNewCompany}
    show={isModalShown}
  />,
];

NewCompanyFormModalButton.propTypes = {
  /**
   * Name of the new company being created.
   */
  companyName: PropTypes.string,

  /**
   * A function that will be called when the create modal should be hidden.
   */
  hideModal: PropTypes.func.isRequired,

  /**
   * True if the modal should be visible/rendered, otherwise false.
   */
  isModalShown: PropTypes.bool,

  /**
   * A function that is passed in from 'PrimaryPositionModal'. If passed in show
   * the PrimaryPositionModal CompanyModal is closed.
   */
  onCloseCreateCompanyModal: PropTypes.func,

  /**
   * A function that will be called when the modal should be shown.
   */
  showModal: PropTypes.func.isRequired,

  /**
   * The button size modifier.
   */
  size: PropTypes.string,
};

NewCompanyFormModalButton.defaultProps = {
  isModalShown: false,
  size: 'medium',
};

/**
 * Enhanced version provides mangement of the modal show/hide state.
 */
export default compose(
  setDisplayName('NewCompanyFormModalButton(enhanced)'),
  withStateHandlers(
    {
      isModalShown: false,
    },
    {
      showModal: (_, { onCreateCompanyClick }) => () => {
        if (onCreateCompanyClick) {
          onCreateCompanyClick();
        }

        return { isModalShown: true };
      },
      hideModal: (_, { onCloseCreateCompanyModal }) => () => {
        if (onCloseCreateCompanyModal) {
          onCloseCreateCompanyModal();
        }

        return { isModalShown: false };
      },
    },
  ),
)(NewCompanyFormModalButton);
