import { Map } from 'immutable';
import createListReducer from '../../reducers/createListReducer';
import {
  CREATE_SUCCESS,
  DELETE_SUCCESS,
  UPDATE_SUCCESS,
} from './actions/entityActionTypes';
import isEntityType from './isEntityType';

/**
 * This is a thin wrapper around createListReducer specifically for use with entity action types.
 * It filters created/deleted/updated actions so that only those of the given entity type are
 * passed through to the listreducer.
 * Also, instead of just `resultKey` I've renamed it `listResultKey` for a tiny bit of clarity.
 */
export default ({
  entityResultKey,
  entityType,
  failure,
  invalidated,
  listResultKey,
  request,
  success,
  ...rest
}) => {
  const listReducer = createListReducer({
    resultKey: listResultKey,
    entityResultKey: entityResultKey,
    request: request,
    success: success,
    failure: failure,
    invalidated: invalidated,
    created: CREATE_SUCCESS,
    deleted: DELETE_SUCCESS,
    updated: UPDATE_SUCCESS,
    ...rest,
  });

  return (state = new Map(), action) => {
    switch (action.type) {
      case CREATE_SUCCESS:
      case DELETE_SUCCESS:
      case UPDATE_SUCCESS: {
        // Ignore other entities.
        if (!isEntityType(action, entityType)) {
          return state;
        }

        return listReducer(state, action);
      }
      default: {
        return listReducer(state, action);
      }
    }
  };
};
