import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import bySearchId from './bySearchId';

const jobApplicationsReducer = combineImmutableReducers({
  bySearchId: bySearchId,
});

jobApplicationsReducer.NAME = NAME;

export default jobApplicationsReducer;
