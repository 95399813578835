import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import interviewsByParentType from './interviewsByParentType';
import filteredInterviewsByParentType from './filteredInterviewsByParentType';

const interviewsReducer = combineImmutableReducers({
  interviewsByParentType: interviewsByParentType,
  filteredInterviewsByParentType: filteredInterviewsByParentType,
});

interviewsReducer.NAME = NAME;

export default interviewsReducer;
