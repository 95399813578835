import selectn from 'selectn';
import { Map } from 'immutable';
import createListReducer from '../../../reducers/createListReducer';
import composeReducers from '../../../reducers/composeReducers';
import {
  TARGET_COMPANY_LIST_FETCH_START,
  TARGET_COMPANY_LIST_FETCH_SUCCESS,
  TARGET_COMPANY_LIST_FETCH_FAILURE,
  TARGET_COMPANY_LIST_INVALIDATE,
} from '../actions/actionTypes';

const targetCompanyListReducer = createListReducer({
  resultKey: 'target_companies',
  entityResultKey: 'target_company',
  request: TARGET_COMPANY_LIST_FETCH_START,
  success: TARGET_COMPANY_LIST_FETCH_SUCCESS,
  failure: TARGET_COMPANY_LIST_FETCH_FAILURE,
  invalidated: TARGET_COMPANY_LIST_INVALIDATE,
});

// Comments is keyed on the parent type (PARENT_CANDIDACY or PARENT_CONTACT),
// then by that parent's ID (a contact ID for PARENT_CONTACT or a candidacy ID for
// PARENT_CANDIDACY).
export default composeReducers(
  (state = new Map(), action) => {
    switch (action.type) {
      case TARGET_COMPANY_LIST_FETCH_START:
      case TARGET_COMPANY_LIST_FETCH_SUCCESS:
      case TARGET_COMPANY_LIST_FETCH_FAILURE: {
        const searchId = selectn('payload.searchId', action);

        if (searchId) {
          return state.update(searchId, list =>
            targetCompanyListReducer(list, action),
          );
        }

        return state;
      }
      case TARGET_COMPANY_LIST_INVALIDATE: {
        const searchId = selectn('payload.searchId', action);

        if (searchId && state.has(searchId)) {
          return state.setIn([searchId, '_meta', 'isInvalidated'], true);
        }

        return state;
      }

      default: {
        return state.map(listState =>
          targetCompanyListReducer(listState, action),
        );
      }
    }
  },
  (state, action) => {
    if (action.type === TARGET_COMPANY_LIST_FETCH_SUCCESS) {
      const searchId = selectn('payload.searchId', action);
      const withoutCount = selectn(
        'payload.result.orphan_candidates_count',
        action,
      );

      if (searchId) {
        return state.setIn([searchId, 'orphanCandidatesCount'], withoutCount);
      }
    }

    return state;
  },
);
