import { stringifyQueryObject } from 'modules/core/urlUtils';
import { createSlice, createSelector } from '@reduxjs/toolkit';
import Api, { getErrorMessage } from 'modules/core/Api';
import url from 'url';
import { debounce, isEqual } from 'lodash';

// TODO: these utils should be moved to core/objectUtils with tests written
import {
  convertToCamelCase,
  convertToSnakeCase,
  stringToCamelCase,
  stringToSnakeCase,
} from 'modules/core/jsonUtils';
// When no network is selected in the saved view, its networkId value is null.
// In the UI, we represent this with a select menu item labelled "All [Records]"
// The menu item must have a non-nullish value, so we assign this value for the UI
// and translate to null for what is sent to the API
const ALL_RECORDS_NETWORK_ID = 0;
const DEFAULT_SORT_FIELD = 'primary_identifier';
const DEBOUNCE_INTERVAL_MS = 500;

export const FilterInputTypes = {
  DATE: 'date',
  NUMBER: 'number',
  MULTI_AUTOCOMPLETE: 'multi_autocomplete',
  RADIO: 'radio',
  TEXT: 'text',
  TEXT_AREA: 'text_area',
  CHECKBOX: 'checkbox',
  SINGLE_VALUE_SELECT: 'single_value_select',
  MINIMUM_STAGED_REACHED: 'minimum_stage_reached',
  MULTI_VALUE_NUMBER: 'multi_value_number',
  MULTI_VALUE_SELECT: 'multi_value_select',
  MULTI_VALUE_SELECT_BOOLEAN: 'multi_value_select_boolean',
  MULTI_VALUE_TEXT_BOOLEAN: 'multi_value_text_boolean',
};

export const FilterInputDefaultValues = {
  [FilterInputTypes.DATE]: null,
  [FilterInputTypes.NUMBER]: null,
  [FilterInputTypes.MULTI_AUTOCOMPLETE]: [],
  [FilterInputTypes.RADIO]: null,
  [FilterInputTypes.TEXT]: '',
  [FilterInputTypes.TEXT_AREA]: '',
  [FilterInputTypes.CHECKBOX]: '0',
  [FilterInputTypes.SINGLE_VALUE_SELECT]: null,
  [FilterInputTypes.MINIMUM_STAGED_REACHED]: null,
  [FilterInputTypes.MULTI_VALUE_NUMBER]: [],
  [FilterInputTypes.MULTI_VALUE_SELECT]: [],
  [FilterInputTypes.MULTI_VALUE_SELECT_BOOLEAN]: {
    values: [],
    operator: 'OR',
  },
  [FilterInputTypes.MULTI_VALUE_TEXT_BOOLEAN]: {
    values: [],
    operator: 'OR',
  },
};

const MaxPercent = 100;

const getFilterError = (type, minString = null, maxString = null) => {
  if (minString && maxString) {
    if (type === 'date') {
      return new Date(minString) > new Date(maxString)
        ? 'Start date must be before end date'
        : null;
    } else if (type === 'number') {
      return Number(minString) > Number(maxString)
        ? 'Min must be less than max'
        : null;
    }
  }
  if (type === 'percent') {
    if (minString && maxString) {
      if (Number(minString) > Number(maxString)) {
        return 'Min must be less than max';
      }
    }
    if (
      (maxString && Number(maxString) > MaxPercent) ||
      (minString && Number(minString) > MaxPercent)
    ) {
      return 'Must be less than 100';
    }
  }
  return null;
};

const initialState = {
  candidateExportFields: null,
  CreateButton: null,
  csvExportRowLimit: Infinity,
  currentPage: 1,
  defaultSavedViewId: null,
  endpoints: null,
  filterAdapter: null,
  filterSummary: [],
  initialSavedView: {},
  isStale: false,
  payloadKeys: null,
  recordLabel: null,
  recordList: null,
  recordListRequestError: null,
  recordListRequestIsInflight: false,
  recordType: null,
  savedView: {},
  selectedColumns: [],
  selectedRecordIds: [],
  /* We initialize shouldUpdateBrowserUrl with a value of false to keep the browserUrl unchanged  */
  shouldUpdateBrowserUrl: false,
  shouldRequestFreshData: false,
  supportedBulkTagCategories: [],
  totalPages: 1,
  totalResults: 0,
  urls: null,
  availableFilterSets: {},
  availableFilterInputs: {},
  persistentFilterSetIds: [],
  filterSetSectionNames: [],
  filterSetErrors: {},
};

const recordIndexSlice = createSlice({
  name: 'recordIndex',
  initialState: initialState,
  reducers: {
    markAsStale: state => {
      state.isStale = true;
    },
    requestRecordListBegin: state => {
      state.recordListRequestIsInflight = true;
      state.recordListRequestError = null;
      state.shouldRequestFreshData = false;
    },
    requestRecordListSuccess: (state, action) => {
      state.csvExportRowLimit = action.payload.csvExportRowLimit;
      state.recordList = action.payload.recordList;
      state.recordListRequestIsInflight = false;
      state.selectedColumns = action.payload.selectedColumns;
      state.totalPages = action.payload.totalPages;
      state.totalResults = action.payload.totalResults;
      state.isStale = false;
      state.shouldUpdateBrowserUrl = action.payload.shouldUpdateBrowserUrl;
    },
    requestRecordListError: (state, action) => {
      state.recordListRequestIsInflight = false;
      state.recordListRequestError = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
      state.selectedRecordIds = [];
    },
    resetRecordId: state => {
      state.selectedRecordIds = [];
    },
    setSelectedRecords: (state, action) => {
      state.selectedRecordIds = action.payload;
    },
    setSavedView: (state, action) => {
      const newSavedView = {
        ...state.savedView,
        ...action.payload,
      };
      state.filterSummary = convertToCamelCase(
        state.filterAdapter.generateFilterSummary(),
      );
      state.savedView = newSavedView;
      state.selectedRecordIds = [];
    },

    setInitialSavedView: (state, action) => {
      state.initialSavedView = action.payload;
    },
    resetView: state => {
      state.savedView = { ...state.initialSavedView };
    },
    clearFilters: state => {
      const { availableFilterInputs } = state;
      const { selectedFilterSetIds, ...rest } = state.savedView.filters;
      const emptyFilterValues = Object.keys(rest).map(filterName => [
        filterName,
        FilterInputDefaultValues[
          availableFilterInputs[stringToSnakeCase(filterName)]?.type
        ],
      ]);
      const newFilters = {
        ...Object.fromEntries(emptyFilterValues),
        selectedFilterSetIds: selectedFilterSetIds,
      };
      state.savedView.filters = newFilters;
      state.savedView.networkId = null;
      state.isStale = true;
    },
    setResultsPerPage: (state, action) => {
      state.currentPage = 1;
      state.savedView.resultsPerPage = action.payload;
      state.selectedRecordIds = [];
    },
    setNetworkId: (state, action) => {
      state.savedView.networkId =
        action.payload === ALL_RECORDS_NETWORK_ID ? null : action.payload;
      state.currentPage = 1;
      state.selectedRecordIds = [];
    },
    setSortParams: (state, action) => {
      const { sortDirection, sortField } = action.payload;
      state.currentPage = 1;
      state.selectedRecordIds = [];
      state.savedView.sortDirection = sortDirection;
      state.savedView.sortField = sortField;
    },
    setColumns: (state, action) => {
      const columns = action.payload;
      const hasRemovedSortedColumn = !columns.includes(
        state.savedView.sortField,
      );
      state.savedView.columns = columns;
      state.savedView.sortField = hasRemovedSortedColumn
        ? DEFAULT_SORT_FIELD
        : state.savedView.sortField;
    },
    setFiltersToModalValues: state => {
      state.filterSummary = convertToCamelCase(
        state.filterAdapter.generateFilterSummary(),
      );
      state.savedView.filters = convertToCamelCase(
        state.filterAdapter.generateFilterParams(),
      );
      state.selectedRecordIds = [];
      state.currentPage = 1;
    },
    setRecordTypeConfig: (state, action) => {
      state.candidateExportFields = action.payload.candidateExportFields;
      state.CreateButton = action.payload.CreateButton;
      state.defaultSavedViewId = action.payload.defaultSavedViewId;
      state.endpoints = action.payload.endpoints;
      state.filterAdapter = action.payload.filterAdapter;
      state.payloadKeys = action.payload.payloadKeys;
      state.recordLabel = action.payload.recordLabel;
      state.recordLabelPlural = action.payload.recordLabelPlural;
      state.recordType = action.payload.recordType;
      state.supportedBulkActions = action.payload.supportedBulkActions;
      state.supportedBulkTagCategories =
        action.payload.supportedBulkTagCategories;
      state.urls = action.payload.urls;
      state.networkType = action.payload.networkType;
      state.isSidebarExpandedKey = action.payload.isSidebarExpandedKey;
    },
    setupFilterSets: (state, action) => {
      state.availableFilterSets = Object.fromEntries(
        action.payload.availableFilterSets.map(filterSet => [
          filterSet.id,
          filterSet,
        ]),
      );

      state.filterSetSectionNames = action.payload.filterSetSections;
      state.persistentFilterSetIds = action.payload.persistentFilterSetIds;
      state.availableFilterInputs = action.payload.availableFilterInputs;
    },
    /**
     * We use `.savedView.filters` and `.initialSavedView.filters` to track dirty state.
     * By adding a key and value for filter inputs that are rendered but are not within
     * the persisted saved view data.
     */
    registerFilterInput: (state, action) => {
      const filterInputMeta =
        state.availableFilterInputs[stringToSnakeCase(action.payload.name)];
      const currentValue =
        state.savedView.filters[stringToCamelCase(action.payload.name)];
      state.savedView.filters[stringToCamelCase(action.payload.name)] =
        currentValue || filterInputMeta.initialSavedValue;
    },
    setSingleFilterValue: (state, action) => {
      const { name, value } = action.payload;
      state.savedView.filters[stringToCamelCase(name)] = value;
      state.shouldRequestFreshData = true;
      state.shouldUpdateBrowserUrl = true;
      state.currentPage = 1;
      state.selectedRecordIds = [];
    },
    setFilterInputOptions: (state, action) => {
      const { name, options } = action.payload;
      state.availableFilterInputs[stringToSnakeCase(name)].options = options;
    },
    addFilterSetToView: (state, action) => {
      state.savedView.filters.selectedFilterSetIds = [
        action.payload,
        ...state.savedView.filters.selectedFilterSetIds,
      ];
    },
    removeFilterSetFromView: (state, action) => {
      state.savedView.filters.selectedFilterSetIds = [
        ...state.savedView.filters.selectedFilterSetIds.filter(
          filterSetId => filterSetId !== action.payload,
        ),
      ];
      const newFilters = { ...state.savedView.filters };
      const filterSet = state.availableFilterSets[action.payload];
      filterSet.filterInputs.forEach(filterName => {
        delete newFilters[stringToCamelCase(filterName)];
      });
      state.savedView.filters = newFilters;
    },
    validateSavedViewFilters: state => {
      const { selectedFilterSetIds, ...filters } = {
        ...state.savedView.filters,
      };

      if (filters && selectedFilterSetIds?.length) {
        // Created Between Filter Set Validation
        state.filterSetErrors['Created Between'] = getFilterError(
          'date',
          filters.createdDateRangeStart,
          filters.createdDateRangeEnd,
        );

        // Updated Between Filter Set Validation
        state.filterSetErrors['Updated Between'] = getFilterError(
          'date',
          filters.updatedDateRangeStart,
          filters.updatedDateRangeEnd,
        );

        // Primary Start Date Filter Set Validation
        state.filterSetErrors['Primary Start Date'] = getFilterError(
          'date',
          filters.primaryStartDateRangeStart,
          filters.primaryStartDateRangeEnd,
        );

        // Graduation Year Filter Set Validation
        state.filterSetErrors['Graduation Year'] = getFilterError(
          'number',
          filters.gradDateRangeStart,
          filters.gradDateRangeEnd,
        );

        // Documents Filter Set Validation
        state.filterSetErrors.Documents =
          getFilterError(
            'date',
            filters.documentExpirationDateRangeStart,
            filters.documentExpirationDateRangeEnd,
          ) ||
          getFilterError(
            'date',
            filters.documentUploadedOnDateRangeStart,
            filters.documentUploadedOnDateRangeEnd,
          );

        // Assessesments Filter Set Validation

        state.filterSetErrors.Assessments = getFilterError(
          'date',
          filters.candidacyAssessmentStartDate,
          filters.candidacyAssessmentEndDate,
        );

        // Desired Compensation Filter Set Validation
        state.filterSetErrors['Desired Compensation'] =
          getFilterError(
            'number',
            filters?.preferredBaseMinimum,
            filters?.preferredBaseMaximum,
          ) ||
          getFilterError(
            'number',
            filters?.preferredBonusMinimum,
            filters?.preferredBonusMaximum,
          ) ||
          getFilterError(
            'percent',
            filters?.preferredEquityMinimum,
            filters?.preferredEquityMaximum,
          ) ||
          getFilterError(
            'number',
            filters?.preferredTotalCompensationMinimum,
            filters?.preferredTotalCompensationMaximum,
          );

        // Current Compensation Filter Set Validation
        state.filterSetErrors['Current Compensation'] =
          getFilterError(
            'number',
            filters?.baseMinimum,
            filters?.baseMaximum,
          ) ||
          getFilterError(
            'number',
            filters?.bonusMinimum,
            filters?.bonusMaximum,
          ) ||
          getFilterError(
            'percent',
            filters?.equityMinimum,
            filters?.equityMaximum,
          );

        // Overall Rating Filter Set Validation
        state.filterSetErrors['Overall Rating'] = getFilterError(
          'number',
          filters?.averageRatingMin,
          filters?.averageRatingMax,
        );

        // Age Between Filter Set Validation
        state.filterSetErrors['Age Between'] = getFilterError(
          'number',
          filters?.ageRangeMin,
          filters?.ageRangeMax,
        );

        // Gender Filter Set Validation

        // Check if gender filter is in use.
        if (filters?.genderIds?.length > 0) {
          // Determine whether results are being filtered by another filter.
          const isUsingAnotherFilter = [
            'affiliationIds',
            'searchIds',
            'industryBoolean',
            'sectorBoolean',
            'skillCategories',
            'positionBoolean',
            'searchNetworkIds',
            'keywordBoolean',
            'positionFunctionCategories',
          ].some(filterName => {
            return (
              filters[filterName]?.values?.length > 0 ||
              filters[filterName]?.length > 0
            );
          });
          state.filterSetErrors.Gender = isUsingAnotherFilter
            ? null
            : 'This option must be combined with one of the following filters: Searches, Skills, Industries, Sectors, Position Functions, Search Networks, Keyword, Job Title, or Affiliations.';
        } else {
          state.filterSetErrors.Gender = null;
        }
      }
    },
  },
});

const {
  addFilterSetToView,
  clearFilters,
  markAsStale,
  registerFilterInput,
  removeFilterSetFromView,
  requestRecordListBegin,
  requestRecordListError,
  requestRecordListSuccess,
  resetRecordId,
  resetView,
  setColumns,
  setCurrentPage,
  setFilterInputOptions,
  setFiltersToModalValues,
  setInitialSavedView,
  setNetworkId,
  setRecordTypeConfig,
  setResultsPerPage,
  setSavedView,
  setSelectedRecords,
  setSingleFilterValue,
  setSortParams,
  setupFilterSets,
  validateSavedViewFilters,
} = recordIndexSlice.actions;

const formatColumns = columns =>
  columns.map(column => {
    const { columnSize, key, ...rest } = column;
    return {
      name: key,
      width: columnSize,
      ...rest,
    };
  });

const handleResponse = (data, recordType) => {
  const response = convertToCamelCase(data);
  const {
    columns,
    csvExportRowLimit,
    totalPages,
    totalResults,
  } = response.metadata;

  const selectedColumns = formatColumns(columns);
  return {
    selectedColumns: selectedColumns,
    recordList: data[recordType].map(record => ({
      id: record[0].id,
      attributes: selectedColumns.map(column => ({
        ...record.find(attribute => column.name === attribute.key),
        ...column,
      })),
    })),
    totalPages: totalPages,
    totalResults: totalResults,
    csvExportRowLimit: csvExportRowLimit,
  };
};

const requestRecordList = () => (dispatch, getState) => {
  const {
    availableFilterInputs,
    currentPage,
    endpoints,
    initialSavedView,
    recordType,
    savedView,
    shouldUpdateBrowserUrl,
  } = getState().recordIndex;

  const isUsingV5UI = Boolean(Object.keys(availableFilterInputs || {})?.length);
  dispatch(requestRecordListBegin());

  const urlParams = convertToSnakeCase({
    resultsPerPage: savedView.resultsPerPage,
    networkId: savedView.networkId,
    sortDirection: savedView.sortDirection,
    sortField: savedView.sortField,
    filters: savedView.filters,
    columns: savedView.columns,
    page: currentPage,
  });

  return Api.get(
    endpoints.records({
      ...urlParams,
      version: isUsingV5UI ? 'v5' : 'v4',
      page: currentPage,
      view: 'table_view',
    }),
  )
    .then(json => handleResponse(json, recordType))
    .then(formattedResponse => {
      return dispatch(
        requestRecordListSuccess({
          ...formattedResponse,
          // We want to avoid updating the url on the first load when the user
          // is in a clean state.
          ...{
            shouldUpdateBrowserUrl:
              shouldUpdateBrowserUrl || !isEqual(savedView, initialSavedView),
          },
        }),
      );
    })
    .then(() => {
      if (getState().recordIndex.shouldUpdateBrowserUrl) {
        const newUrl = url.format({
          pathname: window.location.pathname,
          search: stringifyQueryObject(urlParams),
        });

        window.history.pushState(null, null, newUrl);
      }
    })
    .catch(error => dispatch(requestRecordListError(getErrorMessage(error))));
};

// Define the debounced action outside of the wrapper function so that `debounceAction` is
// not redefined every time `debounceRequestList` is called.
const debouncedAction = debounce(
  dispatch => dispatch(requestRecordList()),
  DEBOUNCE_INTERVAL_MS,
);
const debouncedRequestRecordList = () => dispatch => debouncedAction(dispatch);

const setLegacyFilters = (event = null) => (dispatch, getState) => {
  event?.preventDefault();
  const { filterAdapter, savedView } = getState().recordIndex;

  const filterParams = convertToCamelCase(
    filterAdapter.generateFilterParams() || {},
  );

  if (
    filterAdapter.shouldSortByRelevance(savedView.filters) ||
    filterAdapter.shouldSortByRelevance(filterParams)
  ) {
    dispatch(setSortParams({ sortDirection: null, sortField: 'relevance' }));
  } else {
    dispatch(
      setSortParams({ sortDirection: 'asc', sortField: DEFAULT_SORT_FIELD }),
    );
  }
  dispatch(setFiltersToModalValues());
  dispatch(requestRecordList());
  filterAdapter.closeModal();
};

const clearLegacyFilters = (event = null) => (dispatch, getState) => {
  event?.preventDefault();
  const { filterAdapter } = getState().recordIndex;

  // Set the input value of the RecordIndexHeader to ''
  filterAdapter.keywordInputEl().value = '';
  // Reset filter modal form
  filterAdapter.clearForm();
  // Populate filter state with reset modal values
  dispatch(setLegacyFilters());
};

const removeFilterSetAndSyncView = filterSetId => dispatch => {
  dispatch(removeFilterSetFromView(filterSetId));
  return dispatch(requestRecordList());
};

const setAndValidateSingleFilterValue = ({ name, value }) => dispatch => {
  dispatch(setSingleFilterValue({ name: name, value: value }));
  return dispatch(validateSavedViewFilters());
};

const selectHasRegisteredFilterInput = (state, name) =>
  Object.prototype.hasOwnProperty.call(
    { ...state.recordIndex.initialSavedView.filters },
    stringToCamelCase(name),
  );

const selectFilterInputData = (state, name) =>
  state.recordIndex.availableFilterInputs[stringToSnakeCase(name)];

const selectFilterSets = state => state.recordIndex.availableFilterSets;
const selectSectionName = (_state, sectionName) => sectionName;
const selectFilterSetIdsInSection = createSelector(
  [selectFilterSets, selectSectionName],
  (filterSets, sectionName) =>
    Object.keys(filterSets).filter(
      filterSetId => filterSets[filterSetId].section === sectionName,
    ),
);

// Use the redux docs recommended "Selector factory" pattern to allow for a single selector function to be reused
// with differing inputs.  -- https://redux.js.org/usage/deriving-data-selectors#selector-factories
const makeSelectFilterSetIdsInSection = () => selectFilterSetIdsInSection;

export {
  ALL_RECORDS_NETWORK_ID,
  addFilterSetToView,
  clearFilters,
  clearLegacyFilters,
  debouncedRequestRecordList,
  DEFAULT_SORT_FIELD,
  markAsStale,
  resetRecordId,
  resetView,
  removeFilterSetAndSyncView,
  requestRecordList,
  setColumns,
  setCurrentPage,
  setLegacyFilters,
  setInitialSavedView,
  setNetworkId,
  setRecordTypeConfig,
  setResultsPerPage,
  setSavedView,
  setSelectedRecords,
  setSortParams,
  registerFilterInput,
  setAndValidateSingleFilterValue,
  selectFilterInputData,
  setFilterInputOptions,
  setupFilterSets,
  selectHasRegisteredFilterInput,
  makeSelectFilterSetIdsInSection,
  validateSavedViewFilters,
};

export default recordIndexSlice;
