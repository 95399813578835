import createAjaxAction, {
  createPostAction,
  createPatchAction,
  createDeleteAction,
} from './createAjaxAction';
import createAction from './createAction';

/**
 * @typedef {Object} AjaxActionOptions
 * @property {String} start The action type dispatched when the ajax action is started
 * @property {String} success The action type dispatched when the ajax action finishes successfully
 * @property {String} failure The action type dispatched when the ajax action fails
 * @property {...} [params] Additional parameters to pass through to createAjaxAction.
 */

/**
 * @typedef {Object} CrudActions
 * @property {Function} fetchAll The action creator for fetching all collection items
 * @property {Function} fetchAllIfNeeded The action creator for fetching all collection items
 *   only if needed. Expects the current collection state.
 * @property {Function} invalidateAll The action creator for invalidating the collection
 * @property {Function} fetch The action creator for fetching a single item
 * @property {Function} fetchIfNeeded The action creator for fetching a single item only if
 *   needed. Expects the current item state.
 * @property {Function} invalidate The action creator for invalidating a single item.
 * @property {Function} create The action creator for creating a single item.
 * @property {Function} update The action creator for updating a single item.
 * @property {Function} delete The action creator for deleting a single item.
 */

/**
 * Creates an object that contains common CRUD actions
 * @param {AjaxActionOptions} fetchAll [description]
 * @param {String} invalidateAll The action type dispatched to invalidate the collection
 * @param {AjaxActionOptions} fetch The options passed to createAjaxAction for the fetch action
 * @param {String} invalidate The action type dispatched to invalidate a single item
 * @param {AjaxActionOptions} create The options passed to createPostAction for the create action
 * @param {AjaxActionOptions} update The options passed to createPatchAction for the update action
 * @param {AjaxActionOptions} delete The options passed to createDeleteAction for the delete action
 * @param {Object} [common] Additional options are passed to all variants of `createAjaxAction`
 *    (so these are passed for the `fetch`, `fetchAll`, `create`, `update`, and `delete` actions)
 * @return {CrudActions} The CRUD action creators
 */
export default function createCrudActions({
  create,
  delete: deleteOptions,
  fetch,
  fetchAll,
  invalidate,
  invalidateAll,
  update,
  ...common
}) {
  const actions = {};

  actions.fetchAll = fetchAll && createAjaxAction({ ...common, ...fetchAll });
  actions.invalidateAll = invalidateAll && createAction(invalidateAll);
  actions.fetch = fetch && createAjaxAction({ ...common, ...fetch });
  actions.invalidate = invalidate && createAction(invalidate);
  actions.create = create && createPostAction({ ...common, ...create });
  actions.update = update && createPatchAction({ ...common, ...update });
  actions.delete =
    deleteOptions && createDeleteAction({ ...common, ...deleteOptions });

  return actions;
}
