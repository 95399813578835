import { Map } from 'immutable';
import { isEqual } from 'lodash';
import createFetchReducer from './createFetchReducer';

const initialState = new Map();

export default ({
  create,
  destroy,
  failure,
  invalidate,
  reset,
  start,
  success,
  ...fetchConfig
}) => {
  const fetchReducer = createFetchReducer({
    start: start,
    success: success,
    failure: failure,
    invalidate: invalidate,
    reset: reset,
    ...fetchConfig,
  });

  return function reducer(state = initialState, action) {
    switch (action.type) {
      case create: {
        if (state.has(action.payload.queryId)) {
          // If two components share the same queryId, the create action can be
          // called twice, potentially wiping out existing data. So if we've
          // already created the state for this queryId, we don't need to create it
          // again.
          return state;
        }

        return state.set(action.payload.queryId, fetchReducer(undefined, {}));
      }
      case destroy:
        return state.delete(action.payload.queryId);
      case start:
        return state.update(action.payload.queryId, query =>
          fetchReducer(query, action).set('term', action.payload.term),
        );
      case success:
      case failure:
      case invalidate:
        if (
          isEqual(
            state.getIn([action.payload.queryId, 'term']),
            action.payload.term,
          )
        ) {
          // We need to make sure that by the time we get the success or failure action, that
          // we still have the same search term (we don't want another request to for the wrong
          // term that may have been sent earlier but took longer to return to clobber the
          // results with those from the wrong term).
          return state.update(action.payload.queryId, query =>
            fetchReducer(query, action),
          );
        }
        return state;
      default:
        return state;
    }
  };
};
