import { connect } from 'react-redux';
import getTransaction from '../selectors/getTransaction';

/**
 * A higher order component which maps a `transactionId` prop value to it's transaction record.
 */
export default connect(
  (state, { transactionId }) => ({
    transaction: transactionId && getTransaction(state, transactionId),
  }),
  {},
);
