import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import Modal from '@thrivetrm/ui/components/Modal';
import { URL_REGEX } from 'modules/core/urlUtils';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const LinkModal = ({ onClose, onConfirm }) => {
  const [link, setLink] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, []);

  const handleConfirm = () => {
    onConfirm(link);
  };

  return (
    <Modal isOpen={true} onClose={onClose} size='small' title='Insert Link'>
      <Modal.Body>
        <label className='TextInput__label' htmlFor='link-input'>
          *Url
        </label>
        <input
          className='TextInput__input u-flexItem-grow u-width-100'
          id='link-input'
          onChange={e => setLink(e.target.value)}
          placeholder='Enter url here'
          ref={inputRef}
          value={link}
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonPrimary
          isDisabled={!(link && URL_REGEX.test(link))}
          label='Insert'
          onClick={handleConfirm}
        />
        <ButtonSecondary label='Cancel' onClick={onClose} />
      </Modal.Footer>
    </Modal>
  );
};

LinkModal.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};
export default LinkModal;
