import { connect } from 'react-redux';
import getCandidacyContactId from '../selectors/getCandidacyContactId';

/**
 * A higher order component which maps a `candidacyId` prop value to it's related
 * `contactId` value.`
 */
export default connect(
  (state, { candidacyId }) => ({
    contactId: getCandidacyContactId(state, candidacyId),
  }),
  {},
);
