import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  setStatic,
  onlyUpdateForPropTypes,
  setDisplayName,
} from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';

import FieldState from 'modules/forms/FieldState';
import ReactSelectField from 'modules/forms/components/ReactSelectField';
import { useSelector } from 'react-redux';
import getCurrentUserId from 'modules/user/selectors/getCurrentUserId';

import mapUserIdsToUsers from './mapUserIdsToUsers';
import withUserListFetched from './withUserListFetched';

const UserSelectField = ({
  fieldState,
  multi,
  onChange,
  userList,
  users,
  ...otherProps
}) => {
  const currentUserId = useSelector(getCurrentUserId);
  const handleSelectFieldChange = field => {
    //  ReactSelect returns the value we provided in the options, so it will be something like:
    //  ```
    //    {
    //      id: 4,
    //      name: 'John Doe'
    //    }
    // ```
    // But we just want the underlying user ID value.
    const value = field.getValue();

    if (multi) {
      onChange(field.setValue(value.map(item => item.id)));
    } else {
      onChange(field.setValue(value && value.id));
    }
  };

  const userOptions = users?.toJS().map(user => ({
    id: user.id,
    name: user.id === currentUserId ? `${user.name} (Me)` : user.name,
  }));

  return (
    <ReactSelectField
      {...otherProps}
      fieldState={fieldState}
      isLoading={userList.getIn(['_meta', 'isFetching'])}
      labelKey='name'
      multi={multi}
      onChange={handleSelectFieldChange}
      options={userOptions}
      valueKey='id'
    />
  );
};

UserSelectField.propTypes = {
  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  multi: PropTypes.bool,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,

  userList: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isFetching: PropTypes.bool,
    }),
  }),

  users: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

export default compose(
  setDisplayName('UserSelectField(enhanced)'),
  setStatic('createFieldState', FieldState.create),
  withUserListFetched,
  mapUserIdsToUsers,
  onlyUpdateForPropTypes,
)(UserSelectField);
