import { createSlice } from '@reduxjs/toolkit';
import Api, { getErrorMessage } from 'modules/core/Api';
import { singular } from 'pluralize';

const initialState = {
  availableColumnKeys: [],
  columnDefinitions: [],
  defaultColumnKeys: [],
  loadAvailableColumnsError: null,
  isLoadingAvailableColumns: true,
  isSavingSelectedColumns: false,
  originalSelectedColumnKeys: [],
  pinnedColumnKeys: [],
  saveColumnsError: null,
  selectedColumnKeys: [],
};

const availableColumnsSlice = createSlice({
  name: 'configureColumns',
  initialState: initialState,
  reducers: {
    loadAvailableColumnsBegin: state => {
      state.isLoadingAvailableColumns = true;
    },
    loadAvailableColumnsSuccess: (state, action) => {
      const {
        availableColumnKeys,
        columnDefinitions,
        defaultColumnKeys,
        pinnedColumnKeys,
      } = action.payload;

      state.availableColumnKeys = availableColumnKeys;
      state.columnDefinitions = columnDefinitions;
      state.defaultColumnKeys = defaultColumnKeys;
      state.loadAvailableColumnsError = null;
      state.isLoadingAvailableColumns = false;
      state.pinnedColumnKeys = pinnedColumnKeys;
    },
    loadAvailableColumnsError: (state, action) => {
      state.isLoadingAvailableColumns = false;
      state.loadAvailableColumnsError = action.payload;
    },
    addColumn: (state, action) => {
      state.selectedColumnKeys.push(action.payload.columnKey);
    },
    removeColumn: (state, action) => {
      state.selectedColumnKeys = state.selectedColumnKeys.filter(
        key => key !== action.payload.columnKey,
      );
    },
    removeAllColumns: state => {
      state.selectedColumnKeys = [];
    },
    reorderColumns: (state, action) => {
      state.selectedColumnKeys = action.payload.columnKeys;
    },
    resetColumnsToDefault: state => {
      state.selectedColumnKeys = state.defaultColumnKeys;
    },
    restoreColumnsToOriginal: state => {
      state.selectedColumnKeys = state.originalSelectedColumnKeys;
    },
    saveColumnsBegin: state => {
      state.isSavingSelectedColumns = true;
      state.saveColumnsError = null;
    },
    saveColumnsSuccess: (state, action) => {
      state.isSavingSelectedColumns = false;
      state.saveColumnsError = null;
      state.originalSelectedColumnKeys = action.payload.selectedColumnKeys;
    },
    saveColumnsError: (state, action) => {
      state.isSavingSelectedColumns = false;
      state.saveColumnsError = action.payload;
    },
    setInitialColumns: (state, action) => {
      state.originalSelectedColumnKeys = action.payload;
      state.selectedColumnKeys = action.payload;
    },
  },
});

const {
  addColumn,
  loadAvailableColumnsBegin,
  loadAvailableColumnsError,
  loadAvailableColumnsSuccess,
  removeAllColumns,
  removeColumn,
  reorderColumns,
  resetColumnsToDefault,
  restoreColumnsToOriginal,
  saveColumnsBegin,
  saveColumnsError,
  saveColumnsSuccess,
  setInitialColumns,
} = availableColumnsSlice.actions;

const transformColumnDefinitions = columnDefinitions => {
  const transformedDefinitions = { ...columnDefinitions };
  Object.entries(transformedDefinitions).forEach(([key, definition]) => {
    transformedDefinitions[key] = {
      label: definition.label,
      value: definition.key,
    };
  });
  return transformedDefinitions;
};

const loadAvailableColumns = recordType => dispatch => {
  dispatch(loadAvailableColumnsBegin(recordType));
  const url = `/api/v1/${recordType}/available_columns`;
  Api.get(url)
    .then(response => {
      dispatch(
        loadAvailableColumnsSuccess({
          availableColumnKeys: response.available_column_keys,
          columnDefinitions: transformColumnDefinitions(
            response.column_definitions,
          ),
          defaultColumnKeys: response.default_column_keys,
          pinnedColumnKeys: response.pinned_column_keys,
          selectedColumnKeys: response.selected_column_keys,
        }),
      );
    })
    .catch(error => {
      dispatch(loadAvailableColumnsError(getErrorMessage(error)));
    });
};

const saveColumns = (recordType, onSave) => (dispatch, getState) => {
  dispatch(saveColumnsBegin());
  const userId = getState().user.get('current_user_id');
  const url = `/api/user_options/${userId}`;
  const userPreferenceKey = `${singular(recordType)}_columns`;
  const payload = {
    userId: userId,
    user: {
      [userPreferenceKey]: getState().configureColumns.selectedColumnKeys,
    },
  };
  Api.patch(url, payload)
    .then(response => {
      const selectedColumnKeys =
        response.userOptions.column_preferences[userPreferenceKey];
      dispatch(
        saveColumnsSuccess({
          selectedColumnKeys: selectedColumnKeys,
        }),
      );
      onSave(selectedColumnKeys);
    })
    .catch(error => {
      dispatch(saveColumnsError(getErrorMessage(error)));
    });
};

export {
  loadAvailableColumns,
  addColumn,
  removeAllColumns,
  removeColumn,
  reorderColumns,
  resetColumnsToDefault,
  restoreColumnsToOriginal,
  saveColumns,
  setInitialColumns,
};
export default availableColumnsSlice;
