import getContact from 'modules/contacts/selectors/contacts/getContact';
import getCandidacyContactId from './getCandidacyContactId';

/**
 * Gets the contact record related to a candidacy.
 * @param {Object} state
 * @param {Number} id The candidacy ID
 * @return {?Immutable.Map} The contact record for the candidacy.
 */
const getCandidacyContact = (state, candidacyId) => {
  const contactId = getCandidacyContactId(state, candidacyId);
  return contactId && getContact(state, contactId);
};

export default getCandidacyContact;
