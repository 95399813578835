import getEducations from './getEducations';

/**
 * Gets a value indicating whether educations need to be fetched for a given contact.
 * @param {Object} state
 * @param {Number} contactId The contact's ID
 * @returns {Boolean} True if the educations should be fetched from the server; false otherwise.
 */
export default (state, contactId) => {
  const educations = getEducations(state, contactId);

  if (!educations) {
    return true;
  }

  if (educations.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (educations.hasIn(['_meta', 'error'])) {
    return false;
  }

  return (
    !educations.has('ids') ||
    Boolean(educations.getIn(['_meta', 'isInvalidated']))
  );
};
