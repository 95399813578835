import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import allTalentPools from './allTalentPools';
import talentPoolIdByContactId from './talentPoolIdByContactId';

const talentPoolsReducer = combineImmutableReducers({
  allTalentPools: allTalentPools,
  talentPoolIdByContactId: talentPoolIdByContactId,
});

talentPoolsReducer.NAME = NAME;

export default talentPoolsReducer;
