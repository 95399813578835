import selectn from 'selectn';
import getAssessmentProperty from 'modules/assessments/selectors/getAssessmentProperty';
import { assessmentSchema } from 'modules/assessments/schema';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import isEntityType from 'modules/entities/isEntityType';
import invalidateCandidacyStatus from '../actions/invalidateCandidacyStatus';

/**
 * When an assessment is created, updated, or deleted, invalidate the status of the
 * underlying candidacy record so they will be refetched from the server when they are next needed.
 */
export default ({ action, dispatch, getState }) => {
  if (isEntityType(action, assessmentSchema.key)) {
    switch (action.type) {
      case entityActionTypes.CREATE_SUCCESS:
      case entityActionTypes.UPDATE_SUCCESS:
      case entityActionTypes.DELETE_SUCCESS: {
        const isDelete = action.type === entityActionTypes.DELETE_SUCCESS;
        const assessmentId = selectn(
          isDelete ? 'payload.id' : 'payload.result.assessment',
          action,
        );
        const state = getState();
        const candidacyId = getAssessmentProperty(
          state,
          assessmentId,
          'candidacy_id',
        );

        // Invalidate the candidacy status so it is updated with the latest assessment info.
        if (candidacyId) {
          dispatch(invalidateCandidacyStatus({ id: candidacyId }));
        }
        break;
      }
      default: {
        // noop
      }
    }
  }
};
