import routes from 'modules/routing/routes';
import { TYPE_PIPELINE } from 'modules/searches/constants';
import createQueryActions from '../../../../actions/createQueryActions';
import {
  QUERY_CREATE,
  QUERY_DESTROY,
  QUERY_INVALIDATE,
  QUERY_FETCH_START,
  QUERY_FETCH_SUCCESS,
  QUERY_FETCH_FAILURE,
} from './actionTypes';
import { searchSchema } from '../../schema';

export const {
  create: createQuery,
  destroy: destroyQuery,
  invalidate: invalidateQuery,
  query: fetchQuery,
} = createQueryActions({
  getUrl: ({ candidacyId, contactId, restricted, searchType, term }) =>
    routes.api_v1_autocomplete_query({
      query: {
        query: {
          resource: searchType === TYPE_PIPELINE ? 'pipeline' : 'search',
          term: term,
          restricted: restricted,
          searchType: searchType,
          contactId: contactId,
          candidacyId: candidacyId,
        },
      },
    }),
  schema: [searchSchema],
  create: QUERY_CREATE,
  destroy: QUERY_DESTROY,
  invalidate: QUERY_INVALIDATE,
  start: QUERY_FETCH_START,
  success: QUERY_FETCH_SUCCESS,
  failure: QUERY_FETCH_FAILURE,
});
