//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'recommendations';

export const NOT_A_FIT_LABEL = 'Not a Fit';

export const RECOMMENDED_LABEL = 'Recommended';

//---------------------------------------------------------
// Parent types (types of recommendations list collections)
//---------------------------------------------------------

/**
 * Used to indicate a list of recommendations belonging to a search
 */
export const PARENT_SEARCH = 'search';

/**
 * Used to indicate a list of recommendations belongning to a conctact
 */
export const PARENT_CONTACT = 'contact';

/**
 * All available parent types that can be used.
 */
export const PARENT_TYPES = [PARENT_SEARCH, PARENT_CONTACT];

//---------------------------------------------------------
// Sorting
//---------------------------------------------------------

/**
 * Sort based on the date the recommendations record was created
 */
export const SORT_BY_RECOMMENDED_DATE = 'SORT_BY_RECOMMENDED_DATE';

/**
 * Sort based on the candidate's full name.
 */
export const SORT_BY_NAME = 'SORT_BY_NAME';

/**
 * Sort based on rank
 */
export const SORT_BY_SCORE = 'SORT_BY_SCORE';

/**
 * All valid SORT_BY_* type constants.
 * @const {Array}
 */
export const SORT_BY_TYPES = [
  SORT_BY_NAME,
  SORT_BY_SCORE,
  SORT_BY_RECOMMENDED_DATE,
];

/**
 * The label that should be displayed for a given sort type.
 * @type {Object}
 */
export const SORT_BY_LABELS = {
  [SORT_BY_NAME]: 'Name',
  [SORT_BY_SCORE]: 'Score',
  [SORT_BY_RECOMMENDED_DATE]: 'Recommended Date',
};

export const SORT_BY_DEFAULT = SORT_BY_SCORE;

export const SORT_BY_OPTIONS = Object.keys(SORT_BY_LABELS);

/**
 * Which sort direction should be the default direction initially.
 */
export const SORT_ASCENDING_DEFAULTS = {
  [SORT_BY_NAME]: true,
  [SORT_BY_SCORE]: false,
  [SORT_BY_RECOMMENDED_DATE]: false,
};

export const ASCENDING_SORT_ONLY = [SORT_BY_SCORE];

export const SORT_ASCENDING_DEFAULT = SORT_ASCENDING_DEFAULTS[SORT_BY_DEFAULT];

export const RECOMMEND_MORE = 'RECOMMEND_MORE';

export const NOT_A_FIT = 'NOT_A_FIT';

export const RECOMMEND = 'RECOMMENDED';

export const MOVE_TO_CANDIDATES = 'MOVE_TO_CANDIDATES';

export const THRIVE_AI = 'Thrive AI';
