import moment from 'moment';

const dateRange = fieldState => {
  const startDate = moment(
    fieldState.getNestedField('startDate').getValue(),
    'MM-DD-YYYY',
  );
  const endDate = moment(
    fieldState.getNestedField('endDate').getValue(),
    'MM-DD-YYYY',
  );

  if (startDate && endDate && startDate >= endDate) {
    return 'Start Date must be before End Date';
  }

  return null;
};

export default dateRange;
