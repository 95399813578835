import { NAME } from '../constants';

/**
 * Gets the list state of activities that belong to a particular parent (parent type + id)
 * @param {Object} state
 * @param {String} parentType The parent type (PARENT_SEARCH or PARENT_CANDIDACY)
 * @param {Number} parentId The parent ID -- either a contact ID when
 *   parentType === PARENT_SEARCH, or a candidacy ID when parentType === PARENT_CANDIDACY
 * @return {Immutable.Map|undefined} The list state, if it's been created (it won't be created
 *   until an action related to it has been dispatched)
 */
const getActivityList = (state, parentType, parentId) =>
  state[NAME].getIn(['activityListsByParentType', parentType, parentId]);

export default getActivityList;
