import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withState,
} from 'recompose';
import classnames from 'classnames';
import Overlay from 'react-bootstrap/lib/Overlay';
import Popover from 'react-bootstrap/lib/Popover';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import withScrollContainerRef from 'modules/core/componentsLegacy/withScrollContainerRef';

/**
 * A generic button for voting. Displays either a thumbs-up or thumbs-down
 * button, and an optional total vote count number.
 */
const VoteButton = ({
  checked,
  className,
  componentId,
  disabled,
  getScrollContainer,
  getTargetRef,
  handleHideVoteForm,
  handleVoteButtonClick,
  handleVoteCancel,
  handleVoteSaved,
  setTargetRef,
  showForm,
  showTooltip,
  toolTipText,
  value,
  voteCount,
  voteFormComponent: VoteForm,
  voteFormProps,
  voteFormState,
  voteFormTitle,
}) => [
  <OverlayTrigger
    key='tooltip'
    overlay={
      <Tooltip id={`${componentId}-not-a-fit`}>
        <span>{toolTipText}</span>
      </Tooltip>
    }
    placement='left'
    trigger={showTooltip ? ['hover', 'focus'] : null}
  >
    <button
      className={classnames(
        className,
        'btn',
        'VoteButton',
        'btn-default',
        'btn-toggle',
        {
          active: checked,
        },
      )}
      disabled={disabled}
      key='button'
      onClick={handleVoteButtonClick}
      ref={setTargetRef}
      type='button'
    >
      <i
        className={classnames('fa', {
          'fa-thumbs-up': value === 1,
          'fa-thumbs-down': value === -1,
        })}
      />
      {typeof voteCount === 'number' && voteCount}
    </button>
  </OverlayTrigger>,
  VoteForm && (
    <Overlay
      aria-expanded={showForm}
      aria-haspopup='true'
      container={getScrollContainer}
      containerPadding={20}
      id={`${componentId}-overlay`}
      key='overlay'
      onHide={handleHideVoteForm}
      placement='bottom'
      rootClose={true}
      show={showForm}
      target={getTargetRef}
    >
      <Popover
        className='VoteButton__popover'
        id={`${componentId}-popover`}
        title={voteFormTitle}
      >
        <VoteForm
          initialFormState={voteFormState}
          {...voteFormProps}
          onCancel={handleVoteCancel}
          onSaved={handleVoteSaved}
        />
      </Popover>
    </Overlay>
  ),
];

VoteButton.propTypes = {
  /**
   * Props provided by withScrollContainerRef (getScrollContainer, getTargetRef, setTargetRef)
   */
  ...withScrollContainerRef.propTypes,

  /**
   * Whether the button is depressed (indicating that this is the
   * current vote)
   */
  checked: PropTypes.bool.isRequired,

  className: PropTypes.string,

  /**
   * A unique ID for the component instance (provided by `withComponentId`)
   */
  componentId: PropTypes.string.isRequired,

  /**
   * Whether the button is disabled
   */
  disabled: PropTypes.bool,

  /**
   * Called when the vote form should be hidden (ideally causing `showForm` to be set to `false`)
   */
  handleHideVoteForm: PropTypes.func.isRequired,

  /**
   * Called when the button is clicked, causing the `showForm` value to be toggled
   * between true and false.
   */
  handleVoteButtonClick: PropTypes.func.isRequired,

  /**
   * Called when the vote form was cancelled.
   */
  handleVoteCancel: PropTypes.func.isRequired,

  /**
   * Called when the vote form was successfully submitted
   */
  handleVoteSaved: PropTypes.func.isRequired,

  /**
   * Whether the vote form should be rendered.
   */
  showForm: PropTypes.bool.isRequired,

  /**
   * Whether the tooltip on the vote button should be rendered.
   */
  showTooltip: PropTypes.bool,

  /**
   * Text to show in the tooltip
   */
  toolTipText: PropTypes.string,

  /**
   * The vote value: `1` for "thumbs-up"/"priority"; `-1` for "thumbs-down"/"not a fit"
   */
  value: PropTypes.oneOf([-1, 1]).isRequired,

  /**
   * The current number of votes for this value. If provided it is displayed
   * next to the button icon
   */
  voteCount: PropTypes.number,

  /**
   * The form to show in the popover to handle the voting.
   */
  voteFormComponent: PropTypes.func,

  /**
   * Additional props to pass to the `voteFormComponent`
   */
  voteFormProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types

  /**
   * The previous form state from the last time the voteForm was show.
   * This allows the vote form to keep it's content when the user hits cancel
   * or closes the form (perhaps accidentally), so they don't use any information
   * typed into the form.
   */
  voteFormState: PropTypes.any, // eslint-disable-line react/forbid-prop-types

  /**
   * The title to display in the popover when voting is in progress
   */
  voteFormTitle: PropTypes.string,
};

VoteButton.defaultProps = {
  disabled: false,
  showTooltip: false,
};

export default compose(
  setDisplayName('VoteButton(enhanced)'),
  setPropTypes({
    checked: VoteButton.propTypes.checked,

    /**
     * Callback when the vote button is clicked. First parameter is the vote value.
     */
    onVoteClick: PropTypes.func,
    value: VoteButton.propTypes.value,
    voteCount: VoteButton.propTypes.voteCount,
    voteFormComponent: VoteButton.propTypes.voteFormComponent,
    voteFormProps: VoteButton.propTypes.voteFormProps,
    voteFormTitle: VoteButton.propTypes.voteFormTitle,
  }),

  // Unique ID and scroll container is needed for the overlay
  withComponentId(),
  withScrollContainerRef,

  // Determines whether we're currently showing the form popover.
  withState('showForm', 'setShowForm', false),

  // If the form is closed without saving, we'll hold on to the state to repopulate it if
  // it's opened again (in case "cancel" was hit by accident, for example).
  withState('voteFormState', 'setVoteFormState'),

  // These handlers manage the showing/hiding of the popover form, as
  // well as saving the state of the form when the cancel button is clicked.
  withHandlers({
    handleVoteButtonClick: ({
      onVoteClick,
      setShowForm,
      showForm,
      value,
      voteFormComponent,
    }) => () => {
      if (onVoteClick) {
        onVoteClick(value);
      }

      if (voteFormComponent) {
        setShowForm(!showForm);
      }
    },
    handleHideVoteForm: ({ setShowForm }) => () => setShowForm(false),
    handleVoteSaved: ({ setShowForm, setVoteFormState }) => () => {
      setShowForm(false);
      setVoteFormState();
    },
    handleVoteCancel: ({ setShowForm, setVoteFormState }) => voteFormState => {
      setShowForm(false);
      setVoteFormState(voteFormState);
    },
  }),
)(VoteButton);
