import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import routes from 'modules/routing/routes';
import { useApiPost } from 'modules/core/hooks/useApi';
import {
  toastError,
  toastSuccess,
} from 'modules/toast-notifications/toastNotificationsSlice';
import ExternalLink from 'modules/links/ExternalLink';
import StatusIndicator from '@thrivetrm/ui/components/StatusIndicator';
import SkyminyrCompanySelectModal from './SkyminyrCompanySelectModal';
import { getSkyminyrCompanyCreatePayload } from './getSkyminyrCompanyCreatePayload';

const SkyminyrCompanyCreateButton = ({
  buttonLabel,
  buttonSize,
  canSelectThriveRecords,
  className,
  initialInputValue,
  onCancel,
  onClick,
  onCreate,
  queryParams,
  shouldNavigateToNewCompany,
}) => {
  const dispatch = useDispatch();
  const [isModalOpen, openModal, closeModal] = useToggle(false);

  const [createCompany, isCreatingCompany] = useApiPost(
    routes.api_v1_companies(),
  );

  const handleSubmit = ({ company }) => {
    createCompany(
      { company: getSkyminyrCompanyCreatePayload(company.data) },
      {
        onSuccess: response => {
          closeModal();
          if (response.company) {
            onCreate?.(response.company);
            dispatch(
              toastSuccess(
                <div>
                  <p className='u-margin-n'>
                    {response.company.name} was added to your database
                  </p>
                  <a href={`/companies/${response.company.id}`}>View Company</a>
                </div>,
                { title: 'New Company Added' },
              ),
            );
          }
        },
        onError: errorMessage => {
          dispatch(
            toastError(errorMessage, { title: 'Could not add company' }),
          );
        },
      },
    );
  };

  return (
    <>
      <ButtonPrimary
        className={classNames('u-noWrap', className)}
        icon='add'
        key='button'
        label={buttonLabel}
        onClick={() => {
          onClick?.();
          openModal();
        }}
        size={buttonSize}
      />
      <SkyminyrCompanySelectModal
        actionConfirmationText={
          <>
            <StatusIndicator
              className='u-marginTop-4 u-marginRight-4'
              status='info'
            />
            <p className='u-margin-n u-textColor-gray60'>
              This company will be added to your database, which will
              automatically update with latest metadata.
            </p>
          </>
        }
        canCreateNewCompany={true}
        canSelectThriveRecords={canSelectThriveRecords}
        companySearchGuidance={
          <p className='u-textAlign-c u-textColor-blue'>
            Search for a company, which <br /> automatically populates with
            <br /> enriched data.{' '}
            <ExternalLink
              className='SkyminyrCompanySelect__learnMore'
              href='https://helpcenter.thrivetrm.com/en/'
            >
              Learn more
            </ExternalLink>
          </p>
        }
        initialInputValue={initialInputValue}
        isLoading={isCreatingCompany}
        isOpen={isModalOpen}
        onClose={() => {
          closeModal();
          onCancel?.();
        }}
        onCreate={onCreate}
        onSubmit={handleSubmit}
        queryParams={queryParams}
        shouldNavigateToNewCompany={shouldNavigateToNewCompany}
        submitButtonLabel='Add Company'
        title='Add New Company'
      />
    </>
  );
};

SkyminyrCompanyCreateButton.defaultProps = {
  buttonLabel: 'New Company',
  buttonSize: 'medium',
  canSelectThriveRecords: false,
  shouldNavigateToNewCompany: true,
};
SkyminyrCompanyCreateButton.propTypes = {
  buttonLabel: PropTypes.string,
  buttonSize: ButtonPrimary.propTypes.size,
  canSelectThriveRecords: PropTypes.bool,
  className: PropTypes.string,
  initialInputValue: PropTypes.string,
  onCancel: PropTypes.func,
  onClick: PropTypes.func,
  onCreate: PropTypes.func,
  queryParams: PropTypes.shape({
    resource: PropTypes.string,
  }),
  shouldNavigateToNewCompany: PropTypes.bool,
};
export default SkyminyrCompanyCreateButton;
