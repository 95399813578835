import { NOTIFICATIONS_CREATED } from '../../domains/notifications/constants';

export default function maybeDispatchNotificationsCreated(
  successAction,
  itemKey,
) {
  return dispatch => {
    const { notifications, ...resource } = successAction.payload.data[itemKey];

    if (notifications) {
      const notificationsWithResource = notifications.map(notification => ({
        ...notification,
        resource: resource,
      }));

      dispatch({
        type: NOTIFICATIONS_CREATED,
        payload: {
          ...successAction.payload,
          data: { notifications: notificationsWithResource },
        },
      });
    }
  };
}
