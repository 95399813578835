import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import identity from '@thrivetrm/ui/utilities/identity';
import FieldState from '../FieldState';

export const currentTargetChangeHandler = event => event.currentTarget.value;

/**
 * Normalizes a component as a controlled `Field`, so that it takes `fieldState` and `onChange`
 * props, where `onChange` is called with an updated `fieldState` value when the underlying field
 * value is changed.
 *
 * @param {Component} BaseComponent the component to normalize
 * @param {Object} params Optional parameters
 * @param {Function} [params.handleValueChange=identity] A function that recieves
 *   the BaseComponent's `onChange` callback parameters, and returns the underlying value that
 *   should be assigned to the `FieldState`'s value'.
 * @param {Function} [params.convertInputValue=identity] A function that converts the underlying
 *   `FieldState`'s value before passing ito the base component.
 * @return {Field} a controller field component expecting a `fieldState`
 */
const asField = ({
  handleValueChange = identity,
  convertInputValue = identity,
} = {}) => BaseComponent => {
  class Field extends PureComponent {
    handleChange = (...args) => {
      const { fieldState, onChange } = this.props;
      onChange(fieldState.setValue(handleValueChange(...args)));
    };

    render() {
      const {
        fieldState,

        // Ignored fields to exclude from ...otherProps
        /* eslint-disable no-unused-vars */
        onChange,
        /* eslint-enable no-unused-vars */

        ...otherProps
      } = this.props;

      return (
        <BaseComponent
          {...otherProps}
          name={fieldState.getName()}
          onChange={this.handleChange}
          value={convertInputValue(fieldState.getValue())}
        />
      );
    }
  }

  Field.propTypes = {
    /**
     * The FieldState that manages the value of the control.
     */
    fieldState: PropTypes.instanceOf(FieldState).isRequired,

    /**
     * Called when the field is changed with the updated FieldState object.
     */
    onChange: PropTypes.func.isRequired,
  };

  return Field;
};

export default asField;
