import { EditorState, CharacterMetadata, Modifier } from 'draft-js';
import { Map } from 'immutable';

/**
 * Clears all formatting from the current EditorState's selected range.
 * @param {EditorState} editorState The current editor's EditorState.
 * @return {EditorState} An updated EditorState with the styles from the current selection removed.
 */
const clearFormatting = editorState => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const blockMap = contentState.getBlockMap();
  const startKey = selectionState.getStartKey();
  const startOffset = selectionState.getStartOffset();
  const endKey = selectionState.getEndKey();
  const endOffset = selectionState.getEndOffset();

  // First replace the CharacterMetadata for each character of each block within the selection
  // with a new CharacterMetadata object. This removes all style and entity information from
  // them.
  const newBlocks = blockMap
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === endKey)
    .concat(new Map([[endKey, blockMap.get(endKey)]]))
    .map((block, blockKey) => {
      let sliceStart;
      let sliceEnd;

      if (startKey === endKey) {
        sliceStart = startOffset;
        sliceEnd = endOffset;
      } else {
        sliceStart = blockKey === startKey ? startOffset : 0;
        sliceEnd = blockKey === endKey ? endOffset : block.getLength();
      }

      let chars = block.getCharacterList();
      while (sliceStart < sliceEnd) {
        chars = chars.set(sliceStart, CharacterMetadata.create());
        sliceStart += 1;
      }

      return block.set('characterList', chars);
    });

  let newContentState = contentState.merge({
    blockMap: blockMap.merge(newBlocks),
    selectionBefore: selectionState,
    selectionAfter: selectionState,
  });

  // Set all block types within the selection to unstyled.
  newContentState = Modifier.setBlockType(
    newContentState,
    selectionState,
    'unstyled',
  );

  return EditorState.push(editorState, newContentState, 'change-inline-style');
};

export default clearFormatting;
