/**
 * @param {*} ScrollOptions an object that must contain a valid element within the rendered DOM.
 * It can also contain a topOffset in case scrolling to the element causes it to scroll out of view.
 *
 * If scrolling does not work with this basic use, it means window.scrollTo isn't specific enough and
 * multiple scroll containers may exist on the page.  When this happens, pass a scrollable container
 * element in via scrollContainer (see JobListingForm for example use)
 */
const scrollToElement = ({ element, scrollContainer, topOffset = 0 }) => {
  const { top } = element.getBoundingClientRect();
  (scrollContainer ?? window).scrollTo({
    behavior: 'smooth',
    left: 0,
    top: top - topOffset,
  });
};

export { scrollToElement };
