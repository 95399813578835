//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'meetings';

//---------------------------------------------------------
// Parent types
//---------------------------------------------------------

export const PARENT_CANDIDACY = 'candidacy';
export const PARENT_SEARCH = 'search';
export const PARENT_CONTACT = 'contact';

export const PARENT_TYPES = [PARENT_CANDIDACY, PARENT_SEARCH, PARENT_CONTACT];

//---------------------------------------------------------
// Meeting types?
//---------------------------------------------------------

export const TYPE_MEETING = 'meeting';
