import { fromJS } from 'immutable';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import { INTERVIEW_DELETE_SUCCESS } from 'modules/interviews/actions/actionTypes';
import * as introductionActions from 'modules/introductions/actions';
import { TASK_DELETE_SUCCESS } from 'modules/tasks/actions/actionTypes';
import createCollectionReducer, {
  INITIAL_STATE,
} from '../createCollectionReducer';
import { MEETING_DELETE_SUCCESS } from '../../modules/meetings/actions/actionTypes';
import {
  NOTIFICATIONS_INITIALIZE_TOTAL_COUNT,
  NOTIFICATIONS_INVALIDATE,
  NOTIFICATIONS_FETCH_START,
  NOTIFICATIONS_FETCH_SUCCESS,
  NOTIFICATIONS_FETCH_FAILURE,
  NOTIFICATIONS_UPDATE_READ_START,
  NOTIFICATIONS_UPDATE_READ_SUCCESS,
  NOTIFICATIONS_UPDATE_READ_FAILURE,
  NOTIFICATIONS_UPDATE_ALL_READ_START,
  NOTIFICATIONS_UPDATE_ALL_READ_SUCCESS,
  NOTIFICATIONS_UPDATE_ALL_READ_FAILURE,
} from '../../domains/notifications/constants';
import notification from './notification';

export { INITIAL_STATE };

/**
 * When the action is called with a `before` or `after` parameter we are fetching an additional
 * page of data, so the results should be merged into the existing results in the store, otherwise
 * they should be replaced.
 */
export const shouldMerge = (state, action) =>
  Boolean(action.payload.before || action.payload.after);

const collectionReducer = createCollectionReducer({
  collection: {
    payloadDataKey: 'notifications',
    invalidate: NOTIFICATIONS_INVALIDATE,
    fetchStart: NOTIFICATIONS_FETCH_START,
    fetchSuccess: NOTIFICATIONS_FETCH_SUCCESS,
    fetchFailure: NOTIFICATIONS_FETCH_FAILURE,
    shouldMerge: shouldMerge,
  },
  item: {
    // Transform the data by calling the notification reducer which knows how to handle a raw
    // server object.
    transformData: notification,
  },
});

const deleteByResourceTypeAndId = (resourceType, id, state) => {
  if (!state.has('data')) {
    return state;
  }

  const key = state
    .get('data')
    .findKey(
      value =>
        value.getIn(['data', 'type']) === resourceType &&
        value.getIn(['data', 'resource', 'data', 'id']) === id,
    );

  if (key) {
    return state.deleteIn(['data', key]);
  }

  return state;
};

const supplementalReducer = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    // TODO: Rename to NOTIFICATIONS_INITIALIZE_COUNT
    case NOTIFICATIONS_INITIALIZE_TOTAL_COUNT: {
      return state.hasIn(['meta', 'count'])
        ? state
        : state.setIn(['meta', 'count'], payload.count);
    }
    case NOTIFICATIONS_FETCH_START: {
      return state.update('meta', meta => {
        let updatedMeta = meta;

        if (payload.before) {
          updatedMeta = updatedMeta.set('isFetchingNew', true);
        }

        if (payload.after) {
          updatedMeta = updatedMeta.set('isFetchingMore', true);
        }

        if (payload.filters) {
          updatedMeta = updatedMeta
            .set('previousFilters', meta.get('filters'))
            .set('filters', fromJS(payload.filters));
        }

        return updatedMeta;
      });
    }
    case NOTIFICATIONS_FETCH_FAILURE: {
      return state.update('meta', meta => {
        let updatedMeta = meta;

        if (payload.before) {
          updatedMeta = updatedMeta.set('isFetchingNew', false);
        }

        if (payload.after) {
          updatedMeta = updatedMeta.set('isFetchingMore', false);
        }

        if (meta.has('previousFilters')) {
          // TODO: Check that `payload.filters` is the same as meta.filters?
          updatedMeta = updatedMeta
            .set('filters', meta.get('previousFilters'))
            .delete('previousFilters');
        }

        return updatedMeta;
      });
    }
    case NOTIFICATIONS_FETCH_SUCCESS: {
      return state
        .mergeDeepIn(['meta'], payload.data.meta)
        .update('meta', meta => {
          let updatedMeta = meta.set(
            'hasMore',
            state.get('data').size < meta.get('count'),
          );

          if (payload.before) {
            updatedMeta = updatedMeta.set('isFetchingNew', false);
          }

          if (payload.after) {
            updatedMeta = updatedMeta.set('isFetchingMore', false);
          }

          return updatedMeta.delete('previousFilters');
        });
    }
    case NOTIFICATIONS_UPDATE_READ_START: {
      if (state.hasIn(['data', payload.id])) {
        return state.setIn(['data', payload.id, 'meta', 'isUpdating'], true);
      }

      return state;
    }
    case NOTIFICATIONS_UPDATE_READ_SUCCESS: {
      if (state.hasIn(['data', payload.id])) {
        return state
          .setIn(['data', payload.id, 'data', 'unread'], !payload.read)
          .setIn(['data', payload.id, 'meta', 'isUpdating'], false);
      }

      return state;
    }
    case NOTIFICATIONS_UPDATE_READ_FAILURE: {
      if (state.hasIn(['data', payload.id])) {
        return state.setIn(['data', payload.id, 'meta', 'isUpdating'], false);
      }

      return state;
    }
    case NOTIFICATIONS_UPDATE_ALL_READ_START: {
      return state
        .update(
          'data',
          data =>
            data &&
            data.map(item => {
              if (
                item.getIn(['data', 'sequential_id']) <= action.payload.lteq &&
                item.getIn(['data', 'unread'])
              ) {
                return item.setIn(['meta', 'isUpdating'], true);
              }

              return item;
            }),
        )
        .setIn(['meta', 'isUpdating'], true);
    }
    case NOTIFICATIONS_UPDATE_ALL_READ_SUCCESS: {
      return state
        .update(
          'data',
          data =>
            data &&
            data.map(item => {
              if (
                item.getIn(['data', 'sequential_id']) <= action.payload.lteq &&
                item.getIn(['data', 'unread'])
              ) {
                return item
                  .setIn(['data', 'unread'], false)
                  .setIn(['meta', 'isUpdating'], false);
              }

              return item;
            }),
        )
        .setIn(['meta', 'isUpdating'], false);
    }
    case NOTIFICATIONS_UPDATE_ALL_READ_FAILURE: {
      return state
        .update(
          'data',
          data =>
            data &&
            data.map(item => {
              if (
                item.getIn(['data', 'sequential_id']) <= action.payload.lteq &&
                item.getIn(['data', 'unread'])
              ) {
                return item.setIn(['meta', 'isUpdating'], false);
              }

              return item;
            }),
        )
        .setIn(['meta', 'isUpdating'], false);
    }
    case introductionActions.INTRODUCTION_DELETE_SUCCESS: {
      return deleteByResourceTypeAndId(
        'introduction',
        action.payload.id,
        state,
      );
    }
    case entityActionTypes.DELETE_SUCCESS: {
      if (action.payload.entityType === 'notes') {
        return deleteByResourceTypeAndId('note', action.payload.id, state);
      }

      if (action.payload.entityType === 'outreaches') {
        return deleteByResourceTypeAndId('outreach', action.payload.id, state);
      }

      if (action.payload.entityType === 'references') {
        return deleteByResourceTypeAndId('reference', action.payload.id, state);
      }

      return state;
    }
    case TASK_DELETE_SUCCESS: {
      return deleteByResourceTypeAndId('task', action.payload.id, state);
    }
    case MEETING_DELETE_SUCCESS: {
      return deleteByResourceTypeAndId('meeting', action.payload.id, state);
    }
    case INTERVIEW_DELETE_SUCCESS: {
      return deleteByResourceTypeAndId('interview', action.payload.id, state);
    }
    default: {
      return state;
    }
  }
};

export default function notifications(state = INITIAL_STATE, action) {
  return (
    state
      // First use the generic collection reducer.
      .update(initialState => collectionReducer(initialState, action))
      // Then further reduce using our additional reducer to handle the additional functionality
      // needed for notifications
      .update(reducedState => supplementalReducer(reducedState, action))
      // Finally, reduce each individual notification item using the notification reducer.
      .update(
        'data',
        data =>
          data &&
          data.map(notificationState =>
            notification(notificationState, action),
          ),
      )
  );
}
