import selectn from 'selectn';
import { Map } from 'immutable';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import createListReducer from '../../../reducers/createListReducer';
import noteSchema from '../schema';
import {
  NOTE_LIST_INVALIDATE,
  NOTE_LIST_FETCH_START,
  NOTE_LIST_FETCH_SUCCESS,
  NOTE_LIST_FETCH_FAILURE,
} from '../actions/actionTypes';

const noteListReducer = createListReducer({
  resultKey: 'notes',
  entityResultKey: 'note',
  request: NOTE_LIST_FETCH_START,
  success: NOTE_LIST_FETCH_SUCCESS,
  failure: NOTE_LIST_FETCH_FAILURE,
  invalidated: NOTE_LIST_INVALIDATE,
  deleted: entityActionTypes.DELETE_SUCCESS,
});

// The notesByParentType reducer is keyed on the parent type, then by parent Id.
// So the notes for a search with ID 42 would be at `notes.search.42`
// When we get a collection action it will have the type and ID on the payload,
// so we can use that to call the appropriate list reducer. For create, update,
// and delete actions we need to pass the action down to every single list
// because that item may possibly belong (or no longer belong) to any of our
// lists.
export default (state = new Map(), action) => {
  switch (action.type) {
    case NOTE_LIST_FETCH_START:
    case NOTE_LIST_FETCH_SUCCESS:
    case NOTE_LIST_FETCH_FAILURE:
    case NOTE_LIST_INVALIDATE: {
      const parentType = selectn('payload.parentType', action);
      const parentId = selectn('payload.parentId', action);
      if (parentType && parentId) {
        return state.updateIn([parentType, parentId], listState =>
          noteListReducer(listState, action),
        );
      }

      return state;
    }
    case entityActionTypes.CREATE_SUCCESS: {
      if (selectn('payload.entityType', action) !== noteSchema.key) {
        return state;
      }

      // For create actions, we need to see if the new note should be added to
      // any lists which we currently have fetched.
      const id = selectn('payload.result.note', action);
      const note = selectn(`payload.entities.${noteSchema.key}.${id}`, action);
      if (note && state.hasIn([note.notable_type, note.notable_id, 'ids'])) {
        return state.updateIn(
          [note.notable_type, note.notable_id, 'ids'],
          ids => ids.push(id),
        );
      }

      return state;
    }
    case entityActionTypes.DELETE_SUCCESS: {
      if (selectn('payload.entityType', action) !== noteSchema.key) {
        return state;
      }

      return state.map(parentTypeState =>
        parentTypeState.map(listState => noteListReducer(listState, action)),
      );
    }
    default: {
      return state;
    }
  }
};
