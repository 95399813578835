import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  defaultProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Percentage from '@thrivetrm/ui/components/Percentage';
import Currency from '@thrivetrm/ui/components/Currency';
import canViewCurrentCompensation from '../selectors/canViewCurrentCompensation';
import mapCompensationIdToCompensation from './mapCompensationIdToCompensation';

/**
 * Determines if a compensation record has any underlying data that can be rendered.
 * @param {Immutable.Map} compensation The Compensation record
 * @returns {Boolean} true if the compensation record has any data to display.
 */
export const hasData = compensation =>
  ['base', 'bonus', 'equity', 'notes'].some(prop => compensation.get(prop));

/**
 * Renders a summary table listing base, bonus, equity and notes values, if any.
 */
const CompensationSummary = ({ compensation, excludeNotes }) => (
  <div className='CompensationSummary'>
    <h3>Compensation Data</h3>
    <table className='table--isCompact table--isPlain'>
      <thead />
      <tbody>
        {Number.isFinite(compensation.get('base')) ? (
          <tr key='base'>
            <td className='CompensationSummary__label'>Base:</td>
            <td className='CompensationSummary__value'>
              <Currency
                currency={compensation.get('currency')}
                value={compensation.get('base')}
              />
            </td>
          </tr>
        ) : null}
        {Number.isFinite(compensation.get('bonus')) ? (
          <tr key='bonus'>
            <td className='CompensationSummary__label'>Bonus:</td>
            <td className='CompensationSummary__value'>
              <Currency
                currency={compensation.get('currency')}
                value={compensation.get('bonus')}
              />
            </td>
          </tr>
        ) : null}
        {Number.isFinite(compensation.get('equity')) ? (
          <tr key='equity'>
            <td className='CompensationSummary__label'>Equity:</td>
            <td className='CompensationSummary__value'>
              <Percentage
                maxFractionDigits={2}
                value={compensation.get('equity')}
              />
            </td>
          </tr>
        ) : null}
      </tbody>
      <tfoot>
        {!excludeNotes && compensation.get('notes') ? (
          <tr>
            <td colSpan={2}>
              <h4>Notes</h4>
              {compensation.get('notes')}
            </td>
          </tr>
        ) : null}
      </tfoot>
    </table>
  </div>
);

CompensationSummary.propTypes = {
  /**
   * The compensation record.
   */
  compensation: ImmutablePropTypes.mapContains({
    base: PropTypes.number,
    bonus: PropTypes.number,
    currency: PropTypes.string,
    equity: PropTypes.number,
    notes: PropTypes.string,
  }),

  /**
   * True to note render related notes; False to include them.
   */
  excludeNotes: PropTypes.bool,
};

CompensationSummary.defaultProps = {
  excludeNotes: false,
};

export default compose(
  setDisplayName('CompensationSummary(enhanced)'),
  setPropTypes({
    /**
     * The ID of the compensation record to render.
     */
    compensationId: PropTypes.number.isRequired,

    /**
     * True to note render related notes; False to include them.
     */
    excludeNotes: PropTypes.bool,

    /**
     * True to render the component even if there is no actual data to display in the underlying
     * compenstaion record.
     */
    renderIfEmpty: PropTypes.bool,

    /**
     * If true, user permissions for viewing compensation will be checked and
     * the summary will not render if they are not permitted.
     */
    verifyUserPermitted: PropTypes.bool.isRequired,
  }),
  defaultProps({
    renderIfEmpty: false,
    verifyUserPermitted: true,
  }),
  connect(
    (state, { verifyUserPermitted }) => ({
      isPermitted: !verifyUserPermitted || canViewCurrentCompensation(state),
    }),
    {},
  ),

  // Don't render anything if the user is not permitted to view compensations.
  branch(({ isPermitted }) => !isPermitted, renderNothing),

  mapCompensationIdToCompensation,

  // Don't render anything if there is nothing to render!
  branch(
    ({ compensation, renderIfEmpty }) =>
      !compensation || (!renderIfEmpty && !hasData(compensation)),
    renderNothing,
  ),
)(CompensationSummary);
