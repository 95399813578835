import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import routes from 'modules/routing/routes';
import { searchSchema } from 'modules/searches/schema';
import {
  CANDIDACY_LIST_FETCH_START,
  CANDIDACY_LIST_FETCH_SUCCESS,
  CANDIDACY_LIST_FETCH_FAILURE,
} from './actionTypes';
import { PARENT_PIPELINE, PARENT_CONTACT, PARENT_SEARCH } from '../constants';
import { candidacySchema, candidacyVoteSchema } from '../schemas';

/**
 * Fetches the candidacies that belong to a search or contact.
 * @param {Object} payload
 * @param {Number} payload.searchId The ID of the search to fetch the candidacies for.
 */
export default compose(
  fetchAction,
  normalizeResponse({
    candidacies: [candidacySchema],
    searches: [searchSchema],
    votes: [candidacyVoteSchema],
  }),
  createAsyncActions({
    startActionType: CANDIDACY_LIST_FETCH_START,
    successActionType: CANDIDACY_LIST_FETCH_SUCCESS,
    failureActionType: CANDIDACY_LIST_FETCH_FAILURE,
  }),
  withOptions(({ parentId, parentType }) => {
    if (parentType === PARENT_CONTACT) {
      return {
        url: routes.api_v1_contact_candidacies({ contactId: parentId }),
        parentType: parentType,
        parentId: parentId,
      };
    }

    if (parentType === PARENT_SEARCH) {
      return {
        url: routes.api_v1_search_candidacies({ searchId: parentId }),
        parentType: parentType,
        parentId: parentId,
      };
    }

    if (parentType === PARENT_PIPELINE) {
      return {
        url: routes.api_v1_pipeline_people({ pipelineId: parentId }),
        parentType: parentType,
        parentId: parentId,
      };
    }

    throw new Error(
      `Unknown parentType "${parentType}" provided to fetchCandidacyList`,
    );
  }),
);
