import createEntityListReducer from 'modules/entities/createEntityListReducer';
import {
  FUND_LIST_FETCH_START,
  FUND_LIST_FETCH_SUCCESS,
  FUND_LIST_FETCH_FAILURE,
} from './actions/actionTypes';
import fundSchema from './schema';
import { NAME } from './constants';

/**
 * A reducer that maintains a list of all fund IDs.
 */
const fundsReducer = createEntityListReducer({
  entityType: fundSchema.key,
  listResultKey: 'funds',
  entityResultKey: 'fund',
  request: FUND_LIST_FETCH_START,
  success: FUND_LIST_FETCH_SUCCESS,
  failure: FUND_LIST_FETCH_FAILURE,
});

fundsReducer.NAME = NAME;

export default fundsReducer;
