import { compose } from 'recompose';
import { PATCH } from 'modules/api/methods';
import fetchAction from 'modules/api/fetchAction';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import defaultMethod from 'modules/api/actionModifiers/defaultMethod';
import withResponseSchema from 'modules/api/actionModifiers/withResponseSchema';
import {
  UPDATE_START,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
} from './entityActionTypes';

/**
 * An action creator for fetching and dispatching entity UPDATE_* actions.
 * @param {Object} options
 * @param {Object|String|FormData} options.body The body payload to send with the request
 * @param {String} [options.method="PATCH"] The request method to use
 * @param {*} [options.id] The ID of the entity being updated.
 * @param {String} options.url The URL of the request
 * @param {Schema} options.responseSchema The normalizr schema that describes the server's response.
 */
export default compose(
  fetchAction,
  withResponseSchema,
  createAsyncActions({
    startActionType: UPDATE_START,
    successActionType: UPDATE_SUCCESS,
    failureActionType: UPDATE_FAILURE,
  }),
  defaultMethod(PATCH),
);
