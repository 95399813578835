import { forOwn, groupBy, sortBy } from 'lodash';

const icons = {
  Aliases: 'fa-user-o',
  Companies: 'fa-building-o',
  Contacts: 'fa-user-o',
  'Contact-Networks': 'fa-users',
  Searches: 'fa-folder-open-o',
  'Talent-Pools': 'fa-folder-open-o',

  complete: 'fa-search',
  loading: 'fa-circle-o-notch fa-spin',
};

// Read more about unicode peroperties regex here:
// https://javascript.info/regexp-unicode#unicode-properties-p
const unicodeLettersRegex = /\p{L}/u;

const onSearchProgress = (progressIcon, state) => () => {
  progressIcon.removeClass(icons.loading);
  progressIcon.removeClass(icons.complete);
  progressIcon.addClass(icons[state]);
};

const transformResult = response => {
  const { suggestions } = JSON.parse(response);
  const results = sortBy(suggestions, suggestion => suggestion.data.position);
  return { suggestions: results };
};

const onSelect = suggestion => {
  window.location = suggestion.data.url;
};

const formatResult = (suggestion, val) => {
  const result = $.Autocomplete.defaults.formatResult(suggestion, val);
  return result.replace(
    /^((BD)|(CLSD)|(CNLD)|(LOST)|(HOLD)?)/,
    (a, b) => `<span class='acronym'>${b}</span>`,
  );
};

const formatGroup = (nil, category) => {
  const groupIcon = `<i class='fa ${icons[category]}'></i>`;
  const group = `<strong class='${category}-Search'>${category.replace(
    /-/,
    ' ',
  )}</strong>`;
  return `<div class='autocomplete-group'>${groupIcon} ${group}</div>`;
};

export const filterParams = query => {
  const hasUnicodeLetters = unicodeLettersRegex.test(query);
  const queryParams = hasUnicodeLetters
    ? '&filters[name]='
    : '&filters[keyword]=';
  return queryParams;
};

const beforeRender = (container, suggestions) => {
  const professionalNetworks = $('.global-nav__professional-networks')
    .clone()
    .addClass('cloned');
  const grouped = groupBy(suggestions, suggestion => suggestion.data.type);

  // Insert Professional Networks section.
  container.append(professionalNetworks);

  // Insert View All Results link after 10 results per category.
  forOwn(grouped, (group, name) => {
    if (['Contact-Networks', 'Talent-Pools'].indexOf(name) !== -1) {
      return;
    }
    const networkIds =
      name === 'Contact-Networks' &&
      group.map(network => network.data.record_id);

    const span = `<span class='${name}-Search acronym'>View All</span>`;

    container
      .find(`.${name}-Search`)
      .after("<span class='global-nav__view-all-results'>View All</span>");

    container
      .find('.Contact-Networks-Search')
      .next()
      .data('networkIds', networkIds);

    if (group.length >= 10) {
      container
        .find(`.${name}-Search`)
        .parent()
        .nextUntil(':not(.autocomplete-suggestion)')
        .last()
        .after(`<div class='global-nav__autocomplete-other'>${span}</div>`);
    }
  });
};

const always = () => {
  const searchInput = $('#global_search');
  const progressIcon = searchInput.parent().find('.fa');

  const loading = onSearchProgress(progressIcon, 'loading');
  const searchComplete = onSearchProgress(progressIcon, 'complete');
  const searchIcon = $('.navbar-form .fa-search');
  const searchToggle = $('.nav-search-dropdown');

  // Auto-focus the global search field when the global search dropdown is shown.
  searchToggle
    .on('hide.bs.dropdown', () => searchInput.autocomplete('hide'))
    .on('shown.bs.dropdown', () => searchInput.focus());

  // Prevent global search form submit from reloading the page.
  searchInput.parents('form').on('submit', event => {
    event.preventDefault();
    searchInput.autocomplete('clear');
    searchInput.autocomplete('onValueChange');
  });

  // Perform new global search when clicking the search icon/button next to the field.
  searchIcon.on('click', () => {
    searchInput.autocomplete('clear');
    searchInput.autocomplete('onValueChange');
  });

  // Clicking category name or view all results loads advanced search results page.
  $(document).on(
    'click',
    '.autocomplete-group:has(.Contacts-Search), .global-nav__autocomplete-other:has(.Contacts-Search)',
    () => {
      const query = $('#global_search').val();
      const base = '/contacts?utf8=✓&sort_field=relevance&sort_direction=desc';
      window.location = encodeURI(`${base}${filterParams(query)}${query}`);
    },
  );

  $(document).on(
    'click',
    '.autocomplete-group:has(.Companies-Search), .global-nav__autocomplete-other:has(.Companies-Search)',
    () => {
      const query = $('#global_search').val();
      const base = '/companies?utf8=✓&sort_field=relevance';
      window.location = encodeURI(`${base}${filterParams(query)}${query}`);
    },
  );

  $(document).on(
    'click',
    '.autocomplete-group:has(.Searches-Search), .global-nav__autocomplete-other:has(.Searches-Search)',
    () => {
      const query = $('#global_search').val();
      const base = '/searches?utf8=✓&sort_field=relevance&filters[keyword]=';
      window.location = encodeURI(`${base}${query}`);
    },
  );

  $(document).on(
    'click',
    '.autocomplete-group:has(.Talent-Pools-Search), .global-nav__autocomplete-other:has(.Talent-Pools-Search)',
    () => {
      window.location = encodeURI('/talent_pools');
    },
  );

  // Clicking Contact Networks category lists all contacts in all relevant contact networks.
  $(document).on(
    'click',
    '.Contact-Networks-Search, .autocomplete-group:has(.Contact-Networks-Search)',
    () => {
      const basePath = '/contacts?utf8=✓&filters[sort_field]=relevance';
      const networkIdQueryParamTemplate = '&reset_filter_display[network_ids]=';
      const networkIds = $('.Contact-Networks-Search')
        .next()
        .data('networkIds');
      const networkIdQueryParams = networkIds
        .map(id => `${networkIdQueryParamTemplate}${id}`)
        .join('');
      const url = `${basePath}${networkIdQueryParams}`;
      window.location = encodeURI(url);
    },
  );

  // Dynamically set Professional Networks links using the query string.
  $(document).on('click', '.global-nav__professional-networks a', event => {
    const element = $(event.currentTarget);
    const baseUrl = $(element).data('baseUrl');
    const query = encodeURIComponent($('#global_search').val());
    element.attr('href', `${baseUrl}${query}`);
  });

  return searchInput.autocomplete({
    appendTo: searchToggle,
    beforeRender: beforeRender,
    deferRequestBy: 500,
    forceFixPosition: false,
    formatResult: formatResult,
    formatGroup: formatGroup,
    groupBy: 'type',
    left: 20,
    maxHeight: 600,
    minChars: 3,
    noSuggestionNotice: 'Not found',
    onSearchComplete: searchComplete,
    onSearchError: searchComplete,
    onSearchStart: loading,
    onSelect: onSelect,
    serviceUrl: '/global_searches',
    showNoSuggestionNotice: true,
    transformResult: transformResult,
    triggerSelectOnValidInput: false,
    width: 360,
  });
};

export default {
  always: always,
};
