import { connect } from 'react-redux';
import getEducations from '../../selectors/educations/getEducations';

/**
 * A higher order component which maps a contact ID to it's educations list.
 */
export default connect(
  (state, { contactId }) => ({
    educations: contactId && getEducations(state, contactId),
  }),
  {},
);
