import selectn from 'selectn';
import { Map } from 'immutable';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import { COMMENT_LIST_FETCH_SUCCESS } from '../actions/actionTypes';
import { PARENT_CANDIDACY, PARENT_CONTACT } from '../constants';
import commentSchema from '../commentSchema';

/**
 * Maintains a map by parent type, which contain maps of IDs to comment counts.
 * @example
 * ```json
 * {
 *   CandidacyComment: {
 *     42: 5,
 *     74: 3,
 *   },
 *   ContactComment: {
 *     12: 0,
 *   }
 * }
 * ```
 * Indicates that the candidacy with ID 42 has 5 total comments; the candidacy with ID 74
 * has 3 total comments; and the contact with ID 12 has no comments.
 */
export default (state = new Map(), action) => {
  switch (action.type) {
    case COMMENT_LIST_FETCH_SUCCESS: {
      const commentIds = selectn('payload.result', action);
      const parentId = selectn('payload.parentId', action);
      const parentType = selectn('payload.parentType', action);
      const commentCount = commentIds.reduce((count, id) => {
        const childComments = selectn(
          `payload.entities.comments.${id}.child_comments`,
          action,
        );
        return childComments ? count + childComments.length : count;
      }, commentIds.length);
      return state.setIn([parentType, parentId], commentCount);
    }
    case entityActionTypes.CREATE_SUCCESS: {
      if (selectn('payload.entityType', action) !== commentSchema.key) {
        return state;
      }

      // When a new comment is created, check to see if we have the comment_count for that
      // parent type, and if so increment it.
      const commentId = selectn('payload.result', action);
      const comment = selectn(`payload.entities.comments.${commentId}`, action);
      const parentId =
        comment.type === PARENT_CONTACT
          ? comment.contact_id
          : comment.candidacy_id;

      if (comment.type && state.hasIn([comment.type, parentId])) {
        return state.updateIn([comment.type, parentId], count => count + 1);
      }

      return state;
    }
    case entityActionTypes.DELETE_SUCCESS: {
      if (selectn('payload.entityType', action) !== commentSchema.key) {
        return state;
      }

      // When a comment is deleted the server returns the updated comment_count for the parent.
      // If we have that stored, update it.
      const commentType = selectn('payload.response.type', action);
      const parentIdProperty =
        commentType === PARENT_CONTACT ? 'contact_id' : 'candidacy_id';
      const parentId = selectn(`payload.response.${parentIdProperty}`, action);

      if (state.hasIn([commentType, parentId])) {
        return state.setIn(
          [commentType, parentId],
          selectn('payload.response.comment_count', action),
        );
      }

      return state;
    }
    default: {
      // Sometimes we get a comment count from the underlying entity (candidacy or contact record),
      // So this checks for that case. If gets any contact and candidacy entities that were
      // normalized, and pulls out their ID and `comment_count` property (if it has one),
      // then merges that result into our state.
      const candidacies = selectn('payload.entities.candidacies', action);
      const contacts = selectn('payload.entities.contacts', action);

      if (candidacies || contacts) {
        return state.mergeDeep(
          new Map({
            [PARENT_CONTACT]: contacts || {},
            [PARENT_CANDIDACY]: candidacies || {},
          }).map(
            entities =>
              entities &&
              new Map(
                Object.keys(entities)
                  .map(key => entities[key])
                  .filter(entity =>
                    Object.prototype.hasOwnProperty.call(
                      entity,
                      'comment_count',
                    ),
                  )
                  .map(entity => [entity.id, entity.comment_count]),
              ),
          ),
        );
      }

      return state;
    }
  }
};
