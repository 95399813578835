import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { onlyUpdateForPropTypes } from 'recompose';

// TODO: These avatar components are a hot mess and desperately need to be cleaned up.
// If possible please do not continue to build on top of this.
// see https://thrivetrm.atlassian.net/browse/THRIVE-1197 for more details.

const DEFAULT_SIZE = 80;

/**
 * Renders an avatar image
 */
const Avatar = ({ className, onError, size, url, useLegacyStyles }) => {
  const style = useLegacyStyles
    ? null
    : {
        // height and width is needed for img
        height: size,
        width: size,
        // font-size is needed for icon-nav-overview
        fontSize: size,
      };

  return (
    <div
      className={classnames('Avatar', {
        [className]: Boolean(className),
        'contact-avatar': Boolean(useLegacyStyles),
      })}
      style={style}
    >
      {url ? (
        <img
          alt='Avatar'
          aria-hidden='true'
          className='img-circle'
          onError={onError}
          role='presentation'
          src={url}
        />
      ) : (
        <i aria-hidden='true' className='icon icon-nav-overview' />
      )}
    </div>
  );
};

Avatar.defaultProps = {
  className: null,
  onError: () => {},
  url: null,
};

Avatar.propTypes = {
  /**
   * Optional classname to add to the wrapper div.
   */
  className: PropTypes.string,

  onError: PropTypes.func,
  /**
   * The height and width of the image (if `useLegacyStyles` is false)
   */
  size: PropTypes.number,

  /**
   * The URL to the avatar image.
   */
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),

  /**
   * Whether to render a legacy style of avatar.
   */
  /* eslint-disable-next-line react/boolean-prop-naming */
  useLegacyStyles: PropTypes.bool,
};

Avatar.defaultProps = {
  size: DEFAULT_SIZE,
  useLegacyStyles: false,
};

export default onlyUpdateForPropTypes(Avatar);
