import { createSlice } from '@reduxjs/toolkit';
import getStage from 'modules/search-stages/selectors/getStage';
import getCandidacySearchId from 'modules/candidacies/selectors/getCandidacySearchId';
import updateCandidacy from 'modules/candidacies/actions/updateCandidacy';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import getCandidacyStageId from 'modules/candidacies/selectors/getCandidacyStageId';
import deleteCandidacy from 'modules/candidacies/actions/deleteCandidacy';
import invalidateCandidacyStats from 'modules/searches/actions/candidacyStats/invalidateCandidacyStats';
import { selectDataByCandidacyId } from '../legacySearchDataSelectors';

const initialState = {
  isUpdateInProgress: false,
  selectedCandidacyIds: [],
  updatedCandidacyIds: [],
  failedCandidacyIds: [],
  targetStageId: null,
};

const candidacyBulkActionsSlice = createSlice({
  name: 'candidacyBulkActions',
  initialState: initialState,
  reducers: {
    toggleCandidacySelection: (state, action) => {
      state.selectedCandidacyIds = state.selectedCandidacyIds.includes(
        action.payload,
      )
        ? state.selectedCandidacyIds.filter(val => val !== action.payload)
        : [...state.selectedCandidacyIds, action.payload];
    },
    setTargetStageId: (state, action) => {
      state.targetStageId = action.payload;
    },
    processNextSelectedCandidacyBegin: state => {
      state.isUpdateInProgress = true;
    },
    processNextSelectedCandidacySuccess: state => {
      state.updatedCandidacyIds = [
        ...state.updatedCandidacyIds,
        state.selectedCandidacyIds[
          state.updatedCandidacyIds.length + state.failedCandidacyIds.length
        ],
      ];
      state.isUpdateInProgress =
        state.selectedCandidacyIds.length ===
        state.updatedCandidacyIds.length + state.failedCandidacyIds.length + 1;
    },
    processNextSelectedCandidacyFailure: state => {
      state.failedCandidacyIds = [
        ...state.failedCandidacyIds,
        state.selectedCandidacyIds[
          state.updatedCandidacyIds.length + state.failedCandidacyIds.length
        ],
      ];
      state.isUpdateInProgress =
        state.selectedCandidacyIds.length ===
        state.updatedCandidacyIds.length + state.failedCandidacyIds.length + 1;
    },
    reset: () => initialState,
  },
});

const {
  processNextSelectedCandidacyBegin,
  processNextSelectedCandidacyFailure,
  processNextSelectedCandidacySuccess,
  reset,
  setTargetStageId,
  toggleCandidacySelection,
} = candidacyBulkActionsSlice.actions;

const selectIsBulkUpdateComplete = state =>
  !state.candidacyBulkActions.isUpdateInProgress &&
  Boolean(
    state.candidacyBulkActions.updatedCandidacyIds.length +
      state.candidacyBulkActions.failedCandidacyIds.length,
  );
const selectHasStartedBulkUpdate = state =>
  state.candidacyBulkActions.isUpdateInProgress ||
  Boolean(
    state.candidacyBulkActions.updatedCandidacyIds.length +
      state.candidacyBulkActions.failedCandidacyIds.length,
  );

const selectDataForSelectedCandidacies = state =>
  Object?.fromEntries(
    state.candidacyBulkActions.selectedCandidacyIds?.map(id => [
      id,
      selectDataByCandidacyId(state, id),
    ]) || [],
  );

const selectSelectedStageIds = state => {
  const stageIdsWDups = state.candidacyBulkActions.selectedCandidacyIds?.map(
    candidacyId => getCandidacyStageId(state, candidacyId),
  );
  const uniqueStageIds = [...new Set(stageIdsWDups)];
  return uniqueStageIds;
};

const selectTargetStage = state =>
  state.candidacyBulkActions.targetStageId
    ? getStage(state, state.candidacyBulkActions.targetStageId).toObject()
    : null;

const selectIsUpdatingLastCandidacy = state =>
  state.candidacyBulkActions.selectedCandidacyIds.length ===
  state.candidacyBulkActions.updatedCandidacyIds.length +
    state.candidacyBulkActions.failedCandidacyIds.length +
    1;

const selectCurrentCandidacyId = state =>
  state.candidacyBulkActions.selectedCandidacyIds[
    state.candidacyBulkActions.updatedCandidacyIds.length +
      state.candidacyBulkActions.failedCandidacyIds.length
  ];

const processNextSelectedCandidacy = (sharedData = {}) => async (
  dispatch,
  getState,
) => {
  const state = getState();

  const candidacyId = selectCurrentCandidacyId(state);
  const searchId = getCandidacySearchId(state, candidacyId);
  const targetStage = selectTargetStage(state);

  dispatch(processNextSelectedCandidacyBegin());

  let response = null;
  if (targetStage) {
    response = await dispatch(
      updateCandidacy({
        transactionId: uniqueId(),
        id: candidacyId,
        searchId: searchId,
        candidacy: { stage: targetStage.name, ...sharedData },
        redirectAfterSuccess: false,
      }),
    );
  } else {
    response = await dispatch(deleteCandidacy({ id: candidacyId }));
    dispatch(invalidateCandidacyStats({ searchId: searchId }));
  }

  const isUpdatingLastCandidacy = selectIsUpdatingLastCandidacy(state);

  if (response.payload.error) {
    dispatch(processNextSelectedCandidacyFailure());
  } else {
    dispatch(processNextSelectedCandidacySuccess());
  }

  return isUpdatingLastCandidacy
    ? response
    : dispatch(processNextSelectedCandidacy(sharedData));
};

export {
  processNextSelectedCandidacy,
  reset,
  selectDataForSelectedCandidacies,
  selectHasStartedBulkUpdate,
  selectIsBulkUpdateComplete,
  selectSelectedStageIds,
  selectTargetStage,
  setTargetStageId,
  toggleCandidacySelection,
};

export default candidacyBulkActionsSlice.reducer;
