import getAssessmentList from './getAssessmentList';

/**
 * Determines if the assessment list for a parent should be fetched from the server.
 * @param {Object} state
 * @param {Number} parentType One of PARENT_SEARCH, PARENT_CONTACT or PARENT_CANDIDACY - the list
 *   type to check
 * @param {Number} parentId The search ID, contact ID, or candidacy ID, depending on parentType.
 * @return {Boolean} True if the assessment list needs to be fetched from the server;
 *   Otherwise, false.
 */
export default (state, parentType, parentId) => {
  if (!parentType || !parentId) {
    return false;
  }

  const assessmentList = getAssessmentList(state, parentType, parentId);

  if (!assessmentList) {
    return true;
  }

  if (
    assessmentList.getIn(['_meta', 'isFetching']) ||
    assessmentList.hasIn(['_meta', 'error'])
  ) {
    return false;
  }

  return (
    !assessmentList.has('ids') ||
    Boolean(assessmentList.getIn(['_meta', 'isInvalidated']))
  );
};
