import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { closeQuickView, navigateQuickViewTo } from './quickViewSlice';

const INTEGER_REGEX = /^\d+$/;

// React Router provides all route path params as strings. We often need to use
// record ID values out of the route path, and downstream components typically
// expect those IDs to be numbers, so we convert them here for convenience.
//
// Given a route pattern of '/:recordType/:recordId'
// And provided a path of '/contact/2'
// The resulting param object will be:
//   {
//     recordType: 'contact', // string
//     recordId: 2, // number
//   }
const formatParams = params => {
  const formattedParams = {};
  Object.entries(params).forEach(([key, value]) => {
    const isInteger = INTEGER_REGEX.test(value);
    formattedParams[key] = isInteger ? Number(value) : value;
  });
  return formattedParams;
};

const useQuickView = () => {
  const dispatch = useDispatch();
  const pathParams = useParams();
  const { currentPath, title } = useSelector(state => state.quickView);
  const close = useCallback(() => dispatch(closeQuickView()), [dispatch]);
  const navigateTo = useCallback(
    (path, config) =>
      dispatch(
        navigateQuickViewTo({
          path: path,
          ...config,
        }),
      ),
    [dispatch],
  );

  return {
    close: close,
    currentPath: currentPath,
    navigateTo: navigateTo,
    pathParams: formatParams(pathParams),
    title: title,
  };
};

export default useQuickView;
