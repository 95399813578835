import { isEqual } from 'lodash';
import filterFetchParams from './filterFetchParams';

/**
 * A helper function to determine if 2 param sets are equal. These are essentially the
 * query parameters that are sent with a request.
 * @param {Object} p1
 * @param {Object} p2
 * @param {Object} fetchParams
 * @returns {Boolean} whether or not the param sets are equal
 */
export default (p1, p2, fetchParams) =>
  isEqual(
    filterFetchParams(p1, fetchParams),
    filterFetchParams(p2, fetchParams),
  );
