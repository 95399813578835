import createEntity from 'modules/entities/actions/createEntity';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import commentSchema from '../commentSchema';
import getCommentListUrl from './getCommentListUrl';
import childCommentCreated from './childCommentCreated';

const entityType = commentSchema.key;

/**
 * Creates an action for creating an comment.
 * @param {Object} payload
 * @param {Object} payload.comment The comment to create
 * @param {String} payload.comment.type The comment type / parent type.
 * @param {String} payload.parentId The ID of the parent that this comment belongs to.
 * @param {String} payload.parentType The type of parent that this comment belongs to.
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({
  comment,
  parentId,
  parentType,
  transactionId,
}) => dispatch => {
  dispatch(
    createEntity({
      body: { comment: comment },
      transactionId: transactionId,
      entityType: entityType,
      responseSchema: commentSchema,
      url: getCommentListUrl({
        parentId: parentId,
        parentType: parentType,
      }),
    }),
  ).then(action => {
    if (action.type === entityActionTypes.CREATE_SUCCESS) {
      const { parent_comment_id: parentCommentId } = comment;
      const id = action.payload.result;

      if (parentCommentId) {
        dispatch(
          childCommentCreated({
            id: id,
            parentId: parentCommentId,
          }),
        );
      }
    }

    return action;
  });
};
