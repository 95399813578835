import { fromJS, List, Map } from 'immutable';
import selectn from 'selectn';
import {
  ATTRIBUTES_FETCH_START,
  ATTRIBUTES_FETCH_SUCCESS,
  ATTRIBUTES_FETCH_FAILURE,
} from '../actions/attributes/actionTypes';

/**
 * Maintains a map of searchIds to it's collection of assessment attributes (via an `ids` key)
 */
export default (state = new Map(), action) => {
  switch (action.type) {
    case ATTRIBUTES_FETCH_START: {
      return state.setIn(
        [action.payload.searchId, '_meta', 'isFetching'],
        true,
      );
    }
    case ATTRIBUTES_FETCH_SUCCESS: {
      const ids = new List(selectn('payload.result.attributes', action));
      return state
        .setIn([action.payload.searchId, '_meta', 'isFetching'], false)
        .setIn([action.payload.searchId, 'ids'], ids);
    }
    case ATTRIBUTES_FETCH_FAILURE: {
      return state
        .setIn([action.payload.searchId, '_meta', 'isFetching'], false)
        .setIn(
          [action.payload.searchId, '_meta', 'error'],
          action.payload.error,
        );
    }
    default: {
      // When a search is fetched, it may contain the assessment attributes
      // as a property on the search result object. We take advantage of that
      // here so we don't have to do an extra fetch.
      const searches = selectn('payload.entities.searches', action);

      if (searches) {
        // Grab any search entities that have an `assessment_attributes` value and merge them
        // into our map.
        const searchWithAssessmentAttributes = Object.keys(searches)
          .map(key => searches[key])
          .filter(search => search.assessment_attributes);

        if (searchWithAssessmentAttributes.length) {
          return state.merge(
            new Map(
              searchWithAssessmentAttributes.map(search => [
                search.id,
                fromJS({ ids: search.assessment_attributes }),
              ]),
            ),
          );
        }
      }

      return state;
    }
  }
};
