//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'bulkImports';

export const RESOURCE_TYPE_CONTACT = 'Contact';
export const RESOURCE_TYPE_LABELS = {
  [RESOURCE_TYPE_CONTACT]: 'Contacts',
};
/**
 * Optional parameters that can be used when fetching a list of bulk imports.
 */
export const FETCH_PARAMS = ['page', 'limit', 'sortBy', 'sortAscending'];

//---------------------------------------------------------
// Bulk Import table columns
//---------------------------------------------------------

export const COLUMN_END_TIME = 'end_time';
export const COLUMN_FILE_NAME = 'file';
export const COLUMN_RESOURCE_TYPE = 'resource_type';
export const COLUMN_START_TIME = 'start_time';
export const COLUMN_STATUS_FILE = 'status_file';
export const COLUMN_UPLOAD_STATUS = 'status';
export const COLUMN_USER_NAME = 'user';

export const COLUMNS = [
  COLUMN_END_TIME,
  COLUMN_FILE_NAME,
  COLUMN_RESOURCE_TYPE,
  COLUMN_START_TIME,
  COLUMN_STATUS_FILE,
  COLUMN_UPLOAD_STATUS,
  COLUMN_USER_NAME,
];

export const Modes = {
  CHOOSE_FILE_FORMAT: 'chooseFileFormat',
  CHOOSE_RESOURCE_TYPE: 'chooseResourceType',
  UPLOAD_CSV: 'uploadCsv',
};
