import { connect } from 'react-redux';
import getActive from '../selectors/targetCompanies/getActive';
import getCompanyId from '../selectors/targetCompanies/getCompanyId';

/**
 * A higher order component which maps a list of ACTIVE `targetCompanyIds` to their
 * underlying company ID values as a `companyIds` prop.
 */
export default connect((state, { targetCompanyIds }) => ({
  companyIds:
    targetCompanyIds &&
    targetCompanyIds
      // Filter out non-active companies
      .filter(id => getActive(state, id))
      .map(id => getCompanyId(state, id)),
}));
