import selectn from 'selectn';
import { fromJS, Map } from 'immutable';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import { searchSchema } from 'modules/searches/schema';
import createListReducer from '../../../reducers/createListReducer';
import {
  DOCUMENTS_INVALIDATE,
  DOCUMENTS_FETCH_START,
  DOCUMENTS_FETCH_SUCCESS,
  DOCUMENTS_FETCH_FAILURE,
} from '../actions/actionTypes';
import { OWNER_SEARCH } from '../constants';
import documentSchema from '../schema';

const documentListReducer = createListReducer({
  resultKey: 'documents',
  entityResultKey: 'document',
  request: DOCUMENTS_FETCH_START,
  success: DOCUMENTS_FETCH_SUCCESS,
  failure: DOCUMENTS_FETCH_FAILURE,
  invalidated: DOCUMENTS_INVALIDATE,
  created: entityActionTypes.CREATE_SUCCESS,
  deleted: entityActionTypes.DELETE_SUCCESS,
});

/**
 * The documentLists reducer maintains document lists -- it is keyed on the owner type,
 * then by owner Id
 *
 * @example
 * ```js
 * {
 *   Search: { // owner type OWNER_SEARCH
 *     123: { // The list of documents for search with ID 123
 *       _meta: {...}.
 *       ids: [2, 5, 7, 9],  // The IDs of the documents that belong to search 123
 *     }
 *   },
 *   Contact: { // owner type OWNER_CONTACT
 *     789: { // The list of documents for contact with ID 789
 *       _meta: {...}.
 *       ids: [23, 42],  // The IDs of the documents that belong to contact 789
 *     }
 *   }
 * }
 * ```
 */
export default (state = new Map(), action) => {
  switch (action.type) {
    case DOCUMENTS_FETCH_START:
    case DOCUMENTS_FETCH_SUCCESS:
    case DOCUMENTS_FETCH_FAILURE:
    case DOCUMENTS_INVALIDATE: {
      // When a list fetch action is seen, reduce only the part of the state
      // that belongs to the specific owner
      const ownerType = selectn('payload.ownerType', action);
      const ownerId = selectn('payload.ownerId', action);
      if (ownerType && ownerId) {
        return state.updateIn([ownerType, ownerId], listState =>
          documentListReducer(listState, action),
        );
      }

      return state;
    }
    case entityActionTypes.CREATE_SUCCESS: {
      if (selectn('payload.entityType', action) !== documentSchema.key) {
        return state;
      }

      // For create actions, we need to see if the new document should be added to
      // any lists which we currently have fetched based on the document's owner
      const docId = selectn('payload.result.document', action);
      const doc = selectn(
        `payload.entities.${documentSchema.key}.${docId}`,
        action,
      );
      if (doc && state.hasIn([doc.owner_type, doc.owner_id, 'ids'])) {
        return state.updateIn([doc.owner_type, doc.owner_id, 'ids'], ids =>
          ids.push(docId),
        );
      }

      return state;
    }
    case entityActionTypes.DELETE_SUCCESS: {
      // For deletes we can reduce ALL lists, because it should be removed from any
      // list regardless of the owner (in practice it will only belong to one list,
      // but we don't necessarily know which one based on the action alone).
      return state.map(ownerTypeState =>
        ownerTypeState.map(listState => documentListReducer(listState, action)),
      );
    }
    default: {
      // When a search entity is fetched, it may contain the documents for that search, in which
      // case we want to add them to our list.
      const searches = selectn(`payload.entities.${searchSchema.key}`, action);
      if (searches) {
        // `searches` is a dictionary, convert to an array of search entities.
        const entities = Object.keys(searches)
          .map(key => searches[key])
          // Grab only search entities that have a documents property.
          .filter(search => Array.isArray(search.documents));

        if (entities && entities.length) {
          return state.withMutations(mutable => {
            entities.forEach(search => {
              mutable.setIn(
                [OWNER_SEARCH, searchSchema.getId(search), 'ids'],
                fromJS(search.documents),
              );
            });
          });
        }
      }

      return state;
    }
  }
};
