import React from 'react';
import PropTypes from 'prop-types';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import Avatar from '@thrivetrm/ui/components/Avatar';
import Card from '@thrivetrm/ui/components/Card';
import Date from '@thrivetrm/ui/components/Date';
import ExternalLink from 'modules/links/ExternalLink';

const SkyminyrCompanyDetailsCard = ({ companyDetails, onUnselect }) => {
  const companyAddress = companyDetails.addresses?.[0] ?? null;

  const hasAdditionalInfo = Boolean(
    companyDetails.linkedin_url ||
      companyDetails.employee_count?.headcount ||
      companyDetails.founded,
  );

  return (
    <Card isCentered={false}>
      <div className='u-flex u-flexAlign-c'>
        <Avatar
          className='SkyminyrCompanySelect__avatar u-marginRight-8'
          image={companyDetails.avatar}
          name={companyDetails.name}
          shape='square'
          size='large'
        />
        <div className='u-marginLeft-12 SkyminyrCompanySelect__detailsSection'>
          <h3 className='u-margin-n'>{companyDetails.name}</h3>
          {companyDetails.enriched_sectors ? (
            <p className='u-margin-n'>
              {companyDetails.enriched_sectors
                ? companyDetails.enriched_sectors?.join(', ')
                : null}
            </p>
          ) : null}
          {companyAddress ? (
            <p className='u-margin-n'>
              {[
                companyAddress.address_line_1,
                companyAddress.city,
                companyAddress.state,
                companyAddress.country,
                companyAddress.postal_code,
              ]
                .filter(Boolean)
                .join(', ')}
            </p>
          ) : null}
          {companyDetails.website_url ? (
            <ExternalLink
              className='u-block u-truncate'
              href={companyDetails.website_url}
            >
              {companyDetails.website_url}
            </ExternalLink>
          ) : null}
        </div>
      </div>
      {hasAdditionalInfo ? (
        <div className='u-borderTop u-paddingTop-12 u-marginTop-4'>
          {companyDetails.employee_count?.headcount ? (
            <>
              <h4 className='u-margin-n u-marginTop-4'>Company Size</h4>
              <p className='u-margin-n'>
                {companyDetails.employee_count.headcount} employees
              </p>
            </>
          ) : null}
          {companyDetails.founded ? (
            <>
              <h4 className='u-margin-n u-marginTop-4'>Founded</h4>
              <Date date={companyDetails.founded} format='fullMonthYear' />
            </>
          ) : null}
          {companyDetails.linkedin_url ? (
            <>
              <h4 className='u-margin-n u-marginTop-4'>LinkedIn</h4>
              <ExternalLink
                className='u-block u-truncate'
                href={companyDetails.linkedin_url}
              >
                {companyDetails.linkedin_url}
              </ExternalLink>
            </>
          ) : null}
        </div>
      ) : null}

      <div className='u-flex u-flexJustify-c u-borderTop u-paddingTop-12 u-marginTop-4'>
        <ButtonSecondary label='Unselect' onClick={onUnselect} size='small' />
      </div>
    </Card>
  );
};

SkyminyrCompanyDetailsCard.propTypes = {
  companyDetails: PropTypes.shape({
    addresses: PropTypes.arrayOf(
      PropTypes.shape({
        address_line_1: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
        postal_code: PropTypes.string,
        state: PropTypes.string,
      }),
    ),
    avatar: PropTypes.string,
    employee_count: PropTypes.shape({
      headcount: PropTypes.number,
    }),
    enriched_sectors: PropTypes.arrayOf(PropTypes.string),
    founded: PropTypes.string,
    linkedin_url: PropTypes.string,
    name: PropTypes.string.isRequired,
    website_url: PropTypes.string,
  }),
  onUnselect: PropTypes.func.isRequired,
};

export default SkyminyrCompanyDetailsCard;
