//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'datetime';

//---------------------------------------------------------
// Timezones
//---------------------------------------------------------

export const DEFAULT_TIMEZONE = 'Pacific Time (US & Canada)';
