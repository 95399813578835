import { USER_STATE_KEY } from '../constants';

/**
 * Returns a value indicating if the current user is a candidate user.
 * @param {*} state
 * @returns {Boolean} true if the current user is in the "candidate" role; otherwise, returns false.
 */
const isCandidateUser = state =>
  state[USER_STATE_KEY].get('is_candidate', false);

export default isCandidateUser;
