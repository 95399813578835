import getCandidacyProperty from './getCandidacyProperty';

/**
 * Gets the stage ID for candidacy record.
 * @param {Object} state
 * @param {Number} id The candidacy ID
 * @return {?Number} The ID of the candidacy's current stage.
 */
export default (state, candidacyId) =>
  getCandidacyProperty(state, candidacyId, 'candidacy_stage');
