import React from 'react';
import PropTypes from 'prop-types';
import StatusIndicator from '@thrivetrm/ui/components/StatusIndicator';
import classNames from 'classnames';
import Tooltip from '@thrivetrm/ui/components/Tooltip';

const SEVERITY_TO_STATUS_MAP = {
  add: 'add',
  danger: 'error',
  info: 'info',
  warning: 'warning',
};

const STATUS_CHARACTER_LIMIT = 38;

const OffLimitsStatus = ({ isHoverable, severity, status }) => {
  const shouldTruncate = status?.length > STATUS_CHARACTER_LIMIT;

  return (
    <div
      className={classNames('OffLimitsStatus', `OffLimitsStatus--${severity}`, {
        'OffLimitsStatus--isHoverable': isHoverable,
      })}
      data-testid='offlimits-indicator-status'
    >
      <StatusIndicator
        className='OffLimitsStatus__indicator'
        size='small'
        status={SEVERITY_TO_STATUS_MAP[severity]}
      />
      <Tooltip content={shouldTruncate ? status : null} size='large'>
        <span className='u-noWrap'>
          {shouldTruncate
            ? `${status.slice(0, STATUS_CHARACTER_LIMIT)}...`
            : status}
        </span>
      </Tooltip>
    </div>
  );
};

OffLimitsStatus.defaultProps = {
  isHoverable: false,
};

OffLimitsStatus.propTypes = {
  isHoverable: PropTypes.bool,
  severity: PropTypes.oneOf(Object.keys(SEVERITY_TO_STATUS_MAP)).isRequired,
  status: PropTypes.string.isRequired,
};

export default OffLimitsStatus;
