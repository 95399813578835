import getLocationSortName from 'modules/contacts/selectors/contacts/getLocationSortName';
import getCandidacyContactId from './getCandidacyContactId';

/**
 * Gets a sortable location name for a candidacy record.
 * @param {Object} state
 * @param {Number} id The candidacy ID
 * @return {?String} The location name of the contact that can be used for sorting
 */
const getCandidacySortLocationName = (state, candidacyId) => {
  const contactId = getCandidacyContactId(state, candidacyId);
  return contactId && getLocationSortName(state, contactId);
};

export default getCandidacySortLocationName;
