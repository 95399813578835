import getSearchAttributes from './getSearchAttributes';

/**
 * Determines if the assessment attributes assigned to a particular search need to be fetched from
 * the server.
 * @param {*} state
 * @param {Number} searchId The ID of the search which the assessment attributes are needed for.
 * @returns true if the assessment attributes should be fetched from the server for the specified
 *   search; otherwise, false.
 */
export default (state, searchId) => {
  if (!searchId) {
    return false;
  }

  const searchAttributesState = getSearchAttributes(state, searchId);

  if (!searchAttributesState) {
    return true;
  }

  if (searchAttributesState.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  return (
    !searchAttributesState.has('ids') &&
    !searchAttributesState.hasIn(['_meta', 'error'])
  );
};
