import { Map } from 'immutable';
import {
  GUEST_ASSESSMENT_CREATE_START,
  GUEST_ASSESSMENT_CREATE_FAILURE,
  GUEST_ASSESSMENT_CREATE_SUCCESS,
} from '../actions/guestAssessments/actionTypes';

/**
 * Maintains the status of submitting a guest assessment, keyed by the
 * unique invite ID.
 * Currently the only way to add an additional guest assessment is to reload the
 * whole page, so there's no worry about collisions on the key here, but in the
 * future that may need to change if we allow a guest to submit multiple
 * assessments in one visit.
 */
export default (state = new Map(), action) => {
  switch (action.type) {
    case GUEST_ASSESSMENT_CREATE_START: {
      return state.setIn([action.payload.token, 'isSubmitting'], true);
    }
    case GUEST_ASSESSMENT_CREATE_SUCCESS: {
      return state.mergeIn([action.payload.token], {
        isSubmitting: false,
        isSubmitted: true,
        result: action.payload.data.notice,
        error: null,
      });
    }
    case GUEST_ASSESSMENT_CREATE_FAILURE: {
      return state.mergeIn([action.payload.token], {
        isSubmitting: false,
        error: action.payload.error,
      });
    }
    default: {
      return state;
    }
  }
};
