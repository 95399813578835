import React, { useRef, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select/async';
import CreatableSelect from 'react-select/async-creatable';
import { debounce } from 'lodash';
import classNames from 'classnames';
// eslint-disable-next-line no-restricted-imports
import { components } from 'react-select';
import Icon from '@thrivetrm/ui/components/Icon';
import Loader from '@thrivetrm/ui/components/Loader';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import Api from 'modules/core/Api';
import { EMPTY_STYLES } from 'modules/recordIndex/components/v5-filters/common-react-select-constants';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';

const DEBOUNCE_INTERVAL_MS = 550;

const CrossIcon = () => <Icon type='close' />;
const DropdownIndicator = () => <Icon type='search' />;
const LoadingIndicator = () => (
  <Loader className='u-marginHorizontal-4' size='small' />
);
// eslint-disable-next-line react/prop-types
const MultiValueRemove = ({ onClick, ...props }) => (
  <components.MultiValueRemove {...props}>
    <ButtonSecondary
      className='u-marginHorizontal-4'
      icon='close'
      onClick={onClick}
      size='small'
    />
  </components.MultiValueRemove>
);

const ClearIndicator = props => (
  <components.ClearIndicator {...props}>
    <Icon className='u-marginRight-8' type='close' />
  </components.ClearIndicator>
);

const AsyncSelect = ({
  className,
  errorMessage,
  formatCreateLabel,
  formatOption,
  formatOptionLabel,
  initialInputValue,
  isClearable,
  isCreatable,
  isDisabled,
  isMulti,
  isRequired,
  label,
  menuListFooter,
  onChange,
  onInputChange,
  placeholder,
  refreshOptionsToken,
  url,
  value,
}) => {
  const inputId = uniqueId('asyncSelectInput');
  const asyncSelectRef = useRef(inputId);
  const containerClass = classNames('AsyncSelect', className, {
    'MultiValueInput--isInvalid': Boolean(errorMessage),
  });
  const [inputValue, setInputValue] = useState(initialInputValue);
  const MenuList = menuListProps => (
    <>
      <components.MenuList {...menuListProps}>
        {menuListProps.children}
      </components.MenuList>
      {menuListFooter ?? null}
    </>
  );

  const loadAsyncOptions = useCallback(
    debounce((searchTerm, callBack) => {
      const apiUrl = url(searchTerm);
      if (apiUrl && searchTerm?.trim()) {
        Api.get(apiUrl)
          .then(options => {
            callBack(options?.map(option => formatOption(option)));
          })
          .catch(() => {
            callBack([]);
          });
      } else {
        callBack([]);
      }
    }, DEBOUNCE_INTERVAL_MS),
    [refreshOptionsToken],
  );

  useEffect(() => {
    /*
     manually refetching the options whenever refreshOptionsToken changes,
     as we want to reload the options from different api endpoint with the same params.
    */
    if (refreshOptionsToken && asyncSelectRef?.current?.state?.inputValue) {
      asyncSelectRef.current.setState({ isLoading: true });
      // invokes the loadOptions method of AsyncSelect to refetch the options
      asyncSelectRef.current.loadOptions(
        asyncSelectRef.current.state.inputValue,
        options => {
          asyncSelectRef.current.setState({
            loadedInputValue: asyncSelectRef.current.state.inputValue,
            loadedOptions: options,
            isLoading: false,
          });
        },
      );
    }
  }, [refreshOptionsToken]);

  const SelectDropdown = isCreatable ? CreatableSelect : Select;

  return (
    <div className={containerClass}>
      {label ? (
        <label className='MultiValueInput__label' htmlFor={inputId}>
          {isRequired ? <span aria-hidden='true'>* </span> : null}
          {label}
        </label>
      ) : null}
      <SelectDropdown
        classNamePrefix='MultiValueInput'
        components={{
          // Custom components used to replace the react-select defaults
          CrossIcon: CrossIcon,
          DropdownIndicator: DropdownIndicator,
          IndicatorSeparator: null,
          LoadingIndicator: LoadingIndicator,
          MenuList: MenuList,
          MultiValueRemove: MultiValueRemove,
          ClearIndicator: ClearIndicator,
        }}
        formatCreateLabel={formatCreateLabel}
        formatOptionLabel={formatOptionLabel}
        inputId={inputId}
        inputValue={inputValue}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isMulti={isMulti}
        loadOptions={loadAsyncOptions}
        noOptionsMessage={noOptionsProps =>
          noOptionsProps.inputValue ? 'No matches found' : null
        }
        onChange={onChange}
        onInputChange={(newValue, action) => {
          setInputValue(newValue);
          onInputChange?.(newValue, action);
        }}
        placeholder={placeholder}
        ref={asyncSelectRef}
        styles={EMPTY_STYLES}
        value={value}
      />
      {errorMessage ? (
        <div className='MultiValueInput__errorMessage' role='alert'>
          {errorMessage}
        </div>
      ) : null}
    </div>
  );
};

AsyncSelect.defaultProps = {
  className: null,
  errorMessage: null,
  formatCreateLabel: () => {},
  formatOptionLabel: null,
  initialInputValue: '',
  isClearable: true,
  isCreatable: false,
  isDisabled: false,
  isMulti: false,
  isRequired: false,
  label: null,
  menuListFooter: null,
  onInputChange: () => {},
  placeholder: 'Select...',
  refreshOptionsToken: null,
  value: null,
};

AsyncSelect.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  formatCreateLabel: PropTypes.func,
  formatOption: PropTypes.func.isRequired,
  formatOptionLabel: PropTypes.func,
  initialInputValue: PropTypes.string,
  isClearable: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  menuListFooter: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  placeholder: PropTypes.string,
  refreshOptionsToken: PropTypes.string,
  url: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    ),
  ]),
};

export default AsyncSelect;
