import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';

import allContactMethods from './allContactMethods';
import allOutreachesByParentType from './allOutreachesByParentType';
import recentOutreachesByParentType from './recentOutreachesByParentType';

const outreachesReducer = combineImmutableReducers({
  allContactMethods: allContactMethods,
  allOutreachesByParentType: allOutreachesByParentType,
  recentOutreachesByParentType: recentOutreachesByParentType,
});

outreachesReducer.NAME = NAME;

export default outreachesReducer;
