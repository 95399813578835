//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'jobApplications';

//---------------------------------------------------------
// Sorting
//---------------------------------------------------------

export const SORT_BY_NAME = 'name';
export const SORT_BY_EMAIL = 'email';
export const SORT_BY_DATE_APPLIED = 'created_at';
export const SORT_BY_DEFAULT = SORT_BY_DATE_APPLIED;
export const SORT_ASCENDING_DEFAULT = true;

export const SORT_BY_TYPES = [
  SORT_BY_NAME,
  SORT_BY_EMAIL,
  SORT_BY_DATE_APPLIED,
];

export const SORT_BY_LABELS = {
  [SORT_BY_NAME]: 'Name',
  [SORT_BY_EMAIL]: 'Email',
  [SORT_BY_DATE_APPLIED]: 'Date Applied',
};

export const SORT_BY_OPTIONS = Object.keys(SORT_BY_LABELS);
