import selectn from 'selectn';
import commentSchema from 'modules/comments/commentSchema';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import isEntityType from 'modules/entities/isEntityType';
import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import invalidateCandidacyStatus from '../actions/invalidateCandidacyStatus';

/**
 * When a comment is created, updated, or deleted and it has an associated candidacy record,
 * this invalidates the status of those underlying candidacies so they will be refetched from the
 * server.
 */
export default ({ action, dispatch, getState }) => {
  if (isEntityType(action, commentSchema.key)) {
    switch (action.type) {
      case entityActionTypes.CREATE_SUCCESS:
      case entityActionTypes.UPDATE_SUCCESS:
      case entityActionTypes.DELETE_SUCCESS: {
        const state = getState();
        const isDelete = action.type === entityActionTypes.DELETE_SUCCESS;
        const commentId = selectn(
          isDelete ? 'payload.id' : 'payload.result',
          action,
        );
        const candidacyId = getEntityProperty(
          state,
          commentSchema.key,
          commentId,
          'candidacy_id',
        );
        if (candidacyId) {
          dispatch(invalidateCandidacyStatus({ id: candidacyId }));
        }
        break;
      }
      default: {
        // noop
      }
    }
  }
};
