import { normalize } from 'normalizr';
import url from 'url';
import {
  USER_LIST_FETCH_START,
  USER_LIST_FETCH_SUCCESS,
  USER_LIST_FETCH_FAILURE,
} from './actionTypes';
import userSchema from '../schema';
import createAjaxAction from '../../../actions/createAjaxAction';

export const getUrl = ({ filter } = {}) =>
  url.format({
    pathname: '/api/users',
    query: filter ? { filter: filter } : {},
  });

export default createAjaxAction({
  getUrl: getUrl,
  start: USER_LIST_FETCH_START,
  success: USER_LIST_FETCH_SUCCESS,
  failure: USER_LIST_FETCH_FAILURE,
  createSuccessPayload: (payload, data) => ({
    ...payload,
    ...normalize(data, [userSchema]),
  }),
});
