/**
 * A higher-order function to apply generate the individual async action creators from their
 * types, which are needed  by `fetchAction`
 * @param {Object} actionTypes The action type strings
 * @param {String} actionTypes.startActionType The action type to dispatch when a request starts
 * @param {String} actionTypes.successActionType The action type to dispatch when a request succeeds
 * @param {String} actionTypes.failureActionType The action type to dispatch when a request fails
 *
 * @example
 * ```js
 * const myCustomFetchAction = compose(
 *  fetchAction,
 *  createAsyncActions({
 *    startActionType: 'FETCH_START',
 *    successActionType: 'FETCH_START',
 *    failureActionType: 'FETCH_FAILURE',
 *   })
 * );
 * dispatch(myCustomFetchAction({ id: 1 }));
 *
 * // Same as:
 * const myCustomFetchAction = fetchAction(createAsyncActions({
 *  startActionType: 'FETCH_START',
 *  successActionType: 'FETCH_START',
 *  failureActionType: 'FETCH_FAILURE',
 * })(fetchActionOptions));
 *
 * dispatch(myCustomFetchAction({ id: 1 }));
 * ```
 */
export default ({
  failureActionType,
  startActionType,
  successActionType,
}) => options => ({
  ...options,
  startAction: payload => ({
    type: startActionType,
    payload: payload,
  }),
  successAction: payload => ({
    type: successActionType,
    payload: payload,
  }),
  failureAction: payload => ({
    type: failureActionType,
    payload: payload,
  }),
});
