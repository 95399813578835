/**
 * A handler function which can be used to call prevent default on a
 * browser event.
 * @param {Event} e The event
 * @return {{false}} Always returns false.
 *
 * @example
 * // Prevent a form from being submitted:
 * <form onSubmit={preventDefaultHandler} />
 *
 * // Prevent a link from being followed by the browser
 * <a onClick={preventDefaultHandler} >Link text</a>
 */
const preventDefaultHandler = e => {
  if (e && typeof e.preventDefault === 'function') {
    e.preventDefault();
  }

  return false;
};

export default preventDefaultHandler;
