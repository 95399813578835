import getCandidacyStage from './getCandidacyStage';

/**
 * Gets a value of the candidates stage suitable for sorting.
 * @param {Object} state
 * @param {Number} id The candidacy ID
 * @return {Number|undefined} A value that can be used to sort by the candidate's stage
 */
const getCandidacySortStage = (state, candidacyId) => {
  const stage = getCandidacyStage(state, candidacyId);
  return stage && stage.get('position');
};

export default getCandidacySortStage;
