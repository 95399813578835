import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {
  branch,
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
} from 'recompose';
import getCasualName from 'modules/contacts/selectors/contacts/getCasualName';
import VoteButton from 'modules/forms/components/VoteButton';
import voteCandidacyAction from '../../actions/voteCandidacy';
import mapCandidacyIdToCandidacy from '../mapCandidacyIdToCandidacy';
import mapCandidacyIdToVote from './mapCandidacyIdToVote';
import CandidacyVoteForm from './CandidacyVoteForm';

/**
 * Creates a button for voting for a candidacy record with a thumbs up or thumbs down.
 * When clicked a popover providing an form for including an optional comment is displayed.
 */
export default compose(
  setDisplayName('CandidacyVoteButton'),
  setPropTypes({
    /**
     * The ID of candidacy voting for/agianst
     */
    candidacyId: PropTypes.number.isRequired,

    /**
     * When true, simply clicking the button will vote immediately. Otherwise a comment form will
     * be displayed first.
     */
    disableCommenting: PropTypes.bool.isRequired,

    /**
     * `1` for "priority", `-1` for "not a fit"
     */
    value: PropTypes.oneOf([-1, 1]).isRequired,
  }),
  defaultProps({
    disableCommenting: false,
  }),
  mapCandidacyIdToCandidacy,
  mapCandidacyIdToVote,
  connect(
    (state, { candidacy, value, vote }) => {
      const contactName =
        candidacy && getCasualName(state, candidacy.get('contact'));
      const checked = vote === value;

      return {
        checked: checked,

        className: classnames('CandidacyVoteButton', {
          'CandidacyVoteButton--up': value > 0,
          'CandidacyVoteButton--down': value < 0,
        }),

        // Disable the button while voting or updating the current candidacy.
        disabled:
          candidacy.getIn(['_meta', 'isVoting']) ||
          candidacy.getIn(['_meta', 'isUpdating']),

        toolTipText: value > 0 ? 'Mark as Priority' : 'Mark as Not a Fit',

        // Get the vote count for this particular value. Note that the keys are strings, not numbers!
        voteCount: candidacy && candidacy.getIn(['vote_tally', String(value)]),

        // Generate the vote form title based on the contact's name and what the vote will be.
        voteFormTitle: `Why is ${contactName || 'this person'} ${
          checked ? 'no longer' : ''
        } ${value > 0 ? 'a priority' : 'not a fit'}?`,

        voteFormProps: {
          candidacyId: candidacy.get('id'),

          // If the vote is already "checked", then voting will "unvote", this is
          // done by passing a false value to the action.
          vote: checked ? false : value,
        },
      };
    },
    {
      voteCandidacy: voteCandidacyAction,
    },
  ),

  // If commenting is disbled, handle the `onVoteClick` directly, otherwise pass in a
  // voteFormComponent to be displayed when voting.
  branch(
    ({ disableCommenting }) => disableCommenting,
    withHandlers({
      onVoteClick: ({ candidacyId, checked, value, voteCandidacy }) => () => {
        voteCandidacy({ id: candidacyId, vote: checked ? false : value });
      },
    }),
    withProps({
      voteFormComponent: CandidacyVoteForm,
    }),
  ),
)(VoteButton);
