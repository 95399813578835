import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, defaultProps, setDisplayName, setPropTypes } from 'recompose';
import classnames from 'classnames';
import isCandidateUser from 'modules/auth/selectors/isCandidateUser';
import getPositionProperty from '../selectors/getPositionProperty';

import PositionCompanyName from './PositionCompanyName';
import PositionName from './PositionName';

/**
 * Renders the "title" for a position: a combination the name of the position plus the
 * company name, optionally showing a target company icon.
 */
export const PositionTitle = ({
  isCandidate,
  linkToCompany,
  nameFirst,
  positionId,
  positionName,
  targetCompany,
}) => (
  <div
    className={classnames('PositionTitle', {
      'PositionTitle--name-first': nameFirst && positionName,
    })}
  >
    {nameFirst &&
      positionName && [
        <PositionName key='pre' positionId={positionId} />,
        ' at ',
      ]}
    <div className='PositionTitle__company'>
      {targetCompany && (
        <i className='fa fa-bullseye target-company-indicator' />
      )}
      <PositionCompanyName
        linkToCompany={!isCandidate && linkToCompany}
        positionId={positionId}
      />
    </div>
    {!nameFirst && positionName && (
      <PositionName key='post' positionId={positionId} />
    )}
  </div>
);

PositionTitle.propTypes = {
  /**
   * Whether the current user is a candidate user or not.
   */

  isCandidate: PropTypes.bool,
  /**
   * Whether the company name should link to the company overview page.
   */
  linkToCompany: PropTypes.bool,

  /**
   * True to render:
   *   {title} at {company}
   *
   * False (default) to render:
   *   {company}
   *   {title}
   */
  nameFirst: PropTypes.bool,

  /**
   * The ID of the position to render the headline for.
   */
  positionId: PropTypes.number.isRequired,

  /**
   * The position name / title.
   */
  positionName: PropTypes.string,

  /**
   * When true renders a bullseye indicator next to the company name to indicate that the
   * company this position is attached to is a target company.
   */
  targetCompany: PropTypes.bool,
};

PositionTitle.defaultProps = {
  linkToCompany: false,
  nameFirst: false,
  targetCompany: false,
};

/**
 * The default export requires only a `positionId`, while
 * the named export requires the position record.
 */
export default compose(
  setDisplayName('PositionTitle(enhanced)'),
  setPropTypes({
    linkToCompany: PositionTitle.propTypes.linkToCompany,
    nameFirst: PositionTitle.propTypes.nameFirst,
    positionId: PositionTitle.propTypes.positionId,
    targetCompany: PositionTitle.propTypes.targetCompany,
  }),
  defaultProps(PositionTitle.defaultProps),
  connect(
    (state, { positionId }) => ({
      positionName: getPositionProperty(state, positionId, 'name'),
      isCandidate: isCandidateUser(state),
    }),
    {},
  ),
)(PositionTitle);
