import updateEntity from 'modules/entities/actions/updateEntity';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import routes from 'modules/routing/routes';

import { fetchContact } from 'modules/contacts/actions/contacts/index';
import positionSchema from '../schema';

/**
 * Creates an action for updating a position record.
 * @param {Object} payload
 * @param {Object} payload.position The position record to update
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({
  position: { company, ...position },
  transactionId,
}) => dispatch => {
  dispatch(
    updateEntity({
      body: {
        position: {
          // Rename 'company' key to 'company_id', as expected by the server.
          company_id: company,
          ...position,
        },
      },
      transactionId: transactionId,
      entityType: positionSchema.key,
      responseSchema: { position: positionSchema },
      url: routes.api_v1_position({ id: position.id }),
    }),
  ).then(action => {
    // Refetch the contact in order to guarantee that the off limits
    // indicator is showing the correct reason
    if (action.type === entityActionTypes.UPDATE_SUCCESS) {
      dispatch(fetchContact({ id: position.contact_id }));
    }

    return action;
  });
};
