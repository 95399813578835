import { PARENT_TYPES } from '../constants';
import getActivityList from './getActivityList';

/**
 * Determines if a list of activities should be fetched from the server.
 * @param {Object} state
 * @param {String} parentType The parent type (PARENT_CANDIDACY or PARENT_SEARCH)
 * @param {Number} parentId The parent ID (a contact ID or search ID, depending on
 *   the value of `parentType`)
 * @returns {Boolean} True if the list should be fetched from the server, false if we have the
 *   data and a fetch is not needed.
 */
const shouldFetchActivityList = (state, parentType, parentId) => {
  if (!PARENT_TYPES.includes(parentType)) {
    throw new Error(
      `Invalid parentType for 'shouldFetchActivityList': ${parentType}`,
    );
  }

  if (!parentId) {
    throw new Error('shouldFetchActivityList expects a `parentId`');
  }

  const activityList = getActivityList(state, parentType, parentId);

  if (!activityList) {
    return true;
  }

  if (activityList.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (activityList.hasIn(['_meta', 'error'])) {
    return false;
  }

  return (
    !activityList.has('ids') ||
    Boolean(activityList.getIn(['_meta', 'isInvalidated']))
  );
};

export default shouldFetchActivityList;
