/* eslint-disable camelcase */
// ^ accommodate API exchange format
import updateEntity from 'modules/entities/actions/updateEntity';
import routes from 'modules/routing/routes';
import companySchema from '../companySchema';

/**
 * Creates an action for updating a company record.
 * @param {Object} payload The action payload.
 * @param {Object} payload.company The company record to update
 * @param {Number} payload.company.id The ID of the company being updated
 * @param {Number} [payload.company.parent_company] The ID of the parent company
 * @param {Number} [payload.company.acquired_by_company] The ID of the company this company was
 *   acquired by.
 */
export default ({
  company: { acquired_by_company, parent_company, ...company },
  ...rest
}) =>
  updateEntity({
    ...rest,
    body: {
      company: {
        ...company,
        parent_company_id: parent_company,
        acquired_by_id: acquired_by_company,
      },
    },
    entityType: companySchema.key,
    id: company.id,
    responseSchema: { company: companySchema },
    url: routes.api_v1_company({ id: company.id }),
  });
