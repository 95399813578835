import { Map } from 'immutable';

/**
 * Combines multiple reducing functions into a single reducing function, where each reducing
 * function is responsible for keyed value of the state, where the state is an immutable iterable.
 * @param {Object} reducers The child reducers
 * @param {Object} [options] Additional options to modify the behavior of the reducing function.
 * @param {Iterable} [options.initialState=Map()] The initial state of the parent reducer.
 * @return {Function} A single reducing function that, in turn, calls each child reducer for it's
 *   associated key.
 */
export default function combineImmutableReducers(
  { ...reducers },
  { initialState = Map() } = {},
) {
  const reducerKeys = Object.keys(reducers);

  return (state = initialState, action) =>
    reducerKeys.reduce(
      (accumulatedState, key) =>
        accumulatedState.update(key, keyState =>
          reducers[key](keyState, action),
        ),
      Map.isMap(state) ? state : new Map(state),
    );
}
