const greaterThanOrEqualTo = number => fieldState => {
  const value = fieldState.getRawValue();

  if (typeof value === 'number' && !(value >= number)) {
    return `Value must be at least ${number}`;
  }

  return null;
};

export default greaterThanOrEqualTo;
