/* eslint-disable react/jsx-props-no-spreading */
// ^ Accommodate legacy code
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { scrollParent } from '@thrivetrm/ui/utilities/domUtils';

/**
 * A higher-order component that provides props necessary for getting the scroll container of a
 * rendered element.
 *
 * This is typically used when rendering an Overlay component.
 *
 * @example
 * ```
 * const MyComponent = ({ setTargetRef }) => (
 *   <div ref={setTargetRef}>
 *     <Overlay
 *       container={getScrollContainer}
 *       target={getTargetRef}
 *     >
 *        [...overlay content]
 *     </Overlay>
 *   </div>
 * );
 * ```
 *
 * const MyComponentWithScrolLContainerRef = withScrollContainerRef(MyComponent)
 */
const withScrollContainerRef = BaseComponent =>
  class withScrollContainerRefWrapper extends Component {
    setTargetRef = el => {
      this.targetEl = el;
    };

    getTargetRef = () => this.targetEl;

    getScrollContainer = () => {
      const parent = scrollParent(this.targetEl) || this.targetEl;

      // HACK: This is a temporary fix for a problem in Chrome 61+ that causes
      // the scroll offset to be calculated incorrectly when an Overlay is
      // given `document.scrollingElement` as the container.
      // see https://github.com/react-bootstrap/react-overlays/issues/200
      if (document && document.scrollingElement === parent) {
        return document.body;
      }

      // Exception for modals:
      // When we render things in a modal, we should use the modal-parent element as the container.
      if (parent && parent.classList.contains('modal-content')) {
        return parent.parentNode;
      }

      return parent;
    };

    render() {
      return (
        <BaseComponent
          {...this.props}
          getScrollContainer={this.getScrollContainer}
          getTargetRef={this.getTargetRef}
          setTargetRef={this.setTargetRef}
        />
      );
    }
  };

withScrollContainerRef.propTypes = {
  /**
   * Gets a reference to the scrolling container that the target ref belongs to.
   */
  getScrollContainer: PropTypes.func.isRequired,

  /**
   * Gets a reference to the target element
   */
  getTargetRef: PropTypes.func,

  /**
   * Sets the reference to the target element
   */
  setTargetRef: PropTypes.func.isRequired,
};

export default withScrollContainerRef;
