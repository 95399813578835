export const NAME = 'targetCompanies';

export const SORT_BY_CANDIDATES_COUNT = 'candidates_count';
export const SORT_BY_COMPANY = 'name';
export const SORT_BY_COMPANY_LOCATION_NAME = 'location_name';
export const SORT_BY_PRIORITY_VOTE_COUNT = 'priority_vote_count';
export const SORT_BY_LATEST_EMPLOYEE_COUNT = 'latest_employee_count';

export const SORT_BY_DEFAULT = SORT_BY_PRIORITY_VOTE_COUNT;

export const SORT_ASCENDING_DEFAULT = true;

export const SORT_BY_VALUES = [
  SORT_BY_COMPANY,
  SORT_BY_PRIORITY_VOTE_COUNT,
  SORT_BY_CANDIDATES_COUNT,
  SORT_BY_COMPANY_LOCATION_NAME,
  SORT_BY_LATEST_EMPLOYEE_COUNT,
];

export const SORT_BY_LABELS = {
  [SORT_BY_COMPANY]: 'Name',
  [SORT_BY_PRIORITY_VOTE_COUNT]: 'Priority',
  [SORT_BY_CANDIDATES_COUNT]: 'Candidate Count',
  [SORT_BY_COMPANY_LOCATION_NAME]: 'Location',
  [SORT_BY_LATEST_EMPLOYEE_COUNT]: 'Latest Employee Count',
};

export const SORT_BY_OPTIONS = Object.keys(SORT_BY_LABELS);

//---------------------------------------------------------
// Search page view modes
//---------------------------------------------------------

/**
 * The mode for rendering a the grid of target company "cardS"
 */
export const VIEW_MODE_GRID = 'VIEW_MODE_GRID';

/**
 * The mode for rendering target companies in a table-like list.
 */
export const VIEW_MODE_TABLE = 'VIEW_MODE_TABLE';

/**
 * The default view mode to render.
 */
export const VIEW_MODE_DEFAULT = VIEW_MODE_GRID;

/**
 * All possible valid list modes.
 */
export const VIEW_MODES = [VIEW_MODE_GRID, VIEW_MODE_TABLE];

/**
 * The view modes to display in the view mode select control.
 */
export const SELECTOR_VIEW_MODES = [VIEW_MODE_GRID, VIEW_MODE_TABLE];

/**
 * The labels used to describe each view mode.
 */
export const VIEW_MODE_LABELS = {
  [VIEW_MODE_GRID]: 'View Cards',
  [VIEW_MODE_TABLE]: 'View in List',
};

/**
 * The icons identifying each view mode.
 */
export const VIEW_MODE_ICONS = {
  [VIEW_MODE_GRID]: 'fa-th',
  [VIEW_MODE_TABLE]: 'fa-bars',
};

//---------------------------------------------------------
// Table view columns
//---------------------------------------------------------

export const COLUMN_COMPANY_NAME = 'company_name';
export const COLUMN_LOCATION_NAME = 'location_name';
export const COLUMN_INDUSTRY = 'industry';
export const COLUMN_CANDIDATE_COUNT = 'candidate_count';
export const COLUMN_COMPANY_NAME_WITH_CANDIDATE_COUNT =
  'company_name_with_candidate_count';
export const COLUMN_LATEST_EMPLOYEE_COUNT = 'latest_employee_count';
