import { compose } from 'recompose';
import { POST } from 'modules/api/methods';
import fetchAction from 'modules/api/fetchAction';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import withOptions from 'modules/api/actionModifiers/withOptions';
import routes from 'modules/routing/routes';
import {
  TARGET_COMPANY_VOTE_START,
  TARGET_COMPANY_VOTE_SUCCESS,
  TARGET_COMPANY_VOTE_FAILURE,
} from './actionTypes';
import { targetCompanySchema, targetCompanyCommentSchema } from '../schema';

/**
 * An action for voting for a target company
 * @param {Object} payload
 * @param {Number} payload.targetCompanyId The ID of the target company to vote for
 * @param {String} payload.comment The comment to create when voting.
 * @param {String} [payload.transactionId]
 */
export default compose(
  fetchAction,
  normalizeResponse({
    target_company: targetCompanySchema,
    comment: targetCompanyCommentSchema,
  }),
  createAsyncActions({
    startActionType: TARGET_COMPANY_VOTE_START,
    successActionType: TARGET_COMPANY_VOTE_SUCCESS,
    failureActionType: TARGET_COMPANY_VOTE_FAILURE,
  }),
  withOptions(({ comment, targetCompanyId }) => ({
    body: { comment: comment },
    method: POST,
    url: routes.api_v1_target_company_votes({
      targetCompanyId: targetCompanyId,
    }),
  })),
);
