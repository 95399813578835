import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classnames from 'classnames';

import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';

import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import FieldState from 'modules/forms/FieldState';
import MonthYearSelectField from 'modules/datetime/components/MonthYearSelectField';

/**
 * This component is for selecting both the 'from' position date and the 'to' position date.
 * It includes a checkbox that, when checked shows a month and year selector
 * for 'to' position date. When the checkbox is not checked, the 'to' month
 * and year selectors are hidden.
 */

const PositionDateRangeField = ({
  dateColClass,
  fieldState,
  isCurrentLabel,
  onChange,
  ...props
}) => {
  const componentId = uniqueId();
  const isCurrentPosition = fieldState.getNestedFieldValue('present');

  const [lastToValue, setLastToValue] = useState(
    fieldState.getNestedFieldValue('to'),
  );

  const handleCurrentPositionChange = ({ target }) => {
    const isCurrentPositionChecked = target.checked;
    const toValue = isCurrentPositionChecked ? null : lastToValue;
    const newFieldState = fieldState
      .setNestedFieldValue('present', isCurrentPositionChecked)
      .setNestedFieldValue('to', toValue);

    onChange(newFieldState);
  };

  const handleDateChange = nestedField => {
    onChange(fieldState.setNestedField(nestedField));
    setLastToValue(nestedField.getValue());
  };

  return (
    <div className='PositionDateRangeField row'>
      <div className='col-6'>
        <MonthYearSelectField
          {...props}
          fieldState={fieldState.getNestedField('from')}
          label='Start Date'
          minYear={-65}
          name='from'
          onChange={handleDateChange}
        />
        <div className='checkbox'>
          <label htmlFor={componentId}>
            <input
              checked={Boolean(isCurrentPosition)}
              className='PositionDateRangeField__checkbox inline-form-control'
              id={componentId}
              onChange={handleCurrentPositionChange}
              type='checkbox'
            />
            <span> {isCurrentLabel}</span>
          </label>
        </div>
      </div>

      {!isCurrentPosition ? (
        <MonthYearSelectField
          {...props}
          className={classnames('PositionDateRangeField__to', dateColClass)}
          fieldState={fieldState.getNestedField('to')}
          label='End Date'
          minYear={-65}
          name='to'
          onChange={handleDateChange}
        />
      ) : (
        /* eslint-disable jsx-a11y/label-has-associated-control */
        <div className={classnames('form-group', dateColClass)}>
          <label className='control-label'>End Date</label>
          <div>Present</div>
        </div>
      )}
    </div>
  );
};

PositionDateRangeField.propTypes = {
  /**
   * The bootstrap col-* class to apply to the date field.
   */
  dateColClass: PropTypes.string,

  fieldState: fieldStatePropType.isRequired,

  /**
   * The bootstrap col-* class to apply to the "current" field.
   */
  isCurrentColClass: PropTypes.string,

  /**
   * The label to display in the "current" field.
   */
  isCurrentLabel: PropTypes.node,

  /**
   * Called when the value changes. The value will be either the entered date,
   * or `null` if the "Current position" checkbox is checked.
   */
  onChange: PropTypes.func.isRequired,
};

PositionDateRangeField.defaultProps = {
  dateColClass: 'col-6',
  isCurrentColClass: 'col-6',
  isCurrentLabel: 'Currently working in this role',
};

PositionDateRangeField.createFieldState = (
  name = 'dateRange',
  initialValues = {},
) =>
  FieldState.createNested(name, [
    MonthYearSelectField.createFieldState('to', initialValues.to),
    MonthYearSelectField.createFieldState('from', initialValues.from),
    MonthYearSelectField.createFieldState('present', initialValues.present),
  ]);

export default PositionDateRangeField;
