// Interim Compensation names //

export const CONTACT_DESIRED_INTERIM_COMPENSATION =
  'contact_desired_interim_compensation';

export const CONTACT_CURRENT_INTERIM_COMPENSATION =
  'contact_current_interim_compensation';

export const CONTACT_INTERIM_COMPENSATION_NAMES = [
  CONTACT_DESIRED_INTERIM_COMPENSATION,
  CONTACT_CURRENT_INTERIM_COMPENSATION,
];

/**
 * The titles to display for a particular interim compensation section as identified by the
 * interim compensation record type name.
 */
export const CONTACT_INTERIM_COMPENSATION_TITLES = {
  [CONTACT_DESIRED_INTERIM_COMPENSATION]: 'Desired Interim Compensation',
  [CONTACT_CURRENT_INTERIM_COMPENSATION]: 'Current Interim Compensation',
};
