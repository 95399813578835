import { NAME } from '../constants';

export const INTRODUCTION_LIST_FETCH_START = `${NAME}/INTRODUCTION_LIST_FETCH_START`;
export const INTRODUCTION_LIST_FETCH_SUCCESS = `${NAME}/INTRODUCTION_LIST_FETCH_SUCCESS`;
export const INTRODUCTION_LIST_FETCH_FAILURE = `${NAME}/INTRODUCTION_LIST_FETCH_FAILURE`;
export const INTRODUCTION_LIST_INVALIDATE = `${NAME}/INTRODUCTION_LIST_INVALIDATE`;

export const INTRODUCTION_FETCH_START = `${NAME}/INTRODUCTION_FETCH_START`;
export const INTRODUCTION_FETCH_SUCCESS = `${NAME}/INTRODUCTION_FETCH_SUCCESS`;
export const INTRODUCTION_FETCH_FAILURE = `${NAME}/INTRODUCTION_FETCH_FAILURE`;
export const INTRODUCTION_INVALIDATE = `${NAME}/INTRODUCTION_INVALIDATE`;

export const INTRODUCTION_CREATE_START = `${NAME}/INTRODUCTION_CREATE_START`;
export const INTRODUCTION_CREATE_SUCCESS = `${NAME}/INTRODUCTION_CREATE_SUCCESS`;
export const INTRODUCTION_CREATE_FAILURE = `${NAME}/INTRODUCTION_CREATE_FAILURE`;

export const INTRODUCTION_UPDATE_START = `${NAME}/INTRODUCTION_UPDATE_START`;
export const INTRODUCTION_UPDATE_SUCCESS = `${NAME}/INTRODUCTION_UPDATE_SUCCESS`;
export const INTRODUCTION_UPDATE_FAILURE = `${NAME}/INTRODUCTION_UPDATE_FAILURE`;

export const INTRODUCTION_DELETE_START = `${NAME}/INTRODUCTION_DELETE_START`;
export const INTRODUCTION_DELETE_SUCCESS = `${NAME}/INTRODUCTION_DELETE_SUCCESS`;
export const INTRODUCTION_DELETE_FAILURE = `${NAME}/INTRODUCTION_DELETE_FAILURE`;
