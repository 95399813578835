//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'interviews';

//---------------------------------------------------------
// Parent types
//---------------------------------------------------------

export const PARENT_CANDIDACY = 'candidacy';
export const PARENT_SEARCH = 'search';
export const PARENT_CONTACT = 'contact';

export const PARENT_TYPES = [PARENT_CANDIDACY, PARENT_SEARCH, PARENT_CONTACT];

//---------------------------------------------------------
// Groupings
//---------------------------------------------------------

export const DATE_GROUP_PAST = 'past';
export const DATE_GROUP_UPCOMING = 'upcoming';

export const DATE_GROUPS = [DATE_GROUP_PAST, DATE_GROUP_UPCOMING];

export const DATE_GROUP_LABELS = {
  [DATE_GROUP_PAST]: 'Past Interviews',
  [DATE_GROUP_UPCOMING]: 'Upcoming Interviews',
};

//---------------------------------------------------------
// Sorting
//---------------------------------------------------------

export const SORT_BY_DATE = 'start_time';
export const SORT_BY_INTERVIEWERS = 'interviewers';
export const SORT_BY_CANDIDATE_NAME = 'candidate_name';

/**
 * An array of all available/valid sort-by options.
 */
export const SORT_BY_VALUES = [
  SORT_BY_DATE,
  SORT_BY_INTERVIEWERS,
  SORT_BY_CANDIDATE_NAME,
];

//---------------------------------------------------------
// Interview types
//---------------------------------------------------------

export const TYPE_INTERVIEW_SCHEDULING = 'scheduling';
export const TYPE_INTERVIEW_SCHEDULED = 'scheduled';
export const PERMITTED_TYPES = [
  TYPE_INTERVIEW_SCHEDULED,
  TYPE_INTERVIEW_SCHEDULING,
];

export const INTERVIEW_TYPE_CLIENT = 'Client';
export const INTERVIEW_TYPE_HIRING_MANAGER = 'Hiring Manager';
