import { branch, compose, mapProps, withHandlers, withState } from 'recompose';
import isExecutiveSearchTenant from 'modules/tenant/selectors/isExecutiveSearchTenant';
import { connect } from 'react-redux';

/**
 * Groups list items by stage type
 */
export default compose(
  // First group by stage and sort these groups by position
  mapProps(({ listItems, ...rest }) => ({
    ...rest,
    listItemGroups:
      listItems &&
      listItems
        .groupBy(listItem => listItem.stage && listItem.stage)
        .sortBy((_, stage) => (stage ? stage.get('position') : 0)),
  })),

  // Non executive search tenants should have all stages expanded, look that up here and
  // pass it along to the next withState HOC so it can be used when setting the initial
  // collapsedGroups state.
  connect(
    state => ({
      initiallyExpandAll: !isExecutiveSearchTenant(state),
    }),
    {},
  ),

  // Keep track of which groups are collapsed. This is better than tracking expanded groups because:
  // 1.
  withState(
    'collapsedGroups',
    'setCollapsedGroups',
    ({ initialCollapsedGroups, initiallyExpandAll, listItemGroups }) => {
      // The calculated behavior can be overriden by passing in this prop.
      if (initialCollapsedGroups) {
        return initialCollapsedGroups;
      }

      // Determine which groups should be initially collapsed.
      if (initiallyExpandAll) {
        return [];
      }

      // 'execute_search' tenants should only have "active" searches expanded
      // by default (unless there are currently no tenants in an active stage,
      // then the first stage with candidates should be expanded).
      const inactiveGroups = listItemGroups.filter(
        (group, stage) => stage && !stage.get('active'),
      );
      if (inactiveGroups.count() < listItemGroups) {
        return inactiveGroups.map((group, stage) => stage.get('id')).toArray();
      }

      // No active groups.
      return listItemGroups
        .skip(1)
        .map((group, stage) => stage.get('id'))
        .toArray();
    },
  ),

  // When filtering, we expand all groups by default and maintain a separate collapsed group state
  branch(
    ({ isFiltered }) => isFiltered,
    withState('collapsedGroups', 'setCollapsedGroups', []),
  ),

  mapProps(({ collapsedGroups, listItemGroups, ...rest }) => ({
    ...rest,
    collapsedGroups: collapsedGroups,
    listItems: listItemGroups
      .map((groupItems, stage) => ({
        isGroup: true,
        stage: stage,
        groupItems: groupItems.map((item, index) => ({
          // Assign the relative candidacy ids for each list item, for ranking purposes.
          // With candidacies grouped by stage, all changes to rank need to be
          // confined to the context of the candidacy's stage.
          ...item,
          firstCandidacyId: groupItems.first().candidacyId,
          lastCandidacyId: groupItems.last().candidacyId,
          previousCandidacyId:
            index === 0 ? null : groupItems.get(index - 1).candidacyId,
          nextCandidacyId:
            index >= groupItems.size - 1
              ? null
              : groupItems.get(index + 1).candidacyId,
        })),
        itemCount: groupItems.count(),
        expanded: !collapsedGroups.includes(stage && stage.get('id')),
      }))
      .toList()
      .flatMap(group => (group.expanded ? [group, group.groupItems] : [group]))
      .flatten(),
  })),

  withHandlers({
    toggleGroup: ({ collapsedGroups, setCollapsedGroups }) => groupId => {
      const index = collapsedGroups.indexOf(groupId);
      if (index >= 0) {
        const collapsedGroupsCopy = collapsedGroups.slice(0);
        collapsedGroupsCopy.splice(index, 1);
        setCollapsedGroups(collapsedGroupsCopy);
      } else {
        setCollapsedGroups([groupId].concat(collapsedGroups));
      }
    },
  }),
);
