import getTargetCompanyList from './getTargetCompanyList';

/**
 * Determines if a list of introductions should be fetched.
 * @param {Object} state
 * @param {Number} searchId The searchId of the target companies.
 * @return {Boolean} True if the list should be fetched from the server, false if we have the
 *   data and a fetch is not needed.
 */
export default (state, searchId) => {
  if (!searchId) {
    return false;
  }

  const targetCompanyList = getTargetCompanyList(state, searchId);

  if (!targetCompanyList) {
    return true;
  }

  if (targetCompanyList.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (targetCompanyList.hasIn(['_meta', 'error'])) {
    return false;
  }

  return (
    !targetCompanyList.has('ids') ||
    Boolean(targetCompanyList.getIn(['_meta', 'isInvalidated']))
  );
};
