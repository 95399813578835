import { connect } from 'react-redux';
import getStageListForStageType from '../selectors/getStageListForStageType';

/**
 * A higher order component which maps a `stageType` prop value to it's list of stages.
 */
export default connect(
  (state, { stageType }) => ({
    stageList: getStageListForStageType(state, stageType),
  }),
  {},
);
