import { fromJS, Map } from 'immutable';
import {
  TENANT_OPTIONS_FETCH_START,
  TENANT_OPTIONS_FETCH_FAILURE,
  TENANT_OPTIONS_FETCH_SUCCESS,
} from '../actions/actionTypes';

/**
 * Maintains the state for the list of all tenant option lists.
 * These are all fetched at once and keyed on their type, each containing
 * a list of IDs.
 * @see `fetchTenantOptions` action for more details.
 */
export default (state = new Map(), action) => {
  switch (action.type) {
    case TENANT_OPTIONS_FETCH_START: {
      return state
        .setIn(['meta', 'isFetching'], true)
        .setIn(['meta', 'lastFetched'], Date.now());
    }
    case TENANT_OPTIONS_FETCH_FAILURE: {
      return state
        .setIn(['meta', 'isFetching'], false)
        .setIn(['meta', 'error'], action.payload.error);
    }
    case TENANT_OPTIONS_FETCH_SUCCESS: {
      return state
        .setIn(['meta', 'isFetching'], false)
        .deleteIn(['meta', 'error'])
        .mergeDeep(fromJS(action.payload.result.tenant_options));
    }
    default: {
      return state;
    }
  }
};
