import { NAME } from '../constants';

export const USER_OPTIONS_FETCH_START = `${NAME}/USER_OPTIONS_FETCH_START`;
export const USER_OPTIONS_FETCH_SUCCESS = `${NAME}/USER_OPTIONS_FETCH_SUCCESS`;
export const USER_OPTIONS_FETCH_FAILURE = `${NAME}/USER_OPTIONS_FETCH_FAILURE`;

export const USER_OPTIONS_UPDATE_START = `${NAME}/USER_UPDATE_START`;
export const USER_OPTIONS_UPDATE_SUCCESS = `${NAME}/USER_UPDATE_SUCCESS`;
export const USER_OPTIONS_UPDATE_FAILURE = `${NAME}/USER_UPDATE_FAILURE`;

export const USER_OPTIONS_INVALIDATE = `${NAME}/USER_OPTIONS_FETCH_FAILURE`;

export const SET_PREFERENCE = `${NAME}/SET_PREFERENCE`;
