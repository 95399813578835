import React from 'react';
import PropTypes from 'prop-types';
import Card from '@thrivetrm/ui/components/Card';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import ExternalLink from 'modules/links/ExternalLink';
import Avatar from '@thrivetrm/ui/components/Avatar';

const SkyminyrLegacyCompanyDetailsCard = ({ companyDetails, onRemove }) => {
  const companyAddress = companyDetails.addresses?.[0] ?? null;
  return (
    <Card className='u-flex u-flexJustify-spaceBetween' isCentered={false}>
      <div className='u-flex u-flexAlign-c'>
        <Avatar
          className='SkyminyrCompanySelect__avatar u-marginRight-8'
          image={companyDetails.avatar}
          name={companyDetails.name}
          shape='square'
          size='small'
        />
        <div className='SkyminyrLegacyCompanySelect__detailsSection u-flexItem-grow'>
          <p className='u-margin-n u-textColor-gray60'>{companyDetails.name}</p>
          {companyDetails.enriched_sectors?.slice(0, 3)?.join(', ')}
          {companyAddress ? (
            <p className='u-margin-n'>
              {[
                companyAddress.address_line_1,
                companyAddress.city,
                companyAddress.state,
                companyAddress.country,
                companyAddress.postal_code,
              ]
                .filter(Boolean)
                .join(', ')}
            </p>
          ) : null}
          {companyDetails.website_url ? (
            <ExternalLink
              className='u-block u-truncate'
              href={companyDetails.website_url}
            >
              {companyDetails.website_url}
            </ExternalLink>
          ) : null}
        </div>
      </div>
      <ButtonSecondary
        className='SkyminyrCompanySelect__companyRemoveButton'
        icon='close'
        onClick={onRemove}
        size='small'
      />
    </Card>
  );
};

SkyminyrLegacyCompanyDetailsCard.propTypes = {
  companyDetails: PropTypes.shape({
    addresses: PropTypes.arrayOf(
      PropTypes.shape({
        address_line_1: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
        postal_code: PropTypes.string,
        state: PropTypes.string,
      }),
    ),
    avatar: PropTypes.string,
    enriched_sectors: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string.isRequired,
    website_url: PropTypes.string,
  }),
  onRemove: PropTypes.func.isRequired,
};

export default SkyminyrLegacyCompanyDetailsCard;
