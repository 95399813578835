import selectn from 'selectn';
import { fromJS, Map } from 'immutable';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import positionSchema from 'modules/positions/schema';
import createListReducer from '../../../reducers/createListReducer';
import composeReducers from '../../../reducers/composeReducers';
import {
  CONTACT_FETCH_START,
  CONTACT_FETCH_SUCCESS,
  CONTACT_FETCH_FAILURE,
  CONTACT_INVALIDATE,
} from '../actions/contacts/actionTypes';
import {
  POSITIONS_FETCH_START,
  POSITIONS_FETCH_SUCCESS,
  POSITIONS_FETCH_FAILURE,
} from '../actions/positions/actionTypes';
import { contactSchema } from '../schema';

const positionsListReducer = createListReducer({
  resultKey: 'positions',
  entityResultKey: 'position',
  request: POSITIONS_FETCH_START,
  success: POSITIONS_FETCH_SUCCESS,
  failure: POSITIONS_FETCH_FAILURE,
  created: entityActionTypes.CREATE_SUCCESS,
  deleted: entityActionTypes.DELETE_SUCCESS,
});

/**
 * contains positions keyed on the contact Id (obviously!).
 * positions are also be fetched with the contact entity itself, so we check for that as well.
 */
export default composeReducers(
  (state = new Map(), action) => {
    switch (action.type) {
      case CONTACT_FETCH_START: {
        const contactId = selectn('payload.id', action);

        if (contactId) {
          return state.setIn([contactId, '_meta', 'isFetching'], true);
        }

        return state;
      }
      case CONTACT_FETCH_FAILURE: {
        const contactId = selectn('payload.id', action);

        if (contactId) {
          return state
            .setIn([contactId, '_meta', 'isFetching'], false)
            .setIn([contactId, '_meta', 'error'], action.payload.error);
        }

        return state;
      }
      case CONTACT_FETCH_SUCCESS: {
        const contactId = selectn('payload.id', action);

        if (contactId) {
          // Note that the positions will be set in the reducer below.
          return state
            .setIn([contactId, '_meta', 'isFetching'], false)
            .deleteIn([contactId, '_meta', 'error']);
        }

        return state;
      }
      case CONTACT_INVALIDATE: {
        // When the contact is invalidated, invalidate the positions as well.
        const contactId = selectn('payload.id', action);

        if (contactId && state.has(contactId)) {
          return state.setIn([contactId, '_meta', 'isInvalidated'], true);
        }

        return state;
      }
      case POSITIONS_FETCH_START:
      case POSITIONS_FETCH_SUCCESS:
      case POSITIONS_FETCH_FAILURE: {
        const contactId = selectn('payload.contactId', action);

        if (contactId) {
          return state.update(contactId, list =>
            positionsListReducer(list, action),
          );
        }

        return state;
      }
      case entityActionTypes.CREATE_SUCCESS: {
        if (selectn('payload.entityType', action) !== positionSchema.key) {
          return state;
        }

        const positionId = selectn('payload.result.position', action);
        const position = selectn(
          `payload.entities.${positionSchema.key}.${positionId}`,
          action,
        );

        if (position && position.contact_id && state.has(position.contact_id)) {
          return state.update(position.contact_id, list =>
            positionsListReducer(list, action),
          );
        }

        return state;
      }
      case entityActionTypes.DELETE_SUCCESS: {
        if (selectn('payload.entityType', action) !== positionSchema.key) {
          return state;
        }

        return state.map(list => positionsListReducer(list, action));
      }
      default: {
        return state.map(list => positionsListReducer(list, action));
      }
    }
  },
  (state, action) => {
    // Check to see if any contact entities have been fetched, and if they have positions
    // included.
    const contacts = selectn('payload.entities.contacts', action);

    if (contacts) {
      const lists = Object.keys(contacts)
        .map(contactId => contacts[contactId])
        .filter(contact => contact.positions)
        .map(contact => [
          contactSchema.getId(contact),
          fromJS({
            ids: contact.positions,
            _meta: {
              error: null,
              isFetching: false,
              isInvalidated: false,
              lastFetched: Date.now(),
            },
          }),
        ]);

      if (lists.length) {
        return state.mergeDeep(lists);
      }
    }

    return state;
  },
);
