import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import activitySchema from '../schema';
import {
  ACTIVITIES_FETCH_START,
  ACTIVITIES_FETCH_SUCCESS,
  ACTIVITIES_FETCH_FAILURE,
} from './actionTypes';
import getActivityListUrl from './getActivityListUrl';

/**
 * Fetches the list of activities for a particular parent.
 * @param {Object} options
 * @param {String} options.parentType The parent type (PARENT_CANDIDACY, PARENT_SEARCH)
 * @param {Number} options.parentId The ID for the parent, dependent on parentType
 */
export default compose(
  fetchAction,
  normalizeResponse([activitySchema]),
  createAsyncActions({
    startActionType: ACTIVITIES_FETCH_START,
    successActionType: ACTIVITIES_FETCH_SUCCESS,
    failureActionType: ACTIVITIES_FETCH_FAILURE,
  }),
  withOptions(({ parentId, parentType }) => ({
    url: getActivityListUrl({ parentType: parentType, parentId: parentId }),
  })),
);
