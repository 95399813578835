import { NAME } from '../constants';

/**
 * Gets the list of available rejection reasons that can be selected from
 * when rejecting a candidacy.
 * @param {Object} state
 * @return {Immutable.List<String>}
 */
const getRejectionReasons = state =>
  state[NAME].getIn(['rejectionReasons', 'data']);

export default getRejectionReasons;
