// RTK Query uses the concept of "cache tags" to handle data invalidation. More info:
// https://redux-toolkit.js.org/rtk-query/usage/automated-refetching#cache-tags
// We are splitting up the definition of endpoints across modules, all cache tags
// should be defined here and imported into the modules that need them.

export default {
  CANDIDATE_TAGS: 'CandidateTags',
  CONTACT: 'Contact',
  CONNECTIONS: 'Connections',
  COMPANY: 'Company',
  CONTACT_NETWORKS: 'ContactNetworks',
  DOCUMENTS: 'Documents',
  INDEX_TABLE_RECORDS: 'IndexTableRecords',
  NETWORKS: 'Networks',
  RECRUITMENT_RATINGS: 'RecruitmentRatings',
  REPORTING_RELATIONSHIPS: 'ReportingRelationships',
  SUGGESTED_CONNECTIONS: 'SuggestedConnections',
  OFF_LIMITS: 'OffLimits',
  OFFERS: 'Offers',
  REFERENCES: 'References',
};
