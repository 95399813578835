/**
 * Renames the `ratings` property to `ratings_attributes` for submitting to the server.
 * @param {Object} assessment The assessment record
 * @param {Object} assessment.ratings The assessment's ratings records.
 * @return {Object} A new object with the `ratings` property renamed to `ratings_attributes`
 */
export default ({ ratings, ...assessment }) => {
  const ratingsAttributes = ratings;

  return {
    ratings_attributes: ratingsAttributes,
    ...assessment,
  };
};
