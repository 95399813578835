import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import allNetworks from './allNetworks';
import byContact from './byContact';
import contactsByNetwork from './contactsByNetwork';

const networksReducer = combineImmutableReducers({
  allNetworks: allNetworks,
  byContact: byContact,
  contactsByNetwork: contactsByNetwork,
});

networksReducer.NAME = NAME;

export default networksReducer;
