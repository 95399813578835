//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'candidacies';

//---------------------------------------------------------
// Parent types (types of candidacy list collections)
//---------------------------------------------------------

/**
 * Used to indicate a list of candidacies belonging to a search
 */
export const PARENT_SEARCH = 'search';

/**
 * Used to indicate a list of candidacies belonging to a search
 */
export const PARENT_PIPELINE = 'pipeline';

/**
 * Used to indicate a list of candidacies belongning to a conctact
 */
export const PARENT_CONTACT = 'contact';

/**
 * All available parent types that can be used.
 */
export const PARENT_TYPES = [PARENT_SEARCH, PARENT_PIPELINE, PARENT_CONTACT];

//---------------------------------------------------------
// Sorting
//---------------------------------------------------------

/**
 * Sort based on the date the candidacy record was created
 */
export const SORT_BY_AGE = 'SORT_BY_AGE';

/**
 * Sort based on the candidate's primary company name.
 */
export const SORT_BY_COMPANY_NAME = 'SORT_BY_COMPANY_NAME';

/**
 * Sort based on the date of last contact (the date of the most recent outreach record).
 */
export const SORT_BY_LAST_CONTACTED = 'SORT_BY_LAST_CONTACTED';

/**
 * Sort based on the name of the search's lead internal team member.
 */
export const SORT_BY_LEAD = 'SORT_BY_LEAD';

/**
 * Sort based on the candite's location name.
 */
export const SORT_BY_LOCATION = 'SORT_BY_LOCATION';

/**
 * Sort based on the candidate's full name.
 */
export const SORT_BY_NAME = 'SORT_BY_NAME';

/**
 * Sort based on the number of priority votes.
 */
export const SORT_BY_PRIORITY = 'SORT_BY_PRIORITY';

/**
 * Sort based on rank
 */
export const SORT_BY_RANK = 'SORT_BY_RANK';

/**
 * Sort based on the current stage (based on the stage's "position" property, not it's name)
 */
export const SORT_BY_STAGE = 'SORT_BY_STAGE';

/**
 * All valid SORT_BY_* type constants.
 * @const {Array}
 */
export const SORT_BY_TYPES = [
  SORT_BY_AGE,
  SORT_BY_COMPANY_NAME,
  SORT_BY_LAST_CONTACTED,
  SORT_BY_LEAD,
  SORT_BY_LOCATION,
  SORT_BY_NAME,
  SORT_BY_PRIORITY,
  SORT_BY_RANK,
  SORT_BY_STAGE,
];

/**
 * The label that should be displayed for a given sort type.
 * @type {Object}
 */
export const SORT_BY_LABELS = {
  [SORT_BY_AGE]: 'Date Added',
  [SORT_BY_COMPANY_NAME]: 'Company',
  [SORT_BY_LAST_CONTACTED]: 'Last Contacted',
  [SORT_BY_LEAD]: 'Search Lead',
  [SORT_BY_LOCATION]: 'Location',
  [SORT_BY_NAME]: 'Name',
  [SORT_BY_PRIORITY]: 'Priority',
  [SORT_BY_RANK]: 'Rank',
  [SORT_BY_STAGE]: 'Stage',
};

export const SORT_BY_DEFAULT = SORT_BY_COMPANY_NAME;

/**
 * Which sort direction should be the default direction initially.
 */
export const SORT_ASCENDING_DEFAULTS = {
  [SORT_BY_AGE]: false,
  [SORT_BY_COMPANY_NAME]: false,
  [SORT_BY_LAST_CONTACTED]: false,
  [SORT_BY_LEAD]: false,
  [SORT_BY_LOCATION]: false,
  [SORT_BY_NAME]: false,
  [SORT_BY_PRIORITY]: true,
  [SORT_BY_RANK]: false,
  [SORT_BY_STAGE]: false,
};

export const ASCENDING_SORT_ONLY = [SORT_BY_RANK];

export const SORT_ASCENDING_DEFAULT = SORT_ASCENDING_DEFAULTS[SORT_BY_DEFAULT];

//---------------------------------------------------------
// Filtering
//---------------------------------------------------------

/**
 * The key used for filtering by a target company. (i.e. tagetcompany:"Google")
 * @const {String}
 */
export const FILTER_KEY_TARGET_COMPANY = 'targetcompany';

/**
 * The key used for filtering by a company. (i.e. company:"Google")
 * @const {String}
 */
export const FILTER_KEY_COMPANY = 'company';

/**
 * The target company value to use to indicate the target company filter should
 * filter no matching target companies (targetcompany:"none")
 * @const {String}
 */
export const FILTER_TARGET_COMPANY_NONE = 'none';

/**
 * The Admin should be able to convert Contact to a Recommend Candidacy
 * Object for the stage dropdown
 * Since this isn't a proper "stage" in our database, we need to assign
 * a unique value that will not conflict with legitimate stage IDs.
 */
export const RECOMMENDED_STAGE_OPTION = {
  label: 'Recommended',
  value: 10000,
};
