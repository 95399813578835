import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import FieldState from 'modules/forms/FieldState';
import {
  queryDuplicateCompanies,
  getDuplicateCompanies,
  getDuplicateCompanyQueryIds,
  queryAllDuplicateCompanies,
} from './duplicateRecognition';

const withCompanyDuplicateIds = () => BaseComponent => {
  class CompanyDuplicatesHandler extends Component {
    componentDidMount() {
      const {
        companyActions,
        duplicateDetectionId,
        fieldState,
        queryDuplicates,
      } = this.props;
      const queryNames = getDuplicateCompanyQueryIds(duplicateDetectionId);
      queryNames.forEach(companyActions.createCompaniesQuery);

      if (queryDuplicates) {
        queryAllDuplicateCompanies(
          duplicateDetectionId,
          fieldState.getFieldValue(),
          companyActions.queryCompanies,
          true,
        );
      }
    }

    componentWillUnmount() {
      const { companyActions, duplicateDetectionId } = this.props;
      const queryNames = getDuplicateCompanyQueryIds(duplicateDetectionId);
      queryNames.forEach(companyActions.destroyCompaniesQuery);
    }

    /**
     * Called when the FieldState of the BaseComponent is changed
     * @param {FieldState} fieldState The new, updated FieldState value
     * @param {String|null} childFieldName The name of the child field (if any) that caused the
     *   FieldState change.
     */
    handleFieldChange = (fieldState, childFieldName) => {
      const {
        companyActions,
        duplicateDetectionId,
        onFieldStateChange,
      } = this.props;

      queryDuplicateCompanies(
        duplicateDetectionId,
        childFieldName,
        fieldState.getRawValue()[childFieldName].length > 2
          ? fieldState.getRawValue()
          : { name: '', website_url: '' },
        companyActions.queryCompanies,
      );
      onFieldStateChange(fieldState);
    };

    render() {
      const {
        companies,
        duplicateDetectionId,
        onChange: _onChange,
        ...baseComponentProps
      } = this.props;

      const duplicateIds = getDuplicateCompanies(
        duplicateDetectionId,
        companies,
      );

      return (
        <BaseComponent
          duplicateIds={duplicateIds}
          onChange={this.handleFieldChange}
          {...baseComponentProps}
        />
      );
    }
  }

  CompanyDuplicatesHandler.propTypes = {
    companies: ImmutablePropTypes.mapContains({
      queriesById: ImmutablePropTypes.map.isRequired,
    }).isRequired,

    companyActions: PropTypes.shape({
      createCompaniesQuery: PropTypes.func.isRequired,
      destroyCompaniesQuery: PropTypes.func.isRequired,
      queryCompanies: PropTypes.func.isRequired,
    }).isRequired,

    duplicateDetectionId: PropTypes.string.isRequired,

    fieldState: PropTypes.instanceOf(FieldState).isRequired,
    onChange: PropTypes.func,
    onFieldStateChange: PropTypes.func,
    queryDuplicates: PropTypes.bool,
  };

  return CompanyDuplicatesHandler;
};

export default withCompanyDuplicateIds;
