import selectn from 'selectn';
import { fromJS } from 'immutable';
import composeReducers from '../../../reducers/composeReducers';
import createFetchReducer from '../../../reducers/createFetchReducer';
import {
  USER_OPTIONS_FETCH_START,
  USER_OPTIONS_FETCH_SUCCESS,
  USER_OPTIONS_FETCH_FAILURE,
  USER_OPTIONS_INVALIDATE,
  USER_OPTIONS_UPDATE_START,
  USER_OPTIONS_UPDATE_SUCCESS,
  USER_OPTIONS_UPDATE_FAILURE,
} from '../actions/actionTypes';

const fetchUserOptionsReducer = createFetchReducer({
  invalidate: USER_OPTIONS_INVALIDATE,
  start: USER_OPTIONS_FETCH_START,
  success: USER_OPTIONS_FETCH_SUCCESS,
  failure: USER_OPTIONS_FETCH_FAILURE,
  transformer: data => fromJS(selectn('userOptions', data)),
});

const updateUserOptionsReducer = createFetchReducer({
  invalidate: USER_OPTIONS_INVALIDATE,
  start: USER_OPTIONS_UPDATE_START,
  success: USER_OPTIONS_UPDATE_SUCCESS,
  failure: USER_OPTIONS_UPDATE_FAILURE,
  transformer: data => {
    // I know this is ugly, but we need to set the custom contact columns in the global window.thv
    // object so they can be read by the non-react contact report modal.
    const newContactColumns =
      data.userOptions.column_preferences.contact_columns;
    window.thv.user.userOptions.column_preferences.contact_columns = newContactColumns;
    return fromJS(selectn('userOptions', data));
  },
});

export default composeReducers(
  fetchUserOptionsReducer,
  updateUserOptionsReducer,
);
