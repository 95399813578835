import getPreferredName from './getPreferredName';
import getFirstName from './getFirstName';
import getName from './getName';

/**
 * Gets a "casual" name to reference a contact by. Attempts to use the "preferred name", if any,
 * set on the record, but will fall back to the first name, and then to the full name if that is
 * not available.
 * @param {*} state
 * @param {Number} id The contact ID
 * @returns {String} The name for the contact.
 */
export default (state, id) =>
  getPreferredName(state, id) || getFirstName(state, id) || getName(state, id);
