import createEntityByIdReducer from '../../../reducers/createEntityByIdReducer';
import composeReducers from '../../../reducers/composeReducers';
import contactReducer from './contact';
import { contactSchema } from '../schema';
import {
  CONTACT_FETCH_START,
  CONTACT_FETCH_SUCCESS,
  CONTACT_FETCH_FAILURE,
  CONTACT_INVALIDATE,
  CONTACT_UPDATE_START,
  CONTACT_UPDATE_SUCCESS,
  CONTACT_UPDATE_FAILURE,
} from '../actions/contacts/actionTypes';

export default composeReducers(
  createEntityByIdReducer(contactSchema, {
    invalidated: CONTACT_INVALIDATE,
    fetchStart: CONTACT_FETCH_START,
    fetchSuccess: CONTACT_FETCH_SUCCESS,
    fetchFailure: CONTACT_FETCH_FAILURE,
    updateStart: CONTACT_UPDATE_START,
    updateSuccess: CONTACT_UPDATE_SUCCESS,
    updateFailure: CONTACT_UPDATE_FAILURE,
  }),
  (state, action) =>
    state.map(contactState => contactReducer(contactState, action)),
);
