import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Select, { Creatable } from 'react-select-legacy';
import { debounce } from 'lodash';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';

/**
 * The default function that generates the array of options that are passed to the react-select
 * component to be displayed in it's dropdown.
 * This default implementation returns the results converted to a javascript array, but only
 * if the search term meets the minimum requirements; Otherwise returns an empty array.
 * @param {Immutable.List} [data] The list of results returned from the server.
 * @param {Boolean} isMinimumLength True if the minimum query length was entered.
 * @return {Array} An array of options that can be passed to the react-select `options` prop.
 */
export const defaultCreateOptions = ({ data }) => (data ? data.toJS() : []);

/**
 * The default implementation of getItemById used by the QuerySelect.
 * @param {Map} itemsById The map of items by their IDs
 * @param {*} id The item's ID
 */
export const defaultGetItemById = (itemsById, id) => itemsById.get(id);

/**
 * A getItemById implementation for use with a map of entities, whose keys
 * are always strings
 */
export const entityMapGetItemById = (itemsById, id) =>
  itemsById.get(String(id));

export default class QuerySelect extends Component {
  constructor(...args) {
    super(...args);

    this.componentId = uniqueId();
    this.handleQueryDebounced = debounce(this.handleQuery, 300);

    this.state = {
      term: '',
    };
  }

  componentDidMount() {
    const {
      labelKey,
      loadOptionsOnMount,
      onCreate,
      value, // eslint-disable-line react/prop-types
    } = this.props;
    onCreate(this.componentId);

    if (loadOptionsOnMount && value && value[labelKey]) {
      this.handleQuery(value[labelKey]);
    }
  }

  componentWillUnmount() {
    const { onDestroy } = this.props;
    onDestroy(this.componentId);
  }

  handleQuery = term => {
    const { onQuery, queryParams } = this.props;

    onQuery({
      term: term,
      queryId: this.componentId,
      ...queryParams,
    });
  };

  handleInputChange = value => {
    const { minQueryLength, onInputChange } = this.props;

    const term = value ? value.trim() : '';
    if (term.length >= minQueryLength) {
      this.handleQueryDebounced(term);
    }

    this.setState({ term: term });

    if (onInputChange) {
      onInputChange(value);
    }

    // Important to return the `value` here and not the trimmed term, otherwise it will
    // prevent the user from actually typing a space (and entering multiple words, for example)
    return value;
  };

  render() {
    const {
      createOptions,
      id = this.componentId,
      inputProps,
      isCreatable,
      isLoading: isLoadingParent,
      itemsById,
      getItemById,

      minQueryLength,
      noResultsText,
      queriesById,

      /* eslint-disable no-unused-vars */
      // Prevent these props from being passed to the SelectComponent -- these are for our
      // consumption only.
      onCreate,
      onDestroy,
      onQuery,
      /* eslint-enable no-unused-vars */

      ...selectProps
    } = this.props;
    const { term } = this.state;
    const SelectComponent = isCreatable ? Creatable : Select;

    const ids = queriesById.getIn([this.componentId, 'data']);
    // If itemsById is specified, then we need to lookup the items themselves there, otherwise
    // the data should be provided directly from the queriesById data.
    const data =
      itemsById && ids
        ? ids.map(itemId => getItemById(itemsById, itemId))
        : ids;
    const isLoading =
      isLoadingParent ||
      queriesById.getIn([this.componentId, 'meta', 'isFetching']);
    const isMinimumLength = !minQueryLength || term.length >= minQueryLength;
    const noResults = isMinimumLength
      ? noResultsText
      : `Type at least ${minQueryLength} characters`;

    return (
      <SelectComponent
        {...selectProps}
        inputProps={{
          id: id,
          ...inputProps,
        }}
        isCreatable={isCreatable}
        isLoading={isLoading}
        noResultsText={isLoading ? 'Loading...' : noResults}
        onInputChange={this.handleInputChange}
        options={createOptions({
          data: data,
          isMinimumLength: isMinimumLength,
          isLoading: isLoading,
          term: term,
        })}
        tabSelectsValue={false}
      />
    );
  }
}

QuerySelect.propTypes = {
  /**
   * A function that returns the options that should be passed to the react-select component.
   * The default implementation returns the result of `data.toJS()` if the minimum term length
   * has been entered and data has a value.
   */
  createOptions: PropTypes.func,

  /**
   * Called with `itemsById` and an item ID, should return the item record based on that ID.
   * The main reason for this is so that we can modify the ID value before the lookup -- which
   * is needed when using the new `entities` module. All keys are converted to strings, so
   * in order to lookup the correct entity, the ID must be converted to a string (typically it
   * is a number).
   */
  getItemById: PropTypes.func,

  /**
   * A unique ID for the component.
   */
  id: PropTypes.string,

  /**
   * Optional props to pass through to the underlying <input /> element
   */
  inputProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types

  /**
   * True to use a react-select `Creatable` component false to use a standar react-select
   * `Select` component. @see https://github.com/JedWatson/react-select
   */
  isCreatable: PropTypes.bool,

  /**
   * The parent component can additionally supply an isLoading value when it may be doing some
   * additional loading outside of running the current query.
   */
  isLoading: PropTypes.bool,

  /**
   * The collection of all items, keyed by ID.
   * This should be provided when the underlying data is normalized -- where `queriesById.[id].data`
   * is simply collection of IDs, which must then be looked up in the `itemsById` collection.
   */
  itemsById: ImmutablePropTypes.map,

  labelKey: PropTypes.string,

  loadOptionsOnMount: PropTypes.bool,

  /**
   * The minimum number of characters that must be typed before a query for matching results
   * are triggered.
   */
  minQueryLength: PropTypes.number,

  /**
   * The text to display when there are no matching results returned from the server.
   */
  noResultsText: PropTypes.string,

  onCreate: PropTypes.func.isRequired,

  onDestroy: PropTypes.func.isRequired,

  onInputChange: PropTypes.func,

  onQuery: PropTypes.func.isRequired,

  queriesById: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.mapContains({
      data: PropTypes.any,
      meta: ImmutablePropTypes.mapContains({
        isFetching: PropTypes.bool,
      }),
    }),
    PropTypes.string,
  ).isRequired,

  /**
   * An optional map of additional parameters to pass along to the search query action.
   */
  queryParams: PropTypes.object, // eslint-disable-line react/forbid-prop-types

  /**
   * Search model type: 'JobSearch' or 'TalentPool'
   */
  searchType: PropTypes.oneOf(['JobSearch', 'TalentPool']),

  valueKey: PropTypes.string,
};

QuerySelect.defaultProps = {
  createOptions: defaultCreateOptions,
  getItemById: defaultGetItemById,
  isLoading: false,
  labelKey: 'name',
  loadOptionsOnMount: false,
  minQueryLength: 3,
  noResultsText: 'No results found',
  valueKey: 'id',
};
