import { NOTIFICATIONS_CATEGORY_ALL } from '../../domains/notifications/constants';
import forCategory, {
  INITIAL_STATE as INITIAL_NOTIFICATIONS_STATE,
} from './forCategory';

export const INITIAL_STATE = INITIAL_NOTIFICATIONS_STATE.setIn(
  ['meta', 'category'],
  NOTIFICATIONS_CATEGORY_ALL,
);

/**
 * A reducer that targets a notifications collection with the category `NOTIFICATIONS_CATEGORY_ALL`
 */
export default function allNotifications(state, action) {
  // Simply defer to the notifications with this specific category. We don't have any special
  // behavior for the "All notifications" collection beyond that.
  return forCategory(NOTIFICATIONS_CATEGORY_ALL, state, action);
}
