import { INVALIDATE } from './entityActionTypes';

/**
 * Returns an action for invalidating an entity
 * @param {Object} options
 * @param {String} options.entityType The entity type name (schema key)
 * @param {Number|String} options.id The ID of the entity to invalidate
 */
export default ({ entityType, id, ...options }) => ({
  type: INVALIDATE,
  payload: {
    entityType: entityType,
    id: id,
    ...options,
  },
});
