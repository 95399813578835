/* eslint-disable no-unused-vars */
// ^ legacy violations
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import {
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
} from 'recompose';
import Overlay from 'react-bootstrap/lib/Overlay';
import Popover from 'react-bootstrap/lib/Popover';
import withComponentId from './withComponentId';
import withScrollContainerRef from './withScrollContainerRef';
/**
 * Renders a button that, when clicked, displayed a popover message
 * asking the user to confirm an action with "yes"/"no" buttons.
 *
 * Any child components will be rendered inside the popover, above the
 * confirmation buttons.
 *
 * Unused properties will be passed through to the main button component.
 */
const ConfirmationPopoverButton = ({
  cancelLabel,
  children,
  className,
  componentId,
  confirmLabel,
  containerClassName,
  disabled,
  getScrollContainer,
  getTargetRef,
  handleCancelClick,
  handleClick,
  handleConfirmClick,
  iconClass,
  isActionDanger,
  isConfirmationEnabled,
  isConfirmationOpen,
  label,
  onCancel,
  onConfirm,
  popoverPlacement,
  rootClose,
  setTargetRef,
  title,
  ...otherProps
}) => [
  <button
    className={classnames(className, {
      ConfirmationPopoverButton__delete_btn: isConfirmationOpen,
    })}
    disabled={disabled}
    key='button'
    onClick={handleClick}
    ref={setTargetRef}
    type='button'
    {...otherProps}
  >
    {iconClass ? <i className={iconClass} /> : null}
    {label}
  </button>,
  <Overlay
    container={getScrollContainer}
    containerPadding={20}
    key={`${componentId}-overlay`}
    onHide={handleCancelClick}
    placement={popoverPlacement}
    rootClose={rootClose}
    show={isConfirmationOpen}
    target={getTargetRef}
  >
    <Popover className={containerClassName} id={componentId} title={title}>
      {children}
      <div
        className={classnames(
          {
            ConfirmationPopoverButton__action_btn: Boolean(children),
          },
          'u-textAlign-c',
        )}
        key={`${componentId}-actions`}
      >
        <button
          className='btn btn-primary-outline btn-sm'
          onClick={handleCancelClick}
          type='button'
        >
          {cancelLabel}
        </button>
        <button
          className={classnames('btn btn-sm', {
            'Button--destroy u-textColor-white': isActionDanger,
            'btn-primary': !isActionDanger,
          })}
          onClick={handleConfirmClick}
          type='button'
        >
          {confirmLabel}
        </button>
      </div>
    </Popover>
  </Overlay>,
];

ConfirmationPopoverButton.propTypes = {
  /**
   * Props provided by withScrollContainerRef (getScrollContainer, getTargetRef, setTargetRef)
   */
  ...withScrollContainerRef.propTypes,

  /**
   * The popover's cancel button label/content.
   */
  cancelLabel: PropTypes.string,

  /**
   * The children nodes which will be rendered inside the popover
   */
  children: PropTypes.node,

  /**
   * className to apply to the main button component.
   */
  className: PropTypes.string,

  /**
   * Unique ID for this instance of the component. Provided by withComponentId
   */
  componentId: PropTypes.string.isRequired,

  /**
   * The popover's confirmation button label/content.
   */
  confirmLabel: PropTypes.string,

  /**
   * className to apply to the popover component.
   */
  containerClassName: PropTypes.string,

  /**
   * True to disable the button
   */
  disabled: PropTypes.bool,

  /**
   * Called when the confirmation popover's "cancel" button is clicked.
   */
  handleCancelClick: PropTypes.func.isRequired,

  /**
   * Called when the main button is clicked to trigger display of the confirmation.
   */
  handleClick: PropTypes.func.isRequired,

  /**
   * Called when thc confirmation popover's "confirm" button is clicked.
   */
  handleConfirmClick: PropTypes.func.isRequired,

  /**
   * The icon that will be rendered in place of the label if supplied
   */
  iconClass: PropTypes.string,

  /**
   * Set to false to disable the confirmation popover.
   */
  isConfirmationEnabled: PropTypes.bool,

  /**
   * True to display the confirmation popover, false to hide it.
   */
  isConfirmationOpen: PropTypes.bool.isRequired,

  /**
   * The main button content/label
   */
  label: PropTypes.node,

  /**
   * Called when the popover is closed, either from the user clicking the
   * cancel button or the main button toggling the popover off.
   */
  onCancel: PropTypes.func,

  /**
   * Called when the user clicks the confirmation button in the popover
   */
  onConfirm: PropTypes.func.isRequired,

  /**
   * The position of the confirmation popover relative to the main button.
   */
  popoverPlacement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),

  /**
   * When true, the confirmation popover will be closed when the user clicks outside of the overlay
   */
  rootClose: PropTypes.bool,

  /**
   * The title for the popover confirmation
   */
  title: PropTypes.node,

  /**
   * An optional value that will be passed in the onConfirm callback (along with the event itself)
   */
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
};

ConfirmationPopoverButton.defaultProps = {
  cancelLabel: 'Cancel',
  className: 'btn btn-link btn-sm',
  confirmLabel: 'Yes',
  containerClassName: '',
  isActionDanger: false,
  isConfirmationEnabled: true,
  popoverPlacement: 'top',
  rootClose: true,
};

export default compose(
  setDisplayName('ConfirmationPopoverButton(enhanced)'),
  setPropTypes({
    isConfirmationEnabled: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    value: PropTypes.any,
  }),
  defaultProps({
    isConfirmationEnabled: true,
  }),
  withComponentId(),
  withScrollContainerRef,
  withStateHandlers(
    { isConfirmationOpen: false },
    {
      handleClick: (_, { isConfirmationEnabled, onConfirm, value }) => e => {
        if (!isConfirmationEnabled) {
          onConfirm(e, value);
          return {};
        }

        return { isConfirmationOpen: true };
      },
      handleConfirmClick: (_, { onConfirm, value }) => e => {
        onConfirm(e, value);
        return { isConfirmationOpen: false };
      },

      handleCancelClick: (_, { onCancel }) => e => {
        if (onCancel) {
          onCancel(e);
        }

        return { isConfirmationOpen: false };
      },
    },
  ),
)(ConfirmationPopoverButton);
