import invalidateActivityList from 'modules/activities/actions/invalidateActivityList';
import { PARENT_SEARCH } from 'modules/activities/constants';
import createEntity from 'modules/entities/actions/createEntity';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import routes from 'modules/routing/routes';
import { searchSchema } from 'modules/searches/schema';
import { candidacySchema, candidacyVoteSchema } from '../schemas';

/**
 * Creates an action for creating a candidacy (adding a contact to a search).
 * @param {Object} payload
 * @param {String|String[]} payload.contactId The ID of the contact to add to the search, or an
 *   array of contact IDs to create multiple candidacies at once.
 * @param {String} payload.searchId The ID of the search to add the contact to.
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({
  contactId,
  hidden,
  rejectionComment,
  rejectionReason,
  searchId,
  stageId,
  transactionId,
}) => dispatch => {
  dispatch(
    createEntity({
      transactionId: transactionId,
      contactId: contactId,
      hidden: hidden,
      searchId: searchId,
      stageId: stageId,
      entityType: candidacySchema.key,
      responseSchema: {
        candidacies: [candidacySchema],
        search: searchSchema,
        vote: candidacyVoteSchema,
      },

      url: routes.bulk_api_v1_search_candidacies({ searchId: searchId }),
      body: {
        contact_id: contactId,
        hidden: hidden,
        stage_id: stageId,
        rejection_reason: rejectionReason,
        rejection_comment: rejectionComment,
      },
    }),
  ).then(action => {
    if (action.type === entityActionTypes.CREATE_SUCCESS) {
      // Creating a new candidacy causes a search-related activity to be
      // created, so invalidate that list.
      dispatch(
        invalidateActivityList({
          parentId: searchId,
          parentType: PARENT_SEARCH,
        }),
      );

      const { searchStages } = action.payload.entities;
      const isOfferStage = Object.values(searchStages).every(
        searchStage => searchStage.offer,
      );

      if (isOfferStage) {
        window.location = `/searches/${searchId}/edit#offers`;
      }
    }
    return action;
  });
};
