import createEntityListReducer from 'modules/entities/createEntityListReducer';
import {
  TIMEZONE_LIST_FETCH_START,
  TIMEZONE_LIST_FETCH_SUCCESS,
  TIMEZONE_LIST_FETCH_FAILURE,
} from '../actions/actionTypes';
import timezoneSchema from '../schemas/timezones';

/**
 * A reducer that maintains a list of all residency IDs.
 */
export default createEntityListReducer({
  entityType: timezoneSchema.key,
  listResultKey: 'timezones',
  request: TIMEZONE_LIST_FETCH_START,
  success: TIMEZONE_LIST_FETCH_SUCCESS,
  failure: TIMEZONE_LIST_FETCH_FAILURE,
});
