import PropTypes from 'prop-types';
import React from 'react';

const DEFAULT_SIZE = 32;

/**
 * Renders a loading indicator (animated spinner)
 */
const LoadingIndicator = ({ message, size }) => (
  <div className='LoadingIndicator'>
    <img
      alt={message || 'Loading'}
      height={size}
      src='/loading.gif'
      width={size}
    />
    {message ? <div className='message'>{message}</div> : null}
  </div>
);

LoadingIndicator.defaultProps = {
  message: null,
  size: DEFAULT_SIZE,
};

LoadingIndicator.propTypes = {
  /**
   * An optional message to display below the spinner
   */
  message: PropTypes.string,

  /**
   * The size of loading indicator.
   */
  size: PropTypes.number,
};

export default LoadingIndicator;
