import { normalize } from 'normalizr';
import { MERGE } from './entityActionTypes';

/**
 * Merges in some entity data "synchronously"
 * In hindsight, we should probably have called the `xxEntity` (createEntity, etc) actions
 * something more like `submitEntityCreate` or something similar, then createEntity should
 * really just be the "success" action that gets dispatched, and this would just be called
 * `updateEntity`. Might be worth refactoring sooner rather than later.
 * @param {Object} options
 * @param {Object} options.data The data (as described by `schema`)
 * @param {Schema} options.schema A normalizr schema describing the `data`
 * @param {String} [options.type="MERGE"] The action type
 * @return {Object} An action with a payload containing the normalized data.
 */
export default ({ data, schema, type = MERGE } = {}) => ({
  type: type,
  payload: normalize(data, schema),
});
