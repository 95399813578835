import { NAME } from '../../constants';

export const SEARCHES_FETCH_START = `${NAME}/SEARCHES_FETCH_START`;
export const SEARCHES_FETCH_SUCCESS = `${NAME}/SEARCHES_FETCH_SUCCESS`;
export const SEARCHES_FETCH_FAILURE = `${NAME}/SEARCHES_FETCH_FAILURE`;
export const SEARCHES_INVALIDATE = `${NAME}/SEARCHES_INVALIDATE`;

export const SEARCH_REPORT_CREATE_START = `${NAME}/SEARCH_REPORT_CREATE_START`;
export const SEARCH_REPORT_CREATE_SUCCESS = `${NAME}/SEARCH_REPORT_CREATE_SUCCESS`;
export const SEARCH_REPORT_CREATE_FAILURE = `${NAME}/SEARCH_REPORT_CREATE_FAILURE`;
