import React from 'react';

const AddCommentButtonLabel = () => (
  <div className='AddCommentButtonLabel'>
    <i className='fa fa-plus-circle' />
    <span>Comment</span>
  </div>
);

export default AddCommentButtonLabel;
