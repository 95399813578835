import { branch, compose, lifecycle } from 'recompose';
import connectContactActions from './connectContactActions';

/**
 * @deprecated Use `withContactFetched`
 * When provided a contactID, fetches the contact if needed.
 * @param {Object} [fetchOptions] An optional array of additional fields to request.
 * @param {String[]} [fetchOptions.fields] An optional array of additional fields to request.
 */
export default fetchOptions =>
  compose(
    branch(({ contactActions }) => !contactActions, connectContactActions),
    lifecycle({
      UNSAFE_componentWillMount: function () {
        const { contactActions, contactId } = this.props;
        if (contactId) {
          contactActions.fetchContactIfNeeded({
            id: contactId,
            ...fetchOptions,
          });
        }
      },
      UNSAFE_componentWillReceiveProps: function (nextProps) {
        const { contactActions, contactId } = nextProps;
        if (contactId) {
          contactActions.fetchContactIfNeeded({
            id: contactId,
            ...fetchOptions,
          });
        }
      },
    }),
  );
