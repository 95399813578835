import getCandidacyProperty from './getCandidacyProperty';

/**
 * Gets the priority weight of a candidacy record (the total thumbs up votes minus
 * the total thumbs down votes)
 * @param {Object} state
 * @param {Number} id The candidacy ID
 * @return {Number|undefined} The candidacy's priority weight, if available.
 */
export default (state, candidacyId) =>
  getCandidacyProperty(state, candidacyId, 'priority_weight');
