import React from 'react';
import { compose, mapProps, setDisplayName, wrapDisplayName } from 'recompose';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import FormGroup from './FormGroup';
import withFocusState from './withFocusState';

/**
 * Export the constants (ERROR_DISPLAY_*, LABEL_POSITION_*) from FormGroup
 * Note: because of an ongoing issue with babel-plugin-rewire, we must export these individually
 * instead of being able to simply `export * from '/FormGroup'` :()
 */
export {
  ERROR_DISPLAY_INLINE,
  ERROR_DISPLAY_TOOLTIP,
  ERROR_DISPLAY_FOCUSED,
  ERROR_DISPLAY_BLURRED,
  LABEL_POSITION_BEFORE,
  LABEL_POSITION_AFTER,
} from './FormGroup';

/**
 * A higher-order component that wraps a component in a FormGroup.
 */
const withFormGroup = FieldComponent =>
  compose(
    setDisplayName(wrapDisplayName(FieldComponent, 'withFormGroup')),
    withComponentId('withFormGroup', 'withFormGroupComponentId'),

    // Provides `isFocused`, `wasFocused`, and `wasBlurred` by using the `onBlur` and `onFocus`
    // props passed to the FieldComponent.
    withFocusState,

    // Extract the props that we need to pass to the FormGroup, and pass the rest on to the
    // FieldComponent.
    mapProps(
      ({
        children,
        className,
        error,
        errorDisplay,
        formGrouplabelPosition,
        isFocused,
        label,
        showErrors,
        showFeedback,
        tooltipPlacement,
        warning,
        warningDisplay,
        wasBlurred,
        wasFocused,
        withFormGroupComponentId,
        ...componentProps
      }) => ({
        children: (
          <FieldComponent {...componentProps}>{children}</FieldComponent>
        ),
        className: className,
        error: error,
        errorDisplay: errorDisplay,
        fieldState: componentProps.fieldState,
        formGrouplabelPosition: formGrouplabelPosition,
        id: componentProps.id || withFormGroupComponentId,
        isFocused: isFocused,
        label: label,
        showFeedback: showFeedback,
        showErrors: showErrors,
        tooltipPlacement: tooltipPlacement,
        warning: warning,
        warningDisplay: warningDisplay,
        wasBlurred: wasBlurred,
        wasFocused: wasFocused,
      }),
    ),
  )(FormGroup);

export default withFormGroup;
