import React from 'react';
import PropTypes from 'prop-types';

const TimeLabel = ({
  className,
  shouldAddTitleAttribute = true,
  time,
  title,
}) => {
  const dateObj = time ? new Date(time) : null;

  const dateString =
    dateObj?.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }) || '';

  const dateWTimeString = dateObj?.toLocaleString() || '';

  return (
    <time
      className={className}
      dateTime={dateWTimeString}
      title={shouldAddTitleAttribute ? title || dateWTimeString : null}
    >
      {dateString}
    </time>
  );
};

export default TimeLabel;

TimeLabel.propTypes = {
  className: PropTypes.string,
  shouldAddTitleAttribute: PropTypes.bool,
  time: PropTypes.string,
  title: PropTypes.string,
};
