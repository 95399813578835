/**
 * Tests to determine if a given web storage API is available
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API»
 * @param {String} type The storage type (`localStorage`, `sessionStorage`)
 * @return {Boolean} True if the type of storage is available, otherwise false.
 */
function isStorageAvailable(type) {
  try {
    const storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

const STORAGE_TYPE = 'localStorage';
const IS_AVAILABLE = isStorageAvailable(STORAGE_TYPE);

/**
 * Loads a state object from storage at the given key.
 * @param {String} key The key in the storage object where the state should be loaded from.
 * @return {Object} The object stored in storage at the given key, or an empty object if none
 *   was found or it was unable to be parsed.
 */
export function load(key) {
  if (IS_AVAILABLE) {
    const state = window[STORAGE_TYPE].getItem(key);
    if (state) {
      try {
        return JSON.parse(state);
      } catch (e) {
        return {};
      }
    }
  }

  return {};
}

/**
 * Saves a state object to storage at the given key value.
 * @param {String} key The key within the storage object where the state should be saved.
 * @param {Object} state The state to save.
 */
export function save(key, state) {
  if (IS_AVAILABLE) {
    window[STORAGE_TYPE].setItem(key, JSON.stringify(state));
  }
}
