import url from 'modules/routing/url';
import createQueryActions from '../../../../actions/createQueryActions';
import {
  QUERY_CREATE,
  QUERY_DESTROY,
  QUERY_INVALIDATE,
  QUERY_CLEAR,
  QUERY_FETCH_START,
  QUERY_FETCH_SUCCESS,
  QUERY_FETCH_FAILURE,
} from './actionTypes';
import { contactSchema } from '../../schema';

const {
  create: createContactsQuery,
  destroy: destroyContactsQuery,
  invalidate: invalidateContactsQuery,
  query: fetchContactsQuery,
} = createQueryActions({
  getUrl: ({ queryId: _queryId, queryParams = {}, term }) =>
    url('/api/v1/autocomplete/query', {
      query: {
        resource: 'contact',
        term: term,
        ...queryParams,
      },
    }),
  schema: [contactSchema],
  create: QUERY_CREATE,
  destroy: QUERY_DESTROY,
  invalidate: QUERY_INVALIDATE,
  start: QUERY_FETCH_START,
  success: QUERY_FETCH_SUCCESS,
  failure: QUERY_FETCH_FAILURE,
  transformResponse: suggestions =>
    suggestions.map(contact => ({
      id: contact.id,
      full_name: contact.name_with_company,
      name: contact.name,
      email: contact.email,
      links: { show: `/contacts/${contact.id}` },
      primary_title: contact.position_name,
      primary_company_name: contact.company_name,
      primary_company_id: contact.company_id,
      preferred_email: contact.preferred_email,
      work_email: contact.work_email,
      recent_connection_type_id: contact.recent_connection_type_id ?? null,
      primary_company:
        contact.company_id && contact.company_name
          ? { id: contact.company_id, name: contact.company_name }
          : null,
    })),
});

/**
 * Dispatches an action for clearing the results of a contact query.
 * @param {Object} payload
 * @param {String} payload.queryId The ID of the query to clear.
 */
const clearContactsQuery = ({ queryId }) => ({
  type: QUERY_CLEAR,
  payload: { queryId: queryId },
});

export {
  createContactsQuery,
  destroyContactsQuery,
  fetchContactsQuery,
  invalidateContactsQuery,
  clearContactsQuery,
};
