import { List } from 'immutable';
import createFetchReducer from '../../../reducers/createFetchReducer';
import {
  REJECTION_REASONS_FETCH_START,
  REJECTION_REASONS_FETCH_SUCCESS,
  REJECTION_REASONS_FETCH_FAILURE,
  REJECTION_REASONS_INVALIDATE,
} from '../actions/rejectionReasons/actionTypes';

export default createFetchReducer({
  invalidate: REJECTION_REASONS_INVALIDATE,
  start: REJECTION_REASONS_FETCH_START,
  success: REJECTION_REASONS_FETCH_SUCCESS,
  failure: REJECTION_REASONS_FETCH_FAILURE,
  transformer: List,
});
