import selectn from 'selectn';
import updateEntity from 'modules/entities/actions/updateEntity';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import routes from 'modules/routing/routes';
import { invalidateCandidacyList } from 'modules/candidacies/actions';
import { PARENT_SEARCH, PARENT_PIPELINE } from 'modules/candidacies/constants';
import { targetCompanySchema } from '../schema';
import invalidateTargetCompanyList from './invalidateTargetCompanesList';

/**
 * Creates an action for updating a target company
 * @param {Object} payload
 * @param {Object} payload.targetCompany The target company properties
 * @param {Number} payload.targetCompany.id The target company ID
 */
export default ({
  targetCompany: { company, ...targetCompany },
  ...rest
}) => dispatch => {
  dispatch(
    updateEntity({
      ...rest,
      body: {
        target_company: {
          ...targetCompany,
          company_id: company,
        },
      },
      entityType: targetCompanySchema.key,
      id: targetCompany.id,
      responseSchema: { target_company: targetCompanySchema },
      url: routes.api_v1_target_company({ id: targetCompany.id }),
    }),
  ).then(action => {
    if (action.type === entityActionTypes.UPDATE_SUCCESS) {
      // When a target company is toggled active/inactive, the 'None' count changes
      // Thus, we need to invalidate the list so we can display the proper counts
      const id = selectn('payload.id', action);
      const searchId = selectn(
        `payload.entities.${targetCompanySchema.key}.${id}.search_id`,
        action,
      );
      const base =
        window.location.pathname.split('/')[1] === 'pipelines'
          ? 'People'
          : 'Candidate';
      const parentType = base === 'People' ? PARENT_PIPELINE : PARENT_SEARCH;
      dispatch(invalidateTargetCompanyList({ searchId: searchId }));
      dispatch(
        invalidateCandidacyList({ parentId: searchId, parentType: parentType }),
      );
    }

    return action;
  });
};
