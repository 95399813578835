import PropTypes from 'prop-types';
import {
  branch,
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import mapCompanyIdToCompany from './mapCompanyIdToCompany';
import wrapInCompanyLink from './wrapInCompanyLink';

/**
 * Renders the name of a company
 */
export default compose(
  setDisplayName('CompanyName'),
  setPropTypes({
    /**
     * The ID of the company whose name should be rendered.
     */
    companyId: PropTypes.number.isRequired,

    /**
     * True to wrap the output in a link to the company's page.
     */
    linkToCompany: PropTypes.bool.isRequired,
  }),
  defaultProps({
    linkToCompany: false,
  }),
  branch(({ linkToCompany }) => linkToCompany, wrapInCompanyLink),
  mapCompanyIdToCompany,
  mapProps(({ company }) => ({
    className: 'companies--company-name',
    children: company && company.get('name'),
  })),
)('span');
