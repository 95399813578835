import selectn from 'selectn';
import { Map } from 'immutable';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import createEntityListReducer from 'modules/entities/createEntityListReducer';
import isEntityType from 'modules/entities/isEntityType';
import {
  CANDIDACY_LIST_FETCH_START,
  CANDIDACY_LIST_FETCH_SUCCESS,
  CANDIDACY_LIST_FETCH_FAILURE,
  CANDIDACY_LIST_INVALIDATE,
} from '../actions/actionTypes';
import { PARENT_SEARCH, PARENT_CONTACT } from '../constants';
import { candidacySchema } from '../schemas';

const candidacyListReducer = createEntityListReducer({
  entityType: candidacySchema.key,
  listResultKey: 'candidacies',
  entityResultKey: 'candidacy',
  request: CANDIDACY_LIST_FETCH_START,
  success: CANDIDACY_LIST_FETCH_SUCCESS,
  failure: CANDIDACY_LIST_FETCH_FAILURE,
  invalidated: CANDIDACY_LIST_INVALIDATE,
});

/* Maintains lists of candidacies, first keyed by association ("search" indicating a list of
 * of candidacies that belong to a search, or "contact" indicating the list of candidacies
 * that a contact belongs to).
 */
export default (state = new Map(), action) => {
  switch (action.type) {
    case CANDIDACY_LIST_INVALIDATE:
    case CANDIDACY_LIST_FETCH_START:
    case CANDIDACY_LIST_FETCH_SUCCESS:
    case CANDIDACY_LIST_FETCH_FAILURE: {
      const type = selectn('payload.parentType', action);
      const id = selectn('payload.parentId', action);

      if (id && type) {
        return state.updateIn([type, id], list =>
          candidacyListReducer(list, action),
        );
      }

      return state;
    }
    case entityActionTypes.CREATE_SUCCESS: {
      // When a candidacy is created and we have an associated list
      // fetched, add that record to the appropriate lists.
      if (isEntityType(action, candidacySchema.key)) {
        const candidacyIds = selectn('payload.result.candidacies', action);
        const candidacyEntities = candidacyIds.map(id =>
          selectn(`payload.entities.${candidacySchema.key}.${id}`, action),
        );

        return state.withMutations(map => {
          candidacyEntities.forEach(candidacyEntity => {
            const candidacyId = selectn('id', candidacyEntity);
            const contactId = selectn('contact', candidacyEntity);
            const searchId = selectn('search', candidacyEntity);

            if (contactId && map.hasIn([PARENT_CONTACT, contactId, 'ids'])) {
              map.updateIn([PARENT_CONTACT, contactId, 'ids'], ids =>
                ids.concat(candidacyId).toOrderedSet().toList(),
              );
            }

            if (searchId && map.hasIn([PARENT_SEARCH, searchId, 'ids'])) {
              map.updateIn([PARENT_SEARCH, searchId, 'ids'], ids =>
                ids.concat(candidacyId).toOrderedSet().toList(),
              );
            }
          });
        });
      }

      return state;
    }
    default: {
      return state.map(parentTypes =>
        parentTypes.map(listState => candidacyListReducer(listState, action)),
      );
    }
  }
};
