import { withPropsOnChange } from 'recompose';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';

/**
 * A higher-order component that generates a unique component id and passes it down as a
 * `componentId` prop. It can optionally take a component name which will be prefixed
 * to the unique ID.
 * @param {String} [componentName] an optional name to prefix the ID with.
 * @param {String} [propName='componentId'] the name of the property to assign the ID to.
 * @returns {HigherOrderComponent} A higher order component with a `componentId` prop.
 *
 * Note: Using withPropsOnChange ensures that each time this component is created it gets a new
 * unique componentId. `shouldMapOrKeys` always returns false so that a we never generate
 * a new ID after the initial prop is created. Using `withProps` here won't work because
 * every instance of the component will share the same componentId, which kind of defeats the
 * entire purpose of this HOC.
 */
export default (componentName, propName = 'componentId') =>
  withPropsOnChange(
    () => false,
    () => ({
      [propName]: uniqueId(componentName),
    }),
  );
