import { Record } from 'immutable';
import selectn from 'selectn';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import FieldState from './FieldState';

/**
 * Represents the state of a form.
 *
 * This always gets wrapped in a `FormState` object, and should never be mutated directly or
 * instantiated directly.
 * @type {Record}
 * @private
 */
const FormStateRecord = new Record({
  /**
   * True if the form can be submitted even if some of its fields have errors
   * @type {Boolean}
   */
  canSubmitWithErrors: false,

  /**
   * Any error that occured from the last submission of the form.
   * @type {Error}
   */
  error: null,

  /**
   * The root field that this form manages.
   * This must be a single instance of a FieldState. However, that may further contain
   * nested FieldState objects.
   * @type {FieldState}
   */
  field: null,

  /**
   * A unique ID identifying a particular FormState. This is automatically
   * generated when calling `FormState.create`, and retains the same value
   * across mutations. This is mostly useful for when a new `FormState` is
   * created without recreating an underlying form. It allows us to know when
   * a form has been "reset".
   *
   * The current usecase for this is when we have a Form that, after submitting,
   * clears itself out so that another "thing" can be created with it. However,
   * the underlying fields may have some state associated with them that should
   * also be reset (whether a field was "touched" ,for example - i.e.
   * `withFocusState`). The FormState's `id` value can be used as a "key" when
   * rendering the underlying component, so when the FormState is recreated and
   * the ID is changed, we can force the underlying components to be recreated.
   */
  id: null,

  /**
   * A unique ID identifying a current submission that is in progress. This should be falsey
   * when there is no submission in progress.
   * @type {String}
   */
  submissionId: null,

  /**
   * True if the form has ever been submitted (or attempted to be submitted), regardless of
   * whether the submission was successful.
   * @type {Boolean}
   */
  wasSubmitted: false,
});

/**
 * The main FormState class used for interacting with a `FormStateRecord`.
 * The FormState contains a `FormStateRecord` internally, which should never be mutated or
 * accessed directly.
 */
class FormState {
  /**
   * Creates a new `FormState` instance.
   * @param {FieldState} field The root field instance for the form.
   */
  static create(field, { canSubmitWithErrors = false } = {}) {
    if (!field || !(field instanceof FieldState)) {
      throw new Error(
        'FormState.create must be called with a single FieldState instance!',
      );
    }

    return new FormState(
      new FormStateRecord({
        field: field,
        canSubmitWithErrors: canSubmitWithErrors,
        id: uniqueId(),
      }),
    );
  }

  /**
   * FormState objects should be instantiated with `FormState.create()`
   * @private
   */
  constructor(immutable) {
    if (!(immutable instanceof FormStateRecord)) {
      throw new Error(
        'FormState must be instantiated with a FormStateRecord instance!',
      );
    }

    this._immutable = immutable;
  }

  /**
   * Gets the underlying immutable object for this class.
   * @private
   * @returns {FieldState}
   */
  getImmutable() {
    return this._immutable;
  }

  /**
   * Updates the state to indicate a submission is in progress.
   * @param {*} submissionId A value identifying the current submission. Can be any non-falsey
   *   value, and should ideally be unique to a single submission request.
   * @returns {FormState} A new FormState reflecting the updated state
   */
  startSubmit(submissionId) {
    return new FormState(
      this.getImmutable().merge({
        submissionId: submissionId,
        wasSubmitted: true,
      }),
    );
  }

  /**
   * Updates the state to indicate a submission is no longer in progress
   * @param {*} [error] An optional error, if the previous submission completed with an error.
   * @param {boolean} [inlineSubmitErrors] An optional value indicating whether to pass keyed
   * errors down into the fieldStates for inline display.  If so, a generic error will display
   * as the base message
   * @returns {FormState} A new FormState reflecting the updated state
   */
  endSubmit(error, inlineSubmitErrors) {
    let field = this.getFieldState();
    const inline = inlineSubmitErrors && selectn('body.errors', error);
    if (inline) {
      field = field.setChildErrors(inline);
    }

    return new FormState(
      this.getImmutable().merge({
        field: field,
        submissionId: false,
        wasSubmitted: true,
        error: inline ? new Error('Unable to complete request.') : error,
      }),
    );
  }

  getFormStateId() {
    return this.getImmutable().get('id');
  }

  getSubmissionId() {
    return this.getImmutable().get('submissionId');
  }

  /**
   * Gets a value indicating whether the form is current in the process of being submitted.
   * @returns {Boolean}
   */
  isSubmitting() {
    return Boolean(this.getImmutable().get('submissionId'));
  }

  /**
   * Gets a value indicating whether the form is currently in a state that should allow it to
   * be submitted.
   * @returns {Boolean}
   */
  canSubmit() {
    return (
      (this.getImmutable().get('canSubmitWithErrors') ||
        !this.getImmutable().get('field').hasErrors()) &&
      !this.getImmutable().get('submissionId')
    );
  }

  /**
   * Gets the underlying field state that represents the values of the form.
   * @returns {FieldState}
   */
  getFieldState() {
    return this.getImmutable().get('field');
  }

  /**
   * Updates the underlying field state which represents the values of the form.
   * @returns {FormState} A new FormState reflecting the updated state
   */
  setFieldState(fieldState) {
    return new FormState(this.getImmutable().set('field', fieldState));
  }

  /**
   * Gets the value of the root field for this form.
   * @returns {*} the field values.
   */
  getFieldValue() {
    return this.getImmutable().get('field').getRawValue();
  }

  /**
   * Gets a value indicating whether any of the field values currently has an error state.
   * @returns {Boolean}
   */
  hasFieldErrors() {
    return this.getImmutable().get('field').hasErrors();
  }

  /**
   * Gets any overall form error. This is typically an error message returned by the server
   * when a submission fails.
   * @returns {*}
   */
  getError() {
    return this.getImmutable().get('error');
  }

  /**
   * Gets a value indicating if there has ever been an attempt to submit this form.
   * @returns {Boolean}
   */
  wasSubmitted() {
    return this.getImmutable().get('wasSubmitted');
  }
}

export default FormState;
