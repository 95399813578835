import createQueryReducer from '../createQueryReducer';
import {
  CONTACT_DUPLICATE_QUERY_CREATE,
  CONTACT_DUPLICATE_QUERY_DESTROY,
  CONTACT_DUPLICATE_QUERY_FETCH_START,
  CONTACT_DUPLICATE_QUERY_FETCH_SUCCESS,
  CONTACT_DUPLICATE_QUERY_FETCH_FAILURE,
  CONTACT_DUPLICATE_QUERY_INVALIDATE,
} from '../../actions/contacts/constants';

export default createQueryReducer({
  create: CONTACT_DUPLICATE_QUERY_CREATE,
  destroy: CONTACT_DUPLICATE_QUERY_DESTROY,
  invalidate: CONTACT_DUPLICATE_QUERY_INVALIDATE,
  start: CONTACT_DUPLICATE_QUERY_FETCH_START,
  success: CONTACT_DUPLICATE_QUERY_FETCH_SUCCESS,
  failure: CONTACT_DUPLICATE_QUERY_FETCH_FAILURE,
});
