import { normalize } from 'normalizr';

/**
 * A higher-order function to be used with `fetchAction` that normalizes the response
 * recieved using a `responseSchema` options provided when the action is created.
 *
 * @example
 * ```js
 * const fetchWidgetsAction = compose(
 *  fetchAction,
 *  withResponseSchema,
 *  createAsyncActions({
 *    startActionType: 'FETCH_START',
 *    successActionType: 'FETCH_START',
 *    failureActionType: 'FETCH_FAILURE',
 *   })
 * );
 *
 * const myEntitySchema = new schema.Entity('widgets');
 * dispatch(fetchWidgetsAction({ responseSchema: { widgets: [widgetSchema] } }));
 *
 * // Where the server response might look like:
 * // {
 * //   widgets: [
 * //     { id: 1, name: 'Alpha Widget', color: 'blue' },
 * //     { id: 2, name: 'Beta Widget', color: 'red' },
 * //   ]
 * // }
 * ```
 */
export default ({ responseSchema, successAction, ...options }) => ({
  successAction: ({ response, ...payload }) =>
    successAction({
      ...payload,
      ...normalize(response, responseSchema),
    }),
  ...options,
});
