import { normalize } from 'normalizr';

/**
 * A higher-order function to be used with `fetchAction` that normalizes the response
 * using a predefined schema.
 * @param {Schema} responseSchema A normalizr schema.
 *
 * @example
 * ```js
 * const myEntitySchema = new schema.Entity('widgets');

 * const fetchWidgetsAction = compose(
 *  fetchAction,
 *  normalizeResponse({ widgest: [myEntitySchema] }),
 *  createAsyncActions({
 *    startActionType: 'FETCH_START',
 *    successActionType: 'FETCH_START',
 *    failureActionType: 'FETCH_FAILURE',
 *   })
 * );
 *
 * dispatch(fetchWidgetsAction());
 *
 * // Where the server response might look like:
 * // {
 * //   widgets: [
 * //     { id: 1, name: 'Alpha Widget', color: 'blue' },
 * //     { id: 2, name: 'Beta Widget', color: 'red' },
 * //   ]
 * // }
 * ```
 */
export default responseSchema => ({ successAction, ...options }) => ({
  successAction: ({ response, ...payload }) =>
    successAction({
      ...payload,
      ...normalize(response, responseSchema),
    }),
  ...options,
});
