import { parseQueryString } from 'modules/core/urlUtils';
import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'connected-react-router/immutable';
import { NAME } from './constants';

const INITIAL_STATE = fromJS({
  location: {
    pathname: '',
    search: '',
    hash: '',
    query: {},
    state: null,
  },
});

/**
 * Maintains the current router location..
 */
export default function routing(state = INITIAL_STATE, { payload, type }) {
  switch (type) {
    case LOCATION_CHANGE: {
      // This is used by react-redux-router to sync the history and router
      // state to our store. So this shouldn't be changed without first
      // looking into that.
      return state.merge({
        location: {
          query: payload.search ? parseQueryString(payload.search) : {},
          ...payload,
        },
      });
    }
    default: {
      return state;
    }
  }
}

routing.NAME = NAME;
