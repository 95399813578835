import selectn from 'selectn';
import { List, Map } from 'immutable';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import createEntityListReducer from 'modules/entities/createEntityListReducer';
import {
  REVENUE_LIST_FETCH_START,
  REVENUE_LIST_FETCH_SUCCESS,
  REVENUE_LIST_FETCH_FAILURE,
  REVENUE_LIST_UPDATE_SUCCESS,
} from '../actions/actionTypes';
import revenueSchema from '../schema';

const revenueListReducer = createEntityListReducer({
  entityType: revenueSchema.key,
  listResultKey: 'revenues',
  entityResultKey: 'revenue',
  request: REVENUE_LIST_FETCH_START,
  success: REVENUE_LIST_FETCH_SUCCESS,
  failure: REVENUE_LIST_FETCH_FAILURE,
});

/**
 * A collection of revenueList's keyed on company IDs
 */
export default (state = new Map(), action) => {
  switch (action.type) {
    case REVENUE_LIST_FETCH_START:
    case REVENUE_LIST_FETCH_SUCCESS:
    case REVENUE_LIST_FETCH_FAILURE: {
      const companyId = selectn('payload.companyId', action);
      if (companyId) {
        return state.update(companyId, list =>
          revenueListReducer(list, action),
        );
      }

      return state;
    }
    case REVENUE_LIST_UPDATE_SUCCESS: {
      const companyId = selectn('payload.companyId', action);
      if (companyId) {
        return state.setIn(
          [companyId, 'ids'],
          List(selectn('payload.result.revenues', action)),
        );
      }

      return state;
    }
    case entityActionTypes.CREATE_SUCCESS: {
      const revenueId = selectn('payload.result.revenue', action);
      const companyId = selectn(
        `payload.entities.${revenueSchema.key}.${revenueId}.company`,
        action,
      );
      if (companyId && state.hasIn([companyId, 'ids'])) {
        return state.updateIn([companyId, 'ids'], ids =>
          ids.unshift(revenueId),
        );
      }

      return state;
    }
    default: {
      return state.map(list => revenueListReducer(list, action));
    }
  }
};
