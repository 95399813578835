import { withStateHandlers } from 'recompose';
import FormState from '../FormState';

/**
 * A higher order component that creates a formState property from a fieldStateCreator.
 * It supplies the following props:
 * * formState The current formState value.
 * * onFormStateChange Called when the formState should be updated.
 * * onFieldStateChange Called when the fieldState of the formState should be updated changed.
 * * onResetFormState When called, resets the form state to the initial form state.
 *
 * @param fieldStateCreator A function that is called with the current props and should return a
 *   FieldState value. The second parameter indicates if this is a call to "Reset" the field state:
 *   `fieldStateCreator(props, isResetting);`
 *
 * @example
 * ```js
 * import MyField from 'MyField';
 *
 * const fieldStateCreator = (props) => FieldState.create('foo', props.initialValue || 'bar');
 * const MyComponent = ({ formState, onFieldStateChange, onResetFormState }) => (
 *   <Form formState={formState} onSubmit={handleSubmit} >
 *    <MyField fieldState={formState.getFieldState()} onChange={onFieldStateChange} />
 *   </Form>
 * );
 *
 * const MyForm = withFormState((value) => fieldStateCreator(value))(MyComponent);
 *
 * // fieldStateCreate will get called with the props passed to the form:
 * render = () => (
 *   <MyForm initialValue='baz' />
 * );
 * ```
 */
export default (fieldStateCreator, formStateOptions) =>
  withStateHandlers(
    props => ({
      formState:
        props.initialFormState ||
        FormState.create(fieldStateCreator(props), formStateOptions),
    }),
    {
      onFormStateChange: () => formState => ({ formState: formState }),
      onFieldStateChange: ({ formState, onFieldStateChange }) => fieldState => {
        if (onFieldStateChange) {
          onFieldStateChange(fieldState);
        }
        return { formState: formState.setFieldState(fieldState) };
      },
      onResetFormState: (_, props) => () => ({
        formState: FormState.create(
          fieldStateCreator(props, true),
          formStateOptions,
        ),
      }),
    },
  );
