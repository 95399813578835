import routes from 'modules/routing/routes';
import createCrudActions from '../../../../actions/createCrudActions';
import {
  EDUCATIONS_FETCH_START,
  EDUCATIONS_FETCH_SUCCESS,
  EDUCATIONS_FETCH_FAILURE,
  EDUCATION_CREATE_START,
  EDUCATION_CREATE_SUCCESS,
  EDUCATION_CREATE_FAILURE,
  EDUCATION_DELETE_START,
  EDUCATION_DELETE_SUCCESS,
  EDUCATION_DELETE_FAILURE,
} from './actionTypes';
import shouldFetchEducations from '../../selectors/educations/shouldFetchEducations';
import { educationSchema } from '../../schema';

import updateEducation from './updateEducation';

/**
 * Gets the URL that represnts a collection of education record for a particular contact.
 * Either a contactId or an education record with a contact_id property must be provided.
 * @param {Object} params
 * @param {Number} [params.contactId] ID of the contact that the collection belongs to
 * @param {Object} [params.education] An education record.
 * @param {Number} [params.education.contact_id] ID of the contact that the collection belongs to
 */
const getCollectionUrl = ({ contactId, education }) => {
  if (education) {
    // This is a POST (create)
    if (education.contact_id) {
      return routes.api_v1_contact_educations({
        contactId: education.contact_id,
      });
    }
  }

  if (contactId) {
    return routes.api_v1_contact_educations({ contactId: contactId });
  }

  throw new Error('Education collection requires a contactId');
};

/**
 * Gets the URL representing a specific education record.
 * Either an ID or an education record with an ID must be provided.
 * @param {Object} params
 * @param {Number} [params.id] The ID of the education record
 * @param {Object} [params.education] The education record
 * @param {Number} [params.education.id] The ID of the education record
 */
const getEntityUrl = ({ education, id }) =>
  routes.api_v1_education({ id: id || education.id });

const {
  create: createEducation,
  delete: deleteEducation,
  fetchAll: fetchEducations,
} = createCrudActions({
  getUrl: getEntityUrl,
  schema: { education: educationSchema },
  fetchAll: {
    getUrl: getCollectionUrl,
    schema: { educations: [educationSchema] },
    start: EDUCATIONS_FETCH_START,
    success: EDUCATIONS_FETCH_SUCCESS,
    failure: EDUCATIONS_FETCH_FAILURE,
  },
  create: {
    getUrl: getCollectionUrl,
    start: EDUCATION_CREATE_START,
    success: EDUCATION_CREATE_SUCCESS,
    failure: EDUCATION_CREATE_FAILURE,
  },
  delete: {
    schema: null,
    start: EDUCATION_DELETE_START,
    success: EDUCATION_DELETE_SUCCESS,
    failure: EDUCATION_DELETE_FAILURE,
  },
});

/**
 * Creates an action that will fetch the educations for a contact only if they are needed
 * from the server.
 * @param {Object} params
 * @param {Number} params.contactId The ID of the contact whose educations should be fetched
 * @returns {Function} A thunk for potentially dispatching an action.
 */
export const fetchEducationsIfNeeded = params => (dispatch, getState) => {
  const { contactId } = params;

  if (!contactId) {
    throw new Error('fetchEducationsIfNeeded requires a `contactId` value');
  }

  if (shouldFetchEducations(getState(), contactId)) {
    dispatch(fetchEducations(params));
  }
};

export { fetchEducations, createEducation, updateEducation, deleteEducation };
