import { NAME } from '../constants';

export const TARGET_COMPANY_LIST_FETCH_START = `${NAME}/TARGET_COMPANY_LIST_FETCH_START`;
export const TARGET_COMPANY_LIST_FETCH_SUCCESS = `${NAME}/TARGET_COMPANY_LIST_FETCH_SUCCESS`;
export const TARGET_COMPANY_LIST_FETCH_FAILURE = `${NAME}/TARGET_COMPANY_LIST_FETCH_FAILURE`;

export const TARGET_COMPANY_VOTE_START = `${NAME}/VOTE_START`;
export const TARGET_COMPANY_VOTE_SUCCESS = `${NAME}/VOTE_SUCCESS`;
export const TARGET_COMPANY_VOTE_FAILURE = `${NAME}/VOTE_FAILURE`;

export const TARGET_COMPANY_COMMENT_LIST_FETCH_START = `${NAME}/COMMENT_LIST_FETCH_START`;
export const TARGET_COMPANY_COMMENT_LIST_FETCH_SUCCESS = `${NAME}/COMMENT_LIST_FETCH_SUCCESS`;
export const TARGET_COMPANY_COMMENT_LIST_FETCH_FAILURE = `${NAME}/COMMENT_LIST_FETCH_FAILURE`;

export const TARGET_COMPANY_LIST_INVALIDATE = `${NAME}/TARGET_COMPANY_LIST_INVALIDATE`;
