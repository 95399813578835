import { NAME } from '../../constants';

export const RATINGS_FETCH_START = `${NAME}/RATINGS_FETCH_START`;
export const RATINGS_FETCH_SUCCESS = `${NAME}/RATINGS_FETCH_SUCCESS`;
export const RATINGS_FETCH_FAILURE = `${NAME}/RATINGS_FETCH_FAILURE`;
export const RATINGS_INVALIDATE = `${NAME}/RATINGS_INVALIDATE`;

export const RATING_CREATE_START = `${NAME}/RATING_CREATE_START`;
export const RATING_CREATE_SUCCESS = `${NAME}/RATING_CREATE_SUCCESS`;
export const RATING_CREATE_FAILURE = `${NAME}/RATING_CREATE_FAILURE`;

export const RATING_UPDATE_START = `${NAME}/RATING_UPDATE_START`;
export const RATING_UPDATE_SUCCESS = `${NAME}/RATING_UPDATE_SUCCESS`;
export const RATING_UPDATE_FAILURE = `${NAME}/RATING_UPDATE_FAILURE`;

export const RATING_DELETE_START = `${NAME}/RATING_DELETE_START`;
export const RATING_DELETE_SUCCESS = `${NAME}/RATING_DELETE_SUCCESS`;
export const RATING_DELETE_FAILURE = `${NAME}/RATING_DELETE_FAILURE`;

export const RATING_DEFINITIONS_LIST_FETCH_START = `${NAME}/RATING_DEFINITIONS_LIST_FETCH_START`;
export const RATING_DEFINITIONS_LIST_FETCH_SUCCESS = `${NAME}/RATING_DEFINITIONS_LIST_FETCH_SUCCESS`;
export const RATING_DEFINITIONS_LIST_FETCH_FAILURE = `${NAME}/RATING_DEFINITIONS_LIST_FETCH_FAILURE`;
