import getEntity from 'modules/entities/selectors/getEntity';
import { educationSchema } from '../../schema';

/**
 * Gets an education record by it's ID
 * @param {*} state
 * @param {Number} educationId The ID of the education record
 * @returns {?Immutable.Map} The education record, if found.
 */
export default (state, educationId) =>
  getEntity(state, educationSchema.key, educationId);
