import startTransaction from 'modules/transactions/actions/startTransaction';
import finishTransaction from 'modules/transactions/actions/finishTransaction';
import fetch from './fetch';
import { GET } from './methods';
import { APPLICATION_JSON } from './mimeTypes';

/**
 * Creates a fetch action for dispatching to a redux store.
 * @param {String} url The URL to make the fetch request for
 * @param {String} [transactionId] An optional transaction ID. When provided will cause
 *   `startTransaction` and `finishTransaction` actions to be dispatched.
 * @param {*} [body]
 * @param {String} [contentType='application/json'] The "Content-Type" header to set
 * @param {String} [method='GET'] The request method (GET, POST, PUT, etc)
 * @param {Object} [fetchOptions] Additional options to passthrough to the fetch() call.
 * @param {Function} startAction The action creator called to create the action to be dispatched
 *   when the request is started
 * @param {Function} successAction The action creator called to create the action to be dispatched
 *   on a successful response
 * @param {Function} failureAction The action creator called to create the action to be dispatched
 *   on a failed response
 */
const fetchAction = ({
  // Fetch params
  body,
  contentType = APPLICATION_JSON,
  method = GET,
  // Any other fetch-specific options.
  fetchOptions,
  startAction,
  successAction,
  failureAction,
  ...payload
}) => dispatch => {
  const { transactionId, url } = payload;

  dispatch(startAction(payload));

  if (transactionId) {
    dispatch(startTransaction(transactionId, payload));
  }

  return fetch(url, {
    // If we're submitting a contentType of application/json, make sure we submit some actual
    // JSON.
    body:
      body && contentType === APPLICATION_JSON && typeof body !== 'string'
        ? JSON.stringify(body)
        : body,
    contentType: contentType,
    method: method,
    ...fetchOptions,
  })
    .then(response => successAction({ ...payload, response: response }))
    .catch(error => failureAction({ ...payload, error: error }))
    .then(dispatch)
    .then(action => {
      if (transactionId) {
        dispatch(finishTransaction(transactionId, action.payload));
      }

      return action;
    });
};

export default fetchAction;
