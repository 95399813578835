/* eslint-disable import/no-cycle */
// ^ This component has an intentional circular dependency with CommentListItem
//   to accomodate nested comment lists.
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import {
  branch,
  compose,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import CommentListItem from './CommentListItem';
import withCommentIdsSorted from './withCommentIdsSorted';

/**
 * Renders a list of comments. If `readOnly` is set to true, this property
 * is passed through to the individual comment item which allows for inline
 * editing/deleting of the comments.
 */
const CommentList = ({ className, commentIds, ...props }) => (
  <ul className={classnames('CommentList', className)}>
    {commentIds
      .map(commentId => (
        <CommentListItem {...props} commentId={commentId} key={commentId} />
      ))
      .toArray()}
  </ul>
);

CommentList.propTypes = {
  className: PropTypes.string,

  /**
   * The list of comment IDs to render.
   */
  commentIds: ImmutablePropTypes.listOf(PropTypes.number).isRequired,
};

CommentList.defaultProps = {
  className: 'list-generic',
};

export default compose(
  setDisplayName('CommentList(enhanced)'),
  setPropTypes({
    /**
     * The list of comment IDs to render.
     */
    commentIds: ImmutablePropTypes.listOf(PropTypes.number),
  }),
  withCommentIdsSorted,
  branch(
    ({ commentIds }) => !(commentIds && commentIds.size > 0),
    renderNothing,
  ),
)(CommentList);
