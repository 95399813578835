import * as stringUtils from '@thrivetrm/ui/utilities/stringUtils';
import getCandidacyContact from './getCandidacyContact';

/**
 * Gets the sortable company name for candidacy record.
 * @param {Object} state
 * @param {Number} id The candidacy ID
 * @return {String|undefined} The company name of a candidate as a sortable string
 */
const getCandidacySortCompanyName = (state, candidacyId) => {
  const contact = getCandidacyContact(state, candidacyId);
  return (
    contact && stringUtils.toSortableString(contact.get('primary_company_name'))
  );
};

export default getCandidacySortCompanyName;
