import apiV1 from './index';

const generateRecommendedReferences = apiV1.injectEndpoints({
  endpoints: builder => ({
    addImage: builder.mutation({
      query: ({ payload }) => {
        const bodyFormData = new FormData();
        bodyFormData.append('email_signature[image]', payload);
        return {
          url: `email_signatures`,
          body: bodyFormData,
          method: 'POST',
          headers: {
            'Custom-Header': 'value',
          },
          formData: true,
        };
      },
    }),
    editImage: builder.mutation({
      query: ({ contactId, payload }) => {
        const bodyFormData = new FormData();
        bodyFormData.append('email_signature[image]', payload);
        return {
          url: `email_signatures/${contactId}`,
          body: bodyFormData,
          method: 'PATCH',
          headers: {
            'Custom-Header': 'value',
          },
          formData: true,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddImageMutation,
  useEditImageMutation,
} = generateRecommendedReferences;
