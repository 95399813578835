import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import existingCandidaciesCounts from './existingCandidaciesCounts';
import queries from './queries';
import searchesLists from './searchesLists';

const searchesReducer = combineImmutableReducers({
  existingCandidaciesCounts: existingCandidaciesCounts,
  queries: queries,
  searchesLists: searchesLists,
});

searchesReducer.NAME = NAME;

export default searchesReducer;
