//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'references';

//---------------------------------------------------------
// Parent types
//---------------------------------------------------------

export const PARENT_CANDIDACY = 'candidacy';
export const PARENT_CONTACT = 'contact';

export const PARENT_TYPES = [PARENT_CANDIDACY, PARENT_CONTACT];
