import getSearchLeadRecruiterSortName from 'modules/searches/selectors/getSearchLeadRecruiterSortName';
import getCandidacySearchId from './getCandidacySearchId';

/**
 * Gets the search lead of a candidacy record.
 * @param {Object} state
 * @param {Number} id The candidacy ID
 * @return {?String} The search lead name
 */
const getCandidacySearchLead = (state, candidacyId) => {
  const searchId = getCandidacySearchId(state, candidacyId);
  return searchId && getSearchLeadRecruiterSortName(state, searchId);
};

export default getCandidacySearchLead;
