import routes from 'modules/routing/routes';
import url from 'modules/routing/url';
import createCrudActions from '../../../../actions/createCrudActions';
import {
  CONTACT_FETCH_START,
  CONTACT_FETCH_SUCCESS,
  CONTACT_FETCH_FAILURE,
  CONTACT_INVALIDATE,
  CONTACT_CREATE_START,
  CONTACT_CREATE_SUCCESS,
  CONTACT_CREATE_FAILURE,
  CONTACT_UPDATE_START,
  CONTACT_UPDATE_SUCCESS,
  CONTACT_UPDATE_FAILURE,
} from './actionTypes';
import shouldFetchContact from '../../selectors/contacts/shouldFetchContact';
import { contactSchema } from '../../schema';
import initializeContact from './initializeContact';

const {
  create: createContact,
  fetch: fetchContact,
  invalidate: invalidateContact,
  update: updateContact,
} = createCrudActions({
  getUrl: ({ fields, id }) =>
    url(routes.api_v1_contact({ id: id }), { fields: fields }),
  schema: { contact: contactSchema },
  fetch: {
    start: CONTACT_FETCH_START,
    success: CONTACT_FETCH_SUCCESS,
    failure: CONTACT_FETCH_FAILURE,
  },
  invalidate: CONTACT_INVALIDATE,
  create: {
    getUrl: routes.api_v1_contacts,
    start: CONTACT_CREATE_START,
    success: CONTACT_CREATE_SUCCESS,
    failure: CONTACT_CREATE_FAILURE,
  },
  update: {
    start: CONTACT_UPDATE_START,
    success: CONTACT_UPDATE_SUCCESS,
    failure: CONTACT_UPDATE_FAILURE,
  },
});

/**
 * Fetches a contact record from the server if needed based on the current
 * state and some additional options.
 * @param {Object} params
 * @param {Number} params.id The ID of the contact to possibly fetch.
 * @param {Object} [params.fields] An optional list of fields needed
 * @param {Boolean} [params.requireFull] If the "full" contact record is required.
 *   Some fields are only fetched by the CONTACT_FETCH_* options, and if those
 *   are needed, this parameter should be set to true. For example, fetching a
 *   candidacy does not include a contact's `career_highlights`, `employee_compenstaion`
 *   and similar fields, so setting this to true will ensure those are fetched.
 */
export const fetchContactIfNeeded = params => (dispatch, getState) => {
  const { id, ...options } = params;

  if (!id) {
    throw new Error('fetchContactIfNeeded requires an `id` value');
  }

  if (shouldFetchContact(getState(), id, options)) {
    dispatch(fetchContact(params));
  }
};

export {
  createContact,
  fetchContact,
  initializeContact,
  invalidateContact,
  updateContact,
};

export {
  createContactsQuery,
  destroyContactsQuery,
  fetchContactsQuery,
  invalidateContactsQuery,
  clearContactsQuery,
} from './query';
