import { schema } from 'normalizr';
import companySchema from 'modules/companies/companySchema';

const revenuesSchema = new schema.Entity('revenues', {
  company: companySchema,
});

companySchema.define({
  revenues: [revenuesSchema],
});

export default revenuesSchema;
