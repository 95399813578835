import selectn from 'selectn';
import { Map } from 'immutable';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import createListReducer from '../../../reducers/createListReducer';
import {
  ADDRESSES_FETCH_START,
  ADDRESSES_FETCH_SUCCESS,
  ADDRESSES_FETCH_FAILURE,
} from '../actions/actionTypes';
import addressSchema from '../addressSchema';

const addressListReducer = createListReducer({
  resultKey: 'addresses',
  entityResultKey: 'address',
  request: ADDRESSES_FETCH_START,
  success: ADDRESSES_FETCH_SUCCESS,
  failure: ADDRESSES_FETCH_FAILURE,
  created: entityActionTypes.CREATE_SUCCESS,
  deleted: entityActionTypes.DELETE_SUCCESS,
});

/**
 * The addressLists reducer maintains address lists -- it is keyed on the owner type,
 * then by owner Id
 *
 * @example
 * ```js
 * {
 *   Company: { // owner type OWNER_COMPANY
 *     123: { // The list of documents for company with ID 123
 *       _meta: {...}.
 *       ids: [2, 5, 7, 9],  // The IDs of the addresses that belong to company 123
 *     }
 *   },
 *   Contact: { // owner type OWNER_CONTACT
 *     789: { // The list of addresses for contact with ID 789
 *       _meta: {...}.
 *       ids: [23, 42],  // The IDs of the addresses that belong to contact 789
 *     }
 *   }
 * }
 * ```
 */
export default (state = new Map(), action) => {
  switch (action.type) {
    case ADDRESSES_FETCH_START:
    case ADDRESSES_FETCH_SUCCESS:
    case ADDRESSES_FETCH_FAILURE: {
      // When a list fetch action is seen, reduce only the part of the state
      // that belongs to the specific parent
      const parentType = selectn('payload.parentType', action);
      const parentId = selectn('payload.parentId', action);
      if (parentType && parentId) {
        return state.updateIn([parentType, parentId], listState =>
          addressListReducer(listState, action),
        );
      }

      return state;
    }
    case entityActionTypes.CREATE_SUCCESS: {
      if (selectn('payload.entityType', action) !== addressSchema.key) {
        return state;
      }

      // For create actions, we need to see if the new address should be added to
      // any lists which we currently have fetched based on the address's owner
      const addressId = selectn('payload.result.address', action);
      const address = selectn(
        `payload.entities.${addressSchema.key}.${addressId}`,
        action,
      );
      if (
        address &&
        state.hasIn([address.addressable_type, address.addressable_id, 'ids'])
      ) {
        return state.updateIn(
          [address.addressable_type, address.addressable_id, 'ids'],
          ids => ids.push(addressId),
        );
      }

      return state;
    }
    case entityActionTypes.DELETE_SUCCESS: {
      // For deletes we can reduce ALL lists, because it should be removed from any
      // list regardless of the owner (in practice it will only belong to one list,
      // but we don't necessarily know which one based on the action alone).
      return state.map(parentTypeState =>
        parentTypeState.map(listState => addressListReducer(listState, action)),
      );
    }
    default: {
      return state;
    }
  }
};
