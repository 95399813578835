import getCandidacyList from './getCandidacyList';

/**
 * Determines if the candidacy list for a parent should be fetched from the server.
 * @param {Object} state
 * @param {Number} parentType One of PARENT_SEARCH or PARENT_CONTACT - the list type to check
 *   (either the list of candidacies of a search, or the list of candidacies belonging to a contact)
 * @param {Number} parentId The search or contact ID, depending on parentType.
 * @return {Boolean} True if the candidacy list needs to be fetched from the server;
 *   Otherwise, false.
 */
const shouldFetchCandidacyList = (state, parentType, parentId) => {
  if (!parentType || !parentId) {
    return false;
  }

  const candidacies = getCandidacyList(state, parentType, parentId);

  if (!candidacies) {
    return true;
  }

  if (
    candidacies.getIn(['_meta', 'isFetching']) ||
    candidacies.hasIn(['_meta', 'error'])
  ) {
    return false;
  }

  return (
    !candidacies.has('ids') ||
    Boolean(candidacies.getIn(['_meta', 'isInvalidated']))
  );
};

export default shouldFetchCandidacyList;
