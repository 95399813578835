import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import meetingsByParentType from './meetingsByParentType';

const meetingsReducer = combineImmutableReducers({
  meetingsByParentType: meetingsByParentType,
});

meetingsReducer.NAME = NAME;

export default meetingsReducer;
