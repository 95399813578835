import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import positionSchema from '../schema';

/**
 * Gets the property for a position record
 * @param {Object} state
 * @param {Number} positionId The position ID
 * @return {String} The property value (if found)
 */
export default (state, positionId, propertyName) =>
  getEntityProperty(state, positionSchema.key, positionId, propertyName);
