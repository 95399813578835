import PropTypes from 'prop-types';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import Link from 'modules/routing/components/Link';
import getCompanyUrl from '../selectors/getCompanyUrl';

/**
 * Renders a link to a companies Thrive profile page.
 */
export default compose(
  setDisplayName('CompanyProfilePageLink'),
  setPropTypes({
    companyId: PropTypes.number.isRequired,
  }),
  mapProps(({ companyId, ...rest }) => ({
    useDefaultLinkBehavior: true,
    ...rest,
    href: getCompanyUrl(companyId),
  })),
)(Link);
