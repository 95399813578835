import selectn from 'selectn';
import { Map } from 'immutable';
import createListReducer from '../../../reducers/createListReducer';
import {
  CONTACTS_INVALIDATE,
  CONTACTS_FETCH_START,
  CONTACTS_FETCH_SUCCESS,
  CONTACTS_FETCH_FAILURE,
  CONTACT_ADD_SUCCESS,
  CONTACT_REMOVE_SUCCESS,
} from '../actions/actionTypes';

const contactsListReducer = createListReducer({
  resultKey: 'networks',
  entityResultKey: 'network',
  request: CONTACTS_FETCH_START,
  success: CONTACTS_FETCH_SUCCESS,
  failure: CONTACTS_FETCH_FAILURE,
  invalidated: CONTACTS_INVALIDATE,
});

// Contains the of all contacts that belong to a network, keyed by the network ID
export default (state = new Map(), action) => {
  switch (action.type) {
    case CONTACTS_INVALIDATE:
    case CONTACTS_FETCH_START:
    case CONTACTS_FETCH_SUCCESS:
    case CONTACTS_FETCH_FAILURE: {
      const networkId = selectn('payload.networkId', action);

      if (networkId) {
        return state.update(networkId, list =>
          contactsListReducer(list, action),
        );
      }

      return state;
    }
    case CONTACT_ADD_SUCCESS: {
      const contactId = selectn('payload.contactId', action);
      const networkId = selectn('payload.networkId', action);

      if (contactId && networkId && state.hasIn([networkId, 'ids'])) {
        return state.updateIn([networkId, 'ids'], ids =>
          ids.includes(contactId) ? ids : ids.push(contactId),
        );
      }

      return state;
    }
    case CONTACT_REMOVE_SUCCESS: {
      const contactId = selectn('payload.contactId', action);
      const networkId = selectn('payload.networkId', action);

      if (contactId && networkId && state.hasIn([networkId, 'ids'])) {
        return state.updateIn([networkId, 'ids'], ids =>
          ids.filter(id => id !== contactId),
        );
      }

      return state;
    }
    default: {
      return state.map(contactListState =>
        contactsListReducer(contactListState, action),
      );
    }
  }
};
