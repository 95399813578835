import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import mapPositionIdToPosition from 'modules/positions/components/mapPositionIdToPosition';
import PositionTitle from 'modules/positions/components/PositionTitle';
import PositionView from 'modules/positions/components/PositionView';
import withPositionActions from 'modules/positions/components/withPositionActions';

import connectContactActions from '../connectContactActions';
import ContactPositionForm from './ContactPositionForm';

import InlineEditActions from '../../../../components/forms/InlineEditActions';

/**
 * Renders single position list item.
 */
export class PositionListItem extends Component {
  state = {
    /**
     * True when the item is being edited inline, false when being viewed.
     * @type {Boolean}
     */
    isEditing: false,
  };

  /**
   * Called when the 'Yes' button of the delete confirmation is clicked.
   * Calls the action to delete the position and resets the action.
   */
  handleDeleteClick = () => {
    const { position, positionActions } = this.props;
    this.setState({
      isEditing: false,
    });

    positionActions.deletePosition({ id: position.get('id') });
  };

  /**
   * Called when the edit link is clicked, shows the edit form in place of
   * the position view.
   */
  handleEditClick = () => {
    this.setState({
      isEditing: true,
    });
  };

  /**
   * Called when the edit form is closed, removes the edit form and shows
   * the position view instead.
   */
  handleFormClose = () => {
    this.setState({
      isEditing: false,
    });
  };

  render() {
    const {
      formLayout,
      position,
      positionId,
      readOnly,
      renderDescription,
      renderTitle,
      targetedCompanyIds,
    } = this.props;
    const { isEditing } = this.state;
    const isDeleting = position.getIn(['_meta', 'isDeleting']);
    const canDelete = position.getIn(['permissions', 'delete']);
    const canEdit = position.getIn(['permissions', 'edit']);

    return (
      <li className='PositionListItem'>
        {renderTitle && (
          <h3 className='PositionListItem__title u-marginTop-n'>
            <InlineEditActions
              actionClassName='PositionListItem__actions'
              canDelete={!isEditing && !readOnly && canDelete}
              canEdit={!isEditing && !readOnly && canEdit}
              className='PositionListItem__edit-actions'
              deleteConfirmation='Delete this position?'
              deleteLabel={<i className='fa fa-trash' />}
              disabled={isDeleting}
              editLabel={<i className='fa fa-pencil' />}
              onDelete={this.handleDeleteClick}
              onEdit={this.handleEditClick}
              shouldRenderV4Button={true}
            >
              <PositionTitle
                linkToCompany={true}
                nameFirst={true}
                positionId={positionId}
              />
            </InlineEditActions>
          </h3>
        )}
        {isEditing ? (
          <ContactPositionForm
            allowPrimarySelect={true}
            alwaysShowFieldErrors={true}
            errorDisplay='tooltip'
            header='Edit Position'
            layout={formLayout}
            onCancel={this.handleFormClose}
            onSaved={this.handleFormClose}
            position={position}
            positionId={positionId}
          />
        ) : (
          <PositionView
            isTargetCompany={
              targetedCompanyIds &&
              targetedCompanyIds.includes(position.get('company'))
            }
            positionId={position.get('id')}
            renderDescription={renderDescription}
            showPositionFunctions={true}
            showPositionLevels={true}
          />
        )}
        {!renderTitle && !readOnly && (canEdit || canDelete) && (
          <InlineEditActions
            canDelete={!isEditing && !readOnly && canDelete}
            canEdit={!isEditing && !readOnly && canEdit}
            deleteConfirmation='Delete this position?'
            disabled={isDeleting}
            onDelete={this.handleDeleteClick}
            onEdit={this.handleEditClick}
            position={position}
          />
        )}
      </li>
    );
  }
}

export const propTypes = {
  /**
   * If the item is editable, the form layout to use when editing.
   */
  formLayout: PropTypes.oneOf(ContactPositionForm.FIELD_LAYOUTS),

  /**
   * The position to render.
   */
  position: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isDeleting: PropTypes.bool,
    }),
    permissions: ImmutablePropTypes.mapContains({
      delete: PropTypes.bool.isRequired,
      edit: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,

  /**
   * Action for deleting positions (only required when `readOnly` is false).
   */
  positionActions: requiredIf(
    PropTypes.shape({
      deletePosition: PropTypes.func.isRequired,
    }),
    props => !props.readOnly,
  ),

  positionId: PropTypes.number.isRequired,

  /**
   * True to not allow editing of the position.
   */
  readOnly: PropTypes.bool,

  /**
   * True to render the description field in the position view, false to omit it.
   */
  renderDescription: PropTypes.bool,

  /**
   * True to render the position title, false to omit it.
   */
  renderTitle: PropTypes.bool,

  /**
   * If highlighting target companies, this will be a list of all target company IDs that should
   * be highlighted
   */
  targetedCompanyIds: ImmutablePropTypes.listOf(PropTypes.number),
};

export const defaultProps = {
  formLayout: ContactPositionForm.FIELD_LAYOUTS[0],
  readOnly: false,
  renderDescription: true,
  renderTitle: true,
};

PositionListItem.propTypes = propTypes;
PositionListItem.defaultProps = defaultProps;

export default compose(
  setDisplayName('PositionListItem(enhanced)'),
  setPropTypes({
    positionId: PropTypes.number.isRequired,
  }),
  mapPositionIdToPosition,
  connectContactActions,
  withPositionActions,
)(PositionListItem);
