import getName from 'modules/contacts/selectors/contacts/getName';
import getCandidacyContactId from './getCandidacyContactId';

/**
 * Gets the full name of a candidacy record.
 * @param {Object} state
 * @param {Number} id The candidacy ID
 * @return {?String} The full name of the contact
 */
const getCandidacyFullName = (state, candidacyId) => {
  const contactId = getCandidacyContactId(state, candidacyId);
  return contactId && getName(state, contactId);
};

export default getCandidacyFullName;
