import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, setDisplayName, setPropTypes, setStatic } from 'recompose';
import FieldState from '../FieldState';
import asField from './asField';
import withFormGroup from './withFormGroup';

/**
 * A field for editing a value using a standard `<textarea>` element.
 */
class TextArea extends Component {
  refTextArea = element => {
    const { focusOnMount } = this.props;
    if (element && focusOnMount) {
      element.focus();
    }
  };

  render() {
    const {
      // prevent props from being passed through to the input.
      focusOnMount: _focusOnMount,

      ...otherProps
    } = this.props;

    return (
      <textarea
        className='form-control'
        ref={this.refTextArea}
        {...otherProps}
      />
    );
  }
}

TextArea.propTypes = {
  /**
   * True to automatically focus the text area field when the component is mounted in the DOM.
   */
  focusOnMount: PropTypes.bool,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * The content the textarea contains
   */
  value: PropTypes.string.isRequired,
};

export default compose(
  setDisplayName('TextAreaField'),
  setPropTypes({
    fieldState: PropTypes.instanceOf(FieldState).isRequired,

    /**
     * When true, the text area will be given focus when it is rendered.
     */
    focusOnMount: PropTypes.bool,

    onChange: PropTypes.func.isRequired,
  }),
  setStatic('createFieldState', FieldState.create),
  withFormGroup,
  asField({
    handleValueChange: event => event.currentTarget.value,
    convertInputValue: value => value || '',
  }),
)(TextArea);
