import { NAME } from '../../constants';

/**
 * Gets the educations that belong to a contact.
 * @param {Object} state
 * @param {Number} contactId The contact ID
 * @returns {Immutable.List<Number>} An immutable list of the education IDs that belong to the
 *   contact
 */
export default (state, contactId) =>
  state[NAME].getIn(['educations', 'byContactId', contactId]);
