import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import routes from 'modules/routing/routes';
import { attributeSchema } from '../../schema';
import {
  ATTRIBUTES_FETCH_START,
  ATTRIBUTES_FETCH_SUCCESS,
  ATTRIBUTES_FETCH_FAILURE,
} from './actionTypes';

/**
 * Creates an action that fetches the assessment attributes that have been chosen for a particular
 * search.
 * @param {Object} options
 * @param {Number} options.searchId The ID of the search to fetch the assesssment attributes for.
 */
export default compose(
  fetchAction,
  normalizeResponse({ attributes: [attributeSchema] }),
  createAsyncActions({
    startActionType: ATTRIBUTES_FETCH_START,
    successActionType: ATTRIBUTES_FETCH_SUCCESS,
    failureActionType: ATTRIBUTES_FETCH_FAILURE,
  }),
  withOptions(({ searchId }) => ({
    url: routes.api_v1_search_assessment_attributes({ searchId: searchId }),
  })),
);
