/**
 * This is a middleware for adding "actors" to the redux store. And actor is something
 * that can listen to all actions in order to act on them in some way -- typically
 * by dispatching a related action.
 */
export default (actors = []) => ({ dispatch, getState }) => next => action => {
  const returnedValue = next(action);
  actors.forEach(actor =>
    actor({ action: action, getState: getState, dispatch: dispatch }),
  );
  return returnedValue;
};
