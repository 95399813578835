import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import employeeCountListsByCompanyId from './employeeCountListsByCompanyId';

const companyEmployeeCountsReducer = combineImmutableReducers({
  employeeCountListsByCompanyId: employeeCountListsByCompanyId,
});

companyEmployeeCountsReducer.NAME = NAME;

export default companyEmployeeCountsReducer;
