import { connect } from 'react-redux';
import getContactProperty from '../selectors/contacts/getContactProperty';

/**
 * A higher order component which maps a `contactId` prop value to it's compensation record ID.
 * @param {String} compensationType The compensation type (i.e. "employee")
 * @param {String} [propName='compensationId'] THe name of the prop to add that contains the
 *   compensation ID.
 */
export default (compensationType, propName = 'compensationId') =>
  connect(
    (state, { contactId }) => ({
      [propName]: getContactProperty(
        state,
        contactId,
        `${compensationType}_compensation`,
      ),
    }),
    {},
  );
