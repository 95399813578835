import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import activityListsByParentType from './activityListsByParentType';

const activitiesReducer = combineImmutableReducers({
  activityListsByParentType: activityListsByParentType,
});

activitiesReducer.NAME = NAME;

export default activitiesReducer;
