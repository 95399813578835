import selectn from 'selectn';
import { Map } from 'immutable';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import createListReducer from '../../../reducers/createListReducer';
import {
  REFERENCE_LIST_FETCH_START,
  REFERENCE_LIST_FETCH_SUCCESS,
  REFERENCE_LIST_FETCH_FAILURE,
} from '../actions/actionTypes';
import { PARENT_CANDIDACY, PARENT_CONTACT } from '../constants';
import referenceSchema from '../schema';

const referenceListReducer = createListReducer({
  resultKey: 'references',
  entityResultKey: 'reference',
  request: REFERENCE_LIST_FETCH_START,
  success: REFERENCE_LIST_FETCH_SUCCESS,
  failure: REFERENCE_LIST_FETCH_FAILURE,
  created: entityActionTypes.CREATE_SUCCESS,
  deleted: entityActionTypes.DELETE_SUCCESS,
});

// References are keyed on the parent type (PARENT_CANDIDACY or PARENT_CONTACT),
// then by that parent's ID (a contact ID for PARENT_CONTACT or a candidacy ID for
// PARENT_CANDIDACY).
export default (state = new Map(), action) => {
  switch (action.type) {
    case REFERENCE_LIST_FETCH_START:
    case REFERENCE_LIST_FETCH_SUCCESS:
    case REFERENCE_LIST_FETCH_FAILURE: {
      const parentType = selectn('payload.parentType', action);
      const parentId = selectn('payload.parentId', action);

      if (parentType && parentId) {
        return state.updateIn([parentType, parentId], list =>
          referenceListReducer(list, action),
        );
      }

      return state;
    }
    case entityActionTypes.DELETE_SUCCESS: {
      if (selectn('payload.entityType', action) !== referenceSchema.key) {
        return state;
      }

      return state.map(parentTypeState =>
        parentTypeState.map(listState =>
          referenceListReducer(listState, action),
        ),
      );
    }
    case entityActionTypes.CREATE_SUCCESS: {
      if (selectn('payload.entityType', action) !== referenceSchema.key) {
        return state;
      }

      // Check if the newly created reference belongs to any currently fetched lists.
      const referenceId = selectn('payload.result.reference', action);
      const reference = selectn(
        `payload.entities.references.${referenceId}`,
        action,
      );

      if (reference) {
        return state.withMutations(mutable => {
          if (mutable.hasIn([PARENT_CONTACT, reference.contact_id, 'ids'])) {
            mutable.updateIn(
              [PARENT_CONTACT, reference.contact_id, 'ids'],
              ids => ids.push(referenceId),
            );
          }

          if (
            reference.candidacy_id &&
            mutable.hasIn([PARENT_CANDIDACY, reference.candidacy_id, 'ids'])
          ) {
            mutable.updateIn(
              [PARENT_CANDIDACY, reference.candidacy_id, 'ids'],
              ids => ids.push(referenceId),
            );
          }
        });
      }

      return state;
    }
    default: {
      return state.map(parentTypeState =>
        parentTypeState.map(listState =>
          referenceListReducer(listState, action),
        ),
      );
    }
  }
};
