/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import useFormInput from '@thrivetrm/ui/hooks/useFormInput';
import formValidationRulesPropType from '@thrivetrm/ui/propTypes/formValidationRulesPropType';
import AsyncSelect from './AsyncSelect';

const FormAsyncSelect = ({ initialValue, label, name, rules, ...props }) => {
  const { errorMessage, isRequired, onBlur, onChange, value } = useFormInput({
    name: name,
    initialValue: initialValue,
    label: label,
    rules: rules,
  });

  return (
    <AsyncSelect
      {...props}
      errorMessage={errorMessage}
      isRequired={isRequired}
      label={label}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
    />
  );
};

FormAsyncSelect.defaultProps = {
  initialValue: null,
  label: null,
  rules: null,
};
FormAsyncSelect.propTypes = {
  initialValue: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }),
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  rules: formValidationRulesPropType,
};

export default FormAsyncSelect;
