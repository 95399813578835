import routes from 'modules/routing/routes';
import { PARENT_CANDIDACY, PARENT_CONTACT, PARENT_COMPANY } from '../constants';

/**
 * Gets the URL for the comments resource based on the parent type and parent id
 * @param {Object} params
 * @param {String} payload.parentType The parent/owner type to fetch comments for (candidacy or
 *   contact)
 * @param {Number} payload.parentId The ID of the parent type (contact ID or candidacy ID)
 */
export default ({ parentId, parentType }) => {
  switch (parentType) {
    case PARENT_CANDIDACY: {
      return routes.api_candidacy_comments({ candidacyId: parentId });
    }
    case PARENT_CONTACT: {
      return routes.api_contact_comments({ contactId: parentId });
    }
    case PARENT_COMPANY: {
      return routes.api_company_comments({ companyId: parentId });
    }
    default: {
      throw new Error(`Unknown parentType for comments: ${parentType}`);
    }
  }
};
