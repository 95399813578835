//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'users';

//---------------------------------------------------------
// Filters
//---------------------------------------------------------

export const FILTER_WITH_INTRODUCTIONS = 'with_introductions';
export const FILTER_CLIENTS = 'clients';
export const FILTER_LEADS = 'leads';
export const FILTER_EMPLOYEES = 'employees';
export const FILTER_EXTERNAL_RECRUITERS = 'external_recruiters';
export const FILTER_CRM_USER_AND_EMPLOYEES = 'employee_and_crm_users';
export const FILTER_NON_CANDIDATES = 'non_candidates';

export const FILTERS = [
  FILTER_CRM_USER_AND_EMPLOYEES,
  FILTER_WITH_INTRODUCTIONS,
  FILTER_CLIENTS,
  FILTER_LEADS,
  FILTER_EMPLOYEES,
  FILTER_EXTERNAL_RECRUITERS,
  FILTER_NON_CANDIDATES,
];
