import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as actions from '../actions';

/**
 * Connects the assessment actions to the current dispatched and adds
 * them to props as `assessmentActions`
 */
export default connect(null, dispatch => ({
  assessmentActions: bindActionCreators(actions, dispatch),
}));
