import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as actions from '../actions';

/**
 * Provides a connected `searchStageActions` prop which can dispatch search stage actions.
 */
export default connect(null, dispatch => ({
  searchStageActions: bindActionCreators(actions, dispatch),
}));
