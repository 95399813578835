import { Iterable } from 'immutable';

/**
 * Cast a blank value to `null`.
 */
const nullifyBlankValue = value => {
  if (Iterable.isIterable(value) && value.size === 0) {
    return null;
  }

  if (Array.isArray(value) && value.length === 0) {
    return null;
  }

  if (typeof value === 'string' && value.trim().length === 0) {
    return null;
  }

  if (typeof value === 'number' && Number.isNaN(value)) {
    return null;
  }

  if (typeof value === 'undefined') {
    return null;
  }

  return value;
};

/**
 * Creates a validator function for a required field.
 * @param {String} fieldLabel The descriptive label of the field, used in the error message.
 * @param {String} [verb='is'] The verb used when describing the field as required.
 *   In most cases we'd say "Title is required", but there are cases where we may need to use
 *   an alternative form, for example plural fields would use "are" instead, as in:
 *   "Foobars are required"
 * @return {Function} A function that can be used as a `FieldState` validator.
 */
const requiredField = (fieldLabel, verb = 'is') => fieldState => {
  if (nullifyBlankValue(fieldState.getValue()) === null) {
    return `${fieldLabel} ${verb} required`;
  }

  return null;
};

export default requiredField;
