export const CONTACT_SELECT = 'CONTACT_SELECT';
export const CONTACT_REVIEW_SELECT = 'CONTACT_REVIEW_SELECT';
export const CONTACT_INVALIDATE = 'CONTACT_INVALIDATE';

export const CONTACT_FETCH_START = 'CONTACT_FETCH_START';
export const CONTACT_FETCH_SUCCESS = 'CONTACT_FETCH_SUCCESS';
export const CONTACT_FETCH_FAILURE = 'CONTACT_FETCH_FAILURE';

export const CONTACTS_FETCH_START = 'CONTACTS_FETCH_START';
export const CONTACTS_FETCH_SUCCESS = 'CONTACTS_FETCH_SUCCESS';
export const CONTACTS_FETCH_FAILURE = 'CONTACTS_FETCH_FAILURE';

export const CONTACT_CREATE_START = 'CONTACT_CREATE_START';
export const CONTACT_CREATE_SUCCESS = 'CONTACT_CREATE_SUCCESS';
export const CONTACT_CREATE_FAILURE = 'CONTACT_CREATE_FAILURE';

export const CONTACT_UPDATE_START = 'CONTACT_UPDATE_START';
export const CONTACT_UPDATE_SUCCESS = 'CONTACT_UPDATE_SUCCESS';
export const CONTACT_UPDATE_FAILURE = 'CONTACT_UPDATE_FAILURE';

export const CONTACT_RESUME_UPLOAD_START = 'CONTACT_RESUME_UPLOAD_START';
export const CONTACT_RESUME_UPLOAD_SUCCESS = 'CONTACT_RESUME_UPLOAD_SUCCESS';
export const CONTACT_RESUME_UPLOAD_FAILURE = 'CONTACT_RESUME_UPLOAD_FAILURE';

export const CONTACT_QUERY_CREATE = 'CONTACT_QUERY_CREATE';
export const CONTACT_QUERY_DESTROY = 'CONTACT_QUERY_DESTROY';
export const CONTACT_QUERY_INVALIDATE = 'CONTACT_QUERY_INVALIDATE';
export const CONTACT_QUERY_FETCH_START = 'CONTACT_QUERY_FETCH_START';
export const CONTACT_QUERY_FETCH_SUCCESS = 'CONTACT_QUERY_FETCH_SUCCESS';
export const CONTACT_QUERY_FETCH_FAILURE = 'CONTACT_QUERY_FETCH_FAILURE';

export const CONTACT_QUERY_URL = '/api/contacts/search';

export const CONTACT_DUPLICATE_QUERY_CREATE = 'CONTACT_DUPLICATE_QUERY_CREATE';
export const CONTACT_DUPLICATE_QUERY_DESTROY =
  'CONTACT_DUPLICATE_QUERY_DESTROY';
export const CONTACT_DUPLICATE_QUERY_INVALIDATE =
  'CONTACT_DUPLICATE_QUERY_INVALIDATE';
export const CONTACT_DUPLICATE_QUERY_FETCH_START =
  'CONTACT_DUPLICATE_QUERY_FETCH_START';
export const CONTACT_DUPLICATE_QUERY_FETCH_SUCCESS =
  'CONTACT_DUPLICATE_QUERY_FETCH_SUCCESS';
export const CONTACT_DUPLICATE_QUERY_FETCH_FAILURE =
  'CONTACT_DUPLICATE_QUERY_FETCH_FAILURE';

export const CONTACT_FILTER_WITH_INTRODUCTIONS = 'withIntroductions';
export const CONTACT_FILTER_WITH_PARTNERSHIPS = 'withPartnerships';

export const CONTACT_USER_CREATE_START = 'CONTACT_USER_CREATE_START';
export const CONTACT_USER_CREATE_SUCCESS = 'CONTACT_USER_CREATE_SUCCESS';
export const CONTACT_USER_CREATE_FAILURE = 'CONTACT_USER_CREATE_FAILURE';

export const CONTACT_AVATAR_UPDATE_START = 'CONTACT_AVATAR_UPDATE_START';
export const CONTACT_AVATAR_UPDATE_SUCCESS = 'CONTACT_AVATAR_UPDATE_SUCCESS';
export const CONTACT_AVATAR_UPDATE_FAILURE = 'CONTACT_AVATAR_UPDATE_FAILURE';
