import { connect } from 'react-redux';
import getEducationEndDate from '../../selectors/educations/getEducationEndDate';
import getEducationStartDate from '../../selectors/educations/getEducationStartDate';

/**
 * A Higher order component that sorts a list of educationIds in reverse chronological order by
 * end date, then by start date.
 */
export default connect(
  (state, { educationIds }) => ({
    educationIds:
      educationIds &&
      educationIds
        .sortBy(educationId => [
          getEducationEndDate(state, educationId) || Number.POSITIVE_INFINITY,
          getEducationStartDate(state, educationId) || Number.POSITIVE_INFINITY,
        ])
        .reverse(),
  }),
  {},
);
