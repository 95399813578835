import selectn from 'selectn';
import { Map } from 'immutable';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import createEntityListReducer from 'modules/entities/createEntityListReducer';
import investmentSchema from 'modules/company-investments/schema';
import {
  FUNDING_ROUND_LIST_FETCH_START,
  FUNDING_ROUND_LIST_FETCH_SUCCESS,
  FUNDING_ROUND_LIST_FETCH_FAILURE,
} from '../actions/actionTypes';

const fundingRoundListReducer = createEntityListReducer({
  entityType: investmentSchema.key,
  listResultKey: 'funding_rounds',
  entityResultKey: 'funding_round',
  request: FUNDING_ROUND_LIST_FETCH_START,
  success: FUNDING_ROUND_LIST_FETCH_SUCCESS,
  failure: FUNDING_ROUND_LIST_FETCH_FAILURE,
});

// Funding Rounds lists are each keyed on the companyId
export default (state = new Map(), action) => {
  switch (action.type) {
    case FUNDING_ROUND_LIST_FETCH_START:
    case FUNDING_ROUND_LIST_FETCH_SUCCESS:
    case FUNDING_ROUND_LIST_FETCH_FAILURE: {
      const companyId = selectn('payload.companyId', action);
      if (companyId) {
        return state.update(companyId, list =>
          fundingRoundListReducer(list, action),
        );
      }

      return state;
    }
    case entityActionTypes.CREATE_SUCCESS: {
      const investmentId = selectn('payload.result.funding_round', action);
      const companyId = selectn(
        `payload.entities.${investmentSchema.key}.${investmentId}.venture`,
        action,
      );
      if (companyId && state.hasIn([companyId, 'ids'])) {
        return state.updateIn([companyId, 'ids'], ids =>
          ids.concat(investmentId),
        );
      }

      return state;
    }
    default: {
      return state.map(list => fundingRoundListReducer(list, action));
    }
  }
};
