import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  setStatic,
} from 'recompose';
import FieldState from '../FieldState';
import asField from './asField';
import withFormGroup from './withFormGroup';

export default compose(
  setDisplayName('InputField'),
  setPropTypes({
    fieldState: PropTypes.instanceOf(FieldState).isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  setStatic('createFieldState', FieldState.create),
  withFormGroup,
  asField({
    handleValueChange: event => event.currentTarget.value,
    convertInputValue: value => value || '',
  }),
  defaultProps({ type: 'text', isRequired: false }),
)(({ isRequired, ...props }) => (
  <input className='form-control' required={isRequired} {...props} />
));
