//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'searches';

//---------------------------------------------------------
// Search types
//---------------------------------------------------------

export const TYPE_JOB_SEARCH = 'JobSearch';
export const TYPE_PIPELINE = 'Pipeline';
export const TYPE_TALENT_POOL = 'TalentPool';

/** All valid search types */
export const SEARCH_TYPES = [TYPE_JOB_SEARCH, TYPE_PIPELINE, TYPE_TALENT_POOL];

/** Maps search types to their labels */
export const SEARCH_TYPE_LABELS = {
  [TYPE_TALENT_POOL]: 'Talent Pool',
  [TYPE_JOB_SEARCH]: 'Search',
  [TYPE_PIPELINE]: 'Pipeline',
};

/** Maps search types to their base URL path (for the candidacies list) */
export const SEARCH_PAGE_BASE_PATHS = {
  [TYPE_TALENT_POOL]: '/talent_pools',
  [TYPE_JOB_SEARCH]: '/searches',
  [TYPE_PIPELINE]: '/pipelines',
};

//---------------------------------------------------------
// Parent types
//---------------------------------------------------------

/** Used for identifying searches that belong to a company */
export const PARENT_COMPANY = 'company';
export const PARENT_SEARCH_FIRM = 'search_firm';

export const PARENT_CONTACT = 'contact';

/** All valid parent types */
export const PARENT_TYPES = [
  PARENT_COMPANY,
  PARENT_SEARCH_FIRM,
  PARENT_CONTACT,
];

//---------------------------------------------------------
// Available "view" types (for server requests)
//---------------------------------------------------------

export const FIELDS_VIEW_SIMPLE = 'simple';
export const FIELDS_VIEW_EXPANDED = 'expanded';
export const FIELDS_VIEW_FULL = 'full';

//---------------------------------------------------------
// Reports
//---------------------------------------------------------

export const REPORT_SEARCH_SUMMARY = 'search_candidate_summary';
export const REPORT_LEADERSHIP = 'leadership';

//---------------------------------------------------------
// Search statuses
//---------------------------------------------------------
/** @see {app/models/concerns/search_constants.rb} */

export const STATUS_BIZ_DEV = 'Business Development';
export const STATUS_OPEN = 'Open';
export const STATUS_ACTIVE = 'Active';
export const STATUS_CLOSED = 'Closed';
export const STATUS_CANCELED = 'Canceled';
export const STATUS_LOST = 'Lost';
export const STATUS_HOLD = 'Hold';

//---------------------------------------------------------
// Seearch-related user types
//---------------------------------------------------------

export const USER_RELATION_LEAD_RECRUITER = 'lead';
export const USER_RELATION_PRIMARY_RECRUITER = 'primary';

//---------------------------------------------------------
// Sorting options
//---------------------------------------------------------

export const SORT_BY_CLIENT_COMPANY_NAME = 'client_company_name';
export const SORT_BY_END_DATE = 'end_date';
export const SORT_BY_JOB_TITLE = 'job_title';
// wtf?
export const SORT_BY_LEAD_RECRUITER = 'lead_internal_team_member';
export const SORT_BY_LOCATION = 'location';
export const SORT_BY_MILESTONE = 'milestone';
export const SORT_BY_NAME = 'name';
// wtf?
export const SORT_BY_PRIMARY_RECRUITER = 'lead_recruiter_team_member';
export const SORT_BY_RECRUITMENT_RATING = 'star_rating';
export const SORT_BY_SEARCH_FIRM = 'search_firm';
export const SORT_BY_SOURCE_OF_BUSINESS = 'source_of_business';
export const SORT_BY_START_DATE = 'start_date';
export const SORT_BY_STATUS = 'status';

//---------------------------------------------------------
// Searches table columns
//---------------------------------------------------------

export const COLUMN_CLIENT_COMPANY_NAME = 'client_company_name';
export const COLUMN_EDIT_LINK = 'search_edit';
export const COLUMN_END_DATE = 'end_date';
export const COLUMN_JOB_TITLE = 'job_title';
export const COLUMN_LEAD_RECRUITER = 'lead_recruiter';
export const COLUMN_LOCATION = 'location';
export const COLUMN_MILESTONE = 'milestone';
export const COLUMN_NAME = 'name';
export const COLUMN_PRIMARY_RECRUITER = 'primary_recruiter';
export const COLUMN_SEARCH_FIRM = 'search_firm';
export const COLUMN_SOURCE_OF_BUSINESS = 'source_of_business';
export const COLUMN_STAR_RATING = 'star_rating';
export const COLUMN_START_DATE = 'start_date';
export const COLUMN_STATUS = 'status';

export const COLUMNS = [
  COLUMN_CLIENT_COMPANY_NAME,
  COLUMN_EDIT_LINK,
  COLUMN_END_DATE,
  COLUMN_JOB_TITLE,
  COLUMN_LEAD_RECRUITER,
  COLUMN_LOCATION,
  COLUMN_NAME,
  COLUMN_MILESTONE,
  COLUMN_PRIMARY_RECRUITER,
  COLUMN_SEARCH_FIRM,
  COLUMN_SOURCE_OF_BUSINESS,
  COLUMN_STAR_RATING,
  COLUMN_START_DATE,
  COLUMN_STATUS,
];
