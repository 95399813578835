import { NAME } from '../constants';

/**
 * Gets the number of comments that a particular parent has
 * @param {Object} state
 * @param {String} parentType The parent type (PARENT_CONTACT or PARENT_CANDIDACY)
 * @param {Number} parentId The parent ID -- either a contact ID when
 *   parentType === PARENT_CONTACT, or a candidacy ID when parentType === PARENT_CANDIDACY
 * @return {Number|undefined} The number of total comments (including child comments) that
 *   the parent has -- or undefined if the count is unknown.
 */
export default (state, parentType, parentId) =>
  state[NAME].getIn(['countsByParentType', parentType, parentId]);
