import routes from 'modules/routing/routes';
import { createPostAction } from '../../../../actions/createAjaxAction';
import {
  CONTACT_REPORT_CREATE_START,
  CONTACT_REPORT_CREATE_SUCCESS,
  CONTACT_REPORT_CREATE_FAILURE,
} from './actionTypes';

export default createPostAction({
  getUrl: routes.api_v1_contact_report,
  start: CONTACT_REPORT_CREATE_START,
  success: CONTACT_REPORT_CREATE_SUCCESS,
  failure: CONTACT_REPORT_CREATE_FAILURE,
});
