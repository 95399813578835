//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'emailTemplates';

//---------------------------------------------------------
// Table colums
//---------------------------------------------------------

export const COLUMN_NAME = 'name';
export const COLUMN_CREATED_BY = 'created_by';
export const COLUMN_DATE_CREATED = 'created_at';
export const COLUMN_DATE_MODIFIED = 'updated_at';
export const COLUMN_EDIT_DELETE_BUTTONS = 'email_template_edit_destroy';

export const COLUMNS = [
  COLUMN_NAME,
  COLUMN_CREATED_BY,
  COLUMN_DATE_CREATED,
  COLUMN_DATE_MODIFIED,
  COLUMN_EDIT_DELETE_BUTTONS,
];

//---------------------------------------------------------
// Sorting
//---------------------------------------------------------

export const SORT_BY_NAME = 'name';
