import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CompanyName from 'modules/companies/components/CompanyName';
import getPositionProperty from '../selectors/getPositionProperty';

/**
 * Renders the name of the company associated with a position.
 * This may be a company record in the data, or just a user-entered
 * string.
 */

const PositionCompanyName = ({ linkToCompany, positionId }) => {
  const companyId = useSelector(state =>
    getPositionProperty(state, positionId, 'company'),
  );
  const companyName = useSelector(state =>
    getPositionProperty(state, positionId, 'company_name'),
  );

  return companyId ? (
    <CompanyName
      className='PositionCompanyName'
      companyId={companyId}
      linkToCompany={linkToCompany}
    />
  ) : (
    <span className='PositionCompanyName'>{companyName}</span>
  );
};

PositionCompanyName.propTypes = {
  /**
   * True to render the name with a link to the company page
   * (only applicable when the positioned is linked to a company record, and not
   * simply a user-entered string).
   */
  linkToCompany: PropTypes.bool,
  positionId: PropTypes.number.isRequired,
};

PositionCompanyName.defaultProps = {
  linkToCompany: false,
};

export default PositionCompanyName;
