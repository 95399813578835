import getEntity from 'modules/entities/selectors/getEntity';
import userSchema from '../schema';

/**
 * Gets a user record by it's ID
 * @param {Object} state
 * @param {Number} introductionId The user ID
 * @return {Immutable.Map|undefined} The user record, if found
 */
export default (state, userId) => getEntity(state, userSchema.key, userId);
