const greaterThan = number => fieldState => {
  const value = fieldState.getRawValue();

  if (typeof value === 'number' && !(value > number)) {
    return `Value must be greater than ${number}`;
  }

  return null;
};

export default greaterThan;
