import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import referencesByParentType from './referencesByParentType';

const referencesReducer = combineImmutableReducers({
  referencesByParentType: referencesByParentType,
});

referencesReducer.NAME = NAME;

export default referencesReducer;
