import { schema } from 'normalizr';
import companySchema from 'modules/companies/companySchema';
import userSchema from 'modules/users/schema';

export const targetCompanySchema = new schema.Entity('targetCompanies', {
  company: companySchema,
});

// NOTE target company comments are totally different from our regular "comments" (see the
// comments module), so I've tried to be explicit by refering to them as "target company comments"
// instead of simply "comments" (and also using "targetCompanyCommentId" instead of just
// "commentId", etc). There's really no reason we can't, at some point, convert these to actual
// comments, which are polymorphic anyway.
export const targetCompanyCommentSchema = new schema.Entity(
  'targetCompanyComments',
  {
    user: userSchema,
  },
);

export default targetCompanySchema;
