/* eslint-disable camelcase */
// ^ accommodate API response format
import { schema } from 'normalizr';
import assessmentQuestionSetSchema from 'modules/tenant/schemas/assessmentQuestionSets';

/**
 * For some reason every time I deal with assessments I get confused about the relationship
 * between the various models. So here is an explanation -- mainly for reference for the next time
 * I have to deal with them. But hopefully they help you, too. Or perhaps I'm just dense. Either
 * way, I present:
 *
 * The assessments models
 * -----------------------
 *
 * A tenant defines any number of assessment *options*. These are shared across the entire
 * tenant and it's searches. The option is what defines the `name` and `description` for
 * an assessment "rating" (aka "skill", aka "score", aka "star rating").
 *
 * Each search then takes a subset of these options, defined using a set of assessment *attributes*.
 * Each attribute references a single option, but also includes a `position` which defines
 * the order that they should be displayed in for the search they are associated with.
 *
 * A candidacy within a search may than have any number of *assessments* (associated via the
 * assessment's `candidacy_id`). The assessment contains a `type` (TYPE_RECRUITER or TYPE_CLIENT)
 * and a comment.
 *
 * Each assessment also has any number *ratings* associated with it. The rating contains the
 * score value (typically 0..5, but the maximum value is dictated by the `rating_stars` tenant
 * setting) and is associated with an *attributes* (which defines the order the ratings should be
 * displayed using it's `position`), which is in turn associated with an *option* (which defines
 * the name and description).
 */

/**
 * The schema for an assessment option
 * @type {schema.Entity}
 */
export const optionSchema = new schema.Entity('assessmentOptions');

/**
 * Modfies an incoming record before it is normalized, setting the `assessment_option_id`
 * property from a nested `assessment_option` object, if given.
 * @param {Object} record The incoming record
 * @param {Object} record.assessment_option The nested assessment option.
 * @param {Number} record.assessment_option_id The assessment option ID (may have been given )
 * @return {Object} The rating with an assessment_option_id property set.
 */
export const extractAsssessmentOptionId = ({
  assessment_option,
  assessment_option_id,
  ...props
}) => ({
  assessment_option_id:
    (assessment_option && assessment_option.id) || assessment_option_id,
  assessment_option: assessment_option,
  ...props,
});

/**
 * The schema for an assessment attribute belonging to a search
 * @type {schema.Entity}
 */
export const attributeSchema = new schema.Entity(
  'assessmentAttributes',
  {
    // In some legacy cases the attribute response includes the entire assessment option object
    // itself as an `assessment_option` instead of `assessment_option_id`. But in order to maintain
    // consistency, we always want it to be `assessment_option`. So in `processStrategy` (below)
    // we check to see if we just returned an ID, and if so, make it look like an option object
    // so normalizr then converts it to an ID.
    assessment_option: optionSchema,
  },
  {
    processStrategy: extractAsssessmentOptionId,
  },
);

/**
 * The schema for an assessment rating.
 * @type {schema.Entity}
 */
export const ratingSchema = new schema.Entity(
  'assessmentRatings',
  {
    assessment_option: optionSchema,
  },
  {
    processStrategy: extractAsssessmentOptionId,
  },
);

/**
 * Converts old permissions style (`{ user_can_edit: true, user_can_delete: true }`)
 * to new "permissions: {}" block if needed.
 */
export const processLegacyPermissions = ({
  permissions,
  user_can_delete,
  user_can_edit,
  ...props
}) => ({
  ...props,
  permissions: permissions || { edit: user_can_edit, delete: user_can_delete },
});

/**
 * The schema for an assessment record belonging to a candidacy.
 * @type {schema.Entity}
 */
export const assessmentSchema = new schema.Entity(
  'assessments',
  {
    question_set: assessmentQuestionSetSchema,
    ratings: [ratingSchema],
  },
  {
    processStrategy: processLegacyPermissions,
  },
);
