import { schema } from 'normalizr';
import companySchema from 'modules/companies/companySchema';

const employeeCountsSchema = new schema.Entity('employeeCounts', {
  company: companySchema,
});

companySchema.define({
  employee_counts: [employeeCountsSchema],
});

export default employeeCountsSchema;
