import fetchEntity from 'modules/entities/actions/fetchEntity';
import routes from 'modules/routing/routes';
import companySchema from '../companySchema';

/**
 * Creates an action for fetching a company by it's ID.
 * @param {Object} options
 * @param {Number} options.id The ID of the company to fetch.
 */
export default ({ id }) =>
  fetchEntity({
    entityType: companySchema.key,
    id: id,
    responseSchema: { company: companySchema },
    url: routes.api_v1_company({ id: id }),
  });
