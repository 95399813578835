import url from 'url';
import { stringifyQueryObject } from 'modules/core/urlUtils';
import { convertToSnakeCase } from 'modules/core/jsonUtils';

export default (pathname, query = {}) => {
  const snakeCaseQuery = convertToSnakeCase(query);

  const search = stringifyQueryObject(snakeCaseQuery);

  return url.format({ pathname: pathname, search: search });
};
