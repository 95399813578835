import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import portfolioCompanies from './portfolioCompanies';
import queriesById from './queriesById';

const companiesReducer = combineImmutableReducers({
  portfolioCompanies: portfolioCompanies,
  queriesById: queriesById,
});

companiesReducer.NAME = NAME;

export default companiesReducer;
