import { compose, mapProps, setDisplayName } from 'recompose';

/**
 * An icon used to indicate that a link is external.
 */
export default compose(
  setDisplayName('ExternalLinkIndicator'),
  mapProps(() => ({
    className: 'ExternalLinkIndicator fa fa-external-link',
  })),
)('i');
