import { TRANSACTION_CLEAR } from './actionTypes';

/**
 * Removes a transaction and it's data from storage.
 * @param {String} transactionId The unique transaction ID for this
 *   transaction.
 */
export default function clearTransaction(transactionId) {
  return {
    transactionId: transactionId,
    type: TRANSACTION_CLEAR,
  };
}
