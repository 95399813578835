import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FormState from 'modules/forms/FormState';

/**
 * Returns a function that creates a higher order function that includes a `formState` and
 * an `onChange` listener that updates that `formState` prop.
 *
 * @param {Function} createRootFieldState A function that will be called to create the state
 *   for the root field of the `FormState`, if no `initialFormState` or `initialFormField`
 *   properties were provided. It is called with the component's props and should
 *   return a single `FieldState` value.
 * @return {Function} A function that will create a higher-order component with `formState`
 *   and `onChange` props.
 */
const withFormState = createRootFieldState => FieldComponent => {
  class WithFormState extends Component {
    constructor(props) {
      super(props);
      const { canSubmitWithErrors } = this.props;
      this.state = {
        formState:
          props.initialFormState ||
          FormState.create(
            props.initialFieldState || createRootFieldState(props),
            { canSubmitWithErrors: canSubmitWithErrors },
          ),
      };
    }

    handleChange = formState => {
      const { onChange } = this.props;

      if (onChange) {
        onChange(formState);
      }

      this.setState({ formState: formState });
    };

    render() {
      const {
        /* eslint-disable no-unused-vars */
        initialFieldState,
        initialFormState,
        onChange,
        /* eslint-enable no-unused-vars */

        ...baseComponentProps
      } = this.props;
      const { formState } = this.state;

      return (
        <FieldComponent
          formState={formState}
          onChange={this.handleChange}
          {...baseComponentProps}
        />
      );
    }
  }

  WithFormState.propTypes = {
    canSubmitWithErrors: PropTypes.bool,

    initialFieldState: PropTypes.object, // eslint-disable-line react/forbid-prop-types

    initialFormState: PropTypes.object, // eslint-disable-line react/forbid-prop-types

    onChange: PropTypes.func,
  };

  return WithFormState;
};

export default withFormState;
