const always = () => {
  $('.js-check-accepted-terms').on('click', event => {
    if (!$('#user_accepts_toc').is(':checked')) {
      $('#toc-error').show();
      event.preventDefault();
    }
  });
};

export default {
  always: always,
};
