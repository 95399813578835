import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose, setPropTypes, setStatic } from 'recompose';
import AsyncSelect from 'modules/core/components/AsyncSelect';
import routes from 'modules/routing/routes';
import withFormGroup from 'modules/forms/components/withFormGroup';
import asField from 'modules/forms/components/asField';
import FieldState from 'modules/forms/FieldState';
import Api from 'modules/core/Api';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';
import Card from '@thrivetrm/ui/components/Card';
import StatusIndicator from '@thrivetrm/ui/components/StatusIndicator';
import { isNumber } from 'lodash';
import { connect } from 'react-redux';
import getCompany from 'modules/companies/selectors/getCompany';
import { formatOptions } from './SkyminyrCompanySelect';
import NewCompanyFormModal from '../NewCompanyFormModal';
import SkyminyrLegacyCompanyDetailsCard from './SkyminyrLegacyCompanyDetailsCard';
import SkyminyrCompanyCreateButton from './SkyminyrCompanyCreateButton';

const SkyminyrLegacyCompanySelect = ({
  getCompanyDetails,
  isClearable,
  isMulti,
  onChange,
  onCloseCreateCompanyModal,
  onCreateCompanyClick,
  queryParams,
  value,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [companyDupesData, setCompanyDupesData] = useState([]);
  const [
    isCreateNewCompanyModalOpen,
    openCreateNewCompanyModal,
    closeCreateNewCompanyModal,
  ] = useToggle(false);
  const [
    isFetchingGoldenRecords,
    fetchGoldenRecords,
    fetchSimpleRecords,
  ] = useToggle(true);

  const formatValue = () => {
    if (isMulti) {
      return value?.map(company => {
        if (isNumber(company)) {
          return formatOptions(getCompanyDetails(company)?.toJS());
        }
        return company;
      });
    } else if (isNumber(value)) {
      return formatOptions(getCompanyDetails(value)?.toJS());
    }
    return value;
  };
  const formattedValue = formatValue();

  const handleOnChange = (selectedCompanies, action) => {
    const companyDetails = isMulti
      ? action?.option?.data
      : selectedCompanies?.data;
    onChange(selectedCompanies);

    if (action.action === 'remove-value') {
      setCompanyDupesData(prevCompanyDupes =>
        prevCompanyDupes.filter(
          ({ companyId }) => action.removedValue.value !== companyId,
        ),
      );
    } else if (
      action.action === 'select-option' &&
      companyDetails?.skyminyr_id
    ) {
      Api.get(
        routes.check_for_dupes_api_v1_companies({
          query: {
            match_on: 'skyminyr',
            name: companyDetails.name.toLowerCase(),
            linkedin_url: companyDetails.linkedin_url,
            skyminyr_id: companyDetails?.skyminyr_id,
          },
        }),
      ).then(data => {
        const dupesDetails = {
          companyId: companyDetails.skyminyr_id,
          companyName: companyDetails.name,
          dupesData: data.meta,
        };
        setCompanyDupesData([...companyDupesData, ...[dupesDetails]]);
      });
    }
  };

  const handleInputChange = (newInputValue, { action }) => {
    if (action === 'input-change') {
      setInputValue(newInputValue);
    }
  };

  const getCompanySearchQuery = searchTerm => ({
    query: {
      query: {
        term: searchTerm,
        resource: 'company',
        ...(isFetchingGoldenRecords ? {} : queryParams),
      },
    },
  });

  const menuListFooter =
    inputValue?.trim() !== '' ? (
      <div className='u-padding-4 u-paddingLeft-12'>
        <div className='u-flex u-flexAlign-c'>
          <ButtonLink
            label={
              isFetchingGoldenRecords
                ? 'Show other records in database'
                : 'Show golden records'
            }
            onClick={
              isFetchingGoldenRecords ? fetchSimpleRecords : fetchGoldenRecords
            }
          />
        </div>

        <div className='u-flex u-flexAlign-c u-marginTop-4 u-wordBreak'>
          <ButtonSecondary
            className='SkyminyrCompanySelect__companyCreateButton u-marginRight-4'
            icon='add'
            onClick={openCreateNewCompanyModal}
            size='small'
          />
          Create a new company &quot;{inputValue}&quot;
        </div>
      </div>
    ) : null;

  const selectedCompany = isMulti ? null : formattedValue?.data;
  const unsavedCompanyName =
    selectedCompany?.name &&
    !selectedCompany?.skyminyr_id &&
    !selectedCompany?.id
      ? selectedCompany?.name
      : '';

  const getHelperTexts = () => {
    const dupesDataList = companyDupesData.filter(
      ({ companyId }) => isMulti || companyId === formattedValue?.value,
    );

    const nonExistingCompanies = dupesDataList
      .filter(({ dupesData }) => !dupesData.duplicate_exists)
      .map(({ companyName }) => companyName);
    const existingButNotEnrichedCompanies = dupesDataList
      .filter(
        ({ dupesData }) =>
          dupesData.duplicate_exists && !dupesData.enriched_with_golden_record,
      )
      .map(({ companyName }) => companyName);

    return [
      nonExistingCompanies.length
        ? `${nonExistingCompanies.join(
            ', ',
          )} company will be added to your database, which will automatically update with latest metadata.`
        : null,
      existingButNotEnrichedCompanies.length
        ? `Confirm ${existingButNotEnrichedCompanies.join(
            ', ',
          )} company record to link with. Once company is enriched, it will overwrite company record with the most up to date information.`
        : null,
    ];
  };

  return (
    <>
      {formattedValue && companyDupesData?.length
        ? getHelperTexts().map(text =>
            text ? (
              <Card
                className='u-padding-8 u-marginBottom-8 u-flex'
                isCentered={false}
                key={text}
                type='general'
              >
                <StatusIndicator
                  className='u-marginRight-4 u-marginTop-2'
                  size='small'
                  status='info'
                />
                <p className='u-margin-n u-fontSize-small'>{text}</p>
              </Card>
            ) : null,
          )
        : null}
      <AsyncSelect
        className={classNames({
          'u-hidden':
            selectedCompany?.id ||
            selectedCompany?.skyminyr_id ||
            selectedCompany?.name,
        })}
        formatOption={formatOptions}
        inputValue={inputValue}
        isClearable={isClearable}
        isMulti={isMulti}
        menuListFooter={menuListFooter}
        onChange={handleOnChange}
        onInputChange={handleInputChange}
        placeholder='Enter company name'
        refreshOptionsToken={
          isFetchingGoldenRecords ? 'GoldenToken' : 'SimpleToken'
        }
        rules={{
          required: { value: true, message: 'Selecting a company is required' },
        }}
        url={searchTerm =>
          isFetchingGoldenRecords
            ? routes.api_v1_autocomplete_query_skyminyr(
                getCompanySearchQuery(searchTerm),
              )
            : routes.api_v1_autocomplete_query(
                getCompanySearchQuery(searchTerm),
              )
        }
        value={formattedValue}
      />
      {selectedCompany ? (
        <SkyminyrLegacyCompanyDetailsCard
          companyDetails={selectedCompany}
          onRemove={() => {
            onChange(null);
          }}
        />
      ) : null}
      {isCreateNewCompanyModalOpen ? (
        <NewCompanyFormModal
          companyName={inputValue}
          onClose={newCompanyId => {
            if (newCompanyId) {
              onChange(isMulti ? [...value, newCompanyId] : newCompanyId);
            }
            setInputValue('');
            closeCreateNewCompanyModal();
          }}
          queryDuplicates={true}
          shouldNavigateToNewCompany={false}
          show={isCreateNewCompanyModalOpen}
        />
      ) : null}
      {/* renders a button to create a new company */}
      {unsavedCompanyName ? (
        <SkyminyrCompanyCreateButton
          buttonLabel={`Create "${selectedCompany?.name}"`}
          buttonSize='small'
          canSelectThriveRecords={false}
          className='u-marginTop-4'
          initialInputValue={unsavedCompanyName}
          onCancel={() => {
            onCloseCreateCompanyModal?.();
          }}
          onClick={() => {
            onCreateCompanyClick?.();
          }}
          onCreate={newCompany => {
            if (isNumber(newCompany) || newCompany?.id) {
              const formattedCompany = isNumber(newCompany)
                ? newCompany
                : formatOptions(newCompany);
              onChange(
                isMulti ? [...value, formattedCompany] : formattedCompany,
              );
              onCloseCreateCompanyModal?.();
            }
          }}
          shouldNavigateToNewCompany={false}
        />
      ) : null}
    </>
  );
};

SkyminyrLegacyCompanySelect.defaultProps = {
  isClearable: true,
  isMulti: false,
  queryParams: null,
};

SkyminyrLegacyCompanySelect.propTypes = {
  getCompanyDetails: PropTypes.func,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onCloseCreateCompanyModal: PropTypes.func,
  onCreateCompanyClick: PropTypes.func,
  queryParams: PropTypes.shape({
    parentCompanyId: PropTypes.number,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
};

export default compose(
  setStatic('createFieldState', FieldState.create),
  setPropTypes(SkyminyrLegacyCompanySelect.propTypes),
  withFormGroup,
  asField(),
  connect(
    state => ({
      getCompanyDetails: companyId => getCompany(state, companyId),
    }),
    {},
  ),
)(SkyminyrLegacyCompanySelect);
