export const EMAIL_VALIDATION_REGEX = /.*@.*/;

export const EMAIL_VALIDATION_FAILURE_MESSAGE =
  'An email address should contain the "@" symbol';

export function isValidEmailAddress(email) {
  return EMAIL_VALIDATION_REGEX.test(email);
}

/**
 * Validates a single email address.
 */

export const emailAddress = fieldState => {
  const email = fieldState.getValue();
  if (email && !isValidEmailAddress(email)) {
    return EMAIL_VALIDATION_FAILURE_MESSAGE;
  }

  return null;
};

export default emailAddress;

/**
 * Validates a fieldState that contains an array of email addresses.
 */
export const emailAddresses = fieldState => {
  const emails = fieldState.getValue();
  const invalidEmails = emails.filter(email => !isValidEmailAddress(email));
  if (invalidEmails.length) {
    return `Invalid emails: ${invalidEmails.join(', ')}`;
  }
  return null;
};
