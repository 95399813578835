import combineImmutableReducers from '../../reducers/combineImmutableReducers';
import { NAME } from './constants';

import createListReducer from '../../reducers/createListReducer';
import {
  TASKS_INVALIDATE,
  TASKS_FETCH_START,
  TASKS_FETCH_SUCCESS,
  TASKS_FETCH_FAILURE,
  TASK_CREATE_SUCCESS,
  TASK_UPDATE_SUCCESS,
  TASK_DELETE_SUCCESS,
} from './actions/actionTypes';

const tasksReducer = combineImmutableReducers({
  all: createListReducer({
    resultKey: 'tasks',
    entityResultKey: 'task',
    invalidated: TASKS_INVALIDATE,
    request: TASKS_FETCH_START,
    success: TASKS_FETCH_SUCCESS,
    failure: TASKS_FETCH_FAILURE,
    created: TASK_CREATE_SUCCESS,
    updated: TASK_UPDATE_SUCCESS,
    deleted: TASK_DELETE_SUCCESS,
  }),
});

tasksReducer.NAME = NAME;

export default tasksReducer;
