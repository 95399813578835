import { fromJS } from 'immutable';

export const INITIAL_STATE = fromJS({
  meta: {
    isFetching: false,
    isInvalidated: false,
  },
});

/**
 * Creates a reducer function for handling fetching and invalidating of a payload.
 * @param {Object} options
 * @param {String} options.start The action that indicates a fetch of the latest data has started.
 * @param {String} options.failure The action that indicates a fetch of the latest data has
 * @param {String} options.success The action that indicates a fetch of the latest data has
 *   completed successfully. The `payload` property should contain a `data` property, which should
 *   be the data that should be stored in the new state returned by the reducer.
 *   failed. The `payload` property should contain an `error` property, which should
 *   be an Error object with the details of the failure.
 * @param {String} [options.invalidate] The action that should cause the state to be invalidated.
 * @param {String} [options.reset] The action that should cause the state to be completely reset
 * @param {String} [options.payloadDataKey='data'] The key on the payload that contains the
 *   resulting data. So by default, `action.payload.data` will be used.
 * @param {Function} [options.transformer=Immutable.fromJS] The function that converts the payload
 *   data (`action.payload.data`, by default, though `options.payloadDataKey` could change this)
 *   to the `data` object as it's represented by the resulting state returned by the reducer.
 * @param {Function} [options.updateData] If specified, this method will be called when a success
 *   action is dispatched with the existing data, and the incoming action, and should return
 *   the new data state.
 *   `updateData(currentData, action)` => newData
 * @returns {Function} a reducer funciton for managing data which is fetched from an external
 *   source.
 */
export default function createFetchReducer({
  start,
  failure,
  success,
  invalidate,
  reset,
  payloadDataKey = 'data',
  transformer,
  updateData,
  defaultReducer = state => state,
}) {
  const transformFunction = transformer || fromJS;

  return (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case reset: {
        return INITIAL_STATE;
      }
      case invalidate:
        return state.setIn(['meta', 'isInvalidated'], true);
      case start:
        return state.setIn(['meta', 'isFetching'], true);
      case failure:
        return state.withMutations(map => {
          map.setIn(['meta', 'isFetching'], false);
          map.setIn(['meta', 'error'], action.payload.error);
        });
      case success:
        return state.withMutations(map => {
          map.setIn(['meta', 'isFetching'], false);
          map.setIn(['meta', 'isInvalidated'], false);
          map.deleteIn(['meta', 'error']);

          if (updateData) {
            map.update('data', data => updateData(data, action));
          } else {
            map.set('data', transformFunction(action.payload[payloadDataKey]));
          }
        });
      default:
        return defaultReducer(state, action);
    }
  };
}
