import invalidateEntity from 'modules/entities/actions/invalidateEntity';
import { searchCandidacyStats } from '../../schema';

/**
 * Invalidates the candidacy stats for a search
 * @param {Object} payload
 * @param {Number} payload.searchId The search ID
 */
export default ({ searchId }) =>
  invalidateEntity({
    entityType: searchCandidacyStats.key,
    id: searchId,
  });
