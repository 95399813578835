import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import tenantOptions from './tenantOptions';

const tenantReducer = combineImmutableReducers({
  tenantOptions: tenantOptions,
});

tenantReducer.NAME = NAME;

export default tenantReducer;
