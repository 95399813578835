import getCandidacyProperty from './getCandidacyProperty';

/**
 * Gets the time since a candidate was last contacted.
 * @param {Object} state
 * @param {Number} id The candidacy ID
 * @return {String} The date/time of the most recent outreach record associated with a candidacy.
 */
export default (state, candidacyId) =>
  getCandidacyProperty(state, candidacyId, 'time_last_contacted');
