import { connect } from 'react-redux';
import getPositionEndDate from '../selectors/getPositionEndDate';
import getPositionStartDate from '../selectors/getPositionStartDate';

/**
 * A Higher order component that sorts a list of positionIds in reverse chronological order by
 * end date, then by start date.
 */
export default connect(
  (state, { positionIds }) => ({
    positionIds:
      positionIds &&
      positionIds
        .sortBy(positionId => [
          getPositionEndDate(state, positionId) || Number.POSITIVE_INFINITY,
          getPositionStartDate(state, positionId) || Number.POSITIVE_INFINITY,
        ])
        .reverse(),
  }),
  {},
);
