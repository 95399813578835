import updateEntity from 'modules/entities/actions/updateEntity';
import routes from 'modules/routing/routes';
import companySchema from '../companySchema';

/**
 * Creates an action for updating a logo record.
 * @param {Object} payload The action payload
 * @param {File} payload.logo The image file to use as the logo
 * @param {Number} payload.companyId The company ID to set the logo for.
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({ companyId, logo, transactionId }) => {
  // Binary data is submitted via FormData. This also requires us to set contentType to false
  // so that it will get automatically generated with the correct boundary information when the
  // file is encoded and sent to the server.
  const body = new FormData();
  body.append('company[logo]', logo);

  return updateEntity({
    companyId: companyId,
    transactionId: transactionId,

    entityType: companySchema.key,
    responseSchema: { company: companySchema },

    url: routes.api_v1_company({ id: companyId }),
    body: body,

    // Let content-type header be generated automatically so it contains the correct
    // multi-part boundary ID
    contentType: false,
  });
};
