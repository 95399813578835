import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  withHandlers,
  setPropTypes,
  setStatic,
  setDisplayName,
} from 'recompose';
import classnames from 'classnames';

/**
 * Renders a single toolbar button for the RichTextArea's Toolbar.
 */
const ToolbarButton = ({
  active,
  btnClass,
  disabled,
  handleClick,
  icon,
  title,
}) => (
  <button
    className={classnames('btn', btnClass, {
      active: active,
    })}
    disabled={disabled}
    onMouseDown={handleClick}
    title={title}
    type='button'
  >
    {icon ? <i className={classnames('fa', icon)} /> : title}
  </button>
);

ToolbarButton.propTypes = {
  /**
   * True to set the button as "active" (in a pressed state)
   */
  active: PropTypes.bool,

  /**
   * The class to apply to the button comonent.
   * @type {[type]}
   */
  btnClass: PropTypes.string,

  /**
   * True to disable the button
   */
  disabled: PropTypes.bool,

  /**
   * Called when the toolbar button is clicked.
   */
  handleClick: PropTypes.func.isRequired,

  /**
   * The Font Awesome icon class to use for the button title. If not set, the `title` itself
   * will be used.
   */
  icon: PropTypes.string,

  /**
   * The text title for the button. Used in the title attribute of the button and displayed
   * as the button label itself when an icon is not provided.
   */
  title: PropTypes.string.isRequired,
};

ToolbarButton.defaultProps = {
  btnClass: 'btn-link',
};

export default compose(
  setStatic('defaultProps', ToolbarButton.defaultProps),
  setDisplayName('ToolbarButton(enhanced)'),
  setPropTypes({
    /**
     * Called when the button is clicked. The first parameter of the callback is the `value`
     * property.
     */
    onClick: PropTypes.func.isRequired,
    /**
     * A value that is passed along in the `onClick` callback.
     */
    value: PropTypes.string,
  }),
  withHandlers({
    handleClick: ({ onClick, value }) => event => {
      event.preventDefault();
      onClick(value);
    },
  }),
)(ToolbarButton);
