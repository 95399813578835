import { connect } from 'react-redux';
import * as sortUtils from 'modules/core/sortUtils';
import getCandidacyRank from '../selectors/getCandidacyRank';
import getCandidacySortStage from '../selectors/getCandidacySortStage';

/**
 * A Higher order component that sorts a list of candidacyIds in order of their stage, based on the
 * ordinal sort value of the stage.
 */
export default connect(
  (state, { candidacyIds }) => ({
    candidacyIds:
      candidacyIds &&
      candidacyIds.sortBy(
        id => [getCandidacySortStage(state, id), getCandidacyRank(state, id)],
        sortUtils.arrayComparator,
      ),
  }),
  {},
);
