import selectn from 'selectn';
import { Map } from 'immutable';
import createListReducer from '../../../reducers/createListReducer';
import composeReducers from '../../../reducers/composeReducers';
import {
  RECOMMENDATIONS_LIST_FETCH_START,
  RECOMMENDATIONS_LIST_FETCH_SUCCESS,
  RECOMMENDATIONS_LIST_FETCH_FAILURE,
  RECOMMENDATIONS_LIST_INVALIDATE,
  RECOMMENDED_FETCH_START,
  RECOMMENDED_FETCH_SUCCESS,
  RECOMMENDED_FETCH_FAILURE,
  RECOMMENDED_INVALIDATE,
} from '../actions/actionTypes';
import {
  MOVE_TO_CANDIDATES,
  NOT_A_FIT_LABEL,
  NOT_A_FIT,
  RECOMMEND_MORE,
  RECOMMEND,
  RECOMMENDED_LABEL,
} from '../constants';

const recommendationsListReducer = createListReducer({
  resultKey: 'recommended_candidacies',
  request: RECOMMENDATIONS_LIST_FETCH_START,
  success: RECOMMENDATIONS_LIST_FETCH_SUCCESS,
  failure: RECOMMENDATIONS_LIST_FETCH_FAILURE,
  invalidated: RECOMMENDATIONS_LIST_INVALIDATE,
});

const recommendedListReducer = createListReducer({
  resultKey: 'recommended_candidacies',
  request: RECOMMENDED_FETCH_START,
  success: RECOMMENDED_FETCH_SUCCESS,
  failure: RECOMMENDED_FETCH_FAILURE,
  invalidated: RECOMMENDED_INVALIDATE,
});

export default composeReducers(
  (state = new Map(), action) => {
    switch (action.type) {
      case RECOMMENDATIONS_LIST_FETCH_START:
      case RECOMMENDATIONS_LIST_FETCH_SUCCESS:
      case RECOMMENDATIONS_LIST_FETCH_FAILURE: {
        const searchId = selectn('payload.searchId', action);
        if (searchId) {
          return state.update(searchId, list => {
            return recommendationsListReducer(list, action);
          });
        }

        return state;
      }
      case RECOMMENDATIONS_LIST_INVALIDATE: {
        const searchId = selectn('payload.searchId', action);

        if (searchId && state.has(searchId)) {
          return state.setIn([searchId, '_meta', 'isInvalidated'], true);
        }

        return state;
      }
      case RECOMMENDED_FETCH_START: {
        const { searchId } = action;
        if (searchId) {
          return state
            .update(searchId, list => {
              return recommendedListReducer(list, action);
            })
            .setIn([action.searchId, 'shouldCallRecommendAPI'], false);
        }

        return state;
      }
      case RECOMMENDED_FETCH_SUCCESS:
      case RECOMMENDED_FETCH_FAILURE: {
        const { searchId } = action;
        if (searchId) {
          return state.update(searchId, list => {
            return recommendedListReducer(list, action);
          });
        }

        return state;
      }
      case RECOMMENDED_INVALIDATE: {
        const searchId = selectn('payload.searchId', action);

        if (searchId && state.has(searchId)) {
          return state.setIn([searchId, '_meta', 'isInvalidated'], true);
        }

        return state;
      }

      default: {
        return state.map(listState =>
          recommendationsListReducer(listState, action),
        );
      }
    }
  },

  (state, action) => {
    if (action.type === MOVE_TO_CANDIDATES) {
      const { contactId, searchId } = action;
      const candidacies = [...state.getIn([searchId, 'results'])];
      const filteredCandidacies = candidacies.filter(
        candidate => candidate.id !== contactId,
      );
      return state.setIn([searchId, 'results'], filteredCandidacies);
    }
    if (action.type === NOT_A_FIT) {
      const { contactId } = action;
      const notafitSearchId = action.searchId;
      const candidacies = [...state.getIn([notafitSearchId, 'results'])];
      candidacies.map(candidate => {
        if (candidate.id === contactId) {
          candidate.stage = NOT_A_FIT_LABEL;
        }
        return candidate;
      });
      return state.setIn([notafitSearchId, 'results'], candidacies);
    }
    if (action.type === RECOMMEND) {
      const { contactId } = action;
      const notafitSearchId = action.searchId;
      const candidacies = [...state.getIn([notafitSearchId, 'results'])];
      candidacies.map(candidate => {
        if (candidate.id === contactId) {
          candidate.stage = RECOMMENDED_LABEL;
        }
        return candidate;
      });
      return state.setIn([notafitSearchId, 'results'], candidacies);
    }
    if (action.type === RECOMMEND_MORE) {
      const recommendSearchId = action.searchId;
      return state.setIn([recommendSearchId, 'shouldCallRecommendAPI'], true);
    }
    if (action.type === RECOMMENDATIONS_LIST_FETCH_SUCCESS) {
      const searchId = selectn('payload.searchId', action);
      let isFetching = true;
      const resultCandidacies = selectn(
        'payload.result.recommended_candidacies',
        action,
      );
      let shouldCallRecommendAPI = state.getIn([
        searchId,
        'shouldCallRecommendAPI',
      ]);
      if (!shouldCallRecommendAPI && resultCandidacies.length === 0) {
        shouldCallRecommendAPI = true;
      } else {
        shouldCallRecommendAPI = false;
        isFetching = false;
      }

      if (searchId) {
        return state
          .setIn([searchId, 'results'], resultCandidacies)
          .setIn([searchId, 'shouldCallRecommendAPI'], shouldCallRecommendAPI)
          .setIn([searchId, '_meta', 'isFetching'], isFetching);
      }
    }
    if (action.type === RECOMMENDED_FETCH_START) {
      return state.setIn([action.searchId, '_meta', 'isFetching'], true);
    }

    if (action.type === RECOMMENDED_FETCH_SUCCESS) {
      const { response } = action;
      return state
        .setIn([action.searchId, 'results'], response.recommended_candidacies)
        .setIn([action.searchId, '_meta', 'isFetching'], false)
        .setIn([action.searchId, 'shouldCallRecommendAPI'], false);
    }

    return state;
  },
);
