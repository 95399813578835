import { createSlice } from '@reduxjs/toolkit';
import routes from 'modules/routing/routes';
import Api, { getErrorMessage } from 'modules/core/Api';

const initialState = {
  isCreatingSearch: false,
  isCreatingSearchComplete: false,
  createSearchError: null,
  search: null,
  searchId: null,
};

const searchSlice = createSlice({
  name: 'search',
  initialState: initialState,
  reducers: {
    createSearchBegin: state => {
      state.isCreatingSearchComplete = false;
      state.isCreatingSearch = true;
      state.createSearchError = null;
    },
    createSearchSuccess: (state, action) => {
      state.isCreatingSearch = false;
      state.isCreatingSearchComplete = true;
      state.searchId = action.payload.id;
      state.search = action.payload;
    },
    createSearchError: (state, action) => {
      state.isCreatingSearch = false;
      state.createSearchError = action.payload;
    },
    resetCreateSearch: state => {
      state.isCreatingSearchComplete = false;
      state.createSearchError = null;
    },
  },
});

const {
  createSearchBegin,
  createSearchError,
  createSearchSuccess,
  resetCreateSearch,
} = searchSlice.actions;

const createSearch = payload => dispatch => {
  dispatch(createSearchBegin());
  const endpointUrl = routes.api_v1_searches();
  Api.post(endpointUrl, payload)
    .then(data => {
      dispatch(createSearchSuccess(data));
    })
    .catch(error => {
      dispatch(createSearchError(getErrorMessage(error)));
    });
};

export { createSearch, resetCreateSearch };
export default searchSlice;
