import { connect } from 'react-redux';
import getEducation from '../../selectors/educations/getEducation';

/**
 * A higher order component which maps a `educationId` prop value to it's education record.
 */
export default connect(
  (state, { educationId }) => ({
    education: getEducation(state, educationId),
  }),
  {},
);
