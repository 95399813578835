//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'introductions';

//---------------------------------------------------------
// Parent types
//---------------------------------------------------------

export const PARENT_SEARCH = 'search';
export const PARENT_CONTACT = 'contact';
export const PARENT_COMPANY = 'company';

export const PARENT_TYPES = [PARENT_SEARCH, PARENT_CONTACT, PARENT_COMPANY];
