import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';
import Modal from '@thrivetrm/ui/components/Modal';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import {
  useAddImageMutation,
  useEditImageMutation,
} from 'services/apiV1/emailSignature';
import { addEmailSignature } from 'modules/emailSignature/action/emailSignatureAction';
import { toastError } from 'modules/toast-notifications/toastNotificationsSlice';
import { toHtml } from './convert';

const srcRegex = /src="[^"]*"/;

const ImageUploadModal = ({ editorState, onClose, onConfirm }) => {
  const [addImage, { isLoading: isAddImage }] = useAddImageMutation();
  const [editImage, { isLoading: isEditingImage }] = useEditImageMutation();
  const [imageFile, setImageFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const emailSignature = useSelector(state =>
    state.user.get('email_signature'),
  );
  const currentEmailSignature = useSelector(
    state => state.currentEmailSignature?.emailSignature,
  );
  const dispatch = useDispatch();

  const handleConfirm = () => {
    if (emailSignature || currentEmailSignature) {
      editImage({
        contactId: emailSignature?.id || currentEmailSignature?.id,
        payload: imageFile,
      })
        .unwrap()
        .then(data => {
          dispatch(addEmailSignature(data));
          const srcExists = srcRegex.test(
            toHtml(editorState.getCurrentContent()),
          );
          if (srcExists) {
            // Sending true because we need to replace the existing image
            onConfirm(data?.image?.standard?.url, true);
          } else {
            onConfirm(data?.image?.standard?.url);
          }
        })
        .catch(data => {
          dispatch(
            toastError(data.data?.errors?.image[0] || 'Something went wrong'),
          );
        });
    } else {
      addImage({
        payload: imageFile,
      })
        .unwrap()
        .then(data => {
          dispatch(addEmailSignature(data?.email_signature || data));
          onConfirm(data?.image?.standard?.url);
        })
        .catch(data => {
          dispatch(
            toastError(data.data?.errors?.image[0] || 'Something went wrong'),
          );
        });
    }
  };

  const handleDrop = files => {
    const droppedFile = files[0];
    if (droppedFile) {
      setPreview(URL.createObjectURL(droppedFile));
      setImageFile(droppedFile);
    } else {
      dispatch(
        toastError(
          "Please upload image with file types 'jpg, jpeg, gif, png' only.",
        ),
      );
    }
  };

  const handleRemove = event => {
    event.stopPropagation();
    setPreview(null);
    setImageFile(null);
  };

  return (
    <Modal isOpen={true} onClose={onClose} size='small' title='Add an image'>
      <Modal.Body>
        <Dropzone
          accept='image/jpeg, image/jpg, image/png, image/gif'
          activeClassName='contact-file-uploader-dropzone-active'
          className='contact-file-uploader-dropzone'
          multiple={false}
          onDrop={handleDrop}
        >
          {preview ? (
            <div>
              <div className='u-paddingBottom-2'>
                <span>Photo Preview</span>
              </div>
              <div className='email-signature'>
                <img
                  alt='email signature'
                  aria-hidden='true'
                  className='image-size'
                  role='presentation'
                  src={preview}
                />
              </div>
              <button
                className='btn btn-link contact-avatar-remove-link'
                onClick={handleRemove}
                type='button'
              >
                Remove
              </button>
            </div>
          ) : (
            <div className='u-paddingVertical-64'>
              <span className='btn btn-link'>Choose a file</span>
              <span> or drag / drop image here.</span>
            </div>
          )}
        </Dropzone>
      </Modal.Body>
      <Modal.Footer>
        <ButtonPrimary
          isDisabled={!imageFile || isEditingImage || isAddImage}
          isLoading={isEditingImage || isAddImage}
          label='Save'
          onClick={handleConfirm}
        />
        <ButtonSecondary
          isDisabled={isEditingImage || isAddImage}
          label='Cancel'
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
};

ImageUploadModal.propTypes = {
  editorState: PropTypes.shape({
    getCurrentContent: PropTypes.func,
  }),
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};
export default ImageUploadModal;
