import getEntity from 'modules/entities/selectors/getEntity';
import companySchema from '../companySchema';

/**
 * Gets a company record from it's ID
 * @param {*} state
 * @param {Number} id The ID of the company
 * @returns {?Immutable.Map} The Company record, if found in state.
 */
export default (state, id) => getEntity(state, companySchema.key, id);
