import { NAME } from '../../constants';

/**
 * Gets the list state of the target companies for a search.
 * @param {Object} state
 * @param {Number} searchId The search ID
 * @return {Immutable.Map} The list state of the target companies for the given search ID.
 */
export default (state, searchId) =>
  state[NAME].getIn(['targetCompaniesBySearch', searchId]);
