export const TASK_DELETED = 'tasks/TASK_DELETED';
export const TASK_CREATED = 'tasks/TASK_CREATED';

export const TASKS_INVALIDATE = 'TASKS_INVALIDATE';
export const TASKS_FETCH_START = 'TASKS_FETCH_START';
export const TASKS_FETCH_SUCCESS = 'TASKS_FETCH_SUCCESS';
export const TASKS_FETCH_FAILURE = 'TASKS_FETCH_FAILURE';

export const TASK_CREATE_START = 'TASK_CREATE_START';
export const TASK_CREATE_SUCCESS = 'TASK_CREATE_SUCCESS';
export const TASK_CREATE_FAILURE = 'TASK_CREATE_FAILURE';

export const TASK_UPDATE_START = 'TASK_UPDATE_START';
export const TASK_UPDATE_SUCCESS = 'TASK_UPDATE_SUCCESS';
export const TASK_UPDATE_FAILURE = 'TASK_UPDATE_FAILURE';

export const TASK_DELETE_START = 'TASK_DELETE_START';
export const TASK_DELETE_SUCCESS = 'TASK_DELETE_SUCCESS';
export const TASK_DELETE_FAILURE = 'TASK_DELETE_FAILURE';
