import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';

import fetchEducationsIfNeeded from './fetchEducationsIfNeeded';
import mapContactIdToEducations from './mapContactIdToEducations';
import EducationList from './EducationList';
import EducationForm from './EducationForm';

import withFormToggleButton from '../../../../components/forms/withFormToggleButton';

const AddEducationFormToggleButton = withFormToggleButton(EducationForm);

/**
 * Renders a list of educations for a particular contact.
 */
export const ContactEducations = ({
  allowCreate,
  contactId,
  educations,
  renderIfNoData,
  title,
  ...props
}) => {
  if (
    !renderIfNoData &&
    (!educations || !educations.get('ids') || educations.get('ids').size < 1)
  ) {
    return null;
  }

  return (
    <div className='ContactEducations'>
      {title}
      {allowCreate && (
        <AddEducationFormToggleButton
          // passing an empty string to override default value set by withFormToggleButton
          btnClassName=''
          btnIcon='add'
          btnLabel='New Education'
          className='u-flex u-flexJustify-r'
          contactId={contactId}
          shouldRenderV4Button={true}
        />
      )}
      {educations && (
        <EducationList {...props} educationIds={educations.get('ids')} />
      )}
      {educations && educations.getIn(['_meta', 'isFetching']) && (
        <LoadingIndicator />
      )}
    </div>
  );
};

ContactEducations.propTypes = {
  /**
   * True to allow education records to be created, otherwise false.
   */
  allowCreate: PropTypes.bool,

  /**
   * The ID of the contact the educations belong to.
   */
  contactId: PropTypes.number.isRequired,

  /**
   * The list of educations to render.
   */
  educations: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isFetching: PropTypes.bool,
    }),
    ids: ImmutablePropTypes.listOf(PropTypes.number),
  }),

  /**
   * Normally the component will not render if there are no underlying education records to
   * render - setting this to false will render them refardless.
   */
  renderIfNoData: PropTypes.bool,

  /**
   * This component is used on the search page and contact profile page.  We only
   * want to wrap the PositionList in a Card if it's on the search page
   */
  renderInsideACard: PropTypes.bool,

  /**
   * An optional title to display.
   */
  title: PropTypes.node,
};

ContactEducations.defaultProps = {
  allowCreate: true,
  renderIfNoData: true,
};

export default compose(
  setDisplayName('connect(ContactEducations)'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  fetchEducationsIfNeeded,
  mapContactIdToEducations,
)(ContactEducations);
