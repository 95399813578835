import { List, Map } from 'immutable';
import {
  TENANT_OPTIONS_FETCH_START,
  TENANT_OPTIONS_FETCH_FAILURE,
} from 'modules/tenant/actions/actionTypes';
import { PORTFOLIO_COMPANY_LIST_INITIALIZE } from '../actions/actionTypes';

/**
 * Maintains the state for th elist of company investment stages.
 * These are currently fetched as part of the tenant options
 * request.We don't turn "isFetching" off after a TENANT_OPTIONS_FETCH_FAILURE
 * because our actor will use that to trigger COMPANY_INVESTMENT_STAGE_LIST_INITIALIZE,
 * and if we set it to false too early before we fill the ids, that could
 * potentially cause another component to refetch the options (because
 * shouldFetchTenantOptions will momentarily return true.)
 */
const portfolioCompaniesReducer = (state = new Map(), action) => {
  switch (action.type) {
    case TENANT_OPTIONS_FETCH_START: {
      return state.setIn(['meta', 'isFetching'], true);
    }
    case TENANT_OPTIONS_FETCH_FAILURE: {
      return state
        .setIn(['meta', 'isFetching'], false)
        .setIn(['meta', 'error'], action.payload.error);
    }
    case PORTFOLIO_COMPANY_LIST_INITIALIZE: {
      return state
        .setIn(['meta', 'isFetching'], false)
        .deleteIn(['meta', 'error'])
        .set('ids', new List(action.payload.result));
    }
    default: {
      return state;
    }
  }
};

export default portfolioCompaniesReducer;
