import { fromJS } from 'immutable';
import normalizeContact from '../contacts/normalizeContact';
import { INITIAL_STATE as INITIAL_CONTACT_STATE } from '../contacts/contact';
import createCollectionReducer from '../createCollectionReducer';
import {
  CONTACT_REVIEW_FETCH_START,
  CONTACT_REVIEW_FETCH_SUCCESS,
  CONTACT_REVIEW_FETCH_FAILURE,
} from '../../actions/contactReviews/constants';

const transformContact = contact =>
  INITIAL_CONTACT_STATE.mergeIn(['data'], normalizeContact(contact)).setIn(
    ['meta', 'isContactReview'],
    true,
  );

const transformData = ({ applied, ...data }) =>
  fromJS(data).set('applied', applied && transformContact(applied));

export default createCollectionReducer({
  collection: {
    collectionFromPayload: ({ entities }) => {
      if (entities && entities.contactReviews) {
        return Object.keys(entities.contactReviews).map(
          key => entities.contactReviews[key],
        );
      }

      return [];
    },
    payloadDataKey: 'contact_reviews',
  },

  item: {
    transformData: transformData,

    itemFromPayload: function (payload) {
      if (
        payload.result &&
        payload.entities &&
        payload.entities.contactReviews
      ) {
        return payload.entities.contactReviews[payload.result];
      }

      return payload.contactReview;
    },

    fetchStart: CONTACT_REVIEW_FETCH_START,
    fetchSuccess: CONTACT_REVIEW_FETCH_SUCCESS,
    fetchFailure: CONTACT_REVIEW_FETCH_FAILURE,
  },
});
