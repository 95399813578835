import { NAME } from '../constants';

export const QUERY_CREATE = `${NAME}/QUERY_CREATE`;
export const QUERY_DESTROY = `${NAME}/QUERY_DESTROY`;
export const QUERY_FETCH_START = `${NAME}/QUERY_FETCH_START`;
export const QUERY_FETCH_SUCCESS = `${NAME}/QUERY_FETCH_SUCCESS`;
export const QUERY_FETCH_FAILURE = `${NAME}/QUERY_FETCH_FAILURE`;
export const QUERY_INVALIDATE = `${NAME}/QUERY_INVALIDATE`;

export const NETWORKS_FETCH_START = `${NAME}/NETWORKS_FETCH_START`;
export const NETWORKS_FETCH_SUCCESS = `${NAME}/NETWORKS_FETCH_SUCCESS`;
export const NETWORKS_FETCH_FAILURE = `${NAME}/NETWORKS_FETCH_FAILURE`;
export const NETWORKS_INVALIDATE = `${NAME}/NETWORKS_INVALIDATE`;

export const NETWORK_FETCH_START = `${NAME}/NETWORK_FETCH_START`;
export const NETWORK_FETCH_SUCCESS = `${NAME}/NETWORK_FETCH_SUCCESS`;
export const NETWORK_FETCH_FAILURE = `${NAME}/NETWORK_FETCH_FAILURE`;
export const NETWORK_INVALIDATE = `${NAME}/NETWORK_INVALIDATE`;

export const NETWORK_CREATE_START = `${NAME}/NETWORK_CREATE_START`;
export const NETWORK_CREATE_SUCCESS = `${NAME}/NETWORK_CREATE_SUCCESS`;
export const NETWORK_CREATE_FAILURE = `${NAME}/NETWORK_CREATE_FAILURE`;

export const NETWORK_UPDATE_START = `${NAME}/NETWORK_UPDATE_START`;
export const NETWORK_UPDATE_SUCCESS = `${NAME}/NETWORK_UPDATE_SUCCESS`;
export const NETWORK_UPDATE_FAILURE = `${NAME}/NETWORK_UPDATE_FAILURE`;

export const NETWORK_DELETE_START = `${NAME}/NETWORK_DELETE_START`;
export const NETWORK_DELETE_SUCCESS = `${NAME}/NETWORK_DELETE_SUCCESS`;
export const NETWORK_DELETE_FAILURE = `${NAME}/NETWORK_DELETE_FAILURE`;

export const CONTACTS_FETCH_START = `${NAME}/CONTACTS_FETCH_START`;
export const CONTACTS_FETCH_SUCCESS = `${NAME}/CONTACTS_FETCH_SUCCESS`;
export const CONTACTS_FETCH_FAILURE = `${NAME}/CONTACTS_FETCH_FAILURE`;

export const CONTACTS_INVALIDATE = `${NAME}/CONTACTS_INVALIDATE`;

export const CONTACT_ADD_START = `${NAME}/CONTACT_ADD_START`;
export const CONTACT_ADD_SUCCESS = `${NAME}/CONTACT_ADD_SUCCESS`;
export const CONTACT_ADD_FAILURE = `${NAME}/CONTACT_ADD_FAILURE`;

export const CONTACT_REMOVE_START = `${NAME}/CONTACT_REMOVE_START`;
export const CONTACT_REMOVE_SUCCESS = `${NAME}/CONTACT_REMOVE_SUCCESS`;
export const CONTACT_REMOVE_FAILURE = `${NAME}/CONTACT_REMOVE_FAILURE`;
