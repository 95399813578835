import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import { compose, onlyUpdateForPropTypes, setDisplayName } from 'recompose';
import { VOTE_TYPE_PRIORITY, VOTE_TYPE_NOT_A_FIT } from '../constants';

const ICONS = {
  [VOTE_TYPE_PRIORITY]: 'fa-thumbs-up',
  [VOTE_TYPE_NOT_A_FIT]: 'fa-thumbs-down',
};

/**
 * Renders the title text for a comment based on the type of comment and
 * it's data.
 */
const CommentTitle = ({ comment }) => {
  const text = comment.get('formatted_title');
  const withThumb = !comment.get('candidacy_id');
  const voteType = comment.get('vote_type');

  return (
    <span className='CommentTitle title'>
      {text}
      {withThumb && voteType && (
        <i className={classnames('fa', ICONS[voteType])} />
      )}
    </span>
  );
};

CommentTitle.propTypes = {
  /**
   * The comment to render the title for.
   */
  comment: ImmutablePropTypes.mapContains({
    candidacy_id: PropTypes.number,
    formatted_title: PropTypes.string,
    vote_type: PropTypes.oneOf([VOTE_TYPE_PRIORITY, VOTE_TYPE_NOT_A_FIT]),
  }).isRequired,
};

export default compose(
  setDisplayName('CommentTitle(enhanced)'),
  onlyUpdateForPropTypes,
)(CommentTitle);
