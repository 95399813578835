import { NAME } from '../constants';

/**
 * Returns a value indicating whether the tenant options have been fetched.
 * @param {Object} state
 * @returns {Boolean} True if the tenant options have been fetched from the server; otherwise,
 *   false.
 */
export default state =>
  Boolean(state[NAME].getIn(['tenantOptions', 'meta', 'lastFetched']));
