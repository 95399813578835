import { NAME } from '../../constants';

/**
 * Gets the name for a contact
 * @param {Object} state
 * @param {Number} id The contact ID
 * @returns {String} The full name for the contact
 */
const getName = (state, id) =>
  state[NAME].getIn(['byId', id, 'name']) ||
  state[NAME].getIn(['byId', id, 'full_name']);

export default getName;
