import { USER_STATE_KEY } from '../constants';

/**
 * Determines if the current user is an CRM user.
 * @param {*} state
 * @returns {Boolean} true if the current user is a "CRM"; otherwise, false
 */
const isCrmUser = state => state[USER_STATE_KEY].get('crm_user', false);

export default isCrmUser;
