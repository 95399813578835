/**
 * A higher-order function to apply a default `method` value to `fetchAction` options
 * Sets the `method` property to the specified method by default.
 * @param {String} method The default method to use.
 * @return {Function} A function that sets a default `method` value.
 *
 * @example
 * ```js
 * const myPostAction = compose(
 *   fetchAction,
 *   defaultMethod('POST'),
 * );
 *
 * dispatch(myPostAction({ id: 1, body: JSON.stringofy({ name: 'Joe' }) }));
 *
 * // Can still be overriden! Use `withOptions({ method: method.POST })` to prevent
 * dispatch(myPostAction({ method: 'GET' }));
 * // a 'GET' request is made.
 * ```
 */
export default method => options => ({
  method: method,
  ...options,
});
