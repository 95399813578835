import selectn from 'selectn';
import { Map } from 'immutable';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import createListReducer from '../../../reducers/createListReducer';
import {
  OUTREACH_LIST_FETCH_START,
  OUTREACH_LIST_FETCH_SUCCESS,
  OUTREACH_LIST_FETCH_FAILURE,
} from '../actions/outreaches/actionTypes';
import { LIST_RECENT } from '../constants';
import { outreachSchema } from '../schema';

const recentOutreachListReducer = createListReducer({
  resultKey: 'outreaches',
  entityResultKey: 'outreach',
  request: OUTREACH_LIST_FETCH_START,
  success: OUTREACH_LIST_FETCH_SUCCESS,
  failure: OUTREACH_LIST_FETCH_FAILURE,
  created: entityActionTypes.CREATE_SUCCESS,
  updated: entityActionTypes.UPDATE_SUCCESS,
  deleted: entityActionTypes.DELETE_SUCCESS,
  invalidateOnCreated: true,
  invalidateOnUpdated: true,
  invalidateOnDeleted: true,
});

// similar to `allOutreachesByParentType`, but includes only outreaches
// that have been fetched for the listType LIST_RECENT.
// The primary difference is that when an outreach is created, updated, or deleted,
// these lists simply become invalidate (signaling a server refresh) instead of trying
// to determine if the item needs to be added or removed from the underlying list.
// It's really impossible to know in all cases because changes to any related outreach
// could cause an item to either get added or completely bumped out of the "recent"
// list -- and we really can't determine that without knowing about ALL outreaches,
// so it's much simpler to just invalidate the list and refetch it at that point.
// Note that the server currently only supports this when the collection is for a `Search` parent,
// so this, currently, should never contain a recent list of outreaches for a Contact.
export default (state = new Map(), action) => {
  switch (action.type) {
    case OUTREACH_LIST_FETCH_START:
    case OUTREACH_LIST_FETCH_SUCCESS:
    case OUTREACH_LIST_FETCH_FAILURE: {
      const parentType = selectn('payload.parentType', action);
      const parentId = selectn('payload.parentId', action);
      const listType = selectn('payload.listType', action);

      if (parentType && parentId && listType === LIST_RECENT) {
        return state.updateIn([parentType, parentId], list =>
          recentOutreachListReducer(list, action),
        );
      }

      return state;
    }
    case entityActionTypes.CREATE_SUCCESS:
    case entityActionTypes.UPDATE_SUCCESS:
    case entityActionTypes.DELETE_SUCCESS: {
      if (selectn('payload.entityType', action) !== outreachSchema.key) {
        return state;
      }

      return state.map(
        parentTypeState =>
          parentTypeState &&
          parentTypeState.map(listState =>
            recentOutreachListReducer(listState, action),
          ),
      );
    }
    default: {
      return state.map(
        parentTypeState =>
          parentTypeState &&
          parentTypeState.map(listState =>
            recentOutreachListReducer(listState, action),
          ),
      );
    }
  }
};
