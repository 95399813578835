/**
 * @private
 * Cached CSRF token
 */
let CSRF_TOKEN;

/**
 * Gets the current CSRF token that must be sent with non-GET ajax requests
 * in the X-CSRF-Token header.
 * @param  {Boolean} [reload=false] If true, the token will be re-read from the DOM
 *   even if we've previously cached it.
 * @return {String} The CSRF token.
 */
export default function getCsrfToken(reload) {
  // Currently the token is sent down in a meta tag in the head of our
  // documents, i.e.:
  //
  // ```
  // <html>
  //   <head>
  //     <meta name="csrf-token" content="token_found_here" />
  //     ...
  //   </head>
  //   ...
  // </html>
  // ```
  if (!CSRF_TOKEN || reload) {
    const metaTags = document.getElementsByTagName('meta');
    for (let i = 0; i < metaTags.length; i += 1) {
      if (metaTags[i].getAttribute('name') === 'csrf-token') {
        CSRF_TOKEN = metaTags[i].getAttribute('content');
        break;
      }
    }
  }

  return CSRF_TOKEN;
}
