import createListReducer from '../../../reducers/createListReducer';
import {
  RATING_DEFINITIONS_LIST_FETCH_START,
  RATING_DEFINITIONS_LIST_FETCH_SUCCESS,
  RATING_DEFINITIONS_LIST_FETCH_FAILURE,
} from '../actions/ratings/actionTypes';

/**
 * A reducer that maintains a list of all rating descriptions
 * for the current tenant.
 */
export default createListReducer({
  resultKey: 'rating_definitions',
  request: RATING_DEFINITIONS_LIST_FETCH_START,
  success: RATING_DEFINITIONS_LIST_FETCH_SUCCESS,
  failure: RATING_DEFINITIONS_LIST_FETCH_FAILURE,
});
