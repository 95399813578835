export const NAME = 'talentPools';

export const CANDIDACY_STAGES_TYPE = 'TalentPoolStage';

export const DASHBOARD_VIEW_TABLE = 'index';
export const DASHBOARD_VIEW_CARDS = 'orgchart';
export const DASHBOARD_VIEW_DEFAULT = DASHBOARD_VIEW_CARDS;

export const DASHBOARD_VIEW_TYPES = [
  DASHBOARD_VIEW_TABLE,
  DASHBOARD_VIEW_CARDS,
];

export const DASHBOARD_VIEW_LABELS = {
  [DASHBOARD_VIEW_TABLE]: 'View List',
  [DASHBOARD_VIEW_CARDS]: 'View Cards',
};
export const DASHBOARD_VIEW_ICONS = {
  [DASHBOARD_VIEW_TABLE]: 'fa-bars',
  [DASHBOARD_VIEW_CARDS]: 'fa-th-large',
};

export const SORT_BY_CANDIDACY_COUNT = 'candidacies_count';
export const SORT_BY_COMPANY = 'company';
export const SORT_BY_INCUMBENT_NAME = 'incumbent_name';
export const SORT_BY_JOB_FUNCTION = 'job_function';
export const SORT_BY_JOB_TITLE = 'name';

export const SORT_BY_LABELS = {
  [SORT_BY_COMPANY]: 'Company',
  [SORT_BY_JOB_TITLE]: 'Position/Talent Pool Name',
  [SORT_BY_INCUMBENT_NAME]: 'Incumbent',
  [SORT_BY_JOB_FUNCTION]: 'Function',
  [SORT_BY_CANDIDACY_COUNT]: 'Pool Count',
};

export const SORT_BY_OPTIONS = Object.keys(SORT_BY_LABELS);
