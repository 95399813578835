import selectn from 'selectn';
import { Map } from 'immutable';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import createListReducer from '../../../reducers/createListReducer';
import {
  OUTREACH_LIST_FETCH_START,
  OUTREACH_LIST_FETCH_SUCCESS,
  OUTREACH_LIST_FETCH_FAILURE,
} from '../actions/outreaches/actionTypes';
import { PARENT_SEARCH, PARENT_CONTACT, LIST_ALL } from '../constants';
import { outreachSchema } from '../schema';

const outreachListReducer = createListReducer({
  resultKey: 'outreaches',
  entityResultKey: 'outreach',
  request: OUTREACH_LIST_FETCH_START,
  success: OUTREACH_LIST_FETCH_SUCCESS,
  failure: OUTREACH_LIST_FETCH_FAILURE,
  created: entityActionTypes.CREATE_SUCCESS,
  updated: entityActionTypes.UPDATE_SUCCESS,
  deleted: entityActionTypes.DELETE_SUCCESS,
});

// Outreaches are extremely similar to notes in that they belong to a "parent" collection.
// But there's an important distinction: notes are polymorphic and have `notable_type` and
// `notable_id` indicating the parent. Outreaches, however, are related directly to a contact
// via a `contact_id` value ALWAYS, and additionally may have zero or more search relationships via
// it's `searches` (or `search_ids`) property. So an outreach can actually have multiple parents
// (which is not the case with a note). So to determine if an outreach belongs to a specific
// search, we can't use the parent type the way we do with notes -- we have to check to see if
// the search is included in the outeaches `search_ids`.

// allOutreachesByParentType is keyed on the parent type (PARENT_SEARCH or PARENT_CONTACT),
// then by that parent's ID (a contact ID for PARENT_CONTACT or a search ID for PARENT_SEARCH).
// So the outreaches for a search with ID 42 would be at `allOutreachesByParentType.Search.42`,
// while the outreaches for a contact with ID 123 would be at
// `allOutreachesByParentType.Contact.123.all`.
// When we get a collection action it will have the parent type and ID on the payload,
// so we can use that to call the appropriate list reducer. For create, update,
// and delete actions we need to pass the action down to every single list
// because that item may possibly belong (or no longer belong) to any of our
// lists.
export default (state = new Map(), action) => {
  switch (action.type) {
    case OUTREACH_LIST_FETCH_START:
    case OUTREACH_LIST_FETCH_SUCCESS:
    case OUTREACH_LIST_FETCH_FAILURE: {
      const parentType = selectn('payload.parentType', action);
      const parentId = selectn('payload.parentId', action);
      const listType = selectn('payload.listType', action);

      if (parentType && parentId && listType === LIST_ALL) {
        return state.updateIn([parentType, parentId], list =>
          outreachListReducer(list, action),
        );
      }

      return state;
    }
    case entityActionTypes.CREATE_SUCCESS: {
      if (selectn('payload.entityType', action) !== outreachSchema.key) {
        return state;
      }

      // For create actions, we need to see if the new document should be added to
      // any lists which we currently have fetched based on the document's owner
      const outreachId = selectn('payload.result.outreach', action);
      const outreach = selectn(
        `payload.entities.${outreachSchema.key}.${outreachId}`,
        action,
      );
      if (outreach) {
        return state.withMutations(mutable => {
          if (mutable.hasIn([PARENT_CONTACT, outreach.contact_id, 'ids'])) {
            mutable.updateIn(
              [PARENT_CONTACT, outreach.contact_id, 'ids'],
              ids => ids.push(outreachId),
            );
          }
          outreach.searches.forEach(searchId => {
            if (mutable.hasIn([PARENT_SEARCH, searchId, 'ids'])) {
              mutable.updateIn([PARENT_SEARCH, searchId, 'ids'], ids =>
                ids.push(outreachId),
              );
            }
          });
        });
      }

      return state;
    }
    case entityActionTypes.UPDATE_SUCCESS: {
      if (!state.has(PARENT_SEARCH)) {
        return state;
      }

      // For update actions, the searches may have changed, so we need to remove the
      // outreach ID from any lists where it no longer belongs, and add it to any where it does
      // belong but has not yet been added.
      const outreachId = selectn('payload.result.outreach', action);
      const outreach = selectn(
        `payload.entities.${outreachSchema.key}.${outreachId}`,
        action,
      );
      return state.update(PARENT_SEARCH, searchListsState =>
        searchListsState.map((searchListState, searchId) => {
          if (searchListState.has('ids')) {
            // For each search ID,
            return searchListState.update('ids', ids => {
              const belongs = outreach.searches.includes(searchId);
              const exists = ids.includes(outreachId);
              if (belongs && !exists) {
                return ids.push(outreachId);
              }
              if (exists && !belongs) {
                return ids.filter(id => id !== outreachId);
              }

              return ids;
            });
          }

          // No IDs, so data for this list hasn't been loaded yet.
          return searchListState;
        }),
      );
    }
    case entityActionTypes.DELETE_SUCCESS: {
      if (selectn('payload.entityType', action) !== outreachSchema.key) {
        return state;
      }

      return state.map(parentTypeState =>
        parentTypeState.map(listState =>
          outreachListReducer(listState, action),
        ),
      );
    }
    default: {
      return state.map(parentTypeState =>
        parentTypeState.map(listState =>
          outreachListReducer(listState, action),
        ),
      );
    }
  }
};
