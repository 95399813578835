import { schema } from 'normalizr';
import { searchSchema } from 'modules/searches/schema';

export const contactMethodSchema = new schema.Entity('contactMethods');

/**
 * The schema for a outreach record
 * @type {schema.Entity}
 */
export const outreachSchema = new schema.Entity('outreaches', {
  searches: [searchSchema],
  contact_method: contactMethodSchema,
});
