import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import allResidencies from './allResidencies';

const residenciesReducer = combineImmutableReducers({
  allResidencies: allResidencies,
});

residenciesReducer.NAME = NAME;

export default residenciesReducer;
