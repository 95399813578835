import { TARGET_COMPANY_LIST_INVALIDATE } from './actionTypes';
/**
 * Invalidates the list of target companies associated with a search
 * @param {Object} payload
 * @param {Number} payload.searchId The search ID whose target companies list should be invalidated
 */
export default ({ searchId }) => ({
  type: TARGET_COMPANY_LIST_INVALIDATE,
  payload: { searchId: searchId },
});
