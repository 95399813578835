import fetchEntity from 'modules/entities/actions/fetchEntity';
import routes from 'modules/routing/routes';
import { candidacyStatusSchema } from '../schemas';

/**
 * Fetches the status for a candidacy
 * @param {Object} payload
 * @param {Number} payload.id The candidacy ID
 */
export default ({ id }) =>
  fetchEntity({
    entityType: candidacyStatusSchema.key,
    id: id,
    responseSchema: { status: candidacyStatusSchema },
    url: routes.api_v1_candidacy_status({ candidacyId: id }),
  });
