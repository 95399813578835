import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, withProps } from 'recompose';
import { PARENT_CANDIDACY } from 'modules/comments/constants';
import CommentsPanel from 'modules/comments/components/CommentsPanel';

export default compose(
  setDisplayName('CandidacyCommentsPanel'),
  setPropTypes({
    candidacyId: PropTypes.number,
  }),
  withProps(({ candidacyId }) => ({
    addCommentButtonLabel: 'Add Comment',
    addCommentButtonClassName: 'btn-secondary',
    className: 'CandidacyCommentsPanel',
    inlineEditActionLabels: {
      editLabel: 'Edit',
      deleteLabel: 'Delete',
      replyLabel: 'Reply',
    },
    parentType: PARENT_CANDIDACY,
    parentId: candidacyId,
  })),
)(CommentsPanel);
