import getContactProperty from './getContactProperty';

/**
 * Gets the off limits state of a contact
 * @param {*} state
 * @param {Number} id the contact's ID
 * @returns {?Immutable.Map} The off limits status of the contact which, if it is defined, typically
 *   includes one or more of the following properties:
 *   `category`, `notes`, and/or `status`
 */
export default (state, id) => getContactProperty(state, id, 'off_limits');
