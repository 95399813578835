import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  onlyUpdateForKeys,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import mapContactIdToFullName from './mapContactIdToFullName';
import ContactLink from './ContactLink';

export const ContactName = ({
  contactId,
  fullName,
  linkToContactPage,
  useDefaultLinkBehavior,
}) =>
  linkToContactPage ? (
    <ContactLink
      className='ContactName'
      contactId={contactId}
      useDefaultLinkBehavior={useDefaultLinkBehavior}
    >
      {fullName}
    </ContactLink>
  ) : (
    <span className='ContactName'>{fullName}</span>
  );

ContactName.propTypes = {
  // A contactId is required when we want to link to their profile.
  contactId: requiredIf(PropTypes.number, props =>
    Boolean(props.linkToContactPage),
  ),
  fullName: PropTypes.string.isRequired,
  linkToContactPage: PropTypes.bool,

  /**
   * When true, the contact link will use the default browser link behavior
   * (and not client-side routing)
   */
  useDefaultLinkBehavior: PropTypes.bool,
};

ContactName.defaultProps = {
  linkToContactPage: false,
  useDefaultLinkBehavior: true,
};

export default compose(
  setDisplayName('ContactName(enhanced'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToFullName,
  onlyUpdateForKeys([
    'contactId',
    'fullName',
    'linkToContactPage',
    'useDefaultLinkBehavior',
  ]),
)(ContactName);
