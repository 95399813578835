//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'documents';

//---------------------------------------------------------
// Owner types
//---------------------------------------------------------

export const OWNER_COMPANY = 'Company';
export const OWNER_CONTACT = 'Contact';
export const OWNER_SEARCH = 'Search';

export const OWNER_TYPES = [OWNER_COMPANY, OWNER_CONTACT, OWNER_SEARCH];

//---------------------------------------------------------
// Label values
//---------------------------------------------------------

export const LABEL_POSITION_DESCRIPTION = 'Position Description';
export const LABEL_COMPANY_PRESENTATION = 'Company Presentation';
export const LABEL_INVOICE = 'Invoice';
export const LABEL_OFFER_LETTER = 'Offer Letter';
export const LABEL_LINKEDIN_PDF = 'LinkedIn PDF';
export const LABEL_REFERENCES = 'References';
export const LABEL_RESUME = 'Resume';
export const LABEL_PRESENTATION = 'Presentation';
export const LABEL_OTHER = 'Other';
export const LABEL_VERIFICATION_OF_APPROVAL = 'Verification of Approval';

export const PrimaryDocumentTypeIds = {
  REFERENCE: 3,
  RESUME: 1,
};

export const LABEL_TYPES = [
  LABEL_POSITION_DESCRIPTION,
  LABEL_COMPANY_PRESENTATION,
  LABEL_INVOICE,
  LABEL_OFFER_LETTER,
  LABEL_LINKEDIN_PDF,
  LABEL_REFERENCES,
  LABEL_RESUME,
  LABEL_PRESENTATION,
  LABEL_OTHER,
];

/**
 * The labels that are used for each owner type, and displayed in the label
 * selection dropdown in the DocumentField.
 */
export const OWNER_TYPE_LABELS = {
  [OWNER_COMPANY]: [LABEL_OTHER, LABEL_PRESENTATION],
  [OWNER_CONTACT]: [
    LABEL_LINKEDIN_PDF,
    LABEL_REFERENCES,
    LABEL_RESUME,
    LABEL_OTHER,
  ],
  [OWNER_SEARCH]: [
    LABEL_POSITION_DESCRIPTION,
    LABEL_COMPANY_PRESENTATION,
    LABEL_INVOICE,
    LABEL_OTHER,
  ],
};
