import { ADD_EMAIL_SIGNATURE, NAME } from '../constant';

const initialState = {
  emailSignature: null,
};

const emailSignatureReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EMAIL_SIGNATURE:
      return {
        ...state,
        emailSignature: action.payload,
      };
    default:
      return state;
  }
};

emailSignatureReducer.NAME = NAME;

export default emailSignatureReducer;
