import { Map } from 'immutable';
import { NAME } from '../constants';

const EMPTY_MAP = new Map();

/**
 * Gets the "bucket" of entities keyed by their ID.
 * @deprecated This is here for temporary backward compatability, specifically for QuerySelect.
 *   Do not use this for new functionality!
 */
export default (state, entityType) => state[NAME].get(entityType) || EMPTY_MAP;
