import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import revenueListsByCompanyId from './revenueListsByCompanyId';

const companyRevenuesReducer = combineImmutableReducers({
  revenueListsByCompanyId: revenueListsByCompanyId,
});

companyRevenuesReducer.NAME = NAME;

export default companyRevenuesReducer;
