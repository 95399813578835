import PropTypes from 'prop-types';
import {
  compose,
  mapProps,
  pure,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import Link from 'modules/routing/components/Link';
import getContactProfileUrl from '../selectors/contacts/getContactProfileUrl';

/**
 * Renders a link to a contact profile page.
 */
export default compose(
  setDisplayName('ContactLink'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapProps(({ contactId, ...props }) => ({
    href: getContactProfileUrl(contactId),
    ...props,
  })),
  pure,
)(Link);
