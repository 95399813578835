import selectn from 'selectn';
import { Map } from 'immutable';
import createListReducer from '../../../reducers/createListReducer';
import {
  SEARCH_STAGES_FETCH_START,
  SEARCH_STAGES_FETCH_SUCCESS,
  SEARCH_STAGES_FETCH_FAILURE,
} from '../actions/stages/actionTypes';

const stageListReducer = createListReducer({
  request: SEARCH_STAGES_FETCH_START,
  success: SEARCH_STAGES_FETCH_SUCCESS,
  failure: SEARCH_STAGES_FETCH_FAILURE,
});

export default (state = new Map(), action) => {
  switch (action.type) {
    case SEARCH_STAGES_FETCH_START:
    case SEARCH_STAGES_FETCH_SUCCESS:
    case SEARCH_STAGES_FETCH_FAILURE: {
      const stageType = selectn('payload.stageType', action);

      if (stageType) {
        return state.update(stageType, list => stageListReducer(list, action));
      }

      return state;
    }
    default: {
      return state.map(listState => stageListReducer(listState, action));
    }
  }
};
