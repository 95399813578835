import selectn from 'selectn';
import { POST } from 'modules/api/methods';
import invalidateActivityList from 'modules/activities/actions/invalidateActivityList';
import { PARENT_CANDIDACY, PARENT_SEARCH } from 'modules/activities/constants';
import invalidateCommentList from 'modules/comments/actions/invalidateCommentList';
import updateEntity from 'modules/entities/actions/updateEntity';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import routes from 'modules/routing/routes';
import { candidacyVoteSchema } from '../schemas';

/**
 * Creates or changes the current user's vote value for a candidate.
 * @param {Object} payload
 * @param {String} payload.id The ID of the candidacy
 * @param {Boolean|Number} payload.vote The vote value to set: 1, -1, or false to "unset"
 * @param {String} [payload.comment] A comment to generate to go along with the vote.
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({ comment, id, transactionId, vote }) => dispatch => {
  dispatch(
    updateEntity({
      // This is actually an "update or create", but we treat it as an entity update
      // because then we get the correct `isUpdating` meta flag set.
      method: POST,
      entityType: candidacyVoteSchema.key,
      responseSchema: candidacyVoteSchema,
      url: routes.api_candidacy_votes({ candidacyId: id }),
      transactionId: transactionId,
      body: {
        vote: {
          candidacy_id: id,
          comment: comment,
          type: 'CandidacyVote',
          vote: vote,
        },
      },
    }),
  ).then(action => {
    if (action.type === entityActionTypes.UPDATE_SUCCESS) {
      const { key } = candidacyVoteSchema;
      const contactId = selectn(
        `payload.entities.${key}.${id}.contact`,
        action,
      );
      const searchId = selectn(
        `payload.entities.${key}.${id}.search_id`,
        action,
      );

      // A vote can create a comment, which also generates an activity,
      // so we need to invalidate those related lists if they've been fetched.
      dispatch(
        invalidateActivityList({
          parentId: id,
          parentType: PARENT_CANDIDACY,
        }),
      );

      dispatch(
        invalidateActivityList({
          parentId: searchId,
          parentType: PARENT_SEARCH,
        }),
      );

      dispatch(
        invalidateCommentList({
          candidacyId: id,
          contactId: contactId,
        }),
      );
    }

    return action;
  });
};
