import React from 'react';
import moment from 'moment';
import ReactSelect from 'react-select-legacy';
import asFormGroupField from 'modules/forms/components/asFormGroupField';

const MONTH_OPTIONS = moment
  .months()
  .map((label, value) => ({ label: label, value: value }));

/**
 * A component that displays a dropdown for selecting a month of the year.
 */
export const MonthSelect = ({ ...props }) => (
  <ReactSelect {...props} options={MONTH_OPTIONS} simpleValue={true} />
);

export default asFormGroupField(MonthSelect);
