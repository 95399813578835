import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import React from 'react';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import ExternalLink from 'modules/links/ExternalLink';
import mapCompanyIdToCompany from './mapCompanyIdToCompany';

const CompanyDuplicate = ({ company }) => {
  return (
    <ExternalLink href={`/companies/${company.get('id')}`}>
      {company.get('name')}
    </ExternalLink>
  );
};

CompanyDuplicate.propTypes = {
  company: ImmutablePropTypes.mapContains({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

export default compose(
  setDisplayName('CompanyDuplicate(enhanced)'),
  setPropTypes({
    companyId: PropTypes.number.isRequired,
  }),
  mapCompanyIdToCompany,
)(CompanyDuplicate);
