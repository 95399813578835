import selectn from 'selectn';
import { Map } from 'immutable';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import createEntityListReducer from 'modules/entities/createEntityListReducer';
import isEntityType from 'modules/entities/isEntityType';
import {
  ASSESSMENTS_FETCH_START,
  ASSESSMENTS_FETCH_SUCCESS,
  ASSESSMENTS_FETCH_FAILURE,
} from '../actions/assessments/actionTypes';
import { QUESTION_ANSWER_LIST_UPDATE_SUCCESS } from '../../question-answers/actions/actionTypes';
import { PARENT_CANDIDACY, PARENT_CONTACT, PARENT_SEARCH } from '../constants';
import { assessmentSchema } from '../schema';

const candidacyListReducer = createEntityListReducer({
  entityType: assessmentSchema.key,
  listResultKey: 'assessments',
  entityResultKey: 'assessment',
  request: ASSESSMENTS_FETCH_START,
  success: ASSESSMENTS_FETCH_SUCCESS,
  failure: ASSESSMENTS_FETCH_FAILURE,
});

/* Maintains lists of assessments, first keyed by the parent they were fetched for (search,
 * contact, or candidacy), then keyed by that parent's ID.
 */
export default (state = new Map(), action) => {
  switch (action.type) {
    case ASSESSMENTS_FETCH_START:
    case ASSESSMENTS_FETCH_SUCCESS:
    case ASSESSMENTS_FETCH_FAILURE: {
      const type = selectn('payload.parentType', action);
      const id = selectn('payload.parentId', action);

      if (id && type) {
        return state.updateIn([type, id], list =>
          candidacyListReducer(list, action),
        );
      }

      return state;
    }
    case entityActionTypes.CREATE_SUCCESS: {
      // When an assessment is created and we have any associated lists fetched which it blongs to
      // add that record to them.
      if (isEntityType(action, assessmentSchema.key)) {
        const assessmentId = selectn('payload.result.assessment', action);
        const assessmentEntity = selectn(
          `payload.entities.${assessmentSchema.key}.${assessmentId}`,
          action,
        );
        const candidacyId = selectn('candidacy_id', assessmentEntity);
        const searchId = selectn('search_id', assessmentEntity);
        const contactId = selectn('contact_id', assessmentEntity);

        return state.withMutations(map => {
          if (searchId && map.hasIn([PARENT_CANDIDACY, candidacyId, 'ids'])) {
            map.updateIn([PARENT_CANDIDACY, candidacyId, 'ids'], ids =>
              ids.concat(assessmentId),
            );
          }
          if (contactId && map.hasIn([PARENT_CONTACT, contactId, 'ids'])) {
            map.updateIn([PARENT_CONTACT, contactId, 'ids'], ids =>
              ids.concat(assessmentId),
            );
          }
          if (searchId && map.hasIn([PARENT_SEARCH, searchId, 'ids'])) {
            map.updateIn([PARENT_SEARCH, searchId, 'ids'], ids =>
              ids.concat(assessmentId),
            );
          }
        });
      }

      return state;
    }
    // Currently, two reducers listen for this action: assessmentLists and interviewsByParentType.
    // The entire candidacy gets invalidated anytime either answer list is updated.
    // It isn't a problem currently, but worth noting in case someone runs into this down the road
    case QUESTION_ANSWER_LIST_UPDATE_SUCCESS: {
      const candidacyId = selectn('payload.response.candidacy_id', action);

      return state.setIn(
        [PARENT_CANDIDACY, candidacyId, '_meta', 'isInvalidated'],
        true,
      );
    }
    default: {
      return state.map(parentTypes =>
        parentTypes.map(listState => candidacyListReducer(listState, action)),
      );
    }
  }
};
