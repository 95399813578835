import { connect } from 'react-redux';
import getCompany from '../selectors/getCompany';

/**
 * A higher order component which maps a `companyId` prop value to it's company record.
 */
export default connect(
  (state, { companyId }) => ({
    company: getCompany(state, companyId),
  }),
  {},
);
