import PropTypes from 'prop-types';
import React from 'react';
import pluralize from 'pluralize';
import {
  compose,
  onlyUpdateForPropTypes,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import mapParentToCommentCount from './mapParentToCommentCount';
import { PARENT_TYPES } from '../constants';

/**
 * Renders a span of text indiating the number of comments -- or "Add comment" if there are no
 * comments or the number is unknown.
 */
export const CommentCount = ({
  commentCount,
  iconClassName,
  noCommentsText,
  noText,
}) => (
  <span className='CommentCount'>
    <i className={`${iconClassName}`} />
    {noText && commentCount}
    {!noText &&
      (commentCount > 0
        ? pluralize('comment', commentCount, true)
        : noCommentsText)}
  </span>
);

CommentCount.propTypes = {
  /**
   * The total number of comments, if available.
   */
  commentCount: PropTypes.number,

  /**
   * Icon to be used next to comment count.
   */
  iconClassName: PropTypes.string,

  /**
   * The text to display when there are no comments.
   */
  noCommentsText: PropTypes.string,

  /**
   * If set to true then only the icon and the comment count appear
   */
  noText: PropTypes.bool,
};

CommentCount.defaultProps = {
  iconClassName: 'icon icon-comment-bubble',
  noCommentsText: 'Add comment',
  noText: false,
};

export default compose(
  setDisplayName('CommentCount(enhanced)'),
  setPropTypes({
    parentType: PropTypes.oneOf(PARENT_TYPES).isRequired,
    parentId: PropTypes.number.isRequired,
  }),
  mapParentToCommentCount,
  onlyUpdateForPropTypes,
)(CommentCount);
