import createListReducer from '../../../reducers/createListReducer';
import {
  TALENT_POOLS_INVALIDATE,
  TALENT_POOLS_FETCH_START,
  TALENT_POOLS_FETCH_SUCCESS,
  TALENT_POOLS_FETCH_FAILURE,
  TALENT_POOL_CREATE_SUCCESS,
  TALENT_POOL_UPDATE_SUCCESS,
  TALENT_POOL_DELETE_SUCCESS,
} from '../actions/actionTypes';

/**
 * A reducer that maintains a list of all talent_pool IDs.
 */
export default createListReducer({
  resultKey: 'talent_pools',
  entityResultKey: 'talent_pool',
  invalidated: TALENT_POOLS_INVALIDATE,
  request: TALENT_POOLS_FETCH_START,
  success: TALENT_POOLS_FETCH_SUCCESS,
  failure: TALENT_POOLS_FETCH_FAILURE,
  created: TALENT_POOL_CREATE_SUCCESS,
  updated: TALENT_POOL_UPDATE_SUCCESS,
  deleted: TALENT_POOL_DELETE_SUCCESS,
});
