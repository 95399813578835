import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import formStatePropType from '../propTypes/formStatePropType';

/**
 * Renders an error message block from a formState.
 */
const FormErrorMessage = ({ className, formState }) => {
  const error = formState.getError();

  return (
    <p
      className={classnames(className, 'form-error', 'alert', 'alert-danger', {
        hide: !error,
      })}
    >
      <span>
        {(error && error.message) ||
          'There was an error submitting the form. Please try again.'}
      </span>
    </p>
  );
};

FormErrorMessage.propTypes = {
  /**
   * The current formState to obtain the error state from..
   */
  className: PropTypes.string,
  formState: formStatePropType.isRequired,
};

export default FormErrorMessage;
