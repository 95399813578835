import createEntity from 'modules/entities/actions/createEntity';
import routes from 'modules/routing/routes';
import { assessmentSchema } from '../../schema';
import transformAssessmentForServer from './transformAssessmentForServer';

/**
 * Creates an action for creating an assessment.
 * @param {Object} payload
 * @param {Object} payload.assessment The assessment to create
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({ assessment, token, transactionId }) => dispatch =>
  dispatch(
    createEntity({
      body: { assessment: transformAssessmentForServer(assessment) },
      transactionId: transactionId,
      entityType: assessmentSchema.key,
      responseSchema: { assessment: assessmentSchema },
      // Token may be supplied for guest assessments
      token: token,
      url: routes.api_v1_assessments(),
    }),
  );
