(function initStickyTabsPlugin($) {
  $.fn.stickyTabs = function stickyTabs() {
    // eslint-disable-line no-param-reassign
    const context = this;

    // Show the tab corresponding with the hash in the URL, or the first tab.
    function showTabFromHash() {
      let { hash } = window.location;
      const leadingCharacters = hash.slice(0, 2);

      if (leadingCharacters === '#/') {
        hash = `#${hash.slice(2)}`;
      }

      $(`a[href="${hash}"]`, context).tab('show');
    }

    // Set the correct tab when the page loads
    showTabFromHash(context);

    // Set the correct tab when a user uses their back/forward button
    window.addEventListener('hashchange', showTabFromHash, false);

    // Change the URL when tabs are clicked
    $('a', context).on('click', function handleClick() {
      history.pushState(null, null, this.href); // eslint-disable-line no-restricted-globals
    });

    return this;
  };
})(jQuery);
