import { NAME } from '../constants';

/**
 * Gets the list of assessments belonging to a particular parent.
 * @param {Object} state
 * @param {Number} parentType One of PARENT_SEARCH, PARENT_CONTACT, or PARENT_CANDIDACY
 * @param {Number} parentId The search, contact, or candidacy ID, depending on parentType.
 * @return {?Immutable.Map} The list of assessments.
 */
export default (state, parentType, parentId) =>
  state[NAME].getIn(['assessmentLists', parentType, parentId]);
