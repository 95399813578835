import selectn from 'selectn';
import { Map } from 'immutable';
import createListReducer from '../../../reducers/createListReducer';
import {
  JOB_APPLICATION_LIST_FETCH_START,
  JOB_APPLICATION_LIST_FETCH_SUCCESS,
  JOB_APPLICATION_LIST_FETCH_FAILURE,
} from '../actions/actionTypes';

const jobApplicationsListReducer = createListReducer({
  resultKey: 'job_applications',
  entityResultKey: 'job_application',
  request: JOB_APPLICATION_LIST_FETCH_START,
  success: JOB_APPLICATION_LIST_FETCH_SUCCESS,
  failure: JOB_APPLICATION_LIST_FETCH_FAILURE,
});

// Job Applications are keyed on the searchId
export default (state = new Map(), action) => {
  const searchId = selectn('payload.searchId', action);
  if (searchId) {
    return state.update(searchId, list =>
      jobApplicationsListReducer(list, action),
    );
  }

  return state;
};
