import { connect } from 'react-redux';
import getCandidacyStage from 'modules/candidacies/selectors/getCandidacyStage';
import getCandidacyStageId from 'modules/candidacies/selectors/getCandidacyStageId';

/**
 * A higher order component which maps a `candidacyIds` prop value to a "list item"
 * object that can be used to determine which list item to render for the given candidacy.
 */
export default connect(
  (state, { candidacyIds }) => ({
    listItems:
      candidacyIds &&
      candidacyIds.map((candidacyId, index) => ({
        candidacyId: candidacyId,
        stageId: getCandidacyStageId(state, candidacyId),
        stage: getCandidacyStage(state, candidacyId),
        firstCandidacyId: candidacyIds.first(),
        lastCandidacyId: candidacyIds.last(),
        previousCandidacyId: index === 0 ? null : candidacyIds.get(index - 1),
        nextCandidacyId:
          index >= candidacyIds.size - 1 ? null : candidacyIds.get(index + 1),
      })),
  }),
  {},
);
