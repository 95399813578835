import { fromJS, Map } from 'immutable';
import {
  EXISTING_CANDIDACIES_COUNT_FETCH_START,
  EXISTING_CANDIDACIES_COUNT_FETCH_FAILURE,
  EXISTING_CANDIDACIES_COUNT_FETCH_SUCCESS,
} from '../actions/existingCandidaciesCounts/actionTypes';

/**
 * Maintains the state for the counts of candidates within a search
 * based on an array of contactIds
 */
export default (state = new Map(), action) => {
  switch (action.type) {
    case EXISTING_CANDIDACIES_COUNT_FETCH_START: {
      const { contactIds, searchId } = action.payload;
      return state
        .setIn(
          [searchId, contactIds.sort().toString(), 'meta', 'isFetching'],
          true,
        )
        .setIn(
          [searchId, contactIds.sort().toString(), 'meta', 'lastFetched'],
          Date.now(),
        );
    }
    case EXISTING_CANDIDACIES_COUNT_FETCH_FAILURE: {
      const { contactIds, searchId } = action.payload;
      return state
        .setIn(
          [searchId, contactIds.sort().toString(), 'meta', 'isFetching'],
          false,
        )
        .setIn(
          [searchId, contactIds.sort().toString(), 'meta', 'error'],
          action.payload.error,
        );
    }
    case EXISTING_CANDIDACIES_COUNT_FETCH_SUCCESS: {
      const { contactIds, searchId } = action.payload;
      return state
        .setIn(
          [searchId, contactIds.sort().toString(), 'meta', 'isFetching'],
          false,
        )
        .deleteIn([searchId, contactIds.sort().toString(), 'meta', 'error'])
        .mergeDeepIn(
          [searchId, contactIds.sort().toString()],
          fromJS(action.payload.data),
        );
    }
    default: {
      return state;
    }
  }
};
