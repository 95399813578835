import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import routes from 'modules/routing/routes';
import {
  TARGET_COMPANY_COMMENT_LIST_FETCH_START,
  TARGET_COMPANY_COMMENT_LIST_FETCH_SUCCESS,
  TARGET_COMPANY_COMMENT_LIST_FETCH_FAILURE,
} from './actionTypes';
import { targetCompanyCommentSchema } from '../schema';

/**
 * Creates an action for fetching a list of comments for a target company
 * @param {Object} payload
 * @param {String} payload.targetCompanyId The ID of the target company to fetch comments for
 */
export default compose(
  fetchAction,
  normalizeResponse({ comments: [targetCompanyCommentSchema] }),
  createAsyncActions({
    startActionType: TARGET_COMPANY_COMMENT_LIST_FETCH_START,
    successActionType: TARGET_COMPANY_COMMENT_LIST_FETCH_SUCCESS,
    failureActionType: TARGET_COMPANY_COMMENT_LIST_FETCH_FAILURE,
  }),
  withOptions(({ targetCompanyId }) => ({
    url: routes.api_v1_target_company_comments({
      targetCompanyId: targetCompanyId,
    }),
  })),
);
