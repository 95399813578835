import getEntity from 'modules/entities/selectors/getEntity';
import { optionSchema } from '../schema';

/**
 * Gets an assessment option by it's ID
 * @param {Object} state
 * @param {Number} id The assessment option ID
 * @return {Immutable.Map|undefined} The assessment option record, if found.
 */
export default (state, id) => getEntity(state, optionSchema.key, id);
