import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import hasFeature from 'modules/auth/selectors/hasFeature';
import fetchAssessmentListCreator from '../actions/assessments/fetchAssessments';
import shouldFetchAssessmentListSelector from '../selectors/shouldFetchAssessmentList';
import getAssessmentListSelector from '../selectors/getAssessmentList';
import { PARENT_TYPES } from '../constants';

/**
 * Fetches the assessments for a parent type (if they need to be fetched), and provides the
 * resulting list as a prop to the base component.
 * @param {Object} options
 * @param {String} [options.assessmentListPropName="assessmentList"] The name of the outgoing prop
 *   that will ultimately be used to provide the assessment list state to the base component.
 * @param {String} [options.parentTypePropName="parentType"] The name of the incoming prop that
 *   will be expected to specify the parent type to fetch the list of assessments for.
 * @param {String} [options.parentIdPropName="parentId"] The name of the incoming prop that will
 *   be expected to specify the parent ID to fetch the list of assessments for.
 * @param {String} [options.fetchAssessmentsListPropName] If provided, a prop will be added that
 *   can be called to initiate a fetch, regardless of whether it's needed or not.
 * @return {Function} a higher-order function that fetches and provides the assessment list based
 *   on the options and props provided.
 */
export default ({
  assessmentListPropName = 'assessmentList',
  fetchAssessmentsListPropName = null,
  parentIdPropName = 'parentId',
  parentTypePropName = 'parentType',
} = {}) =>
  compose(
    setDisplayName('withAssessmentListFetched'),
    setPropTypes({
      [parentTypePropName]: PropTypes.oneOf(PARENT_TYPES),
      [parentIdPropName]: PropTypes.number,
    }),

    mapProps(props => ({
      // Store any incoming props so we don't accidentally clobber them -- they will be restored
      // when we're done here.
      incomingProps: props,
      parentType: props[parentTypePropName],
      parentId: props[parentIdPropName],
    })),

    connect(
      (state, { parentId, parentType }) => ({
        shouldFetchCandidacyList: shouldFetchAssessmentListSelector(
          state,
          parentType,
          parentId,
        ),
        assessmentList: getAssessmentListSelector(state, parentType, parentId),
        hasAssessmentTemplates: hasFeature(
          state,
          'feature.assessment_templates',
        ),
      }),
      {
        fetchAssessmentList: fetchAssessmentListCreator,
      },
    ),

    withHandlers({
      fetchAssessmentList: ({
        fetchAssessmentList,
        hasAssessmentTemplates,
        parentId,
        parentType,
      }) => () =>
        fetchAssessmentList({
          parentId: parentId,
          parentType: parentType,
          hasAssessmentTemplates: hasAssessmentTemplates,
        }),

      fetchAssessmentListIfNeeded: ({
        fetchAssessmentList,
        hasAssessmentTemplates,
        parentId,
        parentType,
        shouldFetchCandidacyList,
      }) => () =>
        shouldFetchCandidacyList &&
        fetchAssessmentList({
          parentId: parentId,
          parentType: parentType,
          hasAssessmentTemplates: hasAssessmentTemplates,
        }),
    }),

    lifecycle({
      UNSAFE_componentWillMount: function () {
        this.props.fetchAssessmentListIfNeeded();
      },
      UNSAFE_componentWillReceiveProps: function (nextProps) {
        nextProps.fetchAssessmentListIfNeeded();
      },
    }),

    // Restore the props we saved earlier (`incomingProps`), and add the assessment list, and
    // optionally the fetch handler (if `fetchAssessmentsListPropName` was provided).
    mapProps(({ assessmentList, fetchAssessmentList, incomingProps }) => ({
      [assessmentListPropName]: assessmentList,
      ...(fetchAssessmentsListPropName
        ? {
            [fetchAssessmentsListPropName]: fetchAssessmentList,
          }
        : {}),
      ...incomingProps,
    })),
  );
