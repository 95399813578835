import { fromJS } from 'immutable';
import createQueryReducer from '../../../reducers/createQueryReducer';
import {
  COMPANY_QUERY_CLEAR,
  COMPANY_QUERY_CREATE,
  COMPANY_QUERY_DESTROY,
  COMPANY_QUERY_FETCH_START,
  COMPANY_QUERY_FETCH_SUCCESS,
  COMPANY_QUERY_FETCH_FAILURE,
  COMPANY_QUERY_INVALIDATE,
} from '../actions/actionTypes';

export default createQueryReducer({
  create: COMPANY_QUERY_CREATE,
  destroy: COMPANY_QUERY_DESTROY,
  start: COMPANY_QUERY_FETCH_START,
  success: COMPANY_QUERY_FETCH_SUCCESS,
  failure: COMPANY_QUERY_FETCH_FAILURE,
  invalidate: COMPANY_QUERY_INVALIDATE,
  reset: COMPANY_QUERY_CLEAR,
  // Eventually it might make sense to update the `createQueryReducer` helper to handle
  // our normalized response, but in the meantime we can use the `updateData` prop to
  // manually set the data value from the `result` values that normalizr provides.
  updateData: (data, { payload }) => fromJS(payload.result),
});
