import getEmail from 'modules/contacts/selectors/contacts/getEmail';
import getCandidacyContactId from './getCandidacyContactId';

/**
 * Gets the email of a candidacy record.
 * @param {Object} state
 * @param {Number} id The candidacy ID
 * @return {?String} The email address for a candidacy
 */
const getCandidacyEmail = (state, candidacyId) => {
  const contactId = getCandidacyContactId(state, candidacyId);
  return contactId && getEmail(state, contactId);
};

export default getCandidacyEmail;
