import { schema } from 'normalizr';

export default new schema.Entity(
  'timezones',
  {},
  {
    // Note there _IS_ an "id" attributes on this record, but it's not unique. I have no idea why
    // this was done in this way. The "value" is the unqiue identifier here. This is going to be
    // confusing. 😠
    idAttribute: 'value',
  },
);
