import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import CancelButton from 'modules/forms/components/CancelButton';
import Form from 'modules/forms/components/Form';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import SubmitButton from 'modules/forms/components/SubmitButton';
import TextAreaField from 'modules/forms/components/TextAreaField';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import withCandidacyVoteForm from './withCandidacyVoteForm';

/**
 * Renders a form for adding or editing a candidacy vote comment.
 */
const CandidacyVoteForm = ({
  // props provided by `withCandidacyVoteForm`
  formState,
  onCancel,
  onFieldStateChange,

  // Props passed through/expected from parent component.
  onSubmit,
  submitButtonLabel,
}) => (
  <Form className='CandidacyVoteForm' formState={formState} onSubmit={onSubmit}>
    <TextAreaField
      disabled={formState.isSubmitting()}
      fieldState={formState.getFieldState()}
      onChange={onFieldStateChange}
      showErrors={formState.wasSubmitted() || 'blurred'}
    />
    <FormErrorMessage formState={formState} />
    <div className='Form__footer'>
      <CancelButton onClick={onCancel} />
      <SubmitButton formState={formState} onClick={onSubmit}>
        {submitButtonLabel}
      </SubmitButton>
    </div>
  </Form>
);

CandidacyVoteForm.propTypes = {
  /**
   * The current FormState of the form.
   */
  formState: formStatePropType.isRequired,

  /**
   * Called when the modal should be closed.
   */
  onCancel: PropTypes.func.isRequired,

  /**
   * Called when the formState's underlying fieldState should be changed.
   */
  onFieldStateChange: PropTypes.func.isRequired,

  /**
   * Caleld when the form should be submitted.
   */
  onSubmit: PropTypes.func.isRequired,

  /**
   * The label for the submit button.
   */
  submitButtonLabel: PropTypes.node,
};

CandidacyVoteForm.defaultProps = {
  submitButtonLabel: 'Save',
};

/**
 * The connected version of this component includes a FormState/FieldState and calls
 * onClose when onSaved gets called by the form handler.
 */
export default compose(
  setDisplayName('CandidacyVoteForm(enhanced))'),
  setPropTypes({
    candidacyId: PropTypes.number.isRequired,

    /**
     * Called when the record was saved.
     */
    onSaved: PropTypes.func.isRequired,

    /**
     * Called when the cancel button is clicked. Called with the current formState
     */
    onCancel: PropTypes.func.isRequired,

    /**
     * `1` for "priority", `-1` for "not a fit", false to "unvote"
     */
    vote: PropTypes.oneOf([-1, 1, false]).isRequired,
  }),

  withCandidacyVoteForm,

  withHandlers({
    onCancel: ({ formState, onCancel }) => () => onCancel(formState),
  }),

  /**
   * Prevent props from being passed on to the underlying field
   */
  /* eslint-disable no-unused-vars */
  mapProps(({ candidacyId, onFormStateChange, onResetFormState, ...rest }) => ({
    ...rest,
  })),
  /* eslint-enable no-unused-vars */
)(CandidacyVoteForm);
