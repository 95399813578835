import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import addressLists from './addressLists';

const addressesReducer = combineImmutableReducers({
  addressLists: addressLists,
});

addressesReducer.NAME = NAME;

export default addressesReducer;
