import { schema } from 'normalizr';
import { searchSchema } from 'modules/searches/schema';

/**
 * The schema for a note record
 * @type {schema.Entity}
 */
export default new schema.Entity('notes', {
  searches: [searchSchema],
});
