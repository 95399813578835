import updateTargetCompany from './updateTargetCompany';

export default ({ targetCompanyId, ...props }) =>
  updateTargetCompany({
    ...props,
    targetCompany: {
      id: targetCompanyId,
      active: false,
    },
  });
