import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  setPropTypes,
  setDisplayName,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import getActivityList from '../selectors/getActivityList';
import shouldFetchActivityListSelector from '../selectors/shouldFetchActivityList';
import * as activityActionCreators from '../actions';
import { PARENT_TYPES } from '../constants';

/**
 * A higher order component that, when provided a candidacyId, fetches the activity
 * list that candidacy if needed from the server.
 */
export default compose(
  setDisplayName('withActivityListFetched'),
  setPropTypes({
    /**
     * The ID of the parent to fetch the activity list for (a candidacy ID if parentType is
     * PARENT_CANDIDACY, or a search id if parentType is PARENT_SEARCH)
     */
    parentId: PropTypes.number,

    /**
     * Which type of parent to fetch the list for - PARENT_CANDIDACY to fetch the activities
     * for a specific candidacy, or PARENT_SEARCH to fetch all activities for a search.
     */
    parentType: PropTypes.oneOf(PARENT_TYPES).isRequired,
  }),
  connect(
    (state, { parentId, parentType }) => ({
      activityList: getActivityList(state, parentType, parentId),
      shouldFetchActivityList: shouldFetchActivityListSelector(
        state,
        parentType,
        parentId,
      ),
    }),
    dispatch => ({
      activityActions: bindActionCreators(activityActionCreators, dispatch),
    }),
  ),

  // Create callbacks which are essentially bound to the parentType and parentId of the underlying
  // props, so that we can just call `fetchActivityList()` without params from the consuming
  // component.
  withHandlers({
    fetchActivityList: ({ activityActions, parentId, parentType }) => () => {
      activityActions.fetchActivityList({
        parentType: parentType,
        parentId: parentId,
      });
    },
    fetchActivityListIfNeeded: ({
      activityActions,
      parentId,
      parentType,
      shouldFetchActivityList,
    }) => () => {
      if (shouldFetchActivityList) {
        activityActions.fetchActivityList({
          parentType: parentType,
          parentId: parentId,
        });
      }
    },
  }),

  // Fetch the activity list (if needed) when mounted or props change.
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchActivityListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchActivityListIfNeeded();
    },
  }),
);
