import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  setDisplayName,
  setPropTypes,
  setStatic,
  withProps,
} from 'recompose';
import withPropsRemoved from 'modules/core/componentsLegacy/withPropsRemoved';
import withTenantName from 'modules/tenant/components/withTenantName';
import FieldState from 'modules/forms/FieldState';
import RadioSelectField from 'modules/forms/components/RadioSelectField';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import mapContactIdToContact from '../mapContactIdToContact';

const handleFieldChange = (fieldState, onChange) => childFieldState => {
  const nextFieldState = fieldState.setNestedField(childFieldState);
  onChange(nextFieldState);
};

const gdprStatusGiven = fieldState => {
  const value = fieldState.getRawValue();
  const validOptions = [
    'Consent Provided',
    'Consent Revoked',
    'Consent Pending',
  ];
  if (!validOptions.includes(value)) {
    return 'Please select an option';
  }

  return null;
};

const CandidatePortalConsentField = ({
  fieldState,
  onChange,
  ...otherProps
}) => (
  <div className='CandidatePortalConsentField'>
    <RadioSelectField
      {...otherProps}
      fieldState={fieldState.getNestedField('consent')}
      inline={false}
      onChange={handleFieldChange(fieldState, onChange)}
    />
  </div>
);

CandidatePortalConsentField.propTypes = {
  fieldState: fieldStatePropType.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('CandidatePortalConsentField'),
  mapContactIdToContact,
  setStatic('createFieldState', (name = 'contact', contact) =>
    FieldState.createNested(name, [
      RadioSelectField.createFieldState(
        'consent',
        contact && contact.getIn(['gdpr_status', 'label']),
        gdprStatusGiven,
      ),
    ]),
  ),
  setPropTypes({
    fieldState: fieldStatePropType.isRequired,
    hasCaslEnabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
  }),
  withTenantName(),
  withProps(({ hasCaslEnabled, tenantName }) => {
    const gdprApprovedLabel = `I consent that ${tenantName} may retain my candidate profile.`;
    const caslApprovedLabel = `I consent that ${tenantName} may retain my
                               candidate profile for the purposes noted above. Additionally,
                               I consent to share my information and receive emails from
                               ${tenantName}. I understand that I may unsubscribe from these
                               emails at any time by clicking the unsubscribe link at the
                               bottom of the email.`;
    const consentRevokedLabel = `I do not wish to give consent for ${tenantName} to retain my candidate profile.`;
    const consentPendingLabel =
      'I do not wish to provide or deny consent at this time. Please let me review my candidate profile.';

    const options = [
      {
        label: hasCaslEnabled ? caslApprovedLabel : gdprApprovedLabel,
        value: 'Consent Provided',
      },
      {
        label: consentRevokedLabel,
        value: 'Consent Revoked',
      },
      {
        label: consentPendingLabel,
        value: 'Consent Pending',
      },
    ];

    return {
      options: options,
    };
  }),
  withPropsRemoved('hasCaslEnabled', 'tenantName'),
)(CandidatePortalConsentField);
