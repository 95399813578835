import getEntity from 'modules/entities/selectors/getEntity';
import { candidacySchema } from '../schemas';

/**
 * Gets the candidacy record from an ID
 * @param {Object} state
 * @param {Number} candidacyId The ID of the candidacy
 * @return {Immutable.Map|undefined} The candidacy record, if found.
 */
export default (state, candidacyId) =>
  getEntity(state, candidacySchema.key, candidacyId);
