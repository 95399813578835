import selectn from 'selectn';
import { Map } from 'immutable';
import contact from './contact';

const INITIAL_STATE = new Map();

export default function contactsById(state = INITIAL_STATE, action) {
  const contactId = selectn('payload.contactId', action);

  if (
    action.type === 'entities/CREATE_SUCCESS' &&
    action.payload.entityType === 'candidacies'
  ) {
    // Temporary special case to check for a change from the contact page.
    // This should go away once contacts are normalized!
    const candidacyId = selectn('payload.result', action);
    const candidacy = selectn(
      `payload.entities.candidacies.${candidacyId}`,
      action,
    );

    if (contactId) {
      return state.mergeIn(
        [contactId, 'data', 'candidacies', candidacyId],
        candidacy,
      );
    }
  }

  if (contactId) {
    return state.update(contactId, contactState =>
      contact(contactState, action),
    );
  }

  return state.map(contactState => contact(contactState, action));
}
