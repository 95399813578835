import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import {
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
} from 'recompose';
import Overlay from 'react-bootstrap/lib/Overlay';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import withScrollContainerRef from 'modules/core/componentsLegacy/withScrollContainerRef';
import fieldStatePropType from '../propTypes/fieldStatePropType';

export const ERROR_DISPLAY_INLINE = 'inline';
export const ERROR_DISPLAY_TOOLTIP = 'tooltip';
export const ERROR_DISPLAY_FOCUSED = 'focused';
export const ERROR_DISPLAY_BLURRED = 'blurred';
export const LABEL_POSITION_BEFORE = 'before';
export const LABEL_POSITION_AFTER = 'after';

/**
 * Wraps children in a 'form-group' classed div, provides a
 * label (if the `label` prop is provided), and handles displaying
 * field errors based on the values of the `errorDisplay`, `showErrors`,
 * and `showFeedback` props.
 */
const FormGroup = ({
  children,
  className,
  error,
  errorDisplay,
  fieldState,
  formGrouplabelPosition,
  getScrollContainer,
  getTargetRef,
  handleMouseOut,
  handleMouseOver,
  id,
  isFocused,
  isMouseOver,
  label,
  setTargetRef,
  showErrors,

  // withStateHandlers
  showFeedback,
  tooltipPlacement,
  warning,

  // withScrollContainerRef props
  warningDisplay,
  wasBlurred,
  wasFocused,
}) => {
  const errorMessage = error || fieldState.getError();
  const hasError = Boolean(errorMessage);
  const showErrorState =
    hasError &&
    errorDisplay &&
    (showErrors === true ||
      (showErrors === ERROR_DISPLAY_BLURRED && wasBlurred) ||
      (showErrors === ERROR_DISPLAY_FOCUSED && wasFocused));

  return (
    <div
      className={classnames(className, 'form-group', {
        'has-feedback': showFeedback,
        'has-error': showErrorState,
        'has-warning': Boolean(warning),
        'has-success': showFeedback && !hasError,
        'form-group-label-before':
          label && formGrouplabelPosition === LABEL_POSITION_BEFORE,
        'form-group-label-after':
          label && formGrouplabelPosition === LABEL_POSITION_AFTER,
      })}
      onBlur={handleMouseOut}
      onFocus={handleMouseOver}
      onMouseOut={handleMouseOut}
      onMouseOver={handleMouseOver}
      ref={setTargetRef}
    >
      {errorDisplay === ERROR_DISPLAY_TOOLTIP && (
        <Overlay
          container={getScrollContainer}
          placement={tooltipPlacement}
          show={showErrorState && (isFocused || isMouseOver)}
          target={getTargetRef}
        >
          <Tooltip id={`${id}-error-tooltip`}>{errorMessage}</Tooltip>
        </Overlay>
      )}
      {warningDisplay === ERROR_DISPLAY_TOOLTIP && !hasError && (
        <Overlay
          container={getScrollContainer}
          placement={tooltipPlacement}
          show={warning && (isFocused || isMouseOver)}
          target={getTargetRef}
        >
          <Tooltip id={`${id}-warning-tooltip`}>{warning}</Tooltip>
        </Overlay>
      )}
      {label && formGrouplabelPosition === LABEL_POSITION_BEFORE && (
        <label className='control-label' htmlFor={id}>
          {label}
        </label>
      )}
      {children}
      {label && formGrouplabelPosition === LABEL_POSITION_AFTER && (
        <label className='control-label' htmlFor={id}>
          {label}
        </label>
      )}
      {showFeedback && wasFocused && (
        <i
          aria-hidden='true'
          className={classnames('fa', 'form-control-feedback', {
            'fa-check': !hasError,
            'fa-times': hasError,
          })}
        />
      )}
      {showErrorState && errorDisplay === ERROR_DISPLAY_INLINE && (
        <p className='help-block'>{errorMessage}</p>
      )}
      {!showErrorState &&
        warning &&
        warningDisplay === ERROR_DISPLAY_INLINE && (
          <p className='help-block'>{warning}</p>
        )}
    </div>
  );
};

// All non-documented props will be passed through to the ControlComponent.
FormGroup.propTypes = {
  /**
   * Props provided by withScrollContainerRef (getScrollContainer, getTargetRef, setTargetRef)
   */
  ...withScrollContainerRef.propTypes,

  children: PropTypes.node,

  /**
   * Classes to apply to the container div.
   * This can really be any value that the `classnames`
   * library can take.
   */
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),

  error: PropTypes.oneOfType([PropTypes.node, PropTypes.oneOf([false])]),

  /**
   * How errors should be displayed:
   * ERROR_DISPLAY_TOOLTIP - Error messages are shown in a tooltip
   *   when the field is focused or hovered
   * ERROR_DISPLAY_INLINE - Error messages are shown inline, below the
   *   field.
   */
  errorDisplay: PropTypes.oneOf([
    ERROR_DISPLAY_INLINE,
    ERROR_DISPLAY_TOOLTIP,
    '',
    false,
  ]),

  /**
   * The FieldState that is tied to this field, which contains
   * the error information and value for this field.
   */
  fieldState: fieldStatePropType.isRequired,

  formGrouplabelPosition: PropTypes.oneOf([
    LABEL_POSITION_BEFORE,
    LABEL_POSITION_AFTER,
  ]),

  /**
   * Called when the mouse is moved out of the component.
   */
  handleMouseOut: PropTypes.func.isRequired,

  /**
   * Called when the mouse is moved over the component.
   */
  handleMouseOver: PropTypes.func.isRequired,

  /**
   * The ID of the field component. This is needed to meet
   * accessibliity requirements when a tooltip is displayed.
   * It is passed through to the underlying field component.
   * If an ID is not specified, one is generated. So the field
   * component will always be passed an ID.
   */
  id: PropTypes.string.isRequired,

  /**
   * True if the child component is currently focused, otherwise false.
   */
  isFocused: PropTypes.bool.isRequired,

  /**
   * True if the mouse is currently over the component.
   */
  isMouseOver: PropTypes.bool.isRequired,

  /**
   * An optional label to display with the field.
   */
  label: PropTypes.node,

  /**
   * Determines when validation error message will be displayed:
   * * false - never show error messages
   * * true - always show error messages
   * * ERROR_DISPLAY_FOCUSED - Errors will be displayed as long as
   *   this field has been focused.
   * * ERROR_DISPLAY_BLURRED - Errors will be displayed as long as
   *   this field has been focused and then blurred
   * *
   * @type {[type]}
   */
  showErrors: PropTypes.oneOf([
    true,
    false,
    ERROR_DISPLAY_FOCUSED,
    ERROR_DISPLAY_BLURRED,
  ]),

  /**
   * Whether to show a feedback indicator inside the field component.
   * This will show a checkmark when the field has no errors, or a
   * cross icon when the field has an error.
   */
  showFeedback: PropTypes.bool,

  /**
   * The placement of the error message popover tooltip when
   * `errorDisplay` is set to `ERROR_DISPLAY_TOOLTIP`
   */
  tooltipPlacement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),

  /**
   * An optional warning message to display as feedback
   */
  warning: PropTypes.oneOfType([PropTypes.node, PropTypes.oneOf([false])]),

  /**
   * @see `errorDisplay`, but applied when a warning message is to be shown.
   */
  warningDisplay: PropTypes.oneOf([
    ERROR_DISPLAY_INLINE,
    ERROR_DISPLAY_TOOLTIP,
    '',
    false,
  ]),

  /**
   * True if the child component has ever been blurred.
   */
  wasBlurred: PropTypes.bool.isRequired,

  /**
   * True if the child component has ever been focused.
   */
  wasFocused: PropTypes.bool.isRequired,
};

FormGroup.defaultProps = {
  errorDisplay: ERROR_DISPLAY_INLINE,
  formGrouplabelPosition: LABEL_POSITION_BEFORE,
  showErrors: ERROR_DISPLAY_BLURRED,
  showFeedback: false,
  tooltipPlacement: 'top',
  warningDisplay: ERROR_DISPLAY_INLINE,
};

export default compose(
  setDisplayName('FormGroup(enhanced)'),
  setPropTypes({
    children: FormGroup.propTypes.children,
    className: FormGroup.propTypes.className,
    error: FormGroup.propTypes.error,
    errorDisplay: FormGroup.propTypes.errorDisplay,
    fieldState: FormGroup.propTypes.fieldState,
    formGrouplabelPosition: FormGroup.propTypes.formGrouplabelPosition,
    id: FormGroup.propTypes.id,
    isFocused: FormGroup.propTypes.isFocused,
    label: FormGroup.propTypes.label,
    showFeedback: FormGroup.propTypes.showFeedback,
    showErrors: FormGroup.propTypes.showErrors,
    tooltipPlacement: FormGroup.propTypes.tooltipPlacement,
    warning: FormGroup.propTypes.warning,
    warningDisplay: FormGroup.propTypes.warningDisplay,
    wasBlurred: FormGroup.propTypes.wasBlurred,
    wasFocused: FormGroup.propTypes.wasFocused,
  }),
  defaultProps(FormGroup.defaultProps),

  withScrollContainerRef,

  // Track the mouse-over state for when errorDisplay or warningDisplay is set to
  // ERROR_DISPLAY_TOOLTIP
  withStateHandlers(
    {
      /**
       * True when the mouse is currently over this control
       * @type {Boolean}
       */
      isMouseOver: false,
    },
    {
      handleMouseOver: () => () => ({ isMouseOver: true }),
      handleMouseOut: () => () => ({ isMouseOver: false }),
    },
  ),
)(FormGroup);
