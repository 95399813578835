import React from 'react';
import { shouldUpdate } from 'recompose';

/**
 * Renders the icon used to indicate something is a location.
 */
const LocationIcon = () => <i className='LocationIcon fa fa-map-marker' />;

/**
 * This component never changes once rendered so our exported version tells react to never update.
 */
export default shouldUpdate(() => false)(LocationIcon);
