import PropTypes from 'prop-types';
import React from 'react';
import { onlyUpdateForPropTypes } from 'recompose';
import LocationIcon from './LocationIcon';

/**
 * Renders a location name with an optional map icon to the left or right of the name
 */
const LocationName = ({ iconPosition, name, renderIfEmpty }) =>
  name || renderIfEmpty ? (
    <span className='LocationName'>
      {iconPosition === 'left' && <LocationIcon />}
      {name}
      {iconPosition === 'right' && <LocationIcon />}
    </span>
  ) : null;

LocationName.propTypes = {
  /**
   * When true, renders a map icon to the left of the location name.
   */
  iconPosition: PropTypes.oneOf(['left', 'right']),

  /**
   * The name of the location.
   */
  name: PropTypes.string,

  /**
   * When true, the component will be rendered even if the name is empty/blank.
   */
  renderIfEmpty: PropTypes.bool,
};

LocationName.defaultProps = {
  renderIfEmpty: false,
};

export default onlyUpdateForPropTypes(LocationName);
