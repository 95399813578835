/* eslint-disable max-classes-per-file */
// ^ Accommodate legacy code.
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, setStatic } from 'recompose';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Portal from 'react-overlays/lib/Portal';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';

/* eslint-disable react/prop-types, no-unused-vars */
/**
 * So for some completely unknown reason, the react–datepicker component does NOT play nice
 * with react-select. If you have the date picker open, then click on a react-select field,
 * the datepicker field immediately retakes the focus. I haven't really figured out why, but
 * supplying a custom input component (which does pretty much exactly what the default one
 * does) prevents the issue, and doesn't seem to have any adverse effects. So until this is
 * straightened out, we need to supply a custom input to the date picker.
 */
class CustomInput extends Component {
  focus = () => {};

  render() {
    const {
      customInput,
      date,
      dateFormat,
      excludeDates,
      filterDate,
      includeDates,
      locale,
      maxDate,
      minDate,
      onChangeDate,
      ...rest
    } = this.props;

    return <input type='text' {...rest} />;
  }
}
/* eslint-enable react/prop-types, no-unused-vars */

/**
 * A field for editing a value using a standard `<input>` element.
 */
class DateInputField extends Component {
  // eslint-disable-line react/no-multi-comp
  /**
   * Creates a new FieldState that can be passed to this field.
   * Defers to the default FieldState.create implementation.
   */
  static createFieldState = (name, value, validator, _, ...rest) =>
    FieldState.create(
      name,
      value,
      validator,
      outputValue => {
        // If the date has been modified it will be in local format ('L'),
        // but otherwise we typically hand dates around in ISO8601 format,
        // which it will probably be in if the user hasn't changed it.
        const valueAsMoment = moment(outputValue, ['L', moment.ISO_8601]);
        return valueAsMoment.isValid()
          ? valueAsMoment.format('YYYY-MM-DD')
          : null;
      },
      ...rest,
    );

  handleChange = date => {
    const { fieldState, onChange } = this.props;
    const dateAsMoment = moment(date);
    onChange(
      fieldState.setValue(
        dateAsMoment && dateAsMoment.isValid()
          ? dateAsMoment.format('MM/DD/YYYY')
          : '',
      ),
    );
  };

  render() {
    const {
      fieldState,
      // prevent onChange from being passed through to the input.
      /* eslint-disable no-unused-vars */
      isRequired,
      onChange,
      /* eslint-enable no-unused-vars */
      ...otherProps
    } = this.props;

    const value = fieldState.getValue();
    const momentValue = moment(value, [
      'MM/DD/YYYY',
      'YYYY/MM/DD',
      moment.ISO_8601,
    ]);

    // We're passing in a custom popperContainer -- react-overlays Portal
    // component -- so that the popper always renders at the top level of the
    // DOM. Otherwise, if we render in a scrollable container, the popper will
    // be confined to that container, and if it isn't large enough to display
    // the calendar, it will be cut off.
    return (
      <div className='date-input'>
        <DatePicker
          className='form-control'
          customInput={<CustomInput />}
          name={fieldState.getName()}
          onChange={this.handleChange}
          peekNextMonth={true}
          popperContainer={Portal}
          required={isRequired}
          selected={momentValue.isValid() ? new Date(momentValue) : null}
          todayButton='Today'
          {...otherProps}
        />
      </div>
    );
  }
}

DateInputField.defaultProps = {
  isRequired: false,
};

DateInputField.propTypes = {
  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  isRequired: PropTypes.bool,
  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func.isRequired,
};

export default compose(
  setStatic('createFieldState', DateInputField.createFieldState),
  withFormGroup,
)(DateInputField);
