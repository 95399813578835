//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'outreaches';

//---------------------------------------------------------
// Parent types
//---------------------------------------------------------

export const PARENT_SEARCH = 'Search';
export const PARENT_CONTACT = 'Contact';

export const PARENT_TYPES = [PARENT_SEARCH, PARENT_CONTACT];

//---------------------------------------------------------
// List types
//---------------------------------------------------------

export const LIST_ALL = 'all';
export const LIST_RECENT = 'recent';

export const LIST_TYPES = [LIST_ALL, LIST_RECENT];
