import React from 'react';
import PropTypes from 'prop-types';
import {
  branch,
  compose,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import CompensationSummary from 'modules/compensations/components/CompensationSummary';
import mapContactIdToCompensationId from './mapContactIdToCompensationId';
import ContactDesiredCompensationSummary from './desiredCompensation/ContactDesiredCompensationSummary';

const ContactCompensationSummary = ({
  compensationId,
  contactId,
  useDesiredCompensation,
}) => (
  <div>
    {useDesiredCompensation ? (
      <ContactDesiredCompensationSummary contactId={contactId} />
    ) : (
      <CompensationSummary compensationId={compensationId} />
    )}
  </div>
);

ContactCompensationSummary.propTypes = {
  compensationId: PropTypes.number,
  contactId: PropTypes.number,
  useDesiredCompensation: PropTypes.bool.isRequired,
};

export default compose(
  setDisplayName('ContactCompensationSummary(enhanced)'),
  setPropTypes({
    contactId: PropTypes.number,
  }),
  mapContactIdToCompensationId('employee', 'compensationId'),
  branch(
    ({ compensationId, useDesiredCompensation }) =>
      !useDesiredCompensation && !compensationId,
    renderNothing,
  ),
)(ContactCompensationSummary);
