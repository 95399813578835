import selectn from 'selectn';
import { fromJS } from 'immutable';
import {
  NAME_BOARD_MEMBER,
  NAME_EMPLOYMENT,
} from 'modules/compensations/constants';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import {
  CONTACT_DESIRED_INTERIM_COMPENSATION,
  CONTACT_CURRENT_INTERIM_COMPENSATION,
} from 'modules/contacts/components/interimCompensation/constants';
import normalizeContact from './normalizeContact';
import notifications from './notifications';
import composeReducers from '../composeReducers';
import {
  CONTACT_INVALIDATE,
  CONTACT_FETCH_START,
  CONTACT_FETCH_SUCCESS,
  CONTACT_FETCH_FAILURE,
  CONTACT_CREATE_SUCCESS,
  CONTACT_UPDATE_SUCCESS,
  CONTACT_RESUME_UPLOAD_START,
  CONTACT_RESUME_UPLOAD_SUCCESS,
  CONTACT_RESUME_UPLOAD_FAILURE,
  CONTACT_AVATAR_UPDATE_SUCCESS,
  CONTACT_USER_CREATE_START,
  CONTACT_USER_CREATE_SUCCESS,
  CONTACT_USER_CREATE_FAILURE,
} from '../../actions/contacts/constants';

export const INITIAL_STATE = fromJS({
  meta: {
    isCreatingUser: false,
    isFetching: false,
    isInvalidated: false,
  },
});

function contact(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONTACT_INVALIDATE: {
      return state.setIn(['meta', 'isInvalidated'], true);
    }
    case CONTACT_RESUME_UPLOAD_START:
    case CONTACT_FETCH_START: {
      return state.setIn(['meta', 'isFetching'], true);
    }
    case CONTACT_RESUME_UPLOAD_SUCCESS:
    case CONTACT_CREATE_SUCCESS: {
      return state.withMutations(map => {
        map.set('data', normalizeContact(action.payload.data));
        map.setIn(['meta', 'isFetching'], false);
        map.setIn(['meta', 'isInvalidated'], false);
        map.deleteIn(['meta', 'error']);
      });
    }
    case CONTACT_FETCH_SUCCESS: {
      return state.withMutations(map => {
        map.set(
          'data',
          normalizeContact(
            action.payload.entities.contacts[action.payload.contactId],
          ),
        );
        map.setIn(['meta', 'isFetching'], false);
        map.setIn(['meta', 'isInvalidated'], false);
        map.deleteIn(['meta', 'error']);
      });
    }
    case CONTACT_RESUME_UPLOAD_FAILURE:
    case CONTACT_FETCH_FAILURE: {
      return state.withMutations(map => {
        map.setIn(['meta', 'isFetching'], false);
        map.setIn(['meta', 'error'], action.payload.error);
      });
    }
    case CONTACT_AVATAR_UPDATE_SUCCESS:
    case CONTACT_UPDATE_SUCCESS: {
      const { contactId, entities } = action.payload;
      return state.mergeIn(
        ['data'],
        normalizeContact(entities.contacts[contactId]),
      );
    }
    case entityActionTypes.CREATE_SUCCESS: {
      if (!selectn('payload.entityType') === 'compensations') {
        return state;
      }

      if (
        state.getIn(['data', 'id']) === selectn('payload.contactId', action)
      ) {
        const compensationId = selectn('payload.result', action);
        switch (
          selectn(
            `payload.entities.compensations.${compensationId}.name`,
            action,
          )
        ) {
          case NAME_EMPLOYMENT: {
            return state.setIn(
              ['data', 'employee_compensation'],
              compensationId,
            );
          }
          case NAME_BOARD_MEMBER: {
            return state.setIn(
              ['data', 'board_member_compensation'],
              compensationId,
            );
          }
          case CONTACT_DESIRED_INTERIM_COMPENSATION: {
            return state.setIn(
              ['data', 'contact_desired_interim_placement'],
              compensationId,
            );
          }
          case CONTACT_CURRENT_INTERIM_COMPENSATION: {
            return state.setIn(
              ['data', 'contact_current_interim_placement'],
              compensationId,
            );
          }
          default: {
            return state;
          }
        }
      }

      return state;
    }
    case CONTACT_USER_CREATE_START: {
      return state.setIn(['meta', 'isCreatingUser'], true);
    }
    case CONTACT_USER_CREATE_SUCCESS: {
      return state
        .setIn(['meta', 'isCreatingUser'], false)
        .setIn(['data', 'user'], fromJS(action.payload.data));
    }
    case CONTACT_USER_CREATE_FAILURE: {
      return state
        .setIn(['meta', 'isCreatingUser'], false)
        .setIn(['meta', 'error'], action.payload.error);
    }
    default:
      return state;
  }
}

export default composeReducers(contact, (state, action) =>
  state.update('notifications', notificationsState => {
    const contactId = state.getIn(['data', 'id']);
    return contactId
      ? notifications(contactId, notificationsState, action)
      : notificationsState;
  }),
);
