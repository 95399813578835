import { schema } from 'normalizr';
import { searchSchema } from 'modules/searches/schema';

const documentSchema = new schema.Entity('documents');

searchSchema.define({
  documents: [documentSchema],
});

export default documentSchema;
