import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import transactionsState from 'modules/transactions/propTypes/transactionsState';

const getComponentProps = (
  includeTransactionProps,
  { clearTransaction, transactions, ...props },
) => {
  if (includeTransactionProps) {
    return {
      clearTransaction: clearTransaction,
      transactions: transactions,
      ...props,
    };
  }

  return props;
};

/**
 * Returns a function that will create a higher-order component that can be used to keep track of
 * transactions. It will:
 * 1. Call a function when a transaction has finished (and Dispatch a `clearTransaction` action
 *    to remove the transaction from the state).
 * 2. Dispatch a `clearTransaction` action when the component is being unmounted, to prevent
 *    unused transactions from being needlessly maintained in the state.
 *
 * @param {Function} getTransactionId A method that, when called with the current props,
 *   returns the transactionId of the transaction that should be tracked.
 * @param {Function} onFinish Called when a transaction that is being tracked has finished,
 * @return {Function} A function that returns a higher-order component that will track transactions.
 */
const withTransaction = (
  getTransactionId,
  onFinish,
  { includeTransactionProps } = {},
) => BaseComponent => {
  class TransactionComponent extends PureComponent {
    UNSAFE_componentWillReceiveProps(nextProps) {
      const transactionId = getTransactionId(nextProps);

      // When the props change, check to see if there is a submissionId associated with the
      // formState, and check if that submission matches a transactionId that has successfully
      // finished. If so, notify the parent by calling `onFinish`.
      if (transactionId) {
        const { clearTransaction, transactions } = nextProps;
        const transaction = transactions.get(transactionId);
        if (transaction && transaction.isFinished) {
          clearTransaction(transactionId);
          onFinish(nextProps, transaction.payload);
        }
      }
    }

    componentWillUnmount() {
      const { clearTransaction } = this.props;
      const transactionId = getTransactionId(this.props);

      if (transactionId) {
        //  Clear out any transactions we are using so they don't hang around
        //  indefinitely.
        clearTransaction(transactionId);
      }
    }

    render() {
      return (
        <BaseComponent
          {...getComponentProps(includeTransactionProps, this.props)}
        />
      );
    }
  }

  TransactionComponent.propTypes = {
    /**
     * The action creator that is called for clearing a transaction from
     * the transactions state.
     */
    clearTransaction: PropTypes.func.isRequired,

    /**
     * Transactions state.
     */
    transactions: transactionsState,
  };

  return TransactionComponent;
};

export default withTransaction;
