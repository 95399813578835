import { createSlice } from '@reduxjs/toolkit';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';

const TOAST_DURATION_MS = 3000;

// Rails flash messages ('notice' type only) are published to the window object
// so that React can read them on page load in order to show cross-page
// notifications. 'notice'-type messages equate to 'success'. We do not use
// toasts for other Rails flash message types (e.g. 'error'), because these are
// used for form validation etc. so are better suited to traditional banner
// display on rails-only pages.
const initialState = {
  toasts: window.toastNotifications
    ? window.toastNotifications.map(message => ({
        id: uniqueId(),
        isSticky: true,
        status: 'success',
        message: message,
      }))
    : [],
};

const toastNotificationsSlice = createSlice({
  name: 'toastNotifications',
  initialState: initialState,
  reducers: {
    addToast: (state, action) => {
      state.toasts.push(action.payload);
    },
    removeToast: (state, action) => {
      state.toasts = state.toasts.filter(toast => toast.id !== action.payload);
    },
  },
});

const { addToast, removeToast } = toastNotificationsSlice.actions;

/**
 * Toast notification shape
 * {
 *   isSticky: false,
 *   message: 'Contact saved'
 *   status: 'success'
 *   title: 'You Did It'
 * }
 */
const toast = notification => dispatch => {
  // Assign an ID as a hook to allow for removal
  const toastId = uniqueId();
  dispatch(
    addToast({
      ...notification,
      id: toastId,
    }),
  );

  if (!notification.isSticky) {
    setTimeout(() => dispatch(removeToast(toastId)), TOAST_DURATION_MS);
  }
};

const toastError = (message, config) => dispatch => {
  dispatch(
    toast({
      status: 'error',
      message: message,
      ...config,
    }),
  );
};

const toastInfo = (message, config) => dispatch => {
  dispatch(
    toast({
      status: 'info',
      message: message,
      ...config,
    }),
  );
};

const toastSuccess = (message, config) => dispatch => {
  dispatch(
    toast({
      status: 'success',
      message: message,
      ...config,
    }),
  );
};

const toastWarning = (message, config) => dispatch => {
  dispatch(
    toast({
      status: 'warning',
      message: message,
      ...config,
    }),
  );
};

export {
  removeToast,
  toastError,
  toastInfo,
  toastSuccess,
  toastWarning,
  TOAST_DURATION_MS,
};
export default toastNotificationsSlice;
