import selectn from 'selectn';
import { Map } from 'immutable';

const createMetaReducer = ({
  idProperty = 'id',
  path = ['_meta'],
  invalidated,
  fetchStart,
  fetchSuccess,
  fetchFailure,
  updateStart,
  updateSuccess,
  updateFailure,
  deleteStart,
  deleteSuccess,
  deleteFailure,
}) => (state = new Map(), { payload, type }) => {
  switch (type) {
    case invalidated: {
      return state.setIn(
        [selectn(idProperty, payload), ...path, 'isInvalidated'],
        true,
      );
    }
    case fetchStart: {
      return state.setIn(
        [selectn(idProperty, payload), ...path, 'isFetching'],
        true,
      );
    }
    case fetchSuccess: {
      return state
        .mergeIn([selectn(idProperty, payload), ...path], {
          isFetching: false,
          isInvalidated: false,
          lastFetched: Date.now(),
        })
        .deleteIn([selectn(idProperty, payload), ...path, 'error']);
    }
    case fetchFailure: {
      return state.mergeIn([selectn(idProperty, payload), ...path], {
        isFetching: false,
        error: payload.error,
      });
    }
    case updateStart: {
      return state.setIn(
        [selectn(idProperty, payload), ...path, 'isUpdating'],
        true,
      );
    }
    case updateSuccess: {
      return state
        .mergeIn([selectn(idProperty, payload), ...path], {
          isUpdating: false,
          isInvalidated: false,
          lastFetched: Date.now(),
        })
        .deleteIn([selectn(idProperty, payload), ...path, 'error']);
    }
    case updateFailure: {
      return state.mergeIn([selectn(idProperty, payload), ...path], {
        isUpdating: false,
        error: payload.error,
      });
    }
    case deleteStart: {
      return state.setIn(
        [selectn(idProperty, payload), ...path, 'isDeleting'],
        true,
      );
    }
    case deleteSuccess: {
      return state
        .mergeIn([selectn(idProperty, payload), ...path], {
          isDeleting: false,
          isDeleted: true,
        })
        .deleteIn([selectn(idProperty, payload), ...path, 'error']);
    }
    case deleteFailure: {
      return state.mergeIn([selectn(idProperty, payload), ...path], {
        isDeleting: false,
        error: payload.error,
      });
    }
    default: {
      return state;
    }
  }
};

export default createMetaReducer;
