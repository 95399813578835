import { schema } from 'normalizr';
import * as stringUtils from '@thrivetrm/ui/utilities/stringUtils';
import addressSchema from 'modules/addresses/addressSchema';
import companyPrioritySchema from 'modules/tenant/schemas/companyPriorities';
import industrySchema from 'modules/tenant/schemas/industries';
import revenueGrowthSchema from 'modules/tenant/schemas/revenueGrowths';
import sectorSchema from 'modules/tenant/schemas/sectors';
import targetFunctionSchema from 'modules/tenant/schemas/targetFunctions';
import targetMarketSchema from 'modules/tenant/schemas/targetMarkets';

//---------------------------------------------------------
// Aliases
//---------------------------------------------------------

export const companyAliasSchema = new schema.Entity('companyAliases');

//---------------------------------------------------------
// Companies
//---------------------------------------------------------

const companySchema = new schema.Entity(
  'companies',
  {
    aliases: [companyAliasSchema],
    company_priority: companyPrioritySchema,
    industries: [industrySchema],
    primary_address: addressSchema,
    revenue_growth: revenueGrowthSchema,
    sectors: [sectorSchema],
    target_functions: [targetFunctionSchema],
    target_markets: [targetMarketSchema],
  },
  {
    processStrategy: ({ name, ...values }) => ({
      ...values,
      name: name,
      sort_name: stringUtils.toSortableString(name),
    }),
  },
);

companySchema.define({
  parent_company: companySchema,
});

companySchema.define({
  acquired_by_company: companySchema,
});

//---------------------------------------------------------
// Default export
//---------------------------------------------------------

export default companySchema;
