/* eslint-disable react/jsx-props-no-spreading */
// ^ Accommodate legacy code
import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  defaultProps,
  setDisplayName,
  setPropTypes,
  withPropsOnChange,
} from 'recompose';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';

/**
 * A component for making tooltip-wrapped components simpler.
 *
 * Instead of having to use the OverlayTrigger and Tooltip manually (ensuring that we have a
 * unique ID for the tooltip), this lets you simply wrap an element in a TooltipTrigger:
 *
 * <TooltipTrigger tooltip='This is my tooltip text!'>
 *   <MyElement />
 * </TooltipTrigger>
 *
 * All other props except for `tooltip` and `tooltipProps` will be passed through to the
 * `Overlaytrigger` component.
 */
export default compose(
  setDisplayName('TooltipTrigger'),
  setPropTypes({
    /**
     * The tooltip content (can be a string or another react component)
     */
    tooltip: PropTypes.node.isRequired,

    /**
     * Optional additional props that will be passed to the tooltip component.
     */
    tooltipProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  }),
  defaultProps({
    animation: false,
    placement: 'left',
  }),
  withPropsOnChange(
    () => false,
    () => ({
      tooltipId: uniqueId('TooltipTrigger'),
    }),
  ),
  withPropsOnChange(
    ['tooltip', 'tooltipId', 'tooltipProps'],
    ({ tooltip, tooltipId, tooltipProps }) => ({
      overlay: (
        <Tooltip {...tooltipProps} id={tooltipId}>
          {tooltip}
        </Tooltip>
      ),
    }),
  ),
)(OverlayTrigger);
