// eslint-disable-next-line
import qs from 'qs';

const stringifyQueryObject = queryObject =>
  qs.stringify(queryObject, {
    // brackets array format is the format expected by Rails.
    arrayFormat: 'brackets',
    skipNulls: true,
  });

const parseQueryString = querystring =>
  qs.parse(querystring, {
    ignoreQueryPrefix: true,
  });

const URL_REGEX = /(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/;

export { parseQueryString, stringifyQueryObject, URL_REGEX };
