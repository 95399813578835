import { schema } from 'normalizr';
import userSchema from 'modules/users/schema';

const commentSchema = new schema.Entity('comments', {
  user: userSchema,
});

commentSchema.define({
  child_comments: [commentSchema],
});

export default commentSchema;
