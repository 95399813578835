import { connect } from 'react-redux';
import getTenantName from '../selectors/getTenantName';

export default (propName = 'tenantName') =>
  connect(
    state => ({
      [propName]: getTenantName(state),
    }),
    {},
  );
