import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import fetchPositionListActionCreator from '../../actions/positions/fetchPositionList';
import getShouldFetchPositionList from '../../selectors/positions/shouldFetchPositionList';
import getPositionList from '../../selectors/positions/getPositionList';

/**
 * Fetches the list of positions for a contact ID, if needed, from the server, and provides:
 * * `positionList` The list state for the position of the contact
 * * `shouldFetchPositionList` True if positions need to be fetched from the server;
 *   otherwise false.
 * * `fetchPositionList` A handler that can be called to fetch (or refetch) the position
 *   list for the contact (does not need to be called with any parameters)
 * * `fetchPositionListIfNeeded` A handler that can be called to fetch the position list, but
 *   only if needed.
 */
export default compose(
  setDisplayName('withPositionListFetched'),
  setPropTypes({
    contactId: PropTypes.number,
  }),
  connect(
    (state, { contactId }) => ({
      shouldFetchPositionList: getShouldFetchPositionList(state, contactId),
      positionList: getPositionList(state, contactId),
    }),
    dispatch => ({
      fetchPositionList: bindActionCreators(
        fetchPositionListActionCreator,
        dispatch,
      ),
    }),
  ),
  withHandlers({
    /**
     * A handler for fetching the note list specified by the `parentId` and `parentType`
     * props. This allows the component to simply call `fetchPositionList()` without having to
     * supply params.
     */
    fetchPositionList: ({ contactId, fetchPositionList }) => () =>
      fetchPositionList({ contactId: contactId }),

    /**
     * A handler that, when called, only fetches the note list if needed.
     */
    fetchPositionListIfNeeded: ({
      contactId,
      fetchPositionList,
      shouldFetchPositionList,
    }) => () =>
      shouldFetchPositionList && fetchPositionList({ contactId: contactId }),
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchPositionListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchPositionListIfNeeded();
    },
  }),
);
