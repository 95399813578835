import { normalize } from 'normalizr';
import startTransaction from 'modules/transactions/actions/startTransaction';
import finishTransaction from 'modules/transactions/actions/finishTransaction';
import { eachPairRecursive } from '../lib/object';
import ajaxWithJsonDefault, { ajax as ajaxWithoutJson } from './ajax';

export const identity = data => data;
export const addDataToPayload = (payload, data) => ({ data: data, ...payload });
export const addErrorToPayload = (payload, error) => ({
  error: error,
  ...payload,
});
export const createNormalizePayload = schema => (payload, data) => ({
  ...payload,
  ...normalize(data, schema),
});

/**
 * Generates an action creator that dispatches an asynchronous ajax call.
 *
 * @param  {Object} options
 * @param  {Function} [options.createStartPayload] A function that returns the payload that should
 *   dispatched. The method is called with an argument that contains the options that the
 *   action was called with. If not provided, the options passed into the action will be
 *   used as the payload directly.
 * @param  {Function} options.getUrl A function that returns the URL that should be requested.
 *   The method is called with an argument that contains the options that the action was called
 *   with.
 * @param  {Function} [options.getRequestOptions] A function that returns additional options to be
 *   passed to the ajax method (as the second parameter). This is called with the options passed
 *   to the action creator.
 * @param  {Function} [options.ajax] Override the AJAX function. Useful for testing.
 * @param  {Function} [options.afterResolve] A callback that can dispatch actions after
 *   the promise resolves.
 * @param  {String} options.start The action type to dispatch at the start of the request
 * @param  {String} options.success type The action type to dispatch when the request returns a
 *   successful response.
 * @param  {String} options.failure type The action type to dispatch when the request fails
 * @param  {Function} [options.transformResponse] An optional function that will be called with the
 *   server's response, allowing the response to be transformed in some way. By default the
 *   response is not altered. But this could, for example, add some data:
 *   @example
 *   ```
 *   transformResponse: (data) => Object.assign({
 *    foo: 'The response data now has a `foo` property!'
 *   }, data);
 *   ```
 * @param  {Function} [options.createSuccessPayload] An optional function that can be used to
 *   custom the payload sent when dispatching the `success` action. The default implementation
 *   uses the initial payload (@see `createStartPayload`), and adds the server's response to it
 *   as the `data` property. This method is called with the initial payload and the response
 *   from the server:
 *   ```createSuccessPayload(payload, data)```
 * @param  {Function} [options.createErrorPayload] Similar to `createSuccessPayload`, but called
 *   when the ajax call fails, and called with the error in place of the response data:
 *   ```createErrorPayload(payload, error)```
 * @return {Function} A function that returns an action that will dispatch
 *   with the specified type. The function takes a single parameter which
 *   will be passed to `createStartPayload` and `getUrl`.
 * @example
 *   ```
 *   const foobarStarted = createAjaxAction({
 *     start: FOOBAR_FETCH_START,
 *     success: FOOBAR_FETCH_SUCCESS,
 *     failure: FOOBAR_FETCH_FAILURE,
 *     getUrl: (options) => (`/api/foobar/${options.id}`),
 *     createStartPayload: (options) => ({ fooId: options.id, baz: options.baz })
 *   });
 *
 *   dispatch(foobarStarted({
 *     id: 42,
 *     baz: 'blah'
 *   }));
 *   ```
 */
export default function createAjaxAction({
  createStartPayload = identity,
  getUrl,
  getRequestOptions,
  schema,
  start,
  success,
  failure,
  afterResolve,
  afterResolveSuccess,
  afterResolveFailure,
  isJson = true,
  ajax = isJson ? ajaxWithJsonDefault : ajaxWithoutJson,
  transformResponse = identity,
  createSuccessPayload = schema
    ? createNormalizePayload(schema)
    : addDataToPayload,
  createErrorPayload = addErrorToPayload,
}) {
  return function fetchAction({ _ajax, ...options } = {}) {
    return dispatch => {
      const payload = createStartPayload(options);
      const requestOptions = getRequestOptions
        ? getRequestOptions(options, payload)
        : {};

      dispatch({
        type: start,
        payload: payload,
      });

      if (options.transactionId) {
        dispatch(startTransaction(options.transactionId, payload));
      }

      return (_ajax || ajax)(getUrl(options), requestOptions)
        .then(transformResponse)
        .then(data => ({
          type: success,
          payload: createSuccessPayload(payload, data),
        }))
        .catch(error => ({
          type: failure,
          payload: createErrorPayload(payload, error),
        }))
        .then(dispatch)
        .then(action => {
          if (options.transactionId) {
            dispatch(finishTransaction(options.transactionId, action.payload));
          }

          if (afterResolveSuccess && action.type === success) {
            afterResolveSuccess(dispatch, action);
          }

          if (afterResolveFailure && action.type === failure) {
            afterResolveFailure(dispatch, action);
          }

          if (afterResolve) {
            afterResolve(dispatch, action);
          }

          return action;
        });
    };
  };
}

/**
 * Creates an AJAX action that does a submit action (non "GET", really), optionally including a
 * body payload.
 * @param {Object} params Parameters to pass through to `createAjaxAction`
 * @param {String} [params.payloadProperty] The key on the payload data that will limit what
 *   is sent to the server in the body of the request.
 *   For example, if the action was called with multiple parameters:
 *      `theAction({ foo: 'bar', someId: 1, someOtherId: 2 })`
 *   By setting the `payloadKey` to `foo`, only that key will be sent to the server, resulting
 *   in a body including only: `{ foo: 'bar' }`. Without the payloadKey, the entire payload
 *   would be sent: `{ foo: 'bar', someId: 1, someOtherId: 2 }`.
 * @param {String} params.method
 * @return {Function} An action creator.
 * @example */
export const createSubmitAction = ({ method, payloadKey, ...params }) =>
  createAjaxAction({
    getRequestOptions: (options, payload) => {
      const bodyJS = payloadKey
        ? { [payloadKey]: payload[payloadKey] }
        : payload;

      if (process.env.NODE_ENV === 'development') {
        eachPairRecursive(bodyJS, (keys, value) => {
          if (value === undefined) {
            // eslint-disable-next-line no-console
            console.warn(
              `${keys.join(
                '.',
              )} is undefined, which does not get sent to the server. If you want it to go to the server, change it to null`,
            );
          }
        });
      }

      const body = JSON.stringify(bodyJS);

      return { method: method, body: body };
    },
    ...params,
  });

export const createDeleteAction = params =>
  createSubmitAction({
    method: 'DELETE',
    ...params,
  });

export const createPostAction = params =>
  createSubmitAction({
    method: 'POST',
    ...params,
  });

export const createPutAction = params =>
  createSubmitAction({
    method: 'PUT',
    ...params,
  });

export const createPatchAction = params =>
  createSubmitAction({
    method: 'PATCH',
    ...params,
  });
