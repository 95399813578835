import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import asFormGroupField from 'modules/forms/components/asFormGroupField';
import iso8601PropType from '@thrivetrm/ui/propTypes/iso8601PropType';
import { MonthSelect } from './MonthSelectField';
import { YearSelect } from './YearSelectField';

const DateTypes = { MONTH: 'month', YEAR: 'year' };

const MonthYearSelect = ({
  className,
  onChange,
  value: previousValue,
  ...props
}) => {
  const initialDate = previousValue ? new Date(previousValue) : new Date();
  const [month, setMonth] = useState(initialDate.getUTCMonth());
  const [year, setYear] = useState(initialDate.getUTCFullYear());

  useEffect(() => {
    const dateValue = previousValue ? new Date(previousValue) : new Date();
    setMonth(dateValue.getUTCMonth());
    setYear(dateValue.getUTCFullYear());
  }, [previousValue]);

  const handleDateChange = (newValue, dateType) => {
    let updatedMonth = month;
    let updatedYear = year;

    if (dateType === DateTypes.MONTH) {
      updatedMonth = newValue;
      setMonth(newValue);
    } else if (dateType === DateTypes.YEAR) {
      updatedYear = newValue;
      setYear(newValue);
    }

    const updatedDate = new Date(Date.UTC(updatedYear, updatedMonth, 1));
    onChange(updatedDate.toISOString());
  };

  return (
    <div
      className={classnames(
        'forms--month-year-select row no-gutters',
        className,
      )}
    >
      <MonthSelect
        className='forms--month-year-select-month col-7 u-paddingRight-8'
        key='month'
        onChange={newValue => {
          handleDateChange(newValue, DateTypes.MONTH);
        }}
        value={month}
      />
      <YearSelect
        {...props}
        className='forms--month-year-select-year col-5'
        key='year'
        onChange={newValue => {
          handleDateChange(newValue, DateTypes.YEAR);
        }}
        value={year}
      />
    </div>
  );
};

MonthYearSelect.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: iso8601PropType,
};

export default asFormGroupField(MonthYearSelect);
