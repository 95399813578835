import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import queryTags from './queryTags';

// Define a service using a base URL and expected endpoints
const apiV1 = createApi({
  reducerPath: 'apiV1',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: headers => {
      const csrfToken = document.querySelector('meta[name="csrf-token"]')
        ?.content;
      if (csrfToken) {
        headers.set('X-CSRF-Token', csrfToken);
      }
      return headers;
    },
  }),
  tagTypes: Object.values(queryTags),
  endpoints: () => ({}),
});

export default apiV1;
