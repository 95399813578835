import { NAME } from '../constants';

export const MEETINGS_FETCH_START = `${NAME}/MEETINGS_FETCH_START`;
export const MEETINGS_FETCH_SUCCESS = `${NAME}/MEETINGS_FETCH_SUCCESS`;
export const MEETINGS_FETCH_FAILURE = `${NAME}/MEETINGS_FETCH_FAILURE`;
export const MEETINGS_INVALIDATE = `${NAME}/MEETINGS_INVALIDATE`;

export const MEETING_FETCH_START = `${NAME}/MEETING_FETCH_START`;
export const MEETING_FETCH_SUCCESS = `${NAME}/MEETING_FETCH_SUCCESS`;
export const MEETING_FETCH_FAILURE = `${NAME}/MEETING_FETCH_FAILURE`;
export const MEETING_INVALIDATE = `${NAME}/MEETING_INVALIDATE`;

export const MEETING_CREATE_START = `${NAME}/MEETING_CREATE_START`;
export const MEETING_CREATE_SUCCESS = `${NAME}/MEETING_CREATE_SUCCESS`;
export const MEETING_CREATE_FAILURE = `${NAME}/MEETING_CREATE_FAILURE`;

export const MEETING_UPDATE_START = `${NAME}/MEETING_UPDATE_START`;
export const MEETING_UPDATE_SUCCESS = `${NAME}/MEETING_UPDATE_SUCCESS`;
export const MEETING_UPDATE_FAILURE = `${NAME}/MEETING_UPDATE_FAILURE`;

export const MEETING_DELETE_START = `${NAME}/MEETING_DELETE_START`;
export const MEETING_DELETE_SUCCESS = `${NAME}/MEETING_DELETE_SUCCESS`;
export const MEETING_DELETE_FAILURE = `${NAME}/MEETING_DELETE_FAILURE`;
