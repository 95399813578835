import selectn from 'selectn';
import { isEqual } from 'lodash';
import { Map } from 'immutable';
import createListReducer from '../../../reducers/createListReducer';
import {
  SEARCHES_FETCH_START,
  SEARCHES_FETCH_SUCCESS,
  SEARCHES_FETCH_FAILURE,
  SEARCHES_INVALIDATE,
} from '../actions/searches/actionTypes';

const INITIAL_STATE = new Map();

const searchesListReducer = createListReducer({
  resultKey: 'searches',
  request: SEARCHES_FETCH_START,
  success: SEARCHES_FETCH_SUCCESS,
  failure: SEARCHES_FETCH_FAILURE,
  invalidated: SEARCHES_INVALIDATE,
});

/**
 * A map of search lists keyed by either:
 * 1. a unique list ID; or
 * 2. By their parent type + ID
 */
export default (state = INITIAL_STATE, action) => {
  const listId = selectn('payload.listId', action);

  if (!listId) {
    return state;
  }

  const fetchParams = selectn('payload.fetchParams', action);

  switch (action.type) {
    case SEARCHES_FETCH_START: {
      return (
        state
          .update(listId, listState => searchesListReducer(listState, action))
          // It's important to note that we are NOT converting fetchParams into
          // an Immutable -- this just makes it easier to compare them
          // (below, and in `shouldFetchSearchesList`) -- which is really the main
          // reason we have them anyway.
          .setIn([listId, '_meta', 'fetchParams'], fetchParams)
      );
    }
    case SEARCHES_FETCH_FAILURE:
    case SEARCHES_FETCH_SUCCESS: {
      // Check to make sure that the current fetchParams of the list match the action we're
      // processing. This assures that if we make 2 requests in quick succession --
      // say "page=1" then "page=2", that once we get a responses we only care about the most
      // recent request. Otherwise the response for page 1 could potentially overwrite the
      // response for page 2 if they came back in a different order than requested.
      if (
        !isEqual(
          selectn('payload.fetchParams', action),
          state.getIn([listId, '_meta', 'fetchParams']),
        )
      ) {
        return state;
      }

      return state.update(listId, listState =>
        searchesListReducer(listState, action),
      );
    }
    case SEARCHES_INVALIDATE: {
      return state.update(listId, listState =>
        searchesListReducer(listState, action),
      );
    }
    default: {
      return state;
    }
  }
};
