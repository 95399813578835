import PropTypes from 'prop-types';
import React from 'react';
import { fromJS } from 'immutable';
import classnames from 'classnames';

import YearSelectField from 'modules/datetime/components/YearSelectField';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import TextAreaField from 'modules/forms/components/TextAreaField';
import DateInputField from 'modules/datetime/components/DateInputField';
import UserSelectField from 'modules/users/components/UserSelectField';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import isCandidateUser from 'modules/auth/selectors/isCandidateUser';
import { FILTER_EMPLOYEES } from 'modules/users/constants';
import { useSelector } from 'react-redux';

const LAYOUTS = {
  default: {
    school_name: 'col-6',
    degree: 'col-6',
    start_year: 'col-6',
    end_year: 'col-6',
    description: 'col-12',
    verified_by: 'col-6',
    verified_on: 'col-6',
  },
  narrow: {
    school_name: 'col-12',
    degree: 'col-12',
    start_year: 'col-6',
    end_year: 'col-6',
    description: 'col-12',
    verified_by: 'col-6',
    verified_on: 'col-6',
  },
};

export const FIELD_LAYOUTS = Object.keys(LAYOUTS);

/**
 * Displays a form for editing a contact's education information.
 */

const createFieldState = (name = 'education', education) => {
  const values = fromJS({
    degree: '',
    school_name: '',
    description: '',
    start_year: null,
    end_year: null,
  }).merge(education);

  return FieldState.createNested(
    name,
    [
      InputField.createFieldState('degree', values.get('degree')),
      InputField.createFieldState('school_name', values.get('school_name')),
      YearSelectField.createFieldState('start_year', values.get('start_year')),
      YearSelectField.createFieldState('end_year', values.get('end_year')),
      TextAreaField.createFieldState('description', values.get('description')),
      DateInputField.createFieldState(
        'verified_on',
        values.get('verified_on') || new Date(),
      ),
      UserSelectField.createFieldState(
        'verified_by_id',
        values.get('verified_by_id'),
      ),
    ],
    null,
    fieldValue => ({
      ...fieldValue,
      // Only track verification date if we have a "verified by" user
      verified_on: fieldValue.verified_by_id ? fieldValue.verified_on : null,
    }),
  );
};

export const EducationField = ({
  fieldState,
  layout,
  onChange,
  ...otherProps
}) => {
  const fieldLayout = LAYOUTS[layout];
  const hasEducationVerificationFeature = useFeatureCheck(
    'feature.education_verification',
  );
  const isCandidate = useSelector(state => isCandidateUser(state));
  const handleFieldChange = childFieldState => {
    onChange(fieldState.setNestedField(childFieldState));
  };

  return (
    <div className='EducationField row'>
      <div className={classnames(fieldLayout.school_name)}>
        <InputField
          {...otherProps}
          fieldState={fieldState.getNestedField('school_name')}
          label='School Name'
          onChange={handleFieldChange}
        />
      </div>
      <div className={classnames(fieldLayout.degree)}>
        <InputField
          {...otherProps}
          fieldState={fieldState.getNestedField('degree')}
          label='Degree'
          onChange={handleFieldChange}
        />
      </div>
      <div className={classnames(fieldLayout.start_year)}>
        <YearSelectField
          {...otherProps}
          fieldState={fieldState.getNestedField('start_year')}
          label='Start Year'
          minYear={-65}
          name='start_year'
          onChange={handleFieldChange}
        />
      </div>
      <div className={classnames(fieldLayout.end_year)}>
        <YearSelectField
          {...otherProps}
          fieldState={fieldState.getNestedField('end_year')}
          label='End Year'
          maxYear={6}
          minYear={-65}
          name='end_year'
          onChange={handleFieldChange}
        />
      </div>
      <div className={classnames(fieldLayout.description)}>
        <TextAreaField
          {...otherProps}
          fieldState={fieldState.getNestedField('description')}
          label='Description'
          onChange={handleFieldChange}
        />
      </div>
      {hasEducationVerificationFeature && !isCandidate ? (
        <>
          <div className={classnames(fieldLayout.verified_by)}>
            <UserSelectField
              className='ContactEducationField__verified_by'
              fieldState={fieldState.getNestedField('verified_by_id')}
              filter={FILTER_EMPLOYEES}
              label='Verified By'
              onChange={handleFieldChange}
            />
          </div>
          {fieldState.getNestedField('verified_by_id').getValue() ? (
            <div className={classnames(fieldLayout.verified_on)}>
              <DateInputField
                className='EducationField__verifiedOn'
                dateFormat='MMM d, yyyy'
                fieldState={fieldState.getNestedField('verified_on')}
                label='Verified On'
                onChange={handleFieldChange}
              />
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

EducationField.propTypes = {
  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * The way in which the fields are placed/sized
   */
  layout: PropTypes.oneOf(['default', 'narrow']),

  /**
   * Called when the value is changed, with the upcdated fieldState.
   * The underlying alue will be either the selected date, or null of "isCurrent" is checked.
   */
  onChange: PropTypes.func.isRequired,
};

EducationField.defaultProps = {
  layout: 'default',
};

EducationField.createFieldState = createFieldState;

export default EducationField;
