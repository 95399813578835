/* eslint-disable no-useless-escape */
export const URL_VALIDATION_REGEX = /^(?:https?:\/\/)?[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,}/;
/* eslint-enable no-useless-escape */
export const URL_VALIDATION_FAILURE_MESSAGE = 'This is not a valid url';

export function isValidUrl(url) {
  return URL_VALIDATION_REGEX.test(url);
}

/**
 * Validates a single url.
 */

const isUrl = fieldState => {
  const url = fieldState.getValue();
  if (url && !isValidUrl(url)) {
    return URL_VALIDATION_FAILURE_MESSAGE;
  }

  return null;
};

export default isUrl;
