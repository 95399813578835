import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import isCrmUserSelector from 'modules/auth/selectors/isCrmUser';
import useQuickView from 'modules/quick-view/useQuickView';
import OffLimitsStatus from './OffLimitsStatus';

const SEVERITY_TO_STATUS_MAP = {
  danger: 'error',
  info: 'info',
  warning: 'warning',
};

const OffLimitsIndicator = ({
  className,
  isDisabled,
  reason,
  recordId,
  recordName,
  recordType,
  severity,
  ...props
}) => {
  const { navigateTo } = useQuickView();
  const isCrmUser = useSelector(isCrmUserSelector);

  const buttonClassName = classnames(
    className,
    'u-backgroundColor-transparent',
    'u-border-n',
    'u-padding-n',
  );

  return (
    <button
      {...props}
      className={buttonClassName}
      disabled={isCrmUser || isDisabled}
      onClick={() =>
        navigateTo(`/${recordType}/${recordId}/off-limits`, {
          title: recordName,
        })
      }
      type='button'
    >
      <OffLimitsStatus
        isHoverable={!(isCrmUser || isDisabled)}
        severity={severity}
        status={reason}
      />
    </button>
  );
};

OffLimitsIndicator.defaultProps = {
  className: null,
  isDisabled: false,
};

OffLimitsIndicator.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  reason: PropTypes.string.isRequired,
  recordId: PropTypes.number.isRequired,
  recordName: PropTypes.string.isRequired,
  recordType: PropTypes.oneOf(['company', 'contact']).isRequired,
  severity: PropTypes.oneOf(Object.keys(SEVERITY_TO_STATUS_MAP)).isRequired,
};

export default OffLimitsIndicator;
