import { connect } from 'react-redux';
import getPosition from '../selectors/getPosition';

/**
 * A higher order component which maps a `positionId` prop value to it's position record.
 */
export default connect(
  (state, { positionId }) => ({
    position: getPosition(state, positionId),
  }),
  {},
);
