import { createSlice } from '@reduxjs/toolkit';
import url from 'url';
import omit from 'lodash/omit';
import { stringifyQueryObject } from 'modules/core/urlUtils';
import Api, { getErrorMessage } from 'modules/core/Api';
// TODO: these utils should be moved to core/objectUtils with tests written
import { convertToCamelCase, convertToSnakeCase } from 'modules/core/jsonUtils';
import routes from 'modules/routing/routes';

const RESULTS_PER_PAGE_DEFAULT = '25';
const RESULTS_PER_PAGE_LOCALSTORAGE_KEY = 'pipelinesIndexResultsPerPage';

const initialState = {
  recordList: null,
  currentPage: 1,
  resultsPerPage:
    localStorage.getItem(RESULTS_PER_PAGE_LOCALSTORAGE_KEY) ||
    RESULTS_PER_PAGE_DEFAULT,
  totalPages: 1,
  filters: {},
  sortKey: null,
  sortDirection: null,
  isLoadingPipelineList: true,
  pipelineListRequestError: null,
  selectedColumns: null,
  shouldUpdateBrowserUrl: false,
};

const pipelineSlice = createSlice({
  name: 'pipeline',
  initialState: initialState,
  reducers: {
    requestPipelineListBegin: state => {
      state.isLoadingPipelineList = true;
      state.pipelineListRequestError = null;
    },
    requestPipelineListSuccess: (state, action) => {
      state.isLoadingPipelineList = false;
      state.recordList = action.payload.recordList;
      state.selectedColumns = action.payload.selectedColumns;
      state.totalPages = action.payload.totalPages;
      state.sortKey = action.payload.sortKey;
      state.sortDirection = action.payload.sortDirection;
      state.shouldUpdateBrowserUrl = true;
    },
    requestPipelineListError: (state, action) => {
      state.isLoadingPipelineList = false;
      state.pipelineListRequestError = action.payload;
    },
    setResultsPerPage: (state, action) => {
      state.resultsPerPage = action.payload;
      state.currentPage = 1;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.currentPage = 1;
    },
    setSortParams: (state, action) => {
      state.sortKey = action.payload.sortKey;
      state.sortDirection = action.payload.sortDirection;
      state.currentPage = 1;
    },
  },
});

const {
  requestPipelineListBegin,
  requestPipelineListError,
  requestPipelineListSuccess,
  setCurrentPage,
  setFilters,
  setResultsPerPage,
  setSortParams,
} = pipelineSlice.actions;

const createEndpointUrl = ({
  currentPage,
  filters,
  resultsPerPage,
  sortDirection,
  sortKey,
}) => {
  const params = convertToSnakeCase({
    view: 'table_view',
    page: currentPage,
    filters: filters,
    resultsPerPage: resultsPerPage,
    sortDirection: sortDirection,
    sortField: sortKey,
  });

  const newUrl = url.format({
    pathname: routes.api_v1_pipelines(),
    search: stringifyQueryObject(params),
  });

  return newUrl;
};

const updateBrowserUrl = ({
  currentPage,
  filters,
  resultsPerPage,
  sortDirection,
  sortKey,
}) => {
  const params = convertToSnakeCase({
    filters: filters,
    page: currentPage,
    resultsPerPage: resultsPerPage,
    sortDirection: sortDirection,
    sortField: sortKey,
  });

  const newUrl = url.format({
    pathname: window.location.pathname,
    search: stringifyQueryObject(params),
  });

  window.history.pushState(null, null, newUrl);
};

const formatColumns = columns =>
  columns.map(column => {
    const { columnSize, key, ...rest } = column;
    return {
      name: key,
      width: columnSize,
      ...rest,
    };
  });

const handleResponse = data => {
  const response = convertToCamelCase(data);
  const {
    columns,
    resultsPerPage,
    sortDirection,
    sortField,
    totalPages,
  } = response.metadata;
  const selectedColumns = formatColumns(columns);
  return {
    selectedColumns: selectedColumns,
    recordList: data.pipelines.map(record => ({
      id: record[0].id,
      attributes: selectedColumns.map(column => ({
        ...record.find(attribute => column.name === attribute.key),
        ...column,
      })),
    })),
    totalPages: totalPages,
    resultsPerPage: resultsPerPage,
    sortDirection: sortDirection,
    sortKey: sortField,
  };
};

const requestPipelineList = () => (dispatch, getState) => {
  const { pipeline } = getState();
  dispatch(requestPipelineListBegin());
  const { shouldUpdateBrowserUrl } = pipeline;
  const endpointUrl = createEndpointUrl(pipeline);
  Api.get(endpointUrl)
    .then(json => handleResponse(json))
    .then(pipelineList => dispatch(requestPipelineListSuccess(pipelineList)))
    .then(() => {
      if (shouldUpdateBrowserUrl) {
        updateBrowserUrl(pipeline);
      }
    })
    .catch(error => dispatch(requestPipelineListError(getErrorMessage(error))));
};

const changeFilters = filters => (dispatch, getState) => {
  const { pipeline } = getState();
  dispatch(setFilters({ ...pipeline.filters, ...filters }));
  dispatch(requestPipelineList());
};

const removeFilter = filterKey => (dispatch, getState) => {
  const { pipeline } = getState();
  const newFilters = omit(pipeline.filters, filterKey);
  dispatch(setFilters(newFilters));
  dispatch(requestPipelineList());
};

const clearFilters = () => dispatch => {
  dispatch(setFilters({}));
  dispatch(requestPipelineList());
};

const saveResultsPerPage = val => dispatch => {
  dispatch(setResultsPerPage(val));
  localStorage.setItem(RESULTS_PER_PAGE_LOCALSTORAGE_KEY, val);
};

export {
  changeFilters,
  clearFilters,
  removeFilter,
  requestPipelineList,
  saveResultsPerPage,
  setCurrentPage,
  setFilters,
  setSortParams,
};
export default pipelineSlice;
