import { createSlice } from '@reduxjs/toolkit';
import Api, { getErrorMessage } from '../core/Api';

const initialState = {
  attributesByCategory: null,
  baseRecord: null,
  hasMadeAllSelections: false,
  isLoadingAttributes: true,
  isLoadingBaseRecord: true,
  isMerging: false,
  isMergeComplete: false,
  loadAttributesError: null,
  loadBaseRecordError: null,
  mergeRecordsError: null,
};

// Takes the full data shape of attributes and returns a single attribute
const findAttributeByName = (attributesByCategory, attributeName) =>
  attributesByCategory
    .flatMap(category => category.attributes)
    .find(attribute => attribute.name === attributeName);

// Returns true if all attributes have a selected record (either 'a' or 'b')
const hasMadeAllSelections = attributesByCategory =>
  attributesByCategory.every(category =>
    category.attributes.every(attribute => attribute.selected_record),
  );

// Takes the full data shape of attributes and returns a simple object
// with a shape of { attributeName: attributeValue }. This object is
// only populated with attributes where the 'b' record has been
// selected, since a selection of 'a' means "nothing to do" for the
// backend (those values are already on the record).
const getRecordBSelections = attributesByCategory => {
  const recordBSelections = {};

  attributesByCategory
    .flatMap(category => category.attributes)
    .filter(attribute => attribute.selected_record === 'b')
    .forEach(attribute => {
      recordBSelections[attribute.name] = attribute.record_b;
    });
  return recordBSelections;
};

const duplicateMergeSlice = createSlice({
  name: 'duplicateMerge',
  initialState: initialState,
  reducers: {
    loadBaseRecordBegin: state => {
      state.isLoadingBaseRecord = true;
      state.loadBaseRecordError = null;
    },
    loadBaseRecordSuccess: (state, action) => {
      state.isLoadingBaseRecord = false;
      state.baseRecord = action.payload;
    },
    loadBaseRecordError: (state, action) => {
      state.isLoadingBaseRecord = false;
      state.loadBaseRecordError = action.payload;
    },

    loadAttributesBegin: state => {
      state.isLoadingAttributes = true;
      state.loadAttributesError = null;
    },
    loadAttributesSuccess: (state, action) => {
      state.isLoadingAttributes = false;
      state.attributesByCategory = action.payload;
      state.hasMadeAllSelections = hasMadeAllSelections(action.payload);
    },
    loadAttributesError: (state, action) => {
      state.isLoadingAttributes = false;
      state.loadAttributesError = action.payload;
    },
    updateAttributeSelection: (state, action) => {
      const { attributesByCategory } = state;
      const { attributeName, selectedRecord } = action.payload;
      const attributeToUpdate = findAttributeByName(
        attributesByCategory,
        attributeName,
      );
      attributeToUpdate.selected_record = selectedRecord;
      state.hasMadeAllSelections = hasMadeAllSelections(attributesByCategory);
    },

    mergeRecordsBegin: state => {
      state.isMerging = true;
      state.mergeRecordsError = null;
    },
    mergeRecordsSuccess: state => {
      state.isMerging = false;
      state.isMergeComplete = true;
    },
    mergeRecordsError: (state, action) => {
      state.isMerging = false;
      state.mergeRecordsError = action.payload;
    },
  },
});

const {
  loadAttributesBegin,
  loadAttributesError,
  loadAttributesSuccess,
  loadBaseRecordBegin,
  loadBaseRecordError,
  loadBaseRecordSuccess,
  mergeRecordsBegin,
  mergeRecordsError,
  mergeRecordsSuccess,
  updateAttributeSelection,
} = duplicateMergeSlice.actions;

const loadBaseRecord = endpointUrl => dispatch => {
  dispatch(loadBaseRecordBegin());
  Api.get(endpointUrl)
    .then(record => {
      dispatch(loadBaseRecordSuccess(record));
    })
    .catch(error => {
      let errorMessage = getErrorMessage(error);
      if (errorMessage?.includes('Not found')) {
        errorMessage = "Sorry, we couldn't find that record.";
      }
      dispatch(loadBaseRecordError(errorMessage));
    });
};

const loadAttributes = endpointUrl => dispatch => {
  dispatch(loadAttributesBegin());
  Api.get(endpointUrl)
    .then(data => dispatch(loadAttributesSuccess(data)))
    .catch(error => {
      let errorMessage = getErrorMessage(error);
      if (errorMessage?.includes('Not found')) {
        errorMessage = "Sorry, we couldn't find one or both of these records.";
      }
      dispatch(loadAttributesError(errorMessage));
    });
};

const mergeRecords = endpointUrl => (dispatch, getState) => {
  dispatch(mergeRecordsBegin());
  const { attributesByCategory } = getState().duplicateMerge;
  const recordBSelections = getRecordBSelections(attributesByCategory);

  Api.patch(endpointUrl, { merge: recordBSelections })
    .then(data => {
      dispatch(mergeRecordsSuccess(data));
    })
    .catch(error => {
      dispatch(mergeRecordsError(getErrorMessage(error)));
    });
};

export default duplicateMergeSlice;
export {
  loadBaseRecord,
  loadAttributes,
  mergeRecords,
  updateAttributeSelection,
};
