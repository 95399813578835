import routes from 'modules/routing/routes';
import updateEntity from 'modules/entities/actions/updateEntity';
import { educationSchema } from '../../schema';

/**
 * Creates an action to update an education record
 * @param {Object} params
 * @param {Object} params.education
 * @param {Number} params.education.id The ID of the education record being updated.
 * @param {String} [params.transactionId]
 */
export default ({ education, transactionId }) =>
  updateEntity({
    body: { education: education },
    responseSchema: { education: educationSchema },
    url: routes.api_v1_education({ id: education.id }),
    id: education.id,
    transactionId: transactionId,
  });
