import {
  NOTIFICATIONS_CATEGORY_UNREAD,
  NOTIFICATIONS_UPDATE_ALL_READ_SUCCESS,
  NOTIFICATIONS_UPDATE_READ_SUCCESS,
} from '../../domains/notifications/constants';
import forCategory, {
  INITIAL_STATE as INITIAL_NOTIFICATIONS_STATE,
} from './forCategory';

export const INITIAL_STATE = INITIAL_NOTIFICATIONS_STATE.setIn(
  ['meta', 'category'],
  NOTIFICATIONS_CATEGORY_UNREAD,
);

/**
 * A reducer for a collection of notifications that only fetches notifications that are in an
 * unread state
 */
export default function unreadNotifications(state = INITIAL_STATE, action) {
  // First apply the reducer for our unread category.
  const updatedState = forCategory(
    NOTIFICATIONS_CATEGORY_UNREAD,
    state,
    action,
  );

  // In the case of the unread collection, `count` is the count of unread notifications, but
  // does not actually refelct the number of notifications currrently in the collection. This
  // is because when an unread notiication becomes read, we don't remove it immediately --
  // we just invalidate the collection so it is refetched the next time it is viewed. But we
  // want the count that we display to reflect the actual number of unread notifications.

  switch (action.type) {
    case NOTIFICATIONS_UPDATE_ALL_READ_SUCCESS: {
      return updatedState
        .setIn(['meta', 'isInvalidated'], true)
        .setIn(['meta', 'count'], 0);
    }
    case NOTIFICATIONS_UPDATE_READ_SUCCESS: {
      return updatedState
        .setIn(['meta', 'isInvalidated'], true)
        .updateIn(
          ['meta', 'count'],
          count => count && count + (action.payload.read ? -1 : 1),
        );
    }
    default: {
      return updatedState;
    }
  }
}
