import { connect } from 'react-redux';
import * as sortUtils from 'modules/core/sortUtils';
import getCandidacyRank from '../selectors/getCandidacyRank';
import getCandidacySortCompanyName from '../selectors/getCandidacySortCompanyName';

/**
 * A Higher order component that sorts a list of candidacyIds in order of primary company name»
 */
export default connect(
  (state, { candidacyIds }) => ({
    candidacyIds:
      candidacyIds &&
      candidacyIds.sortBy(
        id => [
          getCandidacySortCompanyName(state, id),
          getCandidacyRank(state, id),
        ],
        sortUtils.arrayComparator,
      ),
  }),
  {},
);
