import {
  toSortableString,
  removeDiacritics,
} from '@thrivetrm/ui/utilities/stringUtils';
import getCandidacyProperty from './getCandidacyProperty';
import getCandidacyEmail from './getCandidacyEmail';
import getCandidacyFullName from './getCandidacyFullName';
import getCandidacySortCompanyName from './getCandidacySortCompanyName';

/**
 * Gets a string of text for filtering a candidacy by keywords
 * Currently includes:
 * - Contact's full names
 * - Contact's email
 * - Current company name
 * - Targetted company names
 * @param {Object} state
 * @param {Number} candidacyId The candidacy's ID
 * @return {String} A string of joined fields that can be filtered against.
 */
export default (state, candidacyId) => {
  const targetedCompanies = getCandidacyProperty(
    state,
    candidacyId,
    'active_target_companies',
  );
  return removeDiacritics(
    toSortableString(
      [
        getCandidacyFullName(state, candidacyId),
        getCandidacyEmail(state, candidacyId),
        getCandidacySortCompanyName(state, candidacyId),
        targetedCompanies && targetedCompanies.join(' '),
      ].join(' '),
    ),
  );
};
