import selectn from 'selectn';
import { fromJS, Map } from 'immutable';
import createListReducer from '../../../reducers/createListReducer';
import composeReducers from '../../../reducers/composeReducers';
import {
  CONTACT_FETCH_START,
  CONTACT_FETCH_FAILURE,
  CONTACT_UPDATE_SUCCESS,
  CONTACT_INVALIDATE,
} from '../actions/contacts/actionTypes';
import {
  DIRECT_REPORTS_CREATE_SUCCESS,
  DIRECT_REPORTS_INVALIDATE,
  DIRECT_REPORTS_FETCH_START,
  DIRECT_REPORTS_FETCH_SUCCESS,
  DIRECT_REPORTS_FETCH_FAILURE,
} from '../actions/directReports/actionTypes';
import { contactSchema } from '../schema';

const directReportsListReducer = createListReducer({
  resultKey: 'direct_reports',
  request: DIRECT_REPORTS_FETCH_START,
  success: DIRECT_REPORTS_FETCH_SUCCESS,
  failure: DIRECT_REPORTS_FETCH_FAILURE,
  invalidated: DIRECT_REPORTS_INVALIDATE,
});

// directReportsByContactId is keyed on the contact Id, obviously.
// direct reports may also be included on the contact entity itself in some cases, so we check
// for that as well.
export default composeReducers(
  (state = new Map(), action) => {
    switch (action.type) {
      case CONTACT_FETCH_START: {
        // See if direct reports have been requested along with the contact data.
        const contactId = selectn('payload.id', action);
        const fields = selectn('payload.fields', action);

        if (contactId && fields && fields.includes('direct_reports')) {
          return state.setIn([contactId, '_meta', 'isFetching'], true);
        }

        return state;
      }
      case CONTACT_FETCH_FAILURE: {
        // See if direct reports have been requested along with the contact data.
        const contactId = selectn('payload.id', action);
        const fields = selectn('payload.fields', action);

        if (contactId && fields && fields.includes('direct_reports')) {
          return state
            .setIn([contactId, '_meta', 'isFetching'], false)
            .setIn([contactId, '_meta', 'error'], action.payload.error);
        }

        return state;
      }
      // CONTACT_FETCH_SUCCESS is handled by the default case below
      case CONTACT_INVALIDATE: {
        // When the contact is invalidated, invalidate the direct reports as well.
        const contactId = selectn('payload.id', action);

        if (contactId && state.has(contactId)) {
          return state.setIn([contactId, '_meta', 'isInvalidated'], true);
        }

        return state;
      }
      case CONTACT_UPDATE_SUCCESS: {
        const contactId = selectn('payload.result.contact', action);
        const contact = selectn(
          `payload.entities.contacts.${contactId}`,
          action,
        );

        if (
          contact &&
          Object.prototype.hasOwnProperty.call(contact, 'reports_to')
        ) {
          // If the reports_to relationship has changed then we need to update the related
          // direct reports lists.
          const reportsToId = contact.reports_to;
          return (
            state
              // If we have direct reports loaded for the current `reports_to` value, make sure
              // it includes the contact's ID.
              .update(reportsToId, reportsState =>
                reportsState &&
                reportsState.has('ids') &&
                !reportsState.get('ids').includes(contactId)
                  ? reportsState.update('ids', ids => ids.push(contactId))
                  : reportsState,
              )
              // Make sure any other direct reports lists no longer contain this contact's ID.
              .map((reportsState, parentId) =>
                reportsState &&
                reportsState.has('ids') &&
                parentId !== reportsToId &&
                reportsState.get('ids').includes(contactId)
                  ? reportsState.update('ids', ids =>
                      ids.filter(id => id !== contactId),
                    )
                  : reportsState,
              )
          );
        }

        return state;
      }
      case DIRECT_REPORTS_FETCH_START:
      case DIRECT_REPORTS_FETCH_SUCCESS:
      case DIRECT_REPORTS_FETCH_FAILURE: {
        const contactId = selectn('payload.contactId', action);

        if (contactId) {
          return state.update(contactId, list =>
            directReportsListReducer(list, action),
          );
        }

        return state;
      }
      case DIRECT_REPORTS_INVALIDATE: {
        const contactId = selectn('payload.contactId', action);

        if (contactId && state.has(contactId)) {
          return state.setIn([contactId, '_meta', 'isInvalidated'], true);
        }

        return state;
      }
      case DIRECT_REPORTS_CREATE_SUCCESS: {
        const contactId = selectn('payload.result.contact', action);

        if (contactId && state.hasIn([contactId, 'ids'])) {
          const directReportId = selectn(
            'payload.result.direct_report',
            action,
          );
          if (directReportId) {
            return state.updateIn([contactId, 'ids'], ids =>
              ids.push(directReportId),
            );
          }
        }

        return state;
      }
      default: {
        return state;
      }
    }
  },
  (state, action) => {
    // Check to see if any contact entities have been fetched, and if they have direct_reports
    // included.
    const contacts = selectn('payload.entities.contacts', action);

    if (contacts) {
      const lists = Object.keys(contacts)
        .map(contactId => contacts[contactId])
        .filter(contact => contact.direct_reports)
        .map(contact => [
          contactSchema.getId(contact),
          fromJS({
            ids: contact.direct_reports,
            _meta: {
              error: null,
              isFetching: false,
              isInvalidated: false,
              lastFetched: Date.now(),
            },
          }),
        ]);

      if (lists.length) {
        return state.mergeDeep(lists);
      }
    }

    return state;
  },
);
