import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { fetchCandidacyList as fetchCandidacyListCreator } from '../actions';
import shouldFetchCandidacyListSelector from '../selectors/shouldFetchCandidacyList';
import getCandidacyListSelector from '../selectors/getCandidacyList';
import { PARENT_TYPES } from '../constants';

/**
 * Fetches the candidacies for a parent type if they need to be fetched, and provides the resulting
 * list as a prop to the base component.
 * @param {Object} options
 * @param {String} [options.candidacyListPropName="candidacyList"] The name of the outgoing prop
 *   that will ultimately be used to provide the candidacy list state to the base component.
 * @param {String} [options.parentTypePropName="parentType"] The name of the incoming prop that
 *   will be expected to specify the parent type to fetch the list of candidacies for.
 * @param {String} [options.parentIdPropName="parentId"] The name of the incoming prop that will
 *   be expected to specify the parent ID to fetch the list of candidacies for.
 * @return {Function} a higher-order function that fetches and provides the candidacy list based
 *   on the options and props provided.
 */
export default ({
  candidacyListPropName = 'candidacyList',
  parentIdPropName = 'parentId',
  parentTypePropName = 'parentType',
} = {}) =>
  compose(
    setDisplayName('withCandidacyListFetched'),
    setPropTypes({
      [parentTypePropName]: PropTypes.oneOf(PARENT_TYPES),
      [parentIdPropName]: PropTypes.number,
    }),
    mapProps(props => ({
      incomingProps: props,
      parentType: props[parentTypePropName],
      parentId: props[parentIdPropName],
    })),
    connect(
      (state, { parentId, parentType }) => ({
        shouldFetchCandidacyList: shouldFetchCandidacyListSelector(
          state,
          parentType,
          parentId,
        ),
        candidacyList: getCandidacyListSelector(state, parentType, parentId),
      }),
      {
        fetchCandidacyList: fetchCandidacyListCreator,
      },
    ),
    withHandlers({
      fetchCandidacyList: ({
        fetchCandidacyList,
        parentId,
        parentType,
      }) => () =>
        fetchCandidacyList({ parentId: parentId, parentType: parentType }),

      fetchCandidacyListIfNeeded: ({
        fetchCandidacyList,
        parentId,
        parentType,
        shouldFetchCandidacyList,
      }) => () =>
        shouldFetchCandidacyList &&
        fetchCandidacyList({ parentId: parentId, parentType: parentType }),
    }),

    lifecycle({
      UNSAFE_componentWillMount: function () {
        this.props.fetchCandidacyListIfNeeded();
      },
      UNSAFE_componentWillReceiveProps: function (nextProps) {
        nextProps.fetchCandidacyListIfNeeded();
      },
    }),
    mapProps(({ candidacyList, incomingProps }) => ({
      [candidacyListPropName]: candidacyList,
      ...incomingProps,
    })),
  );
