import shouldFetchEntity from 'modules/entities/selectors/shouldFetchEntity';
import isFetchingEntity from 'modules/entities/selectors/isFetchingEntity';
import { candidacySchema, candidacyStatusSchema } from '../schemas';

/**
 * Determines if a candidacy's status needs to be fetched from the server.
 * @param {Object} state
 * @param {Number} candidacyId The ID of the candidacy
 * @return {Boolean} True if the candidacy's status needs to be fetched from the server; otherwise,
 *   false
 */
export default (state, candidacyId) =>
  shouldFetchEntity(state, candidacyStatusSchema.key, candidacyId) &&
  // If the candidacy record itself is being fetched, it will include the status.
  !isFetchingEntity(state, candidacySchema.key, candidacyId);
