import getEntitiesMap from 'modules/entities/selectors/getEntitiesMap';
import companySchema from '../companySchema';

/**
 * Attempts to find the company record for a company given it's name.
 * @param {Object} state
 * @param {String} name The company name
 * @returns {Immutable.Map|undefined} The company record, or `undefined` if it wasn't found.
 */
const findCompanyByName = (state, name) => {
  const loweredName = name && name.toLowerCase().trim();

  if (!loweredName) {
    return null;
  }

  const companies = getEntitiesMap(state, companySchema.key);

  if (companies) {
    return companies.find(
      company => company.get('name').toLowerCase() === loweredName,
    );
  }

  return null;
};

export default findCompanyByName;
