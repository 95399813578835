import {
  compose,
  lifecycle,
  mapProps,
  withHandlers,
  withState,
} from 'recompose';

import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import connectTransactions from './connectTransactions';
import mapTransactionIdToTransaction from './mapTransactionIdToTransaction';

/**
 * @deprecated Use `withTransactionTracking` instead.
 *
 * A higher order componenet function that manages the state of a transaction. It provides the
 * following props to the underlying component:
 *   * `startTransaction` a function that generates a new transactionId.
 *   * `transactionId` the current transaction ID.
 *   * `transaction` the transaction record itself.
 *   * `onTransactionComplete` a function that is called when the transaction has been completed.
 *
 * The underlying component should call `startTransaction`, which will return a new, unique
 * tranasaction ID value (as well as passing it into the component as the `transactionId` prop).
 * When the transaction has finished, this HOC will, if given, call `onTransactionComplete` with
 * the transaction record itself, as well as setting the `transactionId` prop to null.
 *
 * Example: if a component wants to simply display a spinner while a transaction is in progress,
 * it can use the result of `startTransaction` when initiating the underyling action, and render
 * a spinner as long as `transactionId` has a value.
 *
 * ```js
 * const MyComponent = ({ record, transactionId }) => (
 *   <div>
 *    {record.name}
 *    <button type='button' onClick={doSomething} disabled={!!transactionId}>Do the thing!</button>
 *    {transactionId && (<i className='fa fa-spin fa-spinner')}
 *   </div>
 * )
 *
 * const MyComponentWithAction = compose(
 *   withTransaction, // The HOC function provided in this file
 *   mapMyAction, // Some HOC that provides `someAction` which uses a `transactionId`
 *   withHandlers({
 *     doSomething: ({ someAction, startTransaction }) => () = {
 *       const transactionId = startTransaction();
 *       someAction({ transactionId });
 *     }
 *   }),
 * )(MyComponent);
 * ```
 */
export default compose(
  connectTransactions,
  withState('transactionId', 'setTransactionId'),
  withHandlers({
    startTransaction: ({ setTransactionId }) => () => {
      const transactionId = uniqueId();
      setTransactionId(transactionId);
      return transactionId;
    },
  }),
  mapTransactionIdToTransaction,
  lifecycle({
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      const {
        onTransactionComplete,
        setTransactionId,
        transaction,
        transactionActions,
        transactionId,
      } = nextProps;

      if (transaction && transaction.isFinished) {
        if (onTransactionComplete) {
          onTransactionComplete(transaction);
        }
        transactionActions.clearTransaction(transactionId);
        setTransactionId(null);
      }
    },
  }),

  // Don't pass `setTransactionId` down to the component, that's for our purposes only.
  mapProps(({ setTransactionId: _, ...props }) => ({
    ...props,
  })),
);
