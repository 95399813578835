import createAjaxAction from '../../../../actions/createAjaxAction';
import {
  REJECTION_REASONS_FETCH_START,
  REJECTION_REASONS_FETCH_SUCCESS,
  REJECTION_REASONS_FETCH_FAILURE,
  REJECTION_REASONS_INVALIDATE,
} from './actionTypes';
import shouldFetchRejectionReasons from '../../selectors/shouldFetchRejectionReasons';

export const fetchRejectionReasons = createAjaxAction({
  getUrl: () => '/api/rejection_reasons',
  start: REJECTION_REASONS_FETCH_START,
  success: REJECTION_REASONS_FETCH_SUCCESS,
  failure: REJECTION_REASONS_FETCH_FAILURE,
});

export const fetchRejectionReasonsIfNeeded = params => (dispatch, getState) => {
  if (shouldFetchRejectionReasons(getState())) {
    dispatch(fetchRejectionReasons(params));
  }
};

export const invalidateRejectionReasons = payload => ({
  type: REJECTION_REASONS_INVALIDATE,
  payload: payload,
});
