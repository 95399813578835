import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, pure, setDisplayName } from 'recompose';
import connectRouting from './connectRouting';

/**
 * Creates a link that is pushed using the `routingActions.push` action
 * instead of allowing the browser to handle the navigation.
 */
export class Link extends Component {
  /**
   * Called when the link is clicked, overriding the default browser action
   * and instead navigating via the router actions.
   */
  handleClick = event => {
    const {
      href,
      onClick,
      routingActions,
      useDefaultLinkBehavior,
    } = this.props;

    if (onClick) {
      // Give the parent component an oportunity to intervene, possibly
      // cancelling the navigation.
      onClick(event);
    }

    if (!event.defaultPrevented && !useDefaultLinkBehavior) {
      event.preventDefault();
      routingActions.push(href);
    }
  };

  render() {
    const {
      children,
      href,

      // Prevent these props from being included in `props` that are passed
      // on to the anchor element.
      /* eslint-disable no-unused-vars */
      onClick,
      routingActions,
      useDefaultLinkBehavior,
      /* eslint-enable no-unused-vars */

      ...props
    } = this.props;

    if (href) {
      return (
        <a href={href} onClick={this.handleClick} {...props}>
          {children}
        </a>
      );
    }

    return <span {...props}>{children}</span>;
  }
}

Link.propTypes = {
  /**
   * The content to display inside the link or span element.
   */
  children: PropTypes.node,

  /**
   * The URL to route to when the link is clicked. If not specified, the
   * link will be rendered as a `span` element.
   */
  href: PropTypes.string,

  /**
   * Called when the link is clicked. Use `event.preventDefault()` to cancel navigation
   */
  onClick: PropTypes.func,

  /**
   * The actions that trigger HTML5 navigation
   */
  routingActions: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,

  /**
   * When true, the browser's default link behavior will be used, otherwise the links will be
   * intercepted and handled by dispatching routing actions.
   */
  useDefaultLinkBehavior: PropTypes.bool,
};

Link.defaultProps = {
  useDefaultLinkBehavior: false,
};

export default compose(setDisplayName('Link'), connectRouting, pure)(Link);
