import { createSlice } from '@reduxjs/toolkit';
import Api, { getErrorMessage } from 'modules/core/Api';
import routes from 'modules/routing/routes';
import {
  toastSuccess,
  toastError,
} from 'modules/toast-notifications/toastNotificationsSlice';

// The results per page selection is persisted in local storage
export const ResultsPerPage = {
  KEY: 'pendingContacts__index--resultsPerPage',
  DEFAULT_VALUE: 25,
};

// The current page is saved in local storage
export const CurrentPage = {
  KEY: 'pendingContacts__index--currentPage',
  DEFAULT_VALUE: 1,
};

const initialState = {
  currentPage: CurrentPage.DEFAULT_VALUE,
  createError: null,
  deleteError: null,
  isCreating: false,
  isDeleting: false,
  isLoading: false,
  loadError: null,
  data: [],
  resultsPerPage: parseInt(
    localStorage.getItem(ResultsPerPage.KEY) || ResultsPerPage.DEFAULT_VALUE,
  ),
  selectedIds: [],
  totalPages: 1,
  totalResults: 0,
  bannerDisplayed: false,
};

const { actions, reducer } = createSlice({
  name: 'pendingContacts',
  initialState: initialState,
  reducers: {
    loadPendingContactsBegin: state => {
      state.isLoading = true;
      state.loadError = null;
    },
    loadPendingContactsSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload.contact_reviews;
      state.totalPages = action.payload.metadata.total_pages;
      state.totalResults = action.payload.metadata.total_results;
      state.bannerDisplayed = action.payload.metadata.banner_displayed;
    },
    loadPendingContactsError: (state, action) => {
      state.isLoading = true;
      state.loadError = action.payload;
    },
    changePageView: (state, action) => {
      state.resultsPerPage =
        action.payload?.resultsPerPage || state.resultsPerPage;
      state.currentPage = action.payload?.currentPage || state.currentPage;
      state.selectedIds = [];
    },

    convertSelectedPendingContactsToContactsBegin: state => {
      state.isCreating = true;
      state.createError = null;
    },
    convertSelectedPendingContactsToContactsSuccess: state => {
      state.isCreating = false;
    },
    convertSelectedPendingContactsToContactsError: (state, action) => {
      state.isCreating = false;
      state.createError = action.payload;
    },
    deletePendingContactsBegin: state => {
      state.isDeleting = true;
      state.deleteError = null;
    },
    deletePendingContactsSuccess: state => {
      state.isDeleting = false;
    },
    deletePendingContactsError: (state, action) => {
      state.isDeleting = false;
      state.deleteError = action.payload;
    },
    toggleSelected: (state, action) => {
      const isAlreadySelected = state.selectedIds.includes(action.payload);
      state.selectedIds = isAlreadySelected
        ? state.selectedIds.filter(selectedId => selectedId !== action.payload)
        : [...state.selectedIds, action.payload];
    },
    resetSelectedPendingContacts: state => {
      state.selectedIds = [];
    },
    selectMatchlessPendingContacts: state => {
      const selectablePendingContacts = state.data.filter(
        pendingContact => !pendingContact.matches?.length,
      );
      const ids = selectablePendingContacts.map(
        pendingContact => pendingContact.id,
      );
      state.selectedIds = ids;
    },
  },
});

const {
  changePageView,
  convertSelectedPendingContactsToContactsBegin,
  convertSelectedPendingContactsToContactsError,
  convertSelectedPendingContactsToContactsSuccess,
  deletePendingContactsBegin,
  deletePendingContactsError,
  deletePendingContactsSuccess,
  loadPendingContactsBegin,
  loadPendingContactsError,
  loadPendingContactsSuccess,
  resetSelectedPendingContacts,
  selectMatchlessPendingContacts,
  toggleSelected,
} = actions;

const loadPendingContacts = () => (dispatch, getState) => {
  dispatch(loadPendingContactsBegin());

  const { currentPage, resultsPerPage } = getState().pendingContacts;
  return Api.get(
    routes.api_v1_contact_reviews({
      query: {
        current_page: currentPage,
        results_per_page: resultsPerPage,
        strategy: 'all',
      },
    }),
  )
    .then(response => {
      const totalPages = response.metadata.total_pages;
      // If the current page is higher than the total number of pages,
      // reset the current page to the last page to prevent "no records found" message.
      if (totalPages !== 0 && currentPage > totalPages) {
        dispatch(changePageView({ currentPage: totalPages }));
        dispatch(loadPendingContacts());
      } else {
        dispatch(loadPendingContactsSuccess(response));
      }
    })
    .catch(error => dispatch(loadPendingContactsError(getErrorMessage(error))));
};

const convertSelectedPendingContactsToContacts = formData => (
  dispatch,
  getState,
) => {
  dispatch(convertSelectedPendingContactsToContactsBegin());

  return Api.post(routes.bulk_api_v1_contacts(), {
    contact_review_ids: getState().pendingContacts.selectedIds,
    hidden: formData.search ? formData.hidden : null,
    network_id: formData.network_id,
    search_id: formData.search ? formData.search.id : null,
    stage_id: formData.search ? formData?.stage_id : null,
    pipeline_id: formData.pipeline ? formData.pipeline.id : null,
  })
    .then(response => {
      dispatch(convertSelectedPendingContactsToContactsSuccess());
      dispatch(toastSuccess(response.message));
      dispatch(resetSelectedPendingContacts());
      dispatch(loadPendingContacts());
    })
    .catch(error => {
      dispatch(
        convertSelectedPendingContactsToContactsError(getErrorMessage(error)),
      );
      dispatch(toastError(getErrorMessage(error)));
    });
};

const deletePendingContacts = ids => dispatch => {
  dispatch(deletePendingContactsBegin());

  return Api.delete(routes.bulk_api_v1_contact_reviews({ query: { ids: ids } }))
    .then(response => {
      dispatch(deletePendingContactsSuccess());
      dispatch(toastSuccess(response.message));
      dispatch(resetSelectedPendingContacts());
      return dispatch(loadPendingContacts());
    })
    .catch(error => {
      dispatch(deletePendingContactsError(getErrorMessage(error)));
    });
};

const deletePendingContact = id => dispatch =>
  dispatch(deletePendingContacts([id]));

const deleteSelectedPendingContacts = () => (dispatch, getState) => {
  const ids = getState().pendingContacts.selectedIds;
  return dispatch(deletePendingContacts(ids));
};

const changeCurrentPage = newPageNumber => dispatch => {
  dispatch(changePageView({ currentPage: newPageNumber }));
  localStorage.setItem(CurrentPage.KEY, newPageNumber);

  return dispatch(loadPendingContacts());
};

const changeResultsPerPage = newResultsPerPageNumber => dispatch => {
  dispatch(
    changePageView({ resultsPerPage: newResultsPerPageNumber, currentPage: 1 }),
  );
  localStorage.setItem(ResultsPerPage.KEY, newResultsPerPageNumber);
  localStorage.setItem(CurrentPage.KEY, 1);

  return dispatch(loadPendingContacts());
};

export {
  convertSelectedPendingContactsToContacts,
  changeCurrentPage,
  changeResultsPerPage,
  deletePendingContact,
  deleteSelectedPendingContacts,
  loadPendingContacts,
  resetSelectedPendingContacts,
  selectMatchlessPendingContacts,
  toggleSelected,
};

export default reducer;
