import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useSelector } from 'react-redux';
import { branch, compose, renderNothing, setPropTypes } from 'recompose';
import moment from 'moment';

import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import TagList from 'modules/core/components/TagList';
import classNames from 'classnames';
import HtmlText from '@thrivetrm/ui/components/HtmlText';
import mapPositionIdToPosition from './mapPositionIdToPosition';
import PositionTitle from './PositionTitle';

const formatAddress = address =>
  [
    address.address_line_1,
    address.address_line_2,
    address.city,
    address.state,
    address.country_code,
  ]
    .filter(Boolean)
    .join(', ');
/**
 * Renders the read-only view for a position item.
 */
export const PositionView = ({
  format,
  isTargetCompany,
  position,
  renderDescription,
  showPositionFunctions,
  showPositionLevels,
}) => {
  const positionId = position.get('id');
  const from = moment.utc(position.get('from'), 'YYYY-MM-DD');
  const to = moment.utc(position.get('to'), 'YYYY-MM-DD');
  const displayTitleAtCompany = format === 'titleAtCompany';
  const isCurrentPosition = position.get('present');
  const fallbackToValue = isCurrentPosition ? 'Present' : 'Unknown';
  const hasPositionAddressFeatureEnabled = useFeatureCheck(
    'feature.position_address',
  );
  const officeLocation = position.get('address')?.toJS();
  const levelNames = useSelector(state =>
    position
      .get('levels')
      .map(levelId => getEntityProperty(state, 'levels', levelId, 'name')),
  );
  const positionFunctionNames = useSelector(state =>
    position
      .get('position_functions')
      .map(levelId =>
        getEntityProperty(state, 'positionFunctions', levelId, 'name'),
      ),
  );
  return (
    <div className='PositionView'>
      <PositionTitle
        nameFirst={displayTitleAtCompany}
        positionId={positionId}
        targetCompany={isTargetCompany}
      />
      {(from.isValid() || to.isValid() || isCurrentPosition) && (
        <div
          className={classNames('PositionView__dates', {
            'u-marginBottom-4': !officeLocation,
          })}
        >
          <span>{from.isValid() ? from.format('MMMM YYYY') : 'Unknown'}</span>
          <span> - </span>
          <span>{to.isValid() ? to.format('MMMM YYYY') : fallbackToValue}</span>
        </div>
      )}
      {hasPositionAddressFeatureEnabled && officeLocation ? (
        <div
          className='u-marginBottom-4'
          data-testid='position-office-location'
        >
          {formatAddress(officeLocation)}{' '}
          <i className='fa fa-map-marker u-textColor-blue' />
        </div>
      ) : null}
      {showPositionFunctions && positionFunctionNames.size > 0 && (
        <div className='u-paddingLeft-n u-marginBottom-4 PositionView__functions'>
          <div className='u-flex'>
            <span className='u-marginTop-4 u-marginRight-4'>Functions: </span>
            <TagList
              className='PositionView__functions-list'
              tags={positionFunctionNames.toArray()}
            />
          </div>
        </div>
      )}
      {showPositionLevels && levelNames.size > 0 && (
        <div className='u-paddingLeft-n u-marginBottom-4 PositionView__levels'>
          <div className='u-flex'>
            <span className='u-marginTop-4 u-marginRight-4'>Levels: </span>
            <TagList
              className='PositionView__levels-list'
              tags={levelNames.toArray()}
            />
          </div>
        </div>
      )}
      {renderDescription ? (
        <HtmlText
          className='PositionView__description'
          htmlString={position.get('formatted_description')}
        />
      ) : null}
    </div>
  );
};

PositionView.propTypes = {
  format: PropTypes.string,
  /**
   * When true renders a bullseye indicator next to the company name to indicate that the
   * company this position is attached to is a target company.
   */
  isTargetCompany: PropTypes.bool,

  /**
   * The position to render.
   */
  position: ImmutablePropTypes.mapContains({
    from: PropTypes.string,
    to: PropTypes.string,
    name: PropTypes.string,
    company_name: PropTypes.string,
    company: PropTypes.number,
  }).isRequired,

  /**
   * True to render the description, false to omit it.
   * @type {[type]}
   */
  renderDescription: PropTypes.bool,

  /**
   * Default false. True to show position functions.
   */
  showPositionFunctions: PropTypes.bool,

  /**
   * Default false. True to show position levels.
   */
  showPositionLevels: PropTypes.bool,
};

PositionView.defaultProps = {
  format: 'default',
  isTargetCompany: false,
  renderDescription: true,
  showPositionFunctions: false,
  showPositionLevels: false,
};

/**
 * The default export requires only a `positionId`, while
 * the named export requires the position record.
 */
export default compose(
  setPropTypes({
    positionId: PropTypes.number.isRequired,

    renderDescription: PropTypes.bool.isRequired,
  }),
  mapPositionIdToPosition,
  branch(({ position }) => !position, renderNothing),
)(PositionView);
