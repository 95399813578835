import { fromJS } from 'immutable';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';

export default (formState, props) => {
  const {
    comment,
    commentActions: { createComment, updateComment },
    parentCommentId,
    parentId,
    parentType,
  } = props;

  const transactionId = uniqueId();
  const fieldValue = formState.getFieldValue();
  const values = (
    comment ||
    fromJS({
      parent_comment_id: parentCommentId,
    })
  ).merge({
    content: fieldValue,
  });

  const action = values.get('id') ? updateComment : createComment;

  action({
    comment: values.toJS(),
    parentType: parentType,
    parentId: parentId,
    transactionId: transactionId,
  });

  return formState.startSubmit(transactionId);
};
