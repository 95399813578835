import getPositionList from './getPositionList';

/**
 * Gets a value indicating whether positions need to be fetched for a given contact.
 * @param {Object} state
 * @param {Number} contactId The contact's ID
 * @returns {Boolean} True if the positions should be fetched from the server; false otherwise.
 */
export default (state, contactId) => {
  if (!contactId) {
    return false;
  }

  const positionList = getPositionList(state, contactId);

  if (!positionList) {
    return true;
  }

  if (positionList.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (positionList.hasIn(['_meta', 'error'])) {
    return false;
  }

  return (
    !positionList.has('ids') ||
    Boolean(positionList.getIn(['_meta', 'isInvalidated']))
  );
};
