import getEntity from 'modules/entities/selectors/getEntity';
import { attributeSchema } from '../schema';

/**
 * Gets an assessment attribute by it's ID
 * @param {Object} state
 * @param {Number} id The assessment attribute ID
 * @return {Immutable.Map|undefined} The assessment attribute record, if found.
 */
export default (state, id) => getEntity(state, attributeSchema.key, id);
