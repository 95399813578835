import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import TooltipTrigger from 'modules/core/componentsLegacy/TooltipTrigger';
import Icon from '@thrivetrm/ui/components/Icon';
import isCandidateUser from 'modules/auth/selectors/isCandidateUser';
import { useSelector } from 'react-redux';
import InlineEditActions from '../../../../components/forms/InlineEditActions';

/**
 * Renders the title for a education record, with optional edit/delete buttons (depending on
 * `canEdit` and `canDelete` prop values)
 */
export const EducationTitle = ({
  canDelete,
  canEdit,
  deleteConfirmation,
  disabled,
  education,
  onDelete,
  onEdit,
  shouldRenderV4Button,
}) => {
  const isCandidate = useSelector(state => isCandidateUser(state));

  return (
    <h3 className='EducationTitle u-marginTop-n'>
      <InlineEditActions
        actionClassName='EducationTitle__actions'
        canDelete={canDelete}
        canEdit={canEdit}
        // passing an empty string to override default value set by InlineEditActions
        className=''
        deleteConfirmation={deleteConfirmation}
        deleteLabel={<i className='fa fa-trash' />}
        disabled={disabled}
        editLabel={<i className='fa fa-pencil' />}
        onDelete={onDelete}
        onEdit={onEdit}
        shouldRenderV4Button={shouldRenderV4Button}
      >
        <div className='u-inline'>{education.get('school_name')}</div>
        {!isCandidate && education.get('verified_by_message') ? (
          <TooltipTrigger
            placement='top'
            tooltip={education.get('verified_by_message')}
          >
            <Icon
              className='u-marginLeft-4 u-marginBottom-2'
              data-testid='education verified by'
              size='medium'
              type='task'
            />
          </TooltipTrigger>
        ) : null}
      </InlineEditActions>
    </h3>
  );
};

export const propTypes = {
  canDelete: PropTypes.bool,
  canEdit: PropTypes.bool,
  deleteConfirmation: PropTypes.string,
  disabled: PropTypes.bool,
  education: ImmutablePropTypes.mapContains({
    school_name: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  shouldRenderV4Button: PropTypes.bool,
};

export const defaultProps = {
  canDelete: false,
  canEdit: false,
  deleteConfirmation: 'Delete this education?',
  disabled: false,
};

EducationTitle.propTypes = propTypes;
EducationTitle.defaultProps = defaultProps;

export default EducationTitle;
