import { List, Map } from 'immutable';
import selectn from 'selectn';

export const errorSelector = selectn('payload.error');

export const asArray = value => {
  if (Array.isArray(value)) {
    return value;
  }

  if (value) {
    return [value];
  }

  return [];
};

/**
 * Creates a reducer function that manages a list of entity IDs.
 * @param {Object} options
 * @param {String} [resultKey] Key that identifies entity IDs on an action's payload.result object
 *   for collection actions (the `success` action, in particular.)
 * @param {String} [entityResultKey] Key that identifies entity IDs on an action's payload.result
 *   object for entity actions (`created`, `deleted` and `updated`)
 * @param {String} request Action type dispatched when this list is requested
 * @param {String} success Action type dispatched when this list is successfully read
 * @param {String} failure Action type dispatched when this list fails to be read
 * @param {String} invalidated Action type dispatched when this list should be invalidated
 * @param {String} [created] Action type dispatched when a new item for this list is created.
 * @param {String} [deleted] Action type dispatched when am item is deleted.
 * @param {String} [updated] Action type dispatched when an item is updated.
 * @param {Boolean} [invalidateOnCreated=false] When true, the collection will be invalidated
 *   whenever a create action is dispatched.
 * @param {Boolean} [invalidateOnUpdated=false] When true, the collection will be invalidated
 *   whenever an update action is dispatched.
 * @param {Boolean} [invalidateOnDeleted=false] When true, the collection will be invalidated
 *   whenever a delete action is dispatched.
 * @return {Function} A reducer function that manages a list of entity IDs generated by Normalizr.
 */
export default function createListReducer({
  created,
  deleted,
  entityResultKey,
  failure,
  invalidateOnCreated = false,
  invalidateOnDeleted = false,
  invalidateOnUpdated = false,
  invalidated,
  request,
  resultKey,
  success,
  updated,
}) {
  const resultsSelector = selectn(
    resultKey ? `payload.result.${resultKey}` : 'payload.result',
  );
  const entityResultSelector = selectn(
    entityResultKey ? `payload.result.${entityResultKey}` : 'payload.result',
  );

  return (state = new Map(), action) => {
    switch (action.type) {
      case request: {
        return state.deleteIn(['_meta', 'error']).mergeIn(['_meta'], {
          isFetching: true,
        });
      }
      case success: {
        return state
          .set('ids', new List(resultsSelector(action)))
          .deleteIn(['_meta', 'error'])
          .mergeIn(['_meta'], {
            isFetching: false,
            lastFetched: Date.now(),
            isInvalidated: false,
          });
      }
      case failure: {
        return state.mergeIn(['_meta'], {
          isFetching: false,
          error: errorSelector(action),
        });
      }
      case invalidated: {
        return state.setIn(['_meta', 'isInvalidated'], true);
      }
      case created: {
        if (!state.has('ids')) {
          return state;
        }

        if (invalidateOnCreated) {
          return state.setIn(['_meta', 'isInvalidated'], true);
        }

        const newIds = asArray(entityResultSelector(action));
        if (!newIds.length) {
          return state;
        }

        return state.update('ids', ids => ids.concat([].concat(newIds)));
      }
      case updated: {
        if (!state.has('ids')) {
          return state;
        }

        if (invalidateOnUpdated) {
          return state.setIn(['_meta', 'isInvalidated'], true);
        }

        return state;
      }
      case deleted: {
        if (!state.has('ids')) {
          return state;
        }

        if (invalidateOnDeleted) {
          return state.setIn(['_meta', 'isInvalidated'], true);
        }

        const entityId =
          entityResultSelector(action) || selectn('payload.id', action);
        if (!entityId) {
          return state;
        }

        return state.update('ids', ids => ids.filter(id => id !== entityId));
      }
      default: {
        return state;
      }
    }
  };
}
