import React from 'react';
import TenantOptionsTagSelectField from './TenantOptionsTagSelectField';
import levelSchema from '../schemas/levels';

/**
 * A tag select component for choosing from the list of levels.
 */

const LevelsTagSelectField = ({ ...props }) => (
  <TenantOptionsTagSelectField
    {...props}
    className='LevelsTagSelectField'
    entityLabel='Level'
    label='Levels'
    schema={levelSchema}
  />
);

LevelsTagSelectField.createFieldState =
  TenantOptionsTagSelectField.createFieldState;

export default LevelsTagSelectField;
