import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { PARENT_TYPES } from '../constants';
import shouldFetchCommentListSelector from '../selectors/shouldFetchCommentList';
import fetchCommentListActionCreator from '../actions/fetchCommentList';

/**
 * When provided a parentId, parentType via props, fetches the comments
 * for that parent if they need to be fetched so they are available for a component.
 */
export default compose(
  setDisplayName('withCommentListFetched'),
  setPropTypes({
    parentId: PropTypes.number,
    parentType: PropTypes.oneOf(PARENT_TYPES),
  }),
  connect(
    (state, { parentId, parentType }) => ({
      shouldFetchCommentList: shouldFetchCommentListSelector(
        state,
        parentType,
        parentId,
      ),
    }),
    dispatch => ({
      fetchCommentList: bindActionCreators(
        fetchCommentListActionCreator,
        dispatch,
      ),
    }),
  ),
  withHandlers({
    fetchCommentList: ({ fetchCommentList, parentId, parentType }) => () => {
      fetchCommentList({ parentType: parentType, parentId: parentId });
    },
    fetchCommentListIfNeeded: ({
      fetchCommentList,
      parentId,
      parentType,
      shouldFetchCommentList,
    }) => () => {
      if (shouldFetchCommentList) {
        fetchCommentList({ parentType: parentType, parentId: parentId });
      }
    },
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchCommentListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchCommentListIfNeeded();
    },
  }),
);
