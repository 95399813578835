import { NAME } from '../constants';

/**
 * Gets the meta data for the rejection reasons list ('isFetching', etc)
 * @param {Object} state
 * @return {Immutable.Map}
 */
const getRejectionReasonsMeta = state =>
  state[NAME].getIn(['rejectionReasons', 'meta']);

export default getRejectionReasonsMeta;
