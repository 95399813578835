import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { fetchCompany as fetchCompanyActionCreator } from '../actions';
import shouldFetchCompanySelector from '../selectors/shouldFetchCompany';
import getCompany from '../selectors/getCompany';

/**
 * Fetches a company record if it needs to be fetched from the server, given it's companyId.
 * */
export default compose(
  setDisplayName('withCompanyFetched'),
  setPropTypes({
    companyId: PropTypes.number,
  }),
  connect(
    (state, { companyId }) => ({
      shouldFetchCompany: shouldFetchCompanySelector(state, companyId),
      company: getCompany(state, companyId),
    }),
    dispatch => ({
      fetchCompany: bindActionCreators(fetchCompanyActionCreator, dispatch),
    }),
  ),
  withHandlers({
    fetchCompanyIfNeeded: ({
      companyId,
      fetchCompany,
      shouldFetchCompany,
    }) => () => {
      if (shouldFetchCompany) {
        fetchCompany({ id: companyId });
      }
    },
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchCompanyIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchCompanyIfNeeded();
    },
  }),
);
