import deleteEntity from 'modules/entities/actions/deleteEntity';
import routes from 'modules/routing/routes';
import { assessmentSchema } from '../../schema';

/**
 * Creates an action for deleting an assessment..
 * @param {Object} payload
 * @param {Number} payload.id The ID of the assessment to delete
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({ id, transactionId }) => dispatch =>
  dispatch(
    deleteEntity({
      transactionId: transactionId,
      // `id` is needed on the payload so we can access it in the reducer.
      id: id,
      entityType: assessmentSchema.key,
      url: routes.api_v1_assessment({ id: id }),
    }),
  );
