import { NAME } from '../../constants';

/**
 * Gets a property in a contact record
 * @param {*} state
 * @param {*} contactId THe ID of the contact
 * @param {*} propertyName The property name on the contact record get get
 * @returns {*} the value of the property, if found.
 */
export default (state, contactId, propertyName) =>
  state[NAME].getIn(['byId', contactId].concat(propertyName));
