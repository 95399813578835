import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FormState from 'modules/forms/FormState';
import ConfirmationDialog from '@thrivetrm/ui/components/ConfirmationDialog';
import useToggle from '@thrivetrm/ui/hooks/useToggle';

/**
 * Displays standard form "Save" and "Cancel" actions. Additional actions
 * can be supplied as children, and will be rendered between after the
 * save button but before the cancel button.
 */
const FormActions = props => {
  const {
    cancelButtonClass,
    cancelLabel,
    children,
    className,
    confirmOnSubmit,
    formState,
    onCancelClick,
    onConfirm,
    onSubmitClick,
    size,
    submitButtonClass,
    submitButtonIcon,
    submitConfirmationText,
    submitConfirmationTitle,
    submitLabel,
  } = props;

  const handleSubmitClick = () => {
    onSubmitClick(formState);
  };

  const [isModalOpen, openModal, closeModal] = useToggle(false);

  // Note that is an `onSubmitClick` handler was specified, we actually set the button to
  // type  "button" instead of "submit", because we want to explicitly handle the button
  // click instead of allowing it to cause the form to be submitted. By omitting the
  // `onSubmitClick` handler, it indicates that the button should just do the default form
  // submit action.

  const isSubmitting = Boolean(formState?.isSubmitting());

  return (
    <>
      {isModalOpen ? (
        <ConfirmationDialog
          cancelLabel='Cancel'
          confirmLabel='Save'
          isOpen={isModalOpen}
          key='submit'
          onClose={closeModal}
          onConfirm={onConfirm}
          title={submitConfirmationTitle}
        >
          {submitConfirmationText}
        </ConfirmationDialog>
      ) : null}
      <div className={className}>
        {onCancelClick && (
          <button
            className={classnames('btn', {
              [cancelButtonClass]: Boolean(cancelButtonClass),
              [`btn-${size}`]: Boolean(size),
            })}
            disabled={isSubmitting}
            key='cancel'
            onClick={onCancelClick}
            type='button'
          >
            {cancelLabel}
          </button>
        )}
        {children}
        {confirmOnSubmit ? (
          <button
            className={classnames('btn', {
              [submitButtonClass]: Boolean(submitButtonClass),
              [`btn-${size}`]: Boolean(size),
            })}
            disabled={!formState.canSubmit()}
            onClick={openModal}
            type='button'
          >
            {submitLabel}
          </button>
        ) : (
          <button
            className={classnames('btn', {
              [submitButtonClass]: Boolean(submitButtonClass),
              [`btn-${size}`]: Boolean(size),
            })}
            disabled={!formState.canSubmit()}
            key='submit'
            onClick={onSubmitClick ? handleSubmitClick : null}
            // eslint-disable-next-line react/button-has-type
            type={onSubmitClick ? 'button' : 'submit'}
          >
            <i
              className={classnames('fa', {
                'fa-spin': isSubmitting,
                'fa-spinner': isSubmitting,
                [submitButtonIcon]: submitButtonIcon && !isSubmitting,
              })}
            />
            &nbsp;
            {submitLabel}
          </button>
        )}
      </div>
    </>
  );
};

FormActions.propTypes = {
  /**
   * The class to apply to the cancel button ('btn-primary',
   * 'btn-secondary', etc...). Defaults to 'btn-default'
   */
  cancelButtonClass: PropTypes.string,

  /**
   * The label to display for the cancel button.
   */
  cancelLabel: PropTypes.string,

  /**
   * Additional content to render between the save and cancel buttons.
   */
  children: PropTypes.node,

  /**
   * The class to apply to the parent div. Defaults to 'form-actions'.
   */
  className: PropTypes.string,

  /**
   * Whether to replace the submit button with a confirmation overlay.
   */
  confirmOnSubmit: PropTypes.bool,

  /**
   * A representation of the current state of the form.
   */
  formState: PropTypes.instanceOf(FormState),

  /**
   * A function that will be called when the cancel
   * button is clicked.
   */
  onCancelClick: PropTypes.func,

  /**
   * A function that will be called when the confirmation dialog is accepted
   */
  onConfirm: PropTypes.func,

  /**
   * A callback to be called when the submit button is clicked.
   * If this prop is specified, the button is given a type of 'button' so that it does
   * not trigger any parent <form> `onSubmit` handlers.
   */
  onSubmitClick: PropTypes.func,

  /**
   * The button size modifier.
   */
  size: PropTypes.oneOf(['sm', 'lg']),

  /**
   * The class to apply to the submit button ('btn-primary',
   * 'btn-secondary', etc...). Defaults to 'btn-primary'
   */
  submitButtonClass: PropTypes.string,

  /**
   * The icon class to use for the submit button (while not actively submitting)
   */
  submitButtonIcon: PropTypes.string,

  /**
   * Text for the body of the submit confirmation modal.
   */
  submitConfirmationText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),

  /**
   * The title for the confirmation modal
   */
  submitConfirmationTitle: PropTypes.node,

  /**
   * The label to display for the submit button.
   */
  submitLabel: PropTypes.string,
};

FormActions.defaultProps = {
  cancelButtonClass: 'CancelButton btn-primary-outline',
  cancelLabel: 'Cancel',
  className: 'form-actions',
  size: 'sm',
  submitButtonClass: 'SubmitButton btn-primary',
  submitButtonIcon: '',
  submitLabel: 'Save',
};

export default FormActions;
