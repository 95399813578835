import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, withHandlers } from 'recompose';

/**
 * Renders a single option for the RadioSelectField component.
 */
const RadioSelectFieldOption = ({
  checked,
  children,
  componentId,
  disabled,
  inline,
  name,
  onChange,
  value,
}) => (
  <label className={inline ? 'radio-inline' : 'radio'} htmlFor={componentId}>
    <input
      checked={checked}
      disabled={disabled}
      id={componentId}
      name={name}
      onChange={onChange}
      type='radio'
      value={value}
    />
    {children}
  </label>
);

RadioSelectFieldOption.propTypes = {
  /**
   * Whether the option should be checked.
   */
  checked: PropTypes.bool,

  /**
   * The content (label) to display for the option.
   */
  children: PropTypes.node,

  /**
   * A unique ID to use for this component instance.
   */
  componentId: PropTypes.string.isRequired,

  /**
   * True to disable the option; otherwise, false.
   */
  disabled: PropTypes.bool,

  /**
   * Whether to display radio option inline.
   */
  inline: PropTypes.bool,

  /**
   * The name of the underlying input.
   */
  name: PropTypes.string.isRequired,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * The value this option represents when selected.
   */
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

RadioSelectFieldOption.defaultProps = {
  checked: false,
  disabled: false,
  inline: true,
};

export default compose(
  setDisplayName('RadioSelectFieldOption(enhanced)'),
  withHandlers({
    // Modify the onChange handler so it returns the actual value instead of the event.
    onChange: ({ onChange, value }) => () => onChange(value),
  }),
)(RadioSelectFieldOption);
