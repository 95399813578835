export const CONTACT_REVIEW_FETCH_START = 'CONTACT_REVIEW_FETCH_START';
export const CONTACT_REVIEW_FETCH_SUCCESS = 'CONTACT_REVIEW_FETCH_SUCCESS';
export const CONTACT_REVIEW_FETCH_FAILURE = 'CONTACT_REVIEW_FETCH_FAILURE';

export const CONTACT_REVIEWS_FETCH_INVALIDATE =
  'CONTACT_REVIEWS_FETCH_INVALIDATE';

export const CONTACT_REVIEW_CREATE_START = 'CONTACT_REVIEW_CREATE_START';
export const CONTACT_REVIEW_CREATE_SUCCESS = 'CONTACT_REVIEW_CREATE_SUCCESS';
export const CONTACT_REVIEW_CREATE_FAILURE = 'CONTACT_REVIEW_CREATE_FAILURE';

export const CONTACT_REVIEW_CREATE_BULK_START =
  'CONTACT_REVIEW_CREATE_BULK_START';
export const CONTACT_REVIEW_CREATE_BULK_SUCCESS =
  'CONTACT_REVIEW_CREATE_BULK_SUCCESS';
export const CONTACT_REVIEW_CREATE_BULK_FAILURE =
  'CONTACT_REVIEW_CREATE_BULK_FAILURE';
