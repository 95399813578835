import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { branch, compose, setPropTypes, setStatic } from 'recompose';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import EducationField, { FIELD_LAYOUTS } from './EducationField';
import connectContactActions from '../connectContactActions';
import mapEducationIdToEducation from './mapEducationIdToEducation';
import withTransactionTrackedForm from '../../../../components/forms/withTransactionTrackedForm';

export const createRootFieldState = ({ education }) =>
  EducationField.createFieldState('education', education);

export const handleSubmit = (formState, props) => {
  const {
    contactActions: { createEducation, updateEducation },
    contactId,
    education,
  } = props;

  const transactionId = uniqueId();
  const fieldValue = formState.getFieldValue();
  const values = (education || fromJS({ contact_id: contactId })).merge(
    fieldValue,
  );

  const action = values.get('id') ? updateEducation : createEducation;

  action({
    education: values.toJS(),
    transactionId: transactionId,
  });

  return formState.startSubmit(transactionId);
};

export const connectForm = compose(
  setPropTypes({
    /**
     * If editing an education
     */
    education: ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
    }),

    educationId: PropTypes.number,
  }),
  branch(
    props => props.educationId && !props.education,
    mapEducationIdToEducation,
  ),
  connectTransactions,
  connectContactActions,
);

export default compose(
  setStatic('FIELD_LAYOUTS', FIELD_LAYOUTS),
  connectForm,
  withTransactionTrackedForm(createRootFieldState, handleSubmit, {
    formClassName: 'EducationForm',
    omitProps: [
      'contactId',
      'formState',
      'onSaved',
      'education',
      'educationId',
      'contactActions',
      'searchId',
      'transactionActions',
    ],
  }),
)(EducationField);
