import selectn from 'selectn';
import merge from 'modules/entities/actions/merge';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import isEntityType from 'modules/entities/isEntityType';
import getEntity from 'modules/entities/selectors/getEntity';
import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import addressSchema from 'modules/addresses/addressSchema';
import companySchema from '../companySchema';
/**
 * When an address is created, updated or deleted it updates the
 * primary_address_id of the company
 */
export default ({ action, dispatch, getState }) => {
  if (isEntityType(action, addressSchema.key)) {
    switch (action.type) {
      case entityActionTypes.CREATE_SUCCESS:
      case entityActionTypes.UPDATE_SUCCESS:
      case entityActionTypes.DELETE_SUCCESS: {
        const isDelete = action.type === entityActionTypes.DELETE_SUCCESS;
        const isCreate = action.type === entityActionTypes.CREATE_SUCCESS;
        const addressId = selectn(
          isDelete ? 'payload.id' : 'payload.result.address',
          action,
        );
        const state = getState();
        // when isCreate, the only way to get the companyId is from the payload
        // because the address entity does not yet exist
        const companyId = isCreate
          ? selectn(
              `payload.entities.addresses.${addressId}.addressable_id`,
              action,
            )
          : getEntityProperty(state, 'addresses', addressId, 'addressable_id');
        const primary = selectn(
          `payload.entities.addresses.${addressId}.primary`,
          action,
        );
        const company = getEntity(state, 'companies', companyId);
        const currentPrimaryAddressId = company.get('primary_address');
        // if we are editing the primary_address and the "primary" checkbox is UNchecked
        const clearPrimaryAddressId =
          !primary && addressId === currentPrimaryAddressId;
        // if primary is checked, make the submitted address the primaryAddress,
        // otherwise, keep the currentPrimaryAddress
        const primaryAddressId = primary ? addressId : currentPrimaryAddressId;

        if (company) {
          dispatch(
            merge({
              data: {
                id: companyId,
                primary_address: clearPrimaryAddressId
                  ? null
                  : primaryAddressId,
                // the companySchema expects `name` and `sort_name`
                name: company.get('name'),
                sort_name: company.get('sort_name'),
              },
              schema: companySchema,
            }),
          );
        }
        break;
      }
      default: {
        // noop
      }
    }
  }
};
