import { connect } from 'react-redux';
import getCommentList from '../selectors/getCommentList';

/**
 * A higher order component which maps a `parentType` and `parentId` to it's
 * comments list state.
 */
export default connect(
  (state, { parentId, parentType }) => ({
    commentList:
      parentId && parentType && getCommentList(state, parentType, parentId),
  }),
  {},
);
