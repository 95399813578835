//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'assessments';

//---------------------------------------------------------
// Parent types (types of assessment list collections)
//---------------------------------------------------------

export const PARENT_SEARCH = 'search';
export const PARENT_CONTACT = 'contact';
export const PARENT_CANDIDACY = 'candidacy';

export const PARENT_TYPES = [PARENT_CANDIDACY, PARENT_CONTACT, PARENT_SEARCH];

//---------------------------------------------------------
// Assessment types
//---------------------------------------------------------

export const TYPE_RESEARCHER = 'ResearcherAssessment';
export const TYPE_RECRUITER = 'RecruiterAssessment';
export const TYPE_CLIENT = 'ClientAssessment';

export const ASSESSMENT_TYPES = [TYPE_CLIENT, TYPE_RECRUITER, TYPE_RESEARCHER];
