import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import {
  compose,
  defaultProps,
  lifecycle,
  setPropTypes,
  setDisplayName,
} from 'recompose';
import KeyCode from 'keycode-js';

import * as stringUtils from '@thrivetrm/ui/utilities/stringUtils';

import getCompany from '../selectors/getCompany';
import getQueriesById from '../selectors/getQueriesById';
import getCompanyEntitiesMap from '../selectors/getCompanyEntitiesMap';
import connectCompanyActions from './connectCompanyActions';
import withCompanyFetched from './withCompanyFetched';

import QuerySelect, {
  entityMapGetItemById,
} from '../../../components/forms/QuerySelect';

const promptTextCreator = label => `"${label}"`;

const CREATE_NEW_KEY_CODES = [
  KeyCode.KEY_RETURN,
  KeyCode.KEY_TAB,
  KeyCode.KEY_ENTER,
];

const shouldKeyDownEventCreateNewOption = ({ keyCode }) =>
  CREATE_NEW_KEY_CODES.includes(keyCode);

/**
 * A control for selecting zero or one company, optionally allowing just a name (not requiring
 * an existing company be selected) when `allowName` is true.
 */
export const CompanySelect = ({
  allowName,
  companiesById,
  company,
  companyActions,
  filter,
  name,
  parentCompanyId,
  queriesById,

  ...props
}) => (
  <div className='CompanySelect'>
    <QuerySelect
      {...props}
      getItemById={entityMapGetItemById}
      isCreatable={allowName}
      isLoading={company && company.getIn(['_meta', 'isFetching'])}
      itemsById={companiesById}
      loadOptionsOnMount={true}
      onCreate={companyActions.createCompaniesQuery}
      onDestroy={companyActions.destroyCompaniesQuery}
      onQuery={value =>
        companyActions.queryCompanies({
          ...value,
          filter: filter,
          parentCompanyId: parentCompanyId,
        })
      }
      promptTextCreator={promptTextCreator}
      queriesById={queriesById}
      shouldKeyDownEventCreateNewOption={shouldKeyDownEventCreateNewOption}
      value={
        name && allowName ? { id: name, name: name } : company && company.toJS()
      }
    />
  </div>
);

CompanySelect.propTypes = {
  allowName: PropTypes.bool.isRequired,

  companiesById: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.map,
    PropTypes.string,
  ).isRequired,

  company: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isFetching: PropTypes.bool,
    }),
    id: PropTypes.number,
    name: PropTypes.string,
  }),

  companyActions: PropTypes.shape({
    createCompaniesQuery: PropTypes.func.isRequired,
    destroyCompaniesQuery: PropTypes.func.isRequired,
    queryCompanies: PropTypes.func.isRequired,
  }).isRequired,

  filter: PropTypes.string,

  /**
   * When `allowName` is false and a non-existant company is selected, this will be the name
   * entered.
   */
  name: PropTypes.string,

  parentCompanyId: PropTypes.number,

  queriesById: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.map,
    PropTypes.string,
  ).isRequired,

  /**
   * If true, shows an addon icon indicating whether or not the currently selected
   * company is "linked" (is assigned to company record, versus simply being a name).
   * Only applicable if `allowName` is also true.
   */
  showLinkedStatus: PropTypes.bool,
};

CompanySelect.defaultProps = {
  showLinkedStatus: false,
};

/**
 * The connected version of this component expects only a number as it's value. That number
 * should be the selected company ID.
 */
export default compose(
  setDisplayName('CompanySelect(enhanced)'),
  setPropTypes({
    /**
     * True to allow any name to be entered -- otherwise only existing companies may be selected.
     */
    allowName: PropTypes.bool.isRequired,

    onChange: PropTypes.func.isRequired,

    /**
     * The selected company ID, or a company name if `allowName` is true.
     */
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  defaultProps({
    allowName: false,
  }),
  connectCompanyActions,
  connect((state, { allowName, onChange, value }) => {
    const companyId = typeof value === 'number' ? value : null;
    const name = allowName && typeof value === 'string' ? value : null;

    return {
      companyId: companyId,
      company: companyId ? getCompany(state, value) : null,
      name: name,
      onChange: newValue => onChange(newValue ? newValue.id : newValue),
      queriesById: getQueriesById(state),
      companiesById: getCompanyEntitiesMap(state),
    };
  }, {}),
  withCompanyFetched,
  lifecycle({
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      const { companiesById, onChange, value } = nextProps;

      if (
        typeof value === 'string' &&
        companiesById !== this.props.companyiesById
      ) {
        // Whenever the byId state is updated and we currently have a string value selected,
        // check to see if that matches an existing company, and instead select that matching
        // company by it's ID.
        const sortName = stringUtils.toSortableString(value);
        const idString = companiesById.findKey(
          company => company.get('sort_name') === sortName,
        );
        const id = idString && parseInt(idString);

        if (id) {
          onChange({ id: id });
        }
      }
    },
  }),
)(CompanySelect);
