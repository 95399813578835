import { omit } from 'lodash';
import { compose, mapProps } from 'recompose';
import withForm from './withForm';
import withTransaction from './withTransaction';
import withFormState from './withFormState';

/**
 * Returns a function that creates a higher-order component that provides:
 * 1. A <form>-wrapped Field (using `withForm`)
 * 2. A FormState (using `withFormState`)
 * 3. Tracked transactions based on the FormState's `submissionId` (using `withTransaction`)

 * @param {Function} createRootFieldState A function that will be called to create the state
 *   for the root field of the `FormState`. It is called with the component's props and should
 *   return a single `FieldState` value.
 * @param {Function} onSubmit The function that is called when the form should be submitted.
 *   It is called with the current form state and the component's props and should return a
 *   `FormState` object representing the state of the form after the submit
 *
 *    `onSubmit(formState: FormState, props: Object): FormState`
 *
 *   To prevent a submission, simply return the `FormState` that was passed as the first parameter.
 * @param {Object} options Additional, optional options.
 * @param {String} options.formClassName Class name(s) to apply to the form field.
 * @param {Array} options.omitProps An array of props that should be omitted from being passed to
 *   the Field component being wrapped.
 * @return {Function} A function for generating the higher-order component.
 */
const withTransactionTrackedForm = (
  createRootFieldState,
  onSubmit,
  {
    asRichTextForm,
    buttonsClass,
    fieldComponentTop,
    formActionClass,
    formActionProps,
    formClassName,
    includeTransactionProps,
    inlineSubmitErrors,
    omitProps,
    scrollToError,
    showErrors,
    showGenericErrorMessage,
  },
) =>
  compose(
    withFormState(createRootFieldState),
    withTransaction(
      props => props.formState.getSubmissionId(),
      (props, payload) => {
        const { error } = payload;
        props.onChange(props.formState.endSubmit(error, inlineSubmitErrors));

        if (!error && props.onSaved) {
          props.onSaved(payload, props);
        }
      },
      {
        includeTransactionProps: includeTransactionProps,
      },
    ),
    withForm(onSubmit, {
      asRichTextForm: asRichTextForm,
      buttonsClass: buttonsClass,
      className: formClassName,
      formActionClass: formActionClass,
      formActionProps: formActionProps,
      fieldComponentTop: fieldComponentTop,
      scrollToError: scrollToError,
      showErrors: showErrors,
      showGenericErrorMessage: showGenericErrorMessage,
    }),
    mapProps(props => omit(props, ['onSaved'].concat(omitProps))),
  );

export default withTransactionTrackedForm;
