import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactSelect from 'react-select-legacy';
import asFormGroupField from 'modules/forms/components/asFormGroupField';
import './RevenueField.scss';

/**
 * A dropdown component for selecting a year from a range of years.
 * The range of years can be provided by a minimum and maximum year value, or by a relative
 * range (-5..10, for example, would show the current year, plus 5 years in the past and 10 years
 * in the future)
 */
export class YearSelect extends Component {
  constructor(props, ...args) {
    super(props, ...args);

    const currentYear = new Date().getFullYear();
    const {
      isRelativeRange,
      maxYear = isRelativeRange ? 1 : currentYear + 1,
      minYear = isRelativeRange ? -100 : 1900,
    } = props;

    // Generate an array of the size we need, spannign from the minYear value to the maxYear
    // value.
    let yearRange = [...Array(Math.abs(maxYear - minYear + 1)).keys()].map(
      i => i + minYear,
    );

    if (isRelativeRange) {
      // When `isRelativeRange` is true, our range is just relative values, which we need to
      // convert to absolute values by adding the current year (i.e. [-1, 0, 1] would becomes
      // [2016, 2017, 2018] if we're in the year 2017 currently)
      yearRange = yearRange.map(year => year + currentYear);
    }

    this.YEAR_OPTIONS = yearRange
      .map(value => ({ label: value, value: value }))
      .reverse();
    this.MIN_VALUE = yearRange[0]; // eslint-disable-line prefer-destructuring
    this.MAX_VALUE = yearRange[yearRange.length - 1];
  }

  render() {
    const {
      isRelativeRange: _isRelativeRange,
      maxYear: _minYear,
      minYear: _maxYear,
      value,
      ...props
    } = this.props;

    return (
      <ReactSelect
        className='YearSelect'
        options={this.YEAR_OPTIONS}
        simpleValue={true}
        value={value}
        {...props}
      />
    );
  }
}

export const propTypes = {
  /**
   * Whether the `maxYear` and `minYear` values are relative to the current year, or are absolute
   * year values.
   */
  isRelativeRange: PropTypes.bool,

  /**
   * The maximum year to show as an option. When `isRelativeRange` is false, this should be an
   * absolute year (1999, 2018, etc). When `isRelativeRange` is true, this is relative to the
   * current year. So if the current year is 2017, a value of 1 will set the maximum
   * year to 2017; a value of 5 will allow a maximum year of 2022; a value of -10 will allow
   * a maximum year of 2012; etc...
   */
  maxYear: PropTypes.number,

  /**
   * The minimum year to show as an option. See `maxYear` for possible values, depending on whether
   * `isRelativeRange` is true or false.
   */
  minYear: PropTypes.number,

  /**
   * The selected year value.
   */
  value: PropTypes.number,
};

export const defaultProps = {
  isRelativeRange: true,
  maxYear: 1,
  minYear: -100,
};

YearSelect.propTypes = propTypes;
YearSelect.defaultProps = defaultProps;

export default asFormGroupField(YearSelect);
