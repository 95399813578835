import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import { candidacySchema } from '../schemas';

/**
 * Gets a candidacy record property by it's ID and propery name
 * @param {Object} state
 * @param {Number} candidacyId The candidacy ID
 * @param {String} propertyName The name of the property to get.
 * @return {*} The property value.
 */
export default (state, candidacyId, propertyName) =>
  getEntityProperty(state, candidacySchema.key, candidacyId, propertyName);
