import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import routes from 'modules/routing/routes';
import { optionSchema } from '../../schema';
import {
  OPTIONS_FETCH_START,
  OPTIONS_FETCH_SUCCESS,
  OPTIONS_FETCH_FAILURE,
} from './actionTypes';

/**
 * Creates an action to fetch all available assessment options for the current tenant
 */
export default compose(
  fetchAction,
  normalizeResponse({ options: [optionSchema] }),
  createAsyncActions({
    startActionType: OPTIONS_FETCH_START,
    successActionType: OPTIONS_FETCH_SUCCESS,
    failureActionType: OPTIONS_FETCH_FAILURE,
  }),
  withOptions({
    url: routes.api_v1_assessment_options(),
  }),
);
