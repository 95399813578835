import { connect } from 'react-redux';
import getComment from '../selectors/getComment';

/**
 * A higher order component which maps a `commentId` prop value to the comment record.
 */
export default connect(
  (state, { commentId }) => ({
    comment: getComment(state, commentId),
  }),
  {},
);
