import getPositionProperty from './getPositionProperty';

/**
 * Gets the start date for a position record
 * @param {Object} state
 * @param {Number} positionId The position ID
 * @return {String} The start date value
 */
export default (state, positionId) =>
  getPositionProperty(state, positionId, 'from');
