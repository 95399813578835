/* eslint-disable import/no-cycle */
// ^ This component has an intentional circular dependency with CommentList to
//   accomodate nested comment lists.
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';
import Card from '@thrivetrm/ui/components/Card';

import mapCommentIdToComment from './mapCommentIdToComment';
import connectCommentActions from './connectCommentActions';
import CommentView from './CommentView';
import CommentForm from './CommentForm';
import CommentList from './CommentList';
import withFormToggleButton from '../../../components/forms/withFormToggleButton';
import InlineEditActions from '../../../components/forms/InlineEditActions';

const CommentReplyFormToggleButton = withFormToggleButton(CommentForm);

/**
 * Renders single comment list item for an `CommentList`, allowing
 * the comment to be edited/deleted inline (if the `readOnly` prop is false).
 */
class CommentListItem extends Component {
  state = {
    /**
     * True when the current item is being edited, false when displaying
     * @type {Boolean}
     */
    isEditing: false,

    /**
     * True when the reply form for this comment is open
     * @type {Boolean}
     */
    isReplying: false,
  };

  /**
   * Sets the state to the given action
   * @param {String} actionName One of: 'isEditing', 'isReplying',
   *   or a falsy value to cancel any action.
   */
  setAction(actionName) {
    const newState = {
      isEditing: false,
      isReplying: false,
    };

    if (actionName) {
      newState[actionName] = true;
    }

    this.setState(newState);
  }

  /**
   * Called when the edit link is clicked.
   * Sets the 'isEditing' state to true so that the edit form is shown.
   */
  handleEditClick = () => {
    this.setAction('isEditing');
  };

  /**
   * Called when the 'Yes' button of the delete confirmation is clicked.
   * Calls the action to delete the comment and resets the action.
   */
  handleDelete = () => {
    const { comment, commentActions } = this.props;
    commentActions.deleteComment({ id: comment.get('id') });
  };

  /**
   * Called when the reply form is toggled on or off.
   * @param  {Boolean} isReplying True if the form was opened,
   *   false if it was closed.
   */
  handleReplyToggle = isReplying => {
    this.setState({ isReplying: isReplying });
  };

  /**
   * Called when the edit form is closed.
   */
  handleEditClose = () => {
    this.setState({ isEditing: false });
  };

  render() {
    const {
      comment,
      inlineEditActionLabels,
      parentId,
      parentType,
      popoverPlacement,
      readOnly,
    } = this.props;

    const { isEditing, isReplying } = this.state;
    const { deleteLabel, editLabel, replyLabel } = inlineEditActionLabels;
    const meta = comment.get('_meta') ? comment.get('_meta').toJS() : {};
    const canDelete = comment.get('user_can_delete');
    const canEdit = comment.get('user_can_edit');
    const canReply = !comment.get('parent_comment_id');
    const childComments = comment.get('child_comments');
    const commentId = comment.get('id');

    return (
      <li
        className={classnames('CommentListItem', {
          'CommentListItem--is-editing': isEditing,
          'CommentListItem--is-replying': isReplying,
          'CommentListItem--is-updating': meta.isUpdating || meta.isDeleting,
          'has-error': meta.error,
        })}
        key={commentId}
      >
        <Card isCentered={false}>
          {isEditing ? (
            <CommentForm
              alwaysShowFieldErrors={true}
              comment={comment}
              errorDisplay='tooltip'
              label='Edit comment'
              onCancel={this.handleEditClose}
              onSaved={this.handleEditClose}
              parentId={parentId}
              parentType={parentType}
            />
          ) : (
            <CommentView commentId={commentId} />
          )}
          {!readOnly && (
            <InlineEditActions
              canDelete={!isEditing && !readOnly && !isReplying && canDelete}
              canEdit={!isEditing && !readOnly && !isReplying && canEdit}
              deleteClassName='CommentListItem__delete'
              deleteConfirmation='Delete this comment?'
              deleteLabel={deleteLabel}
              disabled={meta.isDeleting}
              editClassName='CommentListItem__edit'
              editLabel={editLabel}
              onDelete={this.handleDelete}
              onEdit={this.handleEditClick}
              popoverPlacement={popoverPlacement}
            >
              {canReply && !isEditing && (
                <CommentReplyFormToggleButton
                  btnClassName='CommentListItem__reply btn-link'
                  btnSize='sm'
                  errorDisplay='tooltip'
                  onToggle={this.handleReplyToggle}
                  parentCommentId={commentId}
                  parentId={parentId}
                  parentType={parentType}
                  title='Reply to comment'
                >
                  {replyLabel}
                </CommentReplyFormToggleButton>
              )}
            </InlineEditActions>
          )}
          <CommentList
            className='child-comments'
            commentIds={childComments}
            {...this.props}
          />
        </Card>
      </li>
    );
  }
}

CommentListItem.propTypes = {
  /**
   * The comment to render.
   */
  comment: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      error: PropTypes.any,
      isDeleting: PropTypes.bool,
      isUpdating: PropTypes.bool,
    }),
    child_comments: ImmutablePropTypes.listOf(PropTypes.number),
    id: PropTypes.number.isRequired,
    parent_comment_id: PropTypes.number,
  }).isRequired,

  /**
   * Actions for retrieving, updating and deleting comments.
   */
  commentActions: requiredIf(
    PropTypes.shape({
      deleteComment: PropTypes.func.isRequired,
    }),
    props => !props.readOnly,
  ),

  inlineEditActionLabels: PropTypes.shape({
    deleteLabel: PropTypes.node,
    editLabel: PropTypes.node,
    replyLabel: PropTypes.node,
  }),

  parentId: PropTypes.number.isRequired,

  parentType: PropTypes.string.isRequired,
  /**
   * Optional string to set orientation of delete confirm popovers (if none is
   * passed it defaults to top)
   */
  popoverPlacement: PropTypes.string,

  /**
   * True to not allow editing of comments. (false by default)
   */
  readOnly: PropTypes.bool,
};

CommentListItem.defaultProps = {
  inlineEditActionLabels: {
    editLabel: <i className='fa fa-pencil' />,
    deleteLabel: <i className='fa fa-trash' />,
    replyLabel: 'Reply',
  },
  readOnly: false,
};

export default compose(
  setDisplayName('CommentListItem(enhanced)'),
  setPropTypes({
    commentId: PropTypes.number.isRequired,
  }),
  connectCommentActions,
  mapCommentIdToComment,
)(CommentListItem);
