import getEntity from 'modules/entities/selectors/getEntity';
import commentSchema from '../commentSchema';

/**
 * Gets an comment record by it's ID
 * @param {Object} state
 * @param {Number} id The comment ID
 * @return {Immutable.Map|undefined} The comment record, if found.
 */
export default (state, id) => getEntity(state, commentSchema.key, id);
