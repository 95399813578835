import React from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import {
  compose,
  setDisplayName,
  setPropTypes,
  setStatic,
  withHandlers,
} from 'recompose';
import FieldState from 'modules/forms/FieldState';
import PositionField, {
  FIELD_LAYOUTS,
} from 'modules/positions/components/PositionField';
import PrimaryPositionCheckboxField from './PrimaryPositionCheckboxField';

/**
 * Extends the PositionField to allow marking a position as a contact's "primary" position.
 */
const ContactPositionField = ({
  allowPrimarySelect,
  contactId,
  fieldState,
  handleFieldChange,
  header,
  layout,
  primaryPositionError,
  ...props
}) => {
  return (
    <PositionField
      className='ContactPositionField'
      fieldState={fieldState}
      {...props}
      header={header}
      layout={layout}
    >
      {allowPrimarySelect && (
        <div className='ContactPositionField__primaryPosition col-12'>
          <PrimaryPositionCheckboxField
            {...props}
            className='u-marginBottom-n'
            contactId={
              fieldState.getNestedFieldValue('contact_id') || contactId
            }
            error={primaryPositionError}
            fieldState={fieldState.getNestedField('primary')}
            name='primary'
            onChange={handleFieldChange}
            positionId={fieldState.getNestedFieldValue('id')}
          />
        </div>
      )}
    </PositionField>
  );
};

ContactPositionField.createFieldState = (
  name = 'position',
  position,
  hasSkyminyrFeature,
) => {
  const values = fromJS({
    primary: false,
  }).merge(position);

  // Use the underlying PositionField's implementation, but add some additional child fields
  // that we need for dealing with the "primary" flag.
  const fieldState = PositionField.createFieldState(
    name,
    values,
    hasSkyminyrFeature,
  );
  return fieldState
    .setNestedField(
      PrimaryPositionCheckboxField.createFieldState(
        'primary',
        Boolean(values.get('primary')),
      ),
    )
    .setNestedField(FieldState.create('contact_id', values.get('contact_id')))
    .setNestedField(FieldState.create('id', values.get('id')));
};

export default compose(
  setDisplayName('ContactPositionField(enhanced)'),
  setPropTypes({
    ...ContactPositionField.propTypes,

    // Exclude `handleFieldChange` prop since the whole point of this enhanced
    // version is to provide that.
    handleFieldChange: PropTypes.any,
  }),
  setStatic('FIELD_LAYOUTS', FIELD_LAYOUTS),
  setStatic('createFieldState', ContactPositionField.createFieldState),
  withHandlers({
    handleFieldChange: ({ fieldState, onChange }) => childFieldState => {
      onChange(fieldState.setNestedField(childFieldState));
    },
  }),
)(ContactPositionField);
