import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { fetchSearch as fetchSearchActionCreator } from '../actions';
import getSearch from '../selectors/getSearch';

import shouldFetchSearchSelector from '../selectors/shouldFetchSearch';

/**
 * Fetches a search record if it needs to be fetched from the server, given it's searchId.
 * */
export default compose(
  setDisplayName('withSearchFetched'),
  setPropTypes({
    searchId: PropTypes.number.isRequired,
  }),
  connect(
    (state, { searchId }) => ({
      search: searchId && getSearch(state, searchId),
      shouldFetchSearch: shouldFetchSearchSelector(state, searchId),
    }),
    dispatch => ({
      fetchSearch: bindActionCreators(fetchSearchActionCreator, dispatch),
    }),
  ),
  withHandlers({
    fetchSearch: ({ fetchSearch, searchId }) => () =>
      fetchSearch({ id: searchId }),
    fetchSearchIfNeeded: ({
      fetchSearch,
      searchId,
      shouldFetchSearch,
    }) => () => {
      if (shouldFetchSearch) {
        fetchSearch({ id: searchId });
      }
    },
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchSearchIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchSearchIfNeeded();
    },
  }),
);
