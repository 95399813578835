import { connect } from 'react-redux';
import getCommentCount from '../selectors/getCommentCount';

/**
 * A higher order component which maps a `parentType` and `parentId` to it's
 * comment count
 */
export default connect(
  (state, { parentId, parentType }) => ({
    commentCount:
      parentId && parentType && getCommentCount(state, parentType, parentId),
  }),
  {},
);
