import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';

import notesByParentType from './notesByParentType';

const notesReducer = combineImmutableReducers({
  notesByParentType: notesByParentType,
});

notesReducer.NAME = NAME;

export default notesReducer;
