import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import commentsByTargetCompany from './commentsByTargetCompany';
import targetCompaniesBySearch from './targetCompaniesBySearch';

const targetCompaniesReducer = combineImmutableReducers({
  commentsByTargetCompany: commentsByTargetCompany,
  targetCompaniesBySearch: targetCompaniesBySearch,
});

targetCompaniesReducer.NAME = NAME;

export default targetCompaniesReducer;
