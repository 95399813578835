import createEntity from 'modules/entities/actions/createEntity';
import routes from 'modules/routing/routes';
import companySchema from '../companySchema';

/**
 * An action for creating company
 * @param {Object} payload
 * @param {Object} payload.company The company record to create
 */
export default ({ company: { ...company }, ...rest }) => dispatch =>
  dispatch(
    createEntity({
      ...rest,
      body: {
        company: {
          ...company,
        },
      },
      entityType: companySchema.key,
      responseSchema: { company: companySchema },
      url: routes.api_v1_companies(),
    }),
  ).then(action => action);
