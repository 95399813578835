import selectn from 'selectn';
import { Map } from 'immutable';
import createListReducer from '../../../reducers/createListReducer';
import {
  NETWORKS_INVALIDATE,
  NETWORKS_FETCH_START,
  NETWORKS_FETCH_SUCCESS,
  NETWORKS_FETCH_FAILURE,
  NETWORK_DELETE_SUCCESS,
  CONTACT_ADD_SUCCESS,
  CONTACT_REMOVE_SUCCESS,
} from '../actions/actionTypes';

const networkListReducer = createListReducer({
  resultKey: 'networks',
  entityResultKey: 'network',
  request: NETWORKS_FETCH_START,
  success: NETWORKS_FETCH_SUCCESS,
  failure: NETWORKS_FETCH_FAILURE,
  invalidated: NETWORKS_INVALIDATE,
  deleted: NETWORK_DELETE_SUCCESS,
});

// Contains the list of networks that a contact belongs to, keyed by the contact ID.
export default (state = new Map(), action) => {
  switch (action.type) {
    case NETWORKS_INVALIDATE:
    case NETWORKS_FETCH_START:
    case NETWORKS_FETCH_SUCCESS:
    case NETWORKS_FETCH_FAILURE: {
      const contactId = selectn('payload.contactId', action);

      if (contactId) {
        return state.update(contactId, list =>
          networkListReducer(list, action),
        );
      }

      return state;
    }
    case CONTACT_ADD_SUCCESS: {
      const contactId = selectn('payload.contactId', action);
      const networkId = selectn('payload.networkId', action);

      if (contactId && networkId && state.hasIn([contactId, 'ids'])) {
        return state.updateIn([contactId, 'ids'], ids =>
          ids.includes(networkId) ? ids : ids.push(networkId),
        );
      }

      return state;
    }
    case CONTACT_REMOVE_SUCCESS: {
      const contactId = selectn('payload.contactId', action);
      const networkId = selectn('payload.networkId', action);

      if (contactId && networkId && state.hasIn([contactId, 'ids'])) {
        return state.updateIn([contactId, 'ids'], ids =>
          ids.filter(id => id !== networkId),
        );
      }

      return state;
    }
    default: {
      return state.map(contactListState =>
        networkListReducer(contactListState, action),
      );
    }
  }
};
