import { mapProps } from 'recompose';
import { PARENT_CANDIDACY } from '../constants';

/**
 * A higher-order component that takes a `candidacy` and maps it to the appropriate
 * `parentType` and `parentId` values that can be used to fetch/display
 * comments that belong to that candidacy.
 */
export default mapProps(({ candidacyId, ...props }) => ({
  ...props,
  parentType: PARENT_CANDIDACY,
  parentId: candidacyId,
}));
