import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as actions from '../actions';
import getAllById from '../selectors/getAllById';

export default connect(
  state => ({
    transactions: getAllById(state),
  }),
  dispatch => ({
    transactionActions: bindActionCreators(actions, dispatch),

    // This is for temporary backwards compatability.
    clearTransaction: bindActionCreators(actions.clearTransaction, dispatch),
  }),
);
