import { fromJS, OrderedMap } from 'immutable';

/**
 * The initial state used to initialize the states of both the overall collection and of each
 * individual item.
 * @type {Immutable.Map}
 */
export const INITIAL_STATE = fromJS({
  meta: {
    isFetching: false,
    isInvalidated: false,
  },
});

/**
 * An identity function that returns the first parameter it is called with.
 */
const identity = input => input;

/**
 * Creates a reducer function for maintaining a collection of items that has asynchronous fetches,
 * creates, updates, and deletes.
 *
 * The state contains top-level `meta` and `data` values, with `data` being an OrderedMap keyed on
 * each item's id value, and each item also contains `meta` and `data` keys.
 * Ultimately the structure of the collection ends up like this:
 *
 * ```
 * {
 *   meta: {
 *     isFetching: false,
 *     isInvalidated: false,
 *     error: { ... }
 *   },
 *   data: {
 *     1: {
 *       meta: {
 *         isFetching: false,
 *         isInvalidated: false,
 *         isDeleting: false,
 *         isUpdating: false,
 *         error: { ... }
 *       },
 *       data: {
 *         id: 1,
 *         name: 'John Doe'
 *       }
 *     },
 *     2: {
 *       meta: {
 *         isFetching: false,
 *         isInvalidated: false,
 *         isDeleting: false,
 *         isUpdating: false,
 *         error: { ... }
 *       },
 *       data: {
 *         id: 2,
 *         name: 'Ford Prefect'
 *       }
 *     }
 *   }
 * }
 * ```
 *
 * @param {Object} collection Options for handling actions related to the collection as a whole.
 * @param {String} collection.payloadDataKey The key used to obtain the array of items from the
 *   payload when the collection is fetched. Not used if `collection.collectionFromPayload` is
 *   specified.
 * @param {Function} collection.collectionFromPayload A function that returns the collection of
 *   items given the payload when the collection is fetched. Required if `collection.payloadKey`
 *   is not specified.
 * @param {String} collection.invalidate Action type dispatched when the collection is invalidated.
 * @param {String} collection.fetchState Action type dispatched when a fetch of the entire
 *   collection has started.
 * @param {String} collection.fetchSuccess Action type dispatched when the collection has been
 *   successfully fetched.
 * @param {String} collection.fetchFailure Action type dispatched when a failure occured
 *   fetching the collection.
 * @param {Function} sort] A custom method that can be used to sort the collection. This method
 *   is called with the overall state so that `meta` can be accessed, if needed. It is safe to
 *   assume that `data` will be defined and will be an OrderedMap.
 *
 *   As an example sorting could be done based on a meta property as follows:
 *   ```
 *   (state) => state.update('data', (data) =>
 *      data.sortBy(item => item.getIn(['data', state.getIn(['meta', 'sortBy']) || 'last_name'])))
 *   ```
 * @param {Object} item Options for handling actions related to undividual items in the collection.
 * @param {String} [item.idKey='id'] The key on an item object that corresponds to it's unique ID.
 * @param {String} item.payloadDataKey The key used to obtain the item's data from the payload.
 * @param {Function} item.itemFromPayload A function which, given the payload of an item action,
 *   returns the data for the item.
 * @param {Function} item.idFromItem A function which, given the data for an item, returns the
 *   item's id. The default implementation simply returns the value of the item's `idKey` key.
 * @param {Function} item.idFromPayload A function which, given the payload of an item action,
 *   returns the item's id. The default implementation calls `idFromItem` with the result of
 *   `itemFromPayload` to obtain the ID.
 * @param {Function} [item.transformData=Immutable.fromJS] A function that transforms an item's
 *   raw data to the value that is stored in state. The default implementation converts the object
 *   to an Immutable using `Immutable.fromJS`.
 * @param {String} item.invalidate Action type dispatched when an item should be invalidated.
 * @param {String} item.fetchStart Action type dispatched when an item is being fetched.
 * @param {String} item.fetchSuccess Action type dispatched when an item has been fetched.
 * @param {String} item.fetchFailure Action type dispatched when an item fetch failed.
 * @param {String} item.createStart Action type dispatched when an item is being created.
 * @param {String} item.createSuccess Action type dispatched when an item has been createed.
 * @param {String} item.createFailure Action type dispatched when creating an item failed.
 * @param {String} item.updateStart Action type dispatched when an item is being updated.
 * @param {String} item.updateSuccess Action type dispatched when an item has been updateed.
 * @param {String} item.updateFailure Action type dispatched when an item update failed.
 * @param {String} item.deleteStart Action type dispatched when an item is being deleted.
 * @param {String} item.deleteSuccess Action type dispatched when an item has been deleted.
 * @param {String} item.deleteFailure Action type dispatched when an item delete failed.
 * @param {Function} defaultReducer A reducer function that is called for any other action type
 *   that is not matched by the collection reducer.
 */
const createCollectionReducer = ({
  collection: {
    payloadDataKey: collectionDataKey,
    collectionFromPayload = payload => payload.data[collectionDataKey],
    invalidate: collectionInvalidate,
    clear: collectionClear,
    fetchStart: collectionFetchStart,
    fetchSuccess: collectionFetchSuccess,
    fetchFailure: collectionFetchFailure,
    sort = identity,

    /**
     * Determines whether a fetch should merge results into the existing data (when
     * `shouldMerge()` returns true) or if the results should replace the existing data
     * (when `shouldMerge()` returns false).
     * By default fetches always replace all data.
     * @return {Boolean} A value indicating whether results should be merged into the
     *   existing collection (true) or should replace the collection (false).
     */
    shouldMerge = () => false,
  },
  item: {
    idKey = 'id',
    payloadDataKey: itemDataKey,
    itemFromPayload = payload =>
      payload.data ? payload.data[itemDataKey] : payload[itemDataKey],
    idFromItem = item => item[idKey],
    idFromPayload = payload => idFromItem(itemFromPayload(payload)),
    transformData = fromJS,
    invalidate,
    fetchStart,
    fetchSuccess,
    fetchFailure,
    createStart,
    createSuccess,
    createFailure,
    updateStart,
    updateSuccess,
    updateFailure,
    deleteStart,
    deleteSuccess,
    deleteFailure,
  } = {},
  defaultReducer = identity,
}) => {
  const createItemState = itemData =>
    INITIAL_STATE.set('data', transformData(itemData));

  return (state = INITIAL_STATE, action = {}) => {
    const { payload, type } = action;

    if (typeof type === 'undefined') {
      return defaultReducer(state, action);
    }

    switch (type) {
      case collectionClear: {
        return INITIAL_STATE;
      }
      case collectionInvalidate: {
        return state.setIn(['meta', 'isInvalidated'], true);
      }
      case collectionFetchStart: {
        return state.setIn(['meta', 'isFetching'], true);
      }
      case collectionFetchSuccess: {
        const collection = collectionFromPayload(payload) || [];
        const mappedCollection = new OrderedMap(
          collection.map(item => [idFromItem(item), createItemState(item)]),
        );

        return sort(
          state.withMutations(map => {
            map.setIn(['meta', 'isFetching'], false);
            map.deleteIn(['meta', 'error']);

            if (map.has('data') && shouldMerge(state, action)) {
              map.mergeIn(['data'], mappedCollection);
            } else {
              map.set('data', mappedCollection);
              map.setIn(['meta', 'isInvalidated'], false);
            }
          }),
        );
      }
      case collectionFetchFailure: {
        return state.update('meta', meta =>
          meta.set('isFetching', false).set('error', payload.error),
        );
      }
      case invalidate: {
        const id = idFromPayload(payload);
        if (!state.hasIn(['data', id])) {
          return state;
        }

        return state.setIn(['data', id, 'meta', 'isInvalidated'], true);
      }
      case fetchStart: {
        const id = idFromPayload(payload);
        return state.setIn(['data', id, 'meta', 'isFetching'], true);
      }
      case fetchSuccess:
      case createSuccess: {
        const id = idFromPayload(payload);
        return sort(
          state.setIn(['data', id], createItemState(itemFromPayload(payload))),
        );
      }
      case fetchFailure: {
        const id = idFromPayload(payload);
        if (!state.hasIn(['data', id])) {
          return state;
        }

        return state.updateIn(['data', id, 'meta'], meta =>
          meta.set('isFetching', false).set('error', payload.error),
        );
      }
      case updateStart: {
        const id = idFromPayload(payload);
        if (!state.hasIn(['data', id])) {
          return state;
        }

        return state.updateIn(['data', id], itemState =>
          itemState.withMutations(map => {
            if (!map.getIn(['meta', 'isUpdating'])) {
              // Prevent setting originalData multiple times and losing the actual
              // original data.
              map.set('originalData', map.get('data'));
            }
            map.setIn(['meta', 'isUpdating'], true);
            map.deleteIn(['meta', 'error']);
            map.merge({
              data: transformData(itemFromPayload(payload)),
            });
          }),
        );
      }
      case updateSuccess: {
        const id = idFromPayload(payload);

        return sort(
          state.updateIn(['data', id], itemState =>
            itemState.withMutations(map => {
              map.setIn(['meta', 'isUpdating'], false);
              map.setIn(['meta', 'isInvalidated'], false);
              map.deleteIn(['meta', 'error']);
              map.delete('originalData');
              map.set('data', transformData(itemFromPayload(payload)));
            }),
          ),
        );
      }
      case updateFailure: {
        const id = idFromPayload(payload);
        if (!state.hasIn(['data', id])) {
          return state;
        }

        return state.updateIn(['data', id], itemState =>
          itemState.withMutations(map => {
            map.setIn(['meta', 'isUpdating'], false);
            map.setIn(['meta', 'error'], payload.error);
            map.set('data', itemState.get('originalData'));
            map.delete('originalData');
          }),
        );
      }
      case deleteStart: {
        const id = idFromPayload(payload);
        if (!state.hasIn(['data', id])) {
          return state;
        }

        return state.setIn(['data', id, 'meta', 'isDeleting'], true);
      }
      case deleteSuccess: {
        const id = idFromPayload(payload);
        if (!state.hasIn(['data', id])) {
          return state;
        }

        return state.deleteIn(['data', id]);
      }
      case deleteFailure: {
        const id = idFromPayload(payload);
        if (!state.hasIn(['data', id])) {
          return state;
        }

        return state.updateIn(['data', id, 'meta'], meta =>
          meta.set('isDeleting', false).set('error', payload.error),
        );
      }
      case createFailure:
      case createStart:
      default: {
        return defaultReducer(state, action);
      }
    }
  };
};

export default createCollectionReducer;
