import { FILTERS } from '../constants';
import getUserList from './getUserList';

/**
 * Returns a boolean value indicating whether to fetch a list of users
 * based on the provided list state
 *
 * @param  {Immutable.Map} state
 * @return {Boolean}
 */
export default function shouldFetchUsers(state, filter) {
  if (filter && !FILTERS.includes(filter)) {
    throw new TypeError(
      `Unknown filter supplied to shouldFetchUserList: ${filter}`,
    );
  }

  const userList = getUserList(state, filter);

  if (!userList) {
    return true;
  }

  if (userList.getIn(['_meta', 'isFetching'])) {
    // Already in the process of fetching, so don't fetch again.
    return false;
  }

  if (!userList.get('ids') && !userList.hasIn(['_meta', 'error'])) {
    // Don't have data, and don't have any errors from a previous fetch.
    return true;
  }

  // Fetch only if we've been explicitly invalidated.
  return Boolean(userList.getIn(['_meta', 'isInvalidated']));
}
