import createQueryReducer from '../createQueryReducer';
import {
  CONTACT_QUERY_CREATE,
  CONTACT_QUERY_DESTROY,
  CONTACT_QUERY_FETCH_START,
  CONTACT_QUERY_FETCH_SUCCESS,
  CONTACT_QUERY_FETCH_FAILURE,
  CONTACT_QUERY_INVALIDATE,
} from '../../actions/contacts/constants';

export default createQueryReducer({
  create: CONTACT_QUERY_CREATE,
  destroy: CONTACT_QUERY_DESTROY,
  start: CONTACT_QUERY_FETCH_START,
  success: CONTACT_QUERY_FETCH_SUCCESS,
  failure: CONTACT_QUERY_FETCH_FAILURE,
  invalidate: CONTACT_QUERY_INVALIDATE,
});
