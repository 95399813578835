import { NAME } from '../constants';

/**
 * Gets the list of stages for a particular stage type.
 * @param {Object} state
 * @param {String} stageType The stage type constant (`STAGE_TYPE_JOB_SEARCH`,
 *   `STAGE_TYPE_TALENT_POOL`)
 * @return {Immutable.Map} The stage list for the given stage type
 */
const getStageListForStageType = (state, stageType) =>
  state[NAME].getIn(['stageListsByType', stageType]);

export default getStageListForStageType;
