import getCandidacyProperty from './getCandidacyProperty';

/**
 * Gets a candidacy's underlying contact ID value.
 * @param {Object} state
 * @param {Number} id The candidacy ID
 * @return {?Number} The ID of a candidacy's contact.
 */
export default (state, candidacyId) =>
  getCandidacyProperty(state, candidacyId, 'contact');
