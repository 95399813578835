import selectn from 'selectn';
import invalidateActivityList from 'modules/activities/actions/invalidateActivityList';
import { PARENT_CANDIDACY, PARENT_SEARCH } from 'modules/activities/constants';
import invalidateCommentList from 'modules/comments/actions/invalidateCommentList';
import updateEntity from 'modules/entities/actions/updateEntity';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import routes from 'modules/routing/routes';
import * as searchActions from 'modules/searches/actions';
import { candidacySchema, candidacyVoteSchema } from '../schemas';

/**
 * Creates an action for updating a candidacy
 * @param {Object} payload
 * @param {String} payload.id The ID of the candidacy to update
 * @param {Object} payload.candidacy The candidacy record containing the properties to update
 * @param {String} [payload.candidacy.stage] The stage to update the candidacy to.
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 * @param {String} [payload.redirectAfterSuccess] When provided, the browser will be redirected
 *   to this URL when the update has completed successfully.
 */
export default ({
  candidacy,
  id,
  redirectAfterSuccess,
  transactionId,
}) => dispatch => {
  return dispatch(
    updateEntity({
      transactionId: transactionId,
      entityType: candidacySchema.key,
      responseSchema: { candidacy: candidacySchema, vote: candidacyVoteSchema },
      url: routes.api_v1_candidacy({ id: id }),
      body: { candidacy: candidacy },
    }),
  ).then(action => {
    if (action.type === entityActionTypes.UPDATE_SUCCESS) {
      const contactId = selectn(
        `payload.entities.${candidacySchema.key}.${id}.contact`,
        action,
      );
      const searchId = selectn(
        `payload.entities.${candidacySchema.key}.${id}.search_id`,
        action,
      );

      dispatch(
        invalidateActivityList({
          parentId: id,
          parentType: PARENT_CANDIDACY,
        }),
      );

      dispatch(
        invalidateCommentList({
          candidacyId: id,
          contactId: contactId,
        }),
      );

      dispatch(
        invalidateActivityList({
          parentId: searchId,
          parentType: PARENT_SEARCH,
        }),
      );

      dispatch(searchActions.invalidateCandidacyStats({ searchId: searchId }));

      // This is really awful. It's really just for redirecting to the offer
      // form when a candidate is moved to the offer stage. We need to get
      // rid of this and replace it with a better workflow/ux.
      if (redirectAfterSuccess) {
        window.location = redirectAfterSuccess;
      }
    }

    return action;
  });
};
