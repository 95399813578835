import getSortName from 'modules/contacts/selectors/contacts/getSortName';
import getCandidacyContactId from './getCandidacyContactId';

/**
 * Gets a sortable value for the name of a candidacy record.
 * @param {Object} state
 * @param {Number} id The candidacy ID
 * @return {?String} The name of the contact appropriate for use in sorting.
 */
const getCandidacySortName = (state, candidacyId) => {
  const contactId = getCandidacyContactId(state, candidacyId);
  return contactId && getSortName(state, contactId);
};

export default getCandidacySortName;
