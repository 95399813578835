//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'user';

//---------------------------------------------------------
// Preferences
//---------------------------------------------------------

/**
 * The key used to store the user preferences in local storage.
 */
export const PREFERENCES_STORAGE_KEY = 'THRIVE_PREFERENCES';

//---------------------------------------------------------
// Integration
//---------------------------------------------------------

/**
 * Keys identifying the different types of calendar integration.
 */

export const GOOGLE_CALENDAR_INTEGRATION = 'google';
export const OUTLOOK_CALENDAR_INTEGRATION = 'outlook';
export const CALENDAR_INTEGRATION_TYPES = [
  GOOGLE_CALENDAR_INTEGRATION,
  OUTLOOK_CALENDAR_INTEGRATION,
];

/**
 * Several static createFieldState functions need to know the default_currency value
 * but do not have access to redux state.
 *
 * After an attempt at passing the default_currency from state through HOCs to the
 * static functions, it became very messy and had negative side-effects on other
 * un-related components. As a result, we are opting to use the window.
 *
 * Instead of referencing the window in each static function,
 * centralize the window 'antipattern' here for consistency.
 */
export const DEFAULT_CURRENCY =
  window?.thv?.user?.userOptions?.default_currency || 'USD';
