import merge from 'modules/entities/actions/merge';
import { CONTACT_UPDATE_PRIMARY_POSITION } from './actionTypes';
import { contactSchema } from '../../schema';

/**
 * Updates a contact record in state to reflect a primary position relationship
 * @param {Object} payload
 * @param {Nunber} payload.contactId The contact ID to update
 * @param {Number} payload.positionId The ID of the position that is the contact's "primary"
 *   position record.
 * @returns {Object} A redux action
 */
export default ({ contactId, positionId }) =>
  merge({
    data: {
      id: contactId,
      primary_position: positionId || null,
    },
    schema: contactSchema,
    type: CONTACT_UPDATE_PRIMARY_POSITION,
  });
