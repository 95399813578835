import { NAME } from '../constants';
import hasLoadedTenantOptions from './hasLoadedTenantOptions';

/**
 * Gets a value indicating whether we need to fetch the tenant options from the server.
 * @param {Object} state
 * @returns {Boolean} True if the tenant options need to be fetched from the server; otherwise
 *   false (if we are currently fetching them or have already fettched them).
 */
export default state => {
  if (
    state[NAME].getIn(['tenantOptions', 'meta', 'isFetching']) ||
    state[NAME].getIn(['tenantOptions', 'meta', 'error'])
  ) {
    return false;
  }

  return !hasLoadedTenantOptions(state);
};
