//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'compensations';

//---------------------------------------------------------
// Valid "name" values
//---------------------------------------------------------

export const NAME_EMPLOYMENT = 'FTE';
export const NAME_BOARD_MEMBER = 'Board member';

export const COMPENSATION_NAMES = [NAME_EMPLOYMENT, NAME_BOARD_MEMBER];

/**
 * The titles to display for a particular compensation section as identified by the
 * compensation record type name.
 */
export const COMPENSATION_TITLES = {
  [NAME_EMPLOYMENT]: 'Current Compensation',
  [NAME_BOARD_MEMBER]: 'Board Member Compensation',
};

//---------------------------------------------------------
// Reports
//---------------------------------------------------------

export const REPORT_CANDIDATE_COMPENSATION = 'candidate_compensation_report';
