import PropTypes from 'prop-types';
import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import * as assessmentActionCreators from '../actions';
import shouldFetchSearchAttributesSelector from '../selectors/shouldFetchSearchAttributes';

/**
 * Fetches the assessment attributes for a search
 */
export default compose(
  setDisplayName('withAttributesFetched'),
  setPropTypes({
    searchId: PropTypes.number,
  }),
  connect(
    (state, { searchId }) => ({
      shouldFetchSearchAttributes: shouldFetchSearchAttributesSelector(
        state,
        searchId,
      ),
    }),
    dispatch => ({
      assessmentActions: bindActionCreators(assessmentActionCreators, dispatch),
    }),
  ),
  withHandlers({
    /**
     * A handler that, when called, only fetches the options if needed.
     */
    fetchAttributesIfNeeded: ({
      assessmentActions,
      searchId,
      shouldFetchSearchAttributes,
    }) => () => {
      if (shouldFetchSearchAttributes) {
        assessmentActions.fetchAttributes({ searchId: searchId });
      }
    },
  }),

  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchAttributesIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchAttributesIfNeeded();
    },
  }),
);
