// From: https://github.com/jquery-boilerplate/jquery-boilerplate/blob/master/src/jquery.boilerplate.coffee
(function createSectionTogglePlugin($) {
  const pluginName = 'sectionToggle';
  const defaults = {};

  class SectionTogglePlugin {
    constructor(container, options) {
      this.container = $(container);
      this.settings = $.extend({}, defaults, options);
      this._defaults = defaults;
      this._name = pluginName;

      this.sections = this.container.find('section');
      this.buttons = this.container.find('[data-section-toggle-button]');

      this.init();
    }

    init() {
      if (this.isActive() && !this.dontToggle()) {
        this.sections.slideToggle(0);
      }

      return this.buttons.off('click').on('click', e => this.toggleSections(e));
    }

    isActive() {
      return this.container.hasClass('active');
    }

    dontToggle() {
      return this.container.hasClass('dont-toggle');
    }

    toggleSections(e) {
      e.preventDefault();
      this.sections.slideToggle(200);
      return this.container.toggleClass('active', !this.isActive());
    }
  }

  // A really lightweight plugin wrapper around the constructor,
  // preventing against multiple instantiations
  $.fn[pluginName] = function sectionToggle(options) {
    // eslint-disable-line no-param-reassign
    return this.each(function initSectionTogglePluginInstance() {
      if (!$.data(this, `plugin_${pluginName}`)) {
        $.data(
          this,
          `plugin_${pluginName}`,
          new SectionTogglePlugin(this, options),
        );
      }
    });
  };
})(jQuery);
