//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'contacts_new';

//---------------------------------------------------------
// Contacts column keys
//---------------------------------------------------------

export const COLUMN_KEY_AVAILABILITY = 'seeking_job_status';
export const COLUMN_KEY_AVAILABILITY_REASON = 'seeking_job_reason';
export const COLUMN_KEY_AVERAGE_RATING = 'average_rating';
export const COLUMN_KEY_CANDIDACIES_COUNT = 'candidacies_count';
export const COLUMN_KEY_CITIZENSHIPS = 'citizenships';
export const COLUMN_KEY_COMPANY_JOB_TITLE = 'company_job_title';
export const COLUMN_KEY_COMPANY_NAME = 'company';
export const COLUMN_KEY_COMPANY_PREVIOUS_NAME = 'previous_company';
export const COLUMN_KEY_CONTACT_SOURCE = 'contact_source';
export const COLUMN_KEY_CREATED_AT = 'created_at';
export const COLUMN_KEY_CREATED_BY = 'creator';
export const COLUMN_KEY_CURRENT_BASE_COMPENSATION = 'current_base_compensation';
export const COLUMN_KEY_CURRENT_BONUS_COMPENSATION =
  'current_bonus_compensation';
export const COLUMN_KEY_CURRENT_EQUITY_COMPENSATION =
  'current_equity_compensation';
export const COLUMN_KEY_CURRENTLY_REPORTS_TO = 'currently_reports_to';
export const COLUMN_KEY_ADDITIONAL_EQUITY_GRANT = 'additional_equity_grant';
export const COLUMN_KEY_TOTAL_EQUITY_GRANT = 'total_equity_grant';
export const COLUMN_KEY_EXTERNAL_ID = 'external_id';
export const COLUMN_KEY_DESIRED_BASE_COMPENSATION_RANGE =
  'desired_base_compensation_range';
export const COLUMN_KEY_DESIRED_BONUS_COMPENSATION_RANGE =
  'desired_bonus_compensation_range';
export const COLUMN_KEY_DIRECT_REPORTS_COUNT = 'direct_reports_count';
export const COLUMN_KEY_GDPR_STATUS = 'gdpr_status';
export const COLUMN_KEY_INTERACTION_LEVEL = 'interaction_level';
export const COLUMN_KEY_JOB_TITLE = 'primary_position_name';
export const COLUMN_KEY_LANGUAGES = 'languages';
export const COLUMN_KEY_LAST_MODIFIED = 'updated_at';
export const COLUMN_KEY_LAST_OUTREACH = 'last_contacted_on';
export const COLUMN_KEY_LOCATION = 'location';
export const COLUMN_KEY_MOBILE_PHONE = 'mobile_phone';
export const COLUMN_KEY_NAME = 'name';
export const COLUMN_KEY_OFF_LIMITS = 'off_limits_status';
export const COLUMN_KEY_OUTREACHES_COUNT = 'outreaches_count';
export const COLUMN_KEY_PERSONAL_EMAIL = 'email';
export const COLUMN_KEY_PHONE = 'phone';
export const COLUMN_KEY_PREFERRED_BOARD_COMPANY_REVENUE =
  'preferred_board_company_revenue';
export const COLUMN_KEY_PREFERRED_BOARD_MEMBER_COMPANY_STAGES =
  'preferred_board_member_company_stages';
export const COLUMN_KEY_PREFERRED_BOARD_MEMBER_INTEREST =
  'preferred_board_member_interest';
export const COLUMN_KEY_PREFERRED_COMMUTE_RADIUS = 'preferred_commute_radius';
export const COLUMN_KEY_PREFERRED_COMPANY_REVENUE = 'preferred_company_revenue';
export const COLUMN_KEY_PREFERRED_COMPANY_STAGES = 'preferred_company_stages';
export const COLUMN_KEY_PREFERRED_EMAIL = 'preferred_email';
export const COLUMN_KEY_PREFERRED_EQUITY_RANGE = 'preferred_equity';
export const COLUMN_KEY_PREFERRED_INDUSTRIES = 'preferred_industries';
export const COLUMN_KEY_PREFERRED_INVESTOR_COMPANY_REVENUE =
  'preferred_investor_company_revenue';
export const COLUMN_KEY_PREFERRED_INVESTOR_COMPANY_STAGES =
  'preferred_investor_company_stages';
export const COLUMN_KEY_PREFERRED_INVESTOR_INTEREST =
  'preferred_investor_interest';
export const COLUMN_KEY_PREFERRED_JOB_FUNCTIONS = 'preferred_job_functions';
export const COLUMN_KEY_PREFERRED_LEVEL = 'level';
export const COLUMN_KEY_PREFERRED_PORTFOLIO_COMPANIES =
  'preferred_portfolio_companies';
export const COLUMN_KEY_PREFERRED_RELOCATE_DOMESTICALLY =
  'preferred_relocate_domestically';
export const COLUMN_KEY_PREFERRED_RELOCATE_INTERNATIONALLY =
  'preferred_relocate_internationally';
export const COLUMN_KEY_PREFERRED_SECTORS = 'preferred_sectors';
export const COLUMN_KEY_PREFERRED_TOTAL_COMPENSATION =
  'preferred_total_compensation';
export const COLUMN_KEY_PRIMARY_POSITION_LEVELS = 'primary_position_levels';
export const COLUMN_KEY_REFERRED_BY_COMPANY = 'referred_by_firm';
export const COLUMN_KEY_REFERRED_BY_CONTACT = 'referred_by';
export const COLUMN_KEY_REFERRED_DATE = 'referred_date';
export const COLUMN_KEY_RELATIONSHIP_MANAGER_EMAIL =
  'relationship_manager_email';
export const COLUMN_KEY_REPORTS_TO = 'reports_to';
export const COLUMN_KEY_SEEKING_JOB_STATUS_START_DATE =
  'seeking_job_status_start_date';
export const COLUMN_KEY_SKILLS = 'skills';
export const COLUMN_KEY_SOCIAL_MEDIA_LINK = 'social_link';
export const COLUMN_KEY_US_VISA_REQUIRED = 'us_visa_required';
export const COLUMN_KEY_WORK_EMAIL = 'work_email';
export const COLUMN_KEY_WORK_PHONE = 'work_phone';

//---------------------------------------------------------
// Parent types
//---------------------------------------------------------

// Used to identify contacts that belong to a company
export const PARENT_COMPANY = 'company';

// All valid parent types
export const PARENT_TYPES = [PARENT_COMPANY];

// Link prefix for GHire links
export const GOOGLE_HIRE_LINK_PREFIX =
  'https://hiring.corp.google.com/pro#/profile/google.com-';

export const CONSENT_STATUS_OPTIONS = {
  lbi: {
    value: 'Legitimate Business Interest',
    label: 'Legitimate Business Interest',
  },
  lbiNeeded: { value: 'LBI Notice Needed', label: 'LBI Notice Needed' },
  lbiNotSent: { value: 'LBI Notice Not Sent', label: 'LBI Notice Not Sent' },
  notApplicable: { value: 'Not Applicable', label: 'Not Applicable' },
  pending: { value: 'Consent Pending', label: 'Consent Pending' },
  provided: { value: 'Consent Provided', label: 'Consent Provided' },
  revoked: { value: 'Consent Revoked', label: 'Consent Revoked' },
  undefined: { value: 'Undefined', label: 'Undefined' },
};

export const CASL_STATUS_OPTIONS = [
  CONSENT_STATUS_OPTIONS.notApplicable,
  CONSENT_STATUS_OPTIONS.pending,
  CONSENT_STATUS_OPTIONS.provided,
  CONSENT_STATUS_OPTIONS.revoked,
  CONSENT_STATUS_OPTIONS.undefined,
];

export const CANDIDATE_USER_CASL_STATUS_OPTIONS = [
  CONSENT_STATUS_OPTIONS.pending,
  CONSENT_STATUS_OPTIONS.provided,
  CONSENT_STATUS_OPTIONS.revoked,
];

export const GDPR_STATUS_OPTIONS = [
  CONSENT_STATUS_OPTIONS.notApplicable,
  CONSENT_STATUS_OPTIONS.lbi,
  CONSENT_STATUS_OPTIONS.lbiNeeded,
  CONSENT_STATUS_OPTIONS.lbiNotSent,
  CONSENT_STATUS_OPTIONS.pending,
  CONSENT_STATUS_OPTIONS.provided,
  CONSENT_STATUS_OPTIONS.revoked,
];

/**
 * Optional parameters that can be used when fetching a list of contacts.
 */
export const FETCH_PARAMS = [
  'currentPositionFilterSelection',
  'page',
  'limit',
  'sortBy',
  'sortAscending',
  'keyword',
  'view',
  'filters',
];
