import selectn from 'selectn';
import { fromJS } from 'immutable';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import createEntityListReducer from 'modules/entities/createEntityListReducer';
import paramsEqual from 'modules/routing/selectors/paramsEqual';
import {
  BULK_IMPORT_LIST_INVALIDATE,
  BULK_IMPORT_LIST_FETCH_START,
  BULK_IMPORT_LIST_FETCH_SUCCESS,
  BULK_IMPORT_LIST_FETCH_FAILURE,
} from './actions/actionTypes';
import bulkImportSchema from './schema';
import { NAME, FETCH_PARAMS } from './constants';

/**
 * A reducer that maintains a list of all bulkImport IDs.
 */
const bulkImportListReducer = createEntityListReducer({
  entityType: bulkImportSchema.key,
  // The backend still refers to bulk imports as csv uploads, so we have to reference
  // the `csv_uploads` key from the API response
  listResultKey: 'csv_uploads',
  entityResultKey: 'bulkImports',
  request: BULK_IMPORT_LIST_FETCH_START,
  success: BULK_IMPORT_LIST_FETCH_SUCCESS,
  failure: BULK_IMPORT_LIST_FETCH_FAILURE,
  invalidated: BULK_IMPORT_LIST_INVALIDATE,
});

const INITIAL_STATE = fromJS({});

const bulkImportsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BULK_IMPORT_LIST_FETCH_START: {
      return bulkImportListReducer(state, action).set(
        'params',
        fromJS(selectn('payload.params', action)),
      );
    }
    case entityActionTypes.CREATE_SUCCESS: {
      // add the new ID to the beginning of the list in state
      const bulkImportId = selectn('payload.result.csv_upload', action);
      if (bulkImportId && state.has('ids')) {
        return state.update('ids', ids => ids.unshift(bulkImportId));
      }

      return state;
    }
    case BULK_IMPORT_LIST_INVALIDATE: {
      return state.setIn(['_meta', 'isInvalidated'], true);
    }
    case BULK_IMPORT_LIST_FETCH_FAILURE:
    case BULK_IMPORT_LIST_FETCH_SUCCESS: {
      // Check to make sure that the current params we have in state match the params
      // in the action. This assures that if we make 2 requests in quick succession --
      // say "page=1" then "page=2", that once we get a responses we only care about the most
      // recent request. Otherwise the response for page 1 could potentially overwrite the
      // response for page 2 if they came back in a different order than requested.
      const params = state.get('params');
      if (
        !paramsEqual(
          params && params.toJS(),
          selectn('payload.params', action),
          FETCH_PARAMS,
        )
      ) {
        return state;
      }

      return bulkImportListReducer(state, action).set(
        'pagination',
        fromJS(selectn('payload.result.meta', action)),
      );
    }
    default: {
      return state;
    }
  }
};

bulkImportsReducer.NAME = NAME;

export default bulkImportsReducer;
