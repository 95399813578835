import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * Renders a button for cancelling/closing a form.
 * Used in conjunction with the `Form` component.
 */
const CancelButton = ({ bsSize, children, className, ...props }) => (
  <button
    className={classnames(
      'CancelButton',
      className,
      'btn btn-primary-outline',
      {
        [`btn-${bsSize}`]: Boolean(bsSize),
      },
    )}
    type='button'
    {...props}
  >
    {children}
  </button>
);

CancelButton.defaultProps = {
  bsSize: 'sm',
  children: 'Cancel',
  className: null,
};

CancelButton.propTypes = {
  bsSize: PropTypes.oneOf(['xs', 'sm', 'lg']),
  children: PropTypes.node,
  className: PropTypes.string,
};

export default CancelButton;
