import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import withPositionIdsSortedByDate from 'modules/positions/components/withPositionIdsSortedByDate';
import Card from '@thrivetrm/ui/components/Card';
import PositionListItem, {
  defaultProps as PositionListItemDefaultProps,
  propTypes as PositionListItemPropTypes,
} from './PositionListItem';

/**
 * Renders a list of positions.
 */
export const PositionList = ({
  positionIds,
  renderDescriptions,
  renderInsideACard,
  renderTitles,
  targetedCompanyIds,
  ...props
}) => {
  const positionList = (
    <ul className='PositionList list-generic'>
      {positionIds &&
        positionIds
          .map(positionId => (
            <PositionListItem
              {...props}
              key={positionId}
              positionId={positionId}
              renderDescription={renderDescriptions}
              renderTitle={renderTitles}
              targetedCompanyIds={targetedCompanyIds}
            />
          ))
          .toArray()}
    </ul>
  );
  return renderInsideACard ? (
    <Card isCentered={false}>{positionList}</Card>
  ) : (
    positionList
  );
};

PositionList.propTypes = {
  className: PropTypes.string,

  /**
   * When the list is editable, the layout to use when editing a position.
   */
  formLayout: PositionListItemPropTypes.formLayout,

  /**
   * The map of positions to render.
   */
  positionIds: ImmutablePropTypes.listOf(PropTypes.number).isRequired,

  /**
   * True to render the description for each position; false to omit them.
   */
  renderDescriptions: PropTypes.bool,

  /**
   * This component is used on the search page and contact profile page.  We only
   * want to wrap the PositionList in a Card if it's on the search page
   */
  renderInsideACard: PropTypes.bool,

  /**
   * True to render the titles for each list item, otherwise they will not be rendered.
   */
  renderTitles: PropTypes.bool,

  /**
   * An optional list of *company IDs* (note these are company IDs, not _target_ company IDs, which
   * is a different record) which will be used to mark individual positions
   * as being a target company, if the company ID exists in this collection.
   */
  targetedCompanyIds: ImmutablePropTypes.listOf(PropTypes.number),
};

PositionList.defaultProps = {
  formLayout: PositionListItemDefaultProps.formLayout,
  renderDescriptions: true,
  renderTitles: true,
};

export default withPositionIdsSortedByDate(PositionList);
