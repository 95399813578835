/* eslint-disable camelcase */
// ^ accommodate API exchange format
import routes from 'modules/routing/routes';
import createCrudActions from '../../../actions/createCrudActions';
import {
  INTRODUCTION_LIST_FETCH_START,
  INTRODUCTION_LIST_FETCH_SUCCESS,
  INTRODUCTION_LIST_FETCH_FAILURE,
  INTRODUCTION_LIST_INVALIDATE,
  INTRODUCTION_FETCH_START,
  INTRODUCTION_FETCH_SUCCESS,
  INTRODUCTION_FETCH_FAILURE,
  INTRODUCTION_INVALIDATE,
  INTRODUCTION_CREATE_START,
  INTRODUCTION_CREATE_SUCCESS,
  INTRODUCTION_CREATE_FAILURE,
  INTRODUCTION_UPDATE_START,
  INTRODUCTION_UPDATE_SUCCESS,
  INTRODUCTION_UPDATE_FAILURE,
  INTRODUCTION_DELETE_START,
  INTRODUCTION_DELETE_SUCCESS,
  INTRODUCTION_DELETE_FAILURE,
} from './actionTypes';
import { PARENT_COMPANY, PARENT_CONTACT, PARENT_SEARCH } from '../constants';
import introductionSchema from '../schema';
import maybeDispatchNotificationsCreated from '../../../actions/notifications/maybeDispatchNotificationsCreated';

const getCollectionUrl = ({ introduction, parentId, parentType }) => {
  switch (parentType) {
    case PARENT_CONTACT: {
      return routes.api_v1_contact_introductions({ contactId: parentId });
    }
    case PARENT_COMPANY: {
      return routes.api_v1_company_introductions({ companyId: parentId });
    }
    case PARENT_SEARCH: {
      return routes.api_v1_search_introductions({ searchId: parentId });
    }
    default: {
      if (introduction && introduction.contact) {
        // This is a POST (create)
        return routes.api_v1_contact_introductions({
          contactId: introduction.contact,
        });
      }

      throw new Error(`Invalid parentType for introductions: ${parentType}`);
    }
  }
};

const getEntityUrl = ({ contactId, id, introduction }) =>
  routes.api_v1_contact_introduction({
    id: introduction ? introduction.id : id,
    contactId: introduction ? introduction.contact_id : contactId,
  });

// This renames `search` to `search_id`, `contact` to `contact_id`, and `introduced_to` to
// `introduced_to_id`.
const createSubmitPayload = ({
  introduction: { contact, introduced_to, search, ...introductionProps },
  ...options
}) => ({
  introduction: {
    ...introductionProps,
    contact_id: contact,
    introduced_to_id: introduced_to,
    search_id: search,
  },
  ...options,
});

const {
  create: createIntroduction,
  delete: deleteIntroduction,
  fetch: fetchIntroduction,
  fetchAll: fetchIntroductionList,
  invalidate: invalidateIntroduction,
  invalidateAll: invalidateIntroductionList,
  update: updateIntroduction,
} = createCrudActions({
  getUrl: getEntityUrl,
  schema: { introduction: introductionSchema },
  fetchAll: {
    getUrl: getCollectionUrl,
    schema: { introductions: [introductionSchema] },
    start: INTRODUCTION_LIST_FETCH_START,
    success: INTRODUCTION_LIST_FETCH_SUCCESS,
    failure: INTRODUCTION_LIST_FETCH_FAILURE,
  },
  invalidateAll: INTRODUCTION_LIST_INVALIDATE,
  fetch: {
    getUrl: routes.api_v1_introduction,
    start: INTRODUCTION_FETCH_START,
    success: INTRODUCTION_FETCH_SUCCESS,
    failure: INTRODUCTION_FETCH_FAILURE,
  },
  invalidate: INTRODUCTION_INVALIDATE,
  create: {
    getUrl: getCollectionUrl,
    start: INTRODUCTION_CREATE_START,
    success: INTRODUCTION_CREATE_SUCCESS,
    failure: INTRODUCTION_CREATE_FAILURE,
    createStartPayload: createSubmitPayload,
    afterResolveSuccess: (dispatch, action) => {
      // Ick. This is gross. Temporary measure until we normalize notifications.
      // This dispatches a second action but alters the payload in a way that
      // maybeDispatchNotificationsCreated expects.
      const introductionId = action.payload.result.introduction;
      const introduction =
        action.payload.entities.introductions[introductionId];
      dispatch(
        maybeDispatchNotificationsCreated(
          {
            type: action.type,
            payload: { data: { introduction: introduction } },
          },
          'introduction',
        ),
      );
      dispatch(
        invalidateIntroductionList({
          contactId: action.payload.introduction.contact_id,
          searchId: action.payload.introduction.search_id,
        }),
      );
    },
  },
  update: {
    start: INTRODUCTION_UPDATE_START,
    success: INTRODUCTION_UPDATE_SUCCESS,
    failure: INTRODUCTION_UPDATE_FAILURE,
    createStartPayload: createSubmitPayload,
  },
  delete: {
    schema: null,
    createStartPayload: ({ id, introduction }) => ({
      id: introduction ? introduction.id : id,
    }),
    start: INTRODUCTION_DELETE_START,
    success: INTRODUCTION_DELETE_SUCCESS,
    failure: INTRODUCTION_DELETE_FAILURE,
  },
});

export {
  fetchIntroductionList,
  invalidateIntroductionList,
  fetchIntroduction,
  invalidateIntroduction,
  createIntroduction,
  updateIntroduction,
  deleteIntroduction,
  // Notifications currently needs these.
  INTRODUCTION_UPDATE_START,
  INTRODUCTION_UPDATE_SUCCESS,
  INTRODUCTION_UPDATE_FAILURE,
  INTRODUCTION_DELETE_START,
  INTRODUCTION_DELETE_SUCCESS,
  INTRODUCTION_DELETE_FAILURE,
};
