import { connect } from 'react-redux';
import * as sortUtils from 'modules/core/sortUtils';
import getCandidacyPriorityWeight from '../selectors/getCandidacyPriorityWeight';
import getCandidacyRank from '../selectors/getCandidacyRank';

/**
 * A Higher order component that sorts a list of candidacyIds in order of priority weight
 */
export default connect(
  (state, { candidacyIds }) => ({
    candidacyIds:
      candidacyIds &&
      candidacyIds.sortBy(
        id => [
          getCandidacyPriorityWeight(state, id),
          getCandidacyRank(state, id),
        ],
        sortUtils.arrayComparator,
      ),
  }),
  {},
);
