import invalidateActivityList from 'modules/activities/actions/invalidateActivityList';
import { PARENT_CANDIDACY } from 'modules/activities/constants';
import invalidateEntity from 'modules/entities/actions/invalidateEntity';
import { candidacyStatusSchema } from '../schemas';

/**
 * Invalidates a candidacy's status
 * @param {Object} payload
 * @param {Number} payload.id The candidacy ID
 */
export default ({ id }) => dispatch => {
  dispatch(
    invalidateEntity({
      entityType: candidacyStatusSchema.key,
      id: id,
    }),
  );

  // Invalidate activities if we're invaliding the underlying candidacy status.
  dispatch(
    invalidateActivityList({
      parentId: id,
      parentType: PARENT_CANDIDACY,
    }),
  );
};
