import getCsrfToken from 'modules/api/getCsrfToken';

/**
 * Generates an error message from an error response and body.
 * @param {Object} body The response body
 * @param {Object} response The response object
 * @return {String} An error message
 */
function createErrorMessage(body, response) {
  if (body && body.error) {
    return body.error;
  }
  if (body && body.errors && Array.isArray(body.errors)) {
    return body.errors.join('; ');
  }
  if (body && body.errors && Object.keys(body.errors).length) {
    const errors = [];
    Object.keys(body.errors).forEach(fieldName =>
      body.errors[fieldName].forEach(errorMessage => {
        errors.push(`${fieldName} ${errorMessage}`);
      }),
    );
    return errors.join('; ');
  }
  if (response.status === 422) {
    return 'A field value was not valid';
  }

  return response.statusText;
}

/**
 * Make an AJAX request using `fetch`.
 * This automatically:
 *   1. set the `credentials` option to 'same-origin' so cookies are included.
 *   2. include a 'X-CSRF-Token' header in non-GET requests.
 *   3. Adds Accept and Content-Type headers for 'application/json' mime type.
 * @param  {String} url The URL of the request.
 * @param  {Object} [opts={}] Additional options to pass to the fetch call.
 * @return {Promise} A Promise that resolves with the response of the fetch
 *   call or rejects if an error occurs.
 */
export function ajax(url, opts = {}) {
  const fetchOpts = {
    // Send cookies when URL is the same origin.
    credentials: 'same-origin',
    ...opts,
  };

  fetchOpts.headers = fetchOpts.headers || {};

  // Include the CSRF token if we're not making a GET request.
  if (fetchOpts.method && fetchOpts.method !== 'GET') {
    fetchOpts.headers['X-CSRF-Token'] = getCsrfToken();
  }

  return fetch(url, fetchOpts).then(response => {
    if (response.ok) {
      if (response.status === 204 || response.headers['Content-Length'] === 0) {
        // No content, so can't parse json, just return null
        return Promise.resolve(null);
      }

      return response.json();
    }

    if (response.status === 401 && window.location) {
      // TODO: Consider moving this functionality elsewhere. I don't think it really belongs here.
      // This just does a page reload, which should cause the server to redirect to the login page
      // because the user has been logged out. The reload will also cuase the server to store
      // the correct page to redirect the user BACK to after they have successfully logged in
      // again.
      window.location.reload();
    }

    // We may have gotten a JSON response with some
    // error information, so still try to parse it so we can decorate
    // our error with some additional information.
    return (
      response
        .json()
        // Ignore parsing errors
        .catch(() => null)
        .then(body => {
          const error = new Error(createErrorMessage(body, response));
          error.statusText = response.statusText;
          error.status = response.status;
          error.response = response;
          error.body = body;
          throw error;
        })
    );
  });
}

export default function ajaxWithJsonDefault(url, opts = {}) {
  return ajax(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(opts.headers || {}),
    },
    ...(opts || {}),
  });
}
