import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, withHandlers, setDisplayName, setPropTypes } from 'recompose';
import ErrorAlert from 'modules/core/componentsLegacy/ErrorAlert';
import LoadingIndicator from 'modules/core/componentsLegacy/LoadingIndicator';
import withFormToggleButton from '../../../components/forms/withFormToggleButton';
import { PARENT_TYPES } from '../constants';
import CommentForm from './CommentForm';
import CommentList from './CommentList';
import AddCommentButtonLabel from './AddCommentButtonLabel';
import withCommentListFetched from './withCommentListFetched';
import mapParentToCommentList from './mapParentToCommentList';
import connectCommentActions from './connectCommentActions';

const AddCommentFormToggleButton = withFormToggleButton(CommentForm);

/**
 * A panel that displays a collection of comments, allowing for editing, deleting, replying, and
 * creating new comments.
 */
const CommentsPanel = ({
  addCommentButtonClassName,
  addCommentButtonLabel,
  className,
  commentList,
  handleRefresh,
  parentId,
  parentType,
  popoverPlacement,
  ...props
}) => {
  const isFetching = commentList && commentList.getIn(['_meta', 'isFetching']);
  const hasError =
    commentList && Boolean(commentList.getIn(['_meta', 'error']));

  return (
    <div className={classnames('CommentsPanel', className)}>
      <AddCommentFormToggleButton
        btnClassName={classnames(
          'CommentsPanel__add-comment',
          addCommentButtonClassName,
        )}
        errorDisplay='tooltip'
        parentId={parentId}
        parentType={parentType}
        title='Add comment'
      >
        {addCommentButtonLabel}
      </AddCommentFormToggleButton>
      <div className='CommentsPanel__comments-container'>
        <div className='CommentsPanel__scroll-container'>
          {commentList && commentList.get('ids') && (
            <CommentList
              commentIds={commentList.get('ids')}
              parentId={parentId}
              parentType={parentType}
              popoverPlacement={popoverPlacement}
              {...props}
            />
          )}
          {hasError && !isFetching && (
            <ErrorAlert
              onRetry={handleRefresh}
              title='There was an error fetching comments'
            />
          )}
          {isFetching && <LoadingIndicator />}
        </div>
      </div>
    </div>
  );
};

export const propTypes = {
  /*
  Optional class to apply to the 'Add Comment' button
  */
  addCommentButtonClassName: PropTypes.string,

  addCommentButtonLabel: PropTypes.node,

  className: PropTypes.string,

  commentList: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      error: PropTypes.any,
      isFetching: PropTypes.bool,
    }),
    ids: ImmutablePropTypes.listOf(PropTypes.number),
  }),

  handleRefresh: PropTypes.func.isRequired,

  parentId: PropTypes.number.isRequired,
  parentType: PropTypes.oneOf(PARENT_TYPES),

  /**
   * Optional string to set orientation of delete confirm popovers (if none is
   * passed it defaults to top)
   */
  popoverPlacement: PropTypes.string,
};

export const defaultProps = {
  addCommentButtonClassName: 'btn-link',
  addCommentButtonLabel: <AddCommentButtonLabel />,
};

CommentsPanel.propTypes = propTypes;
CommentsPanel.defaultProps = defaultProps;

export default compose(
  setDisplayName('CommentsPanel(enhanced)'),
  setPropTypes({
    parentId: PropTypes.number.isRequired,
    parentType: PropTypes.string.isRequired,
  }),
  connectCommentActions,
  withHandlers({
    handleRefresh: ({ commentActions, parentId, parentType }) => () =>
      commentActions.fetchCommentList({
        parentId: parentId,
        parentType: parentType,
      }),
  }),
  mapParentToCommentList,
  withCommentListFetched,
)(CommentsPanel);
