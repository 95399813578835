import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import fundingRoundListsByCompanyId from './fundingRoundListsByCompanyId';

const companiesFundingRoundsReducer = combineImmutableReducers({
  fundingRoundListsByCompanyId: fundingRoundListsByCompanyId,
});

companiesFundingRoundsReducer.NAME = NAME;

export default companiesFundingRoundsReducer;
