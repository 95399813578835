import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import formStatePropType from '../propTypes/formStatePropType';

/**
 * Renders a `<form>` element based on a formState.
 */
const Form = ({ children, className, formState, ...props }) => (
  <form
    className={classnames('Form', 'form', className, {
      'is-submitting': formState.isSubmitting(),
      'has-errors': formState.hasFieldErrors(),
    })}
    {...props}
  >
    {children}
  </form>
);

Form.propTypes = {
  /**
   * The contents of the form (usually a Field component)
   */
  children: PropTypes.node,

  /**
   * Additional classname to apply to the form element.
   */
  className: PropTypes.string,

  /**
   * The current formState of the form.
   */
  formState: formStatePropType.isRequired,
};

export default Form;
