import { connect } from 'react-redux';
import * as sortUtils from 'modules/core/sortUtils';
import getCandidacyRank from '../selectors/getCandidacyRank';
import getCandidacyProperty from '../selectors/getCandidacyProperty';

/**
 * A Higher order component that sorts a list of candidacyIds in order of date added to search.
 */
export default connect(
  (state, { candidacyIds }) => ({
    candidacyIds:
      candidacyIds &&
      candidacyIds
        .sortBy(
          id => [
            getCandidacyProperty(state, id, 'created_at'),
            getCandidacyRank(state, id),
          ],
          sortUtils.arrayComparator,
        )
        .reverse(),
  }),
  {},
);
