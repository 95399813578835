import PropTypes from 'prop-types';
import React from 'react';
import {
  branch,
  compose,
  setDisplayName,
  setPropTypes,
  setStatic,
} from 'recompose';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import asField from './asField';
import FieldState from '../FieldState';
import withFormGroup from './withFormGroup';

export function CheckboxField({ id, text, value, ...otherProps }) {
  return (
    <div className='CheckboxField checkbox'>
      <label className='CheckboField__label' htmlFor={id}>
        <input
          {...otherProps}
          checked={value}
          className='inline-form-control'
          id={id}
          type='checkbox'
        />
        <span className='CheckboxField__labelText'>{text}</span>
      </label>
    </div>
  );
}

CheckboxField.propTypes = {
  /**
   * A unique ID identifying the input field.
   */
  id: PropTypes.string.isRequired,

  /**
   * The text to display as the checkbox's label.
   */
  text: PropTypes.string,

  /**
   * True if the checkbox should be checked; Otherwise, false.
   */
  value: PropTypes.bool,
};

/**
 * The CheckboxField wrapped in a FormGroup and handling the FieldState conversions.
 *
 * IMPORTANT!: Note the difference between the `text` and `label` props!
 * The `text` prop controls what is displayed directly next to the checkbox itself,
 * whereas the `label` prop is what is displayed as the label for the _FormGroup_!
 * The `label` is not clickable, and would be displayed ABOVE the checkbox. Typically
 * you want to set `text`. But `label is useful in some cases where we want to show a
 * header, for example we do this:
 *
 *   Willing to Relocate?
 *   [ ] Yes
 *
 * Where "Willing to Relocate" is the `label` prop value and "Yes" is the `text` prop value.
 */
export default compose(
  setDisplayName('CheckboxField(enhanced)'),
  setPropTypes({
    /**
     * The underlying fieldState that supplies the field's value, validations, etc.
     */
    fieldState: PropTypes.instanceOf(FieldState).isRequired,

    /**
     * The labe to display above the entire checkbox component.
     * Note the diference between this prop and the `text` prop! See component docs
     * for more details.
     */
    label: PropTypes.string,

    /**
     * The text to display next to the checkbox.
     * Note the diference between this prop and the `label` prop! See component docs
     * for more details.
     */
    text: PropTypes.string,
  }),
  setStatic('createFieldState', FieldState.create),
  withFormGroup,
  asField({
    handleValueChange: event => event.currentTarget.checked,
    convertInputValue: value => Boolean(value),
  }),
  branch(({ id }) => !id, withComponentId('CheckboxField', 'id')),
)(CheckboxField);
