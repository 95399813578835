import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, setStatic } from 'recompose';

import asField from 'modules/forms/components/asField';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';

import CompanySelect from './CompanySelect';

/**
 * A FieldState-wrapped version of the CompanySelect. The underlying fieldState value
 * should contain only the company ID of the selected company.
 */
export default compose(
  setDisplayName('CompanySelectField'),
  setPropTypes({
    fieldState: PropTypes.instanceOf(FieldState).isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  setStatic('createFieldState', FieldState.create),
  withFormGroup,
  asField(),
)(CompanySelect);
