import { connect } from 'react-redux';
import getSearchAttributes from 'modules/assessments/selectors/getSearchAttributes';

/**
 * A higher order component which takes a `searchId` prop and provides
 * a `searchAttributes` prop -- the search attributes that are currently
 * assigned to the search with that ID.
 */
export default connect(
  (state, { searchId }) => ({
    searchAttributes: searchId && getSearchAttributes(state, searchId),
  }),
  {},
);
