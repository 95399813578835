import { ACTIVITIES_INVALIDATE } from './actionTypes';

/**
 * Invalidates the list of activities for a particular parent.
 * @param {Object} options
 * @param {String} options.parentType The parent type (PARENT_CANDIDACY, PARENT_SEARCH)
 * @param {Number} options.parentId The ID for the parent, dependent on parentType
 */
export default payload => ({
  type: ACTIVITIES_INVALIDATE,
  payload: payload,
});
