import getStageListForStageType from 'modules/search-stages/selectors/getStageListForStageType';
import getStage from 'modules/search-stages/selectors/getStage';
import getRejectionReasons from 'modules/search-stages/selectors/getRejectionReasons';
import getCandidacyList from 'modules/candidacies/selectors/getCandidacyList';
import { PARENT_SEARCH } from 'modules/candidacies/constants';
import getCandidacy from 'modules/candidacies/selectors/getCandidacy';
import getCandidacyContact from 'modules/candidacies/selectors/getCandidacyContact';
import getCandidacyStage from 'modules/candidacies/selectors/getCandidacyStage';
import getStageType from 'modules/searches/selectors/getStageType';
import getPosition from 'modules/positions/selectors/getPosition';

const selectDataByCandidacyId = (state, candidacyId) => ({
  candidacy: getCandidacy(state, candidacyId)?.toJS(),
  contact: getCandidacyContact(state, candidacyId)?.toJS(),
  stage: getCandidacyStage(state, candidacyId)?.toJS(),
  position: getPosition(
    state,
    getCandidacyContact(state, candidacyId)?.get('primary_position'),
  )?.toJS(),
});

const selectSearchCandidaciesData = (state, searchId) =>
  Object.fromEntries(
    getCandidacyList(state, PARENT_SEARCH, searchId)
      ?.get('ids')
      ?.toArray()
      ?.map(candidacyId => [
        candidacyId,
        selectDataByCandidacyId(state, candidacyId),
      ]) || [],
  );

const selectDataBySearchId = (state, searchId) => ({
  data: selectSearchCandidaciesData(state, searchId),
  stages: getStageListForStageType(state, getStageType(state, searchId))
    ?.get('ids')
    ?.map(stageId => getStage(state, stageId))
    ?.toJS(),
});

const selectRejectionReasons = state => getRejectionReasons(state)?.toArray();

export {
  selectDataByCandidacyId,
  selectDataBySearchId,
  selectRejectionReasons,
};
