import { compose } from 'recompose';
import { POST } from 'modules/api/methods';
import fetchAction from 'modules/api/fetchAction';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import defaultMethod from 'modules/api/actionModifiers/defaultMethod';
import withResponseSchema from 'modules/api/actionModifiers/withResponseSchema';
import {
  CREATE_START,
  CREATE_SUCCESS,
  CREATE_FAILURE,
} from './entityActionTypes';

/**
 * An action creator for fetching and dispatching entity CREATE_* actions.
 * @param {Object} options
 * @param {Object|String|FormData} options.body The body payload to send with the request
 * @param {String} [options.method="POST"] The request method to use
 * @param {String} options.url The URL of the request
 * @param {Schema} options.responseSchema The normalizr schema that describes the server's response.
 */
export default compose(
  fetchAction,
  withResponseSchema,
  createAsyncActions({
    startActionType: CREATE_START,
    successActionType: CREATE_SUCCESS,
    failureActionType: CREATE_FAILURE,
  }),
  defaultMethod(POST),
);
