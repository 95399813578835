import getUserPermission from 'modules/auth/selectors/getUserPermission';

/**
 * Gets a value indicating whether the current user has permission to view desired compensation.
 * @param {Object} state
 * @return {Boolean} True if the current user has permission to view desired compenstion;
 * Otherwise, false.
 */
const canViewDesiredCompensation = state =>
  getUserPermission(state, 'can_view_desired_compensation', false);

export default canViewDesiredCompensation;
