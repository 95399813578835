import { connect } from 'react-redux';
import getCompensation from '../selectors/getCompensation';

/**
 * A higher order component which maps a `compensationId` prop value to it's compensation record.
 */
export default connect(
  (state, { compensationId }) => ({
    compensation: getCompensation(state, compensationId),
  }),
  {},
);
