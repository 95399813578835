import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  compose,
  branch,
  defaultProps,
  setPropTypes,
  withProps,
} from 'recompose';
import withCandidacyIdsSortedByAge from './withCandidacyIdsSortedByAge';
import withCandidacyIdsSortedByCompanyName from './withCandidacyIdsSortedByCompanyName';
import withCandidacyIdsSortedByLastContacted from './withCandidacyIdsSortedByLastContacted';
import withCandidacyIdsSortedByLocation from './withCandidacyIdsSortedByLocation';
import withCandidacyIdsSortedByName from './withCandidacyIdsSortedByName';
import withCandidacyIdsSortedByPriority from './withCandidacyIdsSortedByPriority';
import withCandidacyIdsSortedByRank from './withCandidacyIdsSortedByRank';
import withCandidacyIdsSortedBySearchLead from './withCandidacyIdsSortedBySearchLead';
import withCandidacyIdsSortedByStage from './withCandidacyIdsSortedByStage';
import {
  SORT_BY_AGE,
  SORT_BY_COMPANY_NAME,
  SORT_BY_LAST_CONTACTED,
  SORT_BY_LEAD,
  SORT_BY_LOCATION,
  SORT_BY_NAME,
  SORT_BY_PRIORITY,
  SORT_BY_RANK,
  SORT_BY_STAGE,
  SORT_ASCENDING_DEFAULTS,
} from '../constants';

/**
 * A higher order component that provides sorting of a list of candidacy IDs provided by an
 * `candidacyIds` prop, based on the prop values of `sortBy` and `sortAscending`.
 */
export default compose(
  setPropTypes({
    /**
     * The sort type
     */
    sortBy: PropTypes.oneOf([
      SORT_BY_AGE,
      SORT_BY_COMPANY_NAME,
      SORT_BY_LAST_CONTACTED,
      SORT_BY_LEAD,
      SORT_BY_LOCATION,
      SORT_BY_NAME,
      SORT_BY_PRIORITY,
      SORT_BY_RANK,
      SORT_BY_STAGE,
    ]).isRequired,

    /**
     * True to sort descending, false to sort ascending.
     */
    sortAscending: PropTypes.bool.isRequired,

    /**
     * The candidacy IDs to sort.
     */
    candidacyIds: ImmutablePropTypes.listOf(PropTypes.number),
  }),
  defaultProps({
    sortBy: SORT_BY_PRIORITY,
    sortAscending: SORT_ASCENDING_DEFAULTS[SORT_BY_PRIORITY],
  }),
  branch(({ sortBy }) => sortBy === SORT_BY_AGE, withCandidacyIdsSortedByAge),
  branch(
    ({ sortBy }) => sortBy === SORT_BY_COMPANY_NAME,
    withCandidacyIdsSortedByCompanyName,
  ),
  branch(
    ({ sortBy }) => sortBy === SORT_BY_LAST_CONTACTED,
    withCandidacyIdsSortedByLastContacted,
  ),
  branch(
    ({ sortBy }) => sortBy === SORT_BY_LEAD,
    withCandidacyIdsSortedBySearchLead,
  ),
  branch(
    ({ sortBy }) => sortBy === SORT_BY_LOCATION,
    withCandidacyIdsSortedByLocation,
  ),
  branch(({ sortBy }) => sortBy === SORT_BY_NAME, withCandidacyIdsSortedByName),
  branch(
    ({ sortBy }) => sortBy === SORT_BY_PRIORITY,
    withCandidacyIdsSortedByPriority,
  ),
  branch(({ sortBy }) => sortBy === SORT_BY_RANK, withCandidacyIdsSortedByRank),
  branch(
    ({ sortBy }) => sortBy === SORT_BY_STAGE,
    withCandidacyIdsSortedByStage,
  ),
  withProps(({ candidacyIds, sortAscending }) => ({
    candidacyIds:
      candidacyIds && sortAscending ? candidacyIds.reverse() : candidacyIds,
  })),
);
