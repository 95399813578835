import selectn from 'selectn';
import { Map } from 'immutable';
import createListReducer from '../../../reducers/createListReducer';
import {
  INTERVIEW_LIST_INVALIDATE,
  INTERVIEW_LIST_FETCH_START,
  INTERVIEW_LIST_FETCH_SUCCESS,
  INTERVIEW_LIST_FETCH_FAILURE,
  INTERVIEW_CREATE_SUCCESS,
  INTERVIEW_UPDATE_SUCCESS,
  INTERVIEW_DELETE_SUCCESS,
} from '../actions/actionTypes';
import { QUESTION_ANSWER_LIST_UPDATE_SUCCESS } from '../../question-answers/actions/actionTypes';
import { PARENT_CANDIDACY, PARENT_SEARCH, PARENT_CONTACT } from '../constants';

const interviewListReducer = createListReducer({
  resultKey: 'interviews',
  entityResultKey: 'interview',
  request: INTERVIEW_LIST_FETCH_START,
  success: INTERVIEW_LIST_FETCH_SUCCESS,
  failure: INTERVIEW_LIST_FETCH_FAILURE,
  invalidated: INTERVIEW_LIST_INVALIDATE,
  created: INTERVIEW_CREATE_SUCCESS,
  updated: INTERVIEW_UPDATE_SUCCESS,
  deleted: INTERVIEW_DELETE_SUCCESS,
});

// Interviews are keyed on the parent type (PARENT_CANDIDACY or PARENT_SEARCH),
// then by that parent's ID (a search ID for PARENT_SEARCH or a candidacy ID for
// PARENT_CANDIDACY).
export default (state = new Map(), action) => {
  switch (action.type) {
    case INTERVIEW_LIST_FETCH_START:
    case INTERVIEW_LIST_FETCH_SUCCESS:
    case INTERVIEW_LIST_FETCH_FAILURE: {
      const parentType = selectn('payload.parentType', action);
      const parentId = selectn('payload.parentId', action);
      const filter = selectn('payload.filter', action);

      if (parentType && parentId && !filter) {
        return state.updateIn([parentType, parentId], list =>
          interviewListReducer(list, action),
        );
      }

      return state;
    }
    case INTERVIEW_LIST_INVALIDATE: {
      const searchId = selectn('payload.searchId', action);
      const candidacyId = selectn('payload.candidacyId', action);
      const contactId = selectn('payload.contactId', action);
      const filter = selectn('payload.filter', action);

      if (filter) {
        return state;
      }

      let result = state;

      if (searchId && state.hasIn([PARENT_SEARCH, searchId])) {
        result = result.setIn(
          [PARENT_SEARCH, searchId, '_meta', 'isInvalidated'],
          true,
        );
      }

      if (candidacyId && state.hasIn([PARENT_CANDIDACY, candidacyId])) {
        result = result.setIn(
          [PARENT_CANDIDACY, candidacyId, '_meta', 'isInvalidated'],
          true,
        );
      }

      if (contactId && state.hasIn([PARENT_CONTACT, contactId])) {
        result = result.setIn(
          [PARENT_CONTACT, contactId, '_meta', 'isInvalidated'],
          true,
        );
      }

      return result;
    }
    case INTERVIEW_CREATE_SUCCESS: {
      // Check if the newly created interview belongs to any currently fetched lists.
      const interviewId = selectn('payload.result.interview', action);
      const interview = selectn(
        `payload.entities.interviews.${interviewId}`,
        action,
      );

      if (interview) {
        return state.withMutations(mutable => {
          if (mutable.hasIn([PARENT_SEARCH, interview.search_id, 'ids'])) {
            mutable.updateIn([PARENT_SEARCH, interview.search_id, 'ids'], ids =>
              ids.push(interviewId),
            );
          }

          if (
            mutable.hasIn([PARENT_CANDIDACY, interview.candidacy_id, 'ids'])
          ) {
            mutable.updateIn(
              [PARENT_CANDIDACY, interview.candidacy_id, 'ids'],
              ids => ids.push(interviewId),
            );
          }

          if (mutable.hasIn([PARENT_CONTACT, interview.contact_id, 'ids'])) {
            mutable.updateIn(
              [PARENT_CONTACT, interview.contact_id, 'ids'],
              ids => ids.push(interviewId),
            );
          }
        });
      }

      return state;
    }
    // Currently, two reducers listen for this action: assessmentLists and interviewsByParentType.
    // The entire candidacy gets invalidated anytime either answer list is updated.
    // It isn't a problem currently, but worth noting in case someone runs into this down the road
    case QUESTION_ANSWER_LIST_UPDATE_SUCCESS: {
      const candidacyId = selectn('payload.response.candidacy_id', action);

      return state.setIn(
        [PARENT_CANDIDACY, candidacyId, '_meta', 'isInvalidated'],
        true,
      );
    }
    default: {
      return state.map(parentTypeState =>
        parentTypeState.map(listState =>
          interviewListReducer(listState, action),
        ),
      );
    }
  }
};
