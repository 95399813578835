import { NAME } from '../../constants';

export const QUERY_CREATE = `${NAME}/QUERY_CREATE`;
export const QUERY_DESTROY = `${NAME}/QUERY_DESTROY`;
export const QUERY_INVALIDATE = `${NAME}/QUERY_INVALIDATE`;
export const QUERY_CLEAR = `${NAME}/QUERY_CLEAR`;

export const QUERY_FETCH_START = `${NAME}/QUERY_FETCH_START`;
export const QUERY_FETCH_SUCCESS = `${NAME}/QUERY_FETCH_SUCCESS`;
export const QUERY_FETCH_FAILURE = `${NAME}/QUERY_FETCH_FAILURE`;

export const CONTACT_INVALIDATE = `${NAME}/CONTACT_INVALIDATE`;

export const CONTACT_FETCH_START = `${NAME}/CONTACT_FETCH_START`;
export const CONTACT_FETCH_SUCCESS = `${NAME}/CONTACT_FETCH_SUCCESS`;
export const CONTACT_FETCH_FAILURE = `${NAME}/CONTACT_FETCH_FAILURE`;

export const CONTACTS_FETCH_START = `${NAME}/CONTACTS_FETCH_START`;
export const CONTACTS_FETCH_SUCCESS = `${NAME}/CONTACTS_FETCH_SUCCESS`;
export const CONTACTS_FETCH_FAILURE = `${NAME}/CONTACTS_FETCH_FAILURE`;
export const CONTACTS_LIST_INVALIDATE = `${NAME}/CONTACTS_LIST_INVALIDATE`;

export const CONTACT_CREATE_START = `${NAME}/CONTACT_CREATE_START`;
export const CONTACT_CREATE_SUCCESS = `${NAME}/CONTACT_CREATE_SUCCESS`;
export const CONTACT_CREATE_FAILURE = `${NAME}/CONTACT_CREATE_FAILURE`;

export const CONTACT_UPDATE_START = `${NAME}/CONTACT_UPDATE_START`;
export const CONTACT_UPDATE_SUCCESS = `${NAME}/CONTACT_UPDATE_SUCCESS`;
export const CONTACT_UPDATE_FAILURE = `${NAME}/CONTACT_UPDATE_FAILURE`;

export const CONTACT_INITIALIZE = `${NAME}/CONTACT_INITIALIZE`;

export const CONTACT_UPDATE_PRIMARY_POSITION = `${NAME}/CONTACT_UPDATE_PRIMARY_POSITION`;

export const CONTACT_REPORT_CREATE_START = `${NAME}/CONTACT_REPORT_CREATE_START`;
export const CONTACT_REPORT_CREATE_SUCCESS = `${NAME}/CONTACT_REPORT_CREATE_SUCCESS`;
export const CONTACT_REPORT_CREATE_FAILURE = `${NAME}/CONTACT_REPORT_CREATE_FAILURE`;
