import { USER_STATE_KEY } from '../constants';

/**
 * Gets a user permission value
 *
 * Note `getUserPermission` returns legacy-style flags that are set on the actual user.
 * For flipper feature flags use `hasFeature` instead.
 *
 * @deprecated Use `hasFeature` instead.
 * @param {Object} state
 * @param {String} key The permission key (currently stored on the `user` state)
 * @param {*} defaultValue The value to return if the permission is not set or not found.
 * @returns {undefined|*} The permission value, or `defaultValue` if not found or set (which is
 *   `undefined` unless otherwise specified)`
 */
export default (state, key, defaultValue) =>
  state[USER_STATE_KEY].get(key, defaultValue);
