//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'comments';

//---------------------------------------------------------
// Parent types
//---------------------------------------------------------

export const PARENT_CANDIDACY = 'CandidacyComment';
export const PARENT_CONTACT = 'ContactComment';
export const PARENT_COMPANY = 'CompanyComment';

export const PARENT_TYPES = [PARENT_CANDIDACY, PARENT_CONTACT, PARENT_COMPANY];

//---------------------------------------------------------
// Vote types
//---------------------------------------------------------

export const VOTE_TYPE_PRIORITY = 'PRIORITY';
export const VOTE_TYPE_NOT_A_FIT = 'NOT A FIT';
