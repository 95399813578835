import { stringifyQueryObject } from 'modules/core/urlUtils';
import createAjaxAction from '../../../../actions/createAjaxAction';
import {
  SEARCH_STAGES_FETCH_START,
  SEARCH_STAGES_FETCH_SUCCESS,
  SEARCH_STAGES_FETCH_FAILURE,
  SEARCH_STAGES_INVALIDATE,
} from './actionTypes';
import shouldFetchStageList from '../../selectors/shouldFetchStageList';
import candidacyStageSchema from '../../schema';

const getUrl = ({ stageType }) =>
  `/api/stages?${stringifyQueryObject({ type: stageType })}`;

export const fetchStageList = createAjaxAction({
  getUrl: getUrl,
  schema: [candidacyStageSchema],
  start: SEARCH_STAGES_FETCH_START,
  success: SEARCH_STAGES_FETCH_SUCCESS,
  failure: SEARCH_STAGES_FETCH_FAILURE,
});

export const fetchStageListIfNeeded = params => (dispatch, getState) => {
  const { stageType } = params;

  if (!stageType) {
    throw new Error('fetchStageListIfNeeded requires a `stageType` value');
  }

  if (shouldFetchStageList(getState(), stageType)) {
    dispatch(fetchStageList(params));
  }
};

export const invalidateStageList = payload => ({
  type: SEARCH_STAGES_INVALIDATE,
  payload: payload,
});
