import { useCallback, useState, useEffect, useRef } from 'react';
import Api, { getErrorMessage } from '../Api';

const useApi = (url, method) => {
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  /**
   * Adding mutable variable via `useRef` to track whether the component using this hook is mounted or not to
   * prevent the following React error in Jest tests based on a solution found here:
   * https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
   *
   * Warning: Can’t perform a React state update on an unmounted component. This is a no-op, but it indicates
   * a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect
   * cleanup function.
   *
   * It appears the asynchronous `.then().catch()` chain with calls to `useState` setter functions is erroring
   * out in some Jest tests and adding this conditional ensures calls to those setters only occurs when the
   * component is mounted.
   *
   * Additional linting hints indicated the solution of `let isMounted = true` will not work here and instead
   * a `useRef` variable should be used to work with the `useEffect` cleanup at the bottom of this hook
   */
  const isMounted = useRef(true);

  const makeRequest = useCallback(
    async (payload = {}, options = {}) => {
      const { onError, onSuccess, shouldAbortWhenQueryParamsChange } = options;
      if (isMounted.current) {
        setIsLoading(true);
      }

      try {
        /**
         * Using `let` and the `if-else` statement for apiResponse is a hack to prevent breaking existing tests.
         * Some tests use jest's expect().toHaveBeenLastCalledWith(arg1, arg2, ...) which will break when an additional argument is provided.
         * The below only adds a third argument when the shouldAbortWhenQueryParamsChange is expressly provided.
         *  */
        let apiResponse;
        if (shouldAbortWhenQueryParamsChange) {
          apiResponse = await Api[method](url, payload, {
            shouldAbortWhenQueryParamsChange: shouldAbortWhenQueryParamsChange,
          });
        } else {
          apiResponse = await Api[method](url, payload);
        }

        if (isMounted.current) {
          setErrorMessage(null);
          setIsLoading(false);
          setResponse(apiResponse);
          if (onSuccess) {
            onSuccess(apiResponse);
          }
        }
      } catch (requestError) {
        const message = getErrorMessage(requestError);
        if (isMounted.current) {
          setIsLoading(false);
          setErrorMessage(message);
          if (onError) {
            onError(message);
          }
        }
      }
    },
    [url, method, isMounted],
  );

  /**
   * Ensure the value of `isMounted.current` is set to false to prevent asynch state updates on
   * an unmounted component
   */
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return [makeRequest, isLoading, errorMessage, response];
};

const useApiGet = url => useApi(url, 'get');
const useApiPost = url => useApi(url, 'post');
const useApiPut = url => useApi(url, 'put');
const useApiPatch = url => useApi(url, 'patch');
const useApiDelete = url => useApi(url, 'delete');

export { useApiGet, useApiPatch, useApiPost, useApiPut, useApiDelete };
