import { connect } from 'react-redux';
import * as sortUtils from 'modules/core/sortUtils';
import getCandidacyRank from '../selectors/getCandidacyRank';
import getCandidacySortName from '../selectors/getCandidacySortName';

/**
 * A Higher order component that sorts a list of candidacyIds in order of rank
 */
export default connect(
  (state, { candidacyIds }) => ({
    candidacyIds:
      candidacyIds &&
      candidacyIds.sortBy(
        id => [getCandidacyRank(state, id), getCandidacySortName(state, id)],
        sortUtils.arrayComparator,
      ),
  }),
  {},
);
