import { connect } from 'react-redux';
import getQuery from '../selectors/getQuery';

/**
 * A higher order component which maps a queryId prop (which default to `queryId` to
 * it's query data.
 * @param {String} [queryIdPropName='queryId'] The name of the incoming prop that contains the
 *   ID of the query.
 */
export default (queryIdPropName = 'queryId') =>
  connect((state, props) => ({
    companyQuery: getQuery(state, props[queryIdPropName]),
  }));
