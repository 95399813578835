const isNumber = fieldState => {
  const value = fieldState.getRawValue();

  if (
    value !== null &&
    typeof value !== 'undefined' &&
    (typeof value !== 'number' || Number.isNaN(value))
  ) {
    return 'Value must be a number.';
  }

  return null;
};

export default isNumber;
