import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { fetchTargetCompanyList as fetchTargetCompanyListActionCreator } from '../actions';
import getTargetCompanList from '../selectors/targetCompanies/getTargetCompanyList';
import shouldFetchTargetCompanyListSelector from '../selectors/targetCompanies/shouldFetchTargetCompanyList';

/**
 * When provided a searchId, fetches the target companies for that search if they need to be
 * fetched so they are available for a component.
 */
export default compose(
  setDisplayName('withTargetCompanyListFetched'),
  setPropTypes({
    searchId: PropTypes.number,
  }),
  connect(
    (state, { searchId }) => ({
      targetCompanyList: getTargetCompanList(state, searchId),
      shouldFetchTargetCompanyList: shouldFetchTargetCompanyListSelector(
        state,
        searchId,
      ),
    }),
    dispatch => ({
      fetchTargetCompanyList: bindActionCreators(
        fetchTargetCompanyListActionCreator,
        dispatch,
      ),
    }),
  ),
  withHandlers({
    /**
     * A handler for fetching the target company list specified by the `searchId` prop.
     * This allows the component to simply call `fetchTargetCompanyList()` without having to
     * supply params.
     */
    fetchTargetCompanyList: ({ fetchTargetCompanyList, searchId }) => () =>
      fetchTargetCompanyList({ searchId: searchId }),

    /**
     * A handler that, when called, only fetches the target company list if needed.
     */
    fetchTargetCompanyListIfNeeded: ({
      fetchTargetCompanyList,
      searchId,
      shouldFetchTargetCompanyList,
    }) => () =>
      shouldFetchTargetCompanyList &&
      fetchTargetCompanyList({ searchId: searchId }),
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchTargetCompanyListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchTargetCompanyListIfNeeded();
    },
  }),
);
