import getCandidacy from './getCandidacy';

/**
 * Determines if a candidacy records needs to be fetched from the server.
 * @param {Object} state
 * @param {Number} candidacyId The ID of the candidacy
 * @return {Boolean} True if the candidacy record needs to be fetched from the server;
 *   Otherwise, false.
 */
const shouldFetchCandidacy = (state, candidacyId) => {
  if (!candidacyId) {
    return false;
  }

  const candidacy = getCandidacy(state, candidacyId);

  if (!candidacy) {
    return true;
  }

  if (
    candidacy.getIn(['_meta', 'isFetching']) ||
    candidacy.hasIn(['_meta', 'error'])
  ) {
    return false;
  }

  // If there is no `contact` property, then we need to fetch the complete candidacy record.
  return (
    !candidacy.get('contact') ||
    Boolean(candidacy.getIn(['_meta', 'isInvalidated']))
  );
};

export default shouldFetchCandidacy;
