import selectn from 'selectn';
import { Map } from 'immutable';
import createListReducer from '../../../reducers/createListReducer';
import {
  ACTIVITIES_INVALIDATE,
  ACTIVITIES_FETCH_START,
  ACTIVITIES_FETCH_SUCCESS,
  ACTIVITIES_FETCH_FAILURE,
} from '../actions/actionTypes';

const activityListReducer = createListReducer({
  request: ACTIVITIES_FETCH_START,
  success: ACTIVITIES_FETCH_SUCCESS,
  failure: ACTIVITIES_FETCH_FAILURE,
  invalidated: ACTIVITIES_INVALIDATE,
});

// Activities is keyed on the parent type (PARENT_CANDIDACY or PARENT_SEARCH),
// then by that parent's ID (a search ID for PARENT_SEARCH or a candidacy ID for
// PARENT_CANDIDACY).
export default (state = new Map(), action) => {
  switch (action.type) {
    case ACTIVITIES_FETCH_START:
    case ACTIVITIES_FETCH_SUCCESS:
    case ACTIVITIES_FETCH_FAILURE:
    case ACTIVITIES_INVALIDATE: {
      const parentType = selectn('payload.parentType', action);
      const parentId = selectn('payload.parentId', action);

      if (parentType && parentId) {
        return state.updateIn([parentType, parentId], list =>
          activityListReducer(list, action),
        );
      }

      return state;
    }
    default: {
      return state.map(parentTypeState =>
        parentTypeState.map(listState =>
          activityListReducer(listState, action),
        ),
      );
    }
  }
};
