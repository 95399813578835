import { NAME } from '../constants';

export const RECOMMENDATIONS_LIST_FETCH_START = `${NAME}/RECOMMENDATIONS_LIST_FETCH_START`;
export const RECOMMENDATIONS_LIST_FETCH_SUCCESS = `${NAME}/RECOMMENDATIONS_LIST_FETCH_SUCCESS`;
export const RECOMMENDATIONS_LIST_FETCH_FAILURE = `${NAME}/RECOMMENDATIONS_LIST_FETCH_FAILURE`;
export const RECOMMENDATIONS_LIST_INVALIDATE = `${NAME}/RECOMMENDATIONS_LIST_INVALIDATE`;
export const RECOMMENDED_FETCH_START = `${NAME}/RECOMMENDED_FETCH_START`;
export const RECOMMENDED_FETCH_SUCCESS = `${NAME}/RECOMMENDED_FETCH_SUCCESS`;
export const RECOMMENDED_FETCH_FAILURE = `${NAME}/RECOMMENDED_FETCH_FAILURE`;
export const RECOMMENDED_INVALIDATE = `${NAME}/RECOMMENDED_INVALIDATE`;
