import { NAME } from '../constants';

export const COMPANY_QUERY_CREATE = `${NAME}/QUERY_CREATE`;
export const COMPANY_QUERY_DESTROY = `${NAME}/QUERY_DESTROY`;
export const COMPANY_QUERY_INVALIDATE = `${NAME}/QUERY_INVALIDATE`;
export const COMPANY_QUERY_CLEAR = `${NAME}/QUERY_CLEAR`;
export const COMPANY_QUERY_FETCH_START = `${NAME}/QUERY_FETCH_START`;
export const COMPANY_QUERY_FETCH_SUCCESS = `${NAME}/QUERY_FETCH_SUCCESS`;
export const COMPANY_QUERY_FETCH_FAILURE = `${NAME}/QUERY_FETCH_FAILURE`;

export const COMPANY_CREATE_START = `${NAME}/COMPANY_CREATE_START`;
export const COMPANY_CREATE_SUCCESS = `${NAME}/COMPANY_CREATE_SUCCESS`;
export const COMPANY_CREATE_FAILURE = `${NAME}/COMPANY_CREATE_FAILURE`;

export const PORTFOLIO_COMPANY_LIST_INITIALIZE = `${NAME}/PORTFOLIO_COMPANY_LIST_INITIALIZE`;
