import { NAME } from '../../constants';

export const EDUCATIONS_FETCH_START = `${NAME}/EDUCATIONS_FETCH_START`;
export const EDUCATIONS_FETCH_SUCCESS = `${NAME}/EDUCATIONS_FETCH_SUCCESS`;
export const EDUCATIONS_FETCH_FAILURE = `${NAME}/EDUCATIONS_FETCH_FAILURE`;

export const EDUCATION_CREATE_START = `${NAME}/EDUCATION_CREATE_START`;
export const EDUCATION_CREATE_SUCCESS = `${NAME}/EDUCATION_CREATE_SUCCESS`;
export const EDUCATION_CREATE_FAILURE = `${NAME}/EDUCATION_CREATE_FAILURE`;

export const EDUCATION_DELETE_START = `${NAME}/EDUCATION_DELETE_START`;
export const EDUCATION_DELETE_SUCCESS = `${NAME}/EDUCATION_DELETE_SUCCESS`;
export const EDUCATION_DELETE_FAILURE = `${NAME}/EDUCATION_DELETE_FAILURE`;
