import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import fetchCandidacyAction from '../actions/fetchCandidacy';
import fetchCandidacyStatusAction from '../actions/fetchCandidacyStatus';
import shouldFetchCandidacySelector from '../selectors/shouldFetchCandidacy';
import shouldFetchCandidacyStatusSelector from '../selectors/shouldFetchCandidacyStatus';

/**
 * Fetches a candidacy record and/or status if it needs to be fetched from the server.
 * */
export default compose(
  setDisplayName('withCandidacyFetched'),
  setPropTypes({
    candidacyId: PropTypes.number.isRequired,
  }),
  connect(
    (state, { candidacyId }) => ({
      shouldFetchCandidacy: shouldFetchCandidacySelector(state, candidacyId),
      shouldFetchCandidacyStatus: shouldFetchCandidacyStatusSelector(
        state,
        candidacyId,
      ),
    }),
    {
      fetchCandidacy: fetchCandidacyAction,
      fetchCandidacyStatus: fetchCandidacyStatusAction,
    },
  ),

  withHandlers({
    fetchCandidacy: ({ candidacyId, fetchCandidacy }) => () =>
      fetchCandidacy({ id: candidacyId }),

    fetchCandidacyStatus: ({ candidacyId, fetchCandidacyStatus }) => () =>
      fetchCandidacyStatus({ id: candidacyId }),

    fetchCandidacyIfNeeded: ({
      candidacyId: id,
      fetchCandidacy,
      fetchCandidacyStatus,
      shouldFetchCandidacy,
      shouldFetchCandidacyStatus,
    }) => () => {
      if (shouldFetchCandidacy) {
        fetchCandidacy({ id: id });
      } else if (shouldFetchCandidacyStatus) {
        fetchCandidacyStatus({ id: id });
      }
    },
  }),

  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchCandidacyIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchCandidacyIfNeeded();
    },
  }),
);
