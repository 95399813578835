import combineImmutableReducers from '../combineImmutableReducers';
import queriesById from './queriesById';
import duplicatesById from './duplicatesById';
import contactsById from './contactsById';
import selectedContact from './selectedContact';

export default combineImmutableReducers({
  selectedContact: selectedContact,
  // /** @deprecated use byId instead */
  contactsById: contactsById,
  queriesById: queriesById,
  duplicatesById: duplicatesById,
});
