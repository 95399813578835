const isInteger = fieldState => {
  const value = fieldState.getRawValue();

  if (
    value !== null &&
    typeof value !== 'undefined' &&
    (!Number.isFinite(value) || Math.floor(value) !== value)
  ) {
    return 'Value must be a whole number.';
  }

  return null;
};

export default isInteger;
