import updateEntity from 'modules/entities/actions/updateEntity';
import routes from 'modules/routing/routes';
import { assessmentSchema } from '../../schema';
import transformAssessmentForServer from './transformAssessmentForServer';

/**
 * Creates an action for updating an assessment.
 * @param {Object} payload
 * @param {Object} payload.assessment The assessment to update
 * @param {Object} [payload.token] An optional token (needed for guest assessments)
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({ assessment, token, transactionId }) => dispatch =>
  dispatch(
    updateEntity({
      body: { assessment: transformAssessmentForServer(assessment) },
      transactionId: transactionId,
      entityType: assessmentSchema.key,
      responseSchema: { assessment: assessmentSchema },
      token: token,
      url: routes.api_v1_assessment({ id: assessment.id }),
    }),
  );
