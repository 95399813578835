import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import formStatePropType from '../propTypes/formStatePropType';

/**
 * Renders a button for submitting a form.
 * The button will be disabled while there is a submission in progress, or if the form is
 * in an error state.
 * During submit a spinner icon will be shown in place of the button's icon.
 */
const SubmitButton = ({
  bsSize,
  children,
  className,
  disabled,
  formState,
  icon,
  ...props
}) => {
  const isSubmitting = formState.isSubmitting();
  return (
    <button
      className={classnames('SubmitButton', className, 'btn btn-primary', {
        [`btn-${bsSize}`]: Boolean(bsSize),
      })}
      disabled={disabled || !formState.canSubmit()}
      type='submit'
      {...props}
    >
      {icon && (
        <i
          className={classnames(
            'fa',
            {
              'fa-spin': isSubmitting,
              'fa-spinner': isSubmitting,
              [icon]: icon && !isSubmitting,
            },
            'u-marginRight-4',
          )}
        />
      )}
      {children}
    </button>
  );
};

SubmitButton.defaultProps = {
  bsSize: 'sm',
  children: 'Save',
  className: null,
  disabled: false,
  icon: null,
};

SubmitButton.propTypes = {
  /**
   * An optional bootstrap size modifier to apply.
   */
  bsSize: PropTypes.oneOf(['xs', 'sm', 'lg']),

  /**
   * The label or contents of the button to render in addition to the icon.
   */
  children: PropTypes.node,

  /**
   * The className to apply to the button.
   */
  className: PropTypes.string,

  /**
   * True to disable the button even if `formState.canSubmit()` returns true..
   */
  disabled: PropTypes.bool,

  /**
   * The current formState of the form.
   */
  formState: formStatePropType.isRequired,

  /**
   * The icon to display for the button when not in a submit state.
   */
  icon: PropTypes.string,
};

export default SubmitButton;
