import selectn from 'selectn';
import pluralize from 'pluralize';
import { fromJS, Iterable, Map } from 'immutable';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import * as introductionActions from 'modules/introductions/actions';
import {
  TASK_UPDATE_START,
  TASK_UPDATE_SUCCESS,
  TASK_UPDATE_FAILURE,
  TASK_DELETE_START,
  TASK_DELETE_SUCCESS,
  TASK_DELETE_FAILURE,
} from '../../modules/tasks/actions/actionTypes';

import {
  MEETING_UPDATE_START,
  MEETING_UPDATE_SUCCESS,
  MEETING_UPDATE_FAILURE,
  MEETING_DELETE_START,
  MEETING_DELETE_SUCCESS,
  MEETING_DELETE_FAILURE,
} from '../../modules/meetings/actions/actionTypes';

const reduceResource = (
  state,
  { payload, type },
  { deletes, key, updates },
) => {
  if (!payload) {
    return state;
  }

  const id = selectn(`${key}.id`, payload) || payload.id;
  if (!id || state.getIn(['data', 'resource', 'data', 'id']) !== id) {
    return state;
  }

  switch (type) {
    case updates.start: {
      return state.setIn(['data', 'resource', 'meta', 'isUpdating'], true);
    }
    case updates.success: {
      const entity =
        selectn(`data.${key}`, payload) ||
        selectn(`entities.${pluralize(key)}.${id}`, payload);

      const updatedData = entity.notifications && entity.notifications[0];
      return state
        .setIn(['data', 'resource', 'meta', 'isUpdating'], false)
        .mergeIn(['data'], updatedData)
        .mergeIn(['data', 'resource', 'data'], entity);
    }
    case updates.failure: {
      return state.setIn(['data', 'resource', 'meta', 'isUpdating'], false);
    }
    case deletes.start: {
      return state.setIn(['data', 'resource', 'meta', 'isDeleting'], true);
    }
    case deletes.success:
    case deletes.failure: {
      return state.setIn(['data', 'resource', 'meta', 'isDeleting'], false);
    }
    default: {
      return state;
    }
  }
};

/**
 * The reducer for a single notification item.
 * @param {Immutable.Map} state The state of the notification item to reduce.
 * @param {Object} action The action to apply.
 * @return {Immutable.map} The updated state of the notification item.
 */
export default function notification(state, action) {
  if (!Iterable.isIterable(state)) {
    // This is a raw server object, so convert it before calling the reducer.
    const immutableState = fromJS(state)
      // Pull the links off of the resource object onto the notification data.
      .set('links', fromJS((state.resource && state.resource.links) || {}))
      // Make the resource have meta/data
      .update(
        'resource',
        resource =>
          new Map({
            meta: new Map(),
            data: resource,
          }),
      );

    return action ? notification(immutableState) : immutableState;
  }

  switch (state.getIn(['data', 'type'])) {
    case 'introduction': {
      return state.update(notificationState =>
        reduceResource(notificationState, action, {
          key: 'introduction',
          updates: {
            start: introductionActions.INTRODUCTION_UPDATE_START,
            success: introductionActions.INTRODUCTION_UPDATE_SUCCESS,
            failure: introductionActions.INTRODUCTION_UPDATE_FAILURE,
          },
          deletes: {
            start: introductionActions.INTRODUCTION_DELETE_START,
            success: introductionActions.INTRODUCTION_DELETE_SUCCESS,
            failure: introductionActions.INTRODUCTION_DELETE_FAILURE,
          },
        }),
      );
    }
    case 'outreach': {
      if (selectn('payload.entityType', action) !== 'outreaches') {
        return state;
      }
      return state.update(notificationState =>
        reduceResource(notificationState, action, {
          key: 'outreach',
          updates: {
            start: entityActionTypes.UPDATE_START,
            success: entityActionTypes.UPDATE_SUCCESS,
            failure: entityActionTypes.UPDATE_FAILURE,
          },
          deletes: {
            start: entityActionTypes.DELETE_START,
            success: entityActionTypes.DELETE_SUCCESS,
            failure: entityActionTypes.DELETE_FAILURE,
          },
        }),
      );
    }
    case 'note': {
      if (selectn('payload.entityType', action) !== 'notes') {
        return state;
      }

      return state.update(notificationState =>
        reduceResource(notificationState, action, {
          key: 'note',
          updates: {
            start: entityActionTypes.UPDATE_START,
            success: entityActionTypes.UPDATE_SUCCESS,
            failure: entityActionTypes.UPDATE_FAILURE,
          },
          deletes: {
            start: entityActionTypes.DELETE_START,
            success: entityActionTypes.DELETE_SUCCESS,
            failure: entityActionTypes.DELETE_FAILURE,
          },
        }),
      );
    }
    case 'reference': {
      if (selectn('payload.entityType', action) !== 'references') {
        return state;
      }

      return state.update(notificationState =>
        reduceResource(notificationState, action, {
          key: 'reference',
          updates: {
            start: entityActionTypes.UPDATE_START,
            success: entityActionTypes.UPDATE_SUCCESS,
            failure: entityActionTypes.UPDATE_FAILURE,
          },
          deletes: {
            start: entityActionTypes.DELETE_START,
            success: entityActionTypes.DELETE_SUCCESS,
            failure: entityActionTypes.DELETE_FAILURE,
          },
        }),
      );
    }
    case 'task': {
      return state.update(notificationState =>
        reduceResource(notificationState, action, {
          key: 'task',
          updates: {
            start: TASK_UPDATE_START,
            success: TASK_UPDATE_SUCCESS,
            failure: TASK_UPDATE_FAILURE,
          },
          deletes: {
            start: TASK_DELETE_START,
            success: TASK_DELETE_SUCCESS,
            failure: TASK_DELETE_FAILURE,
          },
        }),
      );
    }
    case 'meeting': {
      return state.update(notificationState =>
        reduceResource(notificationState, action, {
          key: 'meeting',
          updates: {
            start: MEETING_UPDATE_START,
            success: MEETING_UPDATE_SUCCESS,
            failure: MEETING_UPDATE_FAILURE,
          },
          deletes: {
            start: MEETING_DELETE_START,
            success: MEETING_DELETE_SUCCESS,
            failure: MEETING_DELETE_FAILURE,
          },
        }),
      );
    }
    default: {
      return state;
    }
  }
}
