import PropTypes from 'prop-types';
import { compose, setPropTypes, setDisplayName } from 'recompose';
import { connect } from 'react-redux';
import LocationName from 'modules/addresses/components/LocationName';
import getCandidacyLocationName from '../selectors/getCandidacyLocationName';

/**
 * Renders the LocationName component using the location name of the given candidacy.
 */
export default compose(
  setDisplayName('CandidacyLocationName'),
  setPropTypes({
    candidacyId: PropTypes.number.isRequired,
  }),
  connect(
    (state, { candidacyId }) => ({
      name: candidacyId && getCandidacyLocationName(state, candidacyId),
    }),
    {},
  ),
)(LocationName);
