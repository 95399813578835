import { compose, setDisplayName } from 'recompose';
import { connect } from 'react-redux';
import hasFeature from '../selectors/hasFeature';

/**
 * Adds the result of feature check as a prop.
 * @param {String} feature The feature name to check.
 * @param {String} propName The prop to add with the result of the feature check.
 */
export default (featureName, propName) =>
  compose(
    setDisplayName('withFeatureCheck'),
    connect(
      state => ({
        [propName]: hasFeature(state, featureName),
      }),
      {},
    ),
  );
