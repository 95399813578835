import { TRANSACTION_FINISH } from './actionTypes';

/**
 * Finishes an existing transaction that was previously started by dispatching
 *   `startTransaction`
 * @param {String} transactionId The unique transaction ID for the transaction.
 * @param {*} [payload] An optional payload to store with the transaction state.
 */
export default function finishTransaction(transactionId, payload) {
  return {
    transactionId: transactionId,
    type: TRANSACTION_FINISH,
    payload: payload,
  };
}
