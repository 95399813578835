//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'companies';

//---------------------------------------------------------
// Filters
//---------------------------------------------------------

export const FILTER_WITH_INTRODUCTION = 'companies/withIntroductions';
export const FILTERS = [FILTER_WITH_INTRODUCTION];

export const MATCH_NAME = 'name';
export const MATCH_WEBSITE_URL = 'website_url';
export const PARENT_SUGGESTION = 'parent_suggestion';

// Addresses table constants
export const COLUMN_ADDRESS_LINE_1 = 'address_line_1';
export const COLUMN_ADDRESS_LINE_2 = 'address_line_2';
export const COLUMN_CITY = 'city';
export const COLUMN_COUNTRY = 'country';
export const COLUMN_EDIT_DELETE = 'edit/delete';
export const COLUMN_LABEL = 'label';
export const COLUMN_POSTAL_CODE = 'postal_code';
export const COLUMN_PRIMARY_ADDRESS = 'primary';
export const COLUMN_STATE = 'state';

export const COLUMNS = [
  COLUMN_PRIMARY_ADDRESS,
  COLUMN_LABEL,
  COLUMN_ADDRESS_LINE_1,
  COLUMN_ADDRESS_LINE_2,
  COLUMN_CITY,
  COLUMN_STATE,
  COLUMN_COUNTRY,
  COLUMN_POSTAL_CODE,
  COLUMN_EDIT_DELETE,
];

// Used to identify addresses that belong to a company
export const PARENT_COMPANY = 'Company';

// Initial sortBy
export const SORT_BY_COUNTRY = 'country';
