import getSearch from './getSearch';

/**
 * Determines if a search records needs to be fetched from the server.
 * @param {Object} state
 * @param {Number} searchId The ID of the search
 * @return {Boolean} True if the search record needs to be fetched from the server;
 *   Otherwise, false.
 */
const shouldFetchSearch = (state, searchId) => {
  if (!searchId) {
    return false;
  }

  const search = getSearch(state, searchId);

  if (!search) {
    return true;
  }

  if (
    search.getIn(['_meta', 'isFetching']) ||
    search.hasIn(['_meta', 'error'])
  ) {
    return false;
  }

  return Boolean(search.getIn(['_meta', 'isInvalidated']));
};

export default shouldFetchSearch;
