import PropTypes from 'prop-types';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  compose,
  lifecycle,
  setDisplayName,
  setPropTypes,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';

import { FILTERS } from '../constants';
import fetchUserListActionCreator from '../actions/fetchUsers';
import getShouldFetchUserList from '../selectors/shouldFetchUserList';
import getUserList from '../selectors/getUserList';

/**
 * A higher order component which loads a list of users and passes them as a prop.
 * To specify a filtered list the `filter` property should be used.
 */
export default compose(
  setDisplayName('withUserListFetched'),
  setPropTypes({
    filter: PropTypes.oneOf(FILTERS),
  }),

  connect(
    (state, { filter }) => {
      const userList = getUserList(state, filter);

      return {
        shouldFetchUserList: getShouldFetchUserList(state, filter),
        userList: userList,
        userIds: userList && userList.get('ids'),
      };
    },
    dispatch => ({
      fetchUserList: bindActionCreators(fetchUserListActionCreator, dispatch),
    }),
  ),
  withHandlers({
    /**
     * A handler for fetching the introduction list specified by the `parentId` and `parentType`
     * props. This allows the component to simply call `fetchUserList()` without having to
     * supply params.
     */
    fetchUserList: ({ fetchUserList, filter }) => () =>
      fetchUserList({ filter: filter }),

    /**
     * A handler that, when called, only fetches the introduction list if needed.
     */
    fetchUserListIfNeeded: ({
      fetchUserList,
      filter,
      shouldFetchUserList,
    }) => () => shouldFetchUserList && fetchUserList({ filter: filter }),
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchUserListIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchUserListIfNeeded();
    },
  }),
);
