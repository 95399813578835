import { mapProps } from 'recompose';

/**
 * A higher-order component that removes props.
 * @example
 * ```
 * import TheBaseComponent from 'somewhere';
 * import { compose } from 'recompose';
 *
 * const MyComponentWithoutProps = withPropsRemoved('remove-me', 'className')(TheBaseComponent);
 *
 * // OR:
 * const MyComponentWithoutProps = compose(
 *   withPropsRemoved('remove-me', 'className')
 *   // ... other HOCs
 * )(TheBaseComponent);
 * ```
 *
 * @param {...String} keys The name of the props to remove.
 * @return {Function} A HOC that prevents props from being passed to the base component.
 */
const withPropsRemoved = (...keys) =>
  mapProps(props =>
    keys.reduce((accumulator, key) => {
      const { [key]: _value, ...rest } = accumulator;
      return rest;
    }, props),
  );

export default withPropsRemoved;
