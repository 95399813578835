import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import companySchema from '../companySchema';

/**
 * Gets the property from a company record
 * @param {*} state
 * @param {Number} companyId The ID of the company entity.
 * @param {String|String[]} The property name or keypath under the company entity to get.
 * @return {*} The property value, if found.
 */
export default (state, companyId, propertyName) =>
  getEntityProperty(state, companySchema.key, companyId, propertyName);
