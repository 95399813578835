import createListReducer from '../../../reducers/createListReducer';
import {
  OPTIONS_FETCH_START,
  OPTIONS_FETCH_SUCCESS,
  OPTIONS_FETCH_FAILURE,
} from '../actions/options/actionTypes';

/**
 * A reducers that maintains a list of all assessment option IDs
 * for the current tenant.
 */
export default createListReducer({
  resultKey: 'options',
  request: OPTIONS_FETCH_START,
  success: OPTIONS_FETCH_SUCCESS,
  failure: OPTIONS_FETCH_FAILURE,
});
