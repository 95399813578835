import { branch, compose, lifecycle } from 'recompose';
import connectContactActions from '../connectContactActions';

/**
 * When provided a contactID, fetches the educations for that contact if needed.
 */
export default compose(
  branch(({ contactActions }) => !contactActions, connectContactActions),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      const { contactActions, contactId } = this.props;
      if (contactId) {
        contactActions.fetchEducationsIfNeeded({ contactId: contactId });
      }
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      const { contactActions, contactId } = nextProps;
      if (contactId) {
        contactActions.fetchEducationsIfNeeded({ contactId: contactId });
      }
    },
  }),
);
