const formatRevenueAttributes = revenues =>
  revenues?.length
    ? revenues.map(revenue => ({
        year: new Date(revenue.date).getFullYear(),
        amount: revenue.revenue || revenue.amount_usd,
      }))
    : [];

const formatEmployeeCountAttributes = employeeCounts =>
  employeeCounts?.length
    ? employeeCounts.map(employeeCount => ({
        year: new Date(employeeCount.date).getFullYear(),
        amount: employeeCount.headcount,
      }))
    : [];

export const getSkyminyrCompanyCreatePayload = company => ({
  aliases_attributes: company.aliases?.map(alias => ({ name: alias })) || [],
  addresses_attributes: company.addresses,
  description: company.description,
  name: company.name,
  remote_logo_url: company.avatar,
  linkedin_url: company.linkedin_url,
  website_url: company.website_url,
  enriched_sector_names: company.enriched_sectors,
  ticker: company.ticker,
  publicly_traded: Boolean(company.ticker),
  revenues_attributes: formatRevenueAttributes(company.revenues),
  employee_counts_attributes: formatEmployeeCountAttributes(
    company.employee_counts,
  ),
  integration_data_attributes: [
    {
      integration_type: 'skyminyr',
      integration_id: company.skyminyr_id,
    },
  ],
});
