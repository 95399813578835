import routes from 'modules/routing/routes';
import createAjaxAction from '../../../../actions/createAjaxAction';
import {
  SEARCHES_FETCH_START,
  SEARCHES_FETCH_SUCCESS,
  SEARCHES_FETCH_FAILURE,
} from './actionTypes';
import {
  PARENT_COMPANY,
  PARENT_SEARCH_FIRM,
  PARENT_CONTACT,
  FIELDS_VIEW_EXPANDED,
} from '../../constants';
import { searchSchema } from '../../schema';

export const getQueryParamsForParentType = (parentType, parentId) => {
  if (parentType === PARENT_SEARCH_FIRM) {
    return {
      search_firm_id_eq: parentId,
    };
  }

  return {};
};

/**
 * Gets the collection of query parameters to send with the request given the payload.
 * Allows us to set some default querystring values.
 * @param {Object} payload The action payload/paramters
 * @param {String} [payload.view="expanded"] The "view" type to return from the server (determines
 *   which fields will be returned in the response).
 * @param {?String|String[]} [payload.status="*"] The status or list of statuses to filter the
 *   searches on. An asterisk indicates all. Without the parameter we only get open/active searches.
 * @param {?Number} [payload.page] The page number to return, if results should be paged on the
 *   server.
 * @param {?Number} [payload.limit] The maximum number of results to return.
 * @return {Object} The query parameters to send to the server.
 */
export const getQueryParams = ({
  view = FIELDS_VIEW_EXPANDED,
  status = '*',
  page,
  limit,
  parentType,
  parentId,
} = {}) => ({
  ...getQueryParamsForParentType(parentType, parentId),
  view: view,
  status_in: status,
  page: page,
  limit: limit,
});

/**
 * Gets the parameters that uniquely identify a fetch. It is a combination of the
 * parent type and ID and the query parameters. It's purpose is to determine if two requests
 * are equivalent.
 * @param {Object} payload
 * @param {?Object} payload.parentId The ID of the parent type object (i.e. a company ID when
 *   parentType is PARENT_COMPANY).
 * @param {?Object} payload.parentType One of PARENT_TYPES.
 */
export const getFetchParams = ({ parentId, parentType, ...payload } = {}) => ({
  parentId: parentId,
  parentType: parentType,
  ...getQueryParams(payload),
});

/**
 * Gets the URL for a searches list request given a payload.
 * @param {Object} payload
 * @param {?Object} payload.parentId The ID of the parent type object (i.e. a company ID when
 *   parentType is PARENT_COMPANY).
 * @param {?Object} payload.parentType One of PARENT_TYPES.
 */
export const getUrl = (payload = {}) => {
  const query = getQueryParams(payload);

  switch (payload.parentType) {
    case PARENT_COMPANY: {
      return routes.api_v1_company_searches({
        companyId: payload.parentId,
        query: query,
      });
    }
    case PARENT_CONTACT: {
      return routes.api_v1_contact_searches({
        contactId: payload.parentId,
        query: query,
      });
    }
    case PARENT_SEARCH_FIRM:
    case undefined: {
      return routes.api_v1_searches({ query: query });
    }
    default: {
      throw new Error(`Unknown parentType for searches: ${payload.parentType}`);
    }
  }
};

const fetchSearchesList = createAjaxAction({
  getUrl: getUrl,
  createStartPayload: payload => ({
    ...payload,
    fetchParams: getFetchParams(payload),
  }),
  schema: { searches: [searchSchema] },
  start: SEARCHES_FETCH_START,
  success: SEARCHES_FETCH_SUCCESS,
  failure: SEARCHES_FETCH_FAILURE,
});

export default fetchSearchesList;
