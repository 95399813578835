export const DOMAINS_REGEX = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;

export function isValidDomainName(domain) {
  return DOMAINS_REGEX.test(domain.trim());
}

export const domainName = fieldState => {
  const domains = fieldState.getValue();
  const invalidDomain = domains.filter(domain => !isValidDomainName(domain));
  if (invalidDomain.length) {
    return `Invalid domains: ${invalidDomain.join(', ')}`;
  }
  return null;
};

export default domainName;
