import { createSlice } from '@reduxjs/toolkit';
import routes from 'modules/routing/routes';
import Api from 'modules/core/Api';

const initialState = {
  isAddingToNetwork: false,
  isAddingToNetworkComplete: false,
  addToNetworkError: null,
};

const addToNetworkSlice = createSlice({
  name: 'addToNetwork',
  initialState: initialState,
  reducers: {
    addToNetworkBegin: state => {
      state.isAddingToNetwork = true;
      state.addToNetworkError = null;
    },
    addToNetworkSuccess: state => {
      state.isAddingToNetworkComplete = true;
      state.isAddingToNetwork = false;
    },
    addToNetworkError: (state, action) => {
      state.isAddingToNetwork = false;
      state.addToNetworkError = action.payload;
    },
    resetAddToNetwork: state => {
      state.isAddingToNetworkComplete = false;
    },
    removeFromNetworkBegin: state => {
      state.isRemovingFromNetwork = true;
      state.removeFromNetworkError = null;
    },
    removeFromNetworkSuccess: state => {
      state.isRemovingFromNetworkComplete = true;
      state.isRemovingFromNetwork = false;
    },
    removeFromNetworkError: (state, action) => {
      state.isRemovingFromNetwork = false;
      state.removeFromNetworkError = action.payload;
    },
  },
});

const {
  addToNetworkBegin,
  addToNetworkError,
  addToNetworkSuccess,
  removeFromNetworkBegin,
  removeFromNetworkError,
  removeFromNetworkSuccess,
  resetAddToNetwork,
} = addToNetworkSlice.actions;

const addRecordsToNetwork = (networkId, recordIds) => dispatch => {
  dispatch(addToNetworkBegin());

  const payload = {
    id: networkId,
    member_ids: recordIds,
  };

  Api.post(routes.bulk_api_v1_networks(), payload)
    .then(data => {
      dispatch(addToNetworkSuccess(data));
    })
    .catch(error => {
      dispatch(addToNetworkError(error.message));
    });
};

const removeRecordsFromNetwork = (
  networkId,
  recordIds,
  onSuccess,
) => dispatch => {
  dispatch(removeFromNetworkBegin());

  const payload = {
    id: networkId,
    member_ids: recordIds,
  };

  Api.delete(routes.bulk_api_v1_networks(), payload)
    .then(data => {
      dispatch(removeFromNetworkSuccess(data));
      onSuccess?.();
    })
    .catch(error => {
      dispatch(removeFromNetworkError(error.message));
    });
};

export { addRecordsToNetwork, resetAddToNetwork, removeRecordsFromNetwork };
export default addToNetworkSlice;
