//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'tenant';

//---------------------------------------------------------
// Tenant Types
//---------------------------------------------------------

// TODO: Is this right? Or is it just 'enterprise'? In refactoring,
// it had been 'vc_enterprise', but the server-side code seems to indicate
// it should just be 'enterprise'
export const TENANT_TYPE_ENTERPRISE = 'enterprise';
export const TENANT_TYPE_VENTURE_CAPITAL = 'venture_capital';
export const TENANT_TYPE_EXECUTIVE_SEARCH = 'executive_search';

export const TENANT_TYPES = [
  TENANT_TYPE_ENTERPRISE,
  TENANT_TYPE_VENTURE_CAPITAL,
  TENANT_TYPE_EXECUTIVE_SEARCH,
];

export const TENANT_TRUE = 'true';

//---------------------------------------------------------
// Labels
//---------------------------------------------------------

export const DEFAULT_RECRUITER_NAME = 'Recruiter';
export const DEFAULT_CLIENT_TYPE_NAME = 'Client';
