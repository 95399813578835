import getCsrfToken from './getCsrfToken';
import { GET } from './methods';
import { APPLICATION_JSON } from './mimeTypes';

/**
 * Generates an error message from an error response and body.
 * @param {Object} body The response body
 * @param {Object} response The response object
 * @return {String} An error message
 */
function createErrorMessage(body, response) {
  if (body && body.error) {
    return body.error;
  }
  if (body && body.errors && Array.isArray(body.errors)) {
    return body.errors.join('; ');
  }
  if (body && body.errors && Object.keys(body.errors).length) {
    const errors = [];
    Object.keys(body.errors).forEach(fieldName =>
      body.errors[fieldName].forEach(errorMessage => {
        errors.push(`${fieldName} ${errorMessage}`);
      }),
    );
    return errors.join('; ');
  }
  if (response.status === 422) {
    return 'A field value was not valid';
  }

  return response.statusText;
}

/**
 * Make an AJAX request using the `fetch` API which automatically parses JSON responses if
 * the response header has the appropriate "application/json" content type.
 * This automatically:
 *   1. set the `credentials` option to 'same-origin' so cookies are included.
 *   2. include a 'X-CSRF-Token' header in non-GET requests.
 *   3. Adds Accept header for 'application/json' mime type
 * @param {String} url The URL of the request.
 * @param {Object} [opts={}] Additional options to pass to the fetch call.
 * @param {String} [opts.accept="application/json"] The accept header to add.
 * @param {String|Boolean} [opts.contentType] The content type header to add, falsey to omit.
 * @param {Object.<String, String>} [opts.headers] Additional request headers to add.
 * @param {String} [opts.method="GET"] The method to use (GET, POST, PUT, PATCH, DELETE, etc)
 * @return {Promise} A Promise that resolves with the response of the fetch
 *   call or rejects if an error occurs.
 */
export default (
  url,
  { accept, contentType, headers, method = GET, ...options } = {},
) =>
  fetch(url, {
    // Send cookies when URL is the same origin.
    credentials: 'same-origin',
    method: method,
    headers: {
      Accept: accept || APPLICATION_JSON,

      // Add contentType header unless it is empty or false
      ...(contentType ? { 'Content-Type': contentType } : {}),

      // Add CSRF token header for non-GET requests.
      ...(method !== GET ? { 'X-CSRF-Token': getCsrfToken() } : {}),

      // Any additional headers passed in.
      ...headers,
    },
    ...options,
  }).then(response => {
    const responseContentType = response.headers.get('content-type');
    const isJson =
      responseContentType && responseContentType.includes(APPLICATION_JSON);

    if (response.ok) {
      if (response.status === 204 || response.headers['Content-Length'] === 0) {
        // No content, so can't parse json, just return null
        return Promise.resolve(null);
      }

      if (isJson) {
        return response.json();
      }
    }

    // We may have gotten a JSON response with some
    // error information, so still try to parse it so we can decorate
    // our error with some additional information.
    return (
      response[isJson ? 'json' : 'text']()
        // Ignore parsing errors
        .catch(() => null)
        .then(body => {
          const error = new Error(createErrorMessage(body, response));
          error.statusText = response.statusText;
          error.status = response.status;
          error.response = response;
          error.body = body;
          throw error;
        })
    );
  });
