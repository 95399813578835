import fetchEntity from 'modules/entities/actions/fetchEntity';
import routes from 'modules/routing/routes';
import { searchSchema } from '../../schema';

/**
 * Creates an action for fetching a search
 * @param {Object} payload
 * @param {String} payload.id The ID of the search
 */

export default ({ id }) =>
  fetchEntity({
    entityType: searchSchema.key,
    id: id,
    responseSchema: { search: searchSchema },
    url: routes.api_v1_search({ id: id }),
  });
