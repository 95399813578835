import composeReducers from './composeReducers';

/**
 * Calls a reducer function only when the predicate returns true.
 *
 * This is simply a function that can be used to filter out calls to a reducer based on the
 * action being dispatched or the current state.
 *
 *
 * @example
 * ```
 * const booleanReducer = (state = true, action) => (action.payload.value === 'yes');
 * const filterIfCanUploadDocumentsPayload = (state, action) =>
 *  (action.payload && typeof action.payload.canUploadDocuments === 'boolean');
 * const canUploadDocumentsReducer = reduceIf(filterIfCanUploadDocumentsPayload, booleanReducer);
 * ```
 *
 * The canUploadDocumentsReducer will only produce an updated state value when an action
 * is dispatched with a `payload.canUploadDocuments` property which is a boolean value. Otherwise,
 * the canUploadDocumentsReducer will simply return it's current state.
 *
 * @param {Function} predicate The predicate that is called with the state and action.
 * @param {...Function} reducer The reducer function or functions that will be called only if
 *   the predicate returns true.
 * @return {Function} A reducer that only passes through to the given reducer when the predicate
 *   returns true.
 */
export default function createFilteredReducer(predicate, ...reducers) {
  if (typeof predicate !== 'function') {
    throw new Error(
      'createFilteredReducer expected to be called with a predicate function' +
        `but instead got  a '${typeof predicate}'`,
    );
  }

  if (reducers.length === 0) {
    throw new Error(
      'createFilteredReducer expected to be called with one or more reducer functions!',
    );
  }

  if (!reducers.every(reducer => typeof reducer === 'function')) {
    throw new Error(
      'createFilteredReducer called with a non-function parameter!',
    );
  }

  const reducer =
    reducers.length === 1 ? reducers[0] : composeReducers(...reducers);

  return (state, action) => {
    if (predicate(state, action)) {
      return reducer(state, action);
    }

    return state;
  };
}
