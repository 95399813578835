import { fromJS, OrderedMap } from 'immutable';

/**
 * Takes an array of items and keys them by ID. If the ID is not present,
 * a temporary ID will be generated in the format of NEW_<iteration>.
 *
 * Please be careful in changing this function. If the keys of new
 * items does not start with `NEW`, it will break `saveContact`.
 *
 * @param  {Array}  [items=[]] An array of items with IDs.
 * @return {Array<Array>}      An array of tuples.
 */
const keyById = (items = []) =>
  new OrderedMap(
    items.reduce((acc, item, i) => {
      const key = item.id || `NEW_${i}`;
      const data = fromJS(item).merge({ isNewRecord: !item.id });
      return acc.concat([[key, data]]);
    }, []),
  );

export default ({ candidacies, documents, ...attributes } = {}) =>
  fromJS(attributes).withMutations(map => {
    map.set('candidacies', keyById(candidacies));
    map.set('documents', keyById(documents));
  });
