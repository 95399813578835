import getUserPermission from 'modules/auth/selectors/getUserPermission';

/**
 * Gets a value indicating whether the current user has permission to view current compensation.
 * @param {Object} state
 * @return {Boolean} True if the current user has permission to view compenstion; Otherwise, false.
 */
const canViewCurrentCompensation = state =>
  getUserPermission(state, 'can_view_current_compensation', false);

export default canViewCurrentCompensation;
