import PropTypes from 'prop-types';
import React from 'react';
import CompanyProfilePageLink from './CompanyProfilePageLink';

/**
 * A higher order component that wraps a base component in a link to a company's page.
 * @param {Node} BaseComponent The react component to wrap
 */
const wrapInCompanyLink = BaseComponent => {
  const CompanyLinkWrappedComponent = ({ companyId, ...props }) => (
    <CompanyProfilePageLink companyId={companyId}>
      <BaseComponent companyId={companyId} {...props} />
    </CompanyProfilePageLink>
  );

  CompanyLinkWrappedComponent.propTypes = {
    companyId: PropTypes.number.isRequired,
  };

  return CompanyLinkWrappedComponent;
};

export default wrapInCompanyLink;
