import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentPath: null,
  title: null,
};

export const quickViewSlice = createSlice({
  name: 'quickView',
  initialState: initialState,
  reducers: {
    closeQuickView: () => {
      // NOTE: `state = initialState;` does not work due to the internal mechanics of Immer
      return initialState;
    },
    navigateQuickViewTo: (state, action) => {
      const { path, title } = action.payload;
      state.currentPath = path;
      if (title) {
        state.title = title;
      }
    },
  },
});

export const { closeQuickView, navigateQuickViewTo } = quickViewSlice.actions;
export default quickViewSlice;
