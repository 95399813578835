import selectn from 'selectn';
import { List, Map } from 'immutable';
import createEntityListReducer from 'modules/entities/createEntityListReducer';
import {
  EMPLOYEE_COUNT_LIST_FETCH_START,
  EMPLOYEE_COUNT_LIST_FETCH_SUCCESS,
  EMPLOYEE_COUNT_LIST_FETCH_FAILURE,
  EMPLOYEE_COUNT_LIST_UPDATE_SUCCESS,
} from '../actions/actionTypes';
import employeeCountSchema from '../schema';

const employeeCountListReducer = createEntityListReducer({
  entityType: employeeCountSchema.key,
  listResultKey: 'employee_counts',
  entityResultKey: 'employee_counts',
  request: EMPLOYEE_COUNT_LIST_FETCH_START,
  success: EMPLOYEE_COUNT_LIST_FETCH_SUCCESS,
  failure: EMPLOYEE_COUNT_LIST_FETCH_FAILURE,
});

// EmployeeCounts keyed on the companyId
export default (state = new Map(), action) => {
  switch (action.type) {
    case EMPLOYEE_COUNT_LIST_FETCH_START:
    case EMPLOYEE_COUNT_LIST_FETCH_SUCCESS:
    case EMPLOYEE_COUNT_LIST_FETCH_FAILURE: {
      const companyId = selectn('payload.companyId', action);
      if (companyId) {
        return state.update(companyId, list =>
          employeeCountListReducer(list, action),
        );
      }

      return state;
    }
    case EMPLOYEE_COUNT_LIST_UPDATE_SUCCESS: {
      const companyId = selectn('payload.companyId', action);
      if (companyId) {
        return state.setIn(
          [companyId, 'ids'],
          List(selectn('payload.result.employee_counts', action)),
        );
      }

      return state;
    }
    default: {
      return state.map(list => employeeCountListReducer(list, action));
    }
  }
};
