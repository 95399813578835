import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import investmentListsByCompanyId from './investmentListsByCompanyId';

const companyInvestmentsReducer = combineImmutableReducers({
  investmentListsByCompanyId: investmentListsByCompanyId,
});

companyInvestmentsReducer.NAME = NAME;

export default companyInvestmentsReducer;
