import { schema } from 'normalizr';
import { attributeSchema, assessmentSchema } from 'modules/assessments/schema';
import companySchema from 'modules/companies/companySchema';
import { contactSchema } from 'modules/contacts/schema';
import industrySchema from 'modules/tenant/schemas/industries';
import jobFunctionSchema from 'modules/tenant/schemas/jobFunctions';
import sectorSchema from 'modules/tenant/schemas/sectors';
import userSchema from 'modules/users/schema';

export const searchCandidacyStats = new schema.Entity(
  'searchCandidacyStats',
  {},
  {
    idAttribute: 'search_id',
  },
);

export const searchSchema = new schema.Entity('searches', {
  assessment_attributes: [attributeSchema],
  candidacy_stats: searchCandidacyStats,
  client_company: companySchema,
  lead_internal_team_member: userSchema,
  lead_recruiter_team_member: userSchema,
  partners: [contactSchema],
  search_firm: companySchema,
  sectors: [sectorSchema],
  source_of_business: {
    company: companySchema,
    contact: contactSchema,
  },
  industries: [industrySchema],

  /**
   * Talent pool specific attributes
   */
  current_contact: contactSchema,
  job_function: jobFunctionSchema,
  internal_team_members: [userSchema],
  client_team_members: [userSchema],
});

assessmentSchema.define({
  search: searchSchema,
});
