import { normalize } from 'normalizr';
import { stringifyQueryObject } from 'modules/core/urlUtils';
import createQueryActions from '../../../actions/createQueryActions';
import companySchema from '../companySchema';
import {
  COMPANY_QUERY_CREATE,
  COMPANY_QUERY_DESTROY,
  COMPANY_QUERY_INVALIDATE,
  COMPANY_QUERY_FETCH_START,
  COMPANY_QUERY_FETCH_SUCCESS,
  COMPANY_QUERY_FETCH_FAILURE,
} from './actionTypes';
import {
  FILTER_WITH_INTRODUCTION,
  MATCH_NAME,
  MATCH_WEBSITE_URL,
  PARENT_SUGGESTION,
} from '../constants';

export const getUrl = ({ filter, parentCompanyId, term }) => {
  const params = { query: term };
  if (filter === FILTER_WITH_INTRODUCTION) {
    params.with_introductions = true;
  }

  if (filter === MATCH_NAME) {
    params.match_name = true;
  }

  if (filter === MATCH_WEBSITE_URL) {
    params.match_website_url = true;
  }

  if (filter === PARENT_SUGGESTION) {
    params.id = parentCompanyId;
    params.parent_suggestion = true;
  }

  return `/api/companies/search?${stringifyQueryObject(params)}`;
};

const actions = createQueryActions({
  create: COMPANY_QUERY_CREATE,
  destroy: COMPANY_QUERY_DESTROY,
  invalidate: COMPANY_QUERY_INVALIDATE,
  start: COMPANY_QUERY_FETCH_START,
  success: COMPANY_QUERY_FETCH_SUCCESS,
  failure: COMPANY_QUERY_FETCH_FAILURE,
  transformResponse: data => {
    if (data.suggestions) {
      return data.suggestions.map(item => ({
        id: item.data.id,
        name: item.value,
        aliases: item.alias,
        matchedOn: item.matched_on,
        exact_match: item.exact_match,
      }));
    }
    return data;
  },
  createSuccessPayload: (payload, data) => ({
    ...payload,
    ...normalize(data, [companySchema]),
  }),
  getUrl: getUrl,
});

export const queryCompanies = actions.query;
export const createCompaniesQuery = actions.create;
export const destroyCompaniesQuery = actions.destroy;
export const invalidateCompaniesQuery = actions.invalidate;
