import selectn from 'selectn';
import { Map } from 'immutable';
import createListReducer from '../../../reducers/createListReducer';
import {
  NETWORKS_INVALIDATE,
  NETWORKS_FETCH_START,
  NETWORKS_FETCH_SUCCESS,
  NETWORKS_FETCH_FAILURE,
  NETWORK_CREATE_SUCCESS,
  NETWORK_DELETE_SUCCESS,
} from '../actions/actionTypes';

const networkListReducer = createListReducer({
  resultKey: 'networks',
  entityResultKey: 'network',
  request: NETWORKS_FETCH_START,
  success: NETWORKS_FETCH_SUCCESS,
  failure: NETWORKS_FETCH_FAILURE,
  invalidated: NETWORKS_INVALIDATE,
  created: NETWORK_CREATE_SUCCESS,
  updated: NETWORK_DELETE_SUCCESS,
});

// Contains the complete list of all networks
export default (state = new Map(), action) => {
  switch (action.type) {
    case NETWORKS_INVALIDATE:
    case NETWORKS_FETCH_START:
    case NETWORKS_FETCH_SUCCESS:
    case NETWORKS_FETCH_FAILURE: {
      const contactId = selectn('payload.contactId', action);

      if (contactId) {
        // Ignore fetches for networks of a specific contact.
        return state;
      }

      return networkListReducer(state, action);
    }
    default: {
      return networkListReducer(state, action);
    }
  }
};
