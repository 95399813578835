import { schema } from 'normalizr';
import { contactSchema } from 'modules/contacts/schema';
import { searchSchema } from 'modules/searches/schema';
import userSchema from 'modules/users/schema';

export default new schema.Entity('references', {
  creator: userSchema,
  referee: contactSchema,
  search: searchSchema,
});
