import invalidateEntity from 'modules/entities/actions/invalidateEntity';
import companySchema from '../companySchema';

/**
 * Invalidates a company entity, signifying it needs to be refreshed from the server.
 * @param {Object} options
 * @param {Number} options.id The ID of the company to invalidate.
 */
export default ({ id }) =>
  invalidateEntity({
    entityType: companySchema.key,
    id: id,
  });
