import moment from 'moment';

/**
 * A PropType for validating moment objects
 */
function momentValidator(
  isRequired,
  props,
  propName,
  componentName,
  location,
  propFullName,
) {
  const { [propName]: propValue } = props;

  if (propValue == null) {
    if (isRequired) {
      return new Error(
        `Required ${location} \`${propFullName}\` was not specified in ` +
          `\`${componentName}\`.`,
      );
    }

    return null;
  }

  if (!moment.isMoment(propValue)) {
    const propType = Array.isArray(propValue) ? 'array' : typeof propValue;

    return new Error(
      `Invalid ${location} \`${propFullName}\` of type ` +
        `\`${propType}\` supplied to \`${componentName}\`, expected a moment.`,
    );
  }

  return null;
}

const momentPropType = momentValidator.bind(null, false);
momentPropType.isRequired = momentValidator.bind(null, true);

export default momentPropType;
