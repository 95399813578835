import fetchEntity from 'modules/entities/actions/fetchEntity';
import routes from 'modules/routing/routes';
import { candidacySchema, candidacyVoteSchema } from '../schemas';

/**
 * Creates an action for fetching a candidacy
 * @param {Object} payload
 * @param {String} payload.id The ID of the candidacy
 */
export default ({ id }) =>
  fetchEntity({
    entityType: candidacySchema.key,
    id: id,
    responseSchema: { candidacy: candidacySchema, vote: candidacyVoteSchema },
    url: routes.api_v1_candidacy({ id: id }),
  });
