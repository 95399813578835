import selectn from 'selectn';
import { Map } from 'immutable';
import createListReducer from '../../../reducers/createListReducer';
import {
  MEETINGS_INVALIDATE,
  MEETINGS_FETCH_START,
  MEETINGS_FETCH_SUCCESS,
  MEETINGS_FETCH_FAILURE,
  MEETING_CREATE_SUCCESS,
  MEETING_UPDATE_SUCCESS,
  MEETING_DELETE_SUCCESS,
} from '../actions/actionTypes';
import { PARENT_CANDIDACY, PARENT_SEARCH, PARENT_CONTACT } from '../constants';

const meetingListReducer = createListReducer({
  resultKey: 'meetings',
  entityResultKey: 'meeting',
  request: MEETINGS_FETCH_START,
  success: MEETINGS_FETCH_SUCCESS,
  failure: MEETINGS_FETCH_FAILURE,
  invalidated: MEETINGS_INVALIDATE,
  created: MEETING_CREATE_SUCCESS,
  updated: MEETING_UPDATE_SUCCESS,
  deleted: MEETING_DELETE_SUCCESS,
});

// Meetings are keyed on the parent type (PARENT_CONTACT),
// then by that parent's ID.
export default (state = new Map(), action) => {
  switch (action.type) {
    case MEETINGS_FETCH_START:
    case MEETINGS_FETCH_SUCCESS:
    case MEETINGS_FETCH_FAILURE: {
      const parentType = selectn('payload.parentType', action);
      const parentId = selectn('payload.parentId', action);
      const filter = selectn('payload.filter', action);

      if (parentType && parentId && !filter) {
        return state.updateIn([parentType, parentId], list =>
          meetingListReducer(list, action),
        );
      }

      return state;
    }
    case MEETINGS_INVALIDATE: {
      const searchId = selectn('payload.searchId', action);
      const candidacyId = selectn('payload.candidacyId', action);
      const contactId = selectn('payload.contactId', action);

      const filter = selectn('payload.filter', action);

      if (filter) {
        return state;
      }

      let result = state;

      if (searchId && state.hasIn([PARENT_SEARCH, searchId])) {
        result = result.setIn(
          [PARENT_SEARCH, searchId, '_meta', 'isInvalidated'],
          true,
        );
      }

      if (candidacyId && state.hasIn([PARENT_CANDIDACY, candidacyId])) {
        result = result.setIn(
          [PARENT_CANDIDACY, candidacyId, '_meta', 'isInvalidated'],
          true,
        );
      }

      if (candidacyId && state.hasIn([PARENT_CONTACT, contactId])) {
        result = result.setIn(
          [PARENT_CONTACT, contactId, '_meta', 'isInvalidated'],
          true,
        );
      }

      return result;
    }
    case MEETING_CREATE_SUCCESS: {
      // Check if the newly created meeting belongs to any currently fetched lists.
      const meetingId = selectn('payload.result.meeting', action);
      const meeting = selectn(`payload.entities.meetings.${meetingId}`, action);

      if (meeting) {
        return state.withMutations(mutable => {
          if (mutable.hasIn([PARENT_SEARCH, meeting.search_id, 'ids'])) {
            mutable.updateIn([PARENT_SEARCH, meeting.search_id, 'ids'], ids =>
              ids.push(meetingId),
            );
          }

          if (mutable.hasIn([PARENT_CANDIDACY, meeting.candidacy_id, 'ids'])) {
            mutable.updateIn(
              [PARENT_CANDIDACY, meeting.candidacy_id, 'ids'],
              ids => ids.push(meetingId),
            );
          }

          if (mutable.hasIn([PARENT_CONTACT, meeting.contact_id, 'ids'])) {
            mutable.updateIn([PARENT_CONTACT, meeting.contact_id, 'ids'], ids =>
              ids.push(meetingId),
            );
          }
        });
      }

      return state;
    }
    default: {
      return state.map(parentTypeState =>
        parentTypeState.map(listState => meetingListReducer(listState, action)),
      );
    }
  }
};
