import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import iso8601PropType from '@thrivetrm/ui/propTypes/iso8601PropType';
import momentPropType from '../propTypes/momentPropType';

/**
 * Renders a date+time value in a consistent way.
 */
const DateTime = ({ className, format, value }) => {
  const theMoment = moment.isMoment(value)
    ? value
    : moment(value, moment.ISO_8601);

  if (format === 'L LT') {
    const date = moment(value, moment.ISO_8601).format('L');
    const time = moment(value, moment.ISO_8601).format('LT');
    return (
      <time className={classnames('datetime', className)}>
        <span>
          {date} at {time}
        </span>
      </time>
    );
  }

  return (
    <time
      className={classnames('datetime', className)}
      dateTime={theMoment.format()}
    >
      {theMoment.format(format)}
    </time>
  );
};

DateTime.propTypes = {
  className: PropTypes.string,

  /**
   * The format string that will be passed to moment for formatting
   * (@see http://momentjs.com/docs/#/displaying/format/)
   */
  format: PropTypes.string,

  /**
   * The date/time value. This should be either a moment, or a date string
   * in ISO 8601 format.
   */
  value: PropTypes.oneOfType([iso8601PropType, momentPropType]),
};

DateTime.defaultProps = {
  format: 'L',
};

export default DateTime;
