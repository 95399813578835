//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'activities';

//---------------------------------------------------------
// Parent types (activity list "parents")
//---------------------------------------------------------

export const PARENT_CANDIDACY = 'Candidacy';
export const PARENT_SEARCH = 'Search';

/**
 * All available parent types (primarily used for validation).
 */
export const PARENT_TYPES = [PARENT_CANDIDACY, PARENT_SEARCH];
