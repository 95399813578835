import { connect } from 'react-redux';
import getSearch from '../selectors/getSearch';

/**
 * A higher order component which maps `searchId` to it's search record.
 */
export default connect(
  (state, { searchId }) => ({
    search: searchId && getSearch(state, searchId),
  }),
  {},
);
