import getLocationName from 'modules/contacts/selectors/contacts/getLocationName';
import getCandidacyContactId from './getCandidacyContactId';

/**
 * Gets the location name of a candidacy record.
 * @param {Object} state
 * @param {Number} id The candidacy ID
 * @return {?String} The location name of the contact
 */
const getCandidacyLocationName = (state, candidacyId) => {
  const contactId = getCandidacyContactId(state, candidacyId);
  return contactId && getLocationName(state, contactId);
};

export default getCandidacyLocationName;
