import deleteEntity from 'modules/entities/actions/deleteEntity';
import routes from 'modules/routing/routes';
import positionSchema from '../schema';

/**
 * Creates an action for deleting a postion record
 * @param {Object} payload
 * @param {Number} payload.id The ID of the position record to delete
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({ id, transactionId }) =>
  deleteEntity({
    transactionId: transactionId,
    // `id` is needed on the payload so we can access it in the reducer.
    id: id,
    entityType: positionSchema.key,
    url: routes.api_v1_position({ id: id }),
  });
