import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { compose, setStatic } from 'recompose';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import FieldState from '../FieldState';
import withFormGroup from './withFormGroup';
import RadioSelectFieldOption from './RadioSelectFieldOption';

/**
 * A field for selecting a single value from a set of options, displayed as a series of radio
 * buttons. This is _effectively_ like a <select> displaying radio buttons instead of a dropdown.
 */
class RadioSelectField extends Component {
  /**
   * Creates a new FieldState that can be passed to this field.
   * Defers to the default FieldState.create implementation.
   */
  static createFieldState = FieldState.create;

  /**
   * Called when the selected value is changed.
   * @param {[type]} event [description]
   * @return {[type]} [description]
   */
  handleChange = value => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setValue(value));
  };

  render() {
    const {
      className,
      componentId,
      disabled,
      fieldState,
      inline,
      // Ignored fields to exclude from ...otherProps
      // eslint-disable-next-line no-unused-vars
      onChange,
      options,
      ...otherProps
    } = this.props;
    const selectedValue = fieldState.getValue();

    // Note that since underlying values can only be strings in the DOM (since they are just
    // attributes), we set `checked` based on an explicit string comparison. Consumers of this
    // component should pass a `stringToValue` prop if the string value needs to be converted
    // to a non-string (or different) value)
    return (
      <div className={classnames('radio', className)} {...otherProps}>
        {options &&
          options.map(({ label, value }) => (
            <RadioSelectFieldOption
              checked={value === selectedValue}
              componentId={`${componentId}-${value}`}
              disabled={disabled}
              inline={inline}
              key={value}
              name={fieldState.getName()}
              onChange={this.handleChange}
              value={value}
            >
              {React.isValidElement(label)
                ? React.cloneElement(label, {
                    checked: value === selectedValue,
                  })
                : label}
            </RadioSelectFieldOption>
          ))}
      </div>
    );
  }
}

RadioSelectField.propTypes = {
  className: PropTypes.string,

  /**
   * A unique ID for this component instance.
   */
  componentId: PropTypes.string.isRequired,

  /**
   * True to disable the radio buttons, otherwise false.
   */
  disabled: PropTypes.bool,

  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Whether to display radio options inline or as block elements.
   */
  inline: PropTypes.bool,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * An array of option values. i.e.:
   * [{
   *   label: 'Apple',
   *   value: 'a'
   * }, {
   *   label: 'Banana',
   *   value: 'b'
   * }, {
   *   label: 'Cherry',
   *   value: 'c'
   * }]
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      value: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    }),
  ),
};

RadioSelectField.defaultProps = {
  disabled: false,
  inline: true,
};

export default compose(
  setStatic('createFieldState', RadioSelectField.createFieldState),
  withFormGroup,
  withComponentId(),
)(RadioSelectField);
