import { connect } from 'react-redux';
import getCandidacySearchLead from '../selectors/getCandidacySearchLead';

/**
 * A Higher order component that sorts a list of candidacyIds in order of the name of search's lead internal team member.
 */
export default connect(
  (state, { candidacyIds }) => ({
    candidacyIds:
      candidacyIds &&
      candidacyIds
        .sortBy(id =>
          (getCandidacySearchLead(state, id) || null)?.toLowerCase(),
        )
        .reverse(),
  }),
  {},
);
