import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

export default ImmutablePropTypes.mapOf(
  PropTypes.shape({
    isFinished: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    payload: PropTypes.any,
  }),
  PropTypes.any,
);
