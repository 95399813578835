import { connect } from 'react-redux';
import getCommentUpdatedAt from '../selectors/getCommentUpdatedAt';

/**
 * A Higher order component that sorts a list of commentIds on in reverse chronological order
 * of the updated_at date.
 */
export default connect((state, { commentIds }) => ({
  commentIds:
    commentIds &&
    commentIds
      .sortBy(commentId => getCommentUpdatedAt(state, commentId))
      .reverse(),
}));
