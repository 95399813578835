// We should seriously consider refactoring each plugin into simple functions.
// Before browserify, infecting the almighty $ made sense, but now, its just
// weird to have these global functions.

require('./section-toggle');
require('./sticky-tabs');

document.addEventListener('DOMContentLoaded', () => {
  $('[data-section-toggle-container]').sectionToggle();
});
