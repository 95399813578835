import getCandidacyProperty from './getCandidacyProperty';

/**
 * Returns a value indicating whether a candidacy has a particular target company.
 * @param {Object} state
 * @param {Number} candidacyId The candidacy ID
 * @param {String|null} targetCompanyName The name of the target company, or null to check if the
 *   candidacy has no target companies.
 * @return {Boolean} True if the candidate does, or has had a position at the targetCompany;
 *   otherwise false.
 */
const hasTargetCompany = (state, candidacyId, targetCompanyName) => {
  const targetCompanyNames = getCandidacyProperty(
    state,
    candidacyId,
    'active_target_companies',
  );

  if (targetCompanyName === null) {
    // If no target company, then what we're actually checking is if the candidacy has NO target
    // companies.
    return !targetCompanyNames || targetCompanyNames.count() === 0;
  }

  return targetCompanyNames && targetCompanyNames.includes(targetCompanyName);
};

export default hasTargetCompany;
