import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  compose,
  onlyUpdateForPropTypes,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import TimeLabel from 'modules/datetime/components/TimeLabel';
import mapCommentIdToComment from './mapCommentIdToComment';
import CommentTitle from './CommentTitle';

/**
 * Renders a readOnly view mode of a single comment.
 */
const CommentView = ({ comment, noDate, noTitle }) => (
  <div className='CommentView'>
    {!noTitle && <CommentTitle comment={comment} key='title' />}
    {!noDate && <TimeLabel time={comment.get('updated_at')} />}
    <ExpandableText
      characterLimit={150}
      content={comment.get('formatted_comment')}
      isSanitizedHtml={true}
    />
  </div>
);

CommentView.propTypes = {
  /**
   * The comment to render.
   */
  comment: ImmutablePropTypes.mapContains({
    formatted_comment: PropTypes.string.isRequired,
    updated_at: PropTypes.string,
  }).isRequired,

  /**
   * When true, the date will not be rendered.
   */
  noDate: PropTypes.bool,

  /**
   * When true, the title will not be rendered.
   */
  noTitle: PropTypes.bool,
};

export default compose(
  setDisplayName('CommentView(enhanced)'),
  setPropTypes({
    commentId: PropTypes.number.isRequired,
  }),
  mapCommentIdToComment,
  onlyUpdateForPropTypes,
)(CommentView);
