import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { branch, compose, mapProps, setPropTypes, setStatic } from 'recompose';
import mapPositionIdToPosition from 'modules/positions/components/mapPositionIdToPosition';
import withPositionActions from 'modules/positions/components/withPositionActions';
import connectTransactions from 'modules/transactions/components/connectTransactions';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';

import withTransactionTrackedForm from '../../../../components/forms/withTransactionTrackedForm';

import ContactPositionField from './ContactPositionField';
import mapContactIdToContact from '../mapContactIdToContact';

export const createRootFieldState = ({
  position = fromJS({}),
  contact,
  contactId,
  positionId,
  hasSkyminyrFeature,
}) =>
  ContactPositionField.createFieldState(
    'position',
    position.merge({
      primary: positionId === contact.get('primary_position'),
      contact_id: contact.get('id') || contactId,
    }),
    hasSkyminyrFeature,
  );

export const handleSubmit = (formState, props) => {
  const {
    contactId,
    position,
    positionActions: { createPosition, updatePosition },
    setAsPrimary,
  } = props;

  const defaults = fromJS({ contact_id: contactId });
  const transactionId = uniqueId();
  const fieldValue = formState.getFieldValue();
  const values = (position || defaults).merge(fieldValue);

  const action = values.get('id') ? updatePosition : createPosition;

  action({
    contact_id: contactId,
    position: {
      ...values.toJS(),
      primary: values.get('primary') || setAsPrimary,
    },
    transactionId: transactionId,
  });

  return formState.startSubmit(transactionId);
};

export const connectForm = compose(
  setPropTypes({
    /**
     * If editing an position
     */
    position: ImmutablePropTypes.mapContains({
      id: PropTypes.number.isRequired,
    }),

    positionId: PropTypes.number,
  }),
  branch(props => props.positionId && !props.position, mapPositionIdToPosition),
  mapProps(({ contactId, position, ...props }) => ({
    ...props,
    position: position,
    contactId: contactId || (position && position.get('contact_id')),
  })),
  mapContactIdToContact,
  connectTransactions,
  withPositionActions,
);

export default compose(
  setStatic('FIELD_LAYOUTS', ContactPositionField.FIELD_LAYOUTS),
  connectForm,
  withFeatureCheck('feature.skyminyr', 'hasSkyminyrFeature'),
  withTransactionTrackedForm(createRootFieldState, handleSubmit, {
    formClassName: 'ContactPositionForm',
    omitProps: [
      'contact',
      'formState',
      'hasSkyminyrFeature',
      'onSaved',
      'position',
      'positionActions',
      'positionId',
      'contactActions',
      'searchId',
      'setAsPrimary',
      'transactionActions',
    ],
  }),
)(ContactPositionField);
