import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  compose,
  branch,
  withProps,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import Avatar from 'modules/core/componentsLegacy/Avatar';
import getAvatarUrl from '../selectors/contacts/getAvatarUrl';

const mapContactIdToAvatarUrl = connect((state, { contactId }) => ({
  url: getAvatarUrl(state, contactId),
}));

/**
 * Renders the avatar image for a contact.
 */
export default compose(
  setDisplayName('ContactAvatar'),
  setPropTypes({
    contactId: PropTypes.number,
    url: PropTypes.string,
  }),
  branch(({ contactId, url }) => !url && contactId, mapContactIdToAvatarUrl),
  withProps(({ useLegacyStyles }) => ({
    className: 'ContactAvatar',
    useLegacyStyles: useLegacyStyles,
  })),
)(Avatar);
