const ABORT_ERROR_NAME = 'AbortError';
const DEFAULT_ERROR_MESSAGE = 'An error has occurred. Please contact Support.';
const HTTP_NO_CONTENT = 204;

class Api {
  constructor() {
    this._inflightRequests = {};
  }

  _makeAbortSignal(requestKey) {
    const abortController = new AbortController();
    this._inflightRequests[requestKey] = abortController;
    return abortController.signal;
  }

  _removeInflightRequest(requestKey) {
    delete this._inflightRequests[requestKey];
  }

  _abortDuplicateRequest(requestKey) {
    const duplicateRequestAbortController = this._inflightRequests[requestKey];
    if (duplicateRequestAbortController) {
      duplicateRequestAbortController.abort();
      this._removeInflightRequest(requestKey);
    }
  }

  _isJsonResponse(response) {
    const hasContent = response.status !== HTTP_NO_CONTENT;
    const isJsonTypeResponse = response.headers
      .get('Content-Type')
      ?.includes('application/json');

    return hasContent && isJsonTypeResponse;
  }

  _getRequestHeaders() {
    const requestHeaders = {
      'Content-Type': 'application/json',
    };
    const csrfToken = document.querySelector('meta[name="csrf-token"]')
      ?.content;
    if (csrfToken) {
      requestHeaders['X-CSRF-Token'] = csrfToken;
    }

    return requestHeaders;
  }

  _makeRequest(
    url,
    method,
    payload,
    options = { shouldAbortWhenQueryParamsChange: true },
  ) {
    const requestKey = options?.shouldAbortWhenQueryParamsChange
      ? url.split('?')[0]
      : url;
    this._abortDuplicateRequest(requestKey);
    const config = {
      method: method,
      signal: this._makeAbortSignal(requestKey),
      headers: this._getRequestHeaders(),
    };

    if (payload) {
      config.body = JSON.stringify(payload);
    }

    return fetch(url, config)
      .then(async response => {
        let data = null;
        if (this._isJsonResponse(response)) {
          data = await response.json();
        }
        if (response.ok) {
          return data;
        } else {
          return Promise.reject(data);
        }
      })
      .finally(() => this._removeInflightRequest(requestKey));
  }

  get(url, options = {}) {
    return this._makeRequest(url, 'GET', null, options);
  }

  post(url, payload, options = {}) {
    return this._makeRequest(url, 'POST', payload, options);
  }

  put(url, payload, options = {}) {
    return this._makeRequest(url, 'PUT', payload, options);
  }

  patch(url, payload, options = {}) {
    return this._makeRequest(url, 'PATCH', payload, options);
  }

  delete(url, payload, options = {}) {
    return this._makeRequest(url, 'DELETE', payload, options);
  }
}

// Code calling any of the methods in this Api class should process caught errors
// through this function.
const getErrorMessage = error => {
  // This module handles cancelling duplicate inflight requests, so we suppress
  // this type of error.
  if (error?.name === ABORT_ERROR_NAME) {
    return null;
  } else if (error?.error) {
    return error.error;
  }
  // Some API endpoints return an `errors` key, which is currently not
  // handled here, as no consumers of this modules have had that need. When and
  // if this functionality is needed, the legacy fetch module can provide a
  // blueprint for handling this:
  // https://github.com/ThriveTRM/true-app/blob/development/app/assets/javascripts/modules/api/fetch.js#L15-L26
  // Note: Unless there's a very compelling resason to do so, we do not want to
  // support an `errors` key that can have either an array or object value.
  // The use of Object is an outlier. If this is ever an issue, we should update
  // the endpoint to return the errors as an array rather than supporting here.
  return DEFAULT_ERROR_MESSAGE;
};

export { getErrorMessage };
export default new Api();
