import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  setPropTypes,
  setStatic,
} from 'recompose';
import CheckboxField from 'modules/forms/components/CheckboxField';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import mapContactIdToContact from '../mapContactIdToContact';
import PrimaryPositionWarning from './PrimaryPositionWarning';

/**
 * A field for setting a position as the primary position for a contact.
 * This will show a waring when setting this is selected but the contact's current
 * primary position is set to a different position.
 */
export default compose(
  setDisplayName('PrimaryPositionCheckboxField'),
  setStatic('createFieldState', CheckboxField.createFieldState),
  setPropTypes({
    /**
     * The ID of the contact this position is or will be associated with.
     */
    contactId: PropTypes.number,

    fieldState: fieldStatePropType.isRequired,

    /**
     * The ID of the position record this appllies to (if editing an existing ID)
     * For contact reviews we auto-generate a unique ID string, so in those cases
     * this will be a string. In "normal" cases it will be a number.
     */
    positionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  defaultProps({
    text: 'Save as the primary position',
  }),
  mapContactIdToContact,
  mapProps(({ contact, contactId, fieldState, positionId, ...rest }) => ({
    ...rest,
    fieldState: fieldState,
    // If the fieldstate is checked (meaning "make this the primary position") and the
    // contact's current primary position is different from this position, then show a warning.
    warning:
      fieldState.getValue() &&
      contact &&
      contact.get('primary_position') &&
      contact.get('primary_position') !== positionId ? (
        <PrimaryPositionWarning contactId={contactId} />
      ) : null,
  })),
)(CheckboxField);
