import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import routes from 'modules/routing/routes';
import { assessmentSchema } from '../../schema';
import {
  ASSESSMENTS_FETCH_START,
  ASSESSMENTS_FETCH_SUCCESS,
  ASSESSMENTS_FETCH_FAILURE,
} from './actionTypes';
import { PARENT_CANDIDACY, PARENT_CONTACT } from '../../constants';

/**
 * Fetches the assessments for a particular candidacy
 * @param {Object} options The fetch options.
 * @param {Number} options.candidacyId The ID of the candidacy to fetch the assessments for.
 */
export default compose(
  fetchAction,
  normalizeResponse({ assessments: [assessmentSchema] }),
  createAsyncActions({
    startActionType: ASSESSMENTS_FETCH_START,
    successActionType: ASSESSMENTS_FETCH_SUCCESS,
    failureActionType: ASSESSMENTS_FETCH_FAILURE,
  }),
  withOptions(({ hasAssessmentTemplates, parentId, parentType }) => {
    if (parentType === PARENT_CONTACT) {
      return {
        url: hasAssessmentTemplates
          ? routes.api_v1_contact_candidacy_assessments({
              contactId: parentId,
            })
          : routes.api_v1_contact_assessments({
              contactId: parentId,
            }),
      };
    }

    if (parentType === PARENT_CANDIDACY) {
      return {
        url: routes.api_v1_candidacy_legacy_assessments({
          candidacyId: parentId,
        }),
      };
    }

    throw new Error(
      `Unknown parentType "${parentType}" provided to fetchAssessments`,
    );
  }),
);
