import PropTypes from 'prop-types';
import selectn from 'selectn';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withHandlers,
  withProps,
} from 'recompose';
import { connect } from 'react-redux';
import preventDefaultHandler from 'modules/core/componentsLegacy/preventDefaultHandler';
import TextAreaField from 'modules/forms/components/TextAreaField';
import withFormState from 'modules/forms/components/withFormState';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import withTransaction from 'modules/transactions/components/withTransaction';
import voteCandidacyAction from '../../actions/voteCandidacy';

/**
 * Provides props for managing a form that submits a candidacy vote comment.
 * The vote is already selected and passed in as a prop before the form is shown.
 *
 * Provides the following props for the child component:
 * * `formState` {FormState} a FormState object with an underlying FieldState for the comment
 * * `onSubmit` {Function} Called when the form should be submitted.
 * * `onFieldStateChange` {Function} Called when the FormState's underlying FieldState should
 *   be changed.
 * * `onFormStateChange` {Function} Called when the FormState should be changed.
 */
export default compose(
  setDisplayName('withCandidacyVoteForm'),
  setPropTypes({
    /**
     * The ID of the candidacy that is being voted on.
     */
    candidacyId: PropTypes.number,

    /**
     * An optional formstate to initial the form with (handled by withFormState)
     */
    initialFormState: formStatePropType,

    /**
     * Called when the form has been successfully saved.
     */
    onSaved: PropTypes.func.isRequired,

    /**
     * `1` for "priority", `-1` for "not a fit", false to "unvote"
     */
    vote: PropTypes.oneOf([-1, 1, false]).isRequired,
  }),

  // Create a temporary prop (`withCandidacyVoteFormProps`) that holds any incoming props
  // that aren't required by us. This ensures we don't overwrite/delete/clobber any props that may
  // need to be passed through.
  withProps(
    ({
      candidacyId: _candidacyId,
      initialFormState: _initialFormState,
      onSaved: _onSaved,
      ...withCandidacyVoteFormProps
    }) => ({
      withCandidacyVoteFormProps: withCandidacyVoteFormProps,
    }),
  ),

  /**
   * Create a FormState, initializing it with an empty comment field.
   * provides `onFormStateChange`, `onFieldStateChange`, and `onResetFormState`
   */
  withFormState(() => TextAreaField.createFieldState('comment', '')),

  /**
   * Watch for any save transaction to complete
   */
  withHandlers({
    /**
     * This gets called by `withTransaction`, below, any time our transaction started
     * with `startTransaction` is called.
     */
    onTransactionComplete: ({
      formState,
      onFormStateChange,
      onResetFormState,
      onSaved,
    }) => transaction => {
      const error = selectn('payload.error', transaction);
      onFormStateChange(formState.endSubmit(error));

      if (!error) {
        onResetFormState();

        // If all was good, call onSaved with the vote ID
        onSaved(selectn('payload.result', transaction));
      }
    },
  }),

  /**
   * Gives us `startStransaction` to create a transaction, and called `onTransactionComplete`
   * when the transaction used with `startTransaction` is finished.
   */
  withTransaction,

  /**
   * Include connected version of `voteCandidacy` that is needed to submit.
   */
  connect(null, {
    voteCandidacy: voteCandidacyAction,
  }),

  /**
   * Add a callback to handle submitting the action form.
   */
  withHandlers({
    // Called when the form should be submitted.
    onSubmit: ({
      candidacyId,
      formState,
      onFormStateChange,
      startTransaction,
      vote,
      voteCandidacy,
    }) => e => {
      const transactionId = startTransaction();

      voteCandidacy({
        id: candidacyId,
        vote: vote,
        comment: formState.getFieldValue(),
        transactionId: transactionId,
      });

      onFormStateChange(formState.startSubmit(transactionId));

      // Prevent default browser behavior, which could cause the browser to attempt
      // to submit a form on it's own.
      return preventDefaultHandler(e);
    },
  }),

  // Only pass in what we need -- prevent any props we used/created from bleeding down to the child.
  mapProps(
    ({
      formState,
      onCancel,
      onFieldStateChange,
      onFormStateChange,
      onResetFormState,
      onSubmit,
      withCandidacyVoteFormProps,
    }) => ({
      ...withCandidacyVoteFormProps,
      formState: formState,
      onFieldStateChange: onFieldStateChange,
      onFormStateChange: onFormStateChange,
      onResetFormState: onResetFormState,
      onSubmit: onSubmit,
      onCancel: onCancel,
    }),
  ),
);
