import createListReducer from '../../../reducers/createListReducer';
import {
  CONTACT_METHOD_LIST_FETCH_START,
  CONTACT_METHOD_LIST_FETCH_SUCCESS,
  CONTACT_METHOD_LIST_FETCH_FAILURE,
} from '../actions/contactMethods/actionTypes';

/**
 * A reducer that maintains a list of all contact method IDs.
 */
export default createListReducer({
  resultKey: 'contact_methods',
  request: CONTACT_METHOD_LIST_FETCH_START,
  success: CONTACT_METHOD_LIST_FETCH_SUCCESS,
  failure: CONTACT_METHOD_LIST_FETCH_FAILURE,
});
