import { normalize } from 'normalizr';
import routes from 'modules/routing/routes';
import { createPostAction } from '../../../../actions/createAjaxAction';
import { ATTRIBUTES_FETCH_SUCCESS } from '../attributes/actionTypes';
import {
  GUEST_ASSESSMENT_INITIALIZE,
  GUEST_ASSESSMENT_CREATE_START,
  GUEST_ASSESSMENT_CREATE_SUCCESS,
  GUEST_ASSESSMENT_CREATE_FAILURE,
} from './actionTypes';
import { attributeSchema } from '../../schema';
import transformAssessmentForServer from '../assessments/transformAssessmentForServer';

/**
 * Creates an action that initializes the underlying data for a guest assessment.
 * @param {Object} options
 * @param {Object[]} options.assessmentAttributes The attributes that can be rated.
 * @param {Number} options.maxRating The maximum number of stars that can be given for any rating
 * @param {Number} options.searchId The ID of the search that this data describes.
 */
export const initializeGuestAssessment = ({
  assessmentAttributes,
  maxRating,
  searchId,
}) => dispatch => {
  // I'm not sure this is even necessary? I don't think any reducers current use this action type.
  // TODO: Investigate whether maxRating is being used appropriately? Or if it's even needed.
  dispatch({
    type: GUEST_ASSESSMENT_INITIALIZE,
    payload: {
      assessmentAttributes: assessmentAttributes,
      maxRating: maxRating,
      searchId: searchId,
    },
  });

  // Ultimately we just need to get our attributes into state for the search in question.
  return dispatch({
    type: ATTRIBUTES_FETCH_SUCCESS,
    payload: {
      searchId: searchId,
      ...normalize(
        { attributes: assessmentAttributes },
        { attributes: [attributeSchema] },
      ),
    },
  });
};

export const createSubmitPayload = ({ assessment, ...other }) => ({
  assessment: transformAssessmentForServer(assessment),
  ...other,
});

/**
 * Creates an action for creating a guest assessment
 * @param {Object} payload
 * @param {String} payload.token The guest assessment token that is used to authorize and assign
 *   this assessment record to the correct candidacy.
 * @param {Object} payload.assessment The assessment record to create.
 * @param {String} [payload.transactionId] An ID for tracking this action in the transactions state
 */
export const createGuestAssessment = createPostAction({
  createStartPayload: createSubmitPayload,
  getUrl: routes.api_guest_assessments,
  start: GUEST_ASSESSMENT_CREATE_START,
  success: GUEST_ASSESSMENT_CREATE_SUCCESS,
  failure: GUEST_ASSESSMENT_CREATE_FAILURE,
});
