import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import candidacyLists from './candidacyLists';

const candidaciesReducer = combineImmutableReducers({
  candidacyLists: candidacyLists,
});

candidaciesReducer.NAME = NAME;

export default candidaciesReducer;
