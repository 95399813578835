import { compose } from 'recompose';
import { DELETE } from 'modules/api/methods';
import fetchAction from 'modules/api/fetchAction';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import defaultMethod from 'modules/api/actionModifiers/defaultMethod';
import {
  DELETE_START,
  DELETE_SUCCESS,
  DELETE_FAILURE,
} from './entityActionTypes';

/**
 * An action creator for fetching and dispatching entity DELETE_* actions.
 * @param {Object} options
 * @param {String} [options.method="DELETE"] The request method to use
 * @param {String} options.url The URL of the request
 * @param {String} options.id The ID of the entity being deleted
 */
export default compose(
  fetchAction,
  createAsyncActions({
    startActionType: DELETE_START,
    successActionType: DELETE_SUCCESS,
    failureActionType: DELETE_FAILURE,
  }),
  defaultMethod(DELETE),
);
