import createEntityListReducer from 'modules/entities/createEntityListReducer';
import {
  RESIDENCIES_INVALIDATE,
  RESIDENCIES_FETCH_START,
  RESIDENCIES_FETCH_SUCCESS,
  RESIDENCIES_FETCH_FAILURE,
} from '../actions/actionTypes';
import residencySchema from '../schema';

/**
 * A reducer that maintains a list of all residency IDs.
 */
export default createEntityListReducer({
  entityType: residencySchema.key,
  listResultKey: 'residencies',
  entityResultKey: 'residency',
  invalidated: RESIDENCIES_INVALIDATE,
  request: RESIDENCIES_FETCH_START,
  success: RESIDENCIES_FETCH_SUCCESS,
  failure: RESIDENCIES_FETCH_FAILURE,
});
