//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'residencies';
export const RESIDENCY_TYPE_CONSTANTS = {
  API_PUBLIC: 'APIPublic',
  CHROME_EXTENSION: 'ChromeExtension',
  OUTLOOK_ADDIN: 'OutlookAddin',
};
