/* eslint-disable camelcase */
// ^ accommodate API exchange format
import { schema } from 'normalizr';
import { contactSchema } from 'modules/contacts/schema';
import commentSchema from 'modules/comments/commentSchema';
import searchStageSchema from 'modules/search-stages/schema';
import { assessmentSchema } from 'modules/assessments/schema';
import jobApplicationSchema from 'modules/job-applications/schema';

/**
 * The "status" of an individual candidacy record. The ID here is the candidacy record ID
 * itself -- a candidacy only ever has 1 status at any given time.
 */
export const candidacyStatusSchema = new schema.Entity(
  'candidacyStatuses',
  {},
  {
    // The status ID should use the candidacy ID.
    idAttribute: (_, parent) => parent.id,

    // Never merge, just use the new status.
    mergeStrategy: (_, newEntity) => newEntity,
  },
);

/**
 * The schema for the candidacy record itself.
 */
export const candidacySchema = new schema.Entity(
  'candidacies',
  {
    status: candidacyStatusSchema,
    contact: contactSchema,
    candidacy_stage: searchStageSchema,
    job_application: jobApplicationSchema,
    recruiter_assessment: assessmentSchema,
  },
  {
    // The status can be null, but we want to make sure we always have at least _something_ to
    // put in our entities reducer.
    processStrategy: ({ status, ...entity }) => ({
      ...entity,
      status:
        status !== null
          ? status
          : {
              created_at: false,
              url: null,
              description: null,
              type: null,
              time_ago: null,
            },
    }),
  },
);

/**
 * The schema that describes the current user's vote for candidacy record. Uses the ID of
 * the candidacy record as it's ID, as there can only be one vote per candidacy for a user.
 */
export const candidacyVoteSchema = new schema.Entity(
  'candidacyVotes',
  {
    candidacy: candidacySchema,
    comment: commentSchema,
  },
  {
    // Use the candidacy_id as the vote ID because each candidacy has one and only one vote
    // per user.
    idAttribute: 'candidacy_id',

    // In some cases the candiacy record is attached, but in other cases it isn't,
    // so in order to be sure the `candidacy` is always an ID, we shape
    // it into a candidacy object (which will get normalized anyway).
    processStrategy: ({ candidacy, candidacy_id, ...rest }) => ({
      ...rest,
      candidacy_id: candidacy_id,
      candidacy: candidacy || {
        id: candidacy_id,
      },
    }),
  },
);
