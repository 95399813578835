import { connect } from 'react-redux';
import { FILTER_TARGET_COMPANY_NONE } from '../constants';
import hasTargetCompany from '../selectors/hasTargetCompany';

/**
 * A Higher order component that filters a list of candidacyIds to only include those candidates
 * who have had a position at the target company named by `filterTargetCompany`. It also adds an
 * `isFiltered` prop so the base component can know when it's candidacyIds have been filtered.
 */
export default connect(
  (state, { candidacyIds, filterTargetCompany, isFiltered }) => ({
    isFiltered: isFiltered || true,
    candidacyIds:
      candidacyIds &&
      candidacyIds.filter(id =>
        hasTargetCompany(
          state,
          id,
          filterTargetCompany === FILTER_TARGET_COMPANY_NONE
            ? null
            : filterTargetCompany,
        ),
      ),
  }),
  {},
);
