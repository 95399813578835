import getComment from '../selectors/getComment';
import commentSchema from '../commentSchema';
import { CHILD_COMMENT_CREATED } from './actionTypes';

/**
 * An action to be dispatched when a comment is create that has a parent.
 * Adds the child comment's ID to the parent comments `child_comments` list (if the parent
 * comment exists in state)
 * @param {Object} payload
 * @param {Number} payload.id The ID of the comment that was created.
 * @param {Number} payload.parentId The ID of the parent comment.
 */
export default ({ id, parentId }) => (dispatch, getState) => {
  const parentComment = getComment(getState(), parentId);

  if (parentComment) {
    dispatch({
      type: CHILD_COMMENT_CREATED,
      payload: {
        entities: {
          [commentSchema.key]: {
            [parentId]: {
              id: parentId,
              child_comments: (
                parentComment.get('child_comments') || []
              ).concat(id),
            },
          },
        },
      },
    });
  }
};
