import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import FieldState from 'modules/forms/FieldState';
import TextAreaField from 'modules/forms/components/TextAreaField';
import * as validators from 'modules/forms/validators';
import CommentTitle from './CommentTitle';

/**
 * A field for editing a single comment
 */
const CommentField = ({ comment, ...otherProps }) => (
  <div className='CommentField'>
    {comment ? <CommentTitle comment={comment} /> : null}
    <TextAreaField {...otherProps} label={false} />
  </div>
);

CommentField.propTypes = {
  /**
   * If editing an existing comment, the record that is being edited.
   */
  comment: ImmutablePropTypes.mapContains({
    formatted_title: PropTypes.string,
  }),

  fieldState: PropTypes.instanceOf(FieldState).isRequired,
};

export const createFieldState = comment =>
  TextAreaField.createFieldState(
    'comment',
    comment ? comment.get('content') : '',
    validators.requiredField('Comments', 'are'),
  );

CommentField.createFieldState = createFieldState;

export default CommentField;
