import PropTypes from 'prop-types';
import React from 'react';
import PositionCompanyName from 'modules/positions/components/PositionCompanyName';
import PositionName from 'modules/positions/components/PositionName';

/**
 * Renders the position and company name for a position record.
 */
const ContactPosition = ({ companyPrefix, linkCompanyName, positionId }) => (
  <div className='ContactPosition'>
    <PositionName positionId={positionId} />
    <div className='ContactPosition__PositionCompanyName'>
      {companyPrefix}
      <PositionCompanyName
        linkToCompany={linkCompanyName}
        positionId={positionId}
      />
    </div>
  </div>
);

ContactPosition.propTypes = {
  /**
   * Content to render before the company name.
   */
  companyPrefix: PropTypes.node,

  /**
   * True to link the company name to it's company profile page, false to not render the
   * company name as a link.
   */
  linkCompanyName: PropTypes.bool,

  /**
   * The ID of the position record.
   */
  positionId: PropTypes.number.isRequired,
};

ContactPosition.defaultProps = {
  linkCompanyName: false,
};

export default ContactPosition;
