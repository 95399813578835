import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  defaultProps,
  mapProps,
  onlyUpdateForKeys,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import getPositionProperty from '../selectors/getPositionProperty';

export default compose(
  setDisplayName('PositionName'),
  setPropTypes({
    positionId: PropTypes.number,
  }),
  defaultProps({
    useFormer: false,
  }),
  connect(
    (state, { positionId }) => ({
      positionTo: getPositionProperty(state, positionId, 'to'),
    }),
    {},
  ),
  connect(
    (state, { positionId, positionTo, useFormer }) => ({
      positionName:
        useFormer && positionTo
          ? `(Former) ${getPositionProperty(state, positionId, 'name')}`
          : getPositionProperty(state, positionId, 'name'),
    }),
    {},
  ),
  onlyUpdateForKeys(['positionName']),
  branch(({ positionName }) => !positionName, renderNothing),
  mapProps(({ positionName }) => ({
    children: positionName,
    className: 'PositionName',
  })),
)('span');
