import createEntity from 'modules/entities/actions/createEntity';
import routes from 'modules/routing/routes';
import positionSchema from '../schema';

/**
 * An action for creating a position record.
 * @param {Object} payload
 * @param {Object} payload.position The positiom record to create
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({ position: { company, ...position }, transactionId }) =>
  createEntity({
    body: {
      position: {
        // Rename 'company' key to 'company_id', as expected by the server.
        company_id: company,
        ...position,
      },
    },
    transactionId: transactionId,
    entityType: positionSchema.key,
    responseSchema: { position: positionSchema },
    url: routes.api_v1_positions(),
  });
