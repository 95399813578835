import { combineReducers } from '@reduxjs/toolkit';

// RTK Query services
import apiV1 from 'services/apiV1';

// Reducers
import activitiesReducer from 'modules/activities/reducers/activitiesReducer';
import addressesReducer from 'modules/addresses/reducers/addressesReducer';
import addToSearchSlice from 'modules/recordIndex/addToSearchSlice';
import addToNetworkSlice from 'modules/recordIndex/addToNetworkSlice';
import assessmentsReducer from 'modules/assessments/reducers/assessmentsReducer';
import bulkImportsReducer from 'modules/bulk-imports/bulkImportsReducer';
import candidaciesReducer from 'modules/candidacies/reducers/candidaciesReducer';
import candidacyBulkActionsReducer from 'modules/candidacy-views/searchPage/candidacyBulkActionsSlice';
import candidacyAssessmentsSlice from 'modules/candidacy-assessments/candidacyAssessmentsSlice';
import commentsReducer from 'modules/comments/reducers/commentsReducer';
import companiesReducer from 'modules/companies/reducers/companiesReducer';
import companiesFundingRoundsReducer from 'modules/company-funding-rounds/reducers/companiesFundingRoundsReducer';
import companyEmployeeCountsReducer from 'modules/company-employee-counts/reducers/companyEmployeeCountsReducer';
import companyInvestmentsReducer from 'modules/company-investments/reducers/companyInvestmentsReducer';
import companyRevenuesReducer from 'modules/company-revenues/reducers/companyRevenuesReducer';
import companySubsidiaryCompaniesReducer from 'modules/subsidiary-companies/subsidiaryCompaniesSlice';
import configureColumnsSlice from 'modules/configure-columns/configureColumnsSlice';
import contactsNewReducer from 'modules/contacts/reducers/contactsNewReducer';
import datetimeReducer from 'modules/datetime/reducers/datetimeReducer';
import documentsReducer from 'modules/documents/reducers/documentsReducer';
import duplicateMergeSlice from 'modules/duplicate-merge/duplicateMergeSlice';
import emailTemplatesReducer from 'modules/email-templates/reducers/emailTemplatesReducer';
import entitiesReducer from 'modules/entities/entitiesReducer';
import fundsReducer from 'modules/funds/fundsReducer';
import interviewsReducer from 'modules/interviews/reducers/interviewsReducer';
import introductionsReducer from 'modules/introductions/reducers/introductionsReducer';
import jobApplicationsReducer from 'modules/job-applications/reducers/jobApplicationsReducer';
import locationsReducer from 'modules/locations/reducers/locationsReducer';
import meetingsReducer from 'modules/meetings/reducers/meetingsReducer';
import networksReducer from 'modules/networks/reducers/networksReducer';
import notesReducer from 'modules/notes/reducers/notesReducer';
import outreachesReducer from 'modules/outreaches/reducers/outreachesReducer';
import pipelineSlice from 'modules/pipelines/pipelineSlice';
import quickViewSlice from 'modules/quick-view/quickViewSlice';
import recommendationsReducer from 'modules/recommendations/reducers/recommendationsReducer';
import recordIndexSlice from 'modules/recordIndex/recordIndexSlice';
import referencesReducer from 'modules/references/reducers/referencesReducer';
import residenciesReducer from 'modules/residencies/reducers/residenciesReducer';
import routingReducer from 'modules/routing/routingReducer';
import searchesReducer from 'modules/searches/reducers/searchesReducer';
import searchRequestSlice from 'modules/search-requests/searchRequestSlice';
import searchSlice from 'modules/searches/searchSlice';
import searchStagesReducer from 'modules/search-stages/reducers/searchStagesReducer';
import tagsReducer from 'modules/tags/reducers/tagsReducer';
import smartTagsReducer from 'modules/tags/reducers/smartTagsReducer';
import talentPoolsReducer from 'modules/talent-pools/reducers/talentPoolsReducer';
import targetCompaniesReducer from 'modules/target-companies/reducers/targetCompaniesReducer';
import tasksReducer from 'modules/tasks/tasksReducer';
import tenantReducer from 'modules/tenant/reducers/tenantReducer';
import toastNotificationsSlice from 'modules/toast-notifications/toastNotificationsSlice';
import transactionsReducer from 'modules/transactions/transactionsReducer';
import userReducer from 'modules/user/reducers/userReducer';
import usersReducer from 'modules/users/usersReducer';
// Actors: Candidacies
import invalidateCandidacyAssessmentActor from 'modules/candidacies/actors/invalidateCandidacyAssessmentActor';
import invalidateCandidacyCommentActor from 'modules/candidacies/actors/invalidateCandidacyCommentActor';
import updateCandidacyAssessmentCountActor from 'modules/candidacies/actors/updateCandidacyAssessmentCountActor';
import updateCandidacyRecruiterAssessmentActor from 'modules/candidacies/actors/updateCandidacyRecruiterAssessmentActor';
// Actors: Companies
import initializePortfolioCompaniesActor from 'modules/companies/actors/initializePortfolioCompaniesActor';
import updateCompanyPrimaryAddressIdActor from 'modules/companies/actors/updateCompanyPrimaryAddressIdActor';
import updateCompanyEmployeeCountsActor from 'modules/company-employee-counts/actors/updateCompanyEmployeeCountsActor';
import updateCompanyRevenuesActor from 'modules/company-revenues/actors/updateCompanyRevenuesActor';
// Actors: Contacts
import updateContactPrimaryPositionActor from 'modules/contacts/actors/updateContactPrimaryPositionActor';

import pendingContactsReducer from 'modules/pending-contacts/pendingContactsSlice';
// Legacy reducers that need to be converted to modules or removed
import contactReviewsReducer from './contactReviews/contactReviewsReducer';
import contactsReducer from './contacts/contactsReducer';
import dashboardReducer from './dashboard/dashboardReducer';
// This doesn't follow reducer naming conventions... but the folder is a minefield so leaving as-is
import notificationsReducer from './notifications/byCategory';
import emailSignatureReducer from '../modules/emailSignature/reducer/emailSignatureReducer';

const rootReducer = combineReducers({
  [apiV1.reducerPath]: apiV1.reducer,
  [activitiesReducer.NAME]: activitiesReducer,
  [addressesReducer.NAME]: addressesReducer,
  addToNetwork: addToNetworkSlice.reducer,
  addToSearch: addToSearchSlice.reducer,
  [assessmentsReducer.NAME]: assessmentsReducer,
  [bulkImportsReducer.NAME]: bulkImportsReducer,
  [emailSignatureReducer.NAME]: emailSignatureReducer,
  [candidaciesReducer.NAME]: candidaciesReducer,
  candidacyAssessments: candidacyAssessmentsSlice.reducer,
  candidacyBulkActions: candidacyBulkActionsReducer,
  [commentsReducer.NAME]: commentsReducer,
  [companiesReducer.NAME]: companiesReducer,
  [companyEmployeeCountsReducer.NAME]: companyEmployeeCountsReducer,
  [companiesFundingRoundsReducer.NAME]: companiesFundingRoundsReducer,
  [companyInvestmentsReducer.NAME]: companyInvestmentsReducer,
  [companyRevenuesReducer.NAME]: companyRevenuesReducer,
  configureColumns: configureColumnsSlice.reducer,
  contactReviews: contactReviewsReducer,
  contacts: contactsReducer,
  [contactsNewReducer.NAME]: contactsNewReducer,
  dashboard: dashboardReducer,
  [datetimeReducer.NAME]: datetimeReducer,
  [documentsReducer.NAME]: documentsReducer,
  duplicateMerge: duplicateMergeSlice.reducer,
  [emailTemplatesReducer.NAME]: emailTemplatesReducer,
  [entitiesReducer.NAME]: entitiesReducer,
  [fundsReducer.NAME]: fundsReducer,
  [interviewsReducer.NAME]: interviewsReducer,
  [introductionsReducer.NAME]: introductionsReducer,
  [jobApplicationsReducer.NAME]: jobApplicationsReducer,
  // TODO: Remove this after removing it from application_helper.rb
  // This just prevents redux from complaining about unknown keys when
  // configureStore is called with an initial state containing keys
  // that don't mapt to a key in the root combineReducers call.
  lang: lang => lang || {},
  [locationsReducer.NAME]: locationsReducer,
  [meetingsReducer.NAME]: meetingsReducer,
  [networksReducer.NAME]: networksReducer,
  [notesReducer.NAME]: notesReducer,
  notifications: notificationsReducer,
  [outreachesReducer.NAME]: outreachesReducer,
  pendingContacts: pendingContactsReducer,
  pipeline: pipelineSlice.reducer,
  quickView: quickViewSlice.reducer,
  [recommendationsReducer.NAME]: recommendationsReducer,
  recordIndex: recordIndexSlice.reducer,
  [referencesReducer.NAME]: referencesReducer,
  [residenciesReducer.NAME]: residenciesReducer,
  [routingReducer.NAME]: routingReducer,
  search: searchSlice.reducer,
  [searchesReducer.NAME]: searchesReducer,
  searchRequest: searchRequestSlice.reducer,
  [searchStagesReducer.NAME]: searchStagesReducer,
  subsidiaryCompanies: companySubsidiaryCompaniesReducer.reducer,
  [smartTagsReducer.NAME]: smartTagsReducer,
  [tagsReducer.NAME]: tagsReducer,
  [talentPoolsReducer.NAME]: talentPoolsReducer,
  [targetCompaniesReducer.NAME]: targetCompaniesReducer,
  [tasksReducer.NAME]: tasksReducer,
  [tenantReducer.NAME]: tenantReducer,
  toastNotifications: toastNotificationsSlice.reducer,
  [transactionsReducer.NAME]: transactionsReducer,
  [userReducer.NAME]: userReducer,
  [usersReducer.NAME]: usersReducer,
});

/**
 * Actors are called any time an action is dispatched. They can dispatch additional actions,
 * but cannot directly modify state (like reducers)
 * NOTE: This is a bad pattern because it creates global/invisible Redux side effects.
 *  Please do not create more of these without further discussion.
 */
rootReducer.actors = [
  // Candidacies
  invalidateCandidacyAssessmentActor,
  invalidateCandidacyCommentActor,
  updateCandidacyAssessmentCountActor,
  updateCandidacyRecruiterAssessmentActor,
  // Companies
  initializePortfolioCompaniesActor,
  updateCompanyPrimaryAddressIdActor,
  updateCompanyEmployeeCountsActor,
  updateCompanyRevenuesActor,
  // Contacts
  updateContactPrimaryPositionActor,
];

export default rootReducer;
