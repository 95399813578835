import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import ContactPosition from './ContactPosition';
import getPrimaryPositionId from '../../selectors/contacts/getPrimaryPositionId';

/**
 * Renders a ContactPosition for a contact's primary position.
 */
export default compose(
  setDisplayName('ContactPrimaryPosition'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  connect(
    (state, { contactId }) => ({
      positionId: getPrimaryPositionId(state, contactId),
    }),
    {},
  ),
  branch(({ positionId }) => !positionId, renderNothing),
)(ContactPosition);
