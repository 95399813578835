import getEntity from 'modules/entities/selectors/getEntity';
import compensationSchema from '../schema';

/**
 * Gets a compensation record by it's ID
 * @param {Object} state
 * @param {Number} id The compensation record ID
 * @return {Immutable.Map|undefined} The compensation record, if found.
 */
export default (state, id) => getEntity(state, compensationSchema.key, id);
