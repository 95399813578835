import getEntity from 'modules/entities/selectors/getEntity';
import positionSchema from '../schema';

/**
 * Gets a position record by it's ID
 * @param {Object} state
 * @param {Number} positionId The position ID
 * @return {Immutable.Map|undefined} The position record, if found
 */
export default (state, positionId) =>
  getEntity(state, positionSchema.key, positionId);
