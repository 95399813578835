import React from 'react';
import PropTypes from 'prop-types';
import useFormValues from '@thrivetrm/ui/hooks/useFormValues';
import Card from '@thrivetrm/ui/components/Card';
import SkyminyrCompanySelect from './SkyminyrCompanySelect';

const SkyminyrCompanySelectForm = ({
  actionConfirmationText,
  canCreateNewCompany,
  canSelectThriveRecords,
  companySearchGuidance,
  initialInputValue,
  onCreate,
  queryParams,
  shouldNavigateToNewCompany,
}) => {
  const formValues = useFormValues();
  return (
    <>
      {formValues.company?.data?.skyminyr_id && actionConfirmationText ? (
        <Card
          className='u-flex u-marginBottom-8 u-border-n'
          isCentered={false}
          type='general'
        >
          {actionConfirmationText}
        </Card>
      ) : null}
      <SkyminyrCompanySelect
        canCreateNewCompany={canCreateNewCompany}
        canSelectThriveRecords={canSelectThriveRecords}
        initialInputValue={initialInputValue}
        onCreate={onCreate}
        queryParams={queryParams}
        shouldNavigateToNewCompany={shouldNavigateToNewCompany}
      />
      {formValues.company?.value ? null : (
        <>
          <div className='u-flex u-flexJustify-c'>
            <img
              alt=''
              height='200px'
              src='/search_records.svg'
              width='200px'
            />
          </div>
          {companySearchGuidance}
        </>
      )}
    </>
  );
};
SkyminyrCompanySelectForm.defaultProps = {
  actionConfirmationText: null,
  canCreateNewCompany: false,
  canSelectThriveRecords: false,
  companySearchGuidance: null,
};
SkyminyrCompanySelectForm.propTypes = {
  actionConfirmationText: PropTypes.node,
  canCreateNewCompany: PropTypes.bool,
  canSelectThriveRecords: PropTypes.bool,
  companySearchGuidance: PropTypes.node,
  initialInputValue: PropTypes.string,
  onCreate: PropTypes.func,
  queryParams: PropTypes.shape({
    resource: PropTypes.string,
  }),
  shouldNavigateToNewCompany: PropTypes.bool,
};

export default SkyminyrCompanySelectForm;
