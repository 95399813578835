import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import ExternalLinkIndicator from './ExternalLinkIndicator';

/**
 * Renders a link to an external URL.
 * Set's `target='_blank'` by default (though this can be overriden), and
 * ensures that `rel='noopener noreferrer'` is always set (this can NOT be overriden)
 */
const ExternalLink = ({ children, className, indicator, ...props }) => (
  <a
    className={classnames('ExternalLink', className)}
    target='_blank'
    {...props}
    rel='noopener noreferrer'
  >
    {indicator === 'before' && <ExternalLinkIndicator />}
    {children}
    {(indicator === true || indicator === 'after') && <ExternalLinkIndicator />}
  </a>
);

ExternalLink.propTypes = {
  /**
   * The content to display inside the link element.
   */
  children: PropTypes.node,

  /**
   * Additional classnames to apply to the link.
   */
  className: PropTypes.string,

  /**
   * Whether and where to render the indicator icon:
   * * false - do not render
   * * "before" - render before the children
   * * true or "after" - render after the children
   */
  indicator: PropTypes.oneOf([true, false, 'before', 'after']),
};

ExternalLink.defaultProps = {
  indicator: false,
};

export default ExternalLink;
