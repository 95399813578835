/* eslint-disable import/no-import-module-exports */
// The linter is complaining that this file is mixing ESM import and CommonJS exports, which it is not
// There's nothing to fix, so disabling the rule in this file.
import Immutable, { fromJS } from 'immutable';
import createHistory from 'history/createBrowserHistory';
import { configureStore } from '@reduxjs/toolkit';
import {
  routerMiddleware,
  LOCATION_CHANGE,
} from 'connected-react-router/immutable';
import apiV1 from 'services/apiV1';

import rootReducer from './reducers/rootReducer';
import actors from './middleware/actors';

const isDevelopment = process.env.NODE_ENV !== 'production';

const getServerState = () => {
  const serverState = window.thv;
  return serverState
    ? {
        ...serverState,
        user: {
          ...serverState.user,
          userOptions: fromJS({
            data: serverState.user?.userOptions,
          }),
        },
      }
    : {};
};

const history = createHistory();

const middleware = getDefaultMiddleware => {
  return getDefaultMiddleware({
    thunk: true,
    immutableCheck: false,
    serializableCheck: false,
  })
    .concat(routerMiddleware(history))
    .concat(actors(rootReducer.actors))
    .concat(apiV1.middleware);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: middleware,
  devTools: isDevelopment
    ? { trace: true, serialize: { immutable: Immutable } }
    : false,
  preloadedState: getServerState(),
});

history.listen(location =>
  store.dispatch({
    type: LOCATION_CHANGE,
    payload: location,
  }),
);

// Populate store with location on page load
store.dispatch({
  type: LOCATION_CHANGE,
  payload: history.location,
});

if (module.hot) {
  module.hot.accept('./reducers/rootReducer', () =>
    store.replaceReducer(rootReducer),
  );
}

export { getServerState, history, middleware };
export default store;
