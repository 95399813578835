import getContact from './getContact';

/**
 * Gets a value indicating whether a contact should be fetched based on the current state.
 * @param {Object} state
 * @param {Number} id The contact's ID
 * @param {Object} options
 * @param {Boolean} isFullContactNeeded When true, if the "full" contact hasn't been fetched,
 *   (or isn't being fetched), this will return true.
 * @returns {Boolean} True if the contact should be fetched from the server; false otherwise.
 */
const shouldFetchContact = (state, id, options = {}) => {
  const contact = getContact(state, id);

  if (!contact) {
    return true;
  }

  if (contact.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (contact.hasIn(['_meta', 'error'])) {
    return false;
  }

  // When the "full" contact is fetched (from a CONTACT_FETCH_SUCCESS),
  // the `byId` reducer's metadata reducer will add a `lastFetched` timestamp.
  // If that doesn't exist, we haven't fetched the full contact yet.
  if (options.requireFull && !contact.getIn(['_meta', 'lastFetched'])) {
    return true;
  }

  return Boolean(contact.getIn(['_meta', 'isInvalidated']));
};

export default shouldFetchContact;
