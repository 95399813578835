import { connect } from 'react-redux';
import getCandidacy from '../selectors/getCandidacy';

/**
 * A higher order component which maps a `candidacyId` prop value to it's candidacy record as
 * a `candidacy` prop.`
 */
export default connect(
  (state, { candidacyId }) => ({
    candidacy: getCandidacy(state, candidacyId),
  }),
  {},
);
