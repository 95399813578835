import React from 'react';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { Entity } from 'draft-js';

const ENTITY_TYPE = 'IMAGE';
const BLOCK_TYPE = 'atomic';

const toHtmlConverter = convertToHTML({
  blockToHTML: {
    paragraph: {
      start: '<p>',
      end: '</p>',
      empty: '<br />',
    },
    unstyled: {
      start: '',
      end: '<br/>',
      empty: '<br/>',
    },
    blockquote: {
      start: '<blockquote>',
      end: '</blockquote>',
    },
    atomic: {
      start: '<figure>',
      end: '</figure>',
    },
  },
  entityToHTML: (entity, originalText) => {
    if (entity.type === 'LINK') {
      const { url } = entity.data;
      const { target } = entity.data;
      return (
        <a href={url} target={target}>
          {originalText}
        </a>
      );
    } else if (entity.type === 'IMAGE') {
      const { src } = entity.data;
      return <img alt='Email-signature' src={src} />;
    }
    return originalText;
  },
  styleToHTML: {
    STRIKETHROUGH: {
      start: '<del>',
      end: '</del>',
    },
  },
});

const fromHtmlConverter = convertFromHTML({
  htmlToBlock: (nodeName, node, inBlock) => {
    if (
      (nodeName === 'figure' && node?.firstChild?.nodeName === 'IMG') ||
      (nodeName === 'img' && inBlock !== BLOCK_TYPE)
    ) {
      return BLOCK_TYPE;
    }
    return null;
  },
  htmlToEntity: (nodeName, node, createEntity) => {
    if (nodeName === 'a') {
      return createEntity('LINK', 'MUTABLE', {
        url: node?.href,
        target: '_blank',
      });
    } else if (nodeName === 'img') {
      const src = node.getAttribute('src');
      if (src) {
        return createEntity('IMAGE', 'IMMUTABLE', { src: src });
      }
    }
    return null;
  },
  blockRendererFn: block => {
    if (
      block.getType() === BLOCK_TYPE &&
      block.getLength() > 0 &&
      Entity.get(block.getEntityAt(0)).getType() === ENTITY_TYPE
    ) {
      return {
        component: ({ blockParam }) => {
          const entity = Entity.get(blockParam.getEntityAt(0));
          const { src } = entity.getData();
          return <img alt='Email-signature' src={src} />;
        },
        editable: false,
      };
    }
    return null;
  },
});

export function fromHtml(html) {
  return fromHtmlConverter(`${html}`);
}

export function toHtml(contentState) {
  let hasImage = false;

  contentState.getBlockMap().forEach(block => {
    const type = block.getType();
    if (type === 'atomic') {
      const entityKey = block.getEntityAt(0);
      if (entityKey) {
        const entity = contentState.getEntity(entityKey);
        const entityType = entity.getType();
        if (entityType === 'IMAGE') {
          // Found an image block
          hasImage = true;
        }
      }
    }
    return null;
  });

  if (hasImage) {
    return toHtmlConverter(contentState);
  }

  // Check to see if there is actually any *text* content. There's always going to be at least
  // one unstyled content block in the editor, so we may end up with just a single "<br>" tag,
  // so we can't reliably check the length after we convert to html.
  if (!contentState.hasText()) {
    return '';
  }

  // But it's also possible the user hit enter a few times, or added a few spaces, in which case
  // we want to pretend that's nothing, too.
  if (!contentState.getPlainText('').trim()) {
    return '';
  }

  return toHtmlConverter(contentState);
}
