import selectn from 'selectn';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import isEntityType from 'modules/entities/isEntityType';
import positionSchema from 'modules/positions/schema';
import getPositionProperty from 'modules/positions/selectors/getPositionProperty';
import getContact from '../selectors/contacts/getContact';
import updatePrimaryPosition from '../actions/contacts/updatePrimaryPosition';

/**
 * Acts on position creates/deletes/updates to update the underlying contact depending on the
 * "primary" flag for the position and it's contact.
 */
export default ({ action, dispatch, getState }) => {
  if (isEntityType(action, positionSchema.key)) {
    switch (action.type) {
      case entityActionTypes.CREATE_SUCCESS:
      case entityActionTypes.UPDATE_SUCCESS:
      case entityActionTypes.DELETE_SUCCESS: {
        const isDelete = action.type === entityActionTypes.DELETE_SUCCESS;
        const positionId = selectn(
          isDelete ? 'payload.id' : 'payload.result.position',
          action,
        );
        const state = getState();
        const contactId = getPositionProperty(state, positionId, 'contact_id');
        const contact = getContact(state, contactId);

        if (contact) {
          // If the contact entity is loaded, we may need to update it's primary_position value.
          const primaryPositionId = contact.get('primary_position');
          const isPrimary =
            !isDelete && getPositionProperty(state, positionId, 'primary');

          if (isPrimary && primaryPositionId !== positionId) {
            // We need to update the contact record to reflect this position as the primary position
            dispatch(
              updatePrimaryPosition({
                contactId: contactId,
                positionId: positionId,
              }),
            );
          } else if (!isPrimary && primaryPositionId === positionId) {
            // We need to update the contact record to reflect this position as no longer being
            // the primary position
            dispatch(
              updatePrimaryPosition({ contactId: contactId, positionId: null }),
            );
          }
        }
        break;
      }
      default: {
        // noop
      }
    }
  }
};
