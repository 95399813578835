import createListReducer from '../../../reducers/createListReducer';
import locationSchema from '../schema';
import {
  LOCATIONS_LIST_FETCH_START,
  LOCATIONS_LIST_FETCH_SUCCESS,
  LOCATIONS_LIST_FETCH_FAILURE,
} from '../actions/actionTypes';
import { NAME } from '../constants';

/**
 * A reducer that maintains a list of all locations for the current tenant.
 */
const locationsReducer = createListReducer({
  resultKey: locationSchema.key,
  request: LOCATIONS_LIST_FETCH_START,
  success: LOCATIONS_LIST_FETCH_SUCCESS,
  failure: LOCATIONS_LIST_FETCH_FAILURE,
});

locationsReducer.NAME = NAME;

export default locationsReducer;
