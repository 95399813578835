import selectn from 'selectn';
import { Map } from 'immutable';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import createListReducer from '../../../reducers/createListReducer';
import {
  COMMENT_LIST_INVALIDATE,
  COMMENT_LIST_FETCH_START,
  COMMENT_LIST_FETCH_SUCCESS,
  COMMENT_LIST_FETCH_FAILURE,
} from '../actions/actionTypes';
import { PARENT_CANDIDACY, PARENT_CONTACT, PARENT_COMPANY } from '../constants';
import commentSchema from '../commentSchema';

const commentListReducer = createListReducer({
  entityResultKey: 'comment',
  request: COMMENT_LIST_FETCH_START,
  success: COMMENT_LIST_FETCH_SUCCESS,
  failure: COMMENT_LIST_FETCH_FAILURE,
  invalidated: COMMENT_LIST_INVALIDATE,
  deleted: entityActionTypes.DELETE_SUCCESS,
});

// Comments is keyed on the parent type (PARENT_CANDIDACY or PARENT_CONTACT),
// then by that parent's ID (a contact ID for PARENT_CONTACT or a candidacy ID for
// PARENT_CANDIDACY).
export default (state = new Map(), action) => {
  switch (action.type) {
    case COMMENT_LIST_FETCH_START:
    case COMMENT_LIST_FETCH_SUCCESS:
    case COMMENT_LIST_FETCH_FAILURE: {
      const parentType = selectn('payload.parentType', action);
      const parentId = selectn('payload.parentId', action);

      if (parentType && parentId) {
        return state.updateIn([parentType, parentId], list =>
          commentListReducer(list, action),
        );
      }

      return state;
    }
    case COMMENT_LIST_INVALIDATE: {
      const contactId = selectn('payload.contactId', action);
      const candidacyId = selectn('payload.candidacyId', action);
      const companyId = selectn('payload.companyId', action);

      let result = state;

      if (contactId && state.hasIn([PARENT_CONTACT, contactId])) {
        result = result.setIn(
          [PARENT_CONTACT, contactId, '_meta', 'isInvalidated'],
          true,
        );
      }

      if (candidacyId && state.hasIn([PARENT_CANDIDACY, candidacyId])) {
        result = result.setIn(
          [PARENT_CANDIDACY, candidacyId, '_meta', 'isInvalidated'],
          true,
        );
      }

      if (companyId && state.hasIn([PARENT_COMPANY, companyId])) {
        result = result.setIn(
          [PARENT_COMPANY, companyId, '_meta', 'isInvalidated'],
          true,
        );
      }

      return result;
    }
    case entityActionTypes.CREATE_SUCCESS: {
      if (selectn('payload.entityType', action) !== commentSchema.key) {
        return state;
      }

      // Check if the newly created comment belongs to any currently fetched lists.
      const commentId = selectn('payload.result', action);
      const comment = selectn(`payload.entities.comments.${commentId}`, action);

      if (comment && !comment.parent_comment_id) {
        return state.withMutations(mutable => {
          if (
            comment.contact_id &&
            mutable.hasIn([PARENT_CONTACT, comment.contact_id, 'ids'])
          ) {
            mutable.updateIn([PARENT_CONTACT, comment.contact_id, 'ids'], ids =>
              ids.push(commentId),
            );
          }

          if (
            comment.company_id &&
            mutable.hasIn([PARENT_COMPANY, comment.company_id, 'ids'])
          ) {
            mutable.updateIn([PARENT_COMPANY, comment.company_id, 'ids'], ids =>
              ids.push(commentId),
            );
          }

          if (
            comment.candidacy_id &&
            mutable.hasIn([PARENT_CANDIDACY, comment.candidacy_id, 'ids'])
          ) {
            mutable.updateIn(
              [PARENT_CANDIDACY, comment.candidacy_id, 'ids'],
              ids => ids.push(commentId),
            );
          }
        });
      }

      return state;
    }
    case entityActionTypes.DELETE_SUCCESS: {
      if (selectn('payload.entityType', action) !== commentSchema.key) {
        return state;
      }

      return state.map(parentTypeState =>
        parentTypeState.map(listState => commentListReducer(listState, action)),
      );
    }
    default: {
      return state.map(parentTypeState =>
        parentTypeState.map(listState => commentListReducer(listState, action)),
      );
    }
  }
};
