import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as actions from '../actions';

/**
 * A higher order component that connects the contact module
 * actions and provides them as a `contactActions` prop.
 */
export default connect(null, dispatch => ({
  contactActions: bindActionCreators(actions, dispatch),
}));
