import selectn from 'selectn';
import { Map } from 'immutable';
import createListReducer from '../../../reducers/createListReducer';
import {
  INTERVIEW_LIST_INVALIDATE,
  INTERVIEW_LIST_FETCH_START,
  INTERVIEW_LIST_FETCH_SUCCESS,
  INTERVIEW_LIST_FETCH_FAILURE,
  INTERVIEW_CREATE_SUCCESS,
  INTERVIEW_UPDATE_SUCCESS,
  INTERVIEW_DELETE_SUCCESS,
} from '../actions/actionTypes';
import { DATE_GROUPS } from '../constants';

const filteredInterviewsListReducer = createListReducer({
  resultKey: 'interviews',
  entityResultKey: 'interview',
  request: INTERVIEW_LIST_FETCH_START,
  success: INTERVIEW_LIST_FETCH_SUCCESS,
  failure: INTERVIEW_LIST_FETCH_FAILURE,
  invalidated: INTERVIEW_LIST_INVALIDATE,
  created: INTERVIEW_CREATE_SUCCESS,
  updated: INTERVIEW_UPDATE_SUCCESS,
  deleted: INTERVIEW_DELETE_SUCCESS,
  invalidateOnCreated: true,
  invalidateOnUpdated: true,
  invalidateOnDeleted: true,
});

/**
 * This reducer is used for managing a small subset of interviews. For the search sidebar
 * we display the 3 most recent past interviews, and next 3 upcoming interviews. In these cases,
 * when an interview record is created, updated, or deleted, we can't reliably keep up our list
 * up-to-date without refetching from the server -- so we invalidate the list instead an just
 * refetch.
 *
 * While similar to `interviewsByParentType`, there is an additional nesting level here,
 * which is one of the DATE_GROUPS values. For example, the 3 most recent interviews for the
 * search with ID 42 would be found at `filteredInterviewsByParentType.search.42.past`.
 */
export default (state = new Map(), action) => {
  switch (action.type) {
    case INTERVIEW_LIST_FETCH_START:
    case INTERVIEW_LIST_FETCH_SUCCESS:
    case INTERVIEW_LIST_FETCH_FAILURE:
    case INTERVIEW_LIST_INVALIDATE: {
      const parentType = selectn('payload.parentType', action);
      const parentId = selectn('payload.parentId', action);
      const filter = selectn('payload.filter', action);

      if (parentType && parentId && filter && DATE_GROUPS.includes(filter)) {
        return state.updateIn([parentType, parentId, filter], list =>
          filteredInterviewsListReducer(list, action),
        );
      }

      return state;
    }
    default: {
      return state.map(
        parentTypeState =>
          parentTypeState &&
          parentTypeState.map(
            parentIdState =>
              parentIdState &&
              parentIdState.map(listState =>
                filteredInterviewsListReducer(listState, action),
              ),
          ),
      );
    }
  }
};
