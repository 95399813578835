import { NAME } from '../constants';

export const INVALIDATE = `${NAME}/INVALIDATE`;

export const FETCH_START = `${NAME}/FETCH_START`;
export const FETCH_SUCCESS = `${NAME}/FETCH_SUCCESS`;
export const FETCH_FAILURE = `${NAME}/FETCH_FAILURE`;

export const CREATE_START = `${NAME}/CREATE_START`;
export const CREATE_SUCCESS = `${NAME}/CREATE_SUCCESS`;
export const CREATE_FAILURE = `${NAME}/CREATE_FAILURE`;

export const UPDATE_START = `${NAME}/UPDATE_START`;
export const UPDATE_SUCCESS = `${NAME}/UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${NAME}/UPDATE_FAILURE`;

export const DELETE_START = `${NAME}/DELETE_START`;
export const DELETE_SUCCESS = `${NAME}/DELETE_SUCCESS`;
export const DELETE_FAILURE = `${NAME}/DELETE_FAILURE`;

export const DELETE_MULTIPLE_START = `${NAME}/DELETE_MULTIPLE_START`;
export const DELETE_MULTIPLE_SUCCESS = `${NAME}/DELETE_MULTIPLE_SUCCESS`;
export const DELETE_MULTIPLE_FAILURE = `${NAME}/DELETE_MULTIPLE_FAILURE`;

export const MERGE = `${NAME}/MERGE`;
