import deleteEntity from 'modules/entities/actions/deleteEntity';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import routes from 'modules/routing/routes';
import commentSchema from '../commentSchema';
import getComment from '../selectors/getComment';
import childCommentDeleted from './childCommentDeleted';

/**
 * An action for deleting a comment
 * @param {Object} payload
 * @param {Number} payload.id The ID of the comment to delete
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({ id, transactionId }) => (dispatch, getState) => {
  const comment = getComment(getState(), id);
  const parentId = comment && comment.get('parent_comment_id');

  dispatch(
    deleteEntity({
      transactionId: transactionId,
      id: id,
      entityType: commentSchema.key,
      url: routes.api_comment({ id: id }),
    }),
  ).then(action => {
    if (action.type === entityActionTypes.DELETE_SUCCESS) {
      // When a comment is deleted that has a parent comment we need to
      // remove its ID from that parent comment's list of child_comment IDs.
      if (parentId) {
        dispatch(childCommentDeleted({ id: id, parentId: parentId }));
      }
    }

    return action;
  });
};
