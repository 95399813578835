import updateEntity from 'modules/entities/actions/updateEntity';
import routes from 'modules/routing/routes';
import commentSchema from '../commentSchema';

const entityType = commentSchema.key;

/**
 * Creates an action for updating a comment
 * @param {Object} payload
 * @param {Object} payload.comment The comment to update
 * @param {Number} payload.comment.id The ID of the comment to update
 * @param {String} [payload.transactionId] A unique ID used to track the request.
 */
export default ({ comment, transactionId }) => dispatch => {
  dispatch(
    updateEntity({
      body: { comment: comment },
      transactionId: transactionId,
      entityType: entityType,
      responseSchema: commentSchema,
      url: routes.api_comment({ id: comment.id }),
    }),
  );
};
