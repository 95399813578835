import { connect } from 'react-redux';
import getFullName from '../selectors/contacts/getFullName';

/**
 * A higher order component which maps a `contactId` prop value to the contact's
 * full name, passing it in as the `fullName` prop.
 */
export default connect(
  (state, { contactId }) => ({
    fullName: getFullName(state, contactId),
  }),
  {},
);
