import { schema } from 'normalizr';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import companySchema from 'modules/companies/companySchema';
import compensationSchema from 'modules/compensations/schema';
import locationSchema from 'modules/locations/schema';
import positionSchema from 'modules/positions/schema';
import citizenshipSchema from 'modules/tenant/schemas/citizenships';
import companyInvestmentStageSchema from 'modules/tenant/schemas/companyInvestmentStages';
import contactLevelSchema from 'modules/tenant/schemas/contactLevels';
import contactAliasSchema from 'modules/tenant/schemas/contactAliases';
import contactSourceSchema from 'modules/tenant/schemas/contactSources';
import disabilityStatusSchema from 'modules/tenant/schemas/disabilityStatuses';
import genderSchema from 'modules/tenant/schemas/genders';
import industrySchema from 'modules/tenant/schemas/industries';
import interactionLevelSchema from 'modules/tenant/schemas/interactionLevels';
import jobFunctionSchema from 'modules/tenant/schemas/jobFunctions';
import languageSchema from 'modules/tenant/schemas/languages';
import pronounSchema from 'modules/tenant/schemas/pronouns';
import raceEthnicitySchema from 'modules/tenant/schemas/raceEthnicities';
import sectorSchema from 'modules/tenant/schemas/sectors';
import seekingJobReasonSchema from 'modules/tenant/schemas/seekingJobReasons';
import sexualOrientationSchema from 'modules/tenant/schemas/sexualOrientations';
import skillSchema from 'modules/tenant/schemas/skills';
import veteranStatusSchema from 'modules/tenant/schemas/veteranStatuses';
import userSchema from 'modules/users/schema';
import demographicSources from 'modules/tenant/schemas/demographicSources';
import positionFunctionSchema from 'modules/tenant/schemas/positionFunctions';
import productAreaSchema from 'modules/tenant/schemas/productAreas';

export const connectionsSchema = new schema.Entity('connections');
export const educationSchema = new schema.Entity('educations');

export const ratingSchema = new schema.Entity('ratings', {
  user: userSchema,
  job_function: jobFunctionSchema,
});

export const ratingDefinitionSchema = new schema.Entity('ratingDefinitions');

export const inviteSchema = new schema.Entity(
  'invites',
  {},
  {
    // The invite ID should use the contact ID.
    idAttribute: 'contact_id',
  },
);

export const contactSchema = new schema.Entity(
  'contacts',
  {
    aliases: [contactAliasSchema],
    board_member_compensation: compensationSchema,
    citizenships: [citizenshipSchema],
    connections: [connectionsSchema],
    companies: [companySchema],
    contact_current_interim_placement: compensationSchema,
    contact_desired_interim_placement: compensationSchema,
    contact_source: contactSourceSchema,
    creator: userSchema,
    disability_status: disabilityStatusSchema,
    educations: [educationSchema],
    employee_compensation: compensationSchema,
    gender: genderSchema,
    interaction_level: interactionLevelSchema,
    languages: [languageSchema],
    level: contactLevelSchema,
    available_talent_function: positionFunctionSchema,
    locations: [locationSchema],
    positions: [positionSchema],
    preferred_board_member_company_stages: [companyInvestmentStageSchema],
    preferred_company_stages: [companyInvestmentStageSchema],
    preferred_industries: [industrySchema],
    preferred_investor_company_stages: [companyInvestmentStageSchema],
    preferred_job_functions: [jobFunctionSchema],
    preferred_portfolio_companies: [companySchema],
    preferred_product_areas: [productAreaSchema],
    preferred_sectors: [sectorSchema],
    previous_company_position: positionSchema,
    primary_company: companySchema,
    primary_position: positionSchema,
    pronouns: [pronounSchema],
    race_ethnicities: [raceEthnicitySchema],
    gender_sources: [demographicSources],
    race_ethnicity_sources: [demographicSources],
    veteran_status_sources: [demographicSources],
    ratings: [ratingSchema],
    referred_by_firm: companySchema,
    relationship_manager: userSchema,
    seeking_job_reason: seekingJobReasonSchema,
    sexual_orientation: sexualOrientationSchema,
    skills: [skillSchema],
    veteran_status: veteranStatusSchema,
  },
  {
    // eslint-disable-next-line camelcase
    processStrategy: ({
      primary_company_id,
      primary_company_name,
      ...contact
    }) => ({
      ...contact,
      // TODO: This information should be pulled from primary_position instead.
      // Having it on the contact is deprecated

      // Unflatted `primary_company_id` and `primary_company_name` into a company-like object, if
      // this is a company (and not simply a name)
      /* eslint-disable camelcase */
      primary_company: primary_company_id
        ? {
            id: primary_company_id,
            name: primary_company_name,
          }
        : null,
      primary_company_name: primary_company_name,
      /* eslint-enable camelcase */
    }),
  },
);

contactSchema.define({
  direct_reports: [contactSchema],
  referred_by: contactSchema,
  reports_to: contactSchema,
});

ratingSchema.define({
  contact: contactSchema,
  attributed_to: contactSchema,
});

// NOTE: This probably should get moved into it's own module when contact reviews are refactored
// and normalized.
export const contactReviewSchema = new schema.Entity(
  'contactReviews',
  {
    applied: {
      aliases: [contactAliasSchema],
      connections: [connectionsSchema],
      pronouns: [pronounSchema],
      positions: [positionSchema],
      race_ethnicities: [raceEthnicitySchema],
      gender_sources: [demographicSources],
      race_ethnicity_sources: [demographicSources],
      veteran_status_sources: [demographicSources],
      educations: [educationSchema],
    },
  },
  {
    processStrategy: function ({
      applied: { educations, positions, ...applied } = {},
      ...values
    }) {
      // This generates unique IDs for any positions that do not have them.
      return {
        ...values,
        applied: {
          ...applied,
          positions:
            positions &&
            positions.map(position => ({
              ...position,
              id: position.id || uniqueId('NEW'),
              duplicate_positions: position.duplicate_positions
                ? position.duplicate_positions.map(duplicatePosition => ({
                    ...duplicatePosition,
                    id: duplicatePosition.id || uniqueId('NEW'),
                  }))
                : [],
            })),
          educations:
            educations &&
            educations.map(education => ({
              ...education,
              id: education.id || uniqueId('NEW'),
            })),
        },
      };
    },
  },
);
