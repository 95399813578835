import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import preferences from './preferences';
import userOptions from './userOptions';

const userReducer = combineImmutableReducers({
  preferences: preferences,
  userOptions: userOptions,
});

userReducer.NAME = NAME;

export default userReducer;
