/**
 * A higher-order function to be used with `fetchAction` that provides the ability
 * to modify the response data.
 * @param {Function} modifier A function that will be called with the
 * response data and should return the modified response data.
 *
 * @example
 * ```js
 * const myEntitySchema = new schema.Entity('widgets');

 * const fetchWidgetsAction = compose(
 *  fetchAction,
 *  modifySuccessResponse((response) => ({ ...response, lastFetched: Date.now() })),
 *  createAsyncActions({
 *    startActionType: 'FETCH_START',
 *    successActionType: 'FETCH_START',
 *    failureActionType: 'FETCH_FAILURE',
 *   })
 * );
 *
 * dispatch(fetchWidgetsAction());
 *
 * // Would modify the response to include a `lastFetched` value.
 * ```
 */
export default modifier => ({ successAction, ...options }) => ({
  successAction: ({ response, ...payload }) =>
    successAction({
      ...payload,
      response: modifier(response, options),
    }),
  ...options,
});
