import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, setStatic } from 'recompose';
import EntityTagSelect from 'modules/entities/components/EntityTagSelect';
import asField from 'modules/forms/components/asField';
import FieldState from 'modules/forms/FieldState';
import withFormGroup from 'modules/forms/components/withFormGroup';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import withTenantOptionsEntityIdsFetched from './withTenantOptionsEntityIdsFetched';

/**
 * A function for creating a tag select component for a list of IDs stored in tenantOptions
 */
export default compose(
  setDisplayName('TenantOptionsTagSelectField'),
  setPropTypes({
    /**
     * The schema that this tag select field will be displaying/selecting.
     */
    schema: PropTypes.shape({
      key: PropTypes.string.isRequired,
    }).isRequired,

    /**
     * A FieldState with an underlying value that is an array of target market IDs.
     */
    fieldState: fieldStatePropType.isRequired,

    /**
     * Called when the fieldState is changed by the user adding/removing target markets.
     */
    onChange: PropTypes.func.isRequired,
  }),

  setStatic('createFieldState', (name, value = [], ...rest) =>
    FieldState.create(name, value.toArray ? value.toArray() : value, ...rest),
  ),

  withTenantOptionsEntityIdsFetched(),

  // Wrap in a formgroup and provide conversion between our fieldState and it's underlying value.
  withFormGroup,
  asField(),
)(EntityTagSelect);
