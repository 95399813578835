import { NAME } from '../constants';

/**
 * Gets the list of attributes for a search from the search ID
 * @param {Object} state
 * @param {Number} searchId The ID of the search to get the attributes for.
 * @return {Immutable.List} The list of search attribute IDs for the search.
 */
export default (state, searchId) =>
  state[NAME].getIn(['searchAttributes', searchId]);
