import escapeStringRegex from 'escape-string-regexp';
import { splitByWhitespace } from '@thrivetrm/ui/utilities/stringUtils';

/**
 * Creates a RegExp object that will return true only when all keywords in the input text match,
 * where the collection of keywords are generating by splitting the input text on whitespace.
 * @param {String} inputText the input text used to generate the collection of keywords and RegExp
 * @param {String} [regexOption='i'] additional options passed to the RegExp object
 */
export const createMatchAllKeywordsRegex = (inputText, regexOptions = 'i') =>
  new RegExp(
    splitByWhitespace(inputText)
      .map(term => `(?=.*${escapeStringRegex(term)})`)
      .join(''),
    regexOptions,
  );

/**
 * Creates a RegExp object that will return true when any keyword in the input text matches,
 * where the collection of keywords are generating by splitting the input text on whitespace.
 * @param {String} inputText the input text used to generate the collection of keywords and RegExp
 * @param {String} [regexOption='i'] additional options passed to the RegExp object
 */
export const createMatchAnyKeywordsRegex = (inputText, regexOptions) =>
  new RegExp(`(${splitByWhitespace(inputText).join('|')})`, regexOptions);
