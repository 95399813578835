import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import rejectionReasons from './rejectionReasons';
import stageListsByType from './stageListsByType';

const searchStagesReducer = combineImmutableReducers({
  rejectionReasons: rejectionReasons,
  stageListsByType: stageListsByType,
});

searchStagesReducer.NAME = NAME;

export default searchStagesReducer;
