import routes from 'modules/routing/routes';
import { PARENT_CANDIDACY, PARENT_SEARCH } from '../constants';

/**
 * Gets a URL for fetching the activities list for a particular parent.
 * @param {Object} options
 * @param {String} options.parentType The parent type (PARENT_CANDIDACY, PARENT_SEARCH)
 * @param {Number} options.parentId The ID for the parent, dependent on parentType
 */
export default ({ parentId, parentType }) => {
  switch (parentType) {
    case PARENT_CANDIDACY: {
      return routes.activities_api_notifications({
        query: { candidacy_id: parentId },
      });
    }
    case PARENT_SEARCH: {
      return routes.activities_api_notifications({
        query: { search_id: parentId },
      });
    }
    default: {
      throw new Error(`Unknown parentType for activities: ${parentType}`);
    }
  }
};
