import getDirectReports from './getDirectReports';

/**
 * Gets a value indicating whether direct reports need to be fetched for a given contact.
 * @param {Object} state
 * @param {Number} id The contact's ID
 * @returns {Boolean} True if the direct reports should be fetched from the server; false otherwise.
 */
export default (state, id) => {
  const directReports = getDirectReports(state, id);

  if (!directReports) {
    return true;
  }

  if (directReports.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (directReports.hasIn(['_meta', 'error'])) {
    return false;
  }

  return (
    !directReports.has('ids') ||
    Boolean(directReports.getIn(['_meta', 'isInvalidated']))
  );
};
