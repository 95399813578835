import { compile } from 'path-to-regexp';
import { convertToSnakeCase } from 'modules/core/jsonUtils';
import routesManifest from './routes.json';
import url from './url';

/**
 * Given static Rails route patterns provided by the `routes.json` manifest generated at
 * build-time via `rake js_routes:dump`...
 * { 'contact': '/contacts/:id', ... }
 *
 * ...export a routes object which provides a function for each route...
 * { contact: () => {}, ... }
 *
 * ...which can then be called with dynamic values in app code to return an interpolated
 * path string....
 * import routes from 'modules/routing/routes';
 * const route = routes.contact({ id: 1 });
 * > '/contacts/1'
 *
 * Query params can be provided via a `query` object, which will be automatically converted
 * to snake_case, structured for Rails, url-encoded, and appended to the path.
 * routes.contact({ id: 1, query: { myParam: 'some value' } })
 * > '/contacts/1?my_param=some%20value
 *
 * Subroutes can be provided (for routes patterns that accept them) via a `path` key
 * routes.tasks({ id: 1, path: 'todo' })
 * > '/tasks/1/todo'
 */
const createRouteFunctions = routePathPatterns => {
  const routeFunctions = {};
  Object.entries(routePathPatterns).forEach(([routeName, pathPattern]) => {
    routeFunctions[routeName] = dynamicRouteParts => {
      const { query, ...pathSegments } = dynamicRouteParts || {};
      // Convert segment names to snake case to mirror the segment names in the routes manifest
      const formattedPathSegments = convertToSnakeCase(pathSegments);

      // Prevent throwing an unhandled exception when invalid path segments are provided, and
      // instead return a null route value. A broken link is better than a whitescreen.
      let path;
      try {
        // `compile` returns a function to create a path, so we curry
        path = compile(pathPattern)(formattedPathSegments);
      } catch (error) {
        return null;
      }

      // Construct full url by formatting/encoding query params and appending them to the path
      return url(path, query);
    };
  });
  return routeFunctions;
};

export default createRouteFunctions(routesManifest);
