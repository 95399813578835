import getStage from 'modules/search-stages/selectors/getStage';
import getCandidacyStageId from './getCandidacyStageId';

/**
 * Gets the stage record for a particular candidacy.
 * @param {Object} state
 * @param {Number} id The candidacy ID
 * @return {?Immutable.Map} The record of the stage the candidacy currently belongs to.
 */
export default (state, candidacyId) => {
  const stageId = getCandidacyStageId(state, candidacyId);
  return stageId && getStage(state, stageId);
};
