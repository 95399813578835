import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import routes from 'modules/routing/routes';
import {
  TARGET_COMPANY_LIST_FETCH_START,
  TARGET_COMPANY_LIST_FETCH_SUCCESS,
  TARGET_COMPANY_LIST_FETCH_FAILURE,
} from './actionTypes';
import { targetCompanySchema } from '../schema';

/**
 * Creates an action for fetching a list of target companies for a search
 * @param {Object} payload
 * @param {String} payload.searchId The ID of the search to fetch target companies for
 */
export default compose(
  fetchAction,
  normalizeResponse({
    target_companies: [targetCompanySchema],
  }),
  createAsyncActions({
    startActionType: TARGET_COMPANY_LIST_FETCH_START,
    successActionType: TARGET_COMPANY_LIST_FETCH_SUCCESS,
    failureActionType: TARGET_COMPANY_LIST_FETCH_FAILURE,
  }),
  withOptions(({ searchId }) => ({
    url: routes.api_v1_search_target_companies({ searchId: searchId }),
  })),
);
