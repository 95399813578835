import routes from 'modules/routing/routes';
import positionSchema from 'modules/positions/schema';
import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import {
  POSITIONS_FETCH_START,
  POSITIONS_FETCH_SUCCESS,
  POSITIONS_FETCH_FAILURE,
} from './actionTypes';

/**
 * Fetches the list of positions for a contact.
 * @param {Object} options
 * @param {String} options.contactId The ID of the contact to fetch the positions for.
 */
export default compose(
  fetchAction,
  normalizeResponse({ positions: [positionSchema] }),
  createAsyncActions({
    startActionType: POSITIONS_FETCH_START,
    successActionType: POSITIONS_FETCH_SUCCESS,
    failureActionType: POSITIONS_FETCH_FAILURE,
  }),
  withOptions(({ contactId }) => ({
    url: routes.api_v1_contact_positions({ contactId: contactId }),
  })),
);
