import selectn from 'selectn';
import { TYPE_RECRUITER } from 'modules/assessments/constants';
import getAssessmentProperty from 'modules/assessments/selectors/getAssessmentProperty';
import { assessmentSchema } from 'modules/assessments/schema';
import merge from 'modules/entities/actions/merge';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import isEntityType from 'modules/entities/isEntityType';
import getEntity from 'modules/entities/selectors/getEntity';
import findEntity from 'modules/entities/selectors/findEntity';
import { candidacySchema } from '../schemas';

/**
 * The name of the property on the candidacy record that maintains the candidacy record's
 * assigned recruiter assessment.
 */
const RECRUITER_ASSESSMENT_PROPERTY = 'recruiter_assessment';

/**
 * A candidacy entity has a `recruiter_assessment` property containing the ID of the assessment
 * record that is the one-and-only recruiter assessment for that candidacy record (it may or may
 * not exist). This actor keeps that property up-to-date:
 *
 * 1. When an assessment is created, check to see if it's a "recruiter" assessment type, and if so
 *    update the underlying candidacy record's `recruiter_assessment` property to point to it.
 * 2. When an assessment record is deleted, check to see if any candidacy records has a
 *    `recruiter_assessment` value with that ID, and if so remove it.
 *
 * Updates don't matter because an assessment can't be changed from one type to the other once it
 * has already been created.
 */
export default ({ action, dispatch, getState }) => {
  if (isEntityType(action, assessmentSchema.key)) {
    switch (action.type) {
      case entityActionTypes.CREATE_SUCCESS:
      case entityActionTypes.UPDATE_SUCCESS: {
        // If an assessment is created, see if we have a matching candidacy entity that needs it's
        // recruiter_assessment property updated.
        const assessmentId = selectn('payload.result.assessment', action);
        const state = getState();
        const type = getAssessmentProperty(state, assessmentId, 'type');
        const candidacyId = getAssessmentProperty(
          state,
          assessmentId,
          'candidacy_id',
        );
        const candidacy = getEntity(state, candidacySchema.key, candidacyId);

        if (
          candidacy &&
          candidacy.get(RECRUITER_ASSESSMENT_PROPERTY) !== assessmentId &&
          type === TYPE_RECRUITER
        ) {
          // The entity exists in state, update the underlying true_asssesment value.
          dispatch(
            merge({
              data: {
                id: candidacyId,
                [RECRUITER_ASSESSMENT_PROPERTY]: assessmentId,
              },
              schema: candidacySchema,
            }),
          );
        }
        break;
      }
      case entityActionTypes.DELETE_SUCCESS: {
        // If an assessment is deleted, see if we have any candidacy entities with that assessment
        // ID as their recruiter_assessment, and null that property out.
        const assessmentId = selectn('payload.id', action);

        // See if we have any entities that have this particular assessment set as it's
        // recruiter_assessment property.
        const candidacy = findEntity(
          getState(),
          candidacySchema.key,
          entity => entity.get(RECRUITER_ASSESSMENT_PROPERTY) === assessmentId,
        );

        if (candidacy) {
          // Found a matching candidacy, so null out the underlying property since this
          // was deleted.
          dispatch(
            merge({
              data: {
                id: candidacy.get('id'),
                [RECRUITER_ASSESSMENT_PROPERTY]: null,
              },
              schema: candidacySchema,
            }),
          );
        }
        break;
      }
      default: {
        // noop
      }
    }
  }
};
