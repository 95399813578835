import { NAME } from '../../constants';

export const DIRECT_REPORTS_INVALIDATE = `${NAME}/DIRECT_REPORTS_INVALIDATE`;
export const DIRECT_REPORTS_FETCH_START = `${NAME}/DIRECT_REPORTS_FETCH_START`;
export const DIRECT_REPORTS_FETCH_SUCCESS = `${NAME}/DIRECT_REPORTS_FETCH_SUCCESS`;
export const DIRECT_REPORTS_FETCH_FAILURE = `${NAME}/DIRECT_REPORTS_FETCH_FAILURE`;

export const DIRECT_REPORTS_CREATE_START = `${NAME}/DIRECT_REPORTS_CREATE_START`;
export const DIRECT_REPORTS_CREATE_SUCCESS = `${NAME}/DIRECT_REPORTS_CREATE_SUCCESS`;
export const DIRECT_REPORTS_CREATE_FAILURE = `${NAME}/DIRECT_REPORTS_CREATE_FAILURE`;
