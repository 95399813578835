import { NAME } from '../../constants';

/**
 * Gets the full name for a contact. This typically includes the company they work for in
 * parenthesis. But sometimes it doesn't. The endpoints are always returning different things. It's
 * super annoying and totally inconsistent and desperately needs to be fixed.
 * @param {Object} state
 * @param {Number} id The contact ID
 * @returns {String} The full name for the contact
 */
export default (state, id) =>
  state[NAME].getIn(['byId', id, 'full_name']) ||
  state[NAME].getIn(['byId', id, 'name']);
