import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { scrollToElement } from 'modules/core/scrollUtils';
import FormState from 'modules/forms/FormState';
import FormActions from './FormActions';

/**
 * Returns a function that creates a higher-order component which genertaes a Form from a root
 * Field component.
 *
 * This wraps the field in a <form /> element, provides submit and cancel buttons,
 * and displays form submission error messages when applicable.
 *
 * @param {Function} onSubmit The function that is called when the form should be submitted.
 *   It is called with the current form state and the component's props and should return a
 *   `FormState` object representing the state of the form after the submit
 *
 *    `onSubmit(formState: FormState, props: Object): FormState`
 *
 *   To prevent a submission, simply return the `FormState` that was passed as the first parameter.
 * @param {Object} [options] Additional options
 * @param {String} [options.className] Additional class names to apply to the <form> element.
 */
const withForm = (
  onSubmit,
  {
    buttonsClass,
    className,
    fieldComponentTop = true,
    formActionClass,
    scrollToError = false,
    showButtons = true,
    showErrors = false,
    showGenericErrorMessage = false,
  } = {},
) => FieldComponent => {
  class Form extends PureComponent {
    handleFieldChange = fieldState => {
      const { formState, onChange } = this.props;
      onChange(formState.setFieldState(fieldState));
    };

    handleCancel = () => {
      const { formState, onCancel } = this.props;
      onCancel(formState);
    };

    handleSubmit = e => {
      e?.preventDefault();
      const { formState, onChange } = this.props;
      /**
       * prevents implicit submit event of position form while creating a company from contact position form
       * as we are using forms within the forms for skyminyr company create flow
       * app/assets/javascripts/modules/positions/components/PositionField.js
       */
      if (e?.target?.id !== 'SkyminyrCompanySelectForm') {
        const newState = onSubmit(formState, this.props);
        onChange(newState);
      }
    };

    render() {
      const {
        alwaysShowFieldErrors,
        asRichTextForm,
        formActionProps,
        formState,
        // eslint-disable-next-line no-unused-vars
        onCancel,
        // eslint-disable-next-line no-unused-vars
        onChange,
        title,
        ...otherProps
      } = this.props;

      const scrollErrorMessageIntoView = el => {
        if (el) {
          /**
           * This function is only called / passed as a ref in order to "get the error element"
           * when scrollToError is true AND an error exists.  The only place scrollToError is set
           * to true is inside modules/components/JobListingForm.
           *
           * NOTE - This solution is the same as can be found in containers/JobListingForm
           */
          const scrollContainer = document.querySelector('.content-wrapper');
          scrollToElement({
            element: el,
            scrollContainer: scrollContainer,
          });
        }
      };

      const error = formState.getError();
      const fieldProps = otherProps;
      const buttons =
        showButtons === true ? (
          <FormActions
            formState={formState}
            onCancelClick={this.handleCancel}
            onConfirm={this.handleSubmit}
            {...formActionProps}
          />
        ) : (
          ''
        );
      const fieldComponent = (
        <FieldComponent
          {...fieldProps}
          disabled={formState.isSubmitting()}
          fieldState={formState.getFieldState()}
          onChange={this.handleFieldChange}
          showErrors={
            alwaysShowFieldErrors || formState.wasSubmitted() ? true : 'blurred'
          }
        />
      );

      const errorMessage = error
        ? error.message
        : 'There was an error submitting the form. Please try again.';

      const genericErrorMessage = 'Please see details below.';

      const errorBanner = (
        <p
          className={classnames('form-error alert alert-danger', {
            hide: !error,
          })}
          ref={scrollToError && error ? scrollErrorMessageIntoView : null}
        >
          <span>
            {showGenericErrorMessage ? genericErrorMessage : errorMessage}
          </span>
        </p>
      );

      return (
        <form
          className={classnames('form', className, {
            'rich-text-form': Boolean(asRichTextForm),
            'is-submitting': formState.isSubmitting(),
            'has-errors': formState.hasFieldErrors(),
          })}
          onSubmit={this.handleSubmit}
        >
          {title ? <h4>{title}</h4> : null}
          {scrollToError ? errorBanner : null}
          {fieldComponentTop ? fieldComponent : null}
          {scrollToError ? null : errorBanner}
          <div className={formActionClass}>
            <div className={buttonsClass}>{buttons}</div>
          </div>

          {fieldComponentTop ? null : fieldComponent}
        </form>
      );
    }
  }

  Form.defaultProps = {
    alwaysShowFieldErrors: showErrors,
  };

  Form.propTypes = {
    /**
     * True to always show field errors, false to only show them after a field has been blurred
     * or if the form has been submitted.
     */
    alwaysShowFieldErrors: PropTypes.bool,

    /**
     * True to add a class of rich-text-form
     */
    asRichTextForm: PropTypes.bool,

    formActionProps: PropTypes.shape(FormActions.propTypes),

    /**
     * The current form state.
     */
    formState: PropTypes.instanceOf(FormState).isRequired,

    /**
     * Called when the form's onCancel is called, and provides the
     * current field values that were left in the form as the first argument.
     */
    onCancel: PropTypes.func,

    /**
     * Called when the form state is changed, with the updated `FormState`.
     */
    onChange: PropTypes.func.isRequired,

    /**
     * String the title to display at the top of the form.
     * @type {[type]}
     */
    title: PropTypes.node,
  };

  return Form;
};

export default withForm;
