import { connect } from 'react-redux';
import getContact from '../selectors/contacts/getContact';

/**
 * A higher order component which maps a `contactId` prop value to its contact record.
 */
export default connect(
  (state, { contactId }) => ({
    contact: getContact(state, contactId),
  }),
  {},
);
