import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  defaultProps,
  renderNothing,
  setDisplayName,
  setPropTypes,
} from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Currency from '@thrivetrm/ui/components/Currency';
import canViewDesiredCompensation from 'modules/compensations/selectors/canViewDesiredCompensation';
import CurrencyRange from '@thrivetrm/ui/components/CurrencyRange';
import PercentageRange from '@thrivetrm/ui/components/PercentageRange';
import mapContactIdToContact from '../mapContactIdToContact';

/**
 * Determines if a contact record has any available compensation data to display.
 * @param {Immutable.Map} contact The contact record
 * @returns {Boolean} true if there is desired compensation data to display for the contact record;
 *   Otherwise, false.
 */
export const hasData = contact =>
  [
    'preferred_base_minimum',
    'preferred_base_maximum',
    'preferred_bonus_minimum',
    'preferred_bonus_maximum',
    'preferred_equity_minimum',
    'preferred_equity_maximum',
    'preferred_total_compensation',
    'desired_compensation_notes',
  ].some(prop => contact.get(prop));

/**
 * Renders a table that shows a summary of desired compensation data for a particular contact.
 */
const ContactDesiredCompensationSummary = ({
  contact,
  excludeNotes,
  renderIfEmpty,
}) => {
  const currency = contact.get('preferred_currency');

  if (!renderIfEmpty && !hasData(contact)) {
    return null;
  }

  return (
    <div className='ContactDesiredCompensationSummary'>
      <h3>Desired Compensation</h3>
      <table className='table--isCompact table--isPlain'>
        <thead />
        <tbody>
          {Number.isFinite(contact.get('preferred_base_minimum')) ||
          Number.isFinite(contact.get('preferred_base_maximum')) ? (
            <tr key='preferred_salary_range'>
              <td key='label'>Base Range:</td>
              <td key='value'>
                <CurrencyRange
                  currency={contact.get('preferred_currency')}
                  maxFallbackContent='No max'
                  maxValue={contact.get('preferred_base_maximum')}
                  minFallbackContent='No min'
                  minValue={contact.get('preferred_base_minimum')}
                />
              </td>
            </tr>
          ) : null}
          {Number.isFinite(contact.get('preferred_bonus_minimum')) ||
          Number.isFinite(contact.get('preferred_bonus_maximum')) ? (
            <tr key='preferred_bonus_range'>
              <td key='label'>Bonus Range:</td>
              <td key='value'>
                <CurrencyRange
                  currency={contact.get('preferred_currency')}
                  maxFallbackContent='No max'
                  maxValue={contact.get('preferred_bonus_maximum')}
                  minFallbackContent='No min'
                  minValue={contact.get('preferred_bonus_minimum')}
                />
              </td>
            </tr>
          ) : null}
          {Number.isFinite(contact.get('preferred_equity_minimum')) ||
          Number.isFinite(contact.get('preferred_equity_maximum')) ? (
            <tr key='preferred_equity_range'>
              <td key='label'>Equity Range:</td>
              <td key='value'>
                <PercentageRange
                  maxFallbackContent='No max'
                  maxValue={contact.get('preferred_equity_maximum')}
                  minFallbackContent='No min'
                  minValue={contact.get('preferred_equity_minimum')}
                />
              </td>
            </tr>
          ) : null}
          {Number.isFinite(contact.get('preferred_total_compensation')) ? (
            <tr key='preferred_total_compensation'>
              <td key='label'>Total Compensation:</td>
              <td key='value'>
                <Currency
                  currency={currency}
                  value={contact.get('preferred_total_compensation')}
                />
              </td>
            </tr>
          ) : null}
        </tbody>
        <tfoot>
          {!excludeNotes && contact.get('desired_compensation_notes') ? (
            <tr>
              <td colSpan={2}>
                <h4>Notes</h4>
                {contact.get('desired_compensation_notes')}
              </td>
            </tr>
          ) : null}
        </tfoot>
      </table>
    </div>
  );
};

ContactDesiredCompensationSummary.propTypes = {
  /**
   * The contact record to display desired compensation data for.
   */
  contact: ImmutablePropTypes.mapContains({
    preferred_currency: PropTypes.string,
    preferred_bonus_range: PropTypes.node,
    preferred_equity_range: PropTypes.node,
    preferred_salary_range: PropTypes.node,
    preferred_total_compensation: PropTypes.number,
    desired_compensation_notes: PropTypes.string,
  }),

  /**
   * When true, related notes (`desired_compensation_notes`) will not be rendered.
   */
  excludeNotes: PropTypes.bool,

  /**
   * When true, the component will be rendered even if there is no underlying data to display.
   */
  renderIfEmpty: PropTypes.bool,
};

ContactDesiredCompensationSummary.defaultProps = {
  excludeNotes: false,
  renderIfEmpty: false,
};

export default compose(
  setDisplayName('ContactDesiredCompensationSummary(enhanced)'),
  setPropTypes({
    /**
     * The ID of the contact to render desired compensation data for.
     */
    contactId: PropTypes.number.isRequired,

    /**
     * If true, user permissions for viewing compensation will be checked and
     * the summary will not render if they are not permitted.
     */
    verifyUserPermitted: PropTypes.bool.isRequired,
  }),
  defaultProps({
    verifyUserPermitted: true,
  }),
  connect(
    (state, { verifyUserPermitted }) => ({
      isPermitted: !verifyUserPermitted || canViewDesiredCompensation(state),
    }),
    {},
  ),
  branch(({ isPermitted }) => !isPermitted, renderNothing),
  mapContactIdToContact,
)(ContactDesiredCompensationSummary);
