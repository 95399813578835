import { NAME } from '../constants';

/**
 * Gets a value indicating whether an entity is currently being fetched based on the current state.
 * @param {Object} state
 * @param {String} entityType The entity type (schema key)
 * @param {*} id The entity's ID.
 * @return {Boolean} True if the entity is currently being fetched; false otherwise.
 */
export default (state, entityType, id) =>
  state[NAME].getIn([entityType, String(id), '_meta', 'isFetching']);
