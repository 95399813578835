//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'notes';

//---------------------------------------------------------
// Parent types
//---------------------------------------------------------

export const PARENT_SEARCH = 'Search';
export const PARENT_CONTACT = 'Contact';

export const PARENT_TYPES = [PARENT_SEARCH, PARENT_CONTACT];
