import { normalize } from 'normalizr';
import { contactSchema } from '../../schema';
import { CONTACT_INITIALIZE } from './actionTypes';

/**
 * An action that seeds the application state with data for a particular contact.
 * @param {Object} options
 * @param {Object} options.contact The contact record.
 * @param {Nubmer} options.contact.id The ID of the contact.
 * @returns {Object} A redux action
 */
const initializeContact = ({ contact }) => ({
  type: CONTACT_INITIALIZE,
  payload: normalize(contact, contactSchema),
});

export default initializeContact;
