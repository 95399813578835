/**
 * Generates an action creator that dispatches the given type.
 * @param  {String} type The action type this action should dispatch.
 * @return {Function} A function that returns an action that will dispatch
 *   with the specified type. The function takes a single parameter which
 *   will be dispatched with the action as it's `payload`.
 * @example
 *   ```
 *   const foobarStarted = createAction(FOOBAR_FETCH_START);
 *   dispatch(started({
 *     id: 42,
 *     baz: 'blah'
 *   }));
 *   ```
 */
export default function createAction(type) {
  return function action(payload = {}) {
    return { type: type, payload: payload };
  };
}
