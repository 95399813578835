import getEntity from './getEntity';

/**
 * Gets a value indicating whether an entity should be fetched based on the current state.
 * @param {Object} state
 * @param {String} entityType The entity type (schema key)
 * @param {*} id The entity's ID.
 * @param {String|String[]} requireProperty An optional property to check for on the entity.
 *   This is useful in cases where wer may have fetched a partial set of properties for an entity,
 *   but a "full" fetch would ensure that a particular property is loaded.
 * @return {Boolean} True if the entity should be fetched; false otherwise.
 */
const shouldFetchEntity = (state, entityType, id, requireProperty) => {
  if (!id || !entityType) {
    return false;
  }

  const entity = getEntity(state, entityType, id);

  if (!entity) {
    return true;
  }

  if (entity.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (entity.hasIn(['_meta', 'error'])) {
    return false;
  }

  if (requireProperty && !entity.has(requireProperty)) {
    return true;
  }

  return Boolean(entity.getIn(['_meta', 'isInvalidated']));
};

export default shouldFetchEntity;
