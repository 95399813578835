import getCandidacyProperty from './getCandidacyProperty';

/**
 * Gest the ID of the search a candidacy belongs to.
 * @param {Object} state
 * @param {Number} id The candidacy ID
 * @return {?Number} The search ID of the candidacy, if it's record exists.
 */
export default (state, candidacyId) =>
  getCandidacyProperty(state, candidacyId, 'search_id');
