import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import { searchSchema } from '../schema';

/**
 * Gets a search record property by it's ID and property name
 * @param {Object} state
 * @param {Number} searchId The search ID
 * @param {String|String[]} propertyName The property name or key path
 * @return {*} The property value.
 */
export default (state, searchId, propertyName) =>
  getEntityProperty(state, searchSchema.key, searchId, propertyName);
