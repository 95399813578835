import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Card from '@thrivetrm/ui/components/Card';
import EducationListItem, {
  defaultProps as EducationListItemDefaultProps,
  propTypes as EducationListItemPropTypes,
} from './EducationListItem';
import withSortedEducationIds from './withSortedEducationIds';

/**
 * Renders a list of educations.
 */
export const EducationList = ({
  educationIds,
  renderDescriptions,
  renderInsideACard,
  renderSchools,
  renderTitles,
  ...props
}) => {
  const educationList = (
    <ul className='EducationList list-generic'>
      {educationIds &&
        educationIds
          .map(educationId => (
            <EducationListItem
              {...props}
              educationId={educationId}
              key={educationId}
              renderDescription={renderDescriptions}
              renderSchool={renderSchools}
              renderTitle={renderTitles}
            />
          ))
          .toArray()}
    </ul>
  );
  return renderInsideACard ? (
    <Card isCentered={false}>{educationList}</Card>
  ) : (
    educationList
  );
};

EducationList.propTypes = {
  className: PropTypes.string,

  /**
   * The map of educations to render.
   */
  educationIds: ImmutablePropTypes.listOf(PropTypes.number).isRequired,

  /**
   * When the list is editable, the layout to use when editing a education.
   */
  formLayout: EducationListItemPropTypes.formLayout,

  /**
   * True to render the description for each education; false to omit them.
   */
  renderDescriptions: PropTypes.bool,

  /**
   * This component is used on the search page and contact profile page.  We only
   * want to wrap the PositionList in a Card if it's on the search page
   */
  renderInsideACard: PropTypes.bool,

  /**
   * True to render the school name in the education view, otherwise it will not be rendered.
   * Typically either `renderSchools` or `renderTitles` should be true, but not both, as they
   * both render the school name.
   */
  renderSchools: PropTypes.bool,

  /**
   * True to render the titles for each list item, otherwise they will not be rendered.
   */
  renderTitles: PropTypes.bool,

  /**
   * An optional list of *company IDs* (note these are company IDs, not _target_ company IDs, which
   * is a different record) which will be used to mark individual educations
   * as being a target company, if the company ID exists in this collection.
   */
  targetedCompanyIds: ImmutablePropTypes.listOf(PropTypes.number),
};

EducationList.defaultProps = {
  formLayout: EducationListItemDefaultProps.formLayout,
  renderDescriptions: true,
  renderSchools: EducationListItemDefaultProps.renderSchool,
  renderTitles: EducationListItemDefaultProps.renderTitle,
};

export default withSortedEducationIds(EducationList);
