import createAjaxAction from './createAjaxAction';

const createQueryAction = type => queryId => ({
  type: type,
  payload: { queryId: queryId },
});

export const transformSuggestions = ({ suggestions }) =>
  suggestions.map(({ data, value, ...rest }) => ({
    id: data.id,
    name: value,
    data: data,
    ...rest,
  }));

export default ({
  create,
  destroy,
  failure,
  invalidate,
  start,
  success,
  ...fetchConfig
}) => ({
  create: createQueryAction(create),
  destroy: createQueryAction(destroy),
  invalidate: createQueryAction(invalidate),
  query: createAjaxAction({
    start: start,
    failure: failure,
    success: success,
    ...fetchConfig,
  }),
});
