import getRejectionReasons from './getRejectionReasons';
import getRejectionReasonsMeta from './getRejectionReasonsMeta';

/**
 * Determines if the list of rejection reasons needs to be fetched from the server.
 * @param {Object} state
 * @return {Boolean} True if the list of rejection reasons needs to be fecthed from the server;
 *   Othewise, false.
 */
const shouldFetchRejectionReasons = state => {
  const rejectionReasons = getRejectionReasons(state);
  const meta = getRejectionReasonsMeta(state);

  if (!meta) {
    return true;
  }

  if (meta.get('isFetching') || meta.has('error')) {
    return false;
  }

  return !rejectionReasons || Boolean(meta.get('isInvalidated'));
};

export default shouldFetchRejectionReasons;
