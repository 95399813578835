import { List } from 'immutable';
import { debounce } from 'lodash';

const QUERY_NAMES = ['name', 'website_url'];
const DELAY = 500;

const intermittently = debounce(fn => fn(), DELAY);

/**
 * Query IDs are the combination of a *unique* component ID.
 * @private
 * @param  {String} componentId The unique ID of a component.
 * @param  {String} fieldName The name of the field that query results will be associated with.
 * @return {String} A concatenation of the two arguments.
 */
const getQueryId = (componentId, fieldName) => `${componentId}-${fieldName}`;

/**
 * Maps over all possible field names, and returns the query IDs for each.
 * @public
 * @param  {String} componentId [description]
 * @return {String}             [description]
 */
const getDuplicateCompanyQueryIds = componentId =>
  QUERY_NAMES.map(getQueryId.bind(null, componentId));

/**
 * @private
 * @param  {Object} payload The query parameters that will be sent to the server.
 * @return {Boolean} Whether or not the payload is valid.
 */

/**
 * Builds a payload that will be dispatched with `queryDuplicateCompanies`.
 * @private
 * @param  {String} queryId The identifier for the `query`.
 * @param  {String} fieldName The name fo the field that changed.
 * @param  {Object} values The current representation of the company record.
 * @return {Object|null} An object with a `queryId` and `term`. Term is an object
 *   that will be sent to the server as query parameters. The `term` is used
 *   to prevent queries from colliding. In the case of an unknown field, this
 *   function will return null.
 */
const getPayload = (queryId, fieldName, values) => ({
  queryId: `${queryId}-${fieldName}`,
  term: values[fieldName]?.trim(),
  filter: fieldName,
});

/**
 * This function builds a payload to be sent to the server. If the query is valid,
 * and the user has stopped typing, we'll invoke the action, which will trigger a
 * request.
 * @public
 * @param  {String} componentId The unique identifier for the component
 * @param  {String} fieldName The name of the field that has changed
 * @param  {Object} values The current representation of the company
 * @param  {Function} invokeQuery A function to invoke the query action. This function
 *   will receive a payload containing `term` and `queryId`.
 * @param {boolean} immediately will determine if the payload should be called with
 *  a delay or not
 * @return {undefined}
 */
const queryDuplicateCompanies = (
  componentId,
  fieldName,
  values,
  invokeQuery,
  immediately,
) => {
  const payload = getPayload(componentId, fieldName, values);

  if (payload) {
    if (immediately) {
      invokeQuery(payload);
    }
    intermittently(() => invokeQuery(payload));
  }
};

/**
 * Check for duplicates on all fields. This is used for immediate dupe recognition
 * on page load.
 */
const queryAllDuplicateCompanies = (
  componentId,
  values,
  invokeQuery,
  immediately,
) =>
  QUERY_NAMES.forEach(fieldName =>
    queryDuplicateCompanies(
      componentId,
      fieldName,
      values,
      invokeQuery,
      immediately,
    ),
  );

/**
 * Builds an object keyed on fieldName, with values as Lists containing matching
 * company records. If the query results aren't present, we'll just return an empty
 * List. This makes component logic much simpler.
 * @public
 * @param  {String} componentId The unique identifier for the component
 * @param  {Immutable.Map} companies The entire companies state
 * @return {Object<Immutable.List>} A mapping of fieldNames and lists of companies
 */
const getDuplicateCompanies = (componentId, companies) =>
  QUERY_NAMES.reduce(
    (acc, name) => ({
      ...acc,
      [name]: companies.getIn(
        ['queriesById', getQueryId(componentId, name), 'data'],
        new List(),
      ),
    }),
    {},
  );

export {
  getDuplicateCompanies,
  getDuplicateCompanyQueryIds,
  queryDuplicateCompanies,
  queryAllDuplicateCompanies,
};
