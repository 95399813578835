import PropTypes from 'prop-types';
import searchQueryParser from 'search-query-parser';
import {
  compose,
  branch,
  defaultProps,
  setPropTypes,
  withProps,
} from 'recompose';
import withCandidacyIdsFilteredByCompany from './withCandidacyIdsFilteredByCompany';
import withCandidacyIdsFilteredByPriorityOnly from './withCandidacyIdsFilteredByPriorityOnly';
import withCandidacyIdsFilteredByTagIds from './withCandidacyIdsFilteredByTagIds';
import withCandidacyIdsFilteredByTargetCompany from './withCandidacyIdsFilteredByTargetCompany';
import withCandidacyIdsFilteredByText from './withCandidacyIdsFilteredByText';
import withCandidacyIdsFilteredVisibleOnly from './withCandidacyIdsFilteredVisibleOnly';
import { FILTER_KEY_COMPANY, FILTER_KEY_TARGET_COMPANY } from '../constants';

/**
 * A higher order component that filters a list of candidacy IDs (provided as a `candidacyIds`
 * props) on a filter value.
 */
export default compose(
  setPropTypes({
    /**
     * The text/keywords to filter on.
     * The filter may include keywords:
     *   * `company:"Some Company Name"` to filter so that only candidates who have worked for a
     *     particular company are included
     *   * `targetcompany:none` to only include candidates who have never worked for any of the
     *     search's target companies
     */
    filter: PropTypes.string,

    /**
     * When true, only "priority" candidacies will be included, otherwise
     * all candidacies will be included.
     */
    priorityOnly: PropTypes.bool.isRequired,

    /**
     * Array of tagIds to filter the candidacy list against
     */
    selectedTagIds: PropTypes.arrayOf(PropTypes.number).isRequired,

    /**
     * When true, only "visible" candidacies will be included, otherwise
     * all candidacies will be included.
     */
    visibleOnly: PropTypes.bool.isRequired,
  }),
  defaultProps({
    priorityOnly: false,
    selectedTagIds: [],
    visibleOnly: false,
  }),

  // First extract any specific query parameters from the filter. This will tokenize things like
  // `targetcompany:"Google, inc."`
  withProps(({ filter }) => {
    if (filter && filter.trim()) {
      const filters = searchQueryParser.parse(filter, {
        keywords: [FILTER_KEY_COMPANY, FILTER_KEY_TARGET_COMPANY],
      });

      return {
        filterText:
          typeof filters === 'string'
            ? filters.trim()
            : filters.text && filters.text.trim(),

        // Currently the searchQueryParser interprets a comma as a specifying multiple keyword
        // filters, even if it is contained inside of quotes. So, for now, if we get back an
        // array of target companies, just merge them back into one string.
        // Of course this doesn't account for something like:
        //   `targetcompany:AOL,Google,"My Company"`
        // So we have to just assume (for now) that we can only filter on one target company.
        // TODO: Find a better query parser.
        filterTargetCompany: Array.isArray(filters[FILTER_KEY_TARGET_COMPANY])
          ? filters?.offsets[0].value
          : filters[FILTER_KEY_TARGET_COMPANY],

        filterCompany: Array.isArray(filters[FILTER_KEY_COMPANY])
          ? filters?.offsets[0].value
          : filters[FILTER_KEY_COMPANY],
      };
    }

    return {};
  }),
  branch(
    ({ filterText }) => Boolean(filterText),
    withCandidacyIdsFilteredByText,
  ),
  branch(
    ({ filterTargetCompany }) => filterTargetCompany,
    withCandidacyIdsFilteredByTargetCompany,
  ),
  branch(
    ({ filterCompany }) => filterCompany,
    withCandidacyIdsFilteredByCompany,
  ),
  branch(
    ({ priorityOnly }) => priorityOnly,
    withCandidacyIdsFilteredByPriorityOnly,
  ),
  branch(({ visibleOnly }) => visibleOnly, withCandidacyIdsFilteredVisibleOnly),
  branch(
    ({ selectedTagIds }) => Boolean(selectedTagIds.length),
    withCandidacyIdsFilteredByTagIds,
  ),
);
