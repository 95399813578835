import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, setDisplayName, setPropTypes } from 'recompose';

import Form from 'modules/forms/components/Form';
import FormState from 'modules/forms/FormState';
import CheckboxField from 'modules/forms/components/CheckboxField';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';

import canCreateCompany from '../selectors/canCreateCompany';
import CompanySelectField from './CompanySelectField';
import NewCompanyFormModalButton from './NewCompanyFormModalButton';

const createFieldState = (name = 'company', value, validator) =>
  FieldState.createNested(
    name,
    [
      CompanySelectField.createFieldState(
        'company',
        value ? value.id || value.name : null,
      ),
      CheckboxField.createFieldState(
        'create',
        (value && value.create) || false,
      ),
      InputField.createFieldState('website', value && value.website),
    ],
    validator,
    ({ company, create, website }) => ({
      id: typeof company === 'number' ? company : null,
      name: typeof company === 'string' ? company : null,
      create: create && typeof company === 'string',
      website: create && typeof company === 'string' ? website : null,
    }),
  );

class CreatableCompanySelectField extends Component {
  static createFieldState = createFieldState;

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(fieldState.setNestedField(childFieldState));
  };

  render() {
    const {
      allowCreate,
      fieldState,
      formState,
      onCloseCreateCompanyModal,
      onCreateCompanyClick,
      onSubmit,
      ...otherProps
    } = this.props;

    const selectedCompany = fieldState.getNestedField('company').getValue();
    const isCreateChecked = fieldState.getNestedField('create').getValue();
    const companyName =
      typeof selectedCompany === 'string' ? selectedCompany : null;

    return (
      <div className='CreatableCompanySelectField'>
        <div className='CreatableCompanySelectField__company' key='company'>
          <CompanySelectField
            allowName={true}
            {...otherProps}
            error={
              allowCreate &&
              companyName &&
              'We couldn’t find a company with that name.'
            }
            fieldState={fieldState.getNestedField('company')}
            onChange={this.handleFieldChange}
          />
        </div>
        {allowCreate && companyName && (
          <div className='CreatableCompanySelectField__create' key='create'>
            <NewCompanyFormModalButton
              btnClassName='btn btn-secondary'
              companyName={companyName}
              onCloseCreateCompanyModal={onCloseCreateCompanyModal}
              onCreateCompanyClick={onCreateCompanyClick}
              size='small'
            />
          </div>
        )}
        {allowCreate && companyName && isCreateChecked && (
          <Form formState={formState} onSubmit={onSubmit} />
        )}
      </div>
    );
  }
}

CreatableCompanySelectField.propTypes = {
  allowCreate: PropTypes.bool,
  fieldState: PropTypes.instanceOf(FieldState).isRequired,
  formState: PropTypes.instanceOf(FormState),
  onChange: PropTypes.func.isRequired,
  onCloseCreateCompanyModal: PropTypes.func,
  onCreateCompanyClick: PropTypes.func,
  onSubmit: PropTypes.func,
};

CreatableCompanySelectField.defaultProps = {
  allowCreate: true,
};

export default compose(
  setDisplayName('CreatableCompanySelectField(enhanced))'),
  setPropTypes({
    allowCreate: PropTypes.bool,
    fieldState: PropTypes.instanceOf(FieldState).isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  connect(
    (state, { allowCreate }) => ({
      // Even if `allowCreate` is true, we also need to ensure the user is permitted
      // to create companies.
      allowCreate: canCreateCompany(state) && allowCreate,
    }),
    {},
  ),
)(CreatableCompanySelectField);
