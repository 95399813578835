//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'addresses';

/**
 * The country code for the United States.
 * Used to determine whether to show the list of US states in the address field, or allow
 * free text input.
 */
export const COUNTRY_CODE_US = 'US';
export const COUNTRY_CODE_CA = 'CA';

export const SORT_BY_COUNTRY = 'country';

// Used to identify addresses that belong to a parent
export const PARENT_COMPANY = 'Company';
export const PARENT_CONTACT = 'Contact';

// All valid parent types
export const PARENT_TYPES = [PARENT_COMPANY, PARENT_CONTACT];
