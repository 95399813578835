import getEntity from 'modules/entities/selectors/getEntity';
import { ratingSchema } from '../schema';

/**
 * Gets an assessment rating by it's ID
 * @param {Object} state
 * @param {Number} id The assessment rating ID
 * @return {Immutable.Map|undefined} The assessment rating record, if found.
 */
export default (state, id) => getEntity(state, ratingSchema.key, id);
