import { fromJS, Map } from 'immutable';
import selectn from 'selectn';
import composeReducers from './composeReducers';
import createMetaReducer from './createMetaReducer';

/**
 * Creates a reducer function that contains a collection of entities mapped by their IDs
 * @param {schema.Entity} schema A normalizr schema definition for the entities this reducer will
 *   manage.
 * @return {Function} A reducer function for maintaining a list of entities mapped by their ID.
 */
export const createEntityByIdReducer = schema => {
  const entitiesSelector = selectn(`payload.entities.${schema.key}`);

  const reducer = (state = Map(), action) => {
    const entities = entitiesSelector(action);

    if (entities) {
      // Just passing the entities to the Map construtor (i.e. `new Map(entities)` will mean that
      // all of the keys will end up be strings, which may not always be the case (and almost
      // always _isn't_ the case, for us). Immutable supports non-string keys, so instead we'll
      // pull out the key from the entity itself (based on the schema) and use the Map constructor
      // which takes an array of tuples (i.e. [[key, value], [key, value], ...]) -- this allows
      // initializing the map with non-string keys.
      const entitiesMap = new Map(
        Object.keys(entities).map(key => [
          schema.getId(entities[key]),
          fromJS(entities[key]),
        ]),
      );

      // Note that we don't want to `mergeDeep` here -- we only want to merge each entity
      // shallowly (which means we just want to merge 2 levels deep).
      return state.mergeWith((prev, next) => prev.merge(next), entitiesMap);
    }

    return state;
  };

  return reducer;
};

export default (schema, metaReducerOptions) =>
  metaReducerOptions
    ? composeReducers(
        createEntityByIdReducer(schema),
        createMetaReducer(metaReducerOptions),
      )
    : createEntityByIdReducer(schema);
