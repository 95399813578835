import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import { assessmentSchema } from '../schema';

/**
 * Gets a property from an assessment record
 * @param {*} state
 * @param {Number} id The ID of the assessment record
 * @param {String} propertyName The name of the property on the assessment record to return
 * @returns {*} The value of the property for the assessment given, if the record exists and
 *   has the propertyName given.
 */
export default (state, id, propertyName) =>
  getEntityProperty(state, assessmentSchema.key, id, propertyName);
