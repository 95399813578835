const isDigits = fieldState => {
  const value = fieldState.getRawValue();

  if (typeof value === 'string' && value.match(/\D/)) {
    return 'Value must be a number.';
  }

  return null;
};

export default isDigits;
