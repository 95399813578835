import routes from 'modules/routing/routes';
import createAjaxAction from '../../../../actions/createAjaxAction';
import { contactSchema } from '../../schema';
import shouldFetchDirectReports from '../../selectors/directReports/shouldFetchDirectReports';
import {
  DIRECT_REPORTS_FETCH_START,
  DIRECT_REPORTS_FETCH_SUCCESS,
  DIRECT_REPORTS_FETCH_FAILURE,
} from './actionTypes';

/**
 * Creates an action for fetching the direct reports for a contact
 * @param {Object} options
 * @param {Number} options.contactId The ID of the contact whose direct reports should be fetched.
 * @param {String[]} [options.fields] An optional list of fields that should be fetched when
 *   returning results.
 */
export const fetchDirectReports = createAjaxAction({
  getUrl: ({ contactId, fields }) =>
    routes.api_v1_contact_direct_reports({
      contactId: contactId,
      query: { fields: fields },
    }),
  start: DIRECT_REPORTS_FETCH_START,
  success: DIRECT_REPORTS_FETCH_SUCCESS,
  failure: DIRECT_REPORTS_FETCH_FAILURE,
  schema: { direct_reports: [contactSchema] },
});

/**
 * Creates an action that will fetch the direct reports for a contact only if they are needed
 * from the server.
 * @param {Object} params
 * @param {Number} params.contactId The ID of the contact whose direct reports should be fetched
 * @returns {Function} A thunk for potentially dispatching an action.
 */
export const fetchDirectReportsIfNeeded = params => (dispatch, getState) => {
  const { contactId } = params;

  if (!contactId) {
    throw new Error('fetchDirectReportsIfNeeded requires an `id` value');
  }

  if (shouldFetchDirectReports(getState(), contactId)) {
    dispatch(fetchDirectReports(params));
  }
};
