/**
 * Combines multiple validators to be run in series into a single validator that
 * can be passed to a FieldState record.
 * @param {Function[]} validators The validators to combine. They will be run in the order
 *   specified and as soon as a validator fails, the result will be returned and no additional
 *   validators will be run.
 * @return {String|null} The result of the first validator that returns a value, or null.
 */
const combineValidators = (...validators) => fieldState =>
  validators.reduce(
    (prev, validator) =>
      prev || (typeof validator === 'function' && validator(fieldState)),
    null,
  );

export default combineValidators;
