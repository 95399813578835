import { createSlice } from '@reduxjs/toolkit';
import url from 'url';
import omit from 'lodash/omit';
import { stringifyQueryObject } from 'modules/core/urlUtils';
import routes from 'modules/routing/routes';
import Api, { getErrorMessage } from 'modules/core/Api';
// TODO: these utils should be moved to core/objectUtils with tests written
import { convertToCamelCase, convertToSnakeCase } from 'modules/core/jsonUtils';

const RESULTS_PER_PAGE_DEFAULT = '25';
const RESULTS_PER_PAGE_LOCALSTORAGE_KEY = 'searchRequestsIndexResultsPerPage';

const initialState = {
  isCreating: false,
  isCreatingComplete: false,
  isLoadingSearchRequest: false,
  isLoadingSearchRequestComplete: false,
  createSearchRequestError: null,
  loadSearchRequestError: null,
  recordList: null,
  currentPage: 1,
  resultsPerPage:
    localStorage.getItem(RESULTS_PER_PAGE_LOCALSTORAGE_KEY) ||
    RESULTS_PER_PAGE_DEFAULT,
  totalPages: 1,
  filters: {},
  sortKey: null,
  sortDirection: null,
  searchRequest: null,
  searchRequestId: null,
  isLoadingSearchRequestList: true,
  searchRequestListRequestError: null,
  selectedColumns: null,
  selectedRecordIds: [],
  shouldUpdateBrowserUrl: false,
  totalResults: 0,
};

const searchRequestSlice = createSlice({
  name: 'searchRequest',
  initialState: initialState,
  reducers: {
    createSearchRequestBegin: state => {
      state.isCreatingComplete = false;
      state.isCreating = true;
      state.createSearchRequestError = null;
    },
    createSearchRequestSuccess: (state, action) => {
      state.isCreating = false;
      state.isCreatingComplete = true;
      state.searchRequestId = action.payload.search_request.id;
      state.searchRequest = action.payload.search_request;
    },
    createSearchRequestError: (state, action) => {
      state.isCreating = false;
      state.createSearchRequestError = action.payload;
    },
    loadSearchRequestBegin: state => {
      state.isLoadingSearchRequest = true;
      state.loadSearchRequestError = null;
    },
    loadSearchRequestSuccess: (state, action) => {
      state.isLoadingSearchRequest = false;
      state.isLoadingSearchRequestComplete = true;
      state.searchRequest = action.payload.search_request;
    },
    loadSearchRequestError: (state, action) => {
      state.isLoadingSearchRequest = false;
      state.loadSearchRequestError = action.payload;
    },
    resetCreateSearchRequest: state => {
      state.isCreatingComplete = false;
      state.createSearchRequestError = null;
    },
    requestSearchRequestListBegin: state => {
      state.isLoadingSearchRequestList = true;
      state.searchRequestListRequestError = null;
    },
    requestSearchRequestListSuccess: (state, action) => {
      state.isLoadingSearchRequestList = false;
      state.recordList = action.payload.recordList;
      state.selectedColumns = action.payload.selectedColumns;
      state.totalPages = action.payload.totalPages;
      state.sortKey = action.payload.sortKey;
      state.sortDirection = action.payload.sortDirection;
      state.shouldUpdateBrowserUrl = true;
      state.totalResults = action.payload.totalResults;
    },
    requestSearchRequestListError: (state, action) => {
      state.isLoadingSearchRequestList = false;
      state.searchRequestListRequestError = action.payload;
    },
    setResultsPerPage: (state, action) => {
      state.resultsPerPage = action.payload;
      state.currentPage = 1;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.currentPage = 1;
    },
    setSelectedRecords: (state, action) => {
      state.selectedRecordIds = action.payload;
    },
    setSortParams: (state, action) => {
      state.sortKey = action.payload.sortKey;
      state.sortDirection = action.payload.sortDirection;
      state.currentPage = 1;
    },
  },
});

const {
  createSearchRequestBegin,
  createSearchRequestError,
  createSearchRequestSuccess,
  loadSearchRequestBegin,
  loadSearchRequestError,
  loadSearchRequestSuccess,
  requestSearchRequestListBegin,
  requestSearchRequestListError,
  requestSearchRequestListSuccess,
  resetCreateSearchRequest,
  setCurrentPage,
  setFilters,
  setResultsPerPage,
  setSelectedRecords,
  setSortParams,
} = searchRequestSlice.actions;

const loadSearchRequest = recordId => dispatch => {
  dispatch(loadSearchRequestBegin());

  const endpointUrl = routes.api_v1_search_request({ id: recordId });

  Api.get(endpointUrl)
    .then(data => {
      dispatch(loadSearchRequestSuccess(data));
    })
    .catch(error => {
      dispatch(loadSearchRequestError(getErrorMessage(error)));
    });
};

const createSearchRequest = payload => dispatch => {
  dispatch(createSearchRequestBegin());
  const endpointUrl = routes.api_v1_search_requests();
  Api.post(endpointUrl, payload)
    .then(data => {
      dispatch(createSearchRequestSuccess(data));
    })
    .catch(error => {
      dispatch(createSearchRequestError(getErrorMessage(error)));
    });
};

const editSearchRequest = payload => dispatch => {
  dispatch(createSearchRequestBegin());
  const endpointUrl = routes.api_v1_search_request({ id: payload.id });
  Api.patch(endpointUrl, payload)
    .then(data => {
      dispatch(createSearchRequestSuccess(data));
    })
    .catch(error => {
      dispatch(createSearchRequestError(getErrorMessage(error)));
    });
};

const createEndpointUrl = ({
  currentPage,
  filters,
  resultsPerPage,
  sortDirection,
  sortKey,
}) => {
  const params = convertToSnakeCase({
    view: 'table_view',
    page: currentPage,
    filters: filters,
    resultsPerPage: resultsPerPage,
    sortDirection: sortDirection,
    sortField: sortKey,
  });

  return url.format({
    pathname: `/api/v1/search_requests`,
    search: stringifyQueryObject(params),
  });
};

const updateBrowserUrl = ({
  currentPage,
  filters,
  resultsPerPage,
  sortDirection,
  sortKey,
}) => {
  const params = convertToSnakeCase({
    filters: filters,
    page: currentPage,
    resultsPerPage: resultsPerPage,
    sortDirection: sortDirection,
    sortField: sortKey,
  });

  const newUrl = url.format({
    pathname: window.location.pathname,
    search: stringifyQueryObject(params),
  });

  window.history.pushState(null, null, newUrl);
};

const formatColumns = columns =>
  columns.map(column => {
    const { column_size: width, key: name, ...rest } = column;
    return {
      name: name,
      width: width,
      ...rest,
    };
  });

const handleResponse = data => {
  const response = convertToCamelCase(data);
  const {
    columns,
    resultsPerPage,
    sortDirection,
    sortField,
    totalPages,
    totalResults,
  } = response.metadata;
  const selectedColumns = formatColumns(columns);
  return {
    selectedColumns: selectedColumns,
    recordList: data.search_requests.map(record => ({
      id: record[0].id,
      attributes: selectedColumns.map(column => ({
        ...record.find(attribute => column.name === attribute.key),
        ...column,
      })),
    })),
    totalPages: totalPages,
    totalResults: totalResults,
    resultsPerPage: resultsPerPage,
    sortDirection: sortDirection,
    sortKey: sortField,
  };
};

const requestSearchRequestList = () => (dispatch, getState) => {
  const { searchRequest } = getState();
  dispatch(requestSearchRequestListBegin());
  const { shouldUpdateBrowserUrl } = searchRequest;
  const endpointUrl = createEndpointUrl(searchRequest);
  Api.get(endpointUrl)
    .then(json => handleResponse(json))
    .then(searchRequestList =>
      dispatch(requestSearchRequestListSuccess(searchRequestList)),
    )
    .then(() => {
      if (shouldUpdateBrowserUrl) {
        updateBrowserUrl(searchRequest);
      }
    })
    .catch(error =>
      dispatch(requestSearchRequestListError(getErrorMessage(error))),
    );
};

const changeFilters = filters => (dispatch, getState) => {
  const { searchRequest } = getState();
  const newFilters = { ...searchRequest.filters, ...filters };
  dispatch(setFilters(newFilters));
  dispatch(requestSearchRequestList());
};

const removeFilter = filterKey => (dispatch, getState) => {
  const { searchRequest } = getState();
  const newFilters = omit(searchRequest.filters, filterKey);
  dispatch(setFilters(newFilters));
  dispatch(requestSearchRequestList());
};

const clearFilters = () => dispatch => {
  dispatch(setFilters({}));
  dispatch(requestSearchRequestList());
};

const saveResultsPerPage = val => dispatch => {
  dispatch(setResultsPerPage(val));
  localStorage.setItem(RESULTS_PER_PAGE_LOCALSTORAGE_KEY, val);
};

export {
  changeFilters,
  clearFilters,
  createSearchRequest,
  editSearchRequest,
  loadSearchRequest,
  removeFilter,
  requestSearchRequestList,
  resetCreateSearchRequest,
  saveResultsPerPage,
  setCurrentPage,
  setFilters,
  setSelectedRecords,
  setSortParams,
};
export default searchRequestSlice;
