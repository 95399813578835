import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@thrivetrm/ui/components/Modal';
import Form from '@thrivetrm/ui/components/Form';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import SkyminyrCompanySelectForm from './SkyminyrCompanySelectForm';

const SkyminyrCompanySelectModal = ({
  actionConfirmationText,
  canCreateNewCompany,
  canSelectThriveRecords,
  companySearchGuidance,
  initialInputValue,
  isLoading,
  isOpen,
  onClose,
  onCreate,
  onSubmit,
  queryParams,
  shouldNavigateToNewCompany,
  submitButtonLabel,
  title,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size='small' title={title}>
      <Form id='SkyminyrCompanySelectForm' onSubmit={onSubmit}>
        <Modal.Body className='ModalBody--noClip'>
          <SkyminyrCompanySelectForm
            actionConfirmationText={actionConfirmationText}
            canCreateNewCompany={canCreateNewCompany}
            canSelectThriveRecords={canSelectThriveRecords}
            companySearchGuidance={companySearchGuidance}
            initialInputValue={initialInputValue}
            onCreate={onCreate}
            queryParams={queryParams}
            shouldNavigateToNewCompany={shouldNavigateToNewCompany}
          />
        </Modal.Body>
        <Modal.Footer>
          <ButtonPrimary isOutline={true} label='Cancel' onClick={onClose} />
          <Form.SubmitButton
            isDisabled={isLoading}
            isLoading={isLoading}
            label={submitButtonLabel}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

SkyminyrCompanySelectModal.defaultProps = {
  submitButtonLabel: 'Save',
};

SkyminyrCompanySelectModal.propTypes = {
  actionConfirmationText: PropTypes.node,
  canCreateNewCompany: PropTypes.bool,
  canSelectThriveRecords: PropTypes.bool,
  companySearchGuidance: PropTypes.node,
  initialInputValue: PropTypes.string,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  queryParams: PropTypes.shape({
    resource: PropTypes.string,
  }),
  shouldNavigateToNewCompany: PropTypes.bool,
  submitButtonLabel: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default SkyminyrCompanySelectModal;
