import { fromJS } from 'immutable';
import createQueryReducer from '../../../reducers/createQueryReducer';
import {
  QUERY_CREATE,
  QUERY_DESTROY,
  QUERY_INVALIDATE,
  QUERY_FETCH_START,
  QUERY_FETCH_SUCCESS,
  QUERY_FETCH_FAILURE,
} from '../actions/queries/actionTypes';

export default createQueryReducer({
  create: QUERY_CREATE,
  destroy: QUERY_DESTROY,
  start: QUERY_FETCH_START,
  success: QUERY_FETCH_SUCCESS,
  failure: QUERY_FETCH_FAILURE,
  invalidate: QUERY_INVALIDATE,

  // Eventually it might make sense to update the `createQueryReducer` helper to handle
  // our normalized response, but in the meantime we can use the `updateData` prop to
  // manually set the data value from the `result` values that normalizr provides.
  updateData: (data, { payload }) => fromJS(payload.result),
});
