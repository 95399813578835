import React from 'react';
import { Provider } from 'react-redux';
import store from '../../store';

/**
 * Connects our redux store to the application.
 */
const StateProvider = props => <Provider store={store} {...props} />;

/**
 * HOC version
 */
export const withStateProvider = BaseComponent => props => (
  <StateProvider>
    <BaseComponent {...props} />
  </StateProvider>
);

export default StateProvider;
