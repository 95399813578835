import getSubdomain from './getSubdomain';
import { TENANT_TRUE } from '../constants';

/**
 * Gets the title that should be displayed for the entire application.
 * In most cases this is "Thrive TRM", but for True we show their name.
 */
export default state => {
  const subdomain = getSubdomain(state);
  return subdomain === TENANT_TRUE ? 'True' : 'Thrive TRM';
};
