import { NAME } from '../constants';

export const TALENT_POOLS_INVALIDATE = `${NAME}/TALENT_POOLS_INVALIDATE`;

export const TALENT_POOLS_FETCH_START = `${NAME}/TALENT_POOLS_FETCH_START`;
export const TALENT_POOLS_FETCH_SUCCESS = `${NAME}/TALENT_POOLS_FETCH_SUCCESS`;
export const TALENT_POOLS_FETCH_FAILURE = `${NAME}/TALENT_POOLS_FETCH_FAILURE`;

export const TALENT_POOL_INVALIDATE = `${NAME}/TALENT_POOL_INVALIDATE`;

export const TALENT_POOL_FETCH_START = `${NAME}/TALENT_POOL_FETCH_START`;
export const TALENT_POOL_FETCH_SUCCESS = `${NAME}/TALENT_POOL_FETCH_SUCCESS`;
export const TALENT_POOL_FETCH_FAILURE = `${NAME}/TALENT_POOL_FETCH_FAILURE`;

export const TALENT_POOL_CREATE_START = `${NAME}/TALENT_POOL_CREATE_START`;
export const TALENT_POOL_CREATE_SUCCESS = `${NAME}/TALENT_POOL_CREATE_SUCCESS`;
export const TALENT_POOL_CREATE_FAILURE = `${NAME}/TALENT_POOL_CREATE_FAILURE`;

export const TALENT_POOL_UPDATE_START = `${NAME}/TALENT_POOL_UPDATE_START`;
export const TALENT_POOL_UPDATE_SUCCESS = `${NAME}/TALENT_POOL_UPDATE_SUCCESS`;
export const TALENT_POOL_UPDATE_FAILURE = `${NAME}/TALENT_POOL_UPDATE_FAILURE`;

export const TALENT_POOL_DELETE_START = `${NAME}/TALENT_POOL_DELETE_START`;
export const TALENT_POOL_DELETE_SUCCESS = `${NAME}/TALENT_POOL_DELETE_SUCCESS`;
export const TALENT_POOL_DELETE_FAILURE = `${NAME}/TALENT_POOL_DELETE_FAILURE`;

export const TALENT_POOL_REPORT_CREATE_START = `${NAME}/TALENT_POOL_REPORT_CREATE_START`;
export const TALENT_POOL_REPORT_CREATE_SUCCESS = `${NAME}/TALENT_POOL_REPORT_CREATE_SUCCESS`;
export const TALENT_POOL_REPORT_CREATE_FAILURE = `${NAME}/TALENT_POOL_REPORT_CREATE_FAILURE`;
