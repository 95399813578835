import { connect } from 'react-redux';
import { PARENT_PIPELINE } from 'modules/candidacies/constants';
import getCandidacyProperty from '../selectors/getCandidacyProperty';
/**
 * A Higher order component that filters a list of candidacyIds to only include those that have
 * at least one of the tags contained in selectedTagIds. It also adds an `isFiltered` prop so the
 * base component can know when it's candidacyIds have been filtered.
 */
export default connect(
  (state, { candidacyIds, isFiltered, parentType, selectedTagIds }) => ({
    isFiltered: isFiltered || true,
    candidacyIds:
      candidacyIds &&
      candidacyIds.filter(id =>
        getCandidacyProperty(
          state,
          id,
          parentType === PARENT_PIPELINE ? 'smart_tag_ids' : 'tag_ids',
        ).some(tagId => selectedTagIds.includes(tagId)),
      ),
  }),
  {},
);
