import { PARENT_TYPES } from '../constants';
import getCommentList from './getCommentList';

/**
 * Determines if a list of comments should be fetched.
 * @param {Object} state
 * @param {String} parentType The parent type (PARENT_CONTACT or PARENT_CANDIDACY)
 * @param {Number} parentId The parent ID (a contact ID or CANDIDACY ID, depending on
 *   the value of `parentType`)
 * @return {Boolean} True if the list should be fetched from the server, false if we have the
 *   data and a fetch is not needed.
 */
export default (state, parentType, parentId) => {
  if (!PARENT_TYPES.includes(parentType)) {
    throw new Error(`Invalid parentType: ${parentType}`);
  }

  if (!parentId) {
    return false;
  }

  const comments = getCommentList(state, parentType, parentId);

  if (!comments) {
    return true;
  }

  if (comments.getIn(['_meta', 'isFetching'])) {
    return false;
  }

  if (comments.hasIn(['_meta', 'error'])) {
    return false;
  }

  return (
    !comments.has('ids') || Boolean(comments.getIn(['_meta', 'isInvalidated']))
  );
};
