import {
  NOTIFICATIONS_CLEAR,
  NOTIFICATIONS_INVALIDATE,
  NOTIFICATIONS_FETCH_START,
  NOTIFICATIONS_FETCH_SUCCESS,
  NOTIFICATIONS_FETCH_FAILURE,
} from '../../domains/notifications/constants';
import notifications, { INITIAL_STATE } from './notifications';

export { INITIAL_STATE };

/**
 * Reduces the notifications just for a specific category. Only actions that pertain to the
 * category provided will be passed on to the `notifications` reducer, others will be ignored.
 * @param {String} category The category ("read", "unread") that this collection contains.
 */
export default function forCategory(category, state = INITIAL_STATE, action) {
  if (!category) {
    throw new Error('forCategory reducer must be called with a category');
  }

  switch (action.type) {
    case NOTIFICATIONS_CLEAR:
    case NOTIFICATIONS_INVALIDATE:
    case NOTIFICATIONS_FETCH_START:
    case NOTIFICATIONS_FETCH_SUCCESS:
    case NOTIFICATIONS_FETCH_FAILURE: {
      if (action.payload.category !== category) {
        return state;
      }

      return notifications(state, action);
    }
    default: {
      return notifications(state, action);
    }
  }
}
