import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import React, { PureComponent } from 'react';
import { fromJS } from 'immutable';
import classnames from 'classnames';
import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import * as validators from 'modules/forms/validators';
/**
 * A field that can be used for creating a basic company record.
 */
class NewCompanyFields extends PureComponent {
  static createFieldState(name = 'company', companyName, company, ...rest) {
    const values = fromJS({
      name: '',
      website_url: '',
    }).merge(company);

    return FieldState.createNested(
      name,
      [
        InputField.createFieldState(
          'name',
          companyName || values.get('name'),
          validators.requiredField('Company name'),
        ),
        InputField.createFieldState(
          'website_url',
          values.get('website_url'),
          validators.isUrl,
        ),
      ],
      ...rest,
    );
  }

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    onChange(
      fieldState.setNestedField(childFieldState),
      childFieldState.getName(),
    );
  };

  render() {
    const {
      duplicateIds,
      exactMatchCompany,
      fieldState,
      onChange: _onChange,
      queryDuplicates: _queryDuplicates,
      ...otherProps
    } = this.props;

    return (
      <div className='NewCompanyFields'>
        <InputField
          {...otherProps}
          className={classnames({
            'has-error': !exactMatchCompany?.isEmpty(),
          })}
          fieldState={fieldState.getNestedField('name')}
          isRequired={true}
          label='Name*'
          onChange={this.handleFieldChange}
        />
        <InputField
          {...otherProps}
          className={classnames({
            'has-error': !duplicateIds.website_url.isEmpty(),
          })}
          fieldState={fieldState.getNestedField('website_url')}
          label='Website URL'
          onChange={this.handleFieldChange}
        />
      </div>
    );
  }
}

NewCompanyFields.propTypes = {
  /**
   * The duplicateIds object that we use to determine error state on the InputFields
   */
  duplicateIds: PropTypes.shape({
    id: PropTypes.number,
    name: ImmutablePropTypes.list,
    website_url: ImmutablePropTypes.list,
  }),

  exactMatchCompany: ImmutablePropTypes.list,
  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Called when the field is changed with the updated FieldState object.
   */
  onChange: PropTypes.func,

  queryDuplicates: PropTypes.bool,
};

export default NewCompanyFields;
