import getUserPermission from 'modules/auth/selectors/getUserPermission';

/**
 * Gets a value indicating whether the current user has permission to create a new company record.
 * @param {Object} state
 * @returns {Boolean} True if the current user is allowed to create companies; otherwise, false.
 */
const canCreateCompany = state => !getUserPermission(state, 'candidate', true);

export default canCreateCompany;
