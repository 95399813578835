import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as actions from '../actions';

/**
 * A higher order component that connects the position actions to the dispatcher
 * and provides them as a `positionActions` prop.
 */
export default connect(null, dispatch => ({
  positionActions: bindActionCreators(actions, dispatch),
}));
