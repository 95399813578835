import selectn from 'selectn';
import { List } from 'immutable';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import positionSchema from 'modules/positions/schema';

import {
  RATING_CREATE_SUCCESS,
  RATING_DELETE_SUCCESS,
} from '../actions/ratings/actionTypes';

const contactReducer = (state, action) => {
  let contactId;
  let ratingId;
  switch (action.type) {
    case entityActionTypes.CREATE_SUCCESS: {
      if (selectn('payload.entityType', action) !== positionSchema.key) {
        return state;
      }

      const positionId = selectn('payload.result.position', action);
      const position = selectn(
        `payload.entities.${positionSchema}.${positionId}`,
        action,
      );

      contactId = position && position.contact_id;
      if (contactId === state.get('id') && position.primary) {
        return state.set('primary_position', positionId);
      }
      if (state.get('primary_position') === positionId && !position.primary) {
        return state.delete('primary_position');
      }
      return state;
    }
    case entityActionTypes.UPDATE_SUCCESS: {
      if (selectn('payload.entityType', action) !== positionSchema.key) {
        return state;
      }

      const positionId = selectn('payload.result.position', action);
      const position = selectn(
        `payload.entities.${positionSchema}.${positionId}`,
        action,
      );

      if (!position || position.contact_id !== state.get('id')) {
        // We _should_ have a position, so that's just a sanity check.
        // But otherwise, we don't really care about this record because it isn't related to this
        // contact record.
        return state;
      }

      if (position.primary) {
        // This position is the primary position -- ensure the contact record reflects that.
        return state.set('primary_position', positionId);
      }

      if (state.get('primary_position') === positionId) {
        // This is no longer the "primary" position but is set as such, so remove it.
        return state.delete('primary_position');
      }

      return state;
    }
    case entityActionTypes.DELETE_SUCCESS: {
      if (selectn('payload.entityType', action) !== positionSchema.key) {
        return state;
      }

      if (selectn('payload.id', action) === state.get('primary_position')) {
        return state.delete('primary_position');
      }

      return state;
    }
    case RATING_DELETE_SUCCESS:
      ratingId = selectn('payload.id', action);
      if (state.get('ratings', new List()).includes(ratingId)) {
        return state.update('ratings', new List(), ratings =>
          ratings.filter(id => id !== ratingId),
        );
      }
      return state;
    case RATING_CREATE_SUCCESS:
      contactId = selectn('payload.rating.contact_id', action);
      ratingId = selectn('payload.result.rating', action);
      if (
        contactId === state.get('id') &&
        !state.get('ratings').includes(ratingId)
      ) {
        return state.update('ratings', new List(), ratings =>
          ratings.push(ratingId),
        );
      }
      return state;
    default:
      return state;
  }
};

export default contactReducer;
