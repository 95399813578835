import { NAME } from '../constants';

/**
 * Gets the current tenant's display name
 * @param {Object} state
 * @return {String} The current tenant's name.
 */
const getTenantName = state => state[NAME].get('tenant_name');

export default getTenantName;
