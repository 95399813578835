//---------------------------------------------------------
// Module name / root reducer name
//---------------------------------------------------------

export const NAME = 'company-investments';

//---------------------------------------------------------
// Sorting
//---------------------------------------------------------

export const SORT_BY_ROUND_DATE = 'date';
export const SORT_BY_COMPANY_STAGE = 'company_stage';
export const SORT_BY_ROUND_AMOUNT = 'amount';
