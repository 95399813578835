import { withStateHandlers } from 'recompose';

/**
 * Provides the following state props:
 * * `isFocused` - True when the component is currently focused
 * * `wasFocused` - True if the component has ever been focused
 * * `wasBlurred` - True if the component has ever been focused and then blurred
 * Based on the following callback props:
 * * `onFocus` - should be called when the component to track is focused
 * * `onBlur` - should be called when the component to track is blurred
 */
export default withStateHandlers(
  {
    /**
     * True when the underlying control has focus.
     * @type {Boolean}
     */
    isFocused: false,

    /**
     * True if this field has ever been focused.
     * @type {Boolean}
     */
    wasFocused: false,

    /**
     * True if this feel has ever lost focus.
     * @type {Boolean}
     */
    wasBlurred: false,
  },
  {
    /**
     * Called when the control component is focused.
     * @param  {Event} event The event that triggered the focus
     * (usually a SyntheticEvent)
     */
    onFocus: (_, { onFocus }) => event => {
      // Make sure that if an onFocus prop was included by someone using this
      // component that it is called through as well.
      if (onFocus) {
        onFocus(event);
      }

      if (!event || !event.defaultPrevented) {
        return { wasFocused: true, isFocused: true };
      }

      return {};
    },

    /**
     * Called when the control component loses focus.
     * @param  {Event} event The event that triggered the focus
     * (usually a SyntheticEvent)
     */
    onBlur: (_, { onBlur }) => event => {
      // Make sure that if an onBlur prop was included by someone using this
      // component that it is called through as well.
      if (onBlur) {
        onBlur(event);
      }

      if (!event || !event.defaultPrevented) {
        return { wasBlurred: true, isFocused: false };
      }

      return {};
    },
  },
);
