import getCandidacyProperty from './getCandidacyProperty';

/**
 * Gets the hidden candidacy records
 * @param {Object} state
 * @param {Number} id The candidacy ID
 * @return {Boolean} true if hidden is set to true, false if it not.
 */
export default (state, candidacyId) =>
  getCandidacyProperty(state, candidacyId, 'hidden');
