import { branch, compose, setDisplayName, withProps } from 'recompose';
import connectTransactions from 'modules/transactions/components/connectTransactions';

import CommentField, { createFieldState } from './CommentField';
import connectCommentActions from './connectCommentActions';
import handleSubmit from './handleSubmit';
import mapCommentIdToComment from './mapCommentIdToComment';

import withTransactionTrackedForm from '../../../components/forms/withTransactionTrackedForm';

export default compose(
  setDisplayName('CommentForm'),
  connectTransactions,
  connectCommentActions,
  branch(props => props.commentId && !props.comment, mapCommentIdToComment),
  withProps({ focusOnMount: true }),
  withTransactionTrackedForm(
    ({ comment }) => createFieldState(comment),
    handleSubmit,
    {
      formClassName: 'comment-form',
      omitProps: [
        'candidacyId',
        'contactId',
        'commentActions',
        'commentId',
        'formState',
        'parentCommentId',
        'parentId',
        'parentType',
        'searchId',
        'transactionActions',
      ],
    },
  ),
)(CommentField);
