import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import assessmentLists from './assessmentLists';
import candidacyInviteTokens from './candidacyInviteTokens';
import guestAssessments from './guestAssessments';
import options from './options';
import searchAttributes from './searchAttributes';

const assessmentsReducer = combineImmutableReducers({
  assessmentLists: assessmentLists,
  candidacyInviteTokens: candidacyInviteTokens,
  guestAssessments: guestAssessments,
  options: options,
  searchAttributes: searchAttributes,
});

assessmentsReducer.NAME = NAME;

export default assessmentsReducer;
