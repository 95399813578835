import selectn from 'selectn';
import { fromJS, Map } from 'immutable';
import createListReducer from '../../../reducers/createListReducer';
import composeReducers from '../../../reducers/composeReducers';
import {
  CONTACT_FETCH_START,
  CONTACT_FETCH_SUCCESS,
  CONTACT_FETCH_FAILURE,
  CONTACT_INVALIDATE,
} from '../actions/contacts/actionTypes';
import {
  EDUCATIONS_FETCH_START,
  EDUCATIONS_FETCH_SUCCESS,
  EDUCATIONS_FETCH_FAILURE,
  EDUCATION_CREATE_SUCCESS,
  EDUCATION_DELETE_SUCCESS,
} from '../actions/educations/actionTypes';
import { contactSchema } from '../schema';

const educationsListReducer = createListReducer({
  resultKey: 'educations',
  entityResultKey: 'education',
  request: EDUCATIONS_FETCH_START,
  success: EDUCATIONS_FETCH_SUCCESS,
  failure: EDUCATIONS_FETCH_FAILURE,
  created: EDUCATION_CREATE_SUCCESS,
  deleted: EDUCATION_DELETE_SUCCESS,
});

/**
 * contains educations keyed on the contact Id.
 * educations are also be fetched with the contact entity itself, so we check
 * for that as well.
 */
export default composeReducers(
  (state = new Map(), action) => {
    switch (action.type) {
      case CONTACT_FETCH_START: {
        const contactId = selectn('payload.id', action);

        if (contactId) {
          return state.setIn([contactId, '_meta', 'isFetching'], true);
        }

        return state;
      }
      case CONTACT_FETCH_FAILURE: {
        const contactId = selectn('payload.id', action);

        if (contactId) {
          return state
            .setIn([contactId, '_meta', 'isFetching'], false)
            .setIn([contactId, '_meta', 'error'], action.payload.error);
        }

        return state;
      }
      case CONTACT_FETCH_SUCCESS: {
        const contactId = selectn('payload.id', action);

        if (contactId) {
          // Note that the educations will be set in the reducer below.
          return state
            .setIn([contactId, '_meta', 'isFetching'], false)
            .deleteIn([contactId, '_meta', 'error']);
        }

        return state;
      }
      case CONTACT_INVALIDATE: {
        // When the contact is invalidated, invalidate the educations as well.
        const contactId = selectn('payload.id', action);

        if (contactId && state.has(contactId)) {
          return state.setIn([contactId, '_meta', 'isInvalidated'], true);
        }

        return state;
      }
      case EDUCATIONS_FETCH_START:
      case EDUCATIONS_FETCH_SUCCESS:
      case EDUCATIONS_FETCH_FAILURE: {
        const contactId = selectn('payload.contactId', action);

        if (contactId) {
          return state.update(contactId, list =>
            educationsListReducer(list, action),
          );
        }

        return state;
      }
      case EDUCATION_CREATE_SUCCESS: {
        const educationId = selectn('payload.result.education', action);
        const education = selectn(
          `payload.entities.educations.${educationId}`,
          action,
        );

        if (
          education &&
          education.contact_id &&
          state.has(education.contact_id)
        ) {
          return state.update(education.contact_id, list =>
            educationsListReducer(list, action),
          );
        }

        return state;
      }
      default: {
        return state.map(list => educationsListReducer(list, action));
      }
    }
  },
  (state, action) => {
    // Check to see if any contact entities have been fetched, and if they have educations
    // included.
    const contacts = selectn('payload.entities.contacts', action);

    if (contacts) {
      const lists = Object.keys(contacts)
        .map(contactId => contacts[contactId])
        .filter(contact => contact.educations)
        .map(contact => [
          contactSchema.getId(contact),
          fromJS({
            ids: contact.educations,
            _meta: {
              error: null,
              isFetching: false,
              isInvalidated: false,
              lastFetched: Date.now(),
            },
          }),
        ]);

      if (lists.length) {
        return state.mergeDeep(lists);
      }
    }

    return state;
  },
);
