import { connect } from 'react-redux';
import getSearchProperty from '../selectors/getSearchProperty';

/**
 * A higher order component which maps `searchId` to an underlying property value on the search
 * record.
 * @param {String} propertyName The name of the search property
 * @param {String} propName The prop name to assign the value to on the component.
 */
export default (propertyName, propName) =>
  connect(
    (state, { searchId }) => ({
      [propName]: searchId && getSearchProperty(state, searchId, propertyName),
    }),
    {},
  );
