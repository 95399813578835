import selectn from 'selectn';
import { fromJS } from 'immutable';
import paramsEqual from 'modules/routing/selectors/paramsEqual';
import createListReducer from '../../../reducers/createListReducer';
import {
  CONTACTS_FETCH_START,
  CONTACTS_FETCH_SUCCESS,
  CONTACTS_FETCH_FAILURE,
  CONTACTS_LIST_INVALIDATE,
} from '../actions/contacts/actionTypes';
import { FETCH_PARAMS } from '../constants';

const INITIAL_STATE = fromJS({});

const contactsListReducer = createListReducer({
  resultKey: 'contacts',
  request: CONTACTS_FETCH_START,
  success: CONTACTS_FETCH_SUCCESS,
  failure: CONTACTS_FETCH_FAILURE,
  invalidated: CONTACTS_LIST_INVALIDATE,
});

/**
 * A map of search lists keyed by a provided list ID
 */
export default (state = INITIAL_STATE, action) => {
  const listId = selectn('payload.listId', action);

  if (!listId) {
    return state;
  }

  switch (action.type) {
    case CONTACTS_FETCH_START: {
      return state
        .update(listId, listState => contactsListReducer(listState, action))
        .setIn([listId, 'params'], fromJS(selectn('payload.params', action)));
    }
    case CONTACTS_FETCH_FAILURE:
    case CONTACTS_FETCH_SUCCESS: {
      // Check to make sure that the current params we have in state match the params
      // in the action. This assures that if we make 2 requests in quick succession --
      // say "page=1" then "page=2", that once we get a responses we only care about the most
      // recent request. Otherwise the response for page 1 could potentially overwrite the
      // response for page 2 if they came back in a different order than requested.
      const params = state.getIn([listId, 'params']);
      if (
        !paramsEqual(
          params && params.toJS(),
          selectn('payload.params', action),
          FETCH_PARAMS,
        )
      ) {
        return state;
      }

      return state
        .update(listId, listState => contactsListReducer(listState, action))
        .setIn(
          [listId, 'pagination'],
          fromJS(selectn('payload.result.meta', action)),
        );
    }
    case CONTACTS_LIST_INVALIDATE: {
      return state.update(listId, listState =>
        contactsListReducer(listState, action),
      );
    }
    default: {
      return state;
    }
  }
};
