import { OrderedMap, fromJS, Map, List } from 'immutable';
import {
  NOTIFICATIONS_CLEAR,
  NOTIFICATIONS_INVALIDATE,
  NOTIFICATIONS_FETCH_START,
  NOTIFICATIONS_FETCH_SUCCESS,
  NOTIFICATIONS_FETCH_FAILURE,
  NOTIFICATIONS_STATE__ALL_SELECTED,
  NOTIFICATIONS_STATE__NONE_SELECTED,
  NOTIFICATIONS_CREATED,
} from '../../domains/notifications/constants';
import notifications from '../notifications/notifications';
import notification from '../notifications/notification';

export const INITIAL_STATE = fromJS({
  meta: {
    isFetching: false,
    isInvalidated: false,
    filters: {
      types: NOTIFICATIONS_STATE__ALL_SELECTED,
    },
  },
});

export default function contactNotifications(
  contactId,
  state = INITIAL_STATE,
  action,
) {
  if (!contactId) {
    throw new Error(
      'contactNotifications reducer must be called with a contactId',
    );
  }

  switch (action.type) {
    case NOTIFICATIONS_CLEAR:
      if (action.payload.contactId !== contactId) {
        return state;
      }

      return state.withMutations(map => {
        map.setIn(
          ['meta', 'filters', 'types'],
          NOTIFICATIONS_STATE__NONE_SELECTED,
        );
        map.set('data', new List());
      });

    case NOTIFICATIONS_INVALIDATE:
      if (action.payload.contactId !== contactId) {
        return state;
      }

      return notifications(state, action);
    case NOTIFICATIONS_FETCH_START:
      if (action.payload.contactId !== contactId) {
        return state;
      }

      return notifications(state, action).setIn(
        ['meta', 'previousFilters'],
        state.getIn(['meta', 'filters']),
      );
    case NOTIFICATIONS_FETCH_SUCCESS:
      if (action.payload.contactId !== contactId) {
        return state;
      }

      return notifications(state, action).deleteIn(['meta', 'previousFilters']);
    case NOTIFICATIONS_FETCH_FAILURE: {
      if (action.payload.contactId !== contactId) {
        return state;
      }

      return notifications(state, action)
        .setIn(['meta', 'filters'], state.getIn(['meta', 'previousFilters']))
        .deleteIn(['meta', 'previousFilters']);
    }
    case NOTIFICATIONS_CREATED: {
      const { data } = action.payload;
      return state.set(
        'data',
        new OrderedMap(
          data.notifications.map(notificationItem => [
            notificationItem.id,
            new Map({
              data: notification(notificationItem),
              meta: new Map(),
            }),
          ]),
        ).concat(state.get('data')),
      );
    }
    default: {
      return notifications(state, action);
    }
  }
}
