/* eslint-disable camelcase */
// ^ accommodate API exchange format
import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import normalizeResponse from 'modules/api/actionModifiers/normalizeResponse';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import modifySuccessResponse from 'modules/api/actionModifiers/modifySuccessResponse';
import withOptions from 'modules/api/actionModifiers/withOptions';

import assessmentQuestionSetSchema from '../schemas/assessmentQuestionSets';
import citizenshipSchema from '../schemas/citizenships';
import companyDocumentLabelSchema from '../schemas/companyDocumentLabels';
import companyInvestmentStagesSchema from '../schemas/companyInvestmentStages';
import companyPrioritySchema from '../schemas/companyPriorities';
import contactDocumentLabelSchema from '../schemas/contactDocumentLabels';
import contactLevelSchema from '../schemas/contactLevels';
import contactPrefixSchema from '../schemas/contactPrefixes';
import contactSourceSchema from '../schemas/contactSources';
import countrySchema from '../schemas/countries';
import currencySchema from '../schemas/currencies';
import demographicSourceSchema from '../schemas/demographicSources';
import disabilityStatusSchema from '../schemas/disabilityStatuses';
import genderSchema from '../schemas/genders';
import industrySchema from '../schemas/industries';
import interactionLevelSchema from '../schemas/interactionLevels';
import interviewQuestionSetSchema from '../schemas/interviewQuestionSets';
import jobFunctionSchema from '../schemas/jobFunctions';
import languageSchema from '../schemas/languages';
import levelSchema from '../schemas/levels';
import numberOfEmployeesSchema from '../schemas/numberOfEmployees';
import placementTypeSchema from '../schemas/placementTypes';
import positionFunctionsSchema from '../schemas/positionFunctions';
import productAreaSchema from '../schemas/productAreas';
import pronounSchema from '../schemas/pronouns';
import provinceSchema from '../schemas/provinces';
import raceEthnicitySchema from '../schemas/raceEthnicities';
import revenueGrowthSchema from '../schemas/revenueGrowths';
import searchDocumentLabelSchema from '../schemas/searchDocumentLabels';
import sectorSchema from '../schemas/sectors';
import seekingJobReasonSchema from '../schemas/seekingJobReasons';
import sexualOrientationSchema from '../schemas/sexualOrientations';
import skillSchema from '../schemas/skills';
import stateSchema from '../schemas/states';
import targetFunctionSchema from '../schemas/targetFunctions';
import targetMarketSchema from '../schemas/targetMarkets';
import veteranStatusSchema from '../schemas/veteranStatuses';

import {
  TENANT_OPTIONS_FETCH_START,
  TENANT_OPTIONS_FETCH_SUCCESS,
  TENANT_OPTIONS_FETCH_FAILURE,
} from './actionTypes';

/**
 * Fetches all tenant options for the current tenant.
 */
export default compose(
  fetchAction,

  // This does two things:
  // 1. Ensures that all keys match their underlying schema keys. This makes things
  //    easier when we want to look things up because we won't have to know what the
  //    key on `tenantOptions` is -- it will always be the same as the schema key.
  // 2. We have to tweak the response for some options because they come back in a weird
  //    format that we can't use directly with normalizr.
  modifySuccessResponse(
    ({
      tenant_options: {
        assessment_question_sets,
        citizenships,
        company_document_labels,
        company_priorities,
        company_stages,
        contact_document_labels,
        contact_levels,
        contact_prefixes,
        contact_sources,
        countries,
        currencies,
        demographic_sources,
        disability_statuses,
        genders,
        industries,
        interaction_levels,
        interview_question_sets,
        job_functions,
        languages,
        levels,
        number_of_employees,
        placement_types,
        position_functions,
        product_areas,
        pronouns,
        provinces,
        race_ethnicities,
        revenue_growths,
        search_document_labels,
        sectors,
        seeking_job_reasons,
        sexual_orientations,
        skills,
        states,
        target_functions,
        target_markets,
        veteran_statuses,
        ...options
      },
    }) => ({
      tenant_options: {
        [assessmentQuestionSetSchema.key]: assessment_question_sets,
        [citizenshipSchema.key]: citizenships,
        [companyDocumentLabelSchema.key]: company_document_labels,
        [companyInvestmentStagesSchema.key]: company_stages,
        [companyPrioritySchema.key]: company_priorities,
        [contactDocumentLabelSchema.key]: contact_document_labels,
        [contactLevelSchema.key]: contact_levels,
        [contactPrefixSchema.key]: contact_prefixes,
        [contactSourceSchema.key]: contact_sources,
        [countrySchema.key]: countries,
        [currencySchema.key]: currencies,
        [demographicSourceSchema.key]: demographic_sources,
        [disabilityStatusSchema.key]: disability_statuses,
        [genderSchema.key]: genders,
        [industrySchema.key]: industries,
        [interactionLevelSchema.key]: interaction_levels,
        [interviewQuestionSetSchema.key]: interview_question_sets,
        [jobFunctionSchema.key]: job_functions,
        [languageSchema.key]: languages,
        [levelSchema.key]: levels,
        [numberOfEmployeesSchema.key]: number_of_employees,
        [placementTypeSchema.key]: placement_types,
        [positionFunctionsSchema.key]: position_functions,
        [productAreaSchema.key]: product_areas,
        [pronounSchema.key]: pronouns,
        [provinceSchema.key]: provinces,
        [raceEthnicitySchema.key]: race_ethnicities,
        [revenueGrowthSchema.key]: revenue_growths,
        [searchDocumentLabelSchema.key]: search_document_labels,
        [sectorSchema.key]: sectors,
        [seekingJobReasonSchema.key]: seeking_job_reasons,
        [sexualOrientationSchema.key]: sexual_orientations,
        [skillSchema.key]: skills,
        [stateSchema.key]: states,
        [targetFunctionSchema.key]: target_functions,
        [targetMarketSchema.key]: target_markets,
        [veteranStatusSchema.key]: veteran_statuses,
        // Pass-through everything else as-is (i.e. "portfolio_companies")
        ...options,
      },
    }),
  ),
  normalizeResponse({
    tenant_options: {
      [assessmentQuestionSetSchema.key]: [assessmentQuestionSetSchema],
      [citizenshipSchema.key]: [citizenshipSchema],
      [companyDocumentLabelSchema.key]: [companyDocumentLabelSchema],
      [companyInvestmentStagesSchema.key]: [companyInvestmentStagesSchema],
      [companyPrioritySchema.key]: [companyPrioritySchema],
      [contactDocumentLabelSchema.key]: [contactDocumentLabelSchema],
      [contactLevelSchema.key]: [contactLevelSchema],
      [contactPrefixSchema.key]: [contactPrefixSchema],
      [contactSourceSchema.key]: [contactSourceSchema],
      [countrySchema.key]: [countrySchema],
      [currencySchema.key]: [currencySchema],
      [demographicSourceSchema.key]: [demographicSourceSchema],
      [disabilityStatusSchema.key]: [disabilityStatusSchema],
      [genderSchema.key]: [genderSchema],
      [industrySchema.key]: [industrySchema],
      [interactionLevelSchema.key]: [interactionLevelSchema],
      [interviewQuestionSetSchema.key]: [interviewQuestionSetSchema],
      [jobFunctionSchema.key]: [jobFunctionSchema],
      [languageSchema.key]: [languageSchema],
      [levelSchema.key]: [levelSchema],
      [numberOfEmployeesSchema.key]: [numberOfEmployeesSchema],
      [placementTypeSchema.key]: [placementTypeSchema],
      [positionFunctionsSchema.key]: [positionFunctionsSchema],
      [pronounSchema.key]: [pronounSchema],
      [provinceSchema.key]: [provinceSchema],
      [productAreaSchema.key]: [productAreaSchema],
      [raceEthnicitySchema.key]: [raceEthnicitySchema],
      [revenueGrowthSchema.key]: [revenueGrowthSchema],
      [searchDocumentLabelSchema.key]: [searchDocumentLabelSchema],
      [sectorSchema.key]: [sectorSchema],
      [seekingJobReasonSchema.key]: [seekingJobReasonSchema],
      [sexualOrientationSchema.key]: [sexualOrientationSchema],
      [skillSchema.key]: [skillSchema],
      [stateSchema.key]: [stateSchema],
      [targetFunctionSchema.key]: [targetFunctionSchema],
      [targetMarketSchema.key]: [targetMarketSchema],
      [veteranStatusSchema.key]: [veteranStatusSchema],
    },
  }),
  createAsyncActions({
    startActionType: TENANT_OPTIONS_FETCH_START,
    successActionType: TENANT_OPTIONS_FETCH_SUCCESS,
    failureActionType: TENANT_OPTIONS_FETCH_FAILURE,
  }),
  withOptions({
    url: '/api/tenant_options',
  }),
);
