import * as stringUtils from '@thrivetrm/ui/utilities/stringUtils';
import getContact from './getContact';

/**
 * Gets the name for a contact usable for sorting (lower cased with whitespace condensed)
 * @param {Object} state
 * @param {Number} id The contact ID
 * @returns {String} The full name for the contact useable for sorting.
 */
export default (state, id) => {
  const contact = getContact(state, id);
  return (
    contact &&
    stringUtils.toSortableString(
      [contact.get('last_name'), contact.get('first_name')].join(' '),
    )
  );
};
