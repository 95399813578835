import { compose } from 'recompose';
import fetchAction from 'modules/api/fetchAction';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withResponseSchema from 'modules/api/actionModifiers/withResponseSchema';
import { FETCH_START, FETCH_SUCCESS, FETCH_FAILURE } from './entityActionTypes';

/**
 * An action creator for fetching and dispatching entity FETCH_* actions.
 * @param {Object} options
 * @param {String} options.url The URL of the request
 * @param {String} options.id The ID of the entity being fetched.
 * @param {Schema} options.responseSchema The normalizr schema that describes the server's response.
 */
export default compose(
  fetchAction,
  withResponseSchema,
  createAsyncActions({
    startActionType: FETCH_START,
    successActionType: FETCH_SUCCESS,
    failureActionType: FETCH_FAILURE,
  }),
);
