import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import introductionListsByParentType from './introductionListsByParentType';

const introductionsReducer = combineImmutableReducers({
  introductionListsByParentType: introductionListsByParentType,
});

introductionsReducer.NAME = NAME;

export default introductionsReducer;
