import { NAME } from '../constants';

export const INTERVIEW_LIST_FETCH_START = `${NAME}/INTERVIEW_LIST_FETCH_START`;
export const INTERVIEW_LIST_FETCH_SUCCESS = `${NAME}/INTERVIEW_LIST_FETCH_SUCCESS`;
export const INTERVIEW_LIST_FETCH_FAILURE = `${NAME}/INTERVIEW_LIST_FETCH_FAILURE`;
export const INTERVIEW_LIST_INVALIDATE = `${NAME}/INTERVIEW_LIST_INVALIDATE`;

export const INTERVIEW_FETCH_START = `${NAME}/INTERVIEW_FETCH_START`;
export const INTERVIEW_FETCH_SUCCESS = `${NAME}/INTERVIEW_FETCH_SUCCESS`;
export const INTERVIEW_FETCH_FAILURE = `${NAME}/INTERVIEW_FETCH_FAILURE`;
export const INTERVIEW_INVALIDATE = `${NAME}/INTERVIEW_INVALIDATE`;

export const INTERVIEW_CREATE_START = `${NAME}/INTERVIEW_CREATE_START`;
export const INTERVIEW_CREATE_SUCCESS = `${NAME}/INTERVIEW_CREATE_SUCCESS`;
export const INTERVIEW_CREATE_FAILURE = `${NAME}/INTERVIEW_CREATE_FAILURE`;

export const INTERVIEW_UPDATE_START = `${NAME}/INTERVIEW_UPDATE_START`;
export const INTERVIEW_UPDATE_SUCCESS = `${NAME}/INTERVIEW_UPDATE_SUCCESS`;
export const INTERVIEW_UPDATE_FAILURE = `${NAME}/INTERVIEW_UPDATE_FAILURE`;

export const INTERVIEW_DELETE_START = `${NAME}/INTERVIEW_DELETE_START`;
export const INTERVIEW_DELETE_SUCCESS = `${NAME}/INTERVIEW_DELETE_SUCCESS`;
export const INTERVIEW_DELETE_FAILURE = `${NAME}/INTERVIEW_DELETE_FAILURE`;
