import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classnames from 'classnames';

/**
 * Renders a containing displaying an error message.
 * May be optionally dismissable, which will render a close button that can remove the message.
 * CMay also include a retry button.
 */
const ErrorAlert = ({
  dismissable,
  error,
  link,
  onRetry,
  retryLabel,
  title,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={classnames('ErrorAlert', 'alert', 'alert-danger', {
        'alert-dismissible': dismissable,
      })}
    >
      {dismissable && (
        <button
          aria-label='Close'
          className='close'
          onClick={handleClose}
          type='button'
        >
          <span aria-hidden='true'>&times;</span>
        </button>
      )}
      <p className='ErrorAlert__body'>
        {title && <span className='ErrorAlert__title'>{title}</span>}
        <span className='ErrorAlert__message'>{error && error.message}</span>
      </p>
      {link && (
        <a href={link.href} rel='noopener noreferrer' target='_blank'>
          {link.label}
        </a>
      )}
      {onRetry && (
        <div className='ErrorAlert__actions' key='actions'>
          <button
            className='ErrorAlert__retry btn btn-sm btn-primary'
            onClick={onRetry}
            type='button'
          >
            {retryLabel}
          </button>
        </div>
      )}
    </div>
  );
};

ErrorAlert.defaultProps = {
  dismissable: true,
  error: null,
  link: null,
  onRetry: null,
  retryLabel: 'Retry',
};

ErrorAlert.propTypes = {
  /**
   * When true, the error will contain a close button that will dismiss the error message.
   * TODO: Does this even work? The button doesn't actually have an onClick handler. It looks
   * like this is relying on bootstrap to be dismissable? But that could cause it to just get
   * rerendered right away. This should be revisitied.
   */
  /* eslint-disable-next-line react/boolean-prop-naming */
  dismissable: PropTypes.bool,

  /**
   * The error with an underlying message that will be shown.
   * TODO: This should probably be simplified so it just takes a string?
   */
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  /**
   * The link to render within the alert
   */
  link: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
  }),

  /**
   * Called when the retry button is clicked. Setting this to a value causes the retry button
   * to be shown; otherwise it will be hidden.
   */
  onRetry: PropTypes.func,

  /**
   * The content to display in the retry button.
   */
  retryLabel: PropTypes.string,

  /**
   * An optional title for the component to be displayed above the error message..
   */
  title: PropTypes.string.isRequired,
};

export default ErrorAlert;
