import PropTypes from 'prop-types';
import React from 'react';
import { fromJS } from 'immutable';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import isCandidateUser from 'modules/auth/selectors/isCandidateUser';

import CreatableCompanySelectField from 'modules/companies/components/CreatableCompanySelectField';

import FieldState from 'modules/forms/FieldState';
import InputField from 'modules/forms/components/InputField';
import TextAreaField from 'modules/forms/components/TextAreaField';

import LevelsTagSelectField from 'modules/tenant/components/LevelsTagSelectField';
import levelSchema from 'modules/tenant/schemas/levels';
import positionFunctionSchema from 'modules/tenant/schemas/positionFunctions';
import PositionFunctionsTagSelectField from 'modules/tenant/components/PositionFunctionsTagSelectField';
import getTenantOptionsIds from 'modules/tenant/selectors/getTenantOptionsIds';
import { isNumber } from 'lodash';

import ReactSelectField from 'modules/forms/components/ReactSelectField';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import SkyminyrLegacyCompanySelect from 'modules/companies/components/skyminyr/SkyminyrLegacyCompanySelect';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import { getSkyminyrCompanyCreatePayload } from 'modules/companies/components/skyminyr/getSkyminyrCompanyCreatePayload';
import PositionOfficeLocationSelectField from './PositionOfficeLocationSelectField';
import PositionDateRangeField from './PositionDateRangeField';

const LAYOUTS = {
  default: {
    name: 'col-6',
    company: 'col-6',
    officeLocation: 'col-6',
    header: 'col-6',
    dateRange: 'col-12',
    description: 'col-12',
    positionFunctions: 'col-6',
    positionLevels: 'col-6',
  },
  narrow: {
    name: 'col-12',
    company: 'col-12',
    officeLocation: 'col-12',
    header: 'col-12',
    dateRange: 'col-12',
    description: 'col-12',
    positionFunctions: 'col-6',
    positionLevels: 'col-6',
  },
};

export const FIELD_LAYOUTS = Object.keys(LAYOUTS);

/**
 * Displays a field for editing a position record.
 */

const PositionField = ({
  children,
  className,
  fieldState,
  header,
  layout,
  onChange,
  onCloseCreateCompanyModal,
  onCreateCompanyClick,
  showPositionFunctions,
  showPositionLevels,
  ...otherProps
}) => {
  const fieldLayout = LAYOUTS[layout];

  const handleFieldChange = childFieldState => {
    onChange(fieldState.setNestedField(childFieldState));
  };

  const allowCompanyCreation = useSelector(state => !isCandidateUser(state));

  const positionFunctionPresent = useSelector(
    state =>
      getTenantOptionsIds(state, positionFunctionSchema.key) &&
      getTenantOptionsIds(state, positionFunctionSchema.key).size > 0,
  );

  const positionLevelsPresent = useSelector(
    state =>
      getTenantOptionsIds(state, levelSchema.key) &&
      getTenantOptionsIds(state, levelSchema.key).size > 0,
  );
  const hasPositionAddressFeatureEnabled = useFeatureCheck(
    'feature.position_address',
  );

  const hasSkyminyrFeature = useFeatureCheck('feature.skyminyr');
  const companyIdOrName = hasSkyminyrFeature
    ? fieldState.getNestedField('company').getValue()
    : fieldState.getNestedField('company').getNestedField('company').getValue();

  const isCreatingNewCompany =
    typeof companyIdOrName === 'string' ||
    Boolean(companyIdOrName?.data?.skyminyr_id);
  const companyNotInThriveHtmlId = uniqueId('unsaved-company');

  return (
    <div className={classnames('PositionField', 'row', className)}>
      {header && (
        <div className={classnames(fieldLayout.header)} key='header'>
          <h4>{header}</h4>
        </div>
      )}
      {children}
      <div className={classnames(fieldLayout.name)} key='name'>
        <InputField
          {...otherProps}
          fieldState={fieldState.getNestedField('name')}
          label='Job Title'
          onChange={handleFieldChange}
        />
      </div>
      <div className={classnames(fieldLayout.company)} key='company'>
        {hasSkyminyrFeature ? (
          <SkyminyrLegacyCompanySelect
            fieldState={fieldState.getNestedField('company')}
            label='Company'
            onChange={handleFieldChange}
            onCloseCreateCompanyModal={onCloseCreateCompanyModal}
            onCreateCompanyClick={onCreateCompanyClick}
          />
        ) : (
          <CreatableCompanySelectField
            {...otherProps}
            allowCreate={allowCompanyCreation}
            fieldState={fieldState.getNestedField('company')}
            label='Company'
            onChange={handleFieldChange}
            onCloseCreateCompanyModal={onCloseCreateCompanyModal}
            onCreateCompanyClick={onCreateCompanyClick}
            showLinkedStatus={false}
          />
        )}
      </div>
      {hasPositionAddressFeatureEnabled ? (
        <div
          className={fieldLayout.officeLocation}
          data-testid='position-office-location'
        >
          {companyIdOrName && isCreatingNewCompany ? (
            <div>
              <label htmlFor={companyNotInThriveHtmlId}>Office Location</label>
              <p id={companyNotInThriveHtmlId}>
                To add an Office Location,{' '}
                <b>the company must first be added to Thrive.</b>
              </p>
            </div>
          ) : (
            <PositionOfficeLocationSelectField
              companyId={
                (Number.isFinite(companyIdOrName)
                  ? companyIdOrName
                  : companyIdOrName?.data?.id) || null
              }
              fieldState={fieldState.getNestedField('addressId')}
              onChange={handleFieldChange}
            />
          )}
        </div>
      ) : null}
      {showPositionFunctions && positionFunctionPresent && (
        <div
          className={classnames(fieldLayout.positionFunctions)}
          key='positionFunctions'
        >
          <PositionFunctionsTagSelectField
            fieldState={fieldState.getNestedField('position_function_ids')}
            onChange={handleFieldChange}
          />
        </div>
      )}
      {showPositionLevels && positionLevelsPresent && (
        <div
          className={classnames(fieldLayout.positionLevels)}
          key='positionLevels'
        >
          <LevelsTagSelectField
            fieldState={fieldState.getNestedField('level_ids')}
            onChange={handleFieldChange}
          />
        </div>
      )}
      <div className={classnames(fieldLayout.dateRange)} key='dateRange'>
        <PositionDateRangeField
          {...otherProps}
          fieldState={fieldState.getNestedField('dateRange')}
          minYear={-65}
          onChange={handleFieldChange}
        />
      </div>
      <div className={classnames(fieldLayout.description)} key='description'>
        <TextAreaField
          {...otherProps}
          fieldState={fieldState.getNestedField('description')}
          label='Description'
          onChange={handleFieldChange}
        />
      </div>
    </div>
  );
};

PositionField.createFieldState = (
  name = 'position',
  position,
  hasSkyminyrFeature,
) => {
  const values = fromJS({
    name: '',
    company_name: '',
    description: '',
  }).merge(position);

  const unsavedCompanyOption = values.get('company_name')
    ? {
        label: values.get('company_name'),
        value: values.get('company_name'),
        data: { name: values.get('company_name') },
      }
    : null;

  return FieldState.createNested(
    name,
    [
      InputField.createFieldState('name', values.get('name')),
      PositionDateRangeField.createFieldState('dateRange', {
        from: values.get('from'),
        to: values.get('to'),
        present: values.get('present'),
      }),
      TextAreaField.createFieldState('description', values.get('description')),
      hasSkyminyrFeature
        ? SkyminyrLegacyCompanySelect.createFieldState(
            'company',
            values.get('company') || unsavedCompanyOption,
          )
        : CreatableCompanySelectField.createFieldState('company', {
            id: values.get('company'),
            name: values.get('company_name'),
            linkedin_id: values.get('company_linkedin_id'),
          }),
      PositionFunctionsTagSelectField.createFieldState(
        'position_function_ids',
        values.get('position_functions'),
      ),
      LevelsTagSelectField.createFieldState('level_ids', values.get('levels')),
      ReactSelectField.createFieldState(
        'addressId',
        values.getIn(['address', 'id']),
      ),
    ],
    null,
    ({ addressId, company, dateRange, ...other }) => {
      const selectedCompanyId =
        (isNumber(company) ? company : company?.data?.id) || null;
      return {
        address_id: company?.id || company?.value ? addressId : null,
        from: dateRange.from,
        to: dateRange.to,
        present: dateRange.present,
        company: hasSkyminyrFeature ? null : company.id,
        company_id: hasSkyminyrFeature ? selectedCompanyId : company.id,
        company_name: hasSkyminyrFeature ? company?.data?.name : company.name,
        company_create: hasSkyminyrFeature ? null : company.create,
        company_website: hasSkyminyrFeature
          ? company?.data?.website_url
          : company.website,
        company_attributes:
          hasSkyminyrFeature && company?.data?.skyminyr_id
            ? getSkyminyrCompanyCreatePayload(company.data)
            : null,
        ...other,
      };
    },
  );
};

PositionField.propTypes = {
  /**
   * Additional content to render below the header and above the child fields.
   */
  children: PropTypes.node,

  /**
   * Optional classname to apply.
   */
  className: PropTypes.string,

  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * A header component/text to display at the top of the field.
   */
  header: PropTypes.node,

  /**
   * The way in which the fields are placed/sized
   */
  layout: PropTypes.oneOf(FIELD_LAYOUTS),

  /**
   * Called when the value is changed, with the updated fieldState.
   * The underlying value will be either the selected date, or null if "isCurrent" is checked.
   */
  onChange: PropTypes.func.isRequired,

  onCloseCreateCompanyModal: PropTypes.func,
  onCreateCompanyClick: PropTypes.func,

  /**
   * Default true. True to show position functions tag select false to hide.
   */
  showPositionFunctions: PropTypes.bool,

  /**
   * Default true. True to show position levels tag select false to hide.
   */
  showPositionLevels: PropTypes.bool,
};

PositionField.defaultProps = {
  layout: 'default',
  showPositionFunctions: true,
  showPositionLevels: true,
};

PositionField.FIELD_LAYOUTS = FIELD_LAYOUTS;

export default PositionField;
