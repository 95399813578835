import { Map, Record, Iterable } from 'immutable';

/**
 * Represents the state of a single form field.
 *
 * The underlying immutable record object used to represent the state of a field.
 * DO NOT MUTATE DIRECTLY!!! Use `FieldState` methods instead.
 * @type {Record}
 * @private
 */
const FieldStateRecord = new Record({
  /**
   * The name of the field
   * @type {String}
   */
  name: null,

  /**
   * The value of the field, or an Iterable that contains nested FieldStateRecords
   * @type {*}
   */
  value: null,

  /**
   * A validator function to call when the field is changed. This function will be called with
   * the current `FieldStateRecord` and should return an error message in the form of a string
   * (or something representing the error, so long as the underlying field that uses it knows
   * what type of error to expect) if the field is invalid, or undefined if the field is valid.
   * @type {Function}
   * @returns {(String|undefined)}
   */
  validator: null,

  /**
   * The current error state of the field, if any.
   * @type {*}
   */
  error: null,

  /**
   * An optional function that will convert the underlying field value to a "raw" value.
   * @type {Function}
   */
  convertToRaw: null,
});

/**
 * The `FieldState` object should be used to mutate `FieldStateRecord` objets.
 * `FieldStateRecord` objects should never be mutated directly!
 */
export default class FieldState {
  /**
   * Creates a new FieldState object.
   * @param {String|Object} name The name of the field, or an object containing the properties to
   *   pass to the FieldState constructor (`name` (required), `value`, `validator`, and/or
   *   `convertToRaw`)
   * @param {*} [value] The value of the field. This may be a raw value (string, number, boolean,
   *   array, etc), or it may be an Iterable that contains additional nested FieldStateRecords
   * @param {Function} [validator] An optional function that can be used to validate this field.
   *   This function is called with the FieldStateRecord itself, and should return a non-falsey
   *   value representing any errors.
   * @return {FieldState} a new FieldState object with the name a value provided.
   */
  static create(name, value, validator, convertToRaw) {
    const options =
      typeof name === 'object'
        ? name
        : {
            name: name,
            value: value,
            validator: validator,
            convertToRaw: convertToRaw,
          };
    return new FieldState(options).validate();
  }

  /**
   * Creates a new FieldState object which is composed of nested field states.
   * @param {String} name The name of the field
   * @param {Array} childFields The fields to nest. All child fields should have unique
   *   names within the array.
   * @returns {FieldState} A FieldState comprised of the neseted FieldState values provided.
   */
  static createNested(name, [...childFields], validator, convertToRaw) {
    return FieldState.create(
      name,
      new Map(
        childFields.map(childField => [childField.getName(), childField]),
      ),
      validator,
      convertToRaw,
    );
  }

  /**
   * @private
   */
  constructor(record) {
    if (!(record instanceof FieldStateRecord)) {
      this._record = new FieldStateRecord(record);
    } else {
      this._record = record;
    }
  }

  /** @deprecated Use `getName()` */
  getFieldName() {
    return this._record.get('name');
  }

  /**
   * Gets the field's name
   * @return {String} The field name.
   */
  getName() {
    return this.getFieldName();
  }

  /**
   * Updates the value of a field and reruns any field validations.
   * This should always be used to update the value! The record should never be updated directly
   * so that we can provide any additional data that may be needed when a field is changed.
   * Currently this includes running validation, but other mutations may be possible in the future
   * so it should not be assumed that this is the only operation that may occur.
   * @param {FieldStateRecord} fieldStateRecord the record whose value should be updated.
   * @param {*} [newValue] - the new value of the field.
   * @param {String} [key] - the key at which to set the value, for nested FieldState values.
   * @returns {FieldStateRecord} An updated record after the field value has been changed,
   *   including updated validation errors.
   */
  setValue(newValue) {
    if (this.getValue() !== newValue) {
      return new FieldState(this._record.set('value', newValue)).validate();
    }

    return this;
  }

  setValidator(newValidator) {
    if (this._record.get('validator') !== newValidator) {
      return new FieldState(
        this._record.set('validator', newValidator),
      ).validate();
    }

    return this;
  }

  /**
   * Gets the field's value. For nested fields, this returns the collection of
   * FieldState values.
   */
  getValue() {
    return this._record.get('value');
  }

  /**
   * Replaces a nested FieldState with a new FieldState.
   * @param {FieldState} childField The updated child FieldState
   * @param {String} [fieldKey] An optional field key specifying the key at which the field
   *   state is stored. If not prevented, the childField's field name will be used (which is
   *   the field key used with `FieldState.createNested()`)
   * @returns {FieldState} an updated field state with the new child field value.
   */
  setNestedField(childField, fieldKey) {
    const key = fieldKey || childField.getName();
    const field = this.getNestedField(key);
    if (field !== childField) {
      const value = this.getValue();
      return new FieldState(
        this._record.set('value', value.set(key, childField)),
      ).validate();
    }

    return this;
  }

  /**
   * Removes a nested FieldState.
   * @param  {[type]} fieldKey [description]
   * @returns {FieldState} an updated field state without the child field
   */
  removeNestedField(fieldKey) {
    if (this.getNestedField(fieldKey)) {
      const value = this.getValue();
      return new FieldState(
        this._record.set('value', value.delete(fieldKey)),
      ).validate();
    }

    return this;
  }

  /**
   * Sets the value of a nested FieldState.
   * @param {String} fieldKey The name of a nested field.
   * @param {*} [newValue] the new value of the nested field.
   */
  setNestedFieldValue(fieldKey, newValue) {
    const field = this.getNestedField(fieldKey);
    const nextField = field.setValue(newValue);
    return this.setNestedField(nextField, fieldKey);
  }

  /**
   * Gets the value of a nested FieldState.
   * @param {String} fieldKey The name of a nested field.
   * @return {*} the value of the nested field.
   */
  getNestedFieldValue(fieldKey) {
    const field = this.getNestedField(fieldKey);
    return field && field.getValue();
  }

  /**
   * Gets all nested fields. Used for has many assocaitions.
   */
  getNestedFields() {
    return this.getValue();
  }

  /**
   * Removes ALL nested fields.
   */
  removeNestedFields() {
    return this.setValue(new Map());
  }

  /**
   * Sets the underlying nested fields in bulk.
   * @param {Array} childFields The fields to nest. All child fields should have unique
   *   names within the array.
   */
  setNestedFields(childFields) {
    return this.setValue(
      new Map(
        childFields.map(childField => [childField.getName(), childField]),
      ),
    );
  }

  /**
   * Gets a nested field value by it's key/field name.
   * @param {FieldName} {String} The nested field's key.
   */
  getNestedField(fieldName) {
    return this.getNestedFields().get(fieldName);
  }

  /**
   * Validates the field and returns an updated field state.
   * @returns {FieldState} An updated FieldState after running validation.
   */
  validate() {
    const validator = this._record.get('validator');

    if (validator) {
      const error = validator(this);
      return this.setChildErrors(error);
    }
    if (this.getError()) {
      return this.setError(null);
    }

    return this;
  }

  /**
   * Gets a value indicating whether a field state has an errors. If the FieldStateRecord contains
   * any nested fields, those will be checked for error states as well.
   * @param {FieldStateRecord} fieldStateRecord The field state to check for errors
   * @returns {Boolean} true if the record or any child records contain an error.
   */
  hasErrors() {
    return Boolean(this.getError()) || this.hasNestedErrors();
  }

  /**
   * Gets a value indicating whether any nested fields have errors. If there are no nested
   * fields, returns false.
   * This should generally not be used directly, and it does not check the field state itself
   * for errors -- use `FieldState.hasErrors` for a more comprehensive check including the
   * current field.
   * @param {FieldStateRecord} fieldStateRecord The field state to check for nested errors
   * @return {Boolean} true if any nested fields exist and have errors
   */
  hasNestedErrors() {
    const value = this.getValue();

    if (value instanceof FieldState) {
      return value.hasErrors();
    }

    if (Iterable.isIterable(value)) {
      return value.some(child =>
        child instanceof FieldState ? child.hasErrors() : false,
      );
    }

    return false;
  }

  /**
   * Gets the error for this field.
   * Note that this does not aggregrate or return any child field errors.
   */
  getError() {
    return this._record.get('error');
  }

  /**
   * Returns a new FieldState with an error. If the error hasn't changed,
   * we'll just return the current fieldstate for perf/render avoidance.
   */
  setError(error) {
    if (error !== this.getError()) {
      return new FieldState(this._record.set('error', error));
    }

    return this;
  }

  /**
   * Sets the error of this fieldState or its children.  If the error is null or
   * a string, it will be set on this fieldState.  If the error is an object,
   * its keys are compared to the names of the child fieldStates, and matching
   * values will be set as the errors of the children.
   */

  setChildErrors(error) {
    // Errors are keyed
    if (error && typeof error === 'object') {
      return Object.keys(error).reduce((fieldState, key) => {
        if (key === 'base') {
          return fieldState.setChildErrors(error[key]);
        }
        if (fieldState.getNestedField(key)) {
          return fieldState.setNestedFieldError(key, error[key]);
        }
        return fieldState;
      }, this);
    }

    // Error is a string or null
    return this.setError(error);
  }

  /**
   * Sets the error of a nested FieldState.
   * @param {String} fieldKey The name of a nested field.
   * @param {*} [newError] the new error of the nested field.
   */
  setNestedFieldError(fieldKey, newError) {
    const field = this.getNestedField(fieldKey);
    const nextField = field.setChildErrors(newError);

    if (field === nextField) {
      // Nothing was changed, so just return the curent instance.
      return this;
    }

    // An error was changes, so wrap in a new FieldState and return that.
    return new FieldState(
      this._record.set('value', this.getValue().set(fieldKey, nextField)),
    );
  }

  /**
   * Gets the value of the field, converting nested FieldState values to as well.
   */
  getRawValue() {
    const convertToRaw = this._record.get('convertToRaw');
    let value = this.getValue();

    if (value instanceof FieldState) {
      return value.getRawValue();
    }

    if (
      Iterable.isIterable(value) &&
      value.every(child => child instanceof FieldState)
    ) {
      const raw = value.map(child => child.getRawValue());
      value = Iterable.isKeyed(raw) ? raw.toObject() : raw.toArray();
    }

    return convertToRaw ? convertToRaw(value) : value;
  }

  /**
   * @deprecated Use `getValue()` or `getRawValue`
   * Get the underlying value for this field. If this contains any nested fields, this will get
   * their values as well.
   */
  getFieldValue() {
    const value = this.getValue();

    if (value instanceof FieldState) {
      return value.getFieldValue();
    }

    if (Iterable.isIterable(value)) {
      return value
        .map(child =>
          child instanceof FieldState ? child.getFieldValue() : child,
        )
        .toJS();
    }

    return value;
  }
}
