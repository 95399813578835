export const USER_LIST_FETCH_START = 'USER_LIST_FETCH_START';
export const USER_LIST_FETCH_SUCCESS = 'USER_LIST_FETCH_SUCCESS';
export const USER_LIST_FETCH_FAILURE = 'USER_LIST_FETCH_FAILURE';

export const USER_AVATAR_UPDATE_START = 'USER_AVATAR_UPDATE_START';
export const USER_AVATAR_UPDATE_SUCCESS = 'USER_AVATAR_UPDATE_SUCCESS';
export const USER_AVATAR_UPDATE_FAILURE = 'USER_AVATAR_UPDATE_FAILURE';

export const USER_UPDATE_START = 'USER_UPDATE_START';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';

export const USER_INITIALIZE = 'USER_INITIALIZE';
