import { Map } from 'immutable';
import {
  NOTIFICATIONS_CATEGORY_ALL,
  NOTIFICATIONS_CATEGORY_UNREAD,
} from '../../domains/notifications/constants';
import all, { INITIAL_STATE as INITIAL_STATE_ALL } from './all';
import unread, { INITIAL_STATE as INITIAL_STATE_UNREAD } from './unread';

export const INITIAL_STATE = new Map({
  [NOTIFICATIONS_CATEGORY_ALL]: INITIAL_STATE_ALL,
  [NOTIFICATIONS_CATEGORY_UNREAD]: INITIAL_STATE_UNREAD,
});

/**
 * Maps two collection of notifications: all and unread.
 */
export default function notificationsByCategory(state = INITIAL_STATE, action) {
  return state
    .update(NOTIFICATIONS_CATEGORY_ALL, allState => all(allState, action))
    .update(NOTIFICATIONS_CATEGORY_UNREAD, unreadState =>
      unread(unreadState, action),
    );
}
