import { TRANSACTION_START } from './actionTypes';

/**
 * Starts a new transaction
 * @param {String} transactionId The unique transaction ID for this
 *   transaction (use `core/uniqueId` to generate a unique GUID)
 * @param {*} [payload] An optional payload to store with the transaction state.
 */
export default function startTransaction(transactionId, payload) {
  return {
    transactionId: transactionId,
    type: TRANSACTION_START,
    payload: payload,
  };
}
