import {
  compose,
  lifecycle,
  mapProps,
  setDisplayName,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import * as sortUtils from 'modules/core/sortUtils';
import fetchCandidacyStatusAction from '../actions/fetchCandidacyStatus';
import getCandidacyRank from '../selectors/getCandidacyRank';
import getCandidacyTimeLastContacted from '../selectors/getCandidacyTimeLastContacted';
import shouldFetchCandidacyStatus from '../selectors/shouldFetchCandidacyStatus';

/**
 * A Higher order component that sorts a list of candidacyIds in order of last contacted date.
 * It additionally fetches any invalidated candidacy statuses because those are needed to ensure
 * the candidacy's are sorted on the most up-to-date value.
 */
export default compose(
  setDisplayName('withCandidacyIdsSortedByLastContacted'),

  // Save any incoming props to restore later.
  mapProps(({ candidacyIds, ...props }) => ({
    candidacyIds: candidacyIds,
    withCandidacyIdsSortedByLastContactedProps: props,
  })),

  connect(
    (state, { candidacyIds }) => ({
      candidacyIds:
        candidacyIds &&
        candidacyIds.sortBy(
          id => [
            getCandidacyTimeLastContacted(state, id) || '0',
            getCandidacyRank(state, id),
          ],
          sortUtils.arrayComparator,
        ),

      // If status has been invalidated for any candidacies in our list, we need to make
      // sure those are refetched otherwise we won't get the updated time_last_contacted value.
      candidacyIdsStatusFetch:
        candidacyIds &&
        candidacyIds.filter(id => shouldFetchCandidacyStatus(state, id)),
    }),
    {
      fetchCandidacyStatus: fetchCandidacyStatusAction,
    },
  ),
  withHandlers({
    fetchCandidacyStatusesIfNeeded: ({
      candidacyIdsStatusFetch,
      fetchCandidacyStatus,
    }) => () => {
      if (candidacyIdsStatusFetch && candidacyIdsStatusFetch.count()) {
        candidacyIdsStatusFetch.forEach(id => fetchCandidacyStatus({ id: id }));
      }
    },
  }),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      this.props.fetchCandidacyStatusesIfNeeded();
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      nextProps.fetchCandidacyStatusesIfNeeded();
    },
  }),

  // Restore initial props and sorted candidacyIds
  mapProps(({ candidacyIds, withCandidacyIdsSortedByLastContactedProps }) => ({
    ...withCandidacyIdsSortedByLastContactedProps,
    candidacyIds: candidacyIds,
  })),
);
