import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setPropTypes } from 'recompose';

import mapEducationIdToEducation from './mapEducationIdToEducation';

/**
 * Renders the read-only view for a education item.
 */
export const EducationView = ({
  education,
  renderDescription,
  renderSchool,
}) => {
  const degree = education.get('degree');
  const startYear = education.get('start_year');
  const endYear = education.get('end_year');

  return (
    <div className='EducationView'>
      {renderSchool && (
        <div className='EducationView__school'>
          {education.get('school_name')}
        </div>
      )}
      {(degree || startYear || endYear) && (
        <div className='EducationView__degree-years'>
          {degree && <span>{degree}</span>}
          {degree && (startYear || endYear) && <span>, </span>}
          {startYear && <span>{startYear}</span>}
          {startYear && endYear && <span> - </span>}
          {endYear && <span>{endYear}</span>}
        </div>
      )}
      {renderDescription && education.get('description') && (
        <div className='EducationView__description'>
          {education.get('description')}
        </div>
      )}
    </div>
  );
};

EducationView.propTypes = {
  /**
   * The education to render.
   */
  education: ImmutablePropTypes.mapContains({
    degree: PropTypes.string,
    description: PropTypes.string,
    end_year: PropTypes.number,
    school_name: PropTypes.string,
    start_year: PropTypes.number,
  }).isRequired,

  /**
   * True to render the description, false to omit it.
   */
  renderDescription: PropTypes.bool,

  renderSchool: PropTypes.bool,
};

EducationView.defaultProps = {
  renderDescription: true,
  renderSchool: true,
};

/**
 * The default export requires only a `educationId`, while
 * the named export requires the education record.
 */
export default compose(
  setPropTypes({
    educationId: PropTypes.number.isRequired,
  }),
  mapEducationIdToEducation,
)(EducationView);
