import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import CommentCount from './CommentCount';
import mapCandidacyIdToParentType from './mapCandidacyIdToParentType';

export { default as CommentsPanel } from './CommentsPanel';

export default compose(
  setDisplayName('CandidacyCommentCount'),
  setPropTypes({
    candidacyId: PropTypes.number,
  }),
  mapCandidacyIdToParentType,
)(CommentCount);
