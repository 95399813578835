const isPlainObject = obj =>
  Object.prototype.toString.call(obj) === '[object Object]';
export const stringToCamelCase = str =>
  str.replace(/(_\w)/g, target => target[1].toUpperCase());
export const stringToSnakeCase = str =>
  str.replace(/([A-Z])/g, letter => `_${letter.toLowerCase()}`);
export const camelCaseToWords = str => str.replace(/([A-Z])/g, ' $1').trim();

const convertObjectCase = (input, stringConversion) => {
  if (isPlainObject(input)) {
    return Object.fromEntries(
      Object.entries(input).map(([key, value]) => [
        stringConversion(key),
        convertObjectCase(value, stringConversion),
      ]),
    );
  }
  if (Array.isArray(input)) {
    return input.map(item => convertObjectCase(item, stringConversion));
  }

  return input;
};

export const convertToCamelCase = input =>
  convertObjectCase(input, stringToCamelCase);
export const convertToSnakeCase = input =>
  convertObjectCase(input, stringToSnakeCase);
