import { normalize } from 'normalizr';
import companySchema from '../companySchema';
import { PORTFOLIO_COMPANY_LIST_INITIALIZE } from './actionTypes';

/**
 * Dispatches an action to initialize the list of all portfolio companies that are defined
 * for the current tenant.
 * @param {Object[]} data The collection of all portfolio companies. These should be company
 *   records, using the standard company schema we have defined.
 */
export default data => ({
  type: PORTFOLIO_COMPANY_LIST_INITIALIZE,
  payload: normalize(data, [companySchema]),
});
