/**
 * Creates a validator function for a field that contains an array of objects, and
 * validates that the array is a minimum length.
 * @param {String} [fieldLabel='field'] The descriptive label of the field,
 *   used in the error message.
 * @param {Number} minimum The minimum number of items required in the array.
 * @return {Function} A function that can be used as a `FieldState` validator.
 */
const hasMinimumLength = (fieldLabel = 'field', minimum = 1) => fieldState => {
  const value = fieldState.getRawValue();
  return value && value.length >= minimum
    ? null
    : `At least ${minimum === 1 ? 'one' : minimum} ${fieldLabel} is required`;
};

export default hasMinimumLength;
