import { fromJS, List } from 'immutable';
import {
  DASHBOARD_FETCH_START,
  DASHBOARD_FETCH_SUCCESS,
  DASHBOARD_FETCH_FAILURE,
} from '../../actions/dashboard/constants';

export const initialState = fromJS({
  data: null,

  meta: {
    isFetching: false,
  },
});

export default function dashboard(state = initialState, action) {
  switch (action.type) {
    case DASHBOARD_FETCH_START:
      return state.setIn(['meta', 'isFetching'], true);
    case DASHBOARD_FETCH_SUCCESS:
      return state.withMutations(map => {
        map.setIn(['meta', 'isFetching'], false);
        map.set('data', new List(action.payload.data));
        map.deleteIn(['meta', 'error']);
      });
    case DASHBOARD_FETCH_FAILURE:
      return state.withMutations(map => {
        map.setIn(['meta', 'isFetching'], false);
        map.setIn(['meta', 'error'], action.payload.error);
      });
    default:
      return state;
  }
}
