import { NAME } from '../constants';

/**
 * Returns the first entity for which the predicate returns true.
 * @param {Object} state
 * @param {String} entityType
 * @param {Function} predicate A function that is called for each entity and given
 *  given the entity itself, which should return true if it is the entity to find.
 */
export default (state, entityType, predicate) =>
  state[NAME].has(entityType)
    ? state[NAME].get(entityType).find(value => predicate(value))
    : null;
