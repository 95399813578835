import { NAME } from '../../constants';

/**
 * Gets the direct reports assigned to a contact.
 * @param {Object} state
 * @param {Number} id The contact ID
 * @returns {Immutable.List.<Number>} An immutable list of the contact IDs which are direct reports
 *   of the given contact.
 */
export default (state, id) =>
  state[NAME].getIn(['directReportsByContactId', id]);
