import selectn from 'selectn';

const selectEntityType = selectn('payload.entityType');

/**
 * Returns a value that indicates whether an action is for a particular entity type.
 * @param {Object} action The dispatched action
 * @param {String} entityType The type of entity to check for (i.e. schema key)
 */
export default (action, entityType) => selectEntityType(action) === entityType;
