import { CANDIDACY_LIST_INVALIDATE } from './actionTypes';

/**
 * Dispatches an action to flag a candidate list collection as invalid.
 * @param {Object} options
 * @param {String} options.parentId The ID of the list to invalidate.
 * @param {String} options.parentType The type of candidate list to invalidate (search or contact).
 */
export default ({ parentId, parentType }) => ({
  type: CANDIDACY_LIST_INVALIDATE,
  payload: { parentId: parentId, parentType: parentType },
});
