import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import hasLoadedTenantOptionsSelector from '../selectors/hasLoadedTenantOptions';
import isFetchingTenantOptionsSelector from '../selectors/isFetchingTenantOptions';
import shouldFetchTenantOptionsSelector from '../selectors/shouldFetchTenantOptions';
import fetchTenantOptions from '../actions/fetchTenantOptions';

/**
 * A higher order component that initiates fetchTenantOptions(), if needed, when
 * a component is going to be mounted
 */
export default compose(
  connect(
    state => ({
      hasLoadedTenantOptions: hasLoadedTenantOptionsSelector(state),
      isFetchingTenantOptions: isFetchingTenantOptionsSelector(state),
      shouldFetchTenantOptions: shouldFetchTenantOptionsSelector(state),
    }),
    {
      fetchTenantOptions: fetchTenantOptions,
    },
  ),
  lifecycle({
    UNSAFE_componentWillMount: function () {
      if (this.props.shouldFetchTenantOptions) {
        this.props.fetchTenantOptions();
      }
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      if (nextProps.shouldFetchTenantOptions) {
        nextProps.fetchTenantOptions();
      }
    },
  }),
);
