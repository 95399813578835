import { connect } from 'react-redux';
import isCandidacyHidden from '../selectors/isCandidacyHidden';

/**
 * A Higher order component that filters a list of candidacyIds to only include those that have
 * a `hidden` set to true. It also adds an `isFiltered` prop so the
 * base component can know when it's candidacyIds have been filtered.
 */
export default connect(
  (state, { candidacyIds, isFiltered }) => ({
    isFiltered: isFiltered || true,
    candidacyIds:
      candidacyIds && candidacyIds.filter(id => !isCandidacyHidden(state, id)),
  }),
  {},
);
