import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, mapProps } from 'recompose';
import { connect } from 'react-redux';
import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import ensureTenantOptionsFetched from './ensureTenantOptionsFetched';
import getTenantOptionsIds from '../selectors/getTenantOptionsIds';

/**
 * Ensures that the tenant options are fetched and provides the list of entity IDs (`entityIds`)
 * as a prop for the schema specified.
 *
 * @param {Object} options
 * @param {Schema} the schema definition of the entities to be fetched. If this is provided
 *  then schemaPropName will not be used, and this will be used exclusively to determine
 *  which entities to provide.
 * @param {String} [options.schemaPropName="schema"] An incoming prop - the schema of
 *  the entitiy as a prop. This could be overriden if schema is provided.
 * @param {String} [options.sortPropertyPropName="sortProperty"] The name of the incoming prop
 *   used to determine how the options should be sorted.
 * @param {String} [options.sortSelectorPropName="sortSelector"] The name of the incoming prop
 *   determines how entities should be sorted - if provided the entityIds will be sorted with
 *   this selector.
 * @param {String} [options.entityIdsPropName="entityIds"] The name of the outgoing prop
 *  gives us the ids of the entities.
 * @param {String} [options.entityTypePropName="entityType"] The name of the outgoing prop
 *  gives us the schema key.
 * @return {Function} a higher-order function that provides tanent entities IDs and type
 */

export default ({
  entityIdsPropName = 'entityIds',
  entityTypePropName = 'entityType',
  schema,
  schemaPropName = 'schema',
  sortPropertyPropName = 'sortProperty',
  sortSelectorPropName = 'sortSelector',
} = {}) =>
  compose(
    setDisplayName('withTenantOptionsEntityIdsFetched'),
    setPropTypes({
      // A prop called [schemaPropName] is only required when the
      // `schemaPropName` parameter is defined.
      ...(schemaPropName && !schema
        ? {
            /**
             * The schema that this tag select field will be displaying/selecting.
             */
            [schemaPropName]: PropTypes.shape({
              key: PropTypes.string.isRequired,
            }).isRequired,
          }
        : {}),

      /**
       * An optional property name on the entity to sort the list on.
       */
      [sortPropertyPropName]: PropTypes.string,

      /**
       * An optional selector function that can be used for sorting the list of entities.
       * This is called with the current state and the entityId, and should return a value that
       * can be used by Immutable.List.sortBy.
       * `sortSelector(state, id)`
       */
      [sortSelectorPropName]: PropTypes.func,
    }),

    mapProps(props => ({
      incomingProps: props,
      schema: schema || props[schemaPropName],
      sortProperty: props[sortPropertyPropName],
      sortSelector: props[sortSelectorPropName],
    })),

    // Make sure the tenant options are fetched, then pull out the IDs for the schema in question.
    ensureTenantOptionsFetched,

    // Get the entity IDs and sort them
    connect(
      (
        state,
        { incomingProps, schema: schemaDefinition, sortProperty, sortSelector },
      ) => {
        // console.log(incomingProps, schemaProp);
        let entityIds = getTenantOptionsIds(state, schemaDefinition.key);
        if (entityIds && sortSelector) {
          entityIds = entityIds.sortBy(id => sortSelector(state, id));
        } else if (entityIds && sortProperty) {
          entityIds = entityIds.sortBy(id =>
            getEntityProperty(state, schemaDefinition.key, id, sortProperty),
          );
        }

        return {
          ...incomingProps,
          [entityIdsPropName]: entityIds && entityIds.toArray(),
          [entityTypePropName]: schemaDefinition.key,
        };
      },
      {},
    ),
  );
