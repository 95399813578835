import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import { candidacyVoteSchema } from '../schemas';

/**
 * Gets the current user's vote for a candidacy
 * @param {Object} state
 * @param {Number} candidacyId The candidacy ID
 * @return {Number|Boolean} The vote value (1, -1), if available and has been voted on;
 *   otherwise false.
 */
export default (state, candidacyId) =>
  getEntityProperty(state, candidacyVoteSchema.key, candidacyId, 'vote', false);
