import shouldFetchEntity from 'modules/entities/selectors/shouldFetchEntity';
import companySchema from '../companySchema';

/**
 * Determines if a company should be fetched from the server
 * @param {*} state
 * @param {Number} id The ID of the company
 * @param {Boolean} requireFull Whether the "full" complete company record is needed. When false,
 *   having any company data (typically a name only) is sufficient data.
 */
export default (state, id, requireFull = true) =>
  shouldFetchEntity(state, companySchema.key, id, requireFull && 'description');
