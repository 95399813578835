import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import debounce from 'lodash/debounce';
import { Editor, EditorState, getDefaultKeyBinding, RichUtils } from 'draft-js';
import Toolbar from './Toolbar';
import { toHtml } from './convert';
import DraftStatus from './DraftStatus';

const MAX_INDENT_LEVEL = 5;
const DRAFT_CHANGE_DEBOUNCE_INTERVAL_MS = 1000;

const KeyCommandOutcomes = {
  HANDLED: 'handled',
  NOT_HANDLED: 'not-handled',
};

// A rich text input component using DraftJS
const RichTextArea = ({
  btnClass,
  disabled,
  lastUpdatedTime,
  name,
  onBlur,
  onChange,
  onDraftChange,
  placeholder,
  shouldShowImageOption,
  shouldShowLinkOption,
  value,
}) => {
  const debouncedOnDraftChange = useMemo(
    () => debounce(onDraftChange, DRAFT_CHANGE_DEBOUNCE_INTERVAL_MS),
    [onDraftChange],
  );

  const handleValueChange = newEditorState => {
    const currentContentState = value.getCurrentContent();
    const newContentState = newEditorState.getCurrentContent();

    // EditorState tracks changes to both content state and selection state. We
    // only care about updating drafts when content changes (changes to
    // selection state fire on focus/blur as well, which causes drafts to be
    // stored after save/cancel actions, due to the debounced delay).
    // https://github.com/facebook/draft-js/issues/830
    const didContentChange = currentContentState !== newContentState;

    if (didContentChange && onDraftChange) {
      debouncedOnDraftChange(toHtml(newContentState));
    }

    onChange(newEditorState);
  };

  const keyBindingFn = event => {
    // Allow for indenting/outdenting lists with the tab key. The actual docs
    // on this are sparse, so here's a link to an example:
    // https://github.com/facebook/draft-js/blob/master/examples/draft-0-10-0/rich/rich.html
    if (event.key === 'Tab') {
      const newState = RichUtils.onTab(event, value, MAX_INDENT_LEVEL);
      if (newState !== value) {
        handleValueChange(newState);
      }
      return false;
    } else {
      return getDefaultKeyBinding(event);
    }
  };

  // https://draftjs.org/docs/quickstart-rich-styling
  // https://draftjs.org/docs/advanced-topics-key-bindings
  const handleKeyCommand = (command, currentValue) => {
    const newValue = RichUtils.handleKeyCommand(currentValue, command);
    if (newValue) {
      handleValueChange(newValue);
      return KeyCommandOutcomes.HANDLED;
    } else {
      return KeyCommandOutcomes.NOT_HANDLED;
    }
  };

  const ImageComponent = ({ block, contentState }) => {
    const entity = contentState.getEntity(block.getEntityAt(0));
    const { src } = entity.getData();
    return <img alt='Email-signature' src={src} />;
  };

  const blockRenderer = contentBlock => {
    if (contentBlock.getType() === 'atomic') {
      const contentState = value.getCurrentContent();
      const entityKey = contentBlock.getEntityAt(0);
      if (entityKey) {
        const entity = contentState.getEntity(entityKey);
        const entityType = entity.getType();
        if (entityType === 'IMAGE') {
          return {
            component: ImageComponent,
            editable: false,
          };
        }
      }
    }
    return null;
  };

  // TODO: replace `name` with `data-testid`. `name` is an invalid attribute on a
  // div, but currently Rails feature specs rely on it for element locators.
  return (
    <>
      <div className='rich-text-area' name={name}>
        <Editor
          blockRendererFn={blockRenderer}
          editorState={value}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={keyBindingFn}
          onBlur={onBlur}
          onChange={handleValueChange}
          placeholder={placeholder}
          readOnly={disabled}
          spellCheck={true}
        />
        <Toolbar
          btnClass={btnClass}
          disabled={disabled}
          editorState={value}
          onChange={handleValueChange}
          shouldShowImageOption={shouldShowImageOption}
          shouldShowLinkOption={shouldShowLinkOption}
        />
      </div>
      <DraftStatus lastUpdatedTime={lastUpdatedTime} />
    </>
  );
};

RichTextArea.defaultProps = {
  // Default to an empty function so that we don't have to do conditional
  // memoization of the debounce wrapper function
  onDraftChange: () => {},
  shouldShowImageOption: false,
  shouldShowLinkOption: false,
};

RichTextArea.propTypes = {
  block: PropTypes.shape({
    getEntityAt: PropTypes.func.isRequired,
  }),
  btnClass: PropTypes.string,
  contentState: PropTypes.shape({
    getEntity: PropTypes.func.isRequired,
  }),
  disabled: PropTypes.bool,
  lastUpdatedTime: PropTypes.number,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onDraftChange: PropTypes.func,
  placeholder: PropTypes.string,
  shouldShowImageOption: PropTypes.bool,
  shouldShowLinkOption: PropTypes.bool,
  value: PropTypes.instanceOf(EditorState).isRequired,
};

export default RichTextArea;
