import { createSlice } from '@reduxjs/toolkit';
import routes from 'modules/routing/routes';
import url from 'url';
import Api, { getErrorMessage } from 'modules/core/Api';
import { stringifyQueryObject } from 'modules/core/urlUtils';
import {
  toastError,
  toastSuccess,
} from 'modules/toast-notifications/toastNotificationsSlice';

const initialState = {
  currentPage: 1,
  isLoadingSubsidiaryCompanies: false,
  isAddingSubsidiaryCompany: false,
  isRemovingSubsidiaryCompany: false,
  loadSubsidiaryCompaniesError: null,
  addSubsidiaryCompanyError: null,
  removeSubsidiaryCompanyError: null,
  subsidiaryCompanies: null,
  totalPages: 1,
  resultsPerPage: 25,
  totalResults: null,
  sortDirection: true,
  sortField: null,
};

const subsidiaryCompaniesSlice = createSlice({
  name: 'subsidiaryCompanies',
  initialState: initialState,
  reducers: {
    loadSubsidiaryCompaniesBegin: state => {
      state.isLoadingSubsidiaryCompanies = true;
      state.loadSubsidiaryCompaniesError = null;
    },
    loadSubsidiaryCompaniesSuccess: (state, action) => {
      const { data, metadata } = action.payload;

      state.isLoadingSubsidiaryCompanies = false;
      state.subsidiaryCompanies = data;
      state.totalPages = metadata.total_pages;
      state.totalResults = metadata.total_results;
      state.currentPage = metadata.current_page;
      state.resultsPerPage = metadata.results_per_page;
    },
    loadSubsidiaryCompaniesError: (state, action) => {
      state.isLoadingSubsidiaryCompanies = false;
      state.loadSubsidiaryCompaniesError = action.payload;
    },
    setResultsPerPage: (state, action) => {
      state.resultsPerPage = action.payload;
      state.currentPage = 1;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setSortFields: (state, action) => {
      state.sortDirection = action.payload.sortDirection;
      state.sortField = action.payload.sortField;
    },
    removeSubsidiaryCompanyBegin: state => {
      state.isRemovingSubsidiaryCompany = true;
      state.removeSubsidiaryCompanyError = null;
    },
    removeSubsidiaryCompanySuccess: state => {
      state.isRemovingSubsidiaryCompany = false;
      state.removeSubsidiaryCompanyError = null;
    },
    removeSubsidiaryCompanyError: (state, action) => {
      state.isRemovingSubsidiaryCompany = false;
      state.removeSubsidiaryCompanyError = action.payload;
    },
    addSubsidiaryCompanyBegin: state => {
      state.isAddingSubsidiaryCompany = true;
      state.addSubsidiaryCompanyError = null;
    },
    addSubsidiaryCompanySuccess: state => {
      state.isAddingSubsidiaryCompany = false;
      state.addSubsidiaryCompanyError = null;
    },
    addSubsidiaryCompanyError: (state, action) => {
      state.isAddingSubsidiaryCompany = false;
      state.addSubsidiaryCompanyError = action.payload;
    },
  },
});

const {
  addSubsidiaryCompanyBegin,
  addSubsidiaryCompanyError,
  addSubsidiaryCompanySuccess,
  loadSubsidiaryCompaniesBegin,
  loadSubsidiaryCompaniesError,
  loadSubsidiaryCompaniesSuccess,
  removeSubsidiaryCompanyBegin,
  removeSubsidiaryCompanyError,
  removeSubsidiaryCompanySuccess,
  setCurrentPage,
  setResultsPerPage,
  setSortFields,
} = subsidiaryCompaniesSlice.actions;

const loadSubsidiaryCompanies = companyId => (dispatch, getState) => {
  const {
    currentPage,
    resultsPerPage,
    sortDirection,
    sortField,
  } = getState().subsidiaryCompanies;

  const endpoint = url.format({
    pathname: routes.api_v1_company_subsidiary_companies({
      companyId: companyId,
    }),
    search: stringifyQueryObject({
      page: currentPage,
      results_per_page: resultsPerPage,
      sort_direction: sortDirection,
      sort_field: sortField,
    }),
  });

  dispatch(loadSubsidiaryCompaniesBegin());

  Api.get(endpoint)
    .then(data => {
      dispatch(loadSubsidiaryCompaniesSuccess(data));
    })
    .catch(error => {
      dispatch(loadSubsidiaryCompaniesError(getErrorMessage(error)));
    });
};

const addSubsidiaryCompany = ({
  onSuccess,
  parentCompanyId,
  subsidiaryCompanyId,
  subsidiaryCompanyName,
  ...otherParams
}) => dispatch => {
  dispatch(addSubsidiaryCompanyBegin());
  const payload = {
    company: { parent_company_id: parentCompanyId, ...otherParams },
  };
  const companyApi = subsidiaryCompanyId
    ? Api.patch(routes.api_v1_company({ id: subsidiaryCompanyId }), payload)
    : Api.post(routes.api_v1_companies(), payload);

  companyApi
    .then(() => {
      dispatch(addSubsidiaryCompanySuccess());
      dispatch(loadSubsidiaryCompanies(parentCompanyId));
      dispatch(toastSuccess(`${subsidiaryCompanyName} added as Subsidiary`));
      if (onSuccess) {
        onSuccess();
      }
    })
    .catch(error => {
      if (!subsidiaryCompanyId) {
        dispatch(toastError(getErrorMessage(error)));
      }
      dispatch(addSubsidiaryCompanyError(getErrorMessage(error)));
    });
};

const removeSubsidiaryCompany = (
  parentCompanyId,
  subsidiaryCompanyId,
) => dispatch => {
  const payload = { company: { parent_company_id: null } };

  dispatch(removeSubsidiaryCompanyBegin());
  Api.patch(routes.api_v1_company({ id: parentCompanyId }), payload)
    .then(() => {
      dispatch(removeSubsidiaryCompanySuccess());
      dispatch(loadSubsidiaryCompanies(subsidiaryCompanyId));
    })
    .catch(error => {
      dispatch(removeSubsidiaryCompanyError(getErrorMessage(error)));
    });
};

export {
  addSubsidiaryCompany,
  loadSubsidiaryCompanies,
  setCurrentPage,
  setResultsPerPage,
  setSortFields,
  removeSubsidiaryCompany,
};

export default subsidiaryCompaniesSlice;
