import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect, useSelector } from 'react-redux';
import React from 'react';
import {
  compose,
  mapProps,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';
import Modal from 'modules/core/componentsLegacy/Modal';
import withComponentId from 'modules/core/componentsLegacy/withComponentId';
import Form from 'modules/forms/components/Form';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import CancelButton from 'modules/forms/components/CancelButton';
import SubmitButton from 'modules/forms/components/SubmitButton';
import fieldStatePropType from 'modules/forms/propTypes/fieldStatePropType';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import NewCompanyFields from './NewCompanyFields';
import withCompanyCreateForm from './withCompanyCreateForm';
import withCompanyDuplicateIds from './withCompanyDuplicateIds';
import connectCompanyActions from './connectCompanyActions';
import mapQueryIdToCompanyQuery from './mapQueryIdToCompanyQuery';
import CompanyDuplicates from './CompanyDuplicates';
import getCompanyProperty from '../selectors/getCompanyProperty';

/**
 * Renders a basic company form in a modal window for creating a new company.
 */
const NewCompanyFormModal = ({
  // props provided by `withCompanyCreateForm`
  duplicateIds,
  fieldState,
  formState,
  onChange,
  // Props passed through/expected from parent component.
  onClose,
  onResetFormState,
  onSubmit,
  show,
  submitButtonLabel,
  // Props for duplicate recognition
  title,

  // Any other props are passed through to the NewCompanyFields component.
  ...fieldProps
}) => {
  const handleCancel = () => {
    if (onResetFormState) {
      onResetFormState();
    }
    onClose();
  };

  const exactMatchCompany = useSelector(state =>
    duplicateIds.name?.filter(
      companyId =>
        getCompanyProperty(state, companyId, 'matchedOn') === 'name' &&
        getCompanyProperty(state, companyId, 'exact_match') === true,
    ),
  );

  const exactMatchAlias = useSelector(state =>
    duplicateIds.name?.filter(
      companyId =>
        getCompanyProperty(state, companyId, 'matchedOn') === 'alias' &&
        getCompanyProperty(state, companyId, 'exact_match') === true,
    ),
  );

  const duplicateCompanyIdsWithNameOrAlias = useSelector(state =>
    duplicateIds.name
      ?.filter(
        companyId =>
          getCompanyProperty(state, companyId, 'exact_match') === false,
      )
      ?.sort((companyId1, companyId2) => {
        const matchedOnId1 = getCompanyProperty(state, companyId1, 'matchedOn');
        const matchedOnId2 = getCompanyProperty(state, companyId2, 'matchedOn');

        if (matchedOnId1 === 'name' && matchedOnId2 === 'alias') {
          return -1;
        } else if (matchedOnId1 === 'alias' && matchedOnId2 === 'name') {
          return 1;
        } else {
          return 0;
        }
      }),
  );

  return (
    <Modal onHide={handleCancel} show={show}>
      <Form formState={formState} onSubmit={onSubmit}>
        <Modal.Header closeButton={true}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CompanyDuplicates
            companyName={fieldState.getNestedField('name').getValue()}
            duplicateIds={exactMatchCompany}
            exactMatchCompany={!exactMatchCompany.isEmpty()}
            isDangerous={true}
            matchedOnPropertyLabel='name'
          />
          <CompanyDuplicates
            duplicateIds={duplicateIds.website_url}
            exactMatchCompany={true}
            matchedOnPropertyLabel='website url'
            showViewMore={false}
          />
          <CompanyDuplicates
            companyName={fieldState.getNestedField('name').getValue()}
            duplicateIds={duplicateCompanyIdsWithNameOrAlias}
            matchedOnPropertyLabel='name or alias'
          />
          <CompanyDuplicates
            companyName={fieldState.getNestedField('name').getValue()}
            duplicateIds={exactMatchAlias}
            exactMatchCompany={!exactMatchAlias.isEmpty()}
            matchedOnPropertyLabel='alias'
          />
          <NewCompanyFields
            {...fieldProps}
            disabled={formState.isSubmitting()}
            duplicateIds={duplicateIds}
            exactMatchCompany={exactMatchCompany}
            fieldState={fieldState}
            onChange={onChange}
            showErrors={formState.wasSubmitted() || 'blurred'}
          />
          <FormErrorMessage formState={formState} />
        </Modal.Body>
        <Modal.Footer>
          <CancelButton bsSize='lg' onClick={handleCancel} />
          <SubmitButton
            bsSize='lg'
            disabled={!exactMatchCompany.isEmpty()}
            formState={formState}
            onClick={onSubmit}
          >
            {submitButtonLabel}
          </SubmitButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

NewCompanyFormModal.propTypes = {
  /**
   * If provided will be the name of the new company being created.
   */
  companyName: PropTypes.string,

  duplicateIds: PropTypes.shape({
    name: ImmutablePropTypes.list,
    website_url: ImmutablePropTypes.list,
  }),

  fieldState: fieldStatePropType,
  /**
   * The current FormState of the form.
   */
  formState: formStatePropType.isRequired,

  onChange: PropTypes.func,
  /**
   * Called when the modal should be closed.
   */
  onClose: PropTypes.func.isRequired,

  onResetFormState: PropTypes.func,

  /**
   * Called when the form should be submitted.
   */
  onSubmit: PropTypes.func.isRequired,

  /**
   * True if the modal should be rendered/displayed, otherwise false.
   */
  show: PropTypes.bool,

  /**
   * The label for the submit button.
   */
  submitButtonLabel: PropTypes.node,

  /**
   * The text to display in the modal header.
   */
  title: PropTypes.node,
};

NewCompanyFormModal.defaultProps = {
  show: false,
  submitButtonLabel: 'Save',
  title: 'New Company',
};

/**
 * The connected version of this component includes a FormState/FieldState and calls
 * onClose when onSaved gets called by the form handler.
 */
export default compose(
  setDisplayName('NewCompanyFormModal(enhanced))'),
  setPropTypes({
    /**
     * Called when the modal should be closed. If the modal was closed because the form was
     * successfully saved, the first argument will be the ID of the new or updated email template.
     */
    onClose: PropTypes.func.isRequired,

    /**
     * True to show the modal, false to hide it.
     */
    show: PropTypes.bool.isRequired,
  }),

  withProps(({ companyName, onClose, onResetFormState }) => ({
    onSaved: onClose,
    onCancel: onClose,
    initialValue: companyName,
    onResetFormState: onResetFormState,
  })),

  connect(state => ({ companies: state.companies }), {}),

  // withCompanyCreateForm will call onSaved when the form has been successfully submitted/saved -
  // we map that directly to the incoming `onClose` prop which will cause the modal to be
  // closed.
  withCompanyCreateForm,

  // create queries and fetch duplicate companyIds
  connectCompanyActions,
  withComponentId(),
  mapQueryIdToCompanyQuery('componentId'),
  withProps(({ componentId, formState }) => ({
    fieldState: formState.getFieldState(),
    duplicateDetectionId: componentId,
  })),

  withCompanyDuplicateIds(),

  /**
   * Prevent props from being passed on to the modal
   */
  /* eslint-disable no-unused-vars */
  mapProps(
    ({
      companyActions,
      companyName,
      companyQuery,
      componentId,
      dispatch,
      initialValue,
      onCancel,
      onCloseCreateCompanyModal,
      onFieldStateChange,
      onFormStateChange,
      onResetFormState,
      onSaved,
      shouldNavigateToNewCompany,
      ...rest
    }) => ({
      title: 'Add a Company',
      submitButtonLabel: 'Create',
      onResetFormState: onResetFormState,
      ...rest,
    }),
    /* eslint-enable no-unused-vars */
  ),
)(NewCompanyFormModal);
