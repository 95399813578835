import { NAME } from '../constants';

/**
 * @constant {String}
 * The action type dispatched when beginning a transaction
 */
export const TRANSACTION_START = `${NAME}/TRANSACTION_START`;

/**
 * @constant {String}
 * The action type dispatched when finishing a transaction
 */
export const TRANSACTION_FINISH = `${NAME}/TRANSACTION_FINISH`;

/**
 * @constant {String}
 * The action type dispatched when removing a transaction
 */
export const TRANSACTION_CLEAR = `${NAME}/TRANSACTION_CLEAR`;
