import selectn from 'selectn';
import * as entityActionTypes from 'modules/entities/actions/entityActionTypes';
import createListReducer from '../../../reducers/createListReducer';
import emailTemplateSchema from '../schema';

import {
  EMAIL_TEMPLATES_FETCH_START,
  EMAIL_TEMPLATES_FETCH_SUCCESS,
  EMAIL_TEMPLATES_FETCH_FAILURE,
} from '../actions/actionTypes';

export const emailTemplateListReducer = createListReducer({
  resultKey: 'email_templates',
  entityResultKey: 'email_template',
  request: EMAIL_TEMPLATES_FETCH_START,
  success: EMAIL_TEMPLATES_FETCH_SUCCESS,
  failure: EMAIL_TEMPLATES_FETCH_FAILURE,
  deleted: entityActionTypes.DELETE_SUCCESS,
  created: entityActionTypes.CREATE_SUCCESS,
});

export default (state, action) => {
  switch (action.type) {
    // Only passthrough for entities that match our type!
    case entityActionTypes.CREATE_SUCCESS:
    case entityActionTypes.DELETE_SUCCESS: {
      if (selectn('payload.entityType', action) === emailTemplateSchema.key) {
        return emailTemplateListReducer(state, action);
      }

      return state;
    }
    default: {
      return emailTemplateListReducer(state, action);
    }
  }
};
