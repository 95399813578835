import { compose } from 'recompose';
import { POST } from 'modules/api/methods';
import fetchAction from 'modules/api/fetchAction';
import createAsyncActions from 'modules/api/actionModifiers/createAsyncActions';
import withOptions from 'modules/api/actionModifiers/withOptions';
import routes from 'modules/routing/routes';
import {
  INVITE_TOKEN_CREATE_START,
  INVITE_TOKEN_CREATE_SUCCESS,
  INVITE_TOKEN_CREATE_FAILURE,
} from './actionTypes';

/**
 * Creates an action that generates a guest assessment token for a particular candidacy
 * @param {Object} options
 * @param {Number} options.candidacyId The ID of the candidacy record to create the token for.
 */
export default compose(
  fetchAction,
  createAsyncActions({
    startActionType: INVITE_TOKEN_CREATE_START,
    successActionType: INVITE_TOKEN_CREATE_SUCCESS,
    failureActionType: INVITE_TOKEN_CREATE_FAILURE,
  }),
  withOptions(({ candidacyId }) => ({
    body: { invite_token: { candidacy_id: candidacyId } },
    method: POST,
    url: routes.api_candidacy_invite_tokens({ candidacyId: candidacyId }),
  })),
);
