import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import commentsByParentType from './commentsByParentType';
import countsByParentType from './countsByParentType';

const commentsReducer = combineImmutableReducers({
  commentsByParentType: commentsByParentType,
  countsByParentType: countsByParentType,
});

commentsReducer.NAME = NAME;

export default commentsReducer;
