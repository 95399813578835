import getPositionProperty from './getPositionProperty';

/**
 * Gets the end date for a position record
 * @param {Object} state
 * @param {Number} positionId The position ID
 * @return {String} The end date value
 */
export default (state, positionId) =>
  getPositionProperty(state, positionId, 'to');
