import { Map } from 'immutable';
import {
  CONTACT_SELECT,
  CONTACT_REVIEW_SELECT,
} from '../../actions/contacts/constants';

const makeSelectedContact = ({ payload: { id } }, type) =>
  new Map({ id: id, type: type });

export default function selectedContact(state = null, action) {
  switch (action.type) {
    case CONTACT_SELECT:
      return makeSelectedContact(action, CONTACT_SELECT);
    case CONTACT_REVIEW_SELECT:
      return makeSelectedContact(action, CONTACT_REVIEW_SELECT);
    default:
      return state;
  }
}
