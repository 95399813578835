import * as stringUtils from '@thrivetrm/ui/utilities/stringUtils';
import getContactProperty from './getContactProperty';

/**
 * Gets a contacts location in a format that is sortable (case insensitive and collapsed whitespace)
 * @param {*} state
 * @param {Number} id The contact ID
 * @returns {String} The contact's location as a sortable string.
 */
export default (state, id) =>
  stringUtils.toSortableString(getContactProperty(state, id, 'location'));
