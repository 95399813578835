import { NAME } from '../constants';

/**
 * Gets a property from an entity object
 * @param {Object} state The current application state
 * @param {String} entityType The entity type name (schema key)
 * @param {*} id The ID of the entity
 * @param {String|String[]} keyOrKeyPath The key or keypath of the property within the entity
 */
export default (state, entityType, id, keyOrKeyPath, notFoundValue) =>
  state[NAME].getIn(
    [
      entityType,

      // All entity IDs are strings! so ensure the id given is converted to a string.
      String(id),

      // This allows passing in either a key or a keypath
      ...(Array.isArray(keyOrKeyPath) ? keyOrKeyPath : [keyOrKeyPath]),
    ],
    notFoundValue,
  );
