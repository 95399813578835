export const DEFAULT_MESSAGE = 'Value is not unique';

export function isUniqueValue(value, values) {
  return values && !values.includes(value);
}

export const uniqueValue = (values, message) => fieldState => {
  const value = fieldState.getValue();

  if (value && !isUniqueValue(value, values)) {
    return message || DEFAULT_MESSAGE;
  }

  return null;
};

export default uniqueValue;
