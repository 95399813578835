import { NAME } from '../constants';

/**
 * Gets an entity by it's ID
 * @param {Object} state The current application state
 * @param {String} entityType The entitiy type (i.e. schema key)
 * @param {String|Number|*} id The ID of the entity (A string, number, or something that
 *   can be converted to a string)
 * @return {Immutable.Map} The entity record, if found.
 */
export default (state, entityType, id) =>
  state[NAME].getIn([entityType, String(id)]);
