import * as stringUtils from '@thrivetrm/ui/utilities/stringUtils';
import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import userSchema from '../schema';

/**
 * Gets a sortable user's name by it's ID
 * @param {Object} state
 * @param {Number} userId The user ID
 * @return {String} The user name
 */
export default (state, userId) =>
  stringUtils.toSortableString(
    [
      getEntityProperty(state, userSchema.key, userId, 'last_name'),
      getEntityProperty(state, userSchema.key, userId, 'first_name'),
    ].join(' '),
  );
