import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import { educationSchema } from '../../schema';

/**
 * Gets an education's start date value
 * @param {*} state
 * @param {Number} educationId The ID of the education record
 * @returns {?String} The start date set for the education record, if found.
 */
export default (state, educationId) =>
  getEntityProperty(state, educationSchema.key, educationId, 'start_year');
