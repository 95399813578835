import { isNil, omitBy, pick } from 'lodash';

/**
 * A helper function to filter out unwanted params
 * @param {Object} payload
 * @param {Object} fetchParams
 * @returns {Object} sanitized params
 */
export default (payload, fetchParams) =>
  omitBy(pick(payload, fetchParams), isNil);
