import { COMMENT_LIST_INVALIDATE } from './actionTypes';
/**
 * Invalidates the list of comments associated with a contact, candidacy, or company.
 * @param {Object} payload
 * @param {Number} payload.companyId The company ID whose comments list should be invalidated
 * @param {Number} payload.contactId The candidacy ID whose comments list should be invalidated
 * @param {Number} payload.candidacyId The candidacy ID whose comments list should be invalidated
 */
export default ({ candidacyId, companyId, contactId }) => ({
  type: COMMENT_LIST_INVALIDATE,
  payload: {
    companyId: companyId,
    contactId: contactId,
    candidacyId: candidacyId,
  },
});
