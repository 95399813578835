import { createSlice } from '@reduxjs/toolkit';
import routes from 'modules/routing/routes';
import Api, { getErrorMessage } from 'modules/core/Api';

const initialState = {
  searchOptions: [],
  isLoadingSearches: false,
  loadSearchesError: null,

  stageOptions: null,
  isLoadingStages: false,
  loadStagesError: null,

  existingCandidacies: null,
  isLoadingExistingCandidacies: false,
  loadExistingCandidaciesError: null,

  isAdding: false,
  isAddingComplete: false,
  addToSearchError: null,
};

const addToSearchSlice = createSlice({
  name: 'addToSearch',
  initialState: initialState,
  reducers: {
    loadSearchesBegin: state => {
      state.isLoadingSearches = true;
      state.loadSearchesError = null;
    },
    loadSearchesSuccess: (state, action) => {
      state.isLoadingSearches = false;
      state.searchOptions = action.payload;
    },
    loadSearchesError: (state, action) => {
      state.isLoadingSearches = false;
      state.loadSearchesError = action.payload;
    },

    loadStagesBegin: state => {
      state.isLoadingStages = true;
      state.loadStagesError = null;
    },
    loadStagesSuccess: (state, action) => {
      state.isLoadingStages = false;
      state.stageOptions = action.payload;
    },
    loadStagesError: (state, action) => {
      state.isLoadingStages = false;
      state.loadStagesError = action.payload;
    },

    loadExistingCandidaciesBegin: state => {
      state.isLoadingExistingCandidacies = true;
      state.loadExistingCandidaciesError = null;
    },
    loadExistingCandidaciesSuccess: (state, action) => {
      state.isLoadingExistingCandidacies = false;
      state.existingCandidacies = action.payload;
    },
    loadExistingCandidaciesError: (state, action) => {
      state.isLoadingExistingCandidacies = false;
      state.loadExistingCandidaciesError = action.payload;
    },
    resetExistingCandidacies: state => {
      state.existingCandidacies = null;
    },

    addToSearchBegin: state => {
      state.isAdding = true;
      state.addToSearchError = null;
    },
    addToSearchSuccess: state => {
      state.isAdding = false;
      state.isAddingComplete = true;
    },
    addToSearchError: (state, action) => {
      state.isAdding = false;
      state.addToSearchError = action.payload;
    },
    resetAddToSearch: state => {
      state.isAddingComplete = false;
    },
  },
});

const {
  addToSearchBegin,
  addToSearchError,
  addToSearchSuccess,
  loadExistingCandidaciesBegin,
  loadExistingCandidaciesError,
  loadExistingCandidaciesSuccess,
  loadSearchesBegin,
  loadSearchesError,
  loadSearchesSuccess,
  loadStagesBegin,
  loadStagesError,
  loadStagesSuccess,
  resetAddToSearch,
  resetExistingCandidacies,
} = addToSearchSlice.actions;

const loadSearches = (searchQuery, queryLength) => dispatch => {
  if (searchQuery?.length < queryLength) {
    dispatch(loadSearchesSuccess([]));
    dispatch(loadExistingCandidaciesSuccess(null));
  } else {
    const endpointUrl = routes.api_v1_autocomplete_query({
      query: {
        query: {
          resource: 'search',
          term: searchQuery,
        },
      },
    });
    dispatch(loadSearchesBegin());
    Api.get(endpointUrl)
      .then(searches => {
        const searchOptions = searches.map(search => ({
          value: search.id,
          label: search.name,
        }));
        dispatch(loadSearchesSuccess(searchOptions));
      })
      .catch(error => {
        dispatch(loadSearchesError(getErrorMessage(error)));
      });
  }
};

const loadExistingCandidacies = (searchId, contactIds) => dispatch => {
  dispatch(loadExistingCandidaciesBegin());
  const endpointUrl = routes.api_v1_search_existing_candidacies_count({
    search_id: searchId,
    query: {
      contact_ids: contactIds,
    },
  });
  Api.get(endpointUrl)
    .then(data => {
      dispatch(loadExistingCandidaciesSuccess(data));
    })
    .catch(error => {
      dispatch(loadExistingCandidaciesError(getErrorMessage(error)));
    });
};

const loadStages = () => dispatch => {
  dispatch(loadStagesBegin());
  Api.get('/api/stages?type=JobSearchStage')
    .then(stages => {
      const stageOptions = stages.filter(
        stage => stage.allow_as_initial && stage.user_assignable,
      );
      dispatch(loadStagesSuccess(stageOptions));
    })
    .catch(error => {
      dispatch(loadStagesError(getErrorMessage(error)));
    });
};

const addToSearch = (
  contactIds,
  searchId,
  stageId,
  candidateVisibility,
  rejectedReason,
  rejectionComment,
  isRejectedReason,
  // eslint-disable-next-line max-params
) => dispatch => {
  dispatch(addToSearchBegin());
  const endpointUrl = routes.bulk_api_v1_search_candidacies({
    search_id: searchId,
  });
  const rejectionPayload = {
    rejection_reason: rejectedReason,
    rejection_comment: rejectionComment,
  };
  const payload = {
    contact_id: contactIds,
    hidden: candidateVisibility,
    stage_id: stageId,
    ...(isRejectedReason ? rejectionPayload : null),
  };
  Api.post(endpointUrl, payload)
    .then(data => {
      dispatch(addToSearchSuccess(data));
    })
    .catch(error => {
      dispatch(addToSearchError(getErrorMessage(error)));
    });
};

export {
  addToSearch,
  loadExistingCandidacies,
  loadSearches,
  loadStages,
  resetExistingCandidacies,
  resetAddToSearch,
};
export default addToSearchSlice;
