import deleteEntity from 'modules/entities/actions/deleteEntity';
import routes from 'modules/routing/routes';
import { candidacySchema } from '../schemas';

/**
 * Creates an action for deleting a candidacy
 * @param {Object} payload
 * @param {String} payload.id The ID of the candidacy
 */
export default ({ id, ...rest }) =>
  deleteEntity({
    ...rest,
    entityType: candidacySchema.key,
    id: id,
    url: routes.api_v1_candidacy({ id: id }),
  });
