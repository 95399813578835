import getStageListForStageType from './getStageListForStageType';
import { STAGE_TYPES } from '../constants';

/**
 * Determines if the list of stages for a particular stage type needs to be fetched
 * from the server.
 * @param {Object} state
 * @param {String} stageType One of the stage type constants
 * @return {Boolean} True if the stage list needs to be fetched from the server;
 *   Otherwise, false.
 */
const shouldFetchStageList = (state, stageType) => {
  if (!stageType) {
    return false;
  }

  if (!STAGE_TYPES.includes(stageType)) {
    throw new Error('Unknown stage type');
  }

  const stageList = getStageListForStageType(state, stageType);

  if (!stageList) {
    return true;
  }

  if (
    stageList.getIn(['_meta', 'isFetching']) ||
    stageList.hasIn(['_meta', 'error'])
  ) {
    return false;
  }

  return (
    !stageList.has('ids') ||
    Boolean(stageList.getIn(['_meta', 'isInvalidated']))
  );
};

export default shouldFetchStageList;
