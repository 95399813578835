import selectn from 'selectn';
import { Map } from 'immutable';
import createListReducer from '../../../reducers/createListReducer';
import {
  TARGET_COMPANY_COMMENT_LIST_FETCH_START,
  TARGET_COMPANY_COMMENT_LIST_FETCH_SUCCESS,
  TARGET_COMPANY_COMMENT_LIST_FETCH_FAILURE,
  TARGET_COMPANY_VOTE_SUCCESS,
} from '../actions/actionTypes';

const commentListReducer = createListReducer({
  resultKey: 'comments',
  entityResultKey: 'comment',
  request: TARGET_COMPANY_COMMENT_LIST_FETCH_START,
  success: TARGET_COMPANY_COMMENT_LIST_FETCH_SUCCESS,
  failure: TARGET_COMPANY_COMMENT_LIST_FETCH_FAILURE,
});

// Comments is keyed on the parent type (PARENT_CANDIDACY or PARENT_CONTACT),
// then by that parent's ID (a contact ID for PARENT_CONTACT or a candidacy ID for
// PARENT_CANDIDACY).
export default (state = new Map(), action) => {
  switch (action.type) {
    case TARGET_COMPANY_COMMENT_LIST_FETCH_START:
    case TARGET_COMPANY_COMMENT_LIST_FETCH_SUCCESS:
    case TARGET_COMPANY_COMMENT_LIST_FETCH_FAILURE: {
      const targetCompanyId = selectn('payload.targetCompanyId', action);

      if (targetCompanyId) {
        return state.update(targetCompanyId, list =>
          commentListReducer(list, action),
        );
      }

      return state;
    }
    case TARGET_COMPANY_VOTE_SUCCESS: {
      const targetCompanyId = selectn('payload.result.target_company', action);

      if (state.hasIn([targetCompanyId, 'ids'])) {
        const commentId = selectn('payload.result.comment', action);

        if (commentId) {
          return state.updateIn([targetCompanyId, 'ids'], ids =>
            ids.push(commentId),
          );
        }
      }

      return state;
    }
    default: {
      return state.map(listState => commentListReducer(listState, action));
    }
  }
};
