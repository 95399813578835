const lessThanOrEqualTo = number => fieldState => {
  const value = fieldState.getRawValue();

  if (typeof value === 'number' && !(value <= number)) {
    return `Value must be less than or equal to ${number}`;
  }

  return null;
};

export default lessThanOrEqualTo;
