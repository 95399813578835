/**
 * Determines if a feature is enabled for the current user
 * @deprecated
 * @param {Object} state
 * @param {String} name of the feature
 * @returns {Boolean} true when the feature is enabled
 */

// DEPRECATED. Do not use directly. Instead, use:
//   * useFeatureCheck hook (preferred)
//   * withFeatureCheck HOC (if you are in an existing recompose chain)
export default (state, featureName) => {
  const features = state.user.get('features', {});
  return Boolean(features[featureName]);
};
