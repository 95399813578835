/* eslint-disable filenames/match-exported */
const REACT_SELECT_COMPONENTS = [
  'clearIndicator',
  'container',
  'control',
  'dropdownIndicator',
  'group',
  'groupHeading',
  'indicatorsContainer',
  'indicatorSeparator',
  'input',
  'loadingIndicator',
  'loadingMessage',
  'menu',
  'menuList',
  'menuPortal',
  'multiValue',
  'multiValueLabel',
  'multiValueRemove',
  'noOptionsMessage',
  'option',
  'singleValue',
  'valueContainer',
];

/**
 * Remove all default styles by iterating over the components and returning an empty object
 * for each component.
 * https://react-select.com/styles
 */
const EMPTY_STYLES = Object.fromEntries(
  REACT_SELECT_COMPONENTS.map(componentKey => [
    componentKey,
    (_provided, _state) => ({}),
  ]),
);

export { EMPTY_STYLES, REACT_SELECT_COMPONENTS };
