import PropTypes from 'prop-types';
import { branch, compose, lifecycle, setPropTypes } from 'recompose';
import connectSearchStageActions from './connectSearchStageActions';

import { STAGE_TYPES } from '../constants';

export const fetchStageListIfNeeded = ({ searchStageActions, stageType }) => {
  if (stageType) {
    searchStageActions.fetchStageListIfNeeded({ stageType: stageType });
  }
};

/**
 * An HOC that fetches the candidacies for a given searchID if they are needed
 * from the server
 */
export default compose(
  setPropTypes({
    stageType: PropTypes.oneOf(STAGE_TYPES),
  }),

  // Connec the actions if they aren't provided already.
  branch(
    ({ searchStageActions }) => !searchStageActions,
    connectSearchStageActions,
  ),

  lifecycle({
    UNSAFE_componentWillMount: function () {
      fetchStageListIfNeeded(this.props);
    },
    UNSAFE_componentWillReceiveProps: function (nextProps) {
      fetchStageListIfNeeded(nextProps);
    },
  }),
);
