import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { EditorState, RichUtils, AtomicBlockUtils } from 'draft-js';
import ToolbarButton from './ToolbarButton';
import clearFormatting from './clearFormatting';
import LinkModal from './LinkModal';
import ImageUploadModal from './ImageUploadModal';
import { fromHtml, toHtml } from './convert';

/**
 * The inline value buttons to render
 */
const INLINE_STYLES = [
  { title: 'Bold', icon: 'fa-bold', value: 'BOLD' },
  { title: 'Italic', icon: 'fa-italic', value: 'ITALIC' },
  { title: 'Underline', icon: 'fa-underline', value: 'UNDERLINE' },
  { title: 'Strikethrough', icon: 'fa-strikethrough', value: 'STRIKETHROUGH' },
];

/**
 * The block type toggle buttons to render.
 */
const BLOCK_TYPES = [
  { title: 'Blockquote', icon: 'fa-quote-left', value: 'blockquote' },
  { title: 'UL', icon: 'fa-list-ul', value: 'unordered-list-item' },
  { title: 'OL', icon: 'fa-list-ol', value: 'ordered-list-item' },
];

/**
 * Renders the RichTextArea's toolbar.
 */
class Toolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLinkModalOpen: false,
      isImageModalOpen: false,
    };
  }

  handleChange = editorState => {
    const { onChange } = this.props;
    onChange(editorState);
  };

  /**
   * Called when the undo button is clicked.
   */
  handleUndo = () => {
    const { editorState } = this.props;
    this.handleChange(EditorState.undo(editorState));
  };

  /**
   * Called when the redo button is clicked.
   */
  handleRedo = () => {
    const { editorState } = this.props;
    this.handleChange(EditorState.redo(editorState));
  };

  /**
   * Called when an inline style should be toggled.
   */
  handleStyleToggle = inlineStyle => {
    const { editorState } = this.props;
    this.handleChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  /**
   * Called when an inline block type should be toggled.
   */
  handleBlockToggle = blockType => {
    const { editorState } = this.props;
    this.handleChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  handleClearFormatting = () => {
    const { editorState } = this.props;
    this.handleChange(clearFormatting(editorState));
  };

  handleLinkToggle = () => {
    this.setState({ isLinkModalOpen: true });
  };

  handleImageToggle = () => {
    this.setState({ isImageModalOpen: true });
  };

  handleLinkConfirm = link => {
    const { editorState } = this.props;
    const contentState = editorState.getCurrentContent();

    // Create a new entity with the link
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url: link, target: '_blank' },
    );

    // Get the key of the newly created entity
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });

    // Apply the link entity to the current selection
    const newEditorStateWithLink = RichUtils.toggleLink(
      newEditorState,
      newEditorState.getSelection(),
      entityKey,
    );

    // Update the editor state with the new link
    this.handleChange(newEditorStateWithLink);

    // Close the modal
    this.setState({ isLinkModalOpen: false });
  };

  handleRemoveLink = () => {
    const { editorState } = this.props;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      this.handleChange(RichUtils.toggleLink(editorState, selection, null));
    }
  };

  hasLinkInSelection = () => {
    const { editorState } = this.props;
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
    const isSelected = blockWithLinkAtBeginning.getEntityAt(startOffset);

    return Boolean(isSelected);
  };

  hasTextSelected = () => {
    const { editorState } = this.props;
    const selection = editorState.getSelection();
    return !selection.isCollapsed();
  };

  handleOnClose = () => {
    const { editorState } = this.props;
    const selection = editorState.getSelection();

    if (!selection.isCollapsed()) {
      const newEditorState = EditorState.forceSelection(
        editorState,
        selection.merge({ hasFocus: false }),
      );
      this.handleChange(newEditorState);
    }
    this.setState({ isLinkModalOpen: false });
  };

  handleImageModalClose = () => {
    this.setState({ isImageModalOpen: false });
  };

  handleImageInsertion = (link, replaceImage) => {
    if (replaceImage) {
      const { editorState } = this.props;
      const currentContent = toHtml(editorState.getCurrentContent());
      const modifiedContent = currentContent.replace(
        /src="[^"]*"/,
        `src="${link}"`,
      );
      const newEditorState = EditorState.createWithContent(
        fromHtml(modifiedContent),
      );
      this.setState({ isImageModalOpen: false });
      this.handleChange(newEditorState);
    } else {
      const { editorState } = this.props;
      const contentState = editorState.getCurrentContent();

      const entityData = {
        src: link,
      };

      const contentStateWithEntity = contentState.createEntity(
        'IMAGE',
        'IMMUTABLE',
        entityData,
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = AtomicBlockUtils.insertAtomicBlock(
        editorState,
        entityKey,
        ' ',
      );

      this.setState({ isImageModalOpen: false });
      this.handleChange(newEditorState);
    }
  };

  render() {
    const {
      btnClass,
      disabled,
      editorState,
      onChange, // eslint-disable-line no-unused-vars
      shouldShowImageOption,
      shouldShowLinkOption,
      size,
    } = this.props;

    const currentStyle = editorState.getCurrentInlineStyle();
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    const groupClassName = classnames('btn-group', {
      [`btn-group-${size}`]: size,
    });

    return (
      <div aria-label='Text styles' className='btn-toolbar' role='toolbar'>
        <div
          aria-label='Inline styles'
          className={groupClassName}
          key='inline-styles'
          role='group'
        >
          {INLINE_STYLES.map(type => (
            <ToolbarButton
              active={currentStyle.has(type.value)}
              btnClass={btnClass}
              disabled={disabled}
              icon={type.icon}
              key={type.title}
              onClick={this.handleStyleToggle}
              title={type.title}
              value={type.value}
            />
          ))}
          {shouldShowLinkOption ? (
            <ToolbarButton
              active={this.hasLinkInSelection() && this.hasTextSelected()}
              btnClass={btnClass}
              disabled={disabled || !this.hasTextSelected()}
              icon='fa-link'
              onClick={() =>
                this.hasLinkInSelection()
                  ? this.handleRemoveLink()
                  : this.handleLinkToggle()
              }
              title='Link'
            />
          ) : null}
          {shouldShowImageOption ? (
            <ToolbarButton
              btnClass={btnClass}
              icon='fa-image'
              onClick={this.handleImageToggle}
              title='Image'
            />
          ) : null}
        </div>
        <div
          aria-label='Inline styles'
          className={groupClassName}
          key='block-types'
          role='group'
        >
          {BLOCK_TYPES.map(type => (
            <ToolbarButton
              active={type.value === blockType}
              btnClass={btnClass}
              disabled={disabled}
              icon={type.icon}
              key={type.title}
              onClick={this.handleBlockToggle}
              title={type.title}
              value={type.value}
            />
          ))}
          <ToolbarButton
            btnClass={btnClass}
            disabled={disabled}
            icon='fa-eraser'
            key='remove-formatting'
            onClick={this.handleClearFormatting}
            title='Clear formatting'
          />
        </div>
        <div
          aria-label='Inline styles'
          className={groupClassName}
          key='history'
          role='group'
        >
          <ToolbarButton
            btnClass={btnClass}
            disabled={disabled || editorState.getUndoStack().size <= 0}
            icon='fa-undo'
            key='undo'
            onClick={this.handleUndo}
            title='Undo'
          />
          <ToolbarButton
            btnClass={btnClass}
            disabled={disabled || editorState.getRedoStack().size <= 0}
            icon='fa-undo fa-flip-horizontal'
            key='redo'
            onClick={this.handleRedo}
            title='Redo'
          />
        </div>
        {this.state.isLinkModalOpen ? (
          <LinkModal
            onClose={this.handleOnClose}
            onConfirm={this.handleLinkConfirm}
          />
        ) : null}
        {this.state.isImageModalOpen ? (
          <ImageUploadModal
            editorState={editorState}
            onClose={this.handleImageModalClose}
            onConfirm={this.handleImageInsertion}
          />
        ) : null}
      </div>
    );
  }
}

Toolbar.propTypes = {
  btnClass: PropTypes.string,

  disabled: PropTypes.bool,

  /**
   * The DraftJS editor state
   */
  editorState: PropTypes.shape({
    getCurrentContent: PropTypes.func.isRequired,
    getCurrentInlineStyle: PropTypes.func.isRequired,
    getRedoStack: PropTypes.func.isRequired,
    getSelection: PropTypes.func.isRequired,
    getUndoStack: PropTypes.func.isRequired,
  }).isRequired,

  /**
   * Called when the editor state is changed.
   */
  onChange: PropTypes.func.isRequired,
  shouldShowImageOption: PropTypes.bool,
  shouldShowLinkOption: PropTypes.bool,

  /*
   * Adjusts the size of the toolbar buttons.
   */
  size: PropTypes.oneOf(['sm', 'lg']),
};

Toolbar.defaultProps = {
  btnClass: ToolbarButton.defaultProps.btnClass,
  size: 'sm',
};

export default Toolbar;
