import { createSlice } from '@reduxjs/toolkit';
import routes from 'modules/routing/routes';
import Api, { getErrorMessage } from 'modules/core/Api';

const initialState = {
  isLoadingCandidacyAssessments: false,
  candidacyAssessments: null,
  loadCandidacyAssessmentsError: null,
};

const candidacyAssessmentsSlice = createSlice({
  name: 'candidacyAssessments',
  initialState: initialState,
  reducers: {
    loadCandidacyAssessmentsBegin: state => {
      state.isLoadingCandidacyAssessments = true;
      state.loadCandidacyAssessmentsError = null;
    },
    loadCandidacyAssessmentsSuccess: (state, action) => {
      state.isLoadingCandidacyAssessments = false;
      state.candidacyAssessments = action.payload;
      state.loadCandidacyAssessmentsError = null;
    },
    loadCandidacyAssessmentsError: (state, action) => {
      state.isLoadingCandidacyAssessments = false;
      state.loadCandidacyAssessmentsError = action.payload;
    },
  },
});

const {
  loadCandidacyAssessmentsBegin,
  loadCandidacyAssessmentsError,
  loadCandidacyAssessmentsSuccess,
} = candidacyAssessmentsSlice.actions;

const loadCandidacyAssessments = searchId => dispatch => {
  dispatch(loadCandidacyAssessmentsBegin());
  Api.get(routes.api_v1_search_candidacy_assessments({ searchId: searchId }))
    .then(data => {
      dispatch(loadCandidacyAssessmentsSuccess(data.assessments));
    })
    .catch(error => {
      dispatch(loadCandidacyAssessmentsError(getErrorMessage(error)));
    });
};

export { loadCandidacyAssessments };

export default candidacyAssessmentsSlice;
