import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import HelpText from '@thrivetrm/ui/components/HelpText';
import Tag from './Tag';
import TooltipTrigger from '../componentsLegacy/TooltipTrigger';

const TagList = ({ className, limit, onDelete, tags, ...props }) => {
  let tagNames = [...tags];
  let tooltip = null;
  if (limit && tagNames.length > limit) {
    tagNames = tags.slice(0, limit);
    const additionalTagNames = tags.slice(limit);

    const tooltipContent = (
      <ul className='u-textAlign-l u-margin-n u-paddingHorizontal-16 u-paddingVertical-4'>
        {additionalTagNames.map(tagName => (
          <li key={tagName}>{tagName}</li>
        ))}
      </ul>
    );

    tooltip = (
      <TooltipTrigger tooltip={tooltipContent}>
        <HelpText>+{additionalTagNames.length} more</HelpText>
      </TooltipTrigger>
    );
  }

  const containerClass = classnames('TagList', className);
  return (
    <div {...props} className={containerClass}>
      {tagNames.map(tagName => (
        <Tag key={tagName} name={tagName} onDelete={onDelete} />
      ))}
      {tooltip}
    </div>
  );
};

TagList.defaultProps = {
  className: null,
  limit: null,
  onDelete: null,
};

TagList.propTypes = {
  /** Classname to render on the root node */
  className: PropTypes.string,
  /** Number of tags to render. Any remaining tags are rendered in a tooltip. */
  limit: PropTypes.number,
  /** A function to call when the delete button is clicked. Called with the tag name */
  onDelete: PropTypes.func,
  /** A collection of Tags to render */
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TagList;
