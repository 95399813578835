import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, mapProps, setDisplayName, setPropTypes } from 'recompose';
import mapPositionIdToPosition from 'modules/positions/components/mapPositionIdToPosition';
import PositionCompanyName from 'modules/positions/components/PositionCompanyName';
import mapContactIdToContact from '../mapContactIdToContact';

/**
 * Renders a warning message notifying the user they that will be replacing the
 * the contact's current primary position.
 * This is used by The ContactPositionField when the the PrimaryPositionCheckboxField is checked
 * and there is already a primary position selected for the contact.
 */
const PrimaryPositionWarning = ({ contact, position = Map() }) => {
  const title = position.get('name');
  const hasCompany = position.has('company') || position.has('company_name');
  const firstName = contact.get('first_name');
  return (
    <span>
      This will replace {title} {title && hasCompany && ' at '}
      {hasCompany && (
        <PositionCompanyName
          linkToCompany={false}
          positionId={position.get('id')}
        />
      )}
      {(title || hasCompany) && ' as '}
      the primary position for {firstName}
    </span>
  );
};

PrimaryPositionWarning.propTypes = {
  contact: ImmutablePropTypes.mapContains({
    first_name: PropTypes.string.isRequired,
  }),
  position: ImmutablePropTypes.shape({
    company: PropTypes.number,
    company_name: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

export default compose(
  setDisplayName('PrimaryPositionWarning(enhanced)'),
  setPropTypes({
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToContact,
  mapProps(({ contact, ...props }) => ({
    ...props,
    contact: contact,
    positionId: contact && contact.get('primary_position'),
  })),
  mapPositionIdToPosition,
)(PrimaryPositionWarning);
