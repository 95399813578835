import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setDisplayName, setPropTypes, withProps } from 'recompose';
import withTenantName from 'modules/tenant/components/withTenantName';
import Card from '@thrivetrm/ui/components/Card';
import CandidatePortalConsentForm from './CandidatePortalConsentForm';

export const CandidatePortalConsentPage = ({ contactId, tenantName }) => (
  <div className='CandidatePortalConsentPage container'>
    <Card className='u-marginTop-24 u-padding-24' type='shadow'>
      <div className='u-textAlign-c'>
        <h3 className='u-textColor-blue'>Connect with {tenantName}</h3>
        <p>
          When you join our network your profile will not be publicly available.
          It will only be shared with hiring teams in our network who are
          interested in experience like yours.
        </p>
      </div>
    </Card>

    <Card
      className='u-marginTop-24 u-padding-24'
      isCentered={false}
      type='shadow'
    >
      <div>
        <h3 className='u-textColor-blue'>Consent & Privacy Policy</h3>
        <CandidatePortalConsentForm
          contactId={contactId}
          tenantName={tenantName}
        />
      </div>
    </Card>
  </div>
);

CandidatePortalConsentPage.propTypes = {
  contactId: PropTypes.number,
  tenantName: PropTypes.string,
};

export default compose(
  setDisplayName('CandidatePortalConsentPage(enhanced)'),
  setPropTypes({
    contact: ImmutablePropTypes.mapContains({
      data: ImmutablePropTypes.map,
      meta: ImmutablePropTypes.map,
    }),
  }),
  withProps(({ contact, contactId }) => ({
    contactId: (contact && contact.getIn(['data', 'id'])) || contactId,
  })),
  withTenantName(),
)(CandidatePortalConsentPage);
