import combineImmutableReducers from '../../../reducers/combineImmutableReducers';
import { NAME } from '../constants';
import allTimezones from './allTimezones';

const datetimeReducer = combineImmutableReducers({
  allTimezones: allTimezones,
});

datetimeReducer.NAME = NAME;

export default datetimeReducer;
