import { schema } from 'normalizr';
import { searchSchema } from 'modules/searches/schema';
import { contactSchema } from 'modules/contacts/schema';
import userSchema from 'modules/users/schema';

export default new schema.Entity('introductions', {
  contact: contactSchema,
  introduced_by: userSchema,
  introduced_to: contactSchema,
  created_by: userSchema,
  searches: [searchSchema],
});
