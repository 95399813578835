import fetchEntity from 'modules/entities/actions/fetchEntity';
import routes from 'modules/routing/routes';
import { searchCandidacyStats } from '../../schema';

/**
 * Creates an action for fetching a search's candidacy statistics
 * @param {Object} payload
 * @param {String} payload.searchId The ID of the search
 */

export default ({ searchId }) =>
  fetchEntity({
    entityType: searchCandidacyStats.key,
    id: searchId,
    responseSchema: { candidacy_stats: searchCandidacyStats },
    url: routes.api_v1_search_candidacy_stats({ searchId: searchId }),
  });
