import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import ConfirmationPopoverButton from 'modules/core/componentsLegacy/ConfirmationPopoverButton';
import ButtonSecondary from '@thrivetrm/ui/components/ButtonSecondary';

const InlineEditActions = ({
  actionClassName,
  canDelete,
  canEdit,
  children,
  className,
  deleteClassName,
  deleteConfirmation,
  deleteLabel,
  disabled,
  editClassName,
  editLabel,
  onDelete,
  onEdit,
  popoverPlacement,
  shouldRenderV4Button,
}) => {
  if (!canEdit && !canDelete && !children) {
    return null;
  }

  const editButton = shouldRenderV4Button ? (
    <ButtonSecondary
      className={editClassName}
      data-testid='inline edit action'
      disabled={disabled}
      icon='edit'
      key='edit'
      onClick={onEdit}
      size='small'
    />
  ) : (
    <button
      className={classnames('btn btn-link btn-sm', editClassName)}
      data-testid='inline edit action'
      disabled={disabled}
      key='edit'
      onClick={onEdit}
      type='button'
    >
      {editLabel}
    </button>
  );

  return (
    <div className={classnames('inline-edit-actions', className)}>
      {children}
      <div
        className={classnames(actionClassName, {
          'pull-right': shouldRenderV4Button,
        })}
        key='edit-delete'
      >
        {canEdit ? editButton : null}
        {canDelete ? (
          <ConfirmationPopoverButton
            className={classnames(deleteClassName, {
              'btn btn-link btn-sm': !shouldRenderV4Button,
              'Button Button--secondary Button--hasIconOnly Button--small u-marginLeft-8': shouldRenderV4Button,
            })}
            data-testid='inline delete action'
            disabled={disabled}
            key='delete'
            label={deleteLabel}
            onConfirm={onDelete}
            popoverPlacement={popoverPlacement}
            title={deleteConfirmation}
          />
        ) : null}
      </div>
    </div>
  );
};

InlineEditActions.propTypes = {
  actionClassName: PropTypes.string,

  canDelete: PropTypes.bool,

  canEdit: PropTypes.bool,

  children: PropTypes.node,

  className: PropTypes.string,

  deleteClassName: PropTypes.string,

  deleteConfirmation: PropTypes.node,

  deleteLabel: PropTypes.node,

  disabled: PropTypes.bool,

  editClassName: PropTypes.string,

  editLabel: PropTypes.node,

  onDelete: PropTypes.func,

  onEdit: PropTypes.func,

  /**
   * Optional string to set orientation of delete confirm popovers
   */
  popoverPlacement: PropTypes.string,

  shouldRenderV4Button: PropTypes.bool,
};

InlineEditActions.defaultProps = {
  actionClassName: 'pull-right',
  className: 'clearfix',
  deleteConfirmation: 'Delete this item?',
  deleteLabel: 'Delete',
  editLabel: 'Edit',
  shouldRenderV4Button: false,
};

export default InlineEditActions;
