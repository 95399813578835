import createEntityListReducer from 'modules/entities/createEntityListReducer';
import {
  TAG_LIST_FETCH_START,
  TAG_LIST_FETCH_SUCCESS,
  TAG_LIST_FETCH_FAILURE,
  TAG_LIST_INVALIDATE,
} from '../actions/actionTypes';
import smartTagSchema from '../schemas/smartTag';

/**
 * Maintains a collection of all smart tags.
 */
const tagsReducer = createEntityListReducer({
  entityType: smartTagSchema.key,
  listResultKey: 'smart_tags',
  entityResultKey: 'smart_tag',
  request: TAG_LIST_FETCH_START,
  success: TAG_LIST_FETCH_SUCCESS,
  failure: TAG_LIST_FETCH_FAILURE,
  invalidated: TAG_LIST_INVALIDATE,
});

tagsReducer.NAME = 'smart_tags';

export default tagsReducer;
