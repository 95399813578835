import { List } from 'immutable';
import findCompanyByName from 'modules/companies/selectors/findCompanyByName';
import { connect } from 'react-redux';
import getCandidacyContact from '../selectors/getCandidacyContact';

/**
 * A Higher order component that filters a list of candidacyIds to only include those candidates
 * who have had a position at the company named by `filterCompany`. It also adds an
 * `isFiltered` prop so the base component can know when it's candidacyIds have been filtered.
 *
 * NOTE: this will currently only work for filtering companies that are associated with
 * actual company records (some contact positions have a company _name_, but aren't actually
 * associated with an underlying company record). For our current purposes this doesn't really
 * make much of a difference, as we're currently only filtering on target companies (active or
 * not) -- and a target company MUST be associated with a record (it can't just be a string).
 */
export default connect((state, { candidacyIds, filterCompany }) => {
  if (!filterCompany || !candidacyIds) {
    return {};
  }

  const company = findCompanyByName(state, filterCompany);

  if (company) {
    const companyId = company.get('id');

    return {
      isFiltered: true,
      candidacyIds: candidacyIds.filter(candidacyId => {
        const contact = getCandidacyContact(state, candidacyId);
        const companyIds = contact && contact.get('companies');
        return companyIds && companyIds.includes(companyId);
      }),
    };
  }

  return {
    isFiltered: true,
    candidacyIds: new List(),
  };
}, {});
