import selectn from 'selectn';
import { TENANT_OPTIONS_FETCH_SUCCESS } from 'modules/tenant/actions/actionTypes';
import initializePortfolioCompanies from '../actions/initializePortfolioCompanies';

/**
 * Acts on tenant option fetches which includes the company stages (this is how the
 * company stages are currently fetched). This normalizes the data so they become
 * entities and dispatches the initialize action we listen for in our reducer.
 */
export default ({ action, dispatch }) => {
  if (action.type === TENANT_OPTIONS_FETCH_SUCCESS) {
    const data = selectn(
      'payload.result.tenant_options.portfolio_companies',
      action,
    );
    dispatch(initializePortfolioCompanies(data || []));
  }
};
