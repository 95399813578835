import getEntity from 'modules/entities/selectors/getEntity';
import activitySchema from '../schema';

/**
 * Gets an activity record from state given it's id
 * @param {*} state
 * @param {Number} id The ID of the activity
 * @returns {?Immutable.Map} The activity record, if found.
 */
export default (state, id) => getEntity(state, activitySchema.key, id);
