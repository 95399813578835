import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { compose, setPropTypes } from 'recompose';
import requiredIf from '@thrivetrm/ui/propTypes/requiredIf';

import connectContactActions from '../connectContactActions';
import mapEducationIdToEducation from './mapEducationIdToEducation';
import { EducationView } from './EducationView';
import EducationForm from './EducationForm';
import EducationTitle from './EducationTitle';

import InlineEditActions from '../../../../components/forms/InlineEditActions';

/**
 * Renders single education list item.
 */
export class EducationListItem extends Component {
  state = {
    /**
     * True when the item is being edited inline, false when being viewed.
     * @type {Boolean}
     */
    isEditing: false,
  };

  /**
   * Called when the 'Yes' button of the delete confirmation is clicked.
   * Calls the action to delete the education and resets the action.
   */
  handleDeleteClick = () => {
    const { contactActions, education } = this.props;
    this.setState({
      isEditing: false,
    });

    contactActions.deleteEducation({ id: education.get('id') });
  };

  /**
   * Called when the edit link is clicked, shows the edit form in place of
   * the education view.
   */
  handleEditClick = () => {
    this.setState({
      isEditing: true,
    });
  };

  /**
   * Called when the edit form is closed, removes the edit form and shows
   * the education view instead.
   */
  handleFormClose = () => {
    this.setState({
      isEditing: false,
    });
  };

  render() {
    const {
      education,
      educationId,
      formLayout,
      readOnly,
      renderDescription,
      renderSchool,
      renderTitle,
      targetedCompanyIds,
    } = this.props;
    const { isEditing } = this.state;
    const isDeleting = education.getIn(['_meta', 'isDeleting']);
    const canDelete = education.getIn(['permissions', 'delete']);
    const canEdit = education.getIn(['permissions', 'edit']);

    return (
      <li className='EducationListItem'>
        {renderTitle && (
          <EducationTitle
            canDelete={!isEditing && !readOnly && canDelete}
            canEdit={!isEditing && !readOnly && canEdit}
            deleteConfirmation='Delete this education?'
            disabled={isDeleting}
            education={education}
            onDelete={this.handleDeleteClick}
            onEdit={this.handleEditClick}
            shouldRenderV4Button={true}
          />
        )}
        {isEditing ? (
          <EducationForm
            alwaysShowFieldErrors={true}
            education={education}
            educationId={educationId}
            errorDisplay='tooltip'
            layout={formLayout}
            onCancel={this.handleFormClose}
            onSaved={this.handleFormClose}
            title='Edit Education'
          />
        ) : (
          <EducationView
            education={education}
            isTargetCompany={
              targetedCompanyIds &&
              targetedCompanyIds.includes(education.get('company'))
            }
            renderDescription={renderDescription}
            renderSchool={renderSchool}
          />
        )}
        {!renderTitle && !readOnly && (canEdit || canDelete) && (
          <InlineEditActions
            canDelete={!isEditing && !readOnly && canDelete}
            canEdit={!isEditing && !readOnly && canEdit}
            deleteConfirmation='Delete this education?'
            disabled={isDeleting}
            education={education}
            onDelete={this.handleDeleteClick}
            onEdit={this.handleEditClick}
          />
        )}
      </li>
    );
  }
}

export const propTypes = {
  /**
   * Actions for deleting educations (only required when `readOnly` is false).
   */
  contactActions: requiredIf(
    PropTypes.shape({
      deleteEducation: PropTypes.func.isRequired,
    }),
    props => !props.readOnly,
  ),

  /**
   * The education to render.
   */
  education: ImmutablePropTypes.mapContains({
    _meta: ImmutablePropTypes.mapContains({
      isDeleting: PropTypes.bool,
    }),
    permissions: ImmutablePropTypes.mapContains({
      delete: PropTypes.bool.isRequired,
      edit: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,

  educationId: PropTypes.number.isRequired,

  /**
   * If the item is editable, the form layout to use when editing.
   */
  formLayout: PropTypes.oneOf(EducationForm.FIELD_LAYOUTS),

  /**
   * True to not allow editing of the education.
   */
  readOnly: PropTypes.bool,

  /**
   * True to render the description field in the education view, false to omit it.
   */
  renderDescription: PropTypes.bool,

  /**
   * True to render the education school name, false to omit it.
   */
  renderSchool: PropTypes.bool,

  /**
   * True to render the education title, false to omit it.
   */
  renderTitle: PropTypes.bool,

  /**
   * If highlighting target companies, this will be a list of all target company IDs that should
   * be highlighted
   */
  targetedCompanyIds: ImmutablePropTypes.listOf(PropTypes.number),
};

export const defaultProps = {
  formLayout: EducationForm.FIELD_LAYOUTS[0],
  readOnly: false,
  renderDescription: true,
  renderSchool: false,
  renderTitle: true,
};

EducationListItem.propTypes = propTypes;
EducationListItem.defaultProps = defaultProps;

export default compose(
  setPropTypes({
    educationId: PropTypes.number.isRequired,
  }),
  mapEducationIdToEducation,
  connectContactActions,
)(EducationListItem);
